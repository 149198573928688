import React from "react";
import { Route, Link, Routes } from "react-router-dom";
import * as AUTH_ROUTES from "./routes";

import LoginPage from "./pages/Login";
import RegisterPage from "./pages/Register";
import ResetPasswordPage from "./pages/ResetPassword";
import ForgetPasswordPage from "./pages/ForgetPassword";

import { AuthStyled } from "./styled";
import RegisterSuccess from "./pages/RegisterSuccess";
import VerifiedSuccess from "./pages/VerifiedSuccess";
import VerifiedFailure from "./pages/VerifiedFailure";
import AlreadyVerified from "./pages/AlreadyVerified";

interface IAuthProps {
  currentTheme: "light" | "dark";
}

const Auth: React.FunctionComponent<IAuthProps> = ({ currentTheme }) => {
  return (
    <AuthStyled>
      <Routes>
        <Route path={AUTH_ROUTES.FORGET_PASSWORD} element={<ForgetPasswordPage />} />
        <Route path={AUTH_ROUTES.RESET_PASSWORD} element={<ResetPasswordPage />} />
        <Route path={AUTH_ROUTES.REGISTER} element={<RegisterPage currentTheme={currentTheme} />} />
        <Route path={AUTH_ROUTES.LOGIN} element={<LoginPage />} />

        <Route path={AUTH_ROUTES.REGISTER_SUCCESSFULLY} element={<RegisterSuccess />} />
        <Route path={AUTH_ROUTES.VERIFIED_SUCCESSFULLY} element={<VerifiedSuccess />} />
        <Route path={AUTH_ROUTES.VERIFIED_FAILED} element={<VerifiedFailure />} />
        <Route path={AUTH_ROUTES.ALREADY_VERIFIED} element={<AlreadyVerified />} />
      </Routes>
    </AuthStyled>
  );
};

export default Auth;
