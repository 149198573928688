import styled from "styled-components";

export const CompareGraphStyled = styled.div`
  text {
    fill: ${({ theme }) => theme.contrast};
  }

  .clear-all {
    padding: 0px 10px;
    background-color: ${({ theme }) => theme.contrast};
    color: ${({ theme }) => theme.background};
    border-radius: 15px;
    font-size: 10px;
    display: flex;
    align-items: center;
    font-weight: bold;
    cursor: pointer;
  }

  .compare-info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    .badge-group {
      display: flex;
      gap: 10px;
    }
  }
`;
