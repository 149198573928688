import styled from "styled-components";

export const multiselectCss = {
  multiselectContainer: {
    border: "none",
    borderRadius: "20px !important",
  },
  searchBox: {
    borderRadius: "20px !important",
    paddingLeft: "20px",
    paddingRight: "20px",
    background: "white",
    border: "1px solid rgba(0, 0, 0, 0)",
    color: "rgb(51, 51, 51)",
    fontFamily: "Open Sans, sans-serif !important",
    fontSize: "14px !important",
    fontWeight: "400 !important",
    minHeight: "50px",
    lineHeight: "21px",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
  },
};

interface ModalStyle {
  background: string;
  display: string;
  flexDirection: "row" | "row-reverse" | "column" | "column-reverse";
  gap: string;
  paddingBottom: string;
}

export const modalStyle: ModalStyle = {
  background: "rgb(240, 240, 240)",
  display: "flex",
  flexDirection: "column",
  gap: "20px",
  paddingBottom: "20px",
};

export const modalFooterStyle = {
  paddingTop: "20px",
  paddingBottom: "20px",
};

const StyledCreateCampaign = styled.div`
  .container {
    background: blue;
  }

  .container-location {
    background: white;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0);
    box-sizing: border-box;
    color: rgb(51, 51, 51);
    font-family: "Open Sans", sans-serif !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    min-height: 50px;
    line-height: 21px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

export default StyledCreateCampaign;
