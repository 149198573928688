import { FILTER_INPUT } from "constants/types";

export enum FILTER_COLUMN {
  BUSINESS = "businessId",
  COUNTRY = "country",
  STATUS = "status",
  CREATED_AT = "created_at",
  UPDATED_AT = "updated_at",
  EMAIL = "email",
  ROLE = "role",
  PACKAGE = "package",
  TOTAL_CAMPAIGNS = "totalCampaign",
  USERNAME = "userName",
  WEBSITE = "website",
  DELETED = "deleted_at",
}

export interface IDateRange {
  startDate: Date;
  endDate: Date;
}

export const FILTER_COLUMN_TEXT = {
  // [FILTER_COLUMN.USERNAME]: "Username",
  [FILTER_COLUMN.BUSINESS]: "Business Type",
  [FILTER_COLUMN.COUNTRY]: "Country",
  [FILTER_COLUMN.CREATED_AT]: "Create Date",
  [FILTER_COLUMN.UPDATED_AT]: "Updated Date",
  [FILTER_COLUMN.EMAIL]: "Username, Email",
  [FILTER_COLUMN.PACKAGE]: "User Type",
  [FILTER_COLUMN.ROLE]: "Role",
  [FILTER_COLUMN.WEBSITE]: "Website",
  [FILTER_COLUMN.DELETED]: "User Status",
};

export const FILTER_COLUMN_ITEMS = [
  // { key: FILTER_COLUMN.USERNAME, text: FILTER_COLUMN_TEXT[FILTER_COLUMN.USERNAME], input: "text" },
  {
    key: FILTER_COLUMN.BUSINESS,
    text: FILTER_COLUMN_TEXT[FILTER_COLUMN.BUSINESS],
    input: FILTER_INPUT.MULTI_SELECT,
  },
  {
    key: FILTER_COLUMN.COUNTRY,
    text: FILTER_COLUMN_TEXT[FILTER_COLUMN.COUNTRY],
    input: FILTER_INPUT.MULTI_SELECT,
  },
  {
    key: FILTER_COLUMN.CREATED_AT,
    text: FILTER_COLUMN_TEXT[FILTER_COLUMN.CREATED_AT],
    input: FILTER_INPUT.DATE_RANGE,
  },
  {
    key: FILTER_COLUMN.UPDATED_AT,
    text: FILTER_COLUMN_TEXT[FILTER_COLUMN.UPDATED_AT],
    input: FILTER_INPUT.DATE_RANGE,
  },
  {
    key: FILTER_COLUMN.EMAIL,
    text: FILTER_COLUMN_TEXT[FILTER_COLUMN.EMAIL],
    input: FILTER_INPUT.TEXT,
  },
  {
    key: FILTER_COLUMN.PACKAGE,
    text: FILTER_COLUMN_TEXT[FILTER_COLUMN.PACKAGE],
    input: FILTER_INPUT.SELECT,
  },
  {
    key: FILTER_COLUMN.ROLE,
    text: FILTER_COLUMN_TEXT[FILTER_COLUMN.ROLE],
    input: FILTER_INPUT.SELECT,
  },
  // {
  //   key: FILTER_COLUMN.WEBSITE,
  //   text: FILTER_COLUMN_TEXT[FILTER_COLUMN.WEBSITE],
  //   input: FILTER_INPUT.TEXT,
  // },
  {
    key: FILTER_COLUMN.DELETED,
    text: FILTER_COLUMN_TEXT[FILTER_COLUMN.DELETED],
    input: FILTER_INPUT.SELECT,
  },
];
