import styled from "styled-components/macro";

export const PaymentResultStyled = styled.div`
  width: 500px;
  margin: 0 auto;
  padding: 100px 0;
  position: relative;

  .payment-result-icon {
    position: absolute;
    left: -200px;
  }

  .payment-result-message {
    .order-detail-id {
      font-size: ${({ theme }) => theme.size.xl};
      font-weight: ${({ theme }) => theme.weight.normal};
    }

    p {
      font-size: ${({ theme }) => theme.size.lg};
    }

    .title {
      color: ${({ theme }) => theme.color.primary};
    }

    .bold {
      font-weight: ${({ theme }) => theme.weight.bold};
    }
  }
`;

export const SpinnerWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
