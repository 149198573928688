import styled, { css } from "styled-components/macro";

export const DatePickerPopup = styled.div`
  background-color: ${({ theme }) => theme.background};
  padding: 10px;
  border-radius: 6px;
  flex-direction: column;
  display: flex;
  position: absolute;
  inset: 0px auto auto 0px;
  justify-content: space-around;
  font-size: 14px;
  color: rgb(33, 37, 41);
  margin-top: 10px;
  border: 1px solid ${({ theme }) => theme.dark};

  .button-wrapper {
    margin-top: 10px;
    display: flex;
    gap: 10px;
    justify-content: end;
  }
`;

export const TotalAmount = styled.span<{ color?: string }>`
  font-size: 18px;
  color: ${({ color, theme }) => color || theme.secondaryHighlight} !important;
  font-weight: 600;
`;

export const CustomBudget = styled.li<{ selected?: boolean }>`
  flex: 1.5;
  text-align: left;
  font-size: 9px;

  .budget-item {
    background: rgb(179, 179, 179);
    border: 0;
    color: rgb(66, 66, 66);
    font-weight: 400;
    height: 35px;
    width: 100%;
    border-radius: 6px;
    text-align: left;
  }

  ${({ selected }) =>
    selected &&
    css`
      .budget-item {
        background: ${({ theme }) => theme.color.primary};
        color: #ffffff;
      }
    `}
`;

export const BudgetItem = styled.li<{ selected?: boolean }>`
  flex-basis: 100%;
  flex: 1;
  position: relative;

  .budget-item {
    background: rgb(179, 179, 179);
    border: 0;
    color: rgb(66, 66, 66);

    font-weight: 400;
    height: 35px;
    width: 100%;
    border-radius: 6px;
  }
  ${({ selected }) =>
    selected &&
    css`
      .budget-item {
        background: ${({ theme }) => theme.color.primary};
        color: #ffffff;
      }
    `}

  &:first-child {
    &:before {
      content: "RECOMMENDED";
      font-size: 5px;
      background: ${({ theme }) => theme.color.gold};
      position: absolute;
      font-weight: bold;
      width: 70%;
      border-radius: 4px 4px 0 0;
      color: #000000;
      padding: 2px 5px;
      transform: translate(12px, -11px);
    }
  }
`;

export const CheckoutTable = styled.section`
  height: 100%;
  flex-basis: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;

  border-radius: 10px;

  .header-title {
    font-size: ${({ theme }) => theme.size.lg};
    color: ${({ theme }) => theme.contrast};
  }

  hr {
    border-color: lightslategray;
  }

  .applied-promo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
  }

  .promo {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    color: #00fe67;
  }

  .checkout-detail {
    padding: 25px;
    height: 100%;
    font-size: 12px;

    .campaign-budget {
      margin-bottom: 20px;

      .estimated-budget {
        font-weight: ${({ theme }) => theme.weight.bold};
        margin-top: 10px;
        color: #fe0099;
      }

      .budget-list {
        list-style: none;
        padding: 0;
        margin-top: 20px;
        display: flex;
        gap: 10px;
        justify-content: space-between;
        margin-bottom: 20px;
        flex-wrap: wrap;
      }

      .budget {
        background: ${({ theme }) => theme.lightBorder};
        height: 35px;
        padding: 0 15px;
        font-size: 14px;
        outline: none;
        border-radius: 6px;
        color: ${({ theme }) => theme.contrast};
        border: none;
      }
    }

    .time-period {
      margin-bottom: 20px;

      .campaign-period {
        color: ${({ theme }) => theme.contrast};
        cursor: pointer;
        height: 35px;
        background: ${({ theme }) => theme.lightBorder};
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        justify-content: start;
        align-items: center;
        font-size: 10px;
        padding: 0 20px;
        border-radius: 6px;
        justify-content: space-between;

        .campaign-range-required {
          font-size: 10px;
          color: ${({ theme }) => theme.text};
        }

        .calendar-icon {
          width: 15px;
          height: 15px;
          opacity: 0.5;

          path,
          line {
            stroke: ${({ theme }) => theme.contrast};
          }
        }
      }
    }

    .promo-section {
      display: flex;
      gap: 10px;
      align-items: start;

      .input-wrapper {
        width: 70%;
      }

      .button-wrapper {
        width: 30%;
      }

      .btn-primary {
        .button-state {
          width: 45px;
        }
      }
    }
  }
`;
