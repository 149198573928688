import { Formik } from "formik";
import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { axiosForReverseAds } from "axiosConfig";
import { updatePaymentStatus } from "store/actions/campaign.actions";
import {
  PAYMENT_GATEWAY_TEXT,
  ENUM_PAYMENT_STATUS,
  PAYMENT_STATUS_TEXT,
  ENUM_PAYMENT_GATEWAY,
} from "store/states/payments.state";
import { RootState } from "store/store";
import { handlingError } from "constants/utility/error";
import Button from "components/Button";
import DropdownSelect from "components/DropdownSelect";
import TextArea from "components/TextArea";
import { PaymentDetailStyled } from "./styled";
import validation from "../../../../../../schemas/edit-payment.schema";

interface IPaymentDetailProps extends PropsFromRedux {}

const mapStateToProps = ({ admin, sidebar, currentCampaign, payments }: RootState) => ({
  admin,
  sidebar,
  currentCampaign,
  payments,
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const PaymentDetail: React.FunctionComponent<IPaymentDetailProps> = ({
  sidebar,
  admin,
  dispatch,
  payments,
  currentCampaign,
}) => {
  const paymentInfo =
    currentCampaign.data?.payments?.find((payment) => payment.id === sidebar.id) ||
    payments.data.find((payment) => payment.id === sidebar.id);
  const [currentStatus, setStatus] = React.useState(paymentInfo?.state);
  const [logs, setLogs] = React.useState("");

  React.useEffect(() => {
    const fetchLogs = async () => {
      try {
        const { data } = await axiosForReverseAds.get(
          `admin/campaign/transaction/${paymentInfo?.id}`
        );
        setLogs(data.data);
      } catch (error: any) {
        handlingError(error);
      }
    };
    if (paymentInfo) {
      fetchLogs();
    }
  }, []);

  return paymentInfo && currentStatus ? (
    <PaymentDetailStyled>
      <h5>
        <b>Payment ID</b>
      </h5>
      <p>{paymentInfo.id}</p>
      <div>
        <h5>
          <b>Amount</b>
        </h5>
        <p>{`$${paymentInfo.amount}`}</p>
      </div>

      <>
        <h5>
          <b>Payment Status</b>
        </h5>
        <p>{PAYMENT_STATUS_TEXT[paymentInfo.state]}</p>
        <h5>
          <b>Payment Type</b>
        </h5>
        <p>{PAYMENT_GATEWAY_TEXT[paymentInfo.paymentGateway]}</p>
      </>

      <div>
        <div className="input-wrapper">
          <h5>
            <b>Transaction Number</b>
          </h5>
          <p>{paymentInfo.id}</p>
        </div>

        {logs && (
          <div className="input-wrapper">
            <h5>
              <b>LOGS</b>
            </h5>
            <TextArea height={300} readonly defaultValue={JSON.stringify(logs, undefined, 4)} />
          </div>
        )}
      </div>
      {/* )} */}
    </PaymentDetailStyled>
  ) : null;
};

export default connector(PaymentDetail);
