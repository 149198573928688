import React from "react";
import { BetaAlertModal } from "./styled";
import { Modal } from "react-bootstrap";
import { hideBetaAlert } from "store/actions/popup.actions";
import { RootState } from "store/store";
import { connect, ConnectedProps, useDispatch } from "react-redux";

interface IBetaAlertProps extends PropsFromRedux {
  theme: "light" | "dark";
}

const mapStateToProps = ({ popup }: RootState) => ({
  popup,
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const BetaAlert: React.FunctionComponent<IBetaAlertProps> = ({ popup, theme }) => {
  const dispatch = useDispatch();

  const onExit = () => {
    dispatch(hideBetaAlert());
  };

  const message = `The Vatic AI Keyword web app is now in BETA, currently providing keyword and
  competitive research functionality to help you optimize your campaign targeting, Limited
  features are being rolled out in phases, enabling us to optimize development and launch
  deeper functionality in the coming months.

  If you are having a problem while using our product or would like to give your feedback to
  `;

  return (
    <BetaAlertModal
      $currentTheme={theme}
      keyboard
      size="lg"
      centered
      show={popup.showBetaAlert.show}
      onHide={onExit}
      onExited={onExit}
      data-testid="beta-alert-modal"
    >
      <Modal.Header>
        <Modal.Title>
          <span data-testid="close-alert" className="close-alert" onClick={onExit}>
            Close
          </span>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="beta-icon" />
        <p className="beta-content">
          <span className="beta-message">
            {message}
            <span>
              our team, please contact{" "}
              <b data-testid="support-email" className="support-email">
                support@vaticai.com
              </b>
            </span>
          </span>
        </p>
      </Modal.Body>
    </BetaAlertModal>
  );
};

export default connector(BetaAlert);
