import React from "react";

const Step2Icon = ({ className }: { className?: string }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 62 62" className={className}>
      <defs>
        <style>
          {
            ".icon-svg{fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}"
          }
        </style>
      </defs>
      <g id="Layer_2" data-name="Layer 2">
        <g id="details">
          <path className="icon-svg" d="M43.26,27.08a13.27,13.27,0,1,1-8.34-8.23" />
          <path className="icon-svg" d="M38.63,30.92a7.92,7.92,0,1,1-8.42-7.38" />
          <line className="icon-svg" x1={50.04} y1={12.78} x2={30.87} y2={31.02} />
          <polyline className="icon-svg" points="46.14 16.34 45.62 11.98 41.03 16.4 41.4 21.11" />
          <polyline className="icon-svg" points="46.34 16.54 50.68 17.23 46.1 21.65 41.4 21.11" />
          <circle className="icon-svg" cx={31} cy={31} r={30} />
        </g>
      </g>
    </svg>
  );
};

export default Step2Icon;
