import { put, call, takeEvery, all, fork } from "redux-saga/effects";
import * as actions from "../action-types/payments.types";
import {
  getPaymentsRequest,
  getPaymentsSuccess,
  getPaymentsFailure,
} from "../actions/payments.actions";
import api from "../../services/api";
import { handlingError } from "../../constants/utility/error";

function* onLoadPayments(action: actions.GetPaymentsAction) {
  try {
    yield put(getPaymentsRequest());
    const { data, total } = yield call(api.admin.payments.getPayments, action.payload);
    yield put(getPaymentsSuccess(data, total));
  } catch (error: any) {
    handlingError(error);
    yield put(getPaymentsFailure(error.response.data.message));
  }
}

function* watchonLoadPayments() {
  yield takeEvery(actions.GET_PAYMENTS, onLoadPayments);
}

export default function* adminSaga() {
  yield all([fork(watchonLoadPayments)]);
}
