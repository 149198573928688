import { Formik } from "formik";
import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { updateUser } from "store/actions/users.actions";
import { RootState } from "store/store";
import { BUSINESS_TYPE } from "constants/advertiser";
import { updateUserSchema } from "schemas/user.schema";
import Button from "components/Button";
import Checkbox from "components/Checkbox";
import DropdownSelect from "components/DropdownSelect";
import Input from "components/Input";
import { EditUserFormStyled } from "./styled";
import { TEXT_ROLE } from "store/states/user.state";

const mapStateToProps = ({ keywordLocations, users, sidebar }: RootState) => ({
  keywordLocations,
  users,
  sidebar,
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface IEditUserFormProps extends PropsFromRedux {}

const EditUserForm: React.FunctionComponent<IEditUserFormProps> = ({
  keywordLocations,
  users,
  sidebar,
  dispatch,
}) => {
  const currentUser = users.data.find((user) => user.id === sidebar.id);
  return currentUser ? (
    <EditUserFormStyled>
      <Formik
        validationSchema={updateUserSchema}
        onSubmit={(values) => {
          dispatch(updateUser({ ...values, id: currentUser.id }));
        }}
        initialValues={{
          fullName: currentUser.userName,
          email: currentUser.email,
          role: currentUser.role,
        }}
      >
        {({ touched, errors, values, setFieldValue, handleChange, handleBlur, handleSubmit }) => {
          return (
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <h6>Full Name</h6>
                <Input
                  left={10}
                  onChange={handleChange}
                  name="fullName"
                  type="text"
                  value={values.fullName}
                  placeholder="Full Name"
                  onBlur={handleBlur}
                  error={errors.fullName}
                  touched={touched.fullName}
                />
              </div>

              <div className="form-group">
                <h6>Email</h6>
                <Input
                  left={10}
                  onChange={handleChange}
                  name="email"
                  type="text"
                  value={values.email}
                  placeholder="Email"
                  onBlur={handleBlur}
                  error={errors.email || users.error}
                  touched={touched.email}
                />
              </div>
              {values.role && (
                <div className="form-group">
                  <DropdownSelect
                    width={50}
                    error={touched.role && errors.role}
                    label="Role"
                    placeholder="Please Select"
                    options={[
                      {
                        value: "admin",
                        text: "Admin",
                      },
                      {
                        value: "super_admin",
                        text: "Super Admin",
                      },
                      {
                        value: "user",
                        text: "User",
                      },
                    ]}
                    searchBy="name"
                    labelField="text"
                    valueField="value"
                    onChange={(role: Array<{ text: string; value: string }>) => {
                      setFieldValue("role", role[0].value);
                    }}
                    values={[{ value: values.role, text: TEXT_ROLE[values.role] }]}
                    name="role"
                    noDataRenderer={() => (
                      <div className="noResultsFound">
                        {keywordLocations.loading ? "Loading..." : "No results found"}
                      </div>
                    )}
                  />
                </div>
              )}

              <Button loading={users.updateUserLoading} type="submit">
                Submit
              </Button>
            </form>
          );
        }}
      </Formik>
    </EditUserFormStyled>
  ) : null;
};

export default connector(EditUserForm);
