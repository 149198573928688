import styled, { css } from "styled-components/macro";
import { Modal } from "react-bootstrap";

const empty = require("assets/images/empty-graphics/competitor-graphic.svg").default;

export const CompetitorPopupModal = styled(Modal)<{ $blur?: boolean }>`
  /* background-color: transparent;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  border-radius: 8px; */
  ${({ $blur }) =>
    $blur &&
    css`
      .modal-content {
        &:after {
          position: absolute;
          content: "";
          width: 100%;
          height: 100%;
          background: #000000;
          border-radius: 10px;
          top: 0;
          left: 0;
          opacity: 0.5;
          z-index: 1;
        }
      }
    `}

  .clear-all {
    padding: 0px 10px;
    background-color: ${({ theme }) => theme.contrast};
    border-radius: 15px;
    font-size: 10px;
    display: flex;
    align-items: center;
    font-weight: bold;
    cursor: pointer;
  }

  .visual-reports {
    .compare-info {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;

      .badge-group {
        display: flex;
        gap: 10px;
      }
    }
  }

  .modal-content {
    padding: 0 20px;
    background-color: ${({ theme }) => theme.modalBackground};
    border-radius: 10px;
    min-height: 500px;

    .visual-result {
      margin-top: 20px;

      .bottom-bar-actions {
        margin-top: 30px;
        margin-left: 25px;
      }
    }
  }

  .modal-header {
    padding: 30px;
    padding-bottom: 0;
    border-bottom: none;

    /* .modal-title {
      background-color: rgba(65, 96, 234, 0.15);
      border-radius: 8px;
      font-size: 0.88em;
      padding: 8px 15px;
      color: ${({ theme }) => theme.color.primary};
      font-weight: 300 !important;
      text-transform: capitalize;
    } */

    .modal-title {
      color: ${({ theme }) => theme.contrast};
      font-size: 18px;

      .keyword-tool-title {
        display: flex;

        .tool-title {
          font-weight: bold;
          padding: 0px 20px 0 0px;
          border-right: 1px solid ${({ theme }) => theme.contrast};
          margin-right: 20px;
        }

        .tool-description {
          font-size: 12px;
          white-space: pre;
        }
      }
    }
  }

  .modal-body {
    padding: 20px 30px;

    .empty {
      background-repeat: no-repeat;
      background-image: url(${empty});
      background-size: 300px;
      width: auto;
      height: 500px;
      background-position: center;
    }

    .search-bar {
      display: flex;
      gap: 5px;
      flex-direction: column;
      color: white;

      .keyword-input {
        width: 100%;
        position: relative;

        > svg,
        path {
          position: absolute;
          fill: ${({ theme }) => theme.dark};
          top: 15px;
          right: 15px;
        }
      }
    }

    //

    display: flex;
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 20px;

    .card-title {
      font-size: 14px;
      margin-bottom: 20px;
      color: ${({ theme }) => theme.contrast};
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .table-title {
      margin-left: 5px;
      font-size: 12px;
      color: ${({ theme }) => theme.contrast};
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .domain-info {
      display: flex;
      justify-content: space-between;
      align-items: start;
      margin-right: 15px;
    }

    .grid {
      padding-right: 15px;
      overflow-y: auto;
      overflow-x: hidden;
      max-height: 600px;
      position: relative;
      margin-top: 30px;
      font-size: 14px;
      display: grid;
      grid-gap: 20px;
      grid-template-rows: minmax(265px, 1fr) 180px 160px 60px 160px 230px;
      grid-auto-columns: calc(50% - 10px);

      .domain-section {
        background: ${({ theme }) => theme.inputDark};
        grid-row: 2/4;
        grid-column: 2/3;
        padding: 20px 0 !important;

        .domain-info {
          padding: 0 20px !important;
        }
      }

      .engagement-section {
        background: ${({ theme }) => theme.inputDark};
        grid-row: 2/3;
        grid-column: 1/2;
      }

      .top-keyword-section {
        background: ${({ theme }) => theme.inputDark};
        grid-row: 6/7;
        grid-column: 2/3;
      }

      .top-keywords-chart {
        display: flex;
        justify-content: center;
      }

      .traffic-section {
        background: ${({ theme }) => theme.inputDark};
        grid-column: 2/3;
        grid-row: 4/6;
      }

      .table-group {
        display: flex;
        grid-column: 1/3;
        background: ${({ theme }) => theme.inputDark};
        border-radius: 10px;
        padding-top: 20px;
        padding-bottom: 10px;

        .bottom-bar {
          padding: 0 5px;
          margin-top: 10px;
        }
      }

      .top-countries-section {
        background: ${({ theme }) => theme.inputDark};
        grid-row: 3/5;
        grid-column: 1/2;
      }

      .top-paid-table {
        width: 50%;
        padding-left: 15px;
        padding-right: 15px;

        .paid-keywords-wrapper {
          position: relative;
        }
      }

      .top-organic-table {
        width: 50%;
        padding-left: 15px;
        padding-right: 15px;
        border-left: 1px solid ${({ theme }) => theme.checkboxBorder};

        .top-organic-wrapper {
          position: relative;
        }
      }

      > div {
        /* padding: 25px; */
        position: relative;
      }

      .test,
      .top-countries-section,
      .top-keyword-section,
      .traffic-section,
      .engagement-section,
      .domain-section {
        padding: 20px;
        border-radius: 10px;
        font-size: 12px;
      }

      .test,
      .result-table,
      .top-countries-section,
      .top-keyword-section,
      .traffic-section,
      .engagement-section,
      .domain-section {
        .spinner-wrapper {
          height: auto;
          width: auto;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-15px, 0);
        }
      }
    }

    .keyword-tool-title {
      font-size: 11px;
    }

    .page-title {
      font-size: 20px;
      font-weight: bold;
    }

    .stats-spinner {
      margin: 20px auto 0;
      display: block;
    }

    .default-state-text {
      color: #adadad;
      height: 200px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0;
      width: 100%;
    }

    .recharts-text {
      fill: ${({ theme }) => theme.contrast};
    }

    .attributes {
      border-bottom: 1px solid ${({ theme }) => theme.keywordsTableBorder};
      padding-bottom: 10px;

      &:last-child {
        border-bottom: none;
      }
    }

    .attributes .icon,
    .attributes .label,
    .attributes .value {
      color: ${({ theme }) => theme.contrast};
      fill: ${({ theme }) => theme.contrast};
    }

    .attributes {
      display: flex;
      justify-content: space-between;

      .icon {
        width: 17px;
        height: 17px;
        display: inline-block;
        margin-right: 15px;
      }

      .label {
        display: inline-block;
      }

      .value {
        text-align: right;
      }
    }

    .traffic-source-chart {
      position: relative;
      height: 300px;

      .chartTooltipWrapper {
        display: inline-block;
        background: ${({ theme }) => theme.contrast};
        border-radius: 8px;
        box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
        padding: 0 20px;

        .chartValue {
          padding: 15px 0;
        }
      }
    }

    .total-visits {
      color: ${({ theme }) => theme.secondary100};

      .visits {
        margin-right: 5px;
        font-size: 12px;
        font-weight: bold;
      }

      .visits-label {
        font-size: 12px;
        letter-spacing: 0px;
      }
    }

    .brand {
      margin-right: 15px;
    }

    .brandIcon {
      width: 17px;
      margin-right: 8px;
      display: inline-block;
    }

    .brandText {
      display: inline-block;
      vertical-align: middle;
    }

    .topKeywordsTable td {
      vertical-align: middle;
      white-space: nowrap;
    }

    .campaignButton {
      font-weight: 400;
      font-family: "Open Sans";
    }

    .visitsPerMonth {
      font-size: 1.125em;
      font-weight: bold;
      margin-right: 5px;
    }

    .domainTraffic {
      color: ${({ theme }) => theme.link};
    }

    .traffic-label {
      margin: 0;
      font-size: 0.8em;
      text-align: end;
      color: ${({ theme }) => theme.contrast};
    }

    .domainChartWrapper {
      margin-top: 20px;

      .recharts-surface {
        overflow: inherit;
      }
    }

    .keywordsContent {
      width: 50%;
      display: flex;
      justify-content: start;
      position: relative;
      height: auto;
      align-items: center;
      color: ${({ theme }) => theme.contrast};

      .keywords {
        width: 100%;
      }
    }

    .keywordsChartWrapper {
      width: 50%;
      display: flex;
      justify-content: end;
    }

    .keywordWrapper {
      margin-bottom: 15px;
      display: flex;
      align-items: center;

      .keywordColor {
        height: 10px;
        width: 10px;
        margin-right: 10px;
        border: 1px solid white;
      }

      .first {
        background: rgb(153, 0, 255);
      }

      .second {
        background: rgb(255, 0, 153);
      }
    }

    .campaignsMenu {
      max-width: 200px;

      .dropdown-item {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .domainLink {
      font-size: 14px;
      color: ${({ theme }) => theme.contrast};
      text-decoration: none;
    }
    @media screen and (max-width: 767px) {
      .domainTraffic {
        text-align: center;
        margin-top: 20px;
      }

      .domainLink {
        text-align: center;
        display: block;
        width: 100%;
      }
    }
  }

  /* .modalActionButtons {
    border-top: 0;
    padding: 20px 0 10px;

    .keywordListPopupClose {
      color: #525252;
      font-size: 1em;
      font-weight: 400;
      text-align: center;
      border-radius: 4px;
      background-color: #dcdcdc;
      border: none;
      margin-right: 10px;
      width: 140px;
      height: 45px;
      &:hover {
        opacity: 0.65;
      }
    }

    .keywordListPopupClose:active {
      background-color: #dcdcdc;
      color: #525252;
    }

    .save-button,
    .close-button {
      margin-left: 20px;
      min-width: 140px;
    }
  } */
`;
