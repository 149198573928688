import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "store/store";
import ListTable from "components/ListTable";
import { ManageUserPage } from "./styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { openSlideModal } from "store/actions/sidebar.actions";
import SlideModal from "components/SlideModal";
import DropdownButton from "components/DropdownButton";
import { Dropdown } from "react-bootstrap";
import EditUserForm from "./EditUserForm";
import { ENUM_USER_ROLES, IUserAuthentication, TEXT_ROLE } from "store/states/user.state";
import { useSortBy, useTable, Column, usePagination } from "react-table";
import Pagination from "components/Pagination";
import { deleteUsers, getUsers } from "store/actions/users.actions";
import { useNavigate } from "react-router-dom";
import UserFilter, { IFilterColumn } from "components/UserFilter";
import { displayDate, formatDate } from "constants/utility/date";
import Spinner from "components/Spinner";

export const FREEZE_COLUMNS = ["userName", "email"];

const columns: Array<Column<IUserAuthentication>> = [
  { Header: "Username", accessor: "userName" },
  { Header: "Email", accessor: "email" },
  { Header: "Campaigns", accessor: "totalCampaign" },
  // { Header: "Country", accessor: "country" },
  { Header: "User Type", accessor: "package" },
  {
    Header: "Created Date",
    id: "created_at",
    accessor: ({ created_at }) => (created_at ? displayDate(new Date(created_at)) : ""),
  },
  {
    Header: "Updated Date",
    id: "updated_at",
    accessor: ({ updated_at }) => (updated_at ? displayDate(new Date(updated_at)) : ""),
  },
  // {
  //   Header: "Business Type",
  //   id: "businessId",
  //   accessor: ({ businessId }) => BUSINESS_ID_REF[businessId],
  // },
  // { Header: "Website", accessor: "website" },
  { Header: "Role", id: "role", accessor: ({ role }) => (role ? TEXT_ROLE[role] : "") },
];

const mapStateToProps = ({ users, user }: RootState) => ({
  users,
  user,
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface INewCampaignProps extends PropsFromRedux {}

const ManageUsers: React.FunctionComponent<INewCampaignProps> = ({ dispatch, users, user }) => {
  const navigate = useNavigate();

  const [currentFilters, setCurrentFilters] = React.useState<IFilterColumn | undefined>(undefined);
  const totalFreezeColumns = FREEZE_COLUMNS.length;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    // allColumns,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex, sortBy },
  } = useTable(
    {
      manualSortBy: true,
      manualPagination: true,
      columns,
      data: users.data,
      pageCount: Math.ceil(users.total / 10),
      initialState: { pageSize: 10, pageIndex: 0 },
    },
    useSortBy,
    usePagination
  );

  React.useEffect(() => {
    const filterObj = {
      deleted_at: currentFilters?.deleted_at,
      created_from: currentFilters?.created_at && formatDate(currentFilters?.created_at.startDate),
      created_to: currentFilters?.created_at && formatDate(currentFilters?.created_at.endDate),
      name_email: currentFilters?.email,
      role: currentFilters?.role,
      package: currentFilters?.package,
      // totalCampaign:currentFilters?. number
      updated_from: currentFilters?.updated_at && formatDate(currentFilters?.updated_at.startDate),
      updated_to: currentFilters?.updated_at && formatDate(currentFilters?.updated_at.endDate),
      page: pageIndex + 1,
      asc: sortBy.length ? (sortBy[0].desc ? "" : sortBy[0].id) : "",
      desc: sortBy.length ? (sortBy[0].desc ? sortBy[0].id : "") : "",
    };

    dispatch(getUsers(filterObj));
  }, [pageIndex]);

  React.useEffect(() => {
    if (sortBy.length) {
      const filterObj = {
        deleted_at: currentFilters?.deleted_at,
        name_email: currentFilters?.email,
        role: currentFilters?.role,
        package: currentFilters?.package,

        created_from:
          currentFilters?.created_at && formatDate(currentFilters?.created_at.startDate),
        created_to: currentFilters?.created_at && formatDate(currentFilters?.created_at.endDate),
        updated_from:
          currentFilters?.updated_at && formatDate(currentFilters?.updated_at.startDate),
        updated_to: currentFilters?.updated_at && formatDate(currentFilters?.updated_at.endDate),
        page: pageIndex + 1,
        asc: sortBy[0].desc ? "" : sortBy[0].id,
        desc: sortBy[0].desc ? sortBy[0].id : "",
      };
      gotoPage(0);
      dispatch(getUsers(filterObj));
    }
  }, [sortBy]);

  React.useEffect(() => {
    if (currentFilters) {
      const filterObj = {
        deleted_at: currentFilters?.deleted_at,
        name_email: currentFilters?.email,
        role: currentFilters?.role,
        package: currentFilters?.package,
        created_from:
          currentFilters?.created_at && formatDate(currentFilters?.created_at.startDate),
        created_to: currentFilters?.created_at && formatDate(currentFilters?.created_at.endDate),
        updated_from:
          currentFilters?.updated_at && formatDate(currentFilters?.updated_at.startDate),
        updated_to: currentFilters?.updated_at && formatDate(currentFilters?.updated_at.endDate),
        page: pageIndex + 1,
        asc: sortBy.length ? (sortBy[0].desc ? "" : sortBy[0].id) : "",
        desc: sortBy.length ? (sortBy[0].desc ? sortBy[0].id : "") : "",
      };
      gotoPage(0);
      dispatch(getUsers(filterObj));
    }
  }, [currentFilters]);

  const applyFilters = (filters: IFilterColumn) => {
    setCurrentFilters((prev) => ({ ...prev, ...filters }));
  };

  return (
    <ManageUserPage>
      <div>
        <div>
          <h6 className="keyword-tool-title">Admin Zone</h6>
          <h2 className="page-title">Manage Users</h2>
        </div>
        <UserFilter currentFilters={currentFilters} onFilter={applyFilters} />
      </div>

      {!users.loading ? (
        <div className="table-wrapper">
          <div className="table-wrapper-inner">
            {users.data && (
              <div className="table-responsive">
                <div className="freeze">
                  <ListTable {...getTableProps()}>
                    <thead>
                      {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map(
                            (column, index) =>
                              index < totalFreezeColumns && (
                                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                  {column.render("Header")}

                                  <span>
                                    {column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""}
                                  </span>
                                </th>
                              )
                          )}
                          <th />
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {page.map((row, rowIndex) => {
                        prepareRow(row);
                        return (
                          <tr
                            className={row.original.deleted_at ? "disabled" : ""}
                            {...row.getRowProps()}
                          >
                            {row.cells.map((cell, colIndex) => {
                              return (
                                colIndex < totalFreezeColumns && (
                                  <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                                )
                              );
                            })}
                            <td>
                              {row.original.deleted_at ? (
                                <span>Deleted</span>
                              ) : (
                                <DropdownButton transparent>
                                  <Dropdown.Toggle>
                                    <span className="actions-button">
                                      <FontAwesomeIcon icon="ellipsis-h" />
                                    </span>
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    {user.role === ENUM_USER_ROLES.SUPER_ADMIN && (
                                      <Dropdown.Item
                                        onClick={() => {
                                          dispatch(openSlideModal(row.original.id));
                                        }}
                                      >
                                        Edit User
                                      </Dropdown.Item>
                                    )}
                                    <Dropdown.Item
                                      onClick={() => {
                                        // navigate(`/${row.original.email}`);
                                        navigate(
                                          `/admin/campaigns?owner_email=${row.original.email}`
                                        );
                                      }}
                                    >
                                      View Campaign
                                    </Dropdown.Item>
                                    {user.role === ENUM_USER_ROLES.SUPER_ADMIN && (
                                      <Dropdown.Item
                                        onClick={() => {
                                          const confirm = window.confirm(
                                            "Please confirm to delete this user"
                                          );
                                          if (confirm) {
                                            dispatch(
                                              deleteUsers([row.original.id], {
                                                deleted_at: currentFilters?.deleted_at,
                                                name_email: currentFilters?.email,
                                                role: currentFilters?.role,
                                                package: currentFilters?.package,

                                                created_from:
                                                  currentFilters?.created_at &&
                                                  formatDate(currentFilters?.created_at.startDate),
                                                created_to:
                                                  currentFilters?.created_at &&
                                                  formatDate(currentFilters?.created_at.endDate),
                                                updated_from:
                                                  currentFilters?.updated_at &&
                                                  formatDate(currentFilters?.updated_at.startDate),
                                                updated_to:
                                                  currentFilters?.updated_at &&
                                                  formatDate(currentFilters?.updated_at.endDate),
                                                page: pageIndex + 1,
                                                asc: sortBy.length
                                                  ? sortBy[0].desc
                                                    ? ""
                                                    : sortBy[0].id
                                                  : "",
                                                desc: sortBy.length
                                                  ? sortBy[0].desc
                                                    ? sortBy[0].id
                                                    : ""
                                                  : "",
                                              })
                                            );
                                          }
                                        }}
                                      >
                                        Delete User
                                      </Dropdown.Item>
                                    )}
                                  </Dropdown.Menu>
                                </DropdownButton>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </ListTable>
                </div>
                <div className="scrollable">
                  <ListTable {...getTableProps()}>
                    <thead>
                      {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map(
                            (column, index) =>
                              index >= totalFreezeColumns && (
                                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                  {column.render("Header")}

                                  <span>
                                    {column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""}
                                  </span>
                                </th>
                              )
                          )}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {page.map((row, rowIndex) => {
                        prepareRow(row);
                        return (
                          <tr
                            className={row.original.deleted_at ? "disabled" : ""}
                            {...row.getRowProps()}
                          >
                            {row.cells.map((cell, colIndex) => {
                              return (
                                colIndex >= totalFreezeColumns && (
                                  <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                                )
                              );
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </ListTable>
                </div>
              </div>
            )}
            <Pagination
              canPreviousPage={canPreviousPage}
              canNextPage={canNextPage}
              pageOptions={pageOptions}
              pageCount={pageCount}
              gotoPage={gotoPage}
              nextPage={nextPage}
              previousPage={previousPage}
              pageIndex={pageIndex}
            />
          </div>
        </div>
      ) : (
        <Spinner />
      )}

      <SlideModal content={<EditUserForm />} />
    </ManageUserPage>
  );
};

export default connector(ManageUsers);
