import * as React from "react";

const CalendarIcon = ({ className }: { className?: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.08 19.5" className={className}>
    <defs>
      <style />
    </defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_2-2" data-name="Layer 2">
        <path
          className="icon-svg"
          d="M16.55,2.22A2.74,2.74,0,0,1,19.28,5V16a2.73,2.73,0,0,1-2.73,2.73h-13A2.73,2.73,0,0,1,.8,16V5A2.74,2.74,0,0,1,3.53,2.22h13m0-.8h-13A3.54,3.54,0,0,0,0,5V16A3.54,3.54,0,0,0,3.53,19.5h13A3.53,3.53,0,0,0,20.08,16V5a3.54,3.54,0,0,0-3.53-3.54Z"
        />
        <line className="icon-svg" x1={4.98} y1={3.2} x2={4.98} y2={0.4} />
        <line className="icon-svg" x1={14.92} y1={3.2} x2={14.92} y2={0.4} />
        <line className="icon-svg" x1={3.19} y1={6.18} x2={16.87} y2={6.18} />
      </g>
    </g>
  </svg>
);

export default CalendarIcon;
