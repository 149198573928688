import { FILTER_INPUT } from "constants/types";

export enum FILTER_COLUMN {
  USERNAME = "userName",
  NAME = "name",
  STATUS = "status",
  CREATED_AT = "created_at",
  START_DATE = "startDate",
  END_DATE = "endDate",
  EMAIL = "owner_email",
  SOURCE = "source",
}

export interface IDateRange {
  startDate: Date;
  endDate: Date;
}

export const FILTER_COLUMN_TEXT = {
  [FILTER_COLUMN.USERNAME]: "User Name",
  [FILTER_COLUMN.NAME]: "Campaign Name",
  [FILTER_COLUMN.SOURCE]: "Source",
  [FILTER_COLUMN.STATUS]: "Campaign Status",
  [FILTER_COLUMN.CREATED_AT]: "Create Date",
  [FILTER_COLUMN.START_DATE]: "Start Date",
  [FILTER_COLUMN.END_DATE]: "End Date",
  [FILTER_COLUMN.EMAIL]: "User Email",
};

export const FILTER_COLUMN_ITEMS = [
  { key: "owner_email", text: "User Email", input: FILTER_INPUT.TEXT },
  { key: "userName", text: "User Name", input: FILTER_INPUT.TEXT },
  { key: "name", text: "Campaign Name", input: FILTER_INPUT.TEXT },
  { key: "status", text: "Campaign Status", input: FILTER_INPUT.SELECT },
  { key: "created_at", text: "Create Date", input: FILTER_INPUT.DATE_RANGE },
  { key: "startDate", text: "Start Date", input: FILTER_INPUT.DATE_RANGE },
  { key: "endDate", text: "End Date", input: FILTER_INPUT.DATE_RANGE },
];
