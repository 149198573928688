import styled from "styled-components";

export const InvoiceListStyled = styled.div`
  .invoice-title {
    color: ${({ theme }) => theme.color.green};
    font-weight: bold;
    font-size: 14px;
  }

  .invoice-table {
    font-size: 12px;

    .table-header {
      height: 30px;
      border-bottom: 1px solid ${({ theme }) => theme.dark};
      display: flex;
      align-items: center;
      justify-content: space-between;

      > span {
        &:nth-child(1) {
          width: 40%;
        }

        &:nth-child(2) {
          width: 40%;
        }

        &:nth-child(3) {
          width: 20%;
          text-align: center;
        }
      }
    }

    .table-body {
      color: ${({ theme }) => theme.text};

      .table-row {
        justify-content: space-between;
        display: flex;
        align-items: center;
        height: 30px;
        border-bottom: 1px solid ${({ theme }) => theme.dark};

        > span {
          &:nth-child(1) {
            width: 40%;
          }

          &:nth-child(2) {
            width: 40%;
          }

          &:nth-child(3) {
            width: 20%;
            text-align: center;
          }
        }
      }
    }
  }

  svg,
  path,
  line,
  polyline {
    stroke: ${({ theme }) => theme.text};
    width: 15px;
    height: 15px;
  }
`;
