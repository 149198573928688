import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Input from "components/Input";
import Button from "components/Button";
import { event } from "cypress/types/jquery";
import { axiosForReverseAds } from "axiosConfig";
import { toast } from "react-toastify";
import StyledStoreSignal from "./styled";

const StoreSignal: React.FC = () => {
  const location = useLocation();
  const signals = location.state?.keywords || "";
  const locations = location.state?.location || "";
  const language = location.state?.language || "";
  const fromReq = location.state?.from || "";

  const navigate = useNavigate();

  const [saved, setSaved] = useState(1);

  const [name, setName] = useState("");

  const save = async () => {
    if (name === "") {
      toast.error("You must fill name field", { autoClose: 1000 });
      return;
    }
    setSaved(1);
    const storedUser_id = localStorage.getItem("user_id");
    let user_id;
    if (storedUser_id) {
      user_id = parseInt(storedUser_id, 10);
    }
    const requestData = {
      user_id: user_id,
      signal_name: name,
      keywords: signals,
      location: locations,
      language: language,
    };
    try {
      const response = await axiosForReverseAds.post("add_signal", requestData);
      if (response.data.status == "success") {
        setSaved(2);
        try {
          localStorage.removeItem("items");
          localStorage.removeItem("keywordTitle");
          localStorage.removeItem("selectedLocation");
          localStorage.removeItem("selectedLanguage");
        } catch {
          console.log("unable to remove stored data");
        }
      } else setSaved(3);
    } catch (e) {
      setSaved(3);
    }
  };

  const goBack = () => {
    // navigate(-1);
    if (fromReq == "suggested") navigate("/app/keywords/suggested", { state: { load: 1 } });
    if (fromReq == "competitor") navigate("/app/keywords/competitor", { state: { load: 2 } });
  };

  return (
    <StyledStoreSignal>
      <div className="container-save">
        {saved == 1 ? (
          <div className="container-save-child">
            <div className="container-save-h">Confirm Save</div>
            <div className="container-save-p">Enter signal name</div>
            <div className="container-save-name">
              <Input
                height={50}
                left={25}
                placeholder="Signal Name"
                noBorder
                black
                value={name}
                onChange={(event: any) => {
                  setName(event.target.value);
                }}
              />
            </div>
            <div className="container-save-btns">
              <Button color="purple" width={150} onClick={goBack}>
                Back
              </Button>
              <Button color="gold" width={150} onClick={save}>
                Save
              </Button>
            </div>
          </div>
        ) : saved == 2 ? (
          <div className="container-success">
            <div className="success-img" />
            <div>Your Signal has been saved successfully</div>
          </div>
        ) : (
          <div>
            <div className="container-error">
              <div className="error-img" />
              <div>Unable to save your signal</div>
            </div>
          </div>
        )}
      </div>
    </StyledStoreSignal>
  );
};

export default StoreSignal;
