import React from "react";
import { AccordionStyled, AccordionToggle, AccordionContent } from "./styled";

interface IAccordionProps {
  trigger: React.ReactNode | JSX.Element;
  content: React.ReactNode | JSX.Element[] | JSX.Element | null;
  onClick?: () => void;
  autoHide?: boolean;
  noBorder?: boolean;
  autoOpen?: boolean;
}
const Accordion: React.FunctionComponent<IAccordionProps> = ({
  trigger,
  content,
  onClick,
  autoHide,
  noBorder,
  autoOpen,
}) => {
  const [contentHeight, setContentHeight] = React.useState(0);
  const ref = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (autoHide) {
      setContentHeight(0);
    }
  }, [autoHide]);

  React.useEffect(() => {
    if (autoOpen) {
      setContentHeight((prev) => (prev ? 0 : ref.current?.scrollHeight || 0));
    }
  }, []);

  return (
    <AccordionStyled noBorder={noBorder}>
      <AccordionToggle
        className="accordion-toggle"
        active={contentHeight > 0}
        onClick={() => {
          onClick && onClick();
          if (!autoHide) {
            setContentHeight(ref.current?.scrollHeight || 0);
          } else {
            setContentHeight((prev) => (prev ? 0 : ref.current?.scrollHeight || 0));
          }
        }}
      >
        <span className="trigger-element">{trigger}</span>
      </AccordionToggle>

      <AccordionContent
        className="accordion-content"
        ref={ref}
        active={contentHeight > 0}
        contentHeight={contentHeight}
        autoHide={autoHide}
      >
        {content}
      </AccordionContent>
    </AccordionStyled>
  );
};

Accordion.defaultProps = {
  noBorder: true,
};

export default Accordion;
