import React from "react";
import { CloseButtonStyled } from "./styled";

interface ICloseButtonProps {
  top?: number;
  right?: number;
  onClick?: () => void;
  dark?: boolean;
}

const CloseButton: React.FunctionComponent<ICloseButtonProps> = ({ top, right, onClick, dark }) => {
  return (
    <CloseButtonStyled
      data-testid="close-button"
      dark={dark}
      onClick={onClick}
      top={top}
      right={right}
    />
  );
};

export default CloseButton;
