import React from "react";
import { Modal } from "react-bootstrap";
import { connect, ConnectedProps, useDispatch } from "react-redux";
import { hideCampaignDetailPopup } from "store/actions/popup.actions";
import { RootState } from "store/store";
import { CampaignPopupModal } from "./styled";
import CloseButton from "components/CloseButton";
import Spinner from "components/Spinner";
import CampaignOverview from "components/CampaignOverview";
import CampaignPerformance from "components/CampaignPerformance";
import { countDays, displayDate } from "constants/utility/date";
import { numberWithCommas } from "constants/utility/number";
import CampaignAdminSettings from "components/CampaignAdminSettings";
import { getCampaignById, resetCurrentCampaign } from "store/actions/campaign.actions";
import EditCampaign from "components/EditCampaign";
import { ENUM_USER_ROLES } from "store/states/user.state";
import { closeSlideModal } from "store/actions/sidebar.actions";

interface ICampaignPopupProps extends PropsFromRedux {}

const mapStateToProps = ({ popup, currentCampaign, user }: RootState) => ({
  popup,
  currentCampaign,
  user,
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const CampaignPopup: React.FunctionComponent<ICampaignPopupProps> = ({
  popup,
  currentCampaign,
  user,
}) => {
  const dispatch = useDispatch();
  const [currentSection, setCurrent] = React.useState("overview");

  const onExit = () => {
    setCurrent("overview");
    dispatch(resetCurrentCampaign());
    dispatch(hideCampaignDetailPopup());
    dispatch(closeSlideModal());
  };

  React.useEffect(() => {
    if (popup.showCampaignDetailPopup.id && popup.showCampaignDetailPopup.show) {
      dispatch(getCampaignById(popup.showCampaignDetailPopup.id));
    }
  }, [popup.showCampaignDetailPopup.show]);

  const days =
    currentCampaign.data &&
    countDays(new Date(currentCampaign.data.startDate), new Date(currentCampaign.data.endDate));

  return (
    <CampaignPopupModal
      centered
      data-testid="build-campaign"
      show={popup.showCampaignDetailPopup.show}
      // backdrop="static"
      size="xl"
      onHide={onExit}
      onExited={onExit}
    >
      <Modal.Header>
        <Modal.Title>
          <CloseButton top={20} right={20} onClick={onExit} />
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {currentCampaign.data && !currentCampaign.loading && !currentCampaign.updating ? (
          <div className="current-campaign-wrapper">
            <div className="section-selector">
              <span
                className={`selector ${currentSection === "overview" ? "selected" : ""}`}
                onClick={() => setCurrent("overview")}
              >
                Overview
              </span>
              <span
                className={`selector ${currentSection === "performance" ? "selected" : ""}`}
                onClick={() => setCurrent("performance")}
              >
                Performance
              </span>

              {user.role !== ENUM_USER_ROLES.USER && (
                <span
                  className={`selector ${currentSection === "admin" ? "selected" : ""}`}
                  onClick={() => setCurrent("admin")}
                >
                  Admin
                </span>
              )}
            </div>
            <div className="campaign-heading">
              <div className="field-item">
                <p className="field-key">Campaign Name</p>
                <span className="field-value">{currentCampaign.data.name}</span>
              </div>
              <div className="field-item">
                <p className="field-key">Campaign Period</p>
                <span className="field-value">
                  {`${displayDate(new Date(currentCampaign.data.startDate))} - ${displayDate(
                    new Date(currentCampaign.data.endDate)
                  )}`}
                </span>
              </div>
              <div className="field-item">
                <p className="field-key">Campaign Budget</p>
                <span className="field-value">{`${numberWithCommas(
                  currentCampaign.data.budgetPerDay * (days || 1)
                )} USD`}</span>
              </div>
              <div className="field-item">
                <p className="field-key">Status</p>
                <span className="field-value">{currentCampaign.data.status}</span>
              </div>
            </div>
            <div className="campaign-body">
              {currentSection === "overview" && <CampaignOverview />}
              {currentSection === "performance" && <CampaignPerformance />}
              {currentSection === "admin" && <CampaignAdminSettings />}
            </div>
          </div>
        ) : (
          <div className="campaign-loading">
            <Spinner />
          </div>
        )}
      </Modal.Body>
      <EditCampaign />
    </CampaignPopupModal>
  );
};

export default connector(CampaignPopup);
