import Switch from "components/Switch";
import React from "react";
import { NotificationSettingsStyled } from "./styled";

const NotificationSetting = () => {
  const notificationList = {
    "New Compared Lists": false,
    "New Suggested Lists": false,
    "New Competitor Lists": false,
    "Compare List Updates": false,
    "Suggested List Updates": false,
    "Competitor List Updates": false,
    "New Campaigns": false,
    "Campaign Updates": false,
  };

  type NOFITICATION_LIST_TYPES = typeof notificationList;

  const [list, setList] = React.useState<NOFITICATION_LIST_TYPES>(notificationList);

  return (
    <NotificationSettingsStyled>
      <b>Notifications</b>
      <div className="notification-settings-wrapper">
        {/* <p>{`Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsumhas been the industry's
standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.`}</p> */}
      </div>

      <div className="notification-table">
        <div className="table-header">
          <div>
            <span>Notification Lists</span>
          </div>
          <div>
            <span>Browser</span>
          </div>
        </div>
        <div className="table-body">
          {Object.keys(list).map((item) => {
            return (
              <div className="noti-list">
                <div>
                  <span>{item}</span>
                </div>
                <div>
                  <Switch
                    index={1}
                    onChange={() =>
                      setList((prev) => ({
                        ...prev,
                        [item]: !prev[item as keyof NOFITICATION_LIST_TYPES],
                      }))
                    }
                    isActive={list[item as keyof NOFITICATION_LIST_TYPES]}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </NotificationSettingsStyled>
  );
};

export default NotificationSetting;
