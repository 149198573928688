import React from "react";
import { BannerPreviewImageStyled } from "./styled";

interface IBannerPreviewImage {
  isHorizontal: boolean;
  src: string;
  height?: number;
}

const BannerPreviewImage: React.FunctionComponent<IBannerPreviewImage> = ({
  isHorizontal,
  src,
  height,
}) => {
  return <BannerPreviewImageStyled height={height} isHorizontal={isHorizontal} src={src} />;
};

export default BannerPreviewImage;
