import React from "react";
import { Plan, PricingPlanLayout, ModalHubSpot, PlanSwitch } from "./styled";
import { HubspotProvider, useHubspotForm } from "@aaronhayes/react-use-hubspot-form";
import LightBulbIcon from "components/Icons/LightBulbIcon";

const HubspotForm = () => {
  const { loaded, error, formCreated } = useHubspotForm({
    portalId: "6807845",
    formId: "2ef4fcaf-580d-40d1-ba11-3530c2d096ea",
    target: "#hubspot-form",
  });

  return (
    <div>
      <div id="hubspot-form" />
    </div>
  );
};

const PLAN_LIST = {
  year: {
    starter: 21,
    growth: 44.25,
    enterprise: 89.25,
  },
  month: {
    starter: 29,
    growth: 59,
    enterprise: 119,
  },
};

const PricingPlan = () => {
  const [show, setShow] = React.useState(false);
  const closeForm = () => setShow(false);
  const hubSpotForm = () => setShow(true);
  const [isMonthlyActive, planToggler] = React.useState(false);

  const currentPlan = isMonthlyActive ? PLAN_LIST.month : PLAN_LIST.year;

  return (
    <PricingPlanLayout>
      <ModalHubSpot centered show={show} onHide={closeForm}>
        <ModalHubSpot.Header closeButton>
          <ModalHubSpot.Title>SIGNUP FOR GROWTH</ModalHubSpot.Title>
        </ModalHubSpot.Header>
        <ModalHubSpot.Body>
          <HubspotProvider>
            <HubspotForm />
          </HubspotProvider>
        </ModalHubSpot.Body>
      </ModalHubSpot>

      <div className="wrapper">
        <div className="page-title">
          <h4>
            <b>PLANS & PRICING</b>
          </h4>
        </div>

        <PlanSwitch onClick={() => planToggler((prev) => !prev)} isMonthlyActive={isMonthlyActive}>
          <div className="monthly">
            <span>MONTHLY</span>
          </div>
          <div className="yearly">
            <span>YEARLY</span>
          </div>
        </PlanSwitch>

        <div className="pricing-plan-list">
          <Plan>
            <div className="light-bulb">
              <div style={{ width: "100%" }}>
                <LightBulbIcon className="lightbulb-icon" />
              </div>
            </div>
            <div />
            <div>
              <span className="plan-title">Features</span>
            </div>
            <div>
              <span className="bold-text">Keyword Tool</span>
            </div>

            <div>
              <span className="feature-item">Compare</span>
            </div>
            <div>
              <span className="feature-item">Suggest</span>
            </div>
            <div>
              <span className="feature-item">Competitor</span>
            </div>
            <div>
              <span />
            </div>

            <div>
              <span className="bold-text">Banner</span>
            </div>
            <div>
              <span className="feature-item">Static banner</span>
            </div>
            <div>
              <span className="feature-item">Gif banner</span>
            </div>
            <div>
              <span className="feature-item">Video banner set</span>
            </div>

            <div />

            <div>
              <span className="feature-item">Custom Landing Page</span>
            </div>
            <div>
              <span className="feature-item">Blockchain Hosting</span>
            </div>

            <div />
            <div />

            <div>
              <span className="bold-text">Free Advertising Credit</span>
            </div>
            <div />
            <div className="contact"> Pay Per Considered Visitor</div>
            <div />
          </Plan>

          <Plan>
            <div className="free plan">
              <span> FREE </span>
            </div>
            <div className="plan-title free-label">
              <span className="highlight plan-label">FREE</span>
            </div>

            <div className="signup">
              <button type="button" className="button-pricing" onClick={hubSpotForm}>
                TRY FOR FREE
              </button>
            </div>

            <div />
            <div />

            <div>
              <span className="highlight-text">14 days </span>
              <span className="feature-item">unlimited access</span>
            </div>
            <div>
              <span className="highlight-text">14 days </span>
              <span className="feature-item">unlimited access</span>
            </div>
            <div>
              <span className="highlight-text">14 days </span>
              <span className="feature-item">unlimited access</span>
            </div>
            <div />
          </Plan>

          <Plan>
            <div className="starter plan">
              <span> STARTER </span>
            </div>

            <div className="plan-title">
              <span className="highlight plan-label">${currentPlan.starter}</span>
            </div>

            <span className="minor">
              <div>
                <span>Per month</span>
              </div>
              <div>{!isMonthlyActive && <span>$260 per year</span>}</div>
            </span>

            <div className="signup">
              <button type="button" className="button-pricing" onClick={hubSpotForm}>
                SIGNUP FOR STARTER
              </button>
            </div>

            <div />
            <div />

            <div>
              <span className="highlight-text">250 searches </span>
              <span className="feature-item">per month</span>
            </div>
            <div>
              <span className="highlight-text">250 searches </span>
              <span className="feature-item">per month</span>
            </div>
            <div>
              <span className="highlight-text">50 searches </span>
              <span className="feature-item">per month</span>
            </div>

            <div />
            <div />
            <div>
              <span className="highlight-text">1 set</span>
              <span className="feature-item">per month</span>
            </div>
            <div />
            <div />

            <div />
            <div>
              <span className="feature-item">Starting from</span>{" "}
              <span className="highlight-text">$299</span>
            </div>
            <div>
              <span className="contact">Contact our sales</span>
            </div>

            <div />
            <div />

            <div>
              <span className="highlight-text">$100</span>
            </div>
            <div />
            <div />
          </Plan>

          <Plan isRecommend>
            <div className="growth plan">
              <span>GROWTH</span>
            </div>

            <div className="plan-title">
              <span className=" plan-label">${currentPlan.growth}</span>
            </div>

            <span className="minor">
              <div>
                <span>Per month</span>
              </div>
              <div>{!isMonthlyActive && <span>$531 per year</span>}</div>
            </span>

            <div className="signup">
              <button type="button" className="button-pricing" onClick={hubSpotForm}>
                SIGNUP FOR GROWTH
              </button>
            </div>

            <div />
            <div />

            <div>
              <span className="highlight-text">500 searches</span>
              <span className="feature-item">per month</span>
            </div>
            <div>
              <span className="highlight-text">500 searches</span>
              <span className="feature-item">per month</span>
            </div>
            <div>
              <span className="highlight-text">100 searches</span>
              <span className="feature-item">per month</span>
            </div>

            <div />
            <div />

            <div>
              <span className="highlight-text">2 sets</span>
              <span className="feature-item">per month</span>
            </div>
            <div>
              <span className="highlight-text">2 sets</span>
              <span className="feature-item">per month</span>
            </div>
            <div>
              <span className="contact">Contact our sales</span>
            </div>

            <div />
            <div>
              <span className="feature-item">Starting from</span>
              <span className="highlight-text">$239</span>
            </div>
            <div>
              <span className="contact">Contact our sales</span>
            </div>

            <div />
            <div />

            <div>
              <span className="highlight-text">$200</span>
            </div>
            <div />
            <div className="contact">Contact our sales</div>
          </Plan>

          <Plan>
            <div className="enterprise plan">
              <span>ENTERPRISE</span>
            </div>
            <div className="plan-title">
              <span className="highlight plan-label">${currentPlan.enterprise}</span>
            </div>

            <span className="minor">
              <div>
                <span>Per month</span>
              </div>
              <div>{!isMonthlyActive && <span>$1,071 per year</span>}</div>
            </span>

            <div className="signup">
              <button type="button" className="button-pricing" onClick={hubSpotForm}>
                SIGNUP FOR ENTERPRISE
              </button>
            </div>

            <div />
            <div />

            <div>
              <span className="highlight-text">Unlimited Searches</span>
            </div>
            <div>
              <span className="highlight-text">Unlimited Searches</span>
            </div>
            <div>
              <span className="highlight-text">Unlimited Searches</span>
            </div>

            <div />
            <div />
            <div>
              <span className="highlight-text">3 sets</span>
              <span className="feature-item">per month</span>
            </div>
            <div>
              <span className="highlight-text">3 sets</span>
              <span className="feature-item">per month</span>
            </div>
            <div>
              <span className="contact">Contact our sales</span>
            </div>

            <div />
            <div>
              <span className="feature-item">Starting from</span>{" "}
              <span className="highlight-text">$179</span>
            </div>
            <div>
              <span className="contact">Contact our sales</span>
            </div>

            <div />
            <div />

            <div>
              <span className="highlight-text">$500</span>
            </div>
            <div />
            <div className="contact">Contact our sales</div>
          </Plan>
        </div>
      </div>
    </PricingPlanLayout>
  );
};

export default PricingPlan;
