import { IErrorMessage, IUsersFilters } from "../../services/types";
import * as actions from "../action-types/users.types";
import { IUpdateUserData, IUserAuthentication } from "../states/user.state";

// get

export function getUsers(payload: IUsersFilters): actions.GetUsersAction {
  return {
    type: actions.GET_USERS,
    payload,
  };
}

export function getUsersRequest(): actions.GetUsersRequestAction {
  return {
    type: actions.GET_USERS_REQUEST,
  };
}

export function getUsersSuccess(
  users: Array<IUserAuthentication>,
  total: number
): actions.GetUsersSuccessAction {
  return {
    type: actions.GET_USERS_SUCCESS,
    users,
    total,
  };
}

export function getUsersFailure(error: IErrorMessage): actions.GetUsersFailureAction {
  return {
    type: actions.GET_USERS_FAILURE,
    error,
  };
}

// update

export function updateUser(data: IUpdateUserData & { id: string }) {
  return {
    type: actions.UPDATE_USER,
    payload: data,
  };
}

export function updateUserRequest(): actions.UpdateUserRequestAction {
  return {
    type: actions.UPDATE_USER_REQUEST,
  };
}

export function updateUserSuccess(user: IUserAuthentication): actions.UpdateUserSuccessAction {
  return {
    type: actions.UPDATE_USER_SUCCESS,
    user,
  };
}

export function updateUserFailure(error: IErrorMessage): actions.UpdateUserFailureAction {
  return {
    type: actions.UPDATE_USER_FAILURE,
    error,
  };
}

// delete

export function deleteUsers(ids: Array<string>, currentFilters: IUsersFilters) {
  return {
    type: actions.DELETE_USERS,
    payload: { ids, currentFilters },
  };
}

export function deleteUsersRequest(): actions.DeleteUsersRequestAction {
  return {
    type: actions.DELETE_USERS_REQUEST,
  };
}

export function deleteUsersSuccess(ids: Array<string>): actions.DeleteUsersSuccessAction {
  return {
    type: actions.DELETE_USERS_SUCCESS,
    ids,
  };
}

export function deleteUsersFailure(error: IErrorMessage): actions.DeleteUsersFailureAction {
  return {
    type: actions.DELETE_USERS_FAILURE,
    error,
  };
}
