import styled from "styled-components/macro";

export const SavedListDropdownStyled = styled.div<{ disabled?: boolean }>`
  pointer-events: ${({ disabled }) => (disabled ? "none" : "default")};
  display: flex;
  position: relative;

  .select-keyword-error {
    color: ${({ theme }) => theme.color.error};
    font-weight: bold;
    position: absolute;
    right: 20px;
    font-size: 12px;
    top: 16px;
  }

  .btn-primary:not(:disabled):not(.disabled).active,
  .btn-primary:not(:disabled):not(.disabled):active,
  .show > .btn-primary.dropdown-toggle {
    border-color: ${({ theme }) => theme.dropdownMenu};
    background-color: ${({ theme }) => theme.dropdownMenu};
    color: ${({ theme }) => theme.selectedSavedList};
    border-radius: 10px 0px 0px 0px;
  }

  .dropdown-menu {
    font-size: 12px;
    width: 200px;
    background-color: ${({ theme }) => theme.dropdownMenu};
    color: ${({ theme }) => theme.contrast};
    border: 4px solid ${({ theme }) => theme.dropdownMenu};
    border-radius: 0px 0px 10px 10px;

    .dropdown-divider {
      border-top-color: ${({ theme }) => theme.background};
    }

    .dropdown-item {
      &:hover,
      &:active {
        font-weight: bold;
        background-color: transparent;
      }

      /* color: ${({ theme }) => theme.contrast} !important; */
      color: rgb(255, 255, 255);
    }
  }

  .no-saved-msg {
    text-align: center;
    width: 100%;
    height: 63px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    border-top: 1px solid #bbbbbb;
  }

  .dropdown-toggle {
    border-radius: 10px 0px 0px 10px;
    font-size: 14px;
    color: ${({ theme }) => theme.selectedSavedList};
    background-color: ${({ theme }) => theme.dropdownMenu};
    padding: 0px 1rem;
    font-weight: bold;
    border-color: ${({ theme }) => theme.dropdownMenu};
    height: 50px;
    min-width: 200px;
  }

  .keyword-input {
    font-size: 14px;
    background: ${({ theme }) => theme.tableBorder};
    color: ${({ theme }) => theme.contrast};
    border-color: ${({ theme }) => theme.tableBorder};
    height: 50px !important;
    outline: none;
    border: none;
    width: 100%;
    padding-left: 20px;
    border-radius: 0px 10px 10px 0px;
    position: relative;

    &:focus {
      box-shadow: none;
      border-color: black;
    }
  }

  .input-group-prepend {
    background-color: white;

    .btn-outline-primary {
      color: black;
      border-radius: 0;
      border-color: rgb(232, 232, 232);
      font-size: 14px;
      font-weight: bold;
      box-shadow: none;
      background-color: white;

      &:active {
        /* background-color: ${({ theme }) => theme.selectedSavedList};
        border-color: ${({ theme }) => theme.selectedSavedList}; */
        background-color: #ffffff;
        border-color: #ffffff;
        color: black;
      }
    }

    input {
      border-radius: 0;
    }

    .dropdown-item {
      font-size: 14px;
      color: #212529 !important;

      &:hover,
      &:focus {
        text-decoration: none;
        background-color: #e9ecef;
      }
    }
  }
`;
