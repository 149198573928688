export const campaignStatus = {
  ACTIVE: "Active",
  PENDING: "Pending",
  PAUSED: "Paused",
  EDITED: "Edited",
  DELETED: "Deleted",
  INACTIVE: "Inactive",
};

export const campaignStatusDropdown = [
  { text: "Active" },
  { text: "Pending" },
  { text: "Paused" },
  { text: "Edited" },
  { text: "Deleted" },
  { text: "Inactive" },
];

export const campaignState = {
  NEW: "New",
  EDITED: "Edited",
};
