import React from "react";
import {
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  AreaChart,
  Area,
  CartesianGrid,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  TooltipProps,
} from "recharts";

import { numberToAbbreviatedString, numberWithCommas } from "constants/utility/number";
import CursorIcon from "components/Icons/CursorIcon";
import ClockIcon from "components/Icons/ClockIcon";
import DocumentIcon from "components/Icons/DocumentIcon";
import ForwardIcon from "components/Icons/ForwardIcon";
import { getGraphColors } from "constants/utility/chart";

import Spinner from "components/Spinner";
import Popover from "components/Popover";
import { messages } from "constants/utility/messages";
import { NameType, ValueType } from "recharts/types/component/DefaultTooltipContent";
import { useDarkMode } from "hooks/useDarkMode";
import { IChartLabel, IDomainInfo, IKeywordsChartData } from "constants/competitor";

const ChartTooltip = ({
  payload,
  valueUnit,
}: TooltipProps<ValueType, NameType> & { valueUnit?: "%" }) => {
  return (
    <div className="chartTooltipWrapper">
      {payload && payload.length ? (
        <div className="chartValue" style={{ color: payload[0].color }}>
          {numberWithCommas(payload[0].payload.value)}
          {valueUnit && valueUnit}
        </div>
      ) : null}
    </div>
  );
};

interface IStatisticSectionProps {
  domainInfo: IDomainInfo | null;
  domainLoading: boolean;
  domainChartData: Array<IChartLabel> | null;
  keywordsChartData: Array<IKeywordsChartData> | null;
  trafficeSource: Array<IChartLabel> | null;
}

const StatisticSection: React.FunctionComponent<IStatisticSectionProps> = ({
  domainInfo,
  domainLoading,
  domainChartData,
  keywordsChartData,
  trafficeSource,
}) => {
  const colors = getGraphColors;
  const [theme] = useDarkMode();

  return (
    <>
      <div className="domain-section">
        <div className="domainCard">
          <div className="domain-info">
            <div>
              {domainInfo ? (
                <a
                  href={`https://www.${domainInfo.site_url}`}
                  className="domainLink"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {domainInfo.site_url}
                </a>
              ) : null}
            </div>

            {domainInfo ? (
              <div>
                <div className="domainTraffic">
                  <span className="visitsPerMonth">
                    <Popover
                      id={messages.titleTraffic.id}
                      placement={messages.titleTraffic.placement}
                      color={messages.titleTraffic.color}
                      title={messages.titleTraffic.title}
                      content={messages.titleTraffic.content}
                      bottomContent={messages.titleTraffic.bottomContent}
                    >
                      {numberWithCommas(domainInfo.traffic.value)}
                    </Popover>
                  </span>
                  <span className="visits-label">Visits</span>
                </div>
                <div className="traffic-label">
                  <span>from last month</span>
                </div>
              </div>
            ) : null}
          </div>
          <div className="domainChartWrapper">
            {domainLoading ? (
              <Spinner />
            ) : domainInfo && domainChartData ? (
              <ResponsiveContainer width="100%" height={250}>
                <AreaChart
                  width={730}
                  height={150}
                  data={domainChartData}
                  margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                >
                  <defs>
                    <linearGradient id="0" x1="0" y1="0" x2="0" y2="1">
                      <stop
                        offset="5%"
                        stopColor={theme === "light" ? colors[0] : colors[3]}
                        stopOpacity={0.3}
                      />
                      <stop
                        offset="50%"
                        stopColor={theme === "light" ? colors[0] : colors[3]}
                        stopOpacity={0}
                      />
                    </linearGradient>
                  </defs>

                  <XAxis dataKey="label" tickMargin={15} tickLine={false} axisLine={false} />
                  <YAxis
                    axisLine={false}
                    tickLine={false}
                    tickFormatter={(val) => numberToAbbreviatedString(val).toString()}
                    tickMargin={10}
                  />
                  <CartesianGrid strokeDasharray="3 3" />
                  <Tooltip content={(tooltipProps) => <ChartTooltip {...tooltipProps} />} />

                  <Area
                    type="monotone"
                    dataKey="value"
                    stroke={theme === "light" ? colors[0] : colors[3]}
                    fillOpacity={1}
                    fill={`url(#${0})`}
                  />
                </AreaChart>
              </ResponsiveContainer>
            ) : (
              <p className="default-state-text h-100 d-flex justify-content-center align-items-center">
                No domain selected
              </p>
            )}
          </div>
        </div>
      </div>

      <div className="engagement-section">
        <div>
          <h3 className="card-title">
            <b>Engagement</b>
          </h3>

          {domainLoading ? (
            <Spinner />
          ) : domainInfo ? (
            <React.Fragment>
              <div className="attributes">
                <div className="label">
                  <span> Total Visits</span>
                </div>
                <div className="value">{numberWithCommas(domainInfo.audience.visits)}</div>
              </div>

              <div className="attributes">
                <div className="label">
                  <span> Avg Visit Duration </span>
                </div>
                <div className="value">{domainInfo.audience.time_on_site_avg}</div>
              </div>

              <div className="attributes">
                <div className="label">
                  <span>Pages Per Visit</span>
                </div>
                <div className="value">{domainInfo.audience.page_views_avg.toFixed(3)}</div>
              </div>

              <div className="attributes">
                <div className="label">
                  <span> Bounce Rate </span>
                </div>
                <div className="value">{domainInfo.audience.bounce_rate}%</div>
              </div>
            </React.Fragment>
          ) : (
            <p className="default-state-text">No domain selected</p>
          )}
        </div>
      </div>

      <div className="top-countries-section">
        <h3 className="card-title">
          <b>Top Countries</b>
        </h3>
        {domainLoading ? (
          <Spinner />
        ) : domainInfo ? (
          domainInfo.traffic.countries.map((country: any, index: number) => (
            <div className="attributes" key={country.value}>
              <div className="label">{country.country}</div>
              <div className="value">{numberWithCommas(country.value)}</div>
            </div>
          ))
        ) : (
          <p className="default-state-text">No domain selected</p>
        )}
      </div>

      <div className="top-keyword-section">
        <h3 className="card-title">
          <b>Top Keywords Traffic</b>
        </h3>
        {domainLoading ? (
          <Spinner />
        ) : domainInfo && keywordsChartData ? (
          <div className="top-keywords-chart">
            <div className="keywordsChartWrapper">
              <PieChart width={200} height={200}>
                <Pie
                  data={keywordsChartData}
                  cx="50%"
                  cy="50%"
                  innerRadius={40}
                  outerRadius={60}
                  fill={theme === "light" ? colors[0] : colors[3]}
                  dataKey="value"
                  legendType="rect"
                >
                  {keywordsChartData.map((entry, index: number) => (
                    <Cell key={`cell-${entry.value}`} fill={colors[index]} />
                  ))}
                </Pie>
              </PieChart>
            </div>
            <div className="keywordsContent">
              <div className="keywords">
                <div className="keywordWrapper">
                  <div className="keywordColor second" />
                  <div className="keywordName">
                    <span>Organic - </span>
                    <span>{numberWithCommas(domainInfo.traffic.sources.search_organic.value)}</span>
                  </div>
                </div>

                <div className="keywordWrapper">
                  <div className="keywordColor first" />
                  <div className="keywordName">
                    <span>Paid - </span>
                    <span>{numberWithCommas(domainInfo.traffic.sources.search_ad.value)}</span>
                  </div>
                </div>

                <div className="total-visits">
                  <span className="visits">
                    {numberWithCommas(
                      domainInfo.traffic.sources.search_ad.value +
                        domainInfo.traffic.sources.search_organic.value
                    )}
                  </span>
                  <span className="visits-label">Visits</span>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <p className="default-state-text">No domain selected</p>
        )}
      </div>

      <div className="traffic-section">
        <h3 className="card-title">
          <b>Traffic Source</b>
        </h3>
        {domainLoading ? (
          <Spinner />
        ) : domainInfo && trafficeSource ? (
          <div className="top-keywords-chart">
            <div className="keywordsChartWrapper">
              <PieChart width={200} height={180}>
                <Pie
                  data={trafficeSource}
                  cx="50%"
                  cy="50%"
                  innerRadius={40}
                  outerRadius={60}
                  fill={theme === "light" ? colors[0] : colors[3]}
                  dataKey="value"
                  legendType="rect"
                >
                  {trafficeSource.map((entry, index: number) => (
                    <Cell key={`cell-${entry.value}`} fill={colors[index]} />
                  ))}
                </Pie>
              </PieChart>
            </div>
            <div className="keywordsContent">
              <div className="keywords">
                {trafficeSource.map((entry, idx) => {
                  return (
                    <div key={entry.value} className="keywordWrapper">
                      <div
                        style={{
                          backgroundColor: getGraphColors[idx],
                        }}
                        className="keywordColor"
                      />
                      <div className="keywordName">
                        <span>{entry.label} - </span>
                        <span>{numberWithCommas(entry.value)} %</span>
                      </div>
                    </div>
                  );
                })}

                {/* <div className="total-visits">
                  <span className="visits">
                    {numberWithCommas(
                      domainInfo.traffic.sources.search_ad.value +
                        domainInfo.traffic.sources.search_organic.value
                    )}
                  </span>
                  <span className="visits-label">Visits</span>
                </div> */}
              </div>
            </div>
          </div>
        ) : (
          <p className="default-state-text">No domain selected</p>
        )}
      </div>
    </>
  );
};

export default StatisticSection;
