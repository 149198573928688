import { IValidFile } from "../../components/Dropzone";

const state = {
  total: 0,
  loading: false,
  error: null,
  data: [],
  isAssetUpdating: false,
  // data: mock,
} as IBannerState;

interface IBannerState {
  loading: boolean;
  error: string | null;
  data: Array<IBanner>;
  total: number;
  isAssetUpdating: boolean;
}

export interface IBanner {
  id?: number;
  landingPageUrl: string;
  thankyouPageUrl: string;
  bannerId?: number;
  images: Array<IValidFile>;
  imagesUrl: Array<string>;
  title: string;
}

export interface IImageFromServer {
  original: {
    height: number;
    width: number;
    mine: string;
    size: number;
  };
  url: string;
}

export interface IBannerFromServer {
  id?: number;
  landingPageUrl: string;
  thankyouPageUrl: string;
  bannerId?: number;
  images: Array<IImageFromServer>;
  imagesUrl: Array<string>;
  title: string;
}

export interface IPostBanner {
  campaignId: string;
  bannerAd: IBanner;
}

export default state;
