import React from "react";
import { connect, ConnectedProps, useDispatch } from "react-redux";
import CampaignList from "components/CampaignList";
import { deleteCurrentUserCampaigns } from "store/actions/campaigns.actions";
import { AllCampaignsPage } from "./styled";
import { RootState } from "store/store";
import TableFilter, { IFilterColumn } from "components/TableFilter";
import { CampaignStatus } from "store/states/campaign.state";

const mapStateToProps = ({ campaigns }: RootState) => ({
  campaigns,
});
const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface IAllCampaignsProps extends PropsFromRedux {}

const AllCampaigns: React.FunctionComponent<IAllCampaignsProps> = ({ campaigns }) => {
  const [selectedCampaigns, setSelectedCampaigns] = React.useState<Array<string>>([]);

  const [currentFilters, setCurrentFilters] = React.useState<IFilterColumn | undefined>(undefined);

  const dispatch = useDispatch();

  const onFilterStatusChange = (status?: CampaignStatus) => {
    setCurrentFilters((prev) => ({ ...prev, status }));
    //
  };

  const onCampaignSelectionChange = (campaignId: string) => {
    if (!selectedCampaigns.includes(campaignId)) {
      setSelectedCampaigns([...selectedCampaigns, campaignId]);
    } else {
      const filteredCampaigns = selectedCampaigns.filter((el) => el !== campaignId);
      setSelectedCampaigns(filteredCampaigns);
    }
  };

  const onSelectionDeleteButtonClick = async () => {
    const confirmed = window.confirm(
      "Are you sure you want to delete these campaigns? Note: This cannot be undone"
    );

    if (confirmed) {
      await dispatch(deleteCurrentUserCampaigns(selectedCampaigns));

      setSelectedCampaigns([]);
    }
  };

  const applyFilters = (filters: IFilterColumn) => {
    setCurrentFilters((prev) => ({ ...prev, ...filters }));
  };

  return (
    <AllCampaignsPage>
      <h6 className="keyword-tool-title">Admin Zone</h6>
      <h2 className="page-title">Campaign</h2>

      <TableFilter
        admin
        currentFilters={currentFilters}
        onFilterStatusChange={onFilterStatusChange}
        onFilter={applyFilters}
      />

      <CampaignList
        admin
        currentFilters={currentFilters}
        onSelect={onCampaignSelectionChange}
        selectedCampaigns={selectedCampaigns}
        selectAll={(selected) =>
          setSelectedCampaigns((prev) => Array.from(new Set([...prev, ...selected])))
        }
        unselectAll={(unselected) =>
          setSelectedCampaigns((prev) => prev.filter((item) => !unselected.includes(item)))
        }
        total={campaigns.total}
        loading={campaigns.loading}
        campaigns={campaigns.data}
        loaded={campaigns.loaded}
        onSelectionDeleteButtonClick={onSelectionDeleteButtonClick}
      />
    </AllCampaignsPage>
  );
};

export default connector(AllCampaigns);
