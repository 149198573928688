import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";

import { axiosForReverseAds } from "axiosConfig";
import StyledSignals from "./styled";
import Button from "components/Button";
const Signals = () => {
  const navigate = useNavigate();
  const LIST_SIZE = 5;
  const [signalList, setSignalList] = useState<
    Array<{ id: number; name: string; location: string; language: string }>
  >([]);

  const [items, setItems] = useState<
    Array<{ id: number; name: string; location: string; language: string }>
  >([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [btnRange, setBtnRange] = useState({ min: 1, max: 3 });

  useEffect(() => {
    const fetchSignals = async () => {
      const storedUser_id = localStorage.getItem("user_id");
      if (storedUser_id) {
        const user_id = parseInt(storedUser_id);
        const response = await axiosForReverseAds.post("get_signals_info", { user_id: user_id });
        setSignalList(response.data.data);
        setItems(response.data.data.slice(0, LIST_SIZE));
        if (response.data.data.length < 3) {
          setBtnRange({ min: 1, max: response.data.data.length });
        }
      } else {
        setSignalList([]);
      }
    };
    fetchSignals();
  }, []);

  const paginate = (startIndex: number) => {
    setItems(signalList.slice(startIndex, startIndex + LIST_SIZE));
  };

  const showBtn = (direction: string) => {
    if (direction === "next") {
      if (btnRange.max === Math.ceil(signalList.length / LIST_SIZE)) {
        return;
      } else if (btnRange.max + 3 > Math.ceil(signalList.length / LIST_SIZE)) {
        setBtnRange({ min: btnRange.max + 1, max: Math.ceil(signalList.length / LIST_SIZE) });
      } else {
        setBtnRange({ min: btnRange.max + 1, max: btnRange.max + 3 });
      }
    } else {
      if (btnRange.min === 1) {
        return;
      } else if (btnRange.min - 3 < 1) {
        setBtnRange({ min: 1, max: 3 });
      } else {
        setBtnRange({ min: btnRange.min - 3, max: btnRange.min - 1 });
      }
    }
  };

  return (
    <StyledSignals>
      <div className="signals-container-full">
        <div className="signals-container-head">Signals</div>
        <div className="btn-container">
          <Button className="btn" onClick={() => navigate("/app/keywords/suggested")}>
            Create Signal
          </Button>
        </div>
        <div className="signals-container-body">
          <table className="signals-table">
            <thead className="signals-thead">
              <tr>
                <th className="th1">Signal Name</th>
                <th className="th1">Location</th>
                <th className="th1">Language</th>
              </tr>
            </thead>

            <tbody>
              {items?.map((item, index) => (
                <tr key={index} className="signals-tr">
                  <td
                    onClick={() => {
                      navigate("/app/keywords/signal/" + item.id, {
                        state: {
                          name: item.name,
                          location: item.location,
                          language: item.language,
                        },
                      });
                    }}
                  >
                    <span className="signals-link">{item.name}</span>
                  </td>
                  <td>{item.location}</td>
                  <td>{item.language}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="pagination-container">
          <div
            className={btnRange.min === 1 ? "pagination-option disable" : "pagination-option"}
            onClick={() => {
              showBtn("prev");
            }}
          >
            &laquo;
          </div>
          {(() => {
            const jsxElements = [];
            for (let i = 0, j = 1; i < signalList.length; i += LIST_SIZE, j++) {
              jsxElements.push(
                <div
                  key={i}
                  className={
                    j >= btnRange.min && j <= btnRange.max
                      ? pageNumber === j
                        ? "pagination-active"
                        : "pagination-option"
                      : "hide"
                  }
                  onClick={() => {
                    paginate(i);
                    setPageNumber(j);
                  }}
                >
                  {j}
                </div>
              );
            }
            return jsxElements;
          })()}
          <div
            className={
              btnRange.max * LIST_SIZE >= signalList.length
                ? "pagination-option disable"
                : "pagination-option"
            }
            onClick={() => {
              showBtn("next");
            }}
          >
            &raquo;
          </div>
        </div>
      </div>
    </StyledSignals>
  );
};

export default Signals;
