import React from "react";
import { CustomModal, SizeList } from "./styled";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import Button from "components/Button";
import { fileSize } from "constants/utility/upload";
import { OverlayInjectedProps } from "react-bootstrap/esm/Overlay";

const InvalidFilesModal = ({ onHide, show, invalidFiles }: any) => {
  const renderAvailableSizes = (props: OverlayInjectedProps) => {
    return (
      <Tooltip id="sizes-tooltip">
        <SizeList
          {...props}
          style={{
            ...props.style,
          }}
        >
          <span>120 x 160</span>
          <span>160 x 600</span>
          <span>200 x 200</span>
          <span>240 x 400</span>
          {/* <span>250 x 250</span> */}
          <span>250 x 360</span>
          <span>300 x 1050</span>
          <span>300 x 250</span>
          <span>300 x 50</span>
          <span>300 x 600</span>
          <span>320 x 100</span>
          <span>320 x 50</span>
          <span>336 x 280</span>
          <span>468 x 60</span>
          {/* <span>580 x 400</span> */}
          <span>600 x 315</span>
          <span>600 x 500</span>
          <span>600 x 600</span>
          <span>728 x 90</span>
          {/* <span>930 x 180</span> */}
          <span>970 x 250</span>
          <span>970 x 90</span>
          {/* <span>980 x 120</span> */}
        </SizeList>
      </Tooltip>
    );
  };

  return (
    <CustomModal show={show} onHide={onHide} centered>
      <Modal.Header>
        <Modal.Title className="h5">Invalid selected files</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="message">
          Specified files could not be uploaded
          <ul className="invalid-files-list">
            {invalidFiles.map((file: File & { id: string }) => (
              <li key={file.id}>
                <i>{file.name}</i>
              </li>
            ))}
          </ul>
          Only files with the following extensions are allowed: <i> gif png jpg jpeg. or</i> <br />
          images sizes is larger than,
          <b> {fileSize(Number(process.env.REACT_APP_MAX_FILE_SIZE) + 194304)}. </b>
          or images not fit to any{" "}
          <OverlayTrigger trigger="click" placement="right" overlay={renderAvailableSizes}>
            <span className="size-trigger">
              <b>available banner sizes.</b>
            </span>
          </OverlayTrigger>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>OK</Button>
      </Modal.Footer>
    </CustomModal>
  );
};

export default InvalidFilesModal;
