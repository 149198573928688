import styled from "styled-components/macro";

export const UserNavigationStyled = styled.div`
  margin-top: 30px;

  ul {
    margin: 0;
    padding: 0;
  }

  .disabled {
    opacity: 0.4;
    pointer-events: none;
  }

  .back-homepage {
    font-size: 12px;
    padding: 0.75rem 1.25rem;
    cursor: pointer;
    color: ${({ theme }) => theme.cpc};

    .back-text {
      .text {
        font-size: 12px;
        font-weight: bold;
      }
      .arrow {
        margin-right: 5px;
        border: solid ${({ theme }) => theme.cpc};
        border-width: 1px 0px 0px 1px;
        display: inline-block;
        padding: 2px;
        transform: translate(0px, -2px) rotate(-45deg);
      }
    }
  }
`;
