import { IDataForSEO } from "constants/utility/keyword";
import { IErrorMessage } from "../../services/types";

export const SAVE_KEYWORDS_TO_HISTORY = "home-actions/SAVE_KEYWORDS_TO_HISTORY";

export interface SaveKeywordsHistoryAction {
  type: typeof SAVE_KEYWORDS_TO_HISTORY;
  payload: { [key: string]: IDataForSEO };
}

export const SAVE_KEYWORDS_TO_HISTORY_REQUEST = "home-actions/SAVE_KEYWORDS_TO_HISTORY_REQUEST";
export interface SaveKeywordsHistoryRequestAction {
  type: typeof SAVE_KEYWORDS_TO_HISTORY_REQUEST;
}

export const SAVE_KEYWORDS_TO_HISTORY_SUCCESS = "home-actions/SAVE_KEYWORDS_TO_HISTORY_SUCCESS";

export interface SaveKeywordsHistorySuccessAction {
  type: typeof SAVE_KEYWORDS_TO_HISTORY_SUCCESS;
  payload: { [key: string]: IDataForSEO };
}

export const SAVE_KEYWORDS_TO_HISTORY_FAILURE = "home-actions/SAVE_KEYWORDS_TO_HISTORY_FAILURE";
export interface SaveKeywordsHistoryFailureAction {
  type: typeof SAVE_KEYWORDS_TO_HISTORY_FAILURE;
  error: IErrorMessage;
}
