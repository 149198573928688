import React from "react";

const EditIcon = ({ className }: { className?: string }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.62 19.47">
      <defs>
        <style>
          {".icon-svg{fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;}"}
        </style>
      </defs>
      <g id="Layer_2" data-name="Layer 2">
        <g id="icons">
          <path
            className="icon-svg"
            d="M8.92.52H2S.5.44.5,1.75L.54,18s.08.94,1.48,1H18.09a1.14,1.14,0,0,0,1-1.19V10.63"
          />
          <polyline className="icon-svg" points="13.4 0.5 19.12 0.5 19.12 6.25" />
          <line className="icon-svg" x1={8.02} y1={11} x2={18.95} y2={0.6} />
        </g>
      </g>
    </svg>
  );
};

export default EditIcon;
