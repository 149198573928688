import { IErrorMessage } from "services/types";

export const GET_TARGETING = "targeting-actions/GET_TARGETING";

export interface GetTargetingListAction {
  type: typeof GET_TARGETING;
}

export const GET_TARGETING_REQUEST = "targeting-actions/GET_TARGETING_REQUEST";
export interface GetTargetingListRequestAction {
  type: typeof GET_TARGETING_REQUEST;
}

export const GET_TARGETING_SUCCESS = "targeting-actions/GET_TARGETING_SUCCESS";

export interface GetTargetingListSuccessAction {
  type: typeof GET_TARGETING_SUCCESS;
  payload: any;
}

export const GET_TARGETING_FAILURE = "targeting-actions/GET_TARGETING_FAILURE";
export interface GetTargetingListFailureAction {
  type: typeof GET_TARGETING_FAILURE;
  payload: string;
}
