import React from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import queryString from "query-string";
import FloatingLabel from "components/FloatingLabel";
import { Formik } from "formik";
import { axiosForReverseAds } from "axiosConfig";
import { Spinner, Alert } from "react-bootstrap";
import { ResetPasswordStyled } from "./styled";
import Button from "components/Button";
import IResetPasswordResponse from "models/reset-password-response.interface";
import resetPasswordSchema from "schemas/reset-password.schema";
import Input from "components/Input";
import * as AUTH_ROUTE from "modules/Auth/routes";
import { handlingError } from "constants/utility/error";

const ResetPassword = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const parsed = queryString.parse(location.search);
  const [hasReset, setPasswordReset] = React.useState<boolean>(false);
  const [alertMessage, setAlertMessage] = React.useState<null | { variant: string; text: string }>(
    null
  );

  if (!parsed.token && !parsed.email) {
    navigate("/auth/login");
  }
  if (hasReset) {
    return (
      <ResetPasswordStyled>
        <div className="wrapper">
          <h4 className="title secondaryFont">Password Reset Complete.</h4>
          <p>Your password has been set. You may go ahead and log in with now.</p>
          <Button
            height={40}
            width={200}
            className="redirect"
            onClick={() => navigate("/auth/login")}
          >
            Login
          </Button>
        </div>
      </ResetPasswordStyled>
    );
  } else if (parsed.token) {
    return (
      <ResetPasswordStyled>
        <div className="wrapper">
          <h4>Reset Password</h4>
          {alertMessage && (
            <Alert className="mb-5" variant={alertMessage.variant}>
              {alertMessage.text}
            </Alert>
          )}
          <p>You can reset your password using this form.</p> <br />
          <Formik
            initialValues={{
              password: "",
              confirmPassword: "",
            }}
            onSubmit={async (values, { setSubmitting }) => {
              try {
                const response = await axiosForReverseAds.post<IResetPasswordResponse>(
                  `/auth/v2/resetPassword`,
                  {
                    token: parsed.token,
                    email: (parsed.email as string).replace(" ", "+"),
                    password: values.password,
                  }
                );

                if (response.status === 200 && response.data) {
                  setPasswordReset(true);
                }
              } catch (error: any) {
                handlingError(error);
                if (error.response.data.message) {
                  setAlertMessage({
                    variant: "danger",
                    text: `It was not possible to reset your password.
                                    This may happen if your token is invalid or expired.`,
                  });
                }
              }
              setSubmitting(false);
            }}
            validationSchema={resetPasswordSchema}
          >
            {({ isSubmitting, touched, errors, values, handleChange, handleSubmit }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <div className="input-wrapper">
                    <Input
                      black
                      data-testid="password"
                      type="password"
                      placeholder="Create New Password"
                      name="password"
                      touched={touched.password}
                      error={errors.password}
                      value={values.password}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="input-wrapper">
                    <Input
                      black
                      data-testid="confirm-password"
                      type="password"
                      placeholder="Confirm New Password"
                      name="confirmPassword"
                      onChange={handleChange}
                      touched={touched.confirmPassword}
                      error={errors.confirmPassword}
                      value={values.confirmPassword}
                    />
                  </div>

                  <div data-testid="submit-btn" className="input-wrapper">
                    <Button width={200} height={40} type="submit" block loading={isSubmitting}>
                      Submit
                    </Button>
                  </div>
                </form>
              );
            }}
          </Formik>
          <div className="forget-password-button">
            <span className="back-button">← Back to </span>
            <Link to={`../${AUTH_ROUTE.LOGIN}`}>{` Log in`}</Link>
          </div>
        </div>
      </ResetPasswordStyled>
    );
  } else {
    return null;
  }
};

export default ResetPassword;
