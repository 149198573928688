import { Modal } from "react-bootstrap";
import styled from "styled-components/macro";

export const CustomModal = styled(Modal)`
  .modal-content {
    background-color: ${({ theme }) => theme.alertModalBackground};
    border: 1px solid ${({ theme }) => theme.dark};
    border-radius: 10px;
    padding: 15px;
  }

  .modal-header {
    justify-content: center;
    text-align: center;
    color: ${({ theme }) => theme.secondary};
    border: none;
    padding: 20px 20px 0px 20px;
  }

  .modal-body {
    text-align: center;

    .confirm-text {
      color: ${({ theme }) => theme.contrast};
      font-size: 12px;
    }
  }

  .modal-footer {
    justify-content: center;
    border: none;

    .btn {
      background: ${({ theme }) => theme.color.primary};
      font-size: ${({ theme }) => theme.size.lg};
      border-radius: 0px;
      outline: none;
      border: none;
    }
  }
`;
