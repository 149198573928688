import { IErrorMessage } from "services/types";

// update advertiser

export const UPDATE_ADVERTISER = "user-actions/UPDATE_ADVERTISER";

export interface UpdateAdvertiserAction {
  type: typeof UPDATE_ADVERTISER;
  payload: {
    businessId: number;
    website: string;
  };
}

export const UPDATE_ADVERTISER_REQUEST = "user-actions/UPDATE_ADVERTISER_REQUEST";
export interface UpdateAdvertiserRequestAction {
  type: typeof UPDATE_ADVERTISER_REQUEST;
}

export const UPDATE_ADVERTISER_SUCCESS = "user-actions/UPDATE_ADVERTISER_SUCCESS";
export interface UpdateAdvertiserSuccessAction {
  type: typeof UPDATE_ADVERTISER_SUCCESS;
  payload: {
    businessId: number;
    website: string;
  };
}

export const UPDATE_ADVERTISER_FAILURE = "user-actions/UPDATE_ADVERTISER_FAILURE";
export interface UpdateAdvertiserFailureAction {
  type: typeof UPDATE_ADVERTISER_FAILURE;
  error: string;
}

// update password

export const UPDATE_PASSWORD = "user-actions/UPDATE_PASSWORD";

export interface UpdatePasswordAction {
  type: typeof UPDATE_PASSWORD;
  payload: {
    password: string;
    newPassword: string;
  };
}

export const UPDATE_PASSWORD_REQUEST = "user-actions/UPDATE_PASSWORD_REQUEST";
export interface UpdatePasswordRequestAction {
  type: typeof UPDATE_PASSWORD_REQUEST;
}

export const UPDATE_PASSWORD_SUCCESS = "user-actions/UPDATE_PASSWORD_SUCCESS";
export interface UpdatePasswordSuccessAction {
  type: typeof UPDATE_PASSWORD_SUCCESS;
}

export const UPDATE_PASSWORD_FAILURE = "user-actions/UPDATE_PASSWORD_FAILURE";
export interface UpdatePasswordFailureAction {
  type: typeof UPDATE_PASSWORD_FAILURE;
  error: {
    newPassword: Array<string>;
  };
}

// forget password

export const FORGET_PASSWORD = "user-actions/FORGET_PASSWORD";

export interface ForgetPasswordAction {
  type: typeof FORGET_PASSWORD;
  payload: string;
}

export const FORGET_PASSWORD_REQUEST = "user-actions/FORGET_PASSWORD_REQUEST";
export interface ForgetPasswordRequestAction {
  type: typeof FORGET_PASSWORD_REQUEST;
}

export const FORGET_PASSWORD_SUCCESS = "user-actions/FORGET_PASSWORD_SUCCESS";
export interface ForgetPasswordSuccessAction {
  type: typeof FORGET_PASSWORD_SUCCESS;
}

export const FORGET_PASSWORD_FAILURE = "user-actions/FORGET_PASSWORD_FAILURE";
export interface ForgetPasswordFailureAction {
  type: typeof FORGET_PASSWORD_FAILURE;
  error: string;
}
