import React from "react";
import {
  StyledCheckbox,
  InputCheckBox,
  CircleCheckbox,
  CheckboxWrapper,
  ExcludeCheckbox,
} from "./styled";

interface ICheckboxProps {
  checked?: boolean;
  centered?: boolean;
  onSelect?: () => void;
  [key: string]: any;
  size?: "sm" | "md";
  shape?: "square" | "circle" | "dash" | "exclude";
  inline?: boolean;
  border?: boolean;
  error?: boolean;
  selectAll?: boolean;
}

const renderCheckboxType = (
  shape: string,
  size: string,
  checked?: boolean,
  border?: boolean,
  error?: boolean,
  selectAll?: boolean
) => {
  switch (shape) {
    case "circle":
      return <CircleCheckbox size={size} checked={!!checked} />;
    case "exclude":
      return <ExcludeCheckbox size={size} checked={!!checked} />;
    default:
      return (
        <StyledCheckbox
          error={error}
          border={border}
          shape={shape || "square"}
          size={size || "md"}
          checked={!!checked}
          selectAll={selectAll}
        />
      );
  }
};

const Checkbox: React.FunctionComponent<ICheckboxProps> = ({
  checked,
  onSelect,
  size,
  shape,
  centered,
  inline,
  border,
  error,
  selectAll,
  ...props
}) => {
  return (
    <CheckboxWrapper onClick={onSelect} inline={inline} centered={centered} size={size!}>
      {renderCheckboxType(shape!, size!, checked, border, error, selectAll)}
      <InputCheckBox data-testid="checkbox" defaultChecked={checked} {...props} />
    </CheckboxWrapper>
  );
};

Checkbox.defaultProps = {
  size: "md",
  shape: "square",
};

export default React.memo(Checkbox);
