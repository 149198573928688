import Button from "components/Button";
import Step1Icon from "components/Icons/Step1Icon";
import Step2Icon from "components/Icons/Step2Icon";
import Step3Icon from "components/Icons/Step3Icon";
import Step4Icon from "components/Icons/Step4Icon";
import Step5Icon from "components/Icons/Step5Icon";
import Step6Icon from "components/Icons/Step6Icon";
import Spinner from "components/Spinner";
import Step1 from "components/Step1";
import Step2 from "components/Step2";
import Step3 from "components/Step3";
import Step4 from "components/Step4";
import Step5 from "components/Step5";
import Step6, { PAYMENT_METHOD_LIST } from "components/Step6";
import TablePagination from "components/TablePagination";
import { CATEGORY_KEY_VALUES_OBJECT } from "constants/category";
import {
  buildCampaignDescription,
  BUILD_CAMPAIGN_INITIAL_FORM_VALUES,
  IPaymentForm,
  IPostCampaignData,
} from "constants/utility/campaign";
import { handlingKeywordServiceError } from "constants/utility/error";
import { convertKeywordV3ArrToObj, IDataForSEOV3 } from "constants/utility/keyword";
import aspectRatio, { getAvailableSizes } from "constants/utility/upload";
import { FormikProps, useFormik } from "formik";
import useKeywordsTableHook from "hooks/useKeywordsTable";
import React from "react";
import { Modal } from "react-bootstrap";
import { connect, ConnectedProps, useDispatch } from "react-redux";
import budgetFormSchema from "schemas/budget-form.schema";
import creditCardSchema from "schemas/credit-card.schema";
import step1Schema from "schemas/step1.schema";
import step3Schema from "schemas/step3.schema";
import step4Schema from "schemas/step4.schema";
import {
  createCampaign,
  editCampaign,
  getCampaignById,
  resetCurrentCampaign,
} from "store/actions/campaign.actions";
import { createCampaignPaymentOrder } from "store/actions/payment.actions";
import { hideBuildCampaign, showScreenLoading } from "store/actions/popup.actions";
import { RootState } from "store/store";
import { BuildCampaignModal, StepItem, StepList, ProgressLine, Progress } from "./styled";
import api from "services/api";
import { addDays } from "date-fns";

interface IBuildCampaignProps extends PropsFromRedux {}

const mapStateToProps = ({ popup, currentCampaign }: RootState) => ({
  popup,
  currentCampaign,
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const BuildCampaign: React.FunctionComponent<IBuildCampaignProps> = ({
  popup,
  currentCampaign,
}) => {
  const scrollRef = React.useRef<HTMLDivElement>(null);
  const [currentStep, setCurrentStep] = React.useState(1);
  const [selectedKeywords, setSelectedKeywords] = React.useState<{ [key: string]: IDataForSEOV3 }>(
    {}
  );
  const { suggestedKeywords, loading, fetchSuggestedKeywords, resetState } = useKeywordsTableHook();
  const [tableData, setTableData] = React.useState(suggestedKeywords);

  const [paymentMethod, selectPaymentMethod] = React.useState<string>(PAYMENT_METHOD_LIST[0].key);
  const [schema, setSchema] = React.useState(step1Schema);
  const [customValidation, setCustomValidation] = React.useState({
    targeting: "",
    keywords: "",
  });
  const [includes, setIncludes] = React.useState<CATEGORY_KEY_VALUES_OBJECT>({});
  const [excludes, setExcludes] = React.useState<CATEGORY_KEY_VALUES_OBJECT>({});

  const [isInvalidFilesShow, setShowInvalidFiles] = React.useState<boolean>(false);

  const dispatch = useDispatch();
  // keywords Table

  const fetchKeywordsList = async (keywords: Array<string>) => {
    if (keywords.length) {
      try {
        const { data } = await api.dataForSEO.keywordSeachVolume(keywords.slice(0, 50));
        const results = convertKeywordV3ArrToObj(data.data);

        return results;
      } catch (error: any) {
        handlingKeywordServiceError(error);
      }
    }
    return {};
  };

  React.useEffect(() => {
    if (currentCampaign.data && popup.showBuildCampaign.show) {
      const {
        theGenders,
        theAgeGroups,
        locations,
        theDevices,
        theOs,
        theBrowsers,
        categories,
        theKeywords,
        banners,
      } = currentCampaign.data;

      const fetchCampaignKeywordData = async () => {
        const initialKeywords = await fetchKeywordsList(theKeywords);
        setTableData(() => ({ ...initialKeywords }));
        setSelectedKeywords(() => ({ ...initialKeywords }));
      };

      fetchCampaignKeywordData();

      if (currentCampaign.data?.categories) {
        const includes = currentCampaign.data.categories
          .filter((category) => category.behavior === "include")
          .reduce((acc, cur) => {
            return {
              ...acc,
              [cur.source_parent_key]: {
                ...acc[cur.source_parent_key],
                [cur.source_key]: cur,
              },
            };
          }, {} as CATEGORY_KEY_VALUES_OBJECT);

        const excludes = currentCampaign.data.categories
          .filter((category) => category.behavior === "exclude")
          .reduce((acc, cur) => {
            return {
              ...acc,
              [cur.source_parent_key]: {
                ...acc[cur.source_parent_key],
                [cur.source_key]: cur,
              },
            };
          }, {} as CATEGORY_KEY_VALUES_OBJECT);

        setExcludes(excludes);
        setIncludes(includes);
      }

      if (
        !(
          theGenders.length ||
          theAgeGroups.length ||
          locations.length ||
          theDevices.length ||
          theOs.length ||
          theBrowsers.length ||
          categories.length
        )
      ) {
        setCurrentStep(2);
      } else if (!theKeywords.length) {
        setCurrentStep(3);
      } else if (!banners.length || !banners[0].images.length) {
        setCurrentStep(4);
      } else {
        setCurrentStep(5);
      }
    }
  }, [currentCampaign.data]);

  const [startingPage, setStartingPage] = React.useState(0);
  const [pageLimit, setPageLimit] = React.useState(10);
  const amountOfKeywords = Object.keys(tableData).length;
  const currentItems = Object.keys(tableData).slice(startingPage, startingPage + pageLimit);
  const keywords = Object.values(selectedKeywords).map((keywordData) => keywordData.display);

  React.useEffect(() => {
    if (currentStep === 6) {
      const paymentSchema =
        paymentMethod === "credit card"
          ? step1Schema
              .concat(step3Schema)
              .concat(step4Schema)
              .concat(budgetFormSchema)
              .concat(creditCardSchema)
          : step1Schema.concat(step3Schema).concat(step4Schema).concat(budgetFormSchema);
      setSchema(paymentSchema);
    }
  }, [paymentMethod]);

  React.useEffect(() => {
    if (popup.showBuildCampaign.id) {
      // setCurrentStep(6);
      dispatch(getCampaignById(popup.showBuildCampaign.id));
    }
  }, [popup.showBuildCampaign.id]);

  const getInitialFormValues = () => {
    if (currentCampaign.data) {
      const { name, description, goal, locations, theDevices, theOs, theBrowsers, banners } =
        currentCampaign.data;

      return {
        ...BUILD_CAMPAIGN_INITIAL_FORM_VALUES,
        name,
        description: description || "",
        goal,
        locations,
        devices: theDevices,
        os: theOs,
        browsers: theBrowsers,

        images: banners.length
          ? banners[0].images.map((image) => ({
              ...image,
              ratio: aspectRatio(image.width, image.height),
              id: image.url,
              availableSizes: getAvailableSizes(image.original.width, image.original.height),
              originWidth: image.original.width,
              originHeight: image.original.height,
              type: image.original.mine,
              size: image.original.size.toString(),
              thumbnail: `${process.env.REACT_APP_IMAGES_STORAGE}${image.url}`,
            }))
          : [],
        landingPageUrl:
          banners.length && banners[0].landingPageUrl ? banners[0].landingPageUrl : "",
        thankyouPageUrl:
          banners.length && banners[0].thankyouPageUrl ? banners[0].thankyouPageUrl : "",
        title: banners.length && banners[0].title ? banners[0].title : "",
      };
    }
    return BUILD_CAMPAIGN_INITIAL_FORM_VALUES;
  };

  React.useEffect(() => {
    switch (currentStep) {
      case 1:
        setSchema(step1Schema);
        break;
      case 2:
        setSchema(step1Schema);
        break;
      case 3:
        setSchema(step1Schema.concat(step3Schema));
        break;
      case 4:
        setSchema(step1Schema.concat(step3Schema).concat(step4Schema));
        break;
      case 5:
        setSchema(step1Schema.concat(step3Schema).concat(step4Schema));
        break;
      case 6:
        setSchema(
          step1Schema
            .concat(step3Schema)
            .concat(step4Schema)
            .concat(budgetFormSchema)
            .concat(creditCardSchema)
        );
        break;
      default:
    }
  }, [currentStep]);

  const buildCampaignFormik = useFormik({
    validationSchema: schema,
    enableReinitialize: true,
    initialValues: getInitialFormValues(),
    onSubmit: async (values) => {
      dispatch(showScreenLoading());
      dispatch(
        createCampaign({ ...values, keywords, paymentMethod, includes, excludes, draft: false })
      );
    },
  });

  React.useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = 0;
    }
  }, [currentStep]);

  React.useEffect(() => {
    setTableData(suggestedKeywords);
  }, [suggestedKeywords]);

  const onSelectAllButtonClick = (newItems: Array<string>) => {
    const selectedData = newItems
      // .slice(startingPage, pageLimit + startingPage)
      .reduce((acc: { [key: string]: IDataForSEOV3 }, cur: string) => {
        acc = { ...acc, [cur]: { ...tableData[cur] } };
        return acc;
      }, {} as { [key: string]: IDataForSEOV3 });

    setSelectedKeywords((prev: { [key: string]: IDataForSEOV3 }) => ({
      ...prev,
      ...selectedData,
    }));
  };

  const onDeselectAllItems = (deselectItems: Array<string>) => {
    setSelectedKeywords((prev: { [key: string]: IDataForSEOV3 }) => {
      deselectItems.forEach((removeKeyword: string) => {
        delete prev[removeKeyword];
      });
      return { ...prev };
    });
  };

  const onKeywordSelect = (addedKeyword: IDataForSEOV3) => {
    setSelectedKeywords((prev: { [key: string]: IDataForSEOV3 }) => {
      if (prev[addedKeyword.keyword]) {
        delete prev[addedKeyword.keyword];
        return { ...prev };
      } else {
        return {
          ...prev,
          [addedKeyword.keyword.toLowerCase()]: {
            ...addedKeyword,
          },
        };
      }
    });
  };

  const onExit = async () => {
    selectPaymentMethod(PAYMENT_METHOD_LIST[0].key);
    buildCampaignFormik.resetForm();
    dispatch(hideBuildCampaign());
    setCurrentStep(1);
    setTableData({});
    setSelectedKeywords({});
    resetState();
  };

  const slicedTableData = currentItems.reduce((acc, cur) => {
    acc[cur] = tableData[cur];
    return acc;
  }, {} as any);

  const renderBuildCampaignStep = (buildCampaignFormik: FormikProps<IPostCampaignData>) => {
    switch (currentStep) {
      case 1:
        return <Step1 formikProps={buildCampaignFormik} />;
      case 2:
        return (
          <Step2
            setError={(error) => setCustomValidation((prev) => ({ ...prev, targeting: error }))}
            error={customValidation.targeting}
            formikProps={buildCampaignFormik}
            includes={includes}
            excludes={excludes}
            setValues={(includes, excludes) => {
              setIncludes(includes);
              setExcludes(excludes);
            }}
          />
        );
      case 3:
        return (
          <Step3
            error={customValidation.keywords}
            selectedKeys={keywords}
            onDeselectAllItems={onDeselectAllItems}
            onKeywordSelect={onKeywordSelect}
            onSelectAllButtonClick={onSelectAllButtonClick}
            fetchSuggestedKeywords={fetchSuggestedKeywords}
            setTableData={(tableData) => {
              setTableData(() => ({ ...tableData }));
            }}
            loading={loading}
            // tableData={tableData}
            tableData={slicedTableData}
            suggestedKeywords={suggestedKeywords}
          />
        );
      case 4:
        return (
          <Step4
            isInvalidFilesShow={isInvalidFilesShow}
            setShowInvalidFiles={(show) => setShowInvalidFiles(show)}
            formikProps={buildCampaignFormik}
          />
        );
      case 5:
        return (
          <Step5
            includes={includes}
            excludes={excludes}
            setCurrentStep={(step) => setCurrentStep(step)}
            formikProps={buildCampaignFormik}
            keywordsData={selectedKeywords}
          />
        );
      case 6:
        return (
          <Step6
            paymentMethod={paymentMethod}
            selectPaymentMethod={(method) => selectPaymentMethod(method)}
            formikProps={buildCampaignFormik}
          />
        );
      default:
        return null;
    }
  };

  const handlePayment = async (id: string, values: IPaymentForm) => {
    const { fullName, expirationDate, cardNumber, securityCode, ...paymentForm } = values;
    const [expirationMonth, expirationYear] = expirationDate.split("/");
    // setPaymentLoading(true);
    dispatch(showScreenLoading());

    dispatch(
      createCampaignPaymentOrder(
        id,
        {
          fullName,
          cardNumber,
          securityCode,
          expirationMonth,
          expirationYear,
        },
        paymentMethod,
        paymentForm,
        // setPaymentLoading
        () => {}
      )
    );
    // dispatch(hideScreenLoading());
  };

  return (
    <BuildCampaignModal
      $blur={isInvalidFilesShow}
      centered
      data-testid="build-campaign"
      show={popup.showBuildCampaign.show}
      size="xl"
      onExited={onExit}
    >
      <Modal.Header>
        <Modal.Title>
          <div className="build-campaign-title">
            <div className="heading">
              <b>Create a new campaign</b>
            </div>
            <div className="modal-description">
              <p className="content">{buildCampaignDescription[currentStep - 1]}</p>
            </div>
          </div>

          <div
            onClick={() => {
              if (currentCampaign.data) {
                dispatch(
                  editCampaign({
                    ...buildCampaignFormik.values,
                    id: currentCampaign.data.id,
                    bannerId:
                      currentCampaign.data.banners.length && currentCampaign.data.banners[0].id,
                    keywords: keywords.length
                      ? keywords
                      : currentCampaign.data
                      ? currentCampaign.data.theKeywords
                      : [],
                    includes,
                    excludes,
                    paymentMethod,
                    paymentForm: {
                      ...buildCampaignFormik.values.paymentForm,
                      startDate: new Date(),
                      endDate: addDays(new Date(), 30),
                    },
                  })
                );
                dispatch(resetCurrentCampaign());
              } else if (currentStep > 1) {
                dispatch(
                  createCampaign({
                    ...buildCampaignFormik.values,
                    includes,
                    excludes,
                    keywords,
                    paymentMethod,
                    paymentForm: {
                      ...buildCampaignFormik.values.paymentForm,
                      startDate: new Date(),
                      endDate: addDays(new Date(), 30),
                    },
                  })
                );
              }
              dispatch(hideBuildCampaign());
            }}
            className="close-btn"
          />
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {(currentCampaign.data && currentCampaign.data.theKeywords.length && !loading) ||
        !currentCampaign.loading ||
        (currentCampaign.data && currentStep !== 1) ? (
          <>
            <div className="steps">
              <StepList>
                <ProgressLine>
                  <Progress currentStep={currentStep} />
                </ProgressLine>
                <StepItem active={currentStep >= 1}>
                  <Step1Icon className="step-icon" />
                  <span className="step-title">1.Details</span>
                </StepItem>
                <StepItem active={currentStep >= 2}>
                  <Step2Icon className="step-icon" />
                  <span className="step-title">2.Targeting</span>
                </StepItem>
                <StepItem active={currentStep >= 3}>
                  <Step3Icon className="step-icon" />
                  <span className="step-title">3.Keywords</span>
                </StepItem>
                <StepItem active={currentStep >= 4}>
                  <Step4Icon className="step-icon" />
                  <span className="step-title">4.Creative</span>
                </StepItem>
                <StepItem active={currentStep >= 5}>
                  <Step5Icon className="step-icon" />
                  <span className="step-title">5.Review</span>
                </StepItem>
                <StepItem active={currentStep >= 6}>
                  <Step6Icon className="step-icon" />
                  <span className="step-title">6.Payment</span>
                </StepItem>
              </StepList>
            </div>

            <div className="form-section">
              <div className="progress-percent">
                <span>Progress {(currentStep - 1) * 20}% |</span>
                <span className="complete"> Completed</span>
              </div>
              <div className="scrolly" ref={scrollRef}>
                <div className="steps-form">{renderBuildCampaignStep(buildCampaignFormik)}</div>
              </div>
            </div>

            <div className="step-navigation">
              <span>
                {currentStep !== 1 && (
                  <Button onClick={() => setCurrentStep((prev) => prev - 1)} width={150}>
                    {"<< Prev"}
                  </Button>
                )}
              </span>
              {customValidation.targeting && (
                <span className="errorMessage">{customValidation.targeting}</span>
              )}
              {currentStep === 3 && Object.keys(tableData).length ? (
                <TablePagination
                  amountOfKeywords={amountOfKeywords}
                  setStartingPage={(num) => setStartingPage(num)}
                  setPageLimit={(num) => setPageLimit(num)}
                  pageLimit={pageLimit}
                  startingPage={startingPage}
                />
              ) : null}
              <span>
                {currentStep === 6 ? (
                  <Button
                    data-testid="submit"
                    onClick={() => {
                      if (popup.showBuildCampaign.id) {
                        buildCampaignFormik.validateForm().then((errorCallback) => {
                          buildCampaignFormik.setTouched(
                            {
                              card: errorCallback.card && {
                                fullName: !!errorCallback.card.fullName,
                                cardNumber: !!errorCallback.card.cardNumber,
                                expirationDate: !!errorCallback.card.expirationDate,
                                securityCode: !!errorCallback.card.securityCode,
                              },
                              paymentForm: errorCallback.paymentForm && {
                                budgetPerDay: !!errorCallback.paymentForm.budgetPerDay,
                                startDate: !!errorCallback.paymentForm.startDate,
                                endDate: !!errorCallback.paymentForm.endDate,
                              },
                            },
                            true
                          );

                          buildCampaignFormik.setErrors(errorCallback);

                          if (popup.showBuildCampaign.id) {
                            if (
                              buildCampaignFormik.values.paymentMethod === "credit card" &&
                              !errorCallback.card
                            ) {
                              if (buildCampaignFormik.values.card.fullName) {
                                handlePayment(popup.showBuildCampaign.id, {
                                  fullName: buildCampaignFormik.values.card.fullName,
                                  cardNumber: buildCampaignFormik.values.card.cardNumber,
                                  expirationDate: buildCampaignFormik.values.card.expirationDate,
                                  securityCode: buildCampaignFormik.values.card.securityCode,
                                  budgetPerDay: buildCampaignFormik.values.paymentForm.budgetPerDay,
                                  startDate: buildCampaignFormik.values.paymentForm.startDate,
                                  endDate: buildCampaignFormik.values.paymentForm.endDate,
                                });
                              }
                            }
                          }
                        });
                      } else {
                        buildCampaignFormik.submitForm();
                      }
                    }}
                  >
                    Submit
                  </Button>
                ) : (
                  <Button
                    data-testid="next-button"
                    onClick={() => {
                      buildCampaignFormik.validateForm().then((errorCallback) => {
                        if (!Object.keys(errorCallback).length) {
                          const isError = !(
                            buildCampaignFormik.values.ageGroups.length ||
                            buildCampaignFormik.values.genders.length ||
                            buildCampaignFormik.values.locations.length ||
                            Object.keys(includes).length ||
                            Object.keys(excludes).length ||
                            buildCampaignFormik.values.browsers.length ||
                            buildCampaignFormik.values.os.length ||
                            buildCampaignFormik.values.devices.length
                          );

                          if (currentStep === 2 && isError) {
                            setCustomValidation((prev) => ({
                              ...prev,
                              targeting: "*Targeting Required",
                            }));
                          } else if (currentStep === 3 && !keywords.length) {
                            setCustomValidation((prev) => ({
                              ...prev,
                              keywords: "*Keyword Required",
                            }));
                          } else {
                            setCustomValidation({ targeting: "", keywords: "" });
                            setCurrentStep((prev) => prev + 1);
                          }
                        } else {
                          buildCampaignFormik.setTouched(
                            Object.keys(errorCallback).reduce((acc, cur) => {
                              acc[cur] = true;
                              return acc;
                            }, {} as { [key: string]: boolean }),
                            true
                          );
                        }
                      });
                    }}
                    width={150}
                  >
                    {"Next >>"}
                  </Button>
                )}
              </span>
            </div>
          </>
        ) : (
          <div>
            <Spinner />
          </div>
        )}
      </Modal.Body>
    </BuildCampaignModal>
  );
};

BuildCampaign.displayName = "BuildCampaign";

export default connector(BuildCampaign);
