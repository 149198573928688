import styled from "styled-components/macro";

export const BannerPreviewImageStyled = styled.div<{
  height?: number;
  src: string;
  isHorizontal: boolean;
}>`
  background-image: url(${({ src }) => src});
  width: 100%;
  height: ${({ isHorizontal, height }) => (isHorizontal ? 50 : height || 300)}px;
  background-position: top;
  background-repeat: no-repeat;
  background-size: contain;
`;
