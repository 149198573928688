import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Button from "components/Button";
import {
  PaymentFilterStyled,
  PaymentFilterPopover,
  FilterListSelector,
  FilteringItem,
  AddFilterButton,
  ColumnOption,
} from "./styled";
import Input from "components/Input";
import { Formik, useFormik } from "formik";
import { OverlayInjectedProps } from "react-bootstrap/esm/Overlay";
import { FilterIcon } from "components/Icons";
import useOnClickOutside from "hooks/useClickOutside";
import DropdownSelect from "components/DropdownSelect";
import { Range } from "react-date-range";
import DateRange from "components/DateRange";
import { displayDate } from "constants/utility/date";
import { isSameDay } from "date-fns";
import { onSelectFilter } from "./helper";
import { FILTER_COLUMN, FILTER_COLUMN_ITEMS, FILTER_COLUMN_TEXT, IDateRange } from "./constants";
import {
  ENUM_PAYMENT_GATEWAY,
  ENUM_PAYMENT_STATUS,
  PaymentGateway,
  PaymentStatus,
} from "store/states/payments.state";
import { FILTER_INPUT } from "constants/types";
import InputRangeNumber from "components/InputRangeNumber";
import { numberWithCommas } from "constants/utility/number";

export interface IFilterColumn {
  created_at?: IDateRange;
  updated_at?: IDateRange;
  amount?: {
    min: string;
    max: string;
  };
  id?: string;
  paymentGateway?: PaymentGateway;
  state?: PaymentStatus;
  ownerName?: string;
  campaignName?: string;
}
interface IUserFilterProps {
  onFilter: (values: IFilterColumn) => void;
  onFilterStatusChange?: (status?: any) => void;
  currentFilters?: IFilterColumn;
}
export interface IFilterItem {
  filter: {
    key: string;
    format: string;
  };
  value:
    | string
    | {
        startDate?: Date;
        endDate?: Date;
        min?: string;
        max?: string;
      }
    | PaymentGateway
    | PaymentStatus;
}

const PaymentFilter: React.FunctionComponent<IUserFilterProps> = ({ onFilter }) => {
  const filterTarget = React.useRef<HTMLDivElement>(null);
  const dateRangeTarget = React.useRef<HTMLDivElement>(null);

  const [isShowFilterPopup, setShowFilterPopup] = React.useState(false);
  const [isShowDateRange, setShowDateRange] = React.useState(false);
  const [selectedDateRange, setSelectedDateRange] = React.useState<Array<Range>>([
    {
      startDate: undefined,
      endDate: undefined,
      key: "selection",
    },
  ]);

  const [filtering, setFiltering] = React.useState<Array<IFilterItem>>([]);

  const [selecting, setSelecting] = React.useState<{
    text: string;
    key: string;
    input: string;
  } | null>(null);

  useOnClickOutside(filterTarget, () => setShowFilterPopup(false));

  useOnClickOutside(dateRangeTarget, () => {
    setSelecting(null);
    setShowDateRange(false);
  });

  const filterPopover = (props: OverlayInjectedProps) => {
    const cols = filtering.map((current) => current.filter.key);
    return (
      <Tooltip style={{ opacity: 1 }} ref={filterTarget} id="filter-tooltip">
        <FilterListSelector
          {...props}
          style={{
            ...props.style,
          }}
          id="filter-popover"
        >
          <ul>
            {FILTER_COLUMN_ITEMS.map((col) => {
              return (
                <ColumnOption
                  disabled={cols.includes(col.key)}
                  key={col.key}
                  onClick={() => {
                    setShowFilterPopup(false);
                    setSelecting(col);
                    if (col.input === FILTER_INPUT.DATE_RANGE) {
                      setShowDateRange(true);
                    }
                  }}
                >
                  {col.text}
                </ColumnOption>
              );
            })}
          </ul>
        </FilterListSelector>
      </Tooltip>
    );
  };

  const dateRangePopover = (props: OverlayInjectedProps) => {
    return (
      <Tooltip style={{ opacity: 1 }} ref={dateRangeTarget} id="date-range-tooltip">
        <PaymentFilterPopover
          {...props}
          style={{
            ...props.style,
          }}
          id="date-range-popover"
        >
          <DateRange
            ranges={selectedDateRange}
            onSelection={(range) => {
              setSelectedDateRange([range]);
              if (range.startDate && range.endDate && !isSameDay(range.startDate, range.endDate)) {
                setFiltering((prev) => {
                  setSelecting(null);
                  if (selecting) {
                    return onSelectFilter(selecting, prev, FILTER_INPUT.DATE_RANGE, {
                      startDate: range.startDate,
                      endDate: range.endDate,
                    });
                  } else {
                    return prev;
                  }
                });

                selecting &&
                  onFilter({
                    [selecting.key]: {
                      startDate: range.startDate,
                      endDate: range.endDate,
                    },
                  });

                setShowDateRange(false);

                setSelectedDateRange([
                  {
                    startDate: undefined,
                    endDate: undefined,
                    key: "selection",
                  },
                ]);
              }
            }}
          />
        </PaymentFilterPopover>
      </Tooltip>
    );
  };

  const submitFilter = (values: { text: string }) => {
    setSelecting(null);

    if (selecting && values.text.trim()) {
      setFiltering((prev) => {
        return onSelectFilter(selecting, prev, FILTER_INPUT.TEXT, values.text);
      });

      onFilter({
        [selecting.key]: values.text,
      });
    }

    formik.resetForm();
  };

  const submitRangeFilter = (values: { min: string; max: string }) => {
    setSelecting(null);

    selecting &&
      setFiltering((prev) => {
        return onSelectFilter(selecting, prev, FILTER_INPUT.NUMBER_RANGE, values);
      });

    selecting &&
      onFilter({
        [selecting.key]: values,
      });

    numRange.resetForm();
  };

  const formik = useFormik({
    onSubmit: submitFilter,
    initialValues: {
      text: "",
    },
  });

  const numRange = useFormik({
    onSubmit: submitRangeFilter,
    initialValues: {
      min: "0",
      max: "10000",
    },
  });

  const renderFilterInput = (selecting: { input: string; key: string; text: string }) => {
    switch (selecting.input) {
      case FILTER_INPUT.TEXT:
        return (
          <Formik
            onSubmit={submitFilter}
            initialValues={{
              text: "",
            }}
          >
            {({ touched, errors, values, handleChange, handleSubmit }) => {
              return (
                <form className="filter-form" onSubmit={handleSubmit}>
                  <Input
                    dark
                    autoComplete="off"
                    touched={touched.text}
                    error={errors.text}
                    name="text"
                    value={values.text}
                    onChange={handleChange}
                  />
                  <Button secondary type="submit">
                    Apply
                  </Button>
                </form>
              );
            }}
          </Formik>
        );

      case FILTER_INPUT.SELECT:
        const options =
          selecting.key === FILTER_COLUMN.PAYMENT_GATEWAY
            ? [{ text: "Stripe", value: ENUM_PAYMENT_GATEWAY.STRIPE }]
            : selecting.key === FILTER_COLUMN.STATUS
            ? [
                { value: ENUM_PAYMENT_STATUS.APPROVED, text: "Approved" },
                { value: ENUM_PAYMENT_STATUS.FAILED, text: "Failed" },
                { value: ENUM_PAYMENT_STATUS.WAITING, text: "Waiting For Payment" },
              ]
            : [];

        return (
          <form className="filter-form" onSubmit={formik.handleSubmit}>
            {/* {renderOptions(selecting.key)} */}
            <DropdownSelect
              border={false}
              searchable={false}
              className="dropdown-select"
              labelField="text"
              valueField="value"
              onChange={(status: Array<{ value: string }>) => {
                formik.setFieldValue("text", status[0].value.toString());
              }}
              values={[{ name: formik.values.text }]}
              options={options}
            />
            <Button disabled={!formik.values.text} type="submit" secondary>
              Apply
            </Button>
          </form>
        );

      case FILTER_INPUT.NUMBER_RANGE:
        return (
          <form className="filter-form" onSubmit={numRange.handleSubmit}>
            <InputRangeNumber
              min={0}
              max={10000}
              onChange={(values) => {
                numRange.setFieldValue("min", values.min);
                numRange.setFieldValue("max", values.max);
              }}
            />
            <Button secondary type="submit">
              Apply
            </Button>
          </form>
        );

      default:
        return null;
    }
  };
  return (
    <PaymentFilterStyled>
      <div className="filtering-tab">
        <div className="filters">
          <FilterIcon />
          <span> Filters:</span>
        </div>
      </div>

      {filtering.map((filterList) => {
        return (
          <FilteringItem key={filterList.filter.key}>
            <div className="current-column">
              {FILTER_COLUMN_TEXT[filterList.filter.key as keyof IFilterColumn]}
            </div>
            {filterList.filter.format === FILTER_INPUT.DATE_RANGE &&
            typeof filterList.value !== "string" &&
            !Array.isArray(filterList.value) &&
            filterList.value.startDate &&
            filterList.value.endDate ? (
              <div>
                <span>{`${displayDate(filterList.value.startDate)} - ${displayDate(
                  filterList.value.endDate
                )}`}</span>
              </div>
            ) : Array.isArray(filterList.value) ? (
              <ul className="multi-list">
                {filterList.value.map((multiItem) => (
                  <li key={multiItem}>{multiItem}</li>
                ))}
              </ul>
            ) : filterList.filter.format === FILTER_INPUT.NUMBER_RANGE &&
              typeof filterList.value !== "string" &&
              !Array.isArray(filterList.value) ? (
              <span>{`$${numberWithCommas(filterList.value.min || 0)} - $${numberWithCommas(
                filterList.value.max || 1
              )}`}</span>
            ) : (
              <span>{filterList.value.toString()}</span>
            )}
            <span
              className="remove-filter"
              onClick={() => {
                setFiltering((prev) =>
                  prev.filter((item) => item.filter.key !== filterList.filter.key)
                );
                onFilter({
                  [filterList.filter.key as keyof IFilterColumn]: "",
                });
              }}
            />
          </FilteringItem>
        );
      })}

      {filtering.length !== FILTER_COLUMN_ITEMS.length && (
        <OverlayTrigger
          show={isShowFilterPopup}
          placement="bottom-start"
          trigger="click"
          overlay={filterPopover}
        >
          <span>
            {!selecting && (
              <AddFilterButton onClick={() => setShowFilterPopup(!isShowFilterPopup)}>
                +
              </AddFilterButton>
            )}
          </span>
        </OverlayTrigger>
      )}

      <OverlayTrigger
        show={!!selecting && isShowDateRange}
        placement="bottom-start"
        trigger="click"
        overlay={dateRangePopover}
      >
        <div className="filter-content">
          {selecting && (
            <div className="current-input">
              {<span className="selecting-col">{selecting.text}</span>}
              <div className="current-filter-wrapper">
                {renderFilterInput(selecting)}

                {selecting.input !== FILTER_INPUT.DATE_RANGE && (
                  <span
                    className="hide-filter"
                    onClick={() => {
                      setSelecting(null);
                    }}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </OverlayTrigger>
    </PaymentFilterStyled>
  );
};

export default PaymentFilter;
