import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Button from "../Button";
import {
  TableFilterStyled,
  TableFilterPopover,
  FilterListSelector,
  FilteringItem,
  AddFilterButton,
  ColumnOption,
} from "./styled";
import Input from "../Input";
import { Formik } from "formik";
import { OverlayInjectedProps } from "react-bootstrap/esm/Overlay";
import { FilterIcon } from "../Icons";
import useOnClickOutside from "hooks/useClickOutside";
import {
  CampaignStatus,
  CAMPAIGN_SOURCE_TEXT,
  ENUM_CAMPAIGN_SOURCE,
  ENUM_CAMPAIGN_STATUS,
} from "store/states/campaign.state";
import DropdownSelect from "../DropdownSelect";
import { Range } from "react-date-range";
import DateRange from "../DateRange";
import { displayDate } from "constants/utility/date";
import { isSameDay } from "date-fns";
import { onSelectFilter } from "./helper";
import { FILTER_COLUMN_ITEMS, FILTER_COLUMN_TEXT, IDateRange } from "./constants";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import { FILTER_INPUT } from "constants/types";

export interface IFilterColumn {
  userName?: string;
  name?: string;
  startDate?: IDateRange;
  endDate?: IDateRange;
  created_at?: IDateRange;
  owner_email?: string;
  status?: CampaignStatus;
  source?: "beeswax" | "adform";
}
interface ITableFilterProps {
  onFilter: (values: IFilterColumn) => void;
  onFilterStatusChange: (status?: CampaignStatus) => void;
  currentFilters?: IFilterColumn;
  admin?: boolean;
}
export interface IFilterItem {
  filter: {
    key: string;
    format: string;
  };
  value: string | { startDate?: Date; endDate?: Date };
}

const TableFilter: React.FunctionComponent<ITableFilterProps> = ({ onFilter, admin }) => {
  const location = useLocation();

  const filterTarget = React.useRef<HTMLDivElement>(null);
  const dateRangeTarget = React.useRef<HTMLDivElement>(null);

  const [isShowFilterPopup, setShowFilterPopup] = React.useState(false);
  const [isShowDateRange, setShowDateRange] = React.useState(false);
  const [selectedDateRange, setSelectedDateRange] = React.useState<Array<Range>>([
    {
      startDate: undefined,
      endDate: undefined,
      key: "selection",
    },
  ]);

  const [filtering, setFiltering] = React.useState<Array<IFilterItem>>([]);

  const [selecting, setSelecting] = React.useState<{
    text: string;
    key: string;
    input: string;
  } | null>(null);

  useOnClickOutside(filterTarget, () => setShowFilterPopup(false));

  useOnClickOutside(dateRangeTarget, () => {
    setSelecting(null);
    setShowDateRange(false);
  });

  React.useEffect(() => {
    if (location.search) {
      const parsed = queryString.parse(location.search);

      setFiltering([
        {
          filter: {
            key: "owner_email",
            format: FILTER_INPUT.TEXT,
          },
          value: parsed.owner_email as string,
        },
      ]);
      onFilter({
        owner_email: parsed.owner_email as string,
      });
    }
  }, [location.search]);

  const filterPopover = (props: OverlayInjectedProps) => {
    const cols = filtering.map((current) => current.filter.key);
    return (
      <Tooltip style={{ opacity: 1 }} ref={filterTarget} id="filter-tooltip">
        <FilterListSelector
          {...props}
          style={{
            ...props.style,
          }}
          id="filter-popover"
        >
          <ul>
            {(admin ? FILTER_COLUMN_ITEMS : FILTER_COLUMN_ITEMS.slice(2)).map((col) => {
              return (
                <ColumnOption
                  disabled={cols.includes(col.key)}
                  key={col.key}
                  onClick={() => {
                    setShowFilterPopup(false);
                    setSelecting(col);
                    if (col.input === FILTER_INPUT.DATE_RANGE) {
                      setShowDateRange(true);
                    }
                  }}
                >
                  {col.text}
                </ColumnOption>
              );
            })}
          </ul>
        </FilterListSelector>
      </Tooltip>
    );
  };

  const dateRangePopover = (props: OverlayInjectedProps) => {
    return (
      <Tooltip style={{ opacity: 1 }} ref={dateRangeTarget} id="date-range-tooltip">
        <TableFilterPopover
          {...props}
          style={{
            ...props.style,
          }}
          id="date-range-popover"
        >
          <DateRange
            ranges={selectedDateRange}
            onSelection={(range) => {
              setSelectedDateRange([range]);
              if (range.startDate && range.endDate && !isSameDay(range.startDate, range.endDate)) {
                setFiltering((prev) => {
                  setSelecting(null);
                  if (selecting) {
                    return onSelectFilter(selecting, prev, FILTER_INPUT.DATE_RANGE, {
                      startDate: range.startDate,
                      endDate: range.endDate,
                    });
                  } else {
                    return prev;
                  }
                });

                selecting &&
                  onFilter({
                    [selecting.key]: {
                      startDate: range.startDate,
                      endDate: range.endDate,
                    },
                  });

                setShowDateRange(false);

                setSelectedDateRange([
                  {
                    startDate: undefined,
                    endDate: undefined,
                    key: "selection",
                  },
                ]);
              }
            }}
          />
        </TableFilterPopover>
      </Tooltip>
    );
  };

  const submitFilter = (values: { text: string }) => {
    setSelecting(null);

    setFiltering((prev) => {
      if (selecting && values.text.trim()) {
        return onSelectFilter(selecting, prev, FILTER_INPUT.TEXT, values.text);
      } else {
        return prev;
      }
    });

    selecting &&
      values.text.trim() &&
      onFilter({
        [selecting.key]: values.text,
      });
  };

  const renderFilterInput = (input: string) => {
    switch (input) {
      case FILTER_INPUT.TEXT:
        return (
          <Formik
            onSubmit={submitFilter}
            initialValues={{
              text: "",
            }}
          >
            {({ touched, errors, values, handleChange, handleSubmit }) => {
              return (
                <form className="filter-form" onSubmit={handleSubmit}>
                  <Input
                    dark
                    autoComplete="off"
                    touched={touched.text}
                    error={errors.text}
                    name="text"
                    value={values.text}
                    onChange={handleChange}
                  />
                  <Button secondary type="submit">
                    Apply
                  </Button>
                </form>
              );
            }}
          </Formik>
        );

      case FILTER_INPUT.SELECT:
        return (
          <Formik
            onSubmit={submitFilter}
            initialValues={{
              text: "",
            }}
          >
            {({ values, setFieldValue, handleSubmit }) => {
              return (
                <form className="filter-form" onSubmit={handleSubmit}>
                  <DropdownSelect
                    border={false}
                    searchable={false}
                    className="dropdown-select"
                    labelField="text"
                    valueField="value"
                    onChange={(status: Array<{ value: string }>) => {
                      setFieldValue("text", status[0].value);
                    }}
                    values={[{ name: values.text }]}
                    options={
                      selecting?.key === "source"
                        ? [
                            {
                              value: ENUM_CAMPAIGN_SOURCE.BEESWAX,
                              text: CAMPAIGN_SOURCE_TEXT[ENUM_CAMPAIGN_SOURCE.BEESWAX],
                            },
                            {
                              value: ENUM_CAMPAIGN_SOURCE.ADFORM,
                              text: CAMPAIGN_SOURCE_TEXT[ENUM_CAMPAIGN_SOURCE.ADFORM],
                            },
                          ]
                        : [
                            {
                              value: ENUM_CAMPAIGN_STATUS.ACTIVE,
                              text: ENUM_CAMPAIGN_STATUS.ACTIVE,
                            },
                            {
                              value: ENUM_CAMPAIGN_STATUS.PENDING,
                              text: ENUM_CAMPAIGN_STATUS.PENDING,
                            },
                            {
                              value: ENUM_CAMPAIGN_STATUS.DRAFT,
                              text: ENUM_CAMPAIGN_STATUS.DRAFT,
                            },
                            {
                              value: ENUM_CAMPAIGN_STATUS.INACTIVE,
                              text: ENUM_CAMPAIGN_STATUS.INACTIVE,
                            },
                          ]
                    }
                  />
                  <Button type="submit" secondary>
                    Apply
                  </Button>
                </form>
              );
            }}
          </Formik>
        );

      default:
        return null;
    }
  };

  return (
    <TableFilterStyled>
      <div className="filtering-tab">
        <div className="filters">
          <FilterIcon />
          <span> Filters:</span>
        </div>
      </div>

      {filtering.map((filterList) => {
        return (
          <FilteringItem key={filterList.filter.key}>
            <div className="current-column">
              {FILTER_COLUMN_TEXT[filterList.filter.key as keyof IFilterColumn]}
            </div>
            {filterList.filter.format === FILTER_INPUT.DATE_RANGE &&
            typeof filterList.value !== "string" &&
            filterList.value.startDate &&
            filterList.value.endDate ? (
              <div>
                <span>{`${displayDate(filterList.value.startDate)} - ${displayDate(
                  filterList.value.endDate
                )}`}</span>
              </div>
            ) : filterList.filter.key === "source" ? (
              <span>{CAMPAIGN_SOURCE_TEXT[filterList.value as ENUM_CAMPAIGN_SOURCE]}</span>
            ) : (
              <span>{filterList.value.toString()}</span>
            )}
            <span
              className="remove-filter"
              onClick={() => {
                setFiltering((prev) =>
                  prev.filter((item) => item.filter.key !== filterList.filter.key)
                );
                onFilter({
                  [filterList.filter.key as keyof IFilterColumn]: "",
                });
              }}
            />
          </FilteringItem>
        );
      })}

      {filtering.length !==
        (admin ? FILTER_COLUMN_ITEMS.length : FILTER_COLUMN_ITEMS.length - 1) && (
        <OverlayTrigger
          show={isShowFilterPopup}
          placement="bottom-start"
          trigger="click"
          overlay={filterPopover}
        >
          <span>
            {!selecting && (
              <AddFilterButton onClick={() => setShowFilterPopup(!isShowFilterPopup)}>
                +
              </AddFilterButton>
            )}
          </span>
        </OverlayTrigger>
      )}
      <OverlayTrigger
        show={isShowDateRange}
        placement="bottom-start"
        trigger="click"
        overlay={dateRangePopover}
      >
        <div className="filter-content">
          {selecting && (
            <div className="current-input">
              <span className="selecting-col"> {selecting.text} </span>
              <div className="current-filter-wrapper">{renderFilterInput(selecting.input)}</div>
              <span className="hide-filter" onClick={() => setSelecting(null)} />
            </div>
          )}
        </div>
      </OverlayTrigger>
    </TableFilterStyled>
  );
};

export default TableFilter;
