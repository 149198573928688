import { IErrorMessage } from "../../services/types";

export const GET_SAVED_KEYWORDS_LIST = "savedKeywords-actions/GET_SAVED_KEYWORDS_LIST";
export interface GetSavedKeywordsListAction {
  type: typeof GET_SAVED_KEYWORDS_LIST;
}

export const GET_SAVED_KEYWORDS_LIST_REQUEST =
  "savedKeywords-actions/GET_SAVED_KEYWORDS_LIST_REQUEST";
export interface GetSavedKeywordsListRequestAction {
  type: typeof GET_SAVED_KEYWORDS_LIST_REQUEST;
}

export const GET_SAVED_KEYWORDS_LIST_SUCCESS =
  "savedKeywords-actions/GET_SAVED_KEYWORDS_LIST_SUCCESS";
export interface GetSavedKeywordsListSuccessAction {
  type: typeof GET_SAVED_KEYWORDS_LIST_SUCCESS;
  payload: Array<{ id: string; name: string }>;
}

export const GET_SAVED_KEYWORDS_LIST_FAILURE =
  "savedKeywords-actions/GET_SAVED_KEYWORDS_LIST_FAILURE";
export interface GetSavedKeywordsListFailureAction {
  type: typeof GET_SAVED_KEYWORDS_LIST_FAILURE;
  error: IErrorMessage;
}
