import { Modal } from "react-bootstrap";
import styled from "styled-components/macro";

export const DuplicateCampaignForm = styled.div`
  .modal-action-buttons {
    display: flex;
    gap: 10px;
    margin-top: 40px;
    justify-content: center;
  }
`;

export const StyledModal = styled(Modal)`
  .modal-content {
    background-color: ${({ theme }) => theme.alertModalBackground};
    border: 1px solid ${({ theme }) => theme.dark};
    border-radius: 10px;
    padding: 15px;

    .form-label {
      color: ${({ theme }) => theme.contrast};
      font-size: 0.876em;
      font-weight: 400;
      line-height: 21px;
      margin-bottom: 8px;
      font-weight: ${({ theme }) => theme.weight.bold};
    }
  }

  .modal-header {
    justify-content: center;
    text-align: center;
    color: ${({ theme }) => theme.color.green};
    border: none;
  }
`;
