import styled, { css } from "styled-components/macro";

const homeLight = require("assets/register/light/home.png");
const compareLight = require("assets/register/light/compare.png");
const campaignLight = require("assets/register/light/campaign.png");

const homeDark = require("assets/register/dark/home.png");
const compareDark = require("assets/register/dark/compare.png");
const campaignDark = require("assets/register/dark/campaign.png");

export const RegisterPageStyled = styled.form<{ currentTheme: "light" | "dark" }>`
  line-height: 20px;
  padding: 50px 0 0 50px;
  display: flex;
  height: 100%;
  width: 100%;
  overflow: hidden;

  .login,
  .signup,
  .title {
    text-align: left;
  }

  .graphics {
    position: relative;
    width: 100%;
    margin-left: 50px;

    .home-sample {
      position: absolute;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      border-radius: 10px;
      width: 680px;
      height: 435px;
      top: -55px;
      left: 0px;

      .icon-wrapper {
        top: 70px;
        right: 20px;
        z-index: 1;
        border-radius: 50%;
        border: 1px solid ${({ theme }) => theme.cpc};
        padding: 10px;

        &:before {
          content: "";
          width: 100%;
          position: absolute;
          top: 0;
          height: 100%;
          left: 0;
          border-radius: 50%;
          background-color: ${({ theme }) => theme.cpc};
        }

        > svg,
        path,
        line,
        polyline {
          stroke: ${({ theme, currentTheme }) =>
            currentTheme === "light" ? "#ffffff" : theme.cpc};
        }
      }
    }

    .compare-sample {
      position: absolute;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      border-radius: 10px;
      width: 450px;
      height: 320px;
      top: 160px;
      left: 260px;

      .icon-wrapper {
        right: 81px;
        bottom: 11px;
        z-index: 1;
        border-radius: 50%;
        border: 1px solid ${({ theme }) => theme.color.primary};
        padding: 10px;

        &:before {
          content: "";
          width: 100%;
          position: absolute;
          top: 0;
          height: 100%;
          left: 0;
          border-radius: 50%;
          background-color: ${({ theme }) => theme.color.primary};
        }

        > svg,
        path,
        line,
        polyline {
          stroke: ${({ theme, currentTheme }) =>
            currentTheme === "light" ? "#ffffff" : theme.color.primary};
        }
      }
    }

    .campaign-sample {
      position: absolute;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      border-radius: 10px;
      width: 370px;
      height: 315px;
      top: 254px;
      left: -47px;

      .icon-wrapper {
        left: 35px;
        z-index: 1;
        bottom: 21px;
        border-radius: 50%;
        border: 1px solid ${({ theme }) => theme.color.blue};
        padding: 10px;

        &:before {
          content: "";
          width: 100%;
          position: absolute;
          top: 0;
          height: 100%;
          left: 0;
          border-radius: 50%;
          background-color: ${({ theme }) => theme.color.blue};
        }

        > svg,
        path,
        line,
        polyline {
          stroke: ${({ theme, currentTheme }) =>
            currentTheme === "light" ? "#ffffff" : theme.color.blue};
        }
      }
    }

    .icon-wrapper {
      position: absolute;

      > svg,
      path,
      line,
      polyline {
        width: 20px;
        height: 20px;
      }
    }

    ${({ currentTheme }) =>
      currentTheme === "light"
        ? css`
            .home-sample {
              background-image: url(${homeLight});
            }

            .compare-sample {
              background-image: url(${compareLight});
            }

            .campaign-sample {
              background-image: url(${campaignLight});
            }

            .icon-wrapper {
              border: none !important;
              &:before {
                z-index: -1;
                opacity: 1;
              }
            }
          `
        : css`
            .home-sample {
              background-image: url(${homeDark});
            }

            .compare-sample {
              background-image: url(${compareDark});
            }

            .campaign-sample {
              background-image: url(${campaignDark});
            }

            .icon-wrapper {
              &:before {
                z-index: 0;
                opacity: 0.2;
              }
            }
          `}
  }

  .checkbox,
  .terms {
    color: ${({ theme }) => theme.text};
  }

  .checkbox {
    display: flex;

    .checkbox-wrapper {
      //
    }
  }

  .section {
    margin-top: 20px;

    a {
      margin: 0px 5px 0px 5px;
    }
  }

  .form-field {
    margin-bottom: 1em;
    position: relative;

    .show-password {
      opacity: 0.4;
      position: absolute;
      top: 0;
      right: 0;
      padding: 7px 12px;
      cursor: pointer;

      > svg,
      path,
      line,
      circle {
        width: 20px;
        height: 20px;
        stroke: ${({ theme }) => theme.contrast};
      }
    }

    .show {
      opacity: 1;
    }
  }

  .react-dropdown-select-input {
    font-size: 12px;
  }

  .offer-label {
    white-space: pre;
    padding-left: 10px;
  }
`;
