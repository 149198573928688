import React from "react";

const CloudIcon = ({ className }: { className?: string }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 98.01 58.55" className={className}>
      <defs>
        <style>{".e{fill:#fff;}"}</style>
      </defs>
      <g id="a" />
      <g id="b">
        <g id="c">
          <path
            id="d"
            className="e"
            d="M83.7,29.93c-.59,0-1.17,.04-1.75,.11,0-.26,.02-.51,.02-.77C81.97,13.11,68.86,0,52.7,0c-13.69,0-25.56,9.49-28.56,22.85-9.6-3.21-19.98,1.97-23.18,11.56-3.21,9.6,1.97,19.98,11.56,23.18,1.87,.63,3.83,.95,5.81,.95H82.69v-.04c.33,.02,.67,.04,1.01,.04,7.9,0,14.31-6.4,14.31-14.3,0-7.9-6.4-14.31-14.3-14.31h0m-20.83,4.95h-2.91c-.78,0-1.42,.64-1.42,1.42h0v12.21c0,.78-.64,1.42-1.42,1.42h-14.62c-.78,0-1.42-.64-1.42-1.42h0v-12.21c0-.78-.64-1.42-1.42-1.42h-2.27c-.78,0-1.42-.64-1.42-1.42,0-.42,.18-.81,.5-1.08l12.67-10.98c.54-.46,1.33-.45,1.85,.01l12.81,10.98c.59,.52,.65,1.41,.13,2-.27,.31-.66,.48-1.07,.48"
          />
        </g>
      </g>
    </svg>
  );
};

export default CloudIcon;
