import { Form } from "react-bootstrap";
import styled from "styled-components";

export const SelectStyled = styled.div`
  position: relative;
  width: 120px;

  /* &:before {
    content: "";
    transition: 0.3s;
    right: 10px;
    top: 10px;
    position: absolute;
    border: solid ${({ theme }) => theme.text};
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
  } */

  .select__menu {
    font-size: 12px;
    background-color: rgb(204, 204, 204);

    .select__option {
      padding: 6px 12px;
    }

    .select__option--is-focused {
      cursor: pointer;
      background-color: rgb(215, 215, 215);
    }

    .select__option--is-selected {
      background-color: rgb(100, 100, 100);
    }
  }

  .select__input {
    height: 0px;
  }

  /* .select__value-container {
    height: 13px;

    .select__input-container {
      height: 13px;
    }
  } */

  /* .select__single-value {
    font-size: 14px;
    transform: translate(0px, -2px);
  } */

  .select__value-container {
    height: 20px !important;
    align-items: baseline;
  }

  .select__control {
    min-height: 28px;
    border: 1px solid rgb(209, 209, 209);
    font-size: 12px;
    font-weight: 600;
    background: linear-gradient(180deg, rgba(221, 221, 221, 1) 0%, rgba(242, 242, 242, 1) 100%);
    box-shadow: none !important;

    .select__indicator {
      width: 20px;
      padding: 3px;
    }

    &:hover {
      border: 1px solid rgb(209, 209, 209);
      /* min-height: 20px; */
    }
  }
`;
