// import { DarkTheme, DefaultTheme, LightTheme } from "styled-components";

const theme = {
  font: {
    primary: "avenirNextMedium",
  },
  color: {
    primary: "rgb(254, 0, 153)",
    primaryDark: "rgb(194, 0, 117)",
    secondary: "rgb(114, 116, 120)",
    darker: "rgb(40, 60, 76)",
    error: "rgb(220, 20, 60)",
    blue: "rgb(39, 90, 255)",
    green: "rgb(0, 255, 102)",
    gold: "rgb(255, 188, 0)",
    purple: "rgb(131,0,221)",
    grayBorder: "#525252",
    scroll: "rgb(68, 68, 68)",
    tableBorder: "rgb(73,73,73)",
    scrollTrack: "inset 0px 10px 10px rgb(255 255 255 / 10%)",
    gray: "#838383",
    placeholder: "rgb(180, 180, 180)",
  },
  size: {
    sm: "10px",
    md: "12px", // 0.875em
    lg: "14px",
    xl: "16px",
    xxl: "18px",
  },
  weight: {
    bold: 600,
    normal: 500,
  },
  specific: {
    sidebarColor: "rgb(255, 255, 255)",
    activeTextColor: "rgb(255, 255, 255)",
    textColor: "#cad9ff",
    sidebarNavText: "#a7acbd",
    sidebarGutter: "35px",
    topOffset: "50px",
  },
};

const NEUTRAL_DARK = {
  900: "",
  800: "rgb(131,131,131)",
  700: "",
  600: "rgb(73, 73, 73)",
  500: "",
  400: "rgb(66, 66, 66)",
  300: "",
  200: "rgb(51, 51, 51)",
  100: "",
  0: "rgb(34,34,34)",
};

const NEUTRAL_LIGHT = {
  900: "",
  800: "rgb(243,243,243)",
  700: "",
  600: "rgb(255,255,255)",
  500: "",
  400: "rgb(255, 255, 255)",
  300: "",
  200: "rgb(255, 255, 255)",
  100: "",
  0: "rgb(255, 255, 255)",
};

export const darkTheme = {
  neutral: NEUTRAL_DARK,

  lightBorder: "rgb(80, 80, 80)",
  light: "rgb(179, 179, 179)",
  text: "rgb(131, 131, 131)",
  dark: "rgb(66,66,66)",
  reviewBackground: "rgb(66,66,66)",
  background: "rgb(51, 51, 51)",
  border: "rgb(66,66,66)",
  contrast: "rgb(231, 231, 231)",
  nav: "rgb(34,34,34)",
  auth: "rgb(66,66,66)",
  link: "rgb(0, 255, 102)",
  input: "rgb(34,34,34)",
  inputSecondary: "rgb(51,51,51)",
  inputDark: "rgb(66,66,66)",
  inputBorder: "rgb(66,66,66)",
  autofill: "0 0 0 1000px rgb(34 34 34) inset",
  tableBorder: "rgb(73,73,73)",
  modalBackground: "rgb(51, 51, 51)",
  highlight: "rgb(0, 255, 102)",
  secondaryHighlight: "rgb(255, 188, 0)",
  inactive: "rgb(255, 255, 255)",
  checkbox: "rgb(100, 100, 100)",
  checkboxBorder: "rgb(131, 131, 131)",
  searchButton: "rgb(51, 51, 51)",
  keywordsTableBorder: "rgb(60, 60, 60)",
  network: "#c4cbe1",
  score: "#fe0099",
  cpc: "rgb(0, 222, 80)",
  homeIcon: "rgb(131, 131, 131)",
  homeLink: "rgb(255, 255, 255)",
  targeting: "rgb(179, 179, 179)",
  scrollTrack: "inset 20px 20px 20px 20px rgb(120 120 120)",
  secondaryScrollTrack: "inset 0px 10px 10px rgb(0 0 0 / 30%)",
  secondaryScroll: "rgb(85, 85, 85)",
  dropdown: "rgb(0,0,0)",
  secondary: "rgb(0, 255, 102)",
  tableContent: "rgb(150, 150, 150)",
  previewTextColor: "rgb(180, 180, 180)",
  invertIcon: "invert(1)",
  reverseIcon: "invert(0)",
  alertModalBackground: "rgb(0,0,0)",
  dropdownMenu: "rgb(30, 30, 30)",
  disabled: "rgb(90, 90, 90)",
  authLogo: "rgb(255, 255, 255)",
  creativeReq: "rgb(34,34,34)",
  secondary100: "rgb(255, 188, 0)",
  secondary300: "rgb(255, 188, 0)",
  secondary900: "rgb(0, 255, 102)",
  selectedSavedList: "rgb(254, 0, 153)",
  radio: "rgb(100, 100, 100)",
  selectAll: "rgb(100, 100, 100)",
  selectAllBorder: "rgb(150, 150, 150)",
  tabSelected: "rgb(131,131,131)",
  dropdownDisabled: "rgb(66,66,66)",
  planHovering: "rgb(66, 66, 66)",
};

export const lightTheme = {
  neutral: NEUTRAL_LIGHT,

  lightBorder: "rgb(230, 230, 230)",
  light: "rgb(248, 248, 248)",
  text: "rgb(51,51,51)",
  dark: "rgb(195, 195, 195)",
  reviewBackground: "rgb(240, 240, 240)",
  background: "rgb(240, 240, 240)",
  border: "#f3f3f3",
  contrast: "rgb(51,51,51)",
  nav: "rgb(231, 231, 231)",
  auth: "rgb(248, 248, 248)",
  link: "rgb(131,0,221)",
  input: "rgb(255, 255, 255)",
  inputSecondary: "rgb(230,230,230)",
  inputDark: "rgb(240, 240, 240)",
  inputBorder: "rgb(230, 230, 230)",
  autofill: "0 0 0 1000px rgb(255 255 255) inset",
  tableBorder: "rgb(240,240,240)",
  modalBackground: "rgb(226, 226, 226)",
  highlight: "rgb(254, 0, 153)",
  secondaryHighlight: "rgb(131,0,221)",
  inactive: "rgb(154,154,154)",
  checkbox: "rgb(204,204,204)",
  checkboxBorder: "rgb(243, 243, 243)",
  searchButton: "rgb(34,34,34)",
  keywordsTableBorder: "rgb(238, 238, 238)",
  network: "rgb(37,71,86)",
  score: "rgb(72, 108, 174)",
  cpc: "rgb(131,0,221)",
  homeIcon: "rgb(197, 197, 197)",
  homeLink: "rgb(51,51,51)",
  targeting: "rgb(51,51,51)",
  scrollTrack: "inset 20px 20px 20px 20px rgb(240 240 240)",
  secondaryScrollTrack: "inset 0px 10px 10px rgb(255 255 255)",
  secondaryScroll: "rgb(204, 204, 204)",
  dropdown: "rgb(204,204,204)",
  secondary: "rgb(254, 0, 153)",
  tableContent: "rgb(150, 150, 150)",
  previewTextColor: "rgb(51, 51, 51)",
  invertIcon: "invert(0)",
  reverseIcon: "invert(1)",
  alertModalBackground: "rgb(230,230,230)",
  dropdownMenu: "rgb(30, 30, 30)",
  disabled: "rgb(180, 180, 180)",
  authLogo: "rgb(254, 0, 153)",
  creativeReq: "rgb(211, 211, 211)",
  secondary100: "rgb(39, 90, 255)",
  secondary300: "rgb(131,0,221)",
  secondary900: "rgb(39, 90, 255)",
  selectedSavedList: "rgb(255, 255 ,255)",
  radio: "rgb(255, 255, 255)",
  selectAll: "rgb(240, 240, 240)",
  selectAllBorder: "rgb(200, 200, 200)",
  tabSelected: "rgb(100,100,100)",
  dropdownDisabled: "rgb(150,150,150)",
  planHovering: "rgb(250, 250, 250)",
};

export default theme;
