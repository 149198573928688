import styled from "styled-components";

const successImg = require("assets/images/success.svg").default;
const errorImg = require("assets/images/error.svg").default;

const StyledStoreSignal = styled.div`
  width: 100%;
  min-height: 100%;
  .container-save {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .container-save-child {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 500px;
    align-items: center;
  }

  .container-save-h {
    width: 100%;
    text-align: center;
    font-size: 18px;
    font-family: "Open Sans", sans-serif;
    line-height: 27px;
  }

  .container-save-p {
    width: 100%;
    /* text-align: center; */
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    font-family: "Open Sans", sans-serif;
    padding-left: 5px;
  }

  .container-save-name {
    width: 100%;
  }

  .container-save-btns {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 20px;
  }

  .success-img {
    background-repeat: no-repeat;
    background-image: url(${successImg});
    width: 150px;
    height: 150px;
    background-position: center;
    scale: 0.5;
  }

  .container-success {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .error-img {
    background-repeat: no-repeat;
    background-image: url(${errorImg});
    width: 500px;
    height: 500px;
    background-position: center;
    scale: 0.2;
  }

  .container-error {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export default StyledStoreSignal;
