import { Modal } from "react-bootstrap";
import styled from "styled-components/macro";

export const CustomModal = styled(Modal)`
  .modal-content {
    background-color: ${({ theme }) => theme.alertModalBackground};
    border: 1px solid ${({ theme }) => theme.dark};
    border-radius: 10px;
    padding: 15px;
  }

  .modal-header {
    justify-content: center;
    text-align: center;
    color: ${({ theme }) => theme.secondary};
    border: none;
  }

  .modal-body {
    .export-content {
      display: flex;
      justify-content: center;
      gap: 30px;

      .checkbox-wrapper {
        display: flex;
        color: ${({ theme }) => theme.contrast};
        font-size: 12px;
        gap: 15px;
        align-items: center;
        cursor: pointer;
      }
    }
  }

  .modal-footer {
    justify-content: center;
    border: none;

    .btn {
      background: ${({ theme }) => theme.color.primary};
      font-size: ${({ theme }) => theme.size.lg};
      border-radius: 0px;
      outline: none;
      border: none;
    }
  }
`;
