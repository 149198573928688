import Dropzone, { IInvalidFile, IValidFile } from "components/Dropzone";
import Checkbox from "components/Checkbox";
import Input from "components/Input";
import InvalidFilesModal from "components/InvalidFilesModal";
import React from "react";
import { Step4Styled, UploadContainer } from "./styled";
import { FormikProps } from "formik";
import { IPostCampaignData } from "constants/utility/campaign";
import BannerPreviewImage from "components/BannerPreviewImage";
import Button from "components/Button";
import RequestCreativeIcon from "components/Icons/RequestCreativeIcon";

interface IStep4Props {
  formikProps: FormikProps<IPostCampaignData>;
  isInvalidFilesShow: boolean;
  setShowInvalidFiles: (show: boolean) => void;
}

const Step4: React.FunctionComponent<IStep4Props> = ({
  formikProps,
  isInvalidFilesShow,
  setShowInvalidFiles,
}) => {
  const [unsupportedFiles, setUnsupportedFiles] = React.useState<Array<IInvalidFile>>([]);
  const [selectedImages, setSelectedImages] = React.useState<Array<string>>([]);

  React.useEffect(() => {
    if (unsupportedFiles.length) {
      setShowInvalidFiles(true);
    }
  }, [unsupportedFiles.length]);

  const { errors, touched, values, handleChange, setFieldValue } = formikProps;

  return (
    <Step4Styled>
      <div className="field name-campaign">
        <div className="build-campaign-title">
          <span className="input-title">Add Content</span>
        </div>
      </div>

      {values.images.length ? (
        <div className="creative-section">
          <div className="creative-input-group">
            <Input
              data-testid="line-item"
              error={errors.title}
              touched={touched.title}
              placeholder="Name (Required)"
              name="title"
              onChange={handleChange}
              value={values.title}
            />
            <Input
              data-testid="landing-page"
              error={errors.landingPageUrl}
              touched={touched.landingPageUrl}
              placeholder="Landing Page URL (Required)"
              name="landingPageUrl"
              onChange={handleChange}
              value={values.landingPageUrl}
            />
            <Input
              data-testid="thank-you"
              error={errors.thankyouPageUrl}
              touched={touched.thankyouPageUrl}
              placeholder="Thank You Page URL (Optional)"
              name="thankyouPageUrl"
              onChange={handleChange}
              value={values.thankyouPageUrl}
            />
          </div>

          <UploadContainer hasUploaded={!!values.images.length}>
            <div className="images-preview">
              <Dropzone
                isError={!!(touched.images && errors.images)}
                setNewBannerImages={(files) =>
                  setFieldValue("images", [...values.images, ...files])
                }
                setUnsupportedFiles={(files) => setUnsupportedFiles(files)}
              />

              {values.images.length ? (
                <div className="image-list-wrapper">
                  {!!selectedImages.length && (
                    <div
                      onClick={() => {
                        const filtered = values.images.filter(
                          (image) => !selectedImages.includes(image.id)
                        );

                        setFieldValue("images", filtered);

                        setFieldValue("imagesUrl", [...values.imagesUrl, ...selectedImages]);

                        setSelectedImages([]);
                      }}
                      style={{ textAlign: "right", marginRight: "20px" }}
                    >
                      <Button outline noPadding>
                        Delete
                      </Button>
                    </div>
                  )}

                  <ul className="image-list">
                    <li className="image-item">
                      <Checkbox
                        checked={selectedImages.length === values.images.length}
                        onSelect={() => {
                          const ids = values.images.map((image) => image.id);
                          if (selectedImages.length === values.images.length) {
                            setSelectedImages([]);
                          } else {
                            setSelectedImages(ids);
                          }
                        }}
                      />
                      <span className="select-all">Select All</span>
                    </li>

                    {values.images.map((image) => {
                      return (
                        image && (
                          <li key={image.id} className="image-item">
                            <Checkbox
                              checked={selectedImages.includes(image.id)}
                              onSelect={() =>
                                setSelectedImages((prev) =>
                                  prev.includes(image.id)
                                    ? prev.filter((imageId) => image.id !== imageId)
                                    : [...prev, image.id]
                                )
                              }
                            />
                            <div className="banner-wrapper">
                              <BannerPreviewImage
                                isHorizontal={(image.width || 0) >= (image.height || 0) * 1.6}
                                src={image.thumbnail}
                              />
                              <div className="size-wrapper">
                                <span className="size-label">
                                  {image.width} x {image.height}
                                </span>
                              </div>
                            </div>
                          </li>
                        )
                      );
                    })}
                  </ul>
                </div>
              ) : null}
            </div>

            {values.images.length ? (
              <>
                <div className="recommended-sizes">
                  <h6 className="info-title">Recommended Sizes</h6>
                  <div className="size-list">
                    <span>120 x 160</span>
                    <span>160 x 600</span>
                    <span>200 x 200</span>
                    <span>240 x 400</span>
                    {/* <span>250 x 250</span> */}
                    <span>250 x 360</span>
                    <span>300 x 1050</span>
                    <span>300 x 250</span>
                    <span>300 x 50</span>
                    <span>300 x 600</span>
                    <span>320 x 100</span>
                    <span>320 x 50</span>
                    <span>336 x 280</span>
                    <span>468 x 60</span>
                    {/* <span>580 x 400</span> */}
                    <span>600 x 315</span>
                    <span>600 x 500</span>
                    <span>600 x 600</span>
                    <span>728 x 90</span>
                    {/* <span>930 x 180</span> */}
                    <span>970 x 250</span>
                    <span>970 x 90</span>
                    {/* <span>980 x 120</span> */}
                  </div>
                </div>
                <div className="creative-requirements-full">
                  <h6 className="info-title">Creative Requirements</h6>
                  <div className="file-info">
                    <span className="file-type">Static Ads</span>
                    <ul>
                      <li>Banners to be provided in either JPEG, or PNG format</li>
                      <li>The individual banner file sizes also cannot exceed 150KB</li>
                    </ul>

                    <span className="file-type">Video Ads</span>
                    <ul>
                      <li>Minimum Video Length: 12 seconds (for skippable ads)</li>
                      <li>Limit: 1024 characters</li>
                      <li>Companion Banner (optional): 300x60</li>
                      <li>JPG, GIF, or PNG (only image assets allowed, no 3rd party tags)</li>
                      <li>150 KB (50KB max for non-shippable video - MP4 preferred)</li>
                      <li>GIF (only image assets allowed, no 3rd party tags)</li>
                    </ul>

                    <span className="file-type">Animated Ads (GIF)</span>
                    <ul>
                      <li>Animation length must be 30 seconds or shorter</li>
                      <li>
                        Animation can be looped, but the animations must stop after 30 seconds
                      </li>
                      <li>Animated GIF ads must be slower than 5 FP</li>
                    </ul>
                  </div>
                </div>
              </>
            ) : null}

            {!values.images.length ? (
              <div className="creative-requirements-short">
                <span className="title">Creative Requiments</span>
                <div className="file-requirement">
                  <b>Static Ads</b>
                  <span> : JPEG, PNG, Maximum file size : 150KB</span>
                </div>
                <div className="file-requirement">
                  <b>Video Ads</b>
                  <span> : GIF Minimum video length : 12 secs.</span>
                </div>
                <div className="file-requirement">
                  <b>Animated Ads (GIF)</b>
                  <span> : Animation length must be 30 seconds or shorter</span>
                </div>
              </div>
            ) : null}
          </UploadContainer>
        </div>
      ) : (
        <div className="creative-uploader">
          <div className="detail-title landing">
            <span>Landing page detail</span>
          </div>

          <div className="detail-title add">
            <span>Add banner ads</span>
          </div>

          <div className="detail-title request">
            <span>Vatic AI creative</span>
          </div>

          <div className="creative-card landing">
            <Input
              dark
              error={errors.title}
              touched={touched.title}
              placeholder="Name (Required)"
              name="title"
              onChange={handleChange}
              value={values.title}
            />
            <Input
              dark
              error={errors.landingPageUrl}
              touched={touched.landingPageUrl}
              placeholder="Landing Page URL (Required)"
              name="landingPageUrl"
              onChange={handleChange}
              value={values.landingPageUrl}
            />
            <Input
              dark
              error={errors.thankyouPageUrl}
              touched={touched.thankyouPageUrl}
              placeholder="Thank You Page URL (Optional)"
              name="thankyouPageUrl"
              onChange={handleChange}
              value={values.thankyouPageUrl}
            />
          </div>
          <div className="creative-card add">
            <div className="dropzone-wrapper">
              <Dropzone
                isError={!!(touched.images && errors.images)}
                setNewBannerImages={(files) =>
                  setFieldValue("images", [...values.images, ...files])
                }
                setUnsupportedFiles={(files) => setUnsupportedFiles(files)}
              />
            </div>

            <div className="requirement">
              <div className="requirement-title">
                <span>Creative Requirements</span>
              </div>
              <ul className="requirement-text">
                <li>Static Ads : JPEG, PNG | Maximum file size : 150KB</li>
                <li>Video Ads : GIF | Minimum video length : 12 seconds</li>
                <li>Animated Ads (GIF) : Minimum video length : 30 seconds</li>
              </ul>
            </div>
          </div>
          <div className="creative-card request">
            <div className="request-body">
              <div className="request-action">
                <div className="light-bulb">
                  <RequestCreativeIcon className="request-icon" />
                </div>
                <div
                  onClick={() => window.open(process.env.REACT_APP_REQUEST_CREATIVE_FORM)}
                  className="creative-req-btn"
                >
                  <span>Request creatives</span>
                </div>
                {/* <div className="info-btn">
                  <span>Know more</span>
                </div> */}
              </div>
            </div>
            <div className="request-info">
              <p className="request-text">
                {`We provide full creative services including banner sets
and landing pages as a subscription to ensure the
best chance of your campaigns converting.
              `}
              </p>
            </div>
          </div>
        </div>
      )}

      <InvalidFilesModal
        invalidFiles={unsupportedFiles}
        show={isInvalidFilesShow}
        onHide={() => {
          setShowInvalidFiles(false);
          setUnsupportedFiles([]);
        }}
      />
    </Step4Styled>
  );
};

export default Step4;
