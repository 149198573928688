import styled, { css } from "styled-components/macro";
import Badge from "react-bootstrap/Badge";

export const BadgeStyled = styled(Badge)<{ $showRemove: boolean; size: "sm" | "xs" | "md" }>`
  background-color: ${({ color }) => color} !important;
  padding: ${({ size }) => (size === "xs" ? "5px 0px 5px 5px" : "8px 0px 8px 14px")};
  color: #ffffff;
  font-size: ${({ size }) => (size === "md" ? "14px" : size === "sm" ? "10px" : "8px")};
  letter-spacing: 0.3px;
  font-weight: 500;
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: ${({ size }) => (size === "xs" ? "0px" : "20px")};
  position: relative;

  ${({ $showRemove, size }) =>
    $showRemove &&
    css`
      .keyword-text {
        margin-right: ${size === "xs" ? "8px" : "20px"};
      }
    `}

  .remove-badge {
    cursor: pointer;
    padding: ${({ size }) => (size === "xs" ? "3px 4px" : "9px 12px")};
    position: absolute;
    right: 0;
    top: 0;
  }
`;
