import StyledCreateCampaign, { modalStyle, multiselectCss, modalFooterStyle } from "./styled";
import { Modal, Button } from "react-bootstrap";
import Input from "components/Input";
import { Multiselect } from "multiselect-react-dropdown";
import { useState } from "react";
import { axiosForReverseAds } from "axiosConfig";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const CreateCampaign = () => {
  const navigate = useNavigate();

  const [location, setLocation] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState<
    Array<{ id: number; name: string; canonicalName: string }>
  >([]);
  const [loadingLocation, setLoadingLocation] = useState(true);

  const [campaignName, setCampaignName] = useState("");

  const handleClose = () => {
    navigate(-1);
  };

  const handleSave = () => {
    if (campaignName === "") {
      toast.error("You must enter Campaign Name", { autoClose: 1000 });
      return;
    }
    const storedUser_id = localStorage.getItem("user_id");
    const createCampaign = async () => {
      try {
        const reqData = {
          user_id: storedUser_id,
          name: campaignName,
          countries: selectedLocation.map((i) => i.name),
        };

        const response = await axiosForReverseAds.post("add_campaign", reqData);
        if (response.data.status === "success") {
          toast.success("Campaign created successfully!", { autoClose: 1000 });
          navigate(-1);
        } else {
          toast.error("There are some issue while creating Campaign", { autoClose: 1000 });
        }
      } catch {
        toast.error("There are some issue while creating Campaign", { autoClose: 1000 });
      }
    };
    createCampaign();
  };

  const handleInputChangeLocation = (text: string) => {
    setLoadingLocation(true);
    const getlocationData = async () => {
      const response = await axiosForReverseAds.get("location/" + text);
      const resData = response.data;
      if (resData.data.length == 0) {
        setLocation([]);
      } else {
        setLocation(resData.data);
      }
      setLoadingLocation(false);
    };
    getlocationData();
  };

  return (
    <StyledCreateCampaign>
      <Modal show={true} onHide={handleClose} centered={true}>
        <Modal.Header closeButton>
          <Modal.Title>
            <div>Create Campaign</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={modalStyle}>
          <div>
            <div>Campaign Name</div>
            <Input
              onChange={(event: any) => {
                setCampaignName(event.target.value);
              }}
              height={50}
              left={25}
              placeholder="Campaign Name"
              black
            ></Input>
          </div>

          <div className="container-location">
            <div>Country</div>
            <Multiselect
              loading={loadingLocation}
              placeholder={"Search Location"}
              isObject={true}
              options={location}
              displayValue={"canonicalName"}
              onRemove={(event: any) => {
                setSelectedLocation(event);
                setLocation([]);
                setLoadingLocation(true);
              }}
              onSelect={(event: any) => {
                setSelectedLocation(event);
                setLocation([]);
                setLoadingLocation(true);
              }}
              onSearch={(event: any) => {
                handleInputChangeLocation(event);
              }}
              selectedValueDecorator={(v: any, o: any) => {
                return o["canonicalName"];
              }}
              style={multiselectCss}
            />
          </div>
        </Modal.Body>
        <Modal.Footer style={modalFooterStyle}>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Create
          </Button>
        </Modal.Footer>
      </Modal>
    </StyledCreateCampaign>
  );
};

export default CreateCampaign;
