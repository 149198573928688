import styled, { css } from "styled-components";

export const CloseButtonStyled = styled.div<{ top?: number; right?: number; dark?: boolean }>`
  position: absolute;
  top: ${({ top }) => top || 0}px;
  right: ${({ right }) => right || 0}px;
  cursor: pointer;
  font-size: 13px;
  background: none;
  width: 20px;
  height: 20px;
  z-index: 1;

  &:before {
    background: rgb(204, 204, 204);
    border-radius: 50%;
    width: 20px;
    height: 20px;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
  }

  &:after {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    content: "✕";
    position: absolute;
    top: 0;
    left: 0;
    color: rgb(242, 242, 242);
    font-size: 12px;
  }

  &:hover {
    &:before {
      background: rgb(170, 170, 170);
    }
  }

  ${({ dark, theme }) =>
    dark &&
    css`
      &:before {
        background-color: ${theme.dark};
      }

      &:after {
        color: #ffffff;
      }
    `}
`;
