import styled from "styled-components/macro";

export const PaymentSummaryWrapper = styled.section`
  padding: 25px;
  border-radius: 10px;
  height: 100%;

  .line {
    border-color: rgb(120, 120, 120);
  }

  .promo {
    display: flex;
    justify-content: space-between;
  }

  .summary-detail {
    font-size: 12px;
    border-radius: 6px;
  }

  .budget-summary {
    margin-top: 30px;
    color: ${({ theme }) => theme.text};
  }

  .amount-summary {
    margin-top: 50px;

    .total-amount {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      font-weight: bold;
    }

    .budget-info {
      font-weight: bold;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      color: ${({ theme }) => theme.secondaryHighlight};
    }
  }
`;
