import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { axiosForReverseAds } from "axiosConfig";
import { Button } from "react-bootstrap";
import Input from "components/Input";
import { toast } from "react-toastify";
import StyledUpdateAudience from "./styled";

const UpdateAudience = () => {
  const navigate = useNavigate();
  const loc = useLocation();
  const { audience_id } = useParams();

  const [signalList, setSignalList] = useState<
    Array<{ id: number; name: string; location: string; language: string }>
  >([]);
  const [checked, setChecked] = useState<Array<{ id: number; flag: boolean }>>([]);
  const [user_id, setUser_id] = useState<number>();
  const [allSelected, setAllSelected] = useState<boolean>(false);

  useEffect(() => {
    const fetchSignals = async () => {
      const storedUser_id = localStorage.getItem("user_id");
      if (storedUser_id) {
        const user_id = parseInt(storedUser_id);
        setUser_id(user_id);
        const response = await axiosForReverseAds.post("get_signals_info", { user_id: user_id });
        setSignalList(response.data.data);
        const demoChecked: any = [];
        response.data.data.map((item: any) => demoChecked.push({ id: item.id, flag: false }));
        setChecked(demoChecked);

        const res = await axiosForReverseAds.get("get_selected_signal_ids", {
          params: { audience_id: audience_id },
        });
        const selectedSignals_ids = res.data.data;

        const newDemoChecked: any = [];
        response.data.data.map((item: any) => {
          const f = selectedSignals_ids.includes(item.id);
          newDemoChecked.push({ id: item.id, flag: f });
        });
        setChecked(newDemoChecked);
        const isAllSelected = newDemoChecked.reduce((acc: boolean, i: any) => {
          return acc && i.flag;
        }, true);
        setAllSelected(isAllSelected);
      } else {
        setSignalList([]);
      }
    };
    fetchSignals();
  }, []);

  const handleChecked = (index: number) => {
    const newItem = [...checked];
    newItem[index].flag = !newItem[index].flag;
    setChecked(newItem);
    const isAllSelected = newItem.reduce((acc, i) => {
      return acc && i.flag;
    }, true);
    setAllSelected(isAllSelected);
  };

  const createAudience = async () => {
    const selectedSignal = checked.filter((i) => i.flag);
    // if (selectedSignal.length == 0) {
    //   toast.error("You must select atleast one signal", { autoClose: 1000 });
    //   return;
    // }
    const signal_ids = selectedSignal.map((i) => i.id);
    const reqData = {
      audience_id: audience_id,
      signal_ids: signal_ids,
    };
    const response = await axiosForReverseAds.post("update_audience", reqData);
    if (response.data.status === "success") {
      toast.success("Audience updated successfully!", { autoClose: 1000 });
      navigate(-1);
    } else {
      toast.error("Unable to update audience", { autoClose: 1000 });
      return;
    }
  };

  const handleAll = () => {
    if (allSelected) {
      setAllSelected(false);
      let newItem = [...checked];
      newItem = newItem.map((i) => {
        i.flag = false;
        return i;
      });
      setChecked(newItem);
    } else {
      setAllSelected(true);
      let newItem = [...checked];
      newItem = newItem.map((i) => {
        i.flag = true;
        return i;
      });
      setChecked(newItem);
    }
  };

  return (
    <StyledUpdateAudience>
      <div className="container">
        <div className="title">Update Audience</div>

        <div className="container-body">
          <div className="container-input">
            <div className="signal-h1">
              Audience Name &nbsp; &nbsp;{" "}
              <span className="signal-name">{loc.state?.audiance_name}</span>
            </div>
          </div>
          <div className="audience-table">
            <table className="table">
              <thead>
                <tr>
                  <th>
                    <input
                      type="checkbox"
                      onClick={handleAll}
                      title={allSelected ? "Unselect All" : "Select All"}
                      checked={allSelected}
                      onChange={() => {}}
                    />
                  </th>
                  <th>Signal Name</th>
                  <th>Location</th>
                  <th>Language</th>
                </tr>
              </thead>
              <tbody>
                {signalList.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <input
                        type="checkbox"
                        onChange={() => {
                          handleChecked(index);
                        }}
                        checked={checked[index].flag}
                      />
                    </td>
                    <td>{item.name}</td>
                    <td>{item.location}</td>
                    <td>{item.language}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="btn-container">
          <Button
            variant="primary"
            onClick={() => {
              navigate(-1);
            }}
          >
            Cancel
          </Button>
          <Button variant="success" onClick={createAudience}>
            Save
          </Button>
        </div>
      </div>
    </StyledUpdateAudience>
  );
};

export default UpdateAudience;
