import { IDataForSEO } from "constants/utility/keyword";
import { ISavedKeyword, ISavedKeywordList } from "store/states/keywords-list.state";
import { IErrorMessage } from "../../services/types";
import * as types from "../action-types/current-saved-keywords.types";

// get

export function getCurrentSavedKeywordsList(
  payload: string
): types.GetCurrentSavedKeywordsListAction {
  return {
    type: types.GET_CURRENT_SAVED_KEYWORDS_LIST,
    payload,
  };
}

export function getCurrentSavedKeywordsListRequest(
  payload: string
): types.GetCurrentSavedKeywordsListRequestAction {
  return {
    type: types.GET_CURRENT_SAVED_KEYWORDS_LIST_REQUEST,
    payload,
  };
}

export function getCurrentSavedKeywordsListSuccess(
  payload: ISavedKeywordList
): types.GetCurrentSavedKeywordsListSuccessAction {
  return {
    type: types.GET_CURRENT_SAVED_KEYWORDS_LIST_SUCCESS,
    payload,
  };
}

export function getCurrentSavedKeywordsListFailure(
  error: IErrorMessage
): types.GetCurrentSavedKeywordsListFailureAction {
  return {
    type: types.GET_CURRENT_SAVED_KEYWORDS_LIST_FAILURE,
    error,
  };
}

// edit

export function editCurrentSavedKeywordsList(payload: {
  id: string;
  name: string;
}): types.EditCurrentSavedKeywordsListAction {
  return {
    type: types.EDIT_CURRENT_SAVED_KEYWORDS_LIST,
    payload,
  };
}

export function editCurrentSavedKeywordsListRequest(): types.EditCurrentSavedKeywordsListRequestAction {
  return {
    type: types.EDIT_CURRENT_SAVED_KEYWORDS_LIST_REQUEST,
  };
}

export function editCurrentSavedKeywordsListSuccess(payload: {
  id: string;
  name: string;
  keywords: Array<{ id: string; keyword: string }>;
}): types.EditCurrentSavedKeywordsListSuccessAction {
  return {
    type: types.EDIT_CURRENT_SAVED_KEYWORDS_LIST_SUCCESS,
    payload,
  };
}

export function editCurrentSavedKeywordsListFailure(
  error: IErrorMessage
): types.EditCurrentSavedKeywordsListFailureAction {
  return {
    type: types.EDIT_CURRENT_SAVED_KEYWORDS_LIST_FAILURE,
    error,
  };
}
