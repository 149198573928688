import styled, { css } from "styled-components/macro";
import Form from "react-bootstrap/Form";
import { memo } from "react";

interface CheckboxSize {
  width: string;
  height: string;
  left: string;
  top: string;
  checkedWidth: string;
  checkedHeight: string;
}

const SIZE_VALUES: { [key: string]: CheckboxSize } = {
  sm: {
    width: "15px",
    height: "15px",
    left: "5px",
    top: "1px",
    checkedWidth: "5px",
    checkedHeight: "11px",
  },
  md: {
    width: "20px",
    height: "20px",
    left: "8px",
    top: "5px",
    checkedWidth: "4px",
    checkedHeight: "9px",
  },
};

export const CheckboxWrapper = styled.div<{ size: string; centered?: boolean; inline?: boolean }>`
  height: ${({ size }) => SIZE_VALUES[size].height};
  width: ${({ size }) => SIZE_VALUES[size].width};
  position: relative;

  display: ${({ inline }) => (inline ? "inline-block" : "block")};

  ${({ centered }) =>
    centered &&
    css`
      margin: 0 auto;
    `}
`;

export const SquareCheckbox = styled.div``;

export const CircleCheckbox = styled.div<{ checked: boolean; size: string }>`
  ${({ size }) =>
    size &&
    css`
      height: ${SIZE_VALUES[size].height};
      width: ${SIZE_VALUES[size].width};

      &:before {
        height: ${SIZE_VALUES[size].height};
        width: ${SIZE_VALUES[size].width};
      }

      &:after {
        height: ${SIZE_VALUES[size].checkedHeight};
        width: ${SIZE_VALUES[size].checkedWidth};
        top: ${SIZE_VALUES[size].top};
        left: ${SIZE_VALUES[size].left};
      }
    `}

  border-radius: 50%;
  transform: translate(0px, 0px);
  position: relative;
  border-width: 0 2px 2px 0;
  cursor: pointer;

  &:before {
    content: "";
    position: absolute;
    border: 1px solid rgb(203, 203, 203);
    z-index: 1;
    border-radius: 50%;
    background-color: rgb(102, 102, 102);
  }

  &:after {
    content: "";
    position: absolute;
    border-width: 0 2px 2px 0;
    border-color: rgb(203, 203, 203);
    border-style: solid;
    transform: rotate(45deg);
    z-index: 2;
  }

  ${({ checked }) =>
    checked
      ? css`
          &:before {
            background-color: ${({ theme }) => theme.secondary900};
          }

          &:after {
            border-color: ${({ theme }) => theme.background};
          }
        `
      : css`
          &:after {
            border-color: solid ${({ theme }) => theme.secondary900};
          }
        `}
`;

export const ExcludeCheckbox = styled.div<{ checked: boolean; size: string }>`
  border-radius: 50%;
  transform: translate(0px, 0px);
  position: relative;
  border-width: 0 2px 2px 0;
  cursor: pointer;

  &:before {
    content: "";
    position: absolute;
    border: 1px solid rgb(203, 203, 203);
    background-color: rgb(102, 102, 102);
    z-index: 1;
    border-radius: 50%;
  }

  &:after {
    content: "✕";
    position: absolute;
    font-size: 12px;
    padding: 1px 6px;
    z-index: 2;
    color: rgb(203, 203, 203);
  }

  ${({ size }) =>
    size === "md"
      ? css`
          height: ${SIZE_VALUES[size].height};
          width: ${SIZE_VALUES[size].width};

          &:before {
            height: ${SIZE_VALUES[size].height};
            width: ${SIZE_VALUES[size].width};
          }
        `
      : css`
          &:before {
            height: ${SIZE_VALUES[size].height};
            width: ${SIZE_VALUES[size].width};
          }
          &:after {
            font-size: 10px;
            padding: 0px 4px;
          }
        `}

  ${({ checked }) =>
    checked
      ? css`
          &:before {
            background-color: ${({ theme }) => theme.color.primary};
          }

          &:after {
            color: white;
          }
        `
      : css`
          &:after {
          }
        `}
`;

export const DashCheckbox = styled.div``;

export const StyledCheckbox = styled.div<{
  checked: boolean;
  size: string;
  shape: string;
  border?: boolean;
  error?: boolean;
  selectAll?: boolean;
}>`
  ${({ error }) =>
    error &&
    css`
      border: 1px solid crimson;
    `}

  transition: .1s;
  cursor: pointer;
  border-radius: 5px;
  transform: translate(0px, 0px);
  height: ${({ size }) => SIZE_VALUES[size].height};
  width: ${({ size }) => SIZE_VALUES[size].width};

  ${({ border, theme, checked }) =>
    border &&
    !checked &&
    css`
      border: 1px solid ${theme.checkboxBorder};
    `}

  ${({ size, shape, checked, theme }) => {
    switch (shape) {
      case "dash":
        if (checked) {
          return css`
            border-radius: 50%;
            &:before {
              content: "";
              position: absolute;
              height: ${SIZE_VALUES[size].height};
              width: ${SIZE_VALUES[size].width};
              background-color: #fe0099;
              border: 1px solid #fe0099;
              z-index: 1;
              border-radius: 50%;
            }

            &:after {
              content: "";
              position: absolute;
              height: ${SIZE_VALUES[size].checkedHeight};
              width: ${SIZE_VALUES[size].checkedWidth};
              top: ${SIZE_VALUES[size].top};
              left: ${SIZE_VALUES[size].left};
              border: solid white;
              border-width: 0 2px 0 0;
              transform: rotate(90deg);
              z-index: 2;
            }
          `;
        } else {
          return css`
            border-radius: 50%;
            &:before {
              content: "";
              position: absolute;
              height: ${SIZE_VALUES[size].height};
              width: ${SIZE_VALUES[size].width};
              border: 1px solid #fe0099;
              z-index: 1;
              border-radius: 50%;
            }

            &:after {
              content: "";
              position: absolute;
              height: ${SIZE_VALUES[size].checkedHeight};
              width: ${SIZE_VALUES[size].checkedWidth};
              top: ${SIZE_VALUES[size].top};
              left: ${SIZE_VALUES[size].left};
              border: solid #fe0099;
              border-width: 0 2px 0 0;

              transform: rotate(90deg);
              z-index: 2;
            }
          `;
        }
      default:
        if (checked) {
          return css`
            &:before {
              content: "";
              position: absolute;
              height: ${SIZE_VALUES[size].height};
              width: ${SIZE_VALUES[size].width};
              background-color: #fe0099;
              border: 1px solid #fe0099;
              z-index: 1;
              border-radius: 5px;
              left: 0;
            }

            &:after {
              content: "";
              position: absolute;
              height: ${SIZE_VALUES[size].checkedHeight};
              width: ${SIZE_VALUES[size].checkedWidth};
              top: ${SIZE_VALUES[size].top};
              left: ${SIZE_VALUES[size].left};
              border: solid white;
              border-width: 0 2px 2px 0;
              transform: rotate(45deg);
              z-index: 2;
            }
          `;
        } else {
          return css`
            background-color: ${theme.checkbox};
          `;
        }
    }
  }}

${({ selectAll, theme, checked }) =>
    selectAll &&
    !checked &&
    css`
      border: 1px solid ${theme.selectAllBorder};
      background-color: ${theme.selectAll};
    `}
`;

export const InputCheckBox = styled(Form.Check.Input)`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;
