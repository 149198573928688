import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "store/store";
import ListTable from "components/ListTable";
import { ManageUserPage } from "./styled";
import { openSlideModal } from "store/actions/sidebar.actions";
import SlideModal from "components/SlideModal";
import { useSortBy, useTable, Column, usePagination } from "react-table";
import Pagination from "components/Pagination";
import PaymentFilter, { IFilterColumn } from "components/PaymentFilter";
import { displayDate, formatDate } from "constants/utility/date";
import Spinner from "components/Spinner";
import PaymentDetail from "modules/Client/pages/CampaignReport/CampaignAdmin/PaymentDetail";
import {
  IPaymentDetail,
  PAYMENT_GATEWAY_TEXT,
  PAYMENT_STATUS_TEXT,
} from "store/states/payments.state";
import { getPayments } from "store/actions/payments.actions";
import { numberWithCommas } from "constants/utility/number";
import { ResearchToolsIcon } from "components/Icons";
import { ENUM_USER_ROLES } from "store/states/user.state";

const columns: Array<Column<IPaymentDetail>> = [
  { Header: "Payment ID", accessor: "id" },
  { Header: "", id: "view", accessor: () => "" },
  { Header: "Amount", id: "amount", accessor: ({ amount }) => `$${numberWithCommas(amount)}` },
  {
    Header: "Payment Gateway",
    id: "paymentGateway",
    accessor: ({ paymentGateway }) => PAYMENT_GATEWAY_TEXT[paymentGateway],
  },
  { Header: "Status", id: "state", accessor: ({ state }) => PAYMENT_STATUS_TEXT[state] },
  {
    Header: "Created Date",
    id: "created_at",
    accessor: ({ created_at }) => (created_at ? displayDate(new Date(created_at)) : ""),
  },
  {
    Header: "Updated Date",
    id: "updated_at",
    accessor: ({ updated_at }) => (updated_at ? displayDate(new Date(updated_at)) : ""),
  },
  { Header: "Campaign Name", accessor: "campaignName" },
  { Header: "Owner Name", accessor: "ownerName" },
];

const mapStateToProps = ({ payments, user }: RootState) => ({
  payments,
  user,
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface IManagePaymentsProps extends PropsFromRedux {}

const ManagePayments: React.FunctionComponent<IManagePaymentsProps> = ({
  dispatch,
  payments,
  user,
}) => {
  const [currentFilters, setCurrentFilters] = React.useState<IFilterColumn | undefined>(undefined);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    // allColumns,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex, sortBy },
  } = useTable(
    {
      manualSortBy: true,
      manualPagination: true,
      columns,
      data: payments.data,
      pageCount: Math.ceil(payments.total / 10),
      initialState: { pageSize: 10, pageIndex: 0 },
    },
    useSortBy,
    usePagination
  );
  React.useEffect(() => {
    const filterObj = {
      id: currentFilters?.id,
      ownerName: currentFilters?.ownerName,
      campaignName: currentFilters?.campaignName,
      amount: currentFilters?.amount,
      state: currentFilters?.state,
      paymentGateway: currentFilters?.paymentGateway,
      created_from: currentFilters?.created_at && formatDate(currentFilters?.created_at.startDate),
      created_to: currentFilters?.created_at && formatDate(currentFilters?.created_at.endDate),
      updated_from: currentFilters?.updated_at && formatDate(currentFilters?.updated_at.startDate),
      updated_to: currentFilters?.updated_at && formatDate(currentFilters?.updated_at.endDate),
      page: pageIndex + 1,
      asc: sortBy.length ? (sortBy[0].desc ? "" : sortBy[0].id) : "",
      desc: sortBy.length ? (sortBy[0].desc ? sortBy[0].id : "") : "",
      amount_from: currentFilters?.amount?.min,
      amount_to: currentFilters?.amount?.max,
    };
    dispatch(getPayments(filterObj));
  }, [pageIndex]);

  React.useEffect(() => {
    if (sortBy.length) {
      const filterObj = {
        id: currentFilters?.id,
        ownerName: currentFilters?.ownerName,
        campaignName: currentFilters?.campaignName,
        amount: currentFilters?.amount,
        state: currentFilters?.state,
        paymentGateway: currentFilters?.paymentGateway,
        created_from:
          currentFilters?.created_at && formatDate(currentFilters?.created_at.startDate),
        created_to: currentFilters?.created_at && formatDate(currentFilters?.created_at.endDate),
        updated_from:
          currentFilters?.updated_at && formatDate(currentFilters?.updated_at.startDate),
        updated_to: currentFilters?.updated_at && formatDate(currentFilters?.updated_at.endDate),
        page: pageIndex + 1,
        asc: sortBy.length ? (sortBy[0].desc ? "" : sortBy[0].id) : "",
        desc: sortBy.length ? (sortBy[0].desc ? sortBy[0].id : "") : "",
        amount_from: currentFilters?.amount?.min,
        amount_to: currentFilters?.amount?.max,
      };
      gotoPage(0);
      dispatch(getPayments(filterObj));
    }
  }, [sortBy]);

  React.useEffect(() => {
    if (currentFilters) {
      const filterObj = {
        id: currentFilters?.id,
        ownerName: currentFilters?.ownerName,
        campaignName: currentFilters?.campaignName,
        amount: currentFilters?.amount,
        state: currentFilters?.state,
        paymentGateway: currentFilters?.paymentGateway,
        created_from:
          currentFilters?.created_at && formatDate(currentFilters?.created_at.startDate),
        created_to: currentFilters?.created_at && formatDate(currentFilters?.created_at.endDate),
        updated_from:
          currentFilters?.updated_at && formatDate(currentFilters?.updated_at.startDate),
        updated_to: currentFilters?.updated_at && formatDate(currentFilters?.updated_at.endDate),
        page: pageIndex + 1,
        asc: sortBy.length ? (sortBy[0].desc ? "" : sortBy[0].id) : "",
        desc: sortBy.length ? (sortBy[0].desc ? sortBy[0].id : "") : "",
        amount_from: currentFilters?.amount?.min,
        amount_to: currentFilters?.amount?.max,
      };
      gotoPage(0);
      dispatch(getPayments(filterObj));
    }
  }, [currentFilters]);

  const applyFilters = (filters: IFilterColumn) => {
    setCurrentFilters((prev) => ({ ...prev, ...filters }));
  };

  // const { values, handleChange, handleSubmit } = formik;
  return (
    <ManageUserPage>
      <div>
        <div>
          <h6 className="keyword-tool-title">Admin Zone</h6>
          <h2 className="page-title">Manage Payments</h2>
        </div>

        <PaymentFilter currentFilters={currentFilters} onFilter={applyFilters} />
      </div>

      {!payments.loading ? (
        <div className="table-wrapper">
          <div className="table-wrapper-inner">
            {payments.data && (
              <ListTable fullWidth {...getTableProps()}>
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                          {column.render("Header")}

                          <span>
                            {column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""}
                          </span>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {page.map((row) => {
                    prepareRow(row);
                    return (
                      <tr
                        // className={row.original.deleted_at ? "disabled" : ""}
                        {...row.getRowProps()}
                      >
                        {row.cells.map((cell, colIndex) => {
                          if (colIndex === 1) {
                            return (
                              <td {...cell.getCellProps()}>
                                {user.role === ENUM_USER_ROLES.SUPER_ADMIN && (
                                  <span onClick={() => dispatch(openSlideModal(row.original.id))}>
                                    <ResearchToolsIcon className="view-payment" />
                                  </span>
                                )}
                              </td>
                            );
                          }
                          return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </ListTable>
            )}
            <Pagination
              canPreviousPage={canPreviousPage}
              canNextPage={canNextPage}
              pageOptions={pageOptions}
              pageCount={pageCount}
              gotoPage={gotoPage}
              nextPage={nextPage}
              previousPage={previousPage}
              pageIndex={pageIndex}
            />
          </div>
        </div>
      ) : (
        <Spinner />
      )}

      <SlideModal content={<PaymentDetail />} />
    </ManageUserPage>
  );
};

export default connector(ManagePayments);
