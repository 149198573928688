import Spinner from "components/Spinner";
import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ENUM_USER_ROLES } from "store/states/user.state";
import { RootState } from "store/store";

interface INavigationIndexProps extends PropsFromRedux {}

const mapStateToProps = ({ user }: RootState) => ({
  user,
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const IndexNavigation: React.FunctionComponent<INavigationIndexProps> = ({ user }) => {
  const navigate = useNavigate();

  React.useEffect(() => {
    const accessToken = window.localStorage.getItem("accessToken");

    if (user.isAuthenticated || accessToken) {
      if (user.role && user.role !== ENUM_USER_ROLES.USER) {
        navigate("/admin/campaigns");
      } else if (user.role === ENUM_USER_ROLES.USER) {
        navigate("/app/home");
      }
    } else {
      navigate("/auth/register");
    }
  }, [user]);

  return <Spinner />;
};

export default connector(IndexNavigation);
