import styled from "styled-components/macro";

export const LocationFilterStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;

  .location-filter {
    display: flex;
    flex-wrap: nowrap;
    gap: 10px;

    .react-dropdown-select[disabled] {
      opacity: 1;
      background: #f3f3f3;
    }

    .react-dropdown-select {
      border-color: transparent !important;
      color: #ffffff;
      box-shadow: none !important;
      border-radius: 0;

      .react-dropdown-select-input {
        ::placeholder {
          /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: #ffffff;
          opacity: 1; /* Firefox */
        }

        :-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: #ffffff;
        }

        ::-ms-input-placeholder {
          /* Microsoft Edge */
          color: #ffffff;
        }
      }
    }
  }
`;
