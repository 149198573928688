import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { axiosForReverseAds } from "../../axiosConfig";
import IGetContentCategoriesResponse from "../../models/get-content-categories-response.interface";
import { handlingError, onSuccess } from "../../constants/utility/error";
import { AdFormCategoryID } from "../../services/types";
import { RootState } from "../store";

export const GET_CATEGORIES_REQUEST = "GET_CATEGORIES_REQUEST";
export const GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS";
export const GET_CATEGORIES_ERROR = "GET_CATEGORIES_ERROR";

export const getCategoriesRequest = () => {
  return {
    type: GET_CATEGORIES_REQUEST,
  };
};

export const getCategoriesSuccess = (categories: Array<AdFormCategoryID>) => {
  return {
    type: GET_CATEGORIES_SUCCESS,
    payload: categories,
  };
};

export const getCategoriesError = (error: any) => {
  return {
    type: GET_CATEGORIES_ERROR,
    payload: error,
  };
};

export const fetchContentCategories = (): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async (dispatch) => {
    try {
      dispatch(getCategoriesRequest());

      const {
        data: { data },
      } = await axiosForReverseAds.get<IGetContentCategoriesResponse>("beeswax/contentCategories");

      dispatch(getCategoriesSuccess(data));
    } catch (error: any) {
      handlingError(error);
    }
  };
};
