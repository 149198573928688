import React from "react";

const LightBulbIcon = ({ className }: { className?: string }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 103.33 138.74" className={className}>
      <defs>
        <style>
          {
            ".svg-icon{fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}"
          }
        </style>
      </defs>
      <g id="a" />
      <g id="b">
        <g id="c">
          <g id="d">
            <path
              id="e"
              className="svg-icon"
              d="M85.63,56.78v-9.57h-5.14c-.31-1.87-.8-3.71-1.47-5.49l4.45-2.57-4.78-8.29-4.45,2.57c-1.21-1.47-2.55-2.81-4.02-4.02l2.57-4.45-8.29-4.79-2.57,4.45c-1.78-.67-3.62-1.16-5.49-1.47v-5.14h-9.57v5.14c-1.87,.31-3.71,.8-5.49,1.46l-2.57-4.45-8.29,4.79,2.57,4.45c-1.47,1.21-2.81,2.55-4.02,4.02l-4.45-2.57-4.79,8.29,4.45,2.57c-.67,1.78-1.16,3.62-1.47,5.49h-5.14v9.57h5.14c.31,1.87,.8,3.71,1.46,5.49l-4.45,2.57,4.79,8.29,4.45-2.57c1.21,1.47,2.55,2.81,4.02,4.02l-2.57,4.45,8.29,4.78,2.57-4.45c1.78,.67,3.62,1.16,5.49,1.47v5.14h9.57v-5.14c1.87-.31,3.71-.8,5.49-1.47l2.57,4.45,8.29-4.79-2.57-4.45c1.47-1.21,2.81-2.55,4.02-4.02l4.45,2.57,4.78-8.29-4.45-2.57c.67-1.78,1.16-3.62,1.47-5.49h5.15Z"
            />
            <path
              id="f"
              className="svg-icon"
              d="M46.23,35.08c9.34-2.99,19.34,2.16,22.33,11.5,2.99,9.34-2.16,19.34-11.5,22.33"
            />
            <path
              id="g"
              className="svg-icon"
              d="M67.03,60.87c-4.9,8.49-15.77,11.41-24.26,6.5s-11.41-15.77-6.5-24.26"
            />
            <path
              id="h"
              className="svg-icon"
              d="M93.56,80.16l-24.27,35.71H34.05L9.78,80.16C-5.96,57.02,.04,25.51,23.18,9.78,46.31-5.96,77.82,.04,93.56,23.18c11.69,17.19,11.69,39.79,0,56.98h0Z"
            />
            <line id="i" className="svg-icon" x1={34.47} y1={126.81} x2={68.83} y2={126.81} />
            <line id="j" className="svg-icon" x1={36.32} y1={137.74} x2={66.98} y2={137.74} />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default LightBulbIcon;
