import React from "react";
import { UserNavigationStyled } from "./styled";
import { useNavigate, useLocation } from "react-router-dom";
import { userRoutes } from "modules/User/routes";
import { useDispatch } from "react-redux";
import { logoutUser } from "store/actions/user.actions";
import { showConfirmPopup } from "store/actions/popup.actions";

interface IUserNavigation {}

const UserNavigation: React.FunctionComponent<IUserNavigation> = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const handleAction = (action: string) => {
    switch (action) {
      case "LOGOUT":
        dispatch(
          showConfirmPopup({
            title: "Log out?",
            body: "Do you want to log out?",
            confirmText: "Log out",
            action: () => dispatch(logoutUser(navigate)),
          })
        );
        break;
      default:
    }
  };

  return (
    <UserNavigationStyled>
      <div className="back-homepage">
        <span className="back-text" onClick={() => navigate("/app/home")}>
          <span>
            <span className="arrow" />
            <span className="text">BACK</span>
          </span>
        </span>
      </div>
      <ul>
        {userRoutes.map((route) => {
          return (
            <li
              key={route.path || route.text}
              onClick={() =>
                route.action ? handleAction(route.action) : route.path && navigate(route.path)
              }
              className={`${route.disabled ? "disabled" : ""} route-item ${
                location.pathname === route.path ? `active` : ""
              }`}
            >
              {route.text}
            </li>
          );
        })}
      </ul>
    </UserNavigationStyled>
  );
};

export default UserNavigation;
