import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BadgeStyled } from "./styled";

interface IBadgeProps {
  children?: React.ReactNode | Element;
  onRemove?: () => void;
  text: string;
  square?: boolean;
  size?: "sm" | "md" | "xs";
  color: string;
  [x: string]: any;
}

const Badge: React.FunctionComponent<IBadgeProps> = ({
  color,
  square,
  size,
  children,
  onRemove,
  text,
  ...props
}) => {
  return (
    <BadgeStyled $showRemove={!!onRemove} color={color} pill={!square} size={size} {...props}>
      {children}
      <span data-testid="badge-text" className="keyword-text">
        {text}
      </span>
      {onRemove && (
        <span data-testid="badge-remove" className="remove-badge" onClick={onRemove}>
          <FontAwesomeIcon icon="times" />
        </span>
      )}
    </BadgeStyled>
  );
};

Badge.defaultProps = {
  size: "sm",
};

export default Badge;
