import { Modal } from "react-bootstrap";
import styled from "styled-components/macro";

export const EditCampaignModal = styled(Modal)`
  color: #ffffff;

  .modal-content {
    background-color: ${({ theme }) => theme.modalBackground};
    border-radius: 20px;
    min-height: 500px;
    border: 1px solid rgb(79, 79, 79);
  }

  .icon-wrapper {
    display: flex;
    position: absolute;
    top: 30px;
    right: 100px;
    flex-direction: column;
    align-items: center;

    .step-icon {
      .icon-svg {
        stroke: ${({ theme }) => theme.secondary};
      }

      width: 50px;
      height: 50px;
      background: ${({ theme }) => theme.modalBackground};
      z-index: 2;
      border-radius: 50%;
      padding: 5px;
    }

    .step-title {
      color: ${({ theme }) => theme.secondary};
      font-size: 14px;
    }
  }

  .modal-header {
    padding: 50px;
    padding-bottom: 0;
    border-bottom: none;
    position: relative;
  }

  .modal-title {
    color: ${({ theme }) => theme.contrast};
    font-size: 18px;

    .build-campaign-title {
      color: ${({ theme }) => theme.contrast};
      display: flex;
      margin-right: 100px;

      .heading {
        padding-right: 20px;
        border-right: 2px solid gray;
        height: 35px;
        white-space: nowrap;
      }

      .modal-description {
        .content {
          white-space: pre-wrap;
          padding-left: 20px;
          font-size: 12px;
        }
      }
    }
  }

  .modal-body {
    padding: 10px 50px 0px 50px;

    .step-navigation {
      display: flex;
      gap: 10px;
      width: calc(100% - 25px);
      padding: 20px 0;
      justify-content: end;
    }

    .form-section {
      padding: 20px 0;
      position: relative;

      .scrolly {
        max-height: 400px;
        overflow-y: scroll;

        .steps-form {
          color: ${({ theme }) => theme.contrast};
          height: 500px;
        }

        ::-webkit-scrollbar {
          width: 8px;
        }

        ::-webkit-scrollbar-thumb {
          /* background-color: ${({ theme }) => theme.color.scroll}; */
        }
        ::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 20px 20px 20px 20px rgb(91 91 91);
        }
      }
    }
  }

  .steps {
    height: 100px;
  }
`;

export const StepItem = styled.li<{ active: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 12px;

  .step-title {
    color: ${({ active, theme }) => (active ? theme.color.green : "#ffffff")};
  }
`;
