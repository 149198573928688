import styled, { css } from "styled-components/macro";

export default styled.button<{
  $block?: boolean;
  $noPadding?: boolean;
  $circle?: boolean;
  $outline?: boolean;
  $danger?: boolean;
  $secondary?: boolean;
  $disabled?: boolean;
  $width?: number;
  $height?: number;
  $transparent?: boolean;
  $full?: boolean;
  $color?: "gold" | "purple" | "blue" | "gray" | "search-button" | "lightgray";
  $icon?: boolean;
  $squared?: boolean;
}>`
  min-width: ${({ $width }) => $width || 100}px;
  min-height: ${({ $height }) => $height || 45}px;
  border-style: solid;
  border-width: 1px;
  color: #ffffff;
  pointer-events: ${({ $disabled }) => ($disabled ? "none" : "auto")};

  border-radius: ${({ $circle, $squared }) => ($circle ? "50px" : $squared ? "5px" : "10px")};
  font-weight: 100;
  font-size: 14px;
  position: relative;

  padding: ${({ $noPadding, $circle }) =>
    $noPadding ? ".175rem .75rem" : $circle ? "4px 16px" : "7px 30px"};
  background-color: ${({ theme }) => theme.color.primary} !important;
  border-color: ${({ theme }) => theme.color.primary} !important;
  transition: 0.3s;

  &:hover {
    background-color: ${({ theme }) => theme.color.primaryDark} !important;
    border-color: ${({ theme }) => theme.color.primaryDark} !important;
  }

  &:disabled,
  &:active,
  &:focus {
    background-color: ${({ theme }) => theme.color.primary};
    border-color: ${({ theme }) => theme.color.primary};
    box-shadow: none;
    color: #ffffff;
  }

  .spinner-border {
    color: white;
    width: 1rem;
    height: 1rem;
    border-width: 0.15em;
  }

  :not(:disabled):not(.disabled):active {
    background-color: ${({ theme }) => theme.color.primary};
  }

  ${({ $disabled }) =>
    $disabled &&
    css`
      opacity: 0.65;
    `}

  ${({ $circle }) =>
    $circle &&
    css`
      min-width: 0;
    `}

  ${({ $outline, theme }) =>
    $outline &&
    css`
      background-color: ${theme.dark} !important;
      border-color: ${theme.checkboxBorder} !important;

      &:hover {
        background-color: ${theme.dark} !important;
        border-color: ${theme.checkboxBorder} !important;
        opacity: 0.65;
      }
    `}


    ${({ $transparent, theme }) =>
    $transparent &&
    css`
      background-color: transparent !important;
      border-color: ${theme.checkbox} !important;
      color: ${theme.contrast} !important;

      &:hover {
        background-color: transparent !important;
        border-color: ${theme.checkbox} !important;
        opacity: 0.65;
      }
    `}

  ${({ $danger }) =>
    $danger &&
    css`
      background-color: rgb(255, 0, 0) !important;
      border-color: rgb(255, 0, 0) !important;

      &:hover {
        background-color: rgb(255, 0, 0) !important;
        border-color: rgb(255, 0, 0) !important;
        opacity: 0.65;
      }
    `}

    ${({ $secondary, theme }) =>
    $secondary &&
    css`
      background-color: ${theme.secondary900} !important;
      border-color: ${theme.secondary900} !important;
      color: ${theme.alertModalBackground};

      :not(:disabled):not(.disabled):active {
        color: ${theme.alertModalBackground};
      }

      &:disabled,
      &:active,
      &:focus {
        color: ${theme.alertModalBackground};
      }

      &:hover {
        background-color: ${theme.secondary900} !important;
        border-color: ${theme.secondary900} !important;
        color: ${theme.alertModalBackground};
        opacity: 0.65;
      }
    `}

    ${({ $full }) =>
    $full &&
    css`
      width: 100%;
    `}

    ${({ $color, theme }) =>
    $color === "gold" &&
    css`
      background-color: ${theme.color.gold} !important;
      border-color: ${theme.color.gold} !important;
      color: #ffffff;

      :not(:disabled):not(.disabled):active {
        color: #ffffff;
      }

      &:disabled,
      &:active,
      &:focus {
        color: #ffffff;
      }

      &:hover {
        background-color: ${theme.color.gold} !important;
        border-color: ${theme.color.gold} !important;
        color: #ffffff;
        opacity: 0.65;
      }
    `}


    ${({ $color, theme }) =>
    $color === "purple" &&
    css`
      background-color: ${theme.color.purple} !important;
      border-color: ${theme.color.purple} !important;
      color: #ffffff;

      :not(:disabled):not(.disabled):active {
        color: #ffffff;
      }

      &:disabled,
      &:active,
      &:focus {
        color: #ffffff;
      }

      &:hover {
        background-color: ${theme.color.purple} !important;
        border-color: ${theme.color.purple} !important;
        color: #ffffff;
        opacity: 0.65;
      }
    `}


    ${({ $color, theme }) =>
    $color === "blue" &&
    css`
      background-color: ${theme.color.blue} !important;
      border-color: ${theme.color.blue} !important;
      color: #ffffff;

      :not(:disabled):not(.disabled):active {
        color: #ffffff;
      }

      &:disabled,
      &:active,
      &:focus {
        color: #ffffff;
      }

      &:hover {
        background-color: ${theme.color.blue} !important;
        border-color: ${theme.color.blue} !important;
        color: #ffffff;
        opacity: 0.65;
      }
    `}

    ${({ $color, theme }) =>
    $color === "gray" &&
    css`
      background-color: ${theme.background} !important;
      border-color: ${theme.selectAllBorder} !important;
      color: ${({ theme }) => theme.contrast};

      :not(:disabled):not(.disabled):active {
        color: ${({ theme }) => theme.contrast};
      }

      &:disabled,
      &:active,
      &:focus {
        color: ${({ theme }) => theme.contrast};
      }

      &:hover {
        background-color: ${theme.background} !important;
        border-color: ${theme.selectAllBorder} !important;
        color: ${({ theme }) => theme.contrast};
        opacity: 0.65;
      }
    `}

    ${({ $color, theme }) =>
    $color === "search-button" &&
    css`
      background-color: ${theme.searchButton} !important;
      border-color: ${theme.text} !important;
      color: #ffffff;

      :not(:disabled):not(.disabled):active {
        color: #ffffff;
      }

      &:disabled,
      &:active,
      &:focus {
        color: #ffffff;
      }

      &:hover {
        background-color: ${theme.searchButton} !important;
        border-color: ${theme.searchButton} !important;
        color: #ffffff;
        opacity: 0.65;
      }
    `}

    ${({ $color, theme }) =>
    $color === "lightgray" &&
    css`
      background-color: rgb(167, 169, 172) !important;
      border-color: ${theme.text} !important;
      color: #ffffff;

      :not(:disabled):not(.disabled):active {
        color: #ffffff;
      }

      &:disabled,
      &:active,
      &:focus {
        color: #ffffff;
      }

      &:hover {
        background-color: rgb(167, 169, 172) !important;
        border-color: ${theme.searchButton} !important;
        color: #ffffff;
        opacity: 0.65;
      }
    `}


    ${({ $icon }) =>
    $icon &&
    css`
      .button-icon-wrapper {
        display: flex;
        align-items: center;
      }
    `}
`;
