import styled from "styled-components/macro";

export const Step6Styled = styled.div`
  padding-right: 20px;

  .section-header {
    /* color: ${({ theme }) => theme.secondary}; */
  }

  .payment-form {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    margin-top: 20px;

    > section {
      background: ${({ theme }) => theme.reviewBackground};
    }
  }

  .name-campaign {
    .build-campaign-title {
      color: ${({ theme }) => theme.contrast};
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
    }
  }
`;
