import { numberWithCommas } from "./number";
import cuid from "cuid";

export interface ICategory {
  "2": string;
  "3": string;
  "4": string;
  "5": string;
  "6": string;
  "7": string;
  "8": string;
  "Criterion ID": string;
  Category: string;
  "RAD CPC": string;
}
export interface IYearMonthCount {
  year: string;
  month: string;
  count: number;
}
export interface IMainCategory {
  id: number | null;
  names: string[];
}
interface IObjectKeys {
  [key: string]: string | number | number[] | IYearMonthCount[] | IMainCategory | null;
}
export interface IDataForSEO extends IObjectKeys {
  language: string;
  loc_id: number | null;
  key: string;
  cmp: number;
  cpc: number;
  sv: number;
  categories: number[];
  ms: IYearMonthCount[];
  id: string;
  competition: number;
  difficultyScore: "Easy" | "Medium" | "Difficult";
  rawRadCpc: number;
  radCpc: number;
  discountPercentage: number;
  googleCpcString: string;
  radCpcString: string;
  discountPercentageString: string;
  svFormatted: string;
  radSv: string;
  radSvFormatted: string;
  display: string;
}

// Old
// [
//   {
//       "language": "en",
//       "loc_id": null,
//       "key": "suggest",
//       "cmp": 0.3678,
//       "cpc": 3.61,
//       "sv": 14762,
//       "categories": [
//           10019,
//       ],
//       "ms": [
//           {
//               "year": "2021",
//               "month": "04",
//               "count": 85
//           },
//           {
//               "year": "2021",
//               "month": "05",
//               "count": 63
//           },
//           {
//               "year": "2021",
//               "month": "06",
//               "count": 65
//           },
//       ],
//       "competition": 37,
//       "difficultyScore": "Medium",
//       "radCpc": 2.3594771241830066,
//       "discountPercentage": 65.359477124183,
//       "googleCpcString": "3.61",
//       "radCpcString": "2.36",
//       "discountPercentageString": "65.36",
//       "svFormatted": "14,762",
//       "radSv": "44286.00",
//       "radSvFormatted": "44,286"
//   },
// ]

// New
// categories: null
// competition: 90
// cpc: 1.63
// device: "all"
// difficultyScore: "Difficult"
// discountPercentage: 64.93506493506493
// discountPercentageString: "64.94"
// googleCpcString: "1.63"
// keyword: "washington nationals"
// language_code: "en"
// location_code: 2840
// monthly_searches: [{year: 2022, month: 3, search_volume: 14770}, {year: 2022, month: 2, search_volume: 5950},…]
// radCpc: 1.0584415584415583
// radCpcString: "1.06"
// radSv: "86100.00"
// radSvFormatted: "86,100"
// search_partners: false
// search_volume: 28700
// svFormatted: "28,700"

export interface IDataForSEOV3 {
  categories: null;
  competition: number;
  cpc: number;
  device: string;
  difficultyScore: "Easy" | "Medium" | "Difficult";
  discountPercentage: number;
  discountPercentageString: string;
  googleCpcString: string;
  keyword: string;
  language_code: string;
  location_code: number;
  monthly_searches: Array<{ month: number; year: number; search_volume: number }>;

  radCpc: number;
  radCpcString: string;
  radSv: string;
  radSvFormatted: string;
  search_partners: boolean;
  search_volume: number;
  svFormatted: string;

  display: string;
  id: string;
}

// export const transformKeywordsResponse = (results: Array<IDataForSEO>) => {
//   const difficulties = ["Easy", "Medium", "Difficult"];
//   const difficultyShare = 100 / difficulties.length;
//   const cpcRatio = 0.83;

//   const getRnd = (min: number, max: number) => {
//     return (Math.floor(Math.random() * (max - min + 1)) + min) / 100;
//   };

//   return results
//     .filter((result) => !!result.keyword)
//     .map((result) => {
//       result.id = cuid();
//       // Calculate the difficulty store
//       // Extract the first category for the keyword (if exists)

//       const radCpcNumber = 0;

//       if (!result.cpc) {
//         result.cpc = getRnd(83, 100);
//       } else if (result.cpc < cpcRatio) {
//         result.cpc = result.cpc + cpcRatio;
//       }

//       // Calculate the RAD CPC price
//       const radScale = getRnd(180, 220); // Before fixed by 2, Antonio ask for random number. So I set by 1.8 - 2.2
//       // result.rawRadCpc = radCpcNumber;
//       result.radCpc =
//         !radCpcNumber || radCpcNumber > result.cpc ? result.cpc / radScale : radCpcNumber;
//       result.discountPercentage = (result.radCpc / result.cpc) * 100;

//       // Round off the cpc values
//       result.googleCpcString = !result.cpc || result.cpc < cpcRatio ? "-" : result.cpc.toFixed(2);
//       result.radCpcString = !result.cpc || result.cpc < cpcRatio ? "-" : result.radCpc.toFixed(2);
//       result.discountPercentageString = result.discountPercentage.toFixed(2);
//       // result.svFormatted = numberWithCommas(result.sv || 0);
//       // result.radSv = ((result.sv || 0) * 3).toString();
//       // result.radSvFormatted = numberWithCommas(result.radSv);
//       result.display = result.keyword;
//       result.keyword = result.keyword.toLowerCase();
//       return result;
//     });
// };

export const convertKeywordArrToObj = (arr: Array<IDataForSEO>, includeCapitalize?: boolean) => {
  const hashtable = arr.reduce((prev, cur) => {
    if (cur) {
      prev[includeCapitalize ? cur.key : cur.key.toLowerCase()] = {
        ...cur,
        display: cur.key,
        device: "all",
        keyword: cur.key,
        language_code: "en",
        monthly_searches: cur.ms.map((prev) => ({
          search_volume: prev.count,
          month: Number(prev.month),
          year: Number(prev.year),
        })),
        search_partners: false,
        location_code: cur.loc_id || 2840,
        search_volume: cur.sv,
        categories: null,
        // categories: null
        // competition: 90
        // cpc: 1.63
        // device: "all"
        // difficultyScore: "Difficult"
        // discountPercentage: 64.93506493506493
        // discountPercentageString: "64.94"
        // googleCpcString: "1.63"
        // keyword: "washington nationals"
        // language_code: "en"
        // location_code: 2840
        // monthly_searches: [{year: 2022, month: 3, search_volume: 14770}, {year: 2022, month: 2, search_volume: 5950},…]
        // radCpc: 1.0584415584415583
        // radCpcString: "1.06"
        // radSv: "86100.00"
        // radSvFormatted: "86,100"
        // search_partners: false
        // search_volume: 28700
        // svFormatted: "28,700"
      };
    }
    return prev;
  }, {} as { [key: string]: IDataForSEOV3 });
  return hashtable;
};

export const convertKeywordV3ArrToObj = (
  arr: Array<IDataForSEOV3>,
  includeCapitalize?: boolean
) => {
  const hashtable = arr.reduce((prev, cur) => {
    if (cur) {
      prev[includeCapitalize ? cur.keyword : cur.keyword.toLowerCase()] = {
        ...cur,
        display: cur.keyword,
      };
    }
    return prev;
  }, {} as { [key: string]: IDataForSEOV3 });
  return hashtable;
};

export function buildFormData(formData: FormData, data: { [k: string]: any }, parentKey?: string) {
  if (data && typeof data === "object" && !(data instanceof Date) && !(data instanceof File)) {
    Object.keys(data).forEach((key) => {
      if (data[key]) {
        buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
      }
    });
  } else {
    const value = data === null ? "" : data;
    if (parentKey) {
      formData.append(parentKey, value as string);
    }
  }
}

export enum KEYWORD_SEARCH_TYPE {
  SUGGESTED_KEYWORDS = "suggestedKeywords",
  TARGET_BY_COMPETITOR = "targetByCompetitor",
  SAVED_LIST = "yourSavedList",
}

export const dropdownItemValues = {
  [KEYWORD_SEARCH_TYPE.SUGGESTED_KEYWORDS]: {
    text: "Suggested Keywords",
    value: KEYWORD_SEARCH_TYPE.SUGGESTED_KEYWORDS,
  },
  [KEYWORD_SEARCH_TYPE.TARGET_BY_COMPETITOR]: {
    text: "Target by Competitor",
    value: KEYWORD_SEARCH_TYPE.TARGET_BY_COMPETITOR,
  },
  [KEYWORD_SEARCH_TYPE.SAVED_LIST]: {
    text: "Your Saved Lists",
    value: KEYWORD_SEARCH_TYPE.SAVED_LIST,
  },
} as Record<
  | KEYWORD_SEARCH_TYPE.SUGGESTED_KEYWORDS
  | KEYWORD_SEARCH_TYPE.TARGET_BY_COMPETITOR
  | KEYWORD_SEARCH_TYPE.SAVED_LIST,
  {
    text: string;
    value:
      | KEYWORD_SEARCH_TYPE.SUGGESTED_KEYWORDS
      | KEYWORD_SEARCH_TYPE.TARGET_BY_COMPETITOR
      | KEYWORD_SEARCH_TYPE.SAVED_LIST;
  }
>;
