import React from "react";
import { TextAreaStyled } from "./styled";

interface ITextAreaProps extends React.ComponentPropsWithoutRef<"textarea"> {
  height?: number;
  readonly?: boolean;
}

const TextArea: React.FunctionComponent<ITextAreaProps> = ({ height, readonly, ...props }) => {
  return <TextAreaStyled {...props} $height={height} $readonly={readonly} />;
};

export default TextArea;
