import { createGlobalStyle } from "styled-components";
/* @import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap"); */

export const GlobalStyle = createGlobalStyle`
  .show > .btn-primary.dropdown-toggle {
    color:${({ theme }) => theme.contrast};
  }

  .btn-primary {
    color:${({ theme }) => theme.contrast};
  }

  .tooltip-inner {
    background: transparent;
  }

  .tooltip-arrow {
    &:before {
      border-right-color: transparent !important;
    }
  }

  .text-left {
    text-align: left;
  }

  .text-right {
    text-align: right;
  }

  .text-center {
    text-align: center;
  }

  html {
    font-size: 16px;
  }
  input:-webkit-autofill,
  textarea:-webkit-autofill,
  select:-webkit-autofill {
    /* -webkit-box-shadow: 0 0 0 1000px rgb(80, 80,80) inset !important; */
    -webkit-text-fill-color: ${({ theme }) => theme.contrast} !important;
  }

  html,
  body {
    display: flex;
    width: 100%;
    min-height: 100%;
    font-family: ${({ theme }) => theme.font.primary}, "Open Sans", sans-serif;
  }

  #root {
    transition: .1s;
    display: flex;
    width: 100%;
    background-color: ${({ theme }) => theme.dark};
    
    .form-control { 
      transition:unset!important;

      &:focus {
        box-shadow: none;
      }
     }
    .recharts-tooltip-wrapper .recharts-default-tooltip { background-color:#1A1A1A!important; }
  }

  ::-webkit-scrollbar-track {
      -webkit-box-shadow: ${({ theme }) => theme.secondaryScrollTrack};
      border-radius: 10px;
    }

    ::-webkit-scrollbar {
      width: 7px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
      background-color: ${({ theme }) => theme.secondaryScroll};
    }




  .spinner-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .secondaryFont {
    font-family: "Open Sans", sans-serif;
  }

  .tertiaryFont {
    font-family: "avenirNextDemiBold";
  }

  /* .btn {
    border-radius: 4px;
    font-size: 1em;
    font-weight: 600;
    padding: 8px 30px;
  } */

  .btn:focus,
  .btn:active {
    box-shadow: none !important;
  }

  /* .btn-outline-primary:hover,
  .btn-outline-primary:active > .btn-outline-primary {
    color: #ffffff;
    background-color: transparent !important;
    border-color: #e8e8e8 !important;
  } */

  .btn-lg {
    min-height: 60px;
    min-width: 165px;
  }

  
  .dropdown-toggle::after {
    margin-left: 8px;
  }

  .custom-control.custom-checkbox label:before {
    border: 1px solid #9faace;
  }

  .custom-control.custom-checkbox label:before,
  .custom-control.custom-checkbox label:after {
    width: 18px;
    height: 18px;
  }

  .link {
    text-decoration: underline;
    color: #4780ff;
  }
  
  .custom-control-label::before,
  .custom-control-label::after {
    top: 0.1rem;
  }

  .errorMessage {
    text-align: left;
    margin-top: 9px;
    color: #ff0033;
    font-size: ${({ theme }) => theme.size.md};
    font-weight: ${({ theme }) => theme.weight.bold};
  }

   .form-control {
    border-radius: 0px;
    border: 1px solid #e8e8e8;
    background: #ffffff;
    /* padding: 10px 15px; */
    font-size: ${({ theme }) => theme.size.md};
  }



  /* Flex for fill in screen height
  **************************/
  .container-fluid {
    display: flex;
    flex-direction: column;
    flex: 1 1;

    padding-left: 20px;
    padding-right: 20px;
  }


    .form-control {
      border-radius: 0px !important;
      font-family: "Open Sans";
      font-weight: 100;
      font-size: 14px;
    }

  .btn-primary,
  .btn-primary:disabled {
    background-color: #FE0099;
    border-color: #FE0099;
  }

  .field.selectedKeywords .keyword { color:#01FF67; }


  .row {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
`;
