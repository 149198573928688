import styled from "styled-components/macro";

export const EditUserFormStyled = styled.div`
  margin-top: 40px;

  .dropdown-group {
    display: flex;
    justify-content: space-between;
  }

  .form-group {
    margin-bottom: 10px;
  }

  .admin-label {
    margin-left: 10px;
  }
`;
