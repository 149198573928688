import Accordion from "components/Accordion";
import Checkbox from "components/Checkbox";
import { CATEGORY_KEY_VALUES_ARRAY, CATEGORY_KEY_VALUES_OBJECT } from "constants/category";
import React from "react";
import { AdFormCategoryID } from "services/types";
import { CategoryListWrapper, CategoryList, StyledItem } from "../styled";

interface ICategoryItemList {
  includes: CATEGORY_KEY_VALUES_OBJECT;
  setIncludes: (prev: AdFormCategoryID) => void;
  excludes: CATEGORY_KEY_VALUES_OBJECT;
  setExcludes: (prev: AdFormCategoryID) => void;
  categoryList: CATEGORY_KEY_VALUES_ARRAY;
  deselect: (subCategory: AdFormCategoryID, type: "exclude" | "include") => void;
  parents: { [key: string]: string };
  onSelectAll: (includes: CATEGORY_KEY_VALUES_OBJECT, excludes: CATEGORY_KEY_VALUES_OBJECT) => void;
}

const CategoryItemList: React.FunctionComponent<ICategoryItemList> = ({
  onSelectAll,
  deselect,
  includes,
  setIncludes,
  excludes,
  setExcludes,
  categoryList,
  parents,
}) => {
  const [active, setActive] = React.useState<null | string>(null);
  return (
    <CategoryListWrapper>
      <div className="category-wrapper">
        {Object.keys(parents).map((group) => {
          return (
            <Accordion
              autoHide={active !== parents[group]}
              onClick={() => {
                setActive(parents[group]);
              }}
              noBorder
              key={group}
              trigger={
                <span data-testid={`category-${group}`}>
                  <b>{parents[group]}</b>
                </span>
              }
              content={
                <CategoryList>
                  <StyledItem>
                    <div className="category-item">
                      <div
                        onClick={() => {
                          const allSubcategories = categoryList[group].reduce((acc, cur) => {
                            acc[cur.source_key] = cur;
                            return acc;
                          }, {} as { [key: string]: AdFormCategoryID & { behavior: "exclude" | "include" } });

                          const getNewExcludes = () => {
                            delete excludes[group];
                            return excludes;
                          };

                          const getNewIncludes = () => ({
                            ...includes,
                            [group]:
                              includes[group] &&
                              Object.values(includes[group]).length ===
                                Object.values(allSubcategories).length
                                ? {}
                                : allSubcategories,
                          });

                          const newIncludes = getNewIncludes();
                          const newExcludes = getNewExcludes();

                          onSelectAll(newIncludes, newExcludes);
                        }}
                      >
                        <Checkbox
                          shape="circle"
                          size="md"
                          type="checkbox"
                          checked={
                            !!(
                              includes[group] &&
                              categoryList[group].length === Object.values(includes[group]).length
                            )
                          }
                        />
                      </div>
                      <div
                        onClick={() => {
                          const allSubcategories = categoryList[group].reduce((acc, cur) => {
                            acc[cur.source_key] = cur;
                            return acc;
                          }, {} as { [key: string]: AdFormCategoryID & { behavior: "exclude" | "include" } });

                          const getNewExcludes = () => ({
                            ...excludes,
                            [group]:
                              excludes[group] &&
                              Object.values(excludes[group]).length ===
                                Object.values(allSubcategories).length
                                ? {}
                                : allSubcategories,
                          });
                          const getNewIncludes = () => {
                            delete includes[group];
                            return includes;
                          };

                          const newIncludes = getNewIncludes();
                          const newExcludes = getNewExcludes();

                          onSelectAll(newIncludes, newExcludes);
                        }}
                      >
                        <Checkbox
                          shape="exclude"
                          size="md"
                          type="checkbox"
                          checked={
                            !!(
                              excludes[group] &&
                              categoryList[group].length === Object.values(excludes[group]).length
                            )
                          }
                        />
                      </div>

                      <span className="parent">
                        <i>Select All</i>
                      </span>
                    </div>
                  </StyledItem>
                  {categoryList[group].map((item) => {
                    return (
                      <StyledItem key={item.id}>
                        <div className="category-item">
                          <div
                            data-testid={`category-${item.source_key}-include`}
                            onClick={() => {
                              if (
                                includes[item.source_parent_key!] &&
                                includes[item.source_parent_key!][item.source_key]
                              ) {
                                deselect(item, "include");
                              } else {
                                setIncludes(item);
                              }
                            }}
                          >
                            <Checkbox
                              shape="circle"
                              size="md"
                              type="checkbox"
                              checked={
                                !!(
                                  includes[item.source_parent_key!] &&
                                  includes[item.source_parent_key!][item.source_key]
                                )
                              }
                            />
                          </div>

                          <div
                            data-testid={`category-${item.source_key}-exclude`}
                            onClick={() => {
                              if (
                                excludes[item.source_parent_key!] &&
                                excludes[item.source_parent_key!][item.source_key]
                              ) {
                                deselect(item, "exclude");
                              } else {
                                setExcludes(item);
                              }
                            }}
                          >
                            <Checkbox
                              shape="exclude"
                              size="md"
                              type="checkbox"
                              checked={
                                !!(
                                  excludes[item.source_parent_key!] &&
                                  excludes[item.source_parent_key!][item.source_key]
                                )
                              }
                            />
                          </div>
                          <div className="category-name">
                            {item.iab_code.includes("-") ? (
                              <span>{item.name}</span>
                            ) : (
                              <span className="parent">
                                <i>Select All</i>
                              </span>
                            )}
                          </div>
                        </div>
                      </StyledItem>
                    );
                  })}
                </CategoryList>
              }
            />
          );
        })}
      </div>
    </CategoryListWrapper>
  );
};

export default CategoryItemList;
