import styled, { css } from "styled-components/macro";

export const MetricSelectorStyled = styled.div<{ color?: string; open: boolean }>`
  width: 200px;
  height: 55px;
  background: ${({ color }) => color || "rgb(68, 68, 68)"};
  padding: 8px 20px;
  display: flex;
  flex-direction: column;
  justify-content: end;
  font-weight: 600;
  line-height: 20px;
  position: relative;
  border-radius: 10px;

  ${({ open }) =>
    open
      ? css`
          &:before {
            content: "";
            border-bottom: 8px solid white;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            position: absolute;
            top: 50%;
            right: 15px;
            margin-top: -2px;
            transform: rotate(180deg);
          }
        `
      : css`
          cursor: pointer;
          &:before {
            content: "";
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-top: 8px solid white;

            position: absolute;
            top: 50%;
            right: 15px;
            margin-top: -2px;
            transform: rotate(-90deg);
          }
        `}

  .metric-key {
    font-size: 12px;
  }

  .metric-value {
    font-size: 18px;
  }
`;

export const CompareSelector = styled.ul<{ color: string }>`
  background: ${({ theme }) => theme.dropdown};
  list-style: none;
  margin: 0;
  padding: 10px 0 10px 0;
  color: ${({ theme }) => theme.contrast};
  width: 100%;
  height: auto;
  position: absolute;
  top: 50px;
  left: 0;
  z-index: 99;
  border-radius: 0px 0px 10px 10px;

  &:after {
    content: "";
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 10px;
    background-color: ${({ color }) => color};
    border-radius: 0px 0px 10px 10px;
  }
`;

export const Comparator = styled.li<{ active: boolean; disabled: boolean }>`
  padding: 10px;
  cursor: pointer;
  color: ${({ theme }) => theme.contrast};
  font-size: 14px;

  &:hover {
    background-color: ${({ theme }) => theme.background};
  }

  font-weight: normal;
  padding: 10px;

  ${({ active, theme }) =>
    active &&
    css`
      font-size: 16px;
      cursor: default;
      color: ${theme.contrast};
      font-weight: bold;
    `}

  ${({ disabled, theme }) =>
    disabled &&
    css`
      cursor: default;
      pointer-events: none;
      color: ${theme.dropdownDisabled};
    `}
`;
