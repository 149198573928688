import { displayDate } from "constants/utility/date";
import { numberWithCommas } from "constants/utility/number";
import { Column } from "react-table";
import { ICampaignReportData } from "store/states/campaigns.state";

type PERFORMANCE_TYPE = "keyword" | "location" | "creative" | "creative" | "device" | "date";

const columns: Array<Column<ICampaignReportData>> = [
  {
    Header: "Ad Spend",
    id: "spend",
    accessor: (report) => `$${report.spend || report.cost || 0}`,
  },
  {
    Header: "Impressions",
    id: "impression",
    accessor: (report) => `${numberWithCommas(report.impression || 0, false)}`,
  },
  {
    Header: "Clicks",
    id: "click",
    accessor: (report) => report.click,
  },
  {
    Header: "CTR",
    id: "ctr",
    accessor: (report) => `${report.ctr.toFixed(2)}%`,
  },
  {
    Header: "Conversions",
    id: "conversion",
    accessor: (report) => `${report.conversion}`,
  },
  {
    Header: "CVR",
    id: "cvr",
    accessor: (report) => report.cvr && `${report.cvr.toFixed(2)}%`,
  },
];

const PERFORMANCE_COLUMN_LIST = {
  keyword: {
    Header: "Keyword",
    id: "segment_name",
    accessor: (report) => report.segment_name || "",
  },

  location: {
    Header: "Location",
    id: "geo",
    accessor: (report) => report.geo || "",
  },

  creative: {
    Header: "Ads",
    id: "creative",
    accessor: (report) => report.creative || "",
  },

  date: {
    Header: "Date",
    id: "date",
    accessor: (report) => (report.date && displayDate(new Date(report.date))) || "",
  },

  device: {
    Header: "Device",
    id: "device",
    accessor: (report) => report.platform || "",
  },
} as Record<PERFORMANCE_TYPE, Column<ICampaignReportData>>;

export const columnWithDate = [PERFORMANCE_COLUMN_LIST["date"], ...columns];
export const columnWithDevice = [PERFORMANCE_COLUMN_LIST["device"], ...columns];
export const columnWithKeyword = [PERFORMANCE_COLUMN_LIST["keyword"], ...columns];
export const columnWithLocation = [PERFORMANCE_COLUMN_LIST["location"], ...columns];
export const columnWithAds = [PERFORMANCE_COLUMN_LIST["creative"], ...columns];
