import React from "react";
import { CustomModal } from "./styled";
import { Modal } from "react-bootstrap";
import Button from "components/Button";
import { RootState } from "store/store";
import { connect, ConnectedProps, useDispatch } from "react-redux";
import { hideChangePasswordPopup, showForgetPasswordPopup } from "store/actions/popup.actions";
import Checkbox from "components/Checkbox";
import CloseButton from "components/CloseButton";
import api from "services/api";
import Input from "components/Input";
import { AdsIcon } from "components/Icons";
import { useFormik } from "formik";
import EyesCloseIcon from "components/Icons/EyesCloseIcon";
import EyesOpenIcon from "components/Icons/EyesOpenIcon";
import { updatePassword } from "store/actions/user.actions";
import updatePasswordSchema from "schemas/update-password.schema";

interface IChangePasswordPopup extends PropsFromRedux {}

const mapStateToProps = ({ popup, changePassword }: RootState) => ({
  popup,
  changePassword,
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const ChangePasswordPopup: React.FunctionComponent<IChangePasswordPopup> = ({
  popup,
  changePassword,
}) => {
  const dispatch = useDispatch();

  const [hideOldPassword, setHideOldPassword] = React.useState(true);
  const [hideNewPassword, setHideNewPassword] = React.useState(true);
  const [hideConfirmPassword, setHideConfirmPassword] = React.useState(true);

  const onHide = () => {
    dispatch(hideChangePasswordPopup());
  };

  const { values, errors, handleChange, submitForm, setFieldError, touched, resetForm } = useFormik(
    {
      initialValues: {
        password: "",
        newPassword: "",
        confirm: "",
      },
      onSubmit: (values) => {
        dispatch(updatePassword(values));
      },
      validationSchema: updatePasswordSchema,
    }
  );

  React.useEffect(() => {
    if (changePassword.error?.newPassword) {
      setFieldError("newPassword", changePassword.error.newPassword[0]);
    }
  }, [changePassword.error]);

  const onExit = () => {
    resetForm();
    setFieldError("newPassword", undefined);
  };

  return (
    <CustomModal
      keyboard
      show={popup.showChangePasswordPopup.show}
      onExit={onExit}
      onHide={onHide}
      centered
    >
      <Modal.Header>
        <b>Change Password</b>
        <CloseButton onClick={onHide} top={10} right={34} dark />
      </Modal.Header>
      <Modal.Body>
        <div className="input-group">
          <div className="input-wrapper">
            <Input
              touched={touched.password}
              error={errors.password}
              name="password"
              value={values.password}
              onChange={handleChange}
              autoComplete="off"
              type={hideOldPassword ? "password" : "text"}
              placeholder="Old Password"
            />
            <div
              className={`icon-wrapper ${hideOldPassword ? "show" : ""}`}
              onClick={() => setHideOldPassword((prev) => !prev)}
            >
              {hideOldPassword ? <EyesCloseIcon /> : <EyesOpenIcon />}
            </div>
          </div>

          <div className="input-wrapper">
            <Input
              touched={touched.newPassword}
              error={errors.newPassword}
              name="newPassword"
              onChange={handleChange}
              value={values.newPassword}
              autoComplete="off"
              type={hideNewPassword ? "password" : "text"}
              placeholder="New Password"
            />
            <div
              className={`icon-wrapper ${hideNewPassword ? "show" : ""}`}
              onClick={() => setHideNewPassword((prev) => !prev)}
            >
              {hideNewPassword ? <EyesCloseIcon /> : <EyesOpenIcon />}
            </div>
          </div>

          <div className="input-wrapper">
            <Input
              touched={touched.confirm}
              error={errors.confirm}
              name="confirm"
              onChange={handleChange}
              value={values.confirm}
              autoComplete="off"
              type={hideConfirmPassword ? "password" : "text"}
              placeholder="Confirm Password"
            />
            <div
              className={`icon-wrapper ${hideConfirmPassword ? "show" : ""}`}
              onClick={() => setHideConfirmPassword((prev) => !prev)}
            >
              {hideConfirmPassword ? <EyesCloseIcon /> : <EyesOpenIcon />}
            </div>
          </div>
          <span
            className="forget-password-btn"
            onClick={() => {
              dispatch(hideChangePasswordPopup());
              dispatch(showForgetPasswordPopup());
            }}
          >
            Forget Password?
          </span>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button width={200} outline onClick={onHide}>
          Cancel
        </Button>
        <Button
          width={200}
          loading={changePassword.loading}
          onClick={() => {
            submitForm();
          }}
        >
          Change Password
        </Button>
      </Modal.Footer>
    </CustomModal>
  );
};

export default connector(ChangePasswordPopup);
