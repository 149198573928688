import React from "react";
import { Image } from "react-bootstrap";
import googleIcon from "assets/images/google-icon.svg";
import reverseAdsIcon from "assets/images/logoNoText.png";
import { IDataForSEOV3 } from "constants/utility/keyword";
import Spinner from "../Spinner";
import { SelectedKeywordsBar } from "./styled";

interface IKeywordSidebar {
  globalSelectedKeywords: Array<IDataForSEOV3>;
  loading?: boolean;
  search?: boolean;
  score?: boolean;
  showAvg?: boolean;
}

const KeywordSidebar: React.FunctionComponent<IKeywordSidebar> = ({
  globalSelectedKeywords,
  loading,
  search,
  score,
  showAvg,
}) => {
  const avgGoogle =
    globalSelectedKeywords.reduce((acc, cur) => acc + cur.cpc, 0) / globalSelectedKeywords.length;

  const avgRAD =
    globalSelectedKeywords.reduce((acc, cur) => acc + cur.radCpc, 0) /
    globalSelectedKeywords.length;

  return (
    <SelectedKeywordsBar data-testid="keyword-sidebar">
      <h5 className="keywordbar-title">Campaign Keywords</h5>
      {globalSelectedKeywords.length ? (
        <>
          <div className="selected-table">
            <div className="keyword-column">Keyword</div>
            <div className="search-column">{search ? "Search" : ""}</div>
            <div className="score-column">{score ? "Score" : ""}</div>
            <div className="cpc-column">
              <span className="brand-text">CPC</span>
            </div>
            <div className="google-icon-column">
              <Image className="selected-brandIcon" src={googleIcon} />
            </div>
            <div className="rad-icon-column">
              <Image className="selected-brandIcon rad" src={reverseAdsIcon} />
            </div>
          </div>

          {showAvg && (
            <div className="avg-tab">
              <span className="avg-cpc-title">Average CPC</span>
              <span className="avg-google">{avgGoogle.toFixed(2)}</span>
              <span className="avg-rad">{avgRAD.toFixed(2)}</span>
            </div>
          )}

          <ul className="keyword-table-list">
            {globalSelectedKeywords.map((obj) => (
              <li key={obj.keyword} className="keywords-body">
                <div className="keyword-column">{obj.display}</div>
                <div className="search-column search">{obj.svFormatted}</div>
                <div
                  className={`score-column comp-txt ${
                    obj.difficultyScore === "Easy"
                      ? "difficultyLow"
                      : obj.difficultyScore === "Medium"
                      ? "difficultyMedium"
                      : "difficultyHigh"
                  }`}
                >
                  {obj.competition}
                </div>
                <div className="google-cpc-column">{obj.googleCpcString}</div>
                <div className="rad-txt rad-cpc-column">{obj.radCpcString}</div>
              </li>
            ))}
          </ul>

          {showAvg && (
            <div className="avg-tab">
              <span className="avg-cpc-title">Average CPC</span>
              <span className="avg-google">{avgGoogle.toFixed(2)}</span>
              <span className="avg-rad">{avgRAD.toFixed(2)}</span>
            </div>
          )}
        </>
      ) : loading ? (
        <Spinner />
      ) : (
        <span className="empty-state">Please search and select your keyword</span>
      )}
    </SelectedKeywordsBar>
  );
};

export default KeywordSidebar;
