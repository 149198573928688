import styled from "styled-components";
import graphic from "assets/images/empty-graphics/privacy.png";
// const graphic = require("assets/images/empty-graphics/privacy-graphic.svg").default;

export const PrivacyStyled = styled.div`
  .privacy-wrapper {
    display: flex;
    margin-top: 20px;
    flex-direction: column;
    overflow-y: auto;
    max-height: 800px;

    .content-wrapper {
      display: flex;

      .content {
        width: 40%;
      }

      .graphic-wrapper {
        width: 60%;
        height: fit-content;
        position: sticky;
        top: 0;
      }
    }

    .graphic {
      background-repeat: no-repeat;
      background-image: url(${graphic});
      background-size: 400px;
      width: auto;
      height: 500px;
      background-position: center;
    }

    .title {
      font-size: 14px;
      color: ${({ theme }) => theme.text};
    }

    .privacy-section {
      font-size: 14px;
      padding-left: 20px;
      margin-top: 5px;
      color: ${({ theme }) => theme.text};
    }
  }
`;
