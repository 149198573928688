import { IErrorMessage } from "../../services/types";

export interface TargetingItem {
  id: number;
  alternative_id: number;
  source: "adform";
  type: "device" | "browser" | "os";
  name: string;
}

interface TargetingState {
  loading: boolean;
  error: IErrorMessage | null;
  data: {
    os: Array<TargetingItem>;
    devices: Array<TargetingItem>;
    browsers: Array<TargetingItem>;
    countries: Array<GeoTarget>;
  };
}

export interface GeoTarget {
  id: number;
  continentId: number;
  name: string;
  iso3Code: string;
  iso2Code: string;
}

const targetingState = {
  loading: false,
  error: null,
  data: {
    os: [],
    devices: [],
    browsers: [],
    countries: [],
  },
} as TargetingState;

export default targetingState;
