import Accordion from "components/Accordion";
import React from "react";
import { PrivacyStyled } from "./styled";

const Privacy = () => {
  return (
    <PrivacyStyled>
      <b>PRIVACY / POLICY</b>
      <div className="privacy-wrapper">
        <p className="title">Privacy Policy of Vatic AI Services</p>

        <div className="content-wrapper">
          <div className="content">
            <Accordion
              noBorder={false}
              content={
                <div className="privacy-section">
                  <p>
                    Vatic AI provides targeted advertising and marketing services for our clients
                    ("Clients" or "Advertisers").
                  </p>
                  <p>
                    Our products help show our clients' ads to the people that are most likely to
                    find them interesting. We aim to make advertising in the digital space more
                    useful and relevant to consumers by showing ads based on keyword intent across
                    digital channels. This data may come from outbound data mining, unique data
                    partnerships with the world's leading data companies and innovative data share
                    agreements with publishers, SSP, Ad Exchanges and Ad Networks. All of this is
                    used to make sure that users receive relevant ads at the right time.
                  </p>
                  <p>
                    To do this, when you visit a website or a mobile application operated by an
                    Advertiser (collectively"Digital Properties"), open an email from an Advertiser,
                    or we serve you an ad on behalf of an Advertiser on a third party site, we may
                    collect data as described in this Privacy Notice. Our platform uses that data,
                    as well as other data described below, to help Advertisers provide ads to you
                    that are more relevant to you.
                  </p>

                  <p>
                    For example, let's say you just joined a local soccer club and are in the market
                    for a new soccer ball. If you visit ACME Soccer Ball Co.'s website in search of
                    the perfect soccer ball, but don't purchase one just yet because you are still
                    looking, we may later show you ACME Soccer Ball Co. ads to encourage you to come
                    back and purchase one of their soccer balls, perhaps even with a discount offer
                    or notice of an upcoming sale. We may also show you ads from ACME Soccer Ball
                    Co. or other companies with additional product recommendations you may be
                    interested in, such as for soccer cleats, or tickets to an upcoming soccer
                    match, as you browse the internet.
                  </p>
                  <p>
                    We also provide various analytics to help advertisers measure whether their
                    campaigns were successful or not -- for instance, by measuring whether
                    particular campaigns led to consumers taking a particular action, like buying a
                    product or visiting a website (sometimes called a "conversion").
                  </p>
                  <p>
                    We also monitor what IP address ranges visitors to our customers' websites are
                    from, match those IP address ranges to companies, and use that information to
                    provide aggregated reports to companies regarding their website traffic.
                  </p>
                </div>
              }
              trigger={<span>About Vatic AI Services</span>}
            />

            <Accordion
              noBorder={false}
              content={
                <div className="privacy-section">
                  <p>
                    We collect the following categories of information for the purposes explained
                    below.
                  </p>

                  <p>
                    Activity on Advertisers' Digital Properties: This is data about your browsing
                    activity on the Advertiser's website or application. For example, which pages
                    you visited and when, what items were clicked on a page, how much time was spent
                    on a page, whether you downloaded a white paper on a business to business
                    ("B2B") website, what items you placed into your online shopping cart, what
                    products were purchased and the price of the products purchased.
                  </p>

                  <p>
                    Device and browser information: This is technical information about the device
                    or browser you use to access the Advertiser's Digital Property. For example,
                    your device's IP address, cookie string data, operating system, and (in the case
                    of mobile devices) your device type, and mobile device's identifier (such as the
                    Apple IDFA or Android Advertising ID, and any other unique identifier that may
                    be assigned to the mobile device).
                  </p>
                  <p>
                    Ad data: This is data about the online ads we have served (or attempted to
                    serve) to you. For example, how many times an ad has been served to you, what
                    page the ad appeared on, and whether you clicked on or otherwise interacted with
                    the ad. This data includes information about how well our clients' ads and
                    campaigns have performed, whether on our platform or on other platforms.
                  </p>

                  <p>
                    Data from Advertising Partners: This is data that we lawfully receive from other
                    digital advertising companies that we work with ("Advertising Partners") to help
                    us deliver ads to you and recognize you across browsers and devices. This may
                    include pseudonymous advertiser identifiers (meaning identifiers that help
                    identify your browser or device, but do not directly identify you as a person)
                    which some Advertisers or other third party Advertising Platforms choose to
                    share with us – for example, your "Customer ID" with an Advertiser, a
                    pseudonymous identifier (such as a cookie) associated with a hashed version of
                    your email address, or demographic data such as age range. We may work with our
                    Advertisers and Advertising Partners to synchronize their unique, pseudonymous
                    identifiers to our own to enable us to more accurately recognise a particular
                    unique browser or device and the advertising interests associated with it.
                  </p>

                  <p>
                    Email and CRM data from Advertisers: Some Advertisers choose to share clear
                    email addresses (as opposed to hashed email addresses, described below) or other
                    contact information belonging to their customers with us, so that (with the help
                    of Advertising Partners) we can help the Advertiser serve, measure and analyze
                    targeted ads to customers. For example, if you have given ACME Soccer Ball Co.
                    your email address, through our service, ACME Soccer Ball Co. may send you a
                    promotional email for a soccer ball you looked at but did not purchase.
                    Similarly if you provided your email to a software website when you downloaded a
                    white paper, through our services the software company may send you a follow up
                    email providing you with more information about the software company's products
                    or services. We use our Advertiser's CRM Data (e.g., their customer lists or
                    other information they provide to us about their customers) that consists of
                    clear email addresses only for the purpose of assisting that particular
                    Advertiser with their own advertising efforts and, in some cases, so we can
                    report performance data back to the Advertiser's CRM / reporting system. We do
                    not share clear email addresses with other third parties for their advertising
                    purposes
                  </p>

                  <p>
                    Hashed email addresses: If an Advertiser permits us, we collect hashed versions
                    of the emails that consumers have entered on that Advertiser's site. Hashing is
                    a "one-way function" that effectively pseudonymizes email addresses. For
                    instance, when joe_the_cat@vaticai.com is run through a typical hashing
                    function, it becomes the following string of digits:
                    0F0B7B1A1A7E8BDBBC6AA545F8CCD6F83671B32479271BFCB6CC8498912058D5. We take this
                    step to de-identify data and protect email addresses, while being able to use an
                    identifier to better connect devices and browsers. We then employ these hashed
                    emails (along with other information) for cross-device targeting, in order to
                    try to recognize users across devices and browsers – for instance, to find the
                    same user across multiple devices such as computers, tablets, and mobile
                    devices. We describe how this helps us better provide our services in "How We
                    Use the Data We Collect" below.
                  </p>

                  <p>
                    Contact Information: We obtain and collect contact information from various
                    third party sources, including from public sources and through licenses with
                    data providers. We may also infer contact information based on email addresses
                    and email naming conventions. This contact information is B2B information – in
                    other words, it is usually the contact information of someone at a business
                    email address, business street address, or sometimes, a business telephone
                    number. We will not collect contact information this way for individuals who are
                    located in European Territories.
                  </p>

                  <p>
                    Shopping Information: Some Advertisers provide us with information about their
                    customers' shopping habits, including transactional information, product codes,
                    and check-out activity (along with browsing information that we collect). This
                    information may come from their websites or other transactional information they
                    (or their service providers) maintain. We generally use this information to help
                    us better target, personalize, and measure the effectiveness of advertising
                    campaigns.
                  </p>
                  <p>
                    Information We Receive in Our Corporate Capacity: We also collect information
                    from our own customers and those who visit our website(s). To learn more about
                    how we collect and use that information, please see our Website Data Privacy
                    page.
                  </p>
                </div>
              }
              trigger={<span>What Data We Collect</span>}
            />

            <Accordion
              noBorder={false}
              content={
                <div className="privacy-section">
                  <p>
                    We use this data to help our Advertisers identify and serve ads to you that are
                    more relevant to you. We also use this data to operate, improve and enhance our
                    services including enhancing the data points we or our Advertising Partners have
                    about a particular user, browser, or device to serve the most relevant ads to
                    you and, in turn, improve performance of an Advertiser's ad campaigns.
                    Specifically, we use this data for:
                  </p>
                  <p>
                    Targeting: Selecting ads that are more likely to be relevant to you based on the
                    interests previously associated with your device and the time of day you may be
                    most interested in viewing these specific ads. For example, we may show you ads
                    for your favorite shopping site (or similar sites we think you may like) during
                    lunch or commute hours.
                  </p>

                  <p>
                    Frequency capping: Making sure that you don't see the same ad too many times.
                  </p>

                  <p>
                    Sequencing: If you are being served a sequence of ads, making sure we show you
                    the right ad next in the sequence.
                  </p>
                  <p>
                    Cross-device matching: Identifying different devices that are likely to be
                    associated with you so that ads can be targeted, capped and sequenced across
                    those devices, and so that campaign effectiveness can be measured and analyzed.
                    For example, cross-device matching helps us NOT show you ads for the shoes you
                    were looking at on your phone but already purchased on your tablet. Instead
                    we'll try to show you ads for an upcoming triathlon where you can put those
                    shoes to work. It also helps us match devices so we can honor your opt-out
                    choices across all devices we know are connected to the opted-out cookie. You
                    may opt-out of being targeted in the above "cross-device" and "cross-channel"
                    ways by employing the respective opt-out techniques we describe in the "Your
                    Choices and Opting-Out of Targeted Ads" section below.
                  </p>

                  <p>
                    Attribution: Monitoring when, where, and at what price we served certain ads on
                    behalf of an Advertiser so that we can measure our influence on the marketing
                    results of the Advertiser's campaigns and overall marketing strategy. For
                    example, being able to measure if a certain ad campaign (the ads shown and to
                    whom they were shown) actually sold more soccer balls for ACME Soccer Ball Co.
                  </p>

                  <p>
                    Reporting: Providing Advertisers insights into how their ads are performing and
                    gain insights into their customers. Reporting may include ad metrics such as
                    impressions, clicks, and conversions (however the Advertiser may define a
                    "conversion" such as, for example, a sale or a white paper download, or a
                    correlation to an actual or inferred sale, site visit or store visit). This data
                    allows an Advertiser to determine if an ad is not performing well (customers are
                    not clicking on it), so that the Advertiser will be able to see that data and
                    update the ad (perhaps with a better deal!). With respect to specific cookie
                    data, we limit reporting to cookie activity on the specific Advertiser's website
                    and which ads were shown, whether there was engagement with those ads.
                  </p>

                  <p>
                    Conducting Our Corporate Operations: As to information we collect in our
                    corporate capacity – our own B2B lists of customers and prospective customers –
                    we use that information to conduct our business operations and communications.
                    Please see our Website Data Privacy page to learn more about how we use that
                    information.
                  </p>

                  <p>
                    Social media identifiers: (such as single sign on through Facebook, Instagram or
                    other social media accounts) and other information you share with us or make
                    public via your social networking account. You may control what information you
                    share by accessing the networking sites' privacy settings. We may use this data
                    to personalize your experience and develop better services.
                  </p>

                  <p>
                    Your device and location: We collect information about your device, mobile phone
                    or tablet, including IP address, browser type, device type, unique device
                    identifiers and operating system. We also collect location data, if you have
                    location services enabled or by matching your IP address. We collect this
                    information for targeting purposes as well as to better our products.
                  </p>

                  <p>
                    Public activities that are user generated: This includes public comments on
                    forums and other websites, any public user profiles that you generate and any
                    listings online that you create. Note that this information is considered public
                    and is subject to the applicable terms of use.
                  </p>
                </div>
              }
              trigger={<span>How We Use the Data We Collect</span>}
            />

            <Accordion
              noBorder={false}
              content={
                <div className="privacy-section">
                  <p>We may disclose information about you:</p>
                  <p>
                    With an Advertiser whose Digital Properties you have visited: We may share
                    information about how you have interacted with that Advertiser's Digital
                    Properties, email campaigns or its Ads.
                  </p>
                  <p>
                    With our service providers: We contract with companies who help with parts of
                    our business operations (e.g., for example, website and data hosting, fraud
                    prevention, viewability reporting, data hygiene, marketing, and email delivery),
                    as well as billing, collections, tech, customer and operational support.
                  </p>

                  <p>
                    In connection with legal proceedings: When we are under a legal obligation to do
                    so, for example to comply with a binding order of a court, or where disclosure
                    is necessary to exercise, establish or defend the legal rights of Vatic AI, our
                    Advertisers or any other third party.
                  </p>

                  <p>
                    To comply with legal process: To satisfy in good faith any applicable law, legal
                    process, or proper governmental request, such as to respond to a subpoena
                    (whether civil or criminal) or similar process.
                  </p>

                  <p>
                    To investigate wrongdoing and protect ourselves or third parties: To enforce our
                    Terms of Service or other policies or investigate any potential violation of our
                    Terms of Service and policies, any potential violation of the law, or to protect
                    ourselves, our customers, or any third party from any potential harm (whether
                    tangible or intangible).
                  </p>

                  <p>
                    In connection with a sale of our business: If a third party acquires some or all
                    of our business or assets, we may disclose your information in connection with
                    the sale (including during due diligence in preparation for the sale).
                  </p>

                  <p>
                    With our Advertising Partners: We also share hashed email addresses (or other
                    pseudonymous identifiers associated with those hashes), technical data that we
                    collect about your browsing habits and your device (such as data relating to our
                    cookies, tracking pixels and similar technologies) with other companies in the
                    digital advertising ecosystem. This enables them and us to better personalize
                    ads to you.
                  </p>
                </div>
              }
              trigger={<span>Data Sharing</span>}
            />

            <Accordion
              noBorder={false}
              content={
                <div className="privacy-section">
                  <p>
                    Vatic AI uses cookies, tracking pixels and related technologies to provide our
                    services for Advertisers. Cookies are small data files that are served by our
                    platform and stored on your device.
                  </p>
                  <p>
                    Tracking cookies enable us to identify your device when you move between
                    different Digital Properties, so that we can serve targeted advertising to you.
                  </p>
                  <p>
                    Specifically, the Vatic AI cookie we serve through the Vatic AI platform for
                    this purpose is named "__Vatic AI" and "__Vatic_AI_fpc". Additionally, we use
                    non-tracking cookies (not unique) to store user decisions in terms of your ad
                    consent and opt-out choices:
                  </p>

                  <p>
                    We may drop a __Vatic AI cookie with value opt-out if you opt-out as described
                    below. We may drop a __consent cookie that stores the consent choices you have
                    made regarding data processing and advertising by Vatic AI. A full list of Vatic
                    AI cookies is set out below:
                  </p>

                  <p>Tracking cookies: __Vatic_AI __Vatic_AI_v4 __Vatic_AI_fpc</p>

                  <p>
                    Non-tracking cookies: _Vatic_AI_consent_paramas __Vatic_AI_post_consent_html
                    __Vatic_AI_post_connsent_css
                  </p>
                </div>
              }
              trigger={<span>Cookies and Related Technologies</span>}
            />

            <Accordion
              noBorder={false}
              content={
                <div className="privacy-section">
                  <p>
                    We recognize how important your online privacy is to you, so we offer the
                    following options for controlling the targeted ads (sometimes called
                    "interest-based ads") you receive and how we use your data.
                  </p>
                  <p>
                    Opting-out of this type of advertising through the below methods will not
                    prevent you from seeing ads, but those ads will likely be less relevant because
                    they will not be tailored to your interests. The ads might, for instance, be
                    randomly generated or based on the web page you are visiting.
                  </p>

                  <p>
                    Web browser: You can opt-out of receiving personalized ads (including retargeted
                    ads) served by us or on our behalf by contacting us at info@ReverseAdWords.com.
                    If you use a Safari browser, please also see directions regarding our
                    cookie-less technology opt-out, below.
                  </p>

                  <p>
                    Cross Device Opt-Out: In some cases we may link multiple browsers or devices to
                    you. If you opt-out on a browser or device and we have additional devices or
                    browsers linked to you, we will extend your opt-out decision to any other linked
                    browsers and devices. Since we only link users across browsers and devices in
                    some conditions, there could be cases where you are still being tracked in a
                    different browser or device we have not linked, and where we are treating you as
                    a different user.
                  </p>

                  <p>
                    {`Mobile Device Opt-Out: To opt-out of receiving targeted ads that are based on your behavior across different mobile applications follow the below instructions, for iOS and Android devices: iOS 7 or Higher: Go to your Settings > Select Privacy > Select Advertising > Enable the "Limit Ad Tracking" setting; and For Android devices with OS 2.2 or higher and Google Play Services version 4.0 or higher: Open your Google Settings app > Select Ads > Enable "Opt out of interest-based advertising".`}
                  </p>

                  <p>
                    Industry Opt-Out Tools and Self-Regulation: Vatic AI is a member of the Network
                    Advertising Initiative (NAI) and adheres to the NAI Code of Conduct. You may use
                    the NAI opt-out tool here, which will allow you to opt-out of seeing
                    personalized ads from us and from other NAI approved member companies. We also
                    comply with the Self-Regulatory Principles for Online Behavioral Advertising as
                    managed by the Digital Advertising Alliance (DAA). You may opt-out of receiving
                    personalized ads from other companies that perform ad targeting services,
                    including some that we may work with as Advertising Partners via the DAA website
                    here. We also comply with the Canadian Self-regulatory Principles for Online
                    Behavioral Advertising as managed by the Digital Advertising Alliance of Canada
                    (DAAC). You may opt-out of receiving personalized ads from other companies that
                    perform ad targeting services, including some that we may work with as
                    Advertising Partners via the DAAC website here. Finally, we also adhere to the
                    European Interactive Advertising Digital Alliance (EDAA) guidelines for online
                    advertising and you may opt-out via their "Your Online Choices" website here.
                  </p>

                  <p>
                    Note to Safari Users. When you opt-out on a browser, we, other advertising
                    platforms, and other third parties, generally place a cookie on your browser
                    instructing us not to use your other online behaviors to customize the ads you
                    see. However, if you are using a Safari browser, opt-out cookies that are set
                    for purposes of restricting this type of advertising may be deleted prior to
                    their intended expiration date; we have no control over these browser controls
                    or cookie deletions.
                  </p>

                  <p>
                    Do Not Track Disclosure: Some internet browsers allow users to send a "Do Not
                    Track" signal to websites they visit. We do not respond to this signal at the
                    present time.
                  </p>

                  <p>
                    Reminder to Users Residing in a European Territory: If you are located in a
                    European Territory you will also have additional data protection rights. These
                    are described under the "Information for European Territory Residents: Our Legal
                    Basis and Your Rights" section below.
                  </p>
                </div>
              }
              trigger={<span>Your Choice and Opting-Out of Targeted Ads</span>}
            />

            <Accordion
              noBorder={false}
              content={
                <div className="privacy-section">
                  <p>
                    CRM Data: We are processors of CRM data that we hold on behalf of an Advertiser,
                    such as email address lists. We retain this CRM Data until the Advertiser asks
                    us to delete this data.
                  </p>
                  <p>
                    Mobile Identifiers and Cookie Identifiers: Cookie identifiers we collect expire
                    (and are then deleted) 13 months from the last time your device accessed a
                    Digital Property using our technology. If you visit another Digital Property
                    that uses our technology inside that 13-month expiry period, then the expiry
                    period will be reset and measured from that date instead. The expiration period
                    for mobile identifiers is controlled by the end-user on their own device.
                  </p>

                  <p>
                    Personal Data Associated with Mobile and Cookie Identifiers Related to Browsing
                    History: We delete personal data associated with mobile and cookie identifiers
                    after 12 months. For example, data such as an Advertiser's website you visited
                    or ads that you may have clicked.
                  </p>
                  <p>
                    Personal Data Associated with Real Time Bids: Data logged for a particular
                    submitted bid or a received real time bid request (including cookie identifiers,
                    mobile identifiers, the advertisable bid on, and the advertisable won or
                    displayed to the end-user) are deleted after 30 days.
                  </p>

                  <p>
                    Personal Data Associated with the Display of an Advertisable: Data logged for
                    the display of an advertisable (including cookie identifiers, the advertisable
                    won or displayed to the end user as well as data indicated whether an end user
                    clicked on the particular advertisable displayed) are deleted after 12 months.
                  </p>
                </div>
              }
              trigger={<span>Data Retention</span>}
            />

            <Accordion
              noBorder={false}
              content={
                <div className="privacy-section">
                  <p>
                    We apply technical, administrative and organizational security measures to
                    protect the personal data we collect against accidental or unlawful destruction,
                    loss, alteration, unauthorized disclosure or access, in particular where the
                    processing involves the transmission of data over a network, and against other
                    unlawful forms of processing.
                  </p>
                  <p>
                    Access to our website is secure and we monitor our platforms for security
                    vulnerabilities regularly. Please note that nothing on the internet is totally
                    secure and we cannot guarantee that our security will not be breached. Remember
                    to always protect your personal data to the best of your abilities. Any
                    communication with Vatic AI via email will be secure to the level of that email
                    platform. Please do not send any sensitive information via unsecure channels to
                    us.
                  </p>

                  <p>
                    Statement on Children: Note that our targeting efforts and our digital
                    properties are not targeted at children and we do not intentionally collect data
                    on minors. If you are a minor and using our website or other digital properties
                    please do so with the permission of your parent or guardian.
                  </p>
                </div>
              }
              trigger={<span>Security</span>}
            />

            <Accordion
              noBorder={false}
              content={
                <div className="privacy-section">
                  <p>
                    We may transfer the information we collect about you to countries (including the
                    United States of America) other than the country where we originally collected
                    it for the purposes of storage and processing of data and operating our
                    services. In general, these countries will be the countries in which we, our
                    Advertisers, or our or their service providers operate.
                  </p>
                  <p>
                    Those countries may not have the same data protection laws as your country.
                    However, when we transfer your information to other countries, we will protect
                    that information as described in this Privacy Notice and take steps, where
                    necessary, to ensure that international transfers comply with applicable laws.
                  </p>
                  <p>
                    For example, when we transfer your information from a European Territory to our
                    parent company in the United States, we do so under the European Commission's
                    Standard Contractual Clauses. These Standard Contractual Clauses are
                    incorporated in the Vatic AI Data Protection Policy.
                  </p>
                </div>
              }
              trigger={<span>International Transfers</span>}
            />

            <Accordion
              noBorder={false}
              content={
                <div className="privacy-section">
                  <p>
                    Our Legal Basis: If you interact with our services from the European
                    Territories, our legal basis for collecting and using the personal data
                    described above will depend on the personal information concerned and the
                    specific context in which we collect it. "European Territories" means the
                    European Economic Area and Switzerland. For the purpose of this Privacy Notice,
                    the term "European Territories" shall continue to include the United Kingdom,
                    even after the United Kingdom leaves the European Economic Area following
                    Brexit.
                  </p>
                  <p>
                    We will normally collect personal data from you where the processing is in our
                    legitimate business interests to do so. For example, to administer our platforms
                    and services and fulfil our contractual obligations as a service provider. In
                    some cases we may collect and process personal data based on consent. If you
                    have questions about or need further information concerning the legal basis on
                    which we collect and use your personal information, including if you would like
                    to better understand how our legitimate interests to process your data are
                    balanced against your data protection rights and freedoms, then please contact
                    us using the contact details provided under the "Contact Us" heading below.
                  </p>

                  <p>
                    Your Enhanced Privacy Rights: In addition, if you are a resident of a European
                    Territory, you have the following enhanced rights under EU data protection law:
                  </p>

                  <p>
                    If you wish to access, correct, update or request deletion of your personal
                    information, you can contact us using the contact details provided in the
                    "Contact Us About Questions or Concerns" section below. You can object to the
                    processing of your personal information, and/or ask us to restrict processing of
                    your personal information. Again, you can exercise these rights by contacting us
                    using the contact details provided under the "Contact us about questions or
                    concerns" heading below. Similarly, if we have collected and process your
                    personal information with your consent, then you can withdraw your consent at
                    any time. Withdrawing your consent will not affect the lawfulness of any
                    processing we conducted prior to your withdrawal, nor will it affect the
                    processing of your personal information conducted in reliance on lawful
                    processing grounds other than consent. Specifically, you can withdraw consent
                    for us or our partners to drop cookies by emailing info@vaticai.com or by
                    refusing consent for Vatic AI when you see a "consent banner" on a publisher or
                    advertiser site which lists Vatic AI as a vendor. You have the right to complain
                    to a data protection authority about our collection and use of your personal
                    information. For more information, please contact your local data protection
                    authority. (Contact details for data protection authorities in the European
                    Territories are available here.) However, if you have any questions about our
                    collection and use of your personal information, we encourage you to contact us
                    first at info@ReverseAdWords.com. If you are unable to obtain the information or
                    resolution that you seek, you may also contact our Data Protection Officer at
                    chris@vaticai.com. Please note that we have no direct relationship with the
                    individuals whose personal data we process on behalf of our clients and
                    partners. Where we act as a processor for our clients and partners (for example,
                    when we handle our clients' CRM data solely to provide them services), you
                    should direct any requests to access, correct, update, or delete your personal
                    data to the respective client or partner. We will respond to any requests by a
                    client or partner to provide assistance with such requests within 30 days.
                  </p>
                </div>
              }
              trigger={
                <span>
                  Information for European Territory Residents : Our Legal Basis and Your Rights
                </span>
              }
            />

            <Accordion
              noBorder={false}
              content={
                <div className="privacy-section">
                  Changes to this Privacy Notice will be posted on this page. If we make a material
                  change to our privacy practices, we will provide notice on the site or by other
                  means as appropriate. If we are required by applicable data protection laws to
                  obtain your consent to any material changes before they come into effect, then we
                  will do so in accordance with law.
                </div>
              }
              trigger={<span>Change to This Privacy Notice</span>}
            />

            <Accordion
              noBorder={false}
              content={
                <div className="privacy-section">
                  <p>
                    If you have any questions about this Privacy Notice or our privacy practices,
                    you can contact info@vaticai.com.
                  </p>
                  <p>
                    If you are located in a European Territory and we are processing your data as a
                    data controller, Vatic AI is the data controller of your information. To contact
                    Vatic AI please email chris@vaticai.com.
                  </p>
                  <p>
                    If you wish to escalate your inquiry after contacting the support team, you are
                    welcome to contact chris@Vatic AI.com. Locations in Toronto, Canada, and Phuket,
                    Thailand.
                  </p>
                  <p>
                    If you have questions or concerns that have not been resolved to your
                    satisfaction by customer service, you may contact Vatic AI's Chief Privacy
                    Officer in writing at:
                  </p>
                  <p>
                    Evan Black Vatic AI Inc. 151 Lakeshore Rd. W. Oakville, Ontario L6K 1E4 Canada
                  </p>
                </div>
              }
              trigger={<span>Contact Us About Questions or Concerns</span>}
            />

            <Accordion
              noBorder={false}
              content={
                <div className="privacy-section">
                  <p>
                    Vatic AI operates the https://www.VaticAI.com/ website, which provides digital
                    advertising services and information about our brand.
                  </p>
                  <p>
                    This page is used to inform website visitors regarding our policies with the
                    collection, use, and disclosure of personal information if anyone decided to use
                    our services on the Vatic AI website.
                  </p>
                  <p>
                    If you choose to use our service, then you agree to the collection and use of
                    information in relation to this policy. The Personal Information that we collect
                    is used for providing and improving the services that we provide. We will not
                    use or share your information with anyone except as described in this Privacy
                    Policy.
                  </p>

                  <p>
                    Information Collection and Use For a better experience while using our services,
                    we may require you to provide us with certain personally identifiable
                    information, including but not limited to your name, phone number, email address
                    and postal address. The information that we collect will be used to contact or
                    identify you or your place of business.
                  </p>

                  <p>
                    Log Data We want to inform you that whenever you visit our website, we collect
                    information that your browser sends to us known as log data. This log data may
                    include information such as your computer's Internet Protocol ("IP") address,
                    browser version, pages of our website that you visit, the time and date of your
                    visit, the time spent on those pages, and other statistics.
                  </p>

                  <p>
                    Cookies Cookies are files with small amount of data that is commonly used as an
                    anonymous unique identifier. These are sent to your browser from the website
                    that you visit and are stored on your computer's hard drive.
                  </p>

                  <p>
                    Our website uses these "cookies" to collect information and to improve our
                    services. You have the option to either accept or refuse these cookies, and know
                    when a cookie is being sent to your computer. If you choose to refuse our
                    cookies, you may not be able to use some portions of our website.
                  </p>

                  <p>
                    Service Providers We may employ third-party companies and individuals for the
                    following reasons: To facilitate our services; To provide services on our
                    behalf; To perform website-related services; or To assist us in analyzing how
                    our website is used. We want to inform users of our website that these third
                    parties have access to your personal information. The reason is to perform the
                    tasks assigned to them on our behalf. However, they are obligated not to
                    disclose or use the information for any other purpose.
                  </p>

                  <p>
                    Security We value your trust in providing us your personal information, thus we
                    are striving to use commercially acceptable means of protecting it. Remember
                    that no method of transmission over the internet, or method of electronic
                    storage is 100% secure and reliable, and we cannot guarantee its absolute
                    security.
                  </p>

                  <p>
                    Links to Other Sites Our website may contain links to other sites. If you click
                    on a third-party link, you will be directed to that site. Note that these
                    external sites are not operated by us. Therefore, we strongly advise you to
                    review the Privacy Policy of these websites. We have no control over, and assume
                    no responsibility for the content, privacy policies, or practices of any
                    third-party sites or services.
                  </p>

                  <p>
                    Children's Privacy Our services are not targeted at anyone under the age of 13.
                    We do not knowingly collect personal identifiable information from children
                    under 13. In the case we discover that a child under 13 has provided us with
                    personal information, we immediately delete this from our servers. If you are a
                    parent or guardian and you are aware that your child has provided us with
                    personal information, please contact us so that we will be able to take the
                    necessary steps to remove this information from our data storage.
                  </p>

                  <p>
                    Changes to This Privacy Policy We may update our Privacy Policy from time to
                    time. Thus, we advise you to review this page periodically for any changes. We
                    will notify you of any changes by posting the new Privacy Policy on this page.
                    These changes are effective immediately, after they are posted on this page.
                  </p>

                  <p>
                    Contact Us If you have any questions about this Privacy Notice or our privacy
                    practices, you can contact info@vaticai.com. If you wish to escalate your
                    inquiry after contacting the support team, you are welcome to contact
                    chris@vaticai.com.
                  </p>
                </div>
              }
              trigger={<span>Privacy Policy of vaticai.com</span>}
            />
          </div>
          <div className="graphic-wrapper">
            <div className="graphic" />
          </div>
        </div>
      </div>
    </PrivacyStyled>
  );
};

export default Privacy;
