import styled from "styled-components/macro";

export const LoginPageStyled = styled.div`
  padding: 50px;
  width: 100vw;
  margin: -50px 0px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;

  .login-btn {
    margin-top: 40px;

    .signup {
      margin-top: 15px;
    }
  }

  .login-btn {
    text-align: center;
  }

  .forget-password-button {
    display: flex;
    justify-content: center;
    margin-bottom: 5px;
    width: 100%;

    .back-button {
      margin-right: 5px;
    }
  }

  .login-form {
    width: 450px;
    margin: 0 auto;
    text-align: center;

    .logo {
      width: 230px;
      margin-bottom: 50px;
      transition: 0.1s;
      fill: ${({ theme }) => theme.authLogo};
    }

    .input-wrapper {
      margin-bottom: 12px;
    }
  }
`;
