import { Dropdown } from "react-bootstrap";
import styled, { css } from "styled-components/macro";

export const SidebarHeader = styled.div`
  margin: 1rem 0px 1.25rem 5px;
  height: 2.74rem;

  position: relative;
  cursor: pointer;
  display: flex;
  padding-left: 20px;

  .rad-logo {
    width: 150px;
    height: auto;

    path {
      transition: 0.1s;
      fill: ${({ theme }) => theme.contrast};
    }
  }

  .icon-wrapper {
    position: absolute;
    right: 25px;
    top: -6px;
    transition: 0.1s;

    > svg {
      width: 16px;
      height: 16px;

      polyline,
      circle {
        transition: 0.1s;
        stroke: ${({ theme }) => theme.contrast};
      }
    }
  }

  .flip {
    right: 20px;
    transform: rotate(180deg);
  }

  &:after {
    color: ${({ theme }) => theme.background};
    font-weight: bold;
    content: "BETA";
    position: absolute;
    bottom: -17px;
    right: 72px;
    background-color: ${({ theme }) => theme.contrast};
    font-size: 0.7rem;
    padding: 3px 10px;
    border-radius: 25px;
    text-align: center;
    line-height: 13px;
    transition: 0.1s;
  }
`;

export const CurrentUser = styled.div<{ isClose: boolean }>`
  transition: 0.1s;
  border-top: 1px solid ${({ theme }) => theme.dark};
  padding: ${({ isClose }) => (isClose ? "0" : " 8px 15px")};

  .thumbnail-actions {
    display: flex;
    justify-content: ${({ isClose }) => (isClose ? "end" : "start")};
    transition: 0.1s;

    .dropdown-toggle {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;

      > svg,
      polyline {
        width: 10px;
        height: 10px;
        stroke: gray;
      }
    }

    .thumbnail {
      background-color: ${({ theme }) => theme.secondary};
      color: ${({ theme }) => theme.alertModalBackground};
      text-align: center;
      height: 30px;
      width: 30px;
      font-size: 12px;
      font-weight: bold;
      padding: 7px 9px;
      border-radius: 50%;
    }
  }
`;

export const SidebarItemWrapper = styled.div``;

export const CustomMenu = styled(Dropdown.Menu)`
  background-color: ${({ theme }) => theme.background};
  color: ${({ theme }) => theme.text};
  padding: 20px;
  border-radius: 10px;
  width: 250px;
  border: 1px solid ${({ theme }) => theme.dark};

  .user-dropdown {
    display: flex;
    border-bottom: 1px solid ${({ theme }) => theme.inputDark};
    padding-bottom: 20px;
    margin-bottom: 20px;
    align-items: center;
    gap: 10px;

    .user-name {
      color: ${({ theme }) => theme.secondary};
      font-size: 12px;
    }

    .edit-user {
      cursor: pointer;
      font-size: 10px;
      padding: 2px 12px;
      border-radius: 5px;
      border: 1px solid ${({ theme }) => theme.text};
      height: 20px;

      &:hover {
        color: ${({ theme }) => theme.contrast};
        border-color: ${({ theme }) => theme.contrast};
      }
    }
  }

  .disabled {
    color: ${({ theme }) => theme.disabled} !important;
  }

  .dropdown-item {
    padding: 8px 0;
    font-size: 12px;
    color: ${({ theme }) => theme.text};

    &:hover {
      background-color: unset !important;
    }

    &:hover {
      color: ${({ theme }) => theme.contrast};
      font-weight: bold;
      /* background-color: darkgray; */
    }
  }
`;

export const SidebarWrapper = styled.aside<{ isClose?: boolean }>`
  /* content: ""; */
  position: fixed;
  top: 0;
  width: 280px;
  height: 100%;
  z-index: 10;
  /* -webkit-transition: all 350ms cubic-bezier(0.6, 0.05, 0.28, 0.91);
  transition: all 350ms cubic-bezier(0.6, 0.05, 0.28, 0.91); */
  // from RAD

  width: 15rem;
  left: 0;
  z-index: 1040;

  .sidebar-item-wrapper {
    .sidebar-item {
      font-style: italic;
      background: unset;
      opacity: 0.5;
      color: ${({ theme }) => theme.contrast} !important;
      border: unset;
      margin-bottom: 0.1 rem;
      font-size: 12px;
      padding: 0.75rem ${({ isClose }) => (isClose ? "1.25rem" : "1.65rem")};
      transition: 0.1s;
    }

    .route-item {
      cursor: pointer;
      border: unset;
      margin-bottom: 0.1 rem;
      color: ${({ theme }) => theme.contrast};
      font-size: 12px;
      padding: 0.65rem ${({ isClose }) => (isClose ? "1.25rem" : "1.65rem")};
      display: flex;
      justify-content: space-between;
      text-decoration: none;
      transition: 0.1s;

      &:hover {
        background: ${({ theme }) => theme.background};
        color: ${({ theme }) => theme.contrast};
        text-decoration: none;
        font-weight: bold;
      }

      .img-icon {
        text-align: center;
        width: 0.85rem;
      }
    }

    .active {
      background: ${({ theme }) => theme.background};
      color: ${({ theme }) => theme.contrast};
      text-decoration: none;
      font-weight: bold;
      transition: 0.1s;
    }
  }

  .overlay {
    background: rgba(0, 0, 0, 0.25);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    bottom: 0;
    display: none;
  }

  .sidebar {
    width: inherit;
    padding-bottom: 15px;
    display: flex;
    flex-direction: column;
    z-index: 2;
    position: fixed;
    height: auto;
    min-height: 100%;
    padding-bottom: 10rem;
    box-shadow: unset;
    background: unset;
    background-color: ${({ theme }) => theme.nav};
    transition: 0.3s;

    .dropdownIcon {
      svg {
        width: 14px;
        height: 14px;
        fill: #a7acbd;
      }
    }

    .active {
      .dropdownIcon {
        svg {
          fill: #ffffff;
        }
      }
    }
  }

  .nav-icon {
    > svg {
      width: 15px;
      height: 15px;

      polyline,
      path,
      polygon,
      line,
      rect {
        transition: 0.1s;
        stroke: ${({ theme }) => theme.contrast};
      }
    }
  }

  .grouprow {
    background: unset;
    opacity: 0.5;
    color: rgb(241, 242, 242) !important;

    &:hover {
      color: #f1f2f2 !important;
      opacity: 0.5;
      font-weight: normal !important;
      background-color: unset !important;
    }
  }

  .list-group-item {
    background-color: rgba(0, 0, 0, 0.1);
    border: unset;
    margin-bottom: 0.1rem;
    color: #ffffff;
    font-size: 12px;

    &:hover {
      background: #333333;
      color: white;
      text-decoration: none;
      font-weight: bold;
    }

    .img-icon {
      text-align: center;
      width: 0.85rem;
    }
  }

  .active {
    background: #333333;
    color: white;
    text-decoration: none;
    font-weight: bold;
  }

  .bottom-group {
    color: #ffffff;
    position: absolute;
    bottom: 0px;
    text-align: center;
    width: 100%;

    a.text-link {
      color: #ffffff;
      padding: 0px 1rem;
      font-size: 12px;
      text-decoration: none;
    }
  }

  .sidebar::-webkit-scrollbar {
    width: 5px;
  }

  .sidebar::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.specific.sidebarNavText};
    border-radius: 3px;
  }

  margin-left: 0;
  position: relative;

  -webkit-transition: all 350ms cubic-bezier(0.6, 0.05, 0.28, 0.91);
  transition: all 350ms cubic-bezier(0.6, 0.05, 0.28, 0.91);

  ${({ isClose }) =>
    isClose &&
    css`
        margin-left: -11.5rem;
        .list-group-item {
            .grouprow {
                /* border-bottom: 1px solid #FFFFFF; */
                /* height: 1px; */
                padding: unset;
                margin: 1rem 0rem;
            }
        }

        .bottom-group {
            .separator { display: none; }
            .lastgroup { 
                float: right; 
                text-align: center; 
                .text-link {
                    display: block;
                    font-size: 80%;
                    padding: 0rem 0.5rem 1.5rem 0.5rem;
                    white-space: nowrap;
                }
            }
        }
  }
    
    `}
`;
