import { BannerImage, IAdsCreative, ILocation } from "store/states/campaigns.state";
import { IValidFile } from "components/Dropzone";
import { CATEGORY_KEY_VALUES_OBJECT, ICategoryItem } from "constants/category";
import { IBUDGET_FORM, ICARD_FORM } from "store/actions/payment.actions";
import { BUDGET_LIST } from "constants/payment";
import { PAYMENT_METHOD_LIST } from "components/PaymentMethod";

const Step1 = {
  title: "1.Details",
  nav: "Advertise \\ New Campaign \\ Step 1",
  InstructionTitle: "CREATE A NEW CAMPAIGN",
  InstructionDescription:
    "Take the first step in building scalable and sustainable advertising campaigns without expensive and inconvenient processes.",
};

const Step2 = {
  title: "2.Targeting",
  nav: "Advertise \\ New Campaign \\ Step 2",
  InstructionTitle: "CHOOSE THE RIGHT TARGET AUDIENCE",
  InstructionDescription:
    "Create a niche audience persona by names and job titles, demographic, interests, goals and challenges, keywords and behaviors.",
};

const Step3 = {
  title: "3.Keywords",
  nav: "Advertise \\ New Campaign \\ Step 3",
  InstructionTitle: "ASSIGN KEYWORDS TO YOUR CAMPAIGN",
  InstructionDescription: `Build an accurate roadmap of your buyers' entire purchase path on a keyword-per-keyword basis with our patented "Keyword Roadmapping Algorithm", perfect for considered purchases.`,
};

const Step4 = {
  title: "4.Creative",
  nav: "Advertise \\ New Campaign \\ Step 4",
  InstructionTitle: "RUN SUCCESSFUL ADS FOR YOUR CAMPAIGN",
  InstructionDescription:
    "Upload, modify or build creative banners that are consistent with your branding. Banners should communicate a strong product/service offer with structured design, simple but powerful content and a visible conversion-oriented call-to-action.",
};

const Step5 = {
  title: "5.Payment",
  nav: "Advertise \\ New Campaign \\ Step 5",
  InstructionTitle: "YOU'RE ALL SET TO LAUNCH YOUR CAMPAIGN",
  InstructionDescription:
    "Choose your preferred payment method to activate your campaign and immediately begin measuring results with our flexible reporting options to show all the ways your digital efforts impacted sales and visits across all channels.",
};

type StepInformation = {
  title: string;
  InstructionTitle: string;
  InstructionDescription: string;
  nav: string;
};

export const stepInfo: Array<StepInformation> = [Step1, Step2, Step3, Step4, Step5];

export interface ICreativeRequest {
  creativeId?: string;
  newCreativeAssets: Array<File & { id: string }>;
  landingPageUrl: string;
  hook: string;
  offer: string;
  action: string;
  briefing: string;
  notes: string;
  assets: Array<string>;
  deleteCreativeAssets: Array<string>;
}

export interface IPostBannerData {
  name: string;
  width: number;
  height: number;
  imageFile: File;
}

export interface IGetBannerData {
  name: string;
  url: string;
  width: number;
  height: number;
  original: {
    size: number;
    mine: string;
    width: number;
    height: number;
  };
}

export interface IPostCreativeGroup {
  landingPageUrl: string;
  thankyouPageUrl?: string;
  images: Array<IPostBannerData>;
  title: string;
}

export interface IGetCreativeGroup {
  landingPageUrl: string;
  thankyouPageUrl?: string;
  images: Array<IGetBannerData>;
  title: string;
}
export interface IPostCampaign {
  id?: string;
  bannerId?: number;
  name: string;
  description: string;
  goal: string;
  contentCategories: Array<{ id: number; behavior: "include" | "exclude" }>;

  ageGroups: Array<string>;
  genders: Array<string>;
  locations: Array<ILocation>;
  devices: Array<number>;
  os: Array<number>;
  browsers: Array<number>;

  title: string;
  landingPageUrl: string;
  thankyouPageUrl: string;
  images: Array<IValidFile>;
  imagesUrl: Array<string>;

  keywords: Array<string>;
  // payment

  card: {
    fullName: string;
    cardNumber: string;
    expirationDate: string;
    securityCode: string;
  };
  paymentMethod: string;
  paymentForm: IBUDGET_FORM;
}

export enum MULTI_STEP_FORM {
  CAMPAIGN_DETAIL = 0,
  CAMPAIGN_TARGET = 1,

  KEYWORDS_SELECTION = 2,
  CAMPAIGN_CREATIVE = 3,
  PAYMENT_METHOD = 4,
}

export const savedLists = [
  { title: "Phuket Travel" },
  { title: "Krabi Travel" },
  { title: "Thailand Online Marketing" },
];

export enum KEYWORD_SEARCH_TYPE {
  SUGGESTED_KEYWORDS = "suggestedKeywords",
  TARGET_BY_COMPETITOR = "targetByCompetitor",
  SAVED_LIST = "yourSavedList",
}

export const dropdownItemValues = {
  [KEYWORD_SEARCH_TYPE.SUGGESTED_KEYWORDS]: {
    text: "Suggested Keywords",
    value: KEYWORD_SEARCH_TYPE.SUGGESTED_KEYWORDS,
  },
  [KEYWORD_SEARCH_TYPE.TARGET_BY_COMPETITOR]: {
    text: "Target by Competitor",
    value: KEYWORD_SEARCH_TYPE.TARGET_BY_COMPETITOR,
  },
  [KEYWORD_SEARCH_TYPE.SAVED_LIST]: {
    text: "Your Saved Lists",
    value: KEYWORD_SEARCH_TYPE.SAVED_LIST,
  },
} as Record<
  | KEYWORD_SEARCH_TYPE.SUGGESTED_KEYWORDS
  | KEYWORD_SEARCH_TYPE.TARGET_BY_COMPETITOR
  | KEYWORD_SEARCH_TYPE.SAVED_LIST,
  {
    text: string;
    value:
      | KEYWORD_SEARCH_TYPE.SUGGESTED_KEYWORDS
      | KEYWORD_SEARCH_TYPE.TARGET_BY_COMPETITOR
      | KEYWORD_SEARCH_TYPE.SAVED_LIST;
  }
>;
