import { AnyAction } from "redux";
import { AdFormCategoryID } from "services/types";
import * as CATEGORY_ACTIONS from "../actions/categories.actions";
import categories from "../states/categories.state";

const categoriesReducer = (state = categories, action: AnyAction) => {
  switch (action.type) {
    case CATEGORY_ACTIONS.GET_CATEGORIES_REQUEST:
      state = { ...state, loading: true };
      break;
    case CATEGORY_ACTIONS.GET_CATEGORIES_SUCCESS:
      state = {
        ...state,
        data: action.payload.reduce(
          (acc: { [key: string]: Array<AdFormCategoryID> }, cur: AdFormCategoryID) => {
            if (cur.source_parent_key !== "0") {
              acc[cur.source_parent_key] = acc[cur.source_parent_key]
                ? [...acc[cur.source_parent_key], cur]
                : [cur];
              return acc;
            }
            return acc;
          },
          {}
        ),
        parent: action.payload.reduce((acc: { [key: string]: string }, cur: AdFormCategoryID) => {
          if (!cur.iab_code.includes("-")) {
            acc[cur.source_key] = cur.name;
          }
          return acc;
        }, {}),
        loading: false,
      };
      break;
    case CATEGORY_ACTIONS.GET_CATEGORIES_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;
    default:
  }

  return state;
};

export default categoriesReducer;
