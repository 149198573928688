import styled from "styled-components/macro";

export const SearchResult = styled.ul`
  list-style: none;
  padding: 0 20px;
  background: ${({ theme }) => theme.alertModalBackground};
  margin-top: 3px;
  border-radius: 10px;
  font-size: 12px;
  max-height: 200px;
  overflow-y: auto;

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 20px 20px 20px 20px rgb(91 91 91);
  }

  > li {
    padding: 10px 0;
    border-bottom: 1px solid ${({ theme }) => theme.dark};
    color: ${({ theme }) => theme.tableContent};
    transition: 0.3s;
    cursor: pointer;

    &:hover {
      color: ${({ theme }) => theme.contrast};
    }

    &:last-child {
      border: none;
    }
  }
`;

export const LocationTags = styled.div`
  display: flex;
  flex-wrap: wrap;

  .added-location {
    background-color: rgb(252, 190, 0);
    color: black;
    padding: 6px 20px;
    padding-right: 15px;
    font-size: ${({ theme }) => theme.size.md};
    border-radius: 5px;
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 0;
    width: fit-content;
    margin-right: 10px;

    .tag-remove {
      margin-left: 10px;
      cursor: pointer;
    }
  }
`;

export const LocationSelectorStyled = styled.div`
  min-height: 400px;
  padding: 20px;
  background-color: ${({ theme }) => theme.background};
  border-radius: 10px;

  display: flex;
  flex-direction: column;

  .bm_bottomLeftOverlay,
  .bm_bottomRightOverlay {
    display: none;
  }

  .bing-map-element {
    width: 600px;
    height: 400px;
  }

  .map-right-side {
    padding-left: 20px;
    width: 400px;

    .selected-locations {
      margin-top: -40px;
    }
  }

  .location-section {
    display: flex;
    justify-content: space-between;
    gap: 10px;

    .map-helpers {
      display: flex;
      gap: 10px;
      width: 100%;
      flex-direction: column;

      .location-field {
        .react-dropdown-select {
          padding: 0 14px;
        }
      }

      .location-field-wrapper {
        width: 100%;

        .search-box-container {
          position: relative;
          display: flex;
          flex-direction: column;
          width: 100%;
          z-index: -1;
          //
          /* visibility: none; */

          .MicrosoftMap {
            .as_container {
              width: 100%;
              background: ${({ theme }) => theme.dark};
              .asOuterContainer {
                border: ${({ theme }) => theme.dark};
              }
              .suggestLink {
                background: ${({ theme }) => theme.dark};
                color: white;
              }
            }
          }

          .auto-suggest-map {
            width: auto;
            box-sizing: content-box;
            background-color: ${({ theme }) => theme.dark} !important;
            border: 0;
            border-radius: 0;
            color: #ffffff;
            height: 45px;
            padding: 0 14px;
            font-size: 14px;
            border-radius: 8px;

            &:focus-visible {
              outline: none;
            }
          }

          .MicrosoftMap .as_container a {
            color: ${({ theme }) => theme.dark} !important;
          }
        }
      }
    }

    .add-btn {
      width: 150px;
    }
  }
`;
