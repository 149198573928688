import React from "react";

const TrashIcon: React.FunctionComponent<React.ComponentPropsWithoutRef<"svg">> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.26 17.59" {...props}>
      <defs>
        <style>
          {
            ".icon-svg{fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:0.8px;}"
          }
        </style>
      </defs>
      <g id="Layer_2" data-name="Layer 2">
        <g id="icons">
          <path
            className="icon-svg"
            d="M1.42,3l.05,13.08s.05.89,1.4,1.07h9.49s1.35-.09,1.44-1L13.85,3Z"
          />
          <line className="icon-svg" x1={0.4} y1={3.04} x2={14.86} y2={3.04} />
          <path
            className="icon-svg"
            d="M5,3,5,1.61S4.94.44,6.56.4l2.53,0a1.24,1.24,0,0,1,1.16,1.1V3Z"
          />
        </g>
      </g>
    </svg>
  );
};

export default TrashIcon;
