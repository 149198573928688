import { IErrorMessage } from "../../services/types";

// save

export const SAVE_COMPETITOR = "competitor-actions/SAVE_COMPETITOR";

export interface SaveCompetitorAction {
  type: typeof SAVE_COMPETITOR;
  payload: string;
}

export const SAVE_COMPETITOR_REQUEST = "competitor-actions/SAVE_COMPETITOR_REQUEST";
export interface SaveCompetitorRequestAction {
  type: typeof SAVE_COMPETITOR_REQUEST;
}

export const SAVE_COMPETITOR_SUCCESS = "competitor-actions/SAVE_COMPETITOR_SUCCESS";
export interface SaveCompetitorSuccessAction {
  type: typeof SAVE_COMPETITOR_SUCCESS;
  payload: { id: number; value: string };
}

export const SAVE_COMPETITOR_FAILURE = "competitor-actions/SAVE_COMPETITOR_FAILURE";
export interface SaveCompetitorFailureAction {
  type: typeof SAVE_COMPETITOR_FAILURE;
  error: IErrorMessage;
}

// get

export const GET_COMPETITOR = "competitor-actions/GET_COMPETITOR";

export interface GetCompetitorAction {
  type: typeof GET_COMPETITOR;
}

export const GET_COMPETITOR_REQUEST = "competitor-actions/GET_COMPETITOR_REQUEST";
export interface GetCompetitorRequestAction {
  type: typeof GET_COMPETITOR_REQUEST;
}

export const GET_COMPETITOR_SUCCESS = "competitor-actions/GET_COMPETITOR_SUCCESS";
export interface GetCompetitorSuccessAction {
  type: typeof GET_COMPETITOR_SUCCESS;
  payload: any;
}

export const GET_COMPETITOR_FAILURE = "competitor-actions/GET_COMPETITOR_FAILURE";
export interface GetCompetitorFailureAction {
  type: typeof GET_COMPETITOR_FAILURE;
  error: IErrorMessage;
}

// delete

export const DELETE_COMPETITOR = "competitor-actions/DELETE_COMPETITOR";

export interface DeleteCompetitorAction {
  type: typeof DELETE_COMPETITOR;
  payload: Array<number>;
}

export const DELETE_COMPETITOR_REQUEST = "competitor-actions/DELETE_COMPETITOR_REQUEST";
export interface DeleteCompetitorRequestAction {
  type: typeof DELETE_COMPETITOR_REQUEST;
}

export const DELETE_COMPETITOR_SUCCESS = "competitor-actions/DELETE_COMPETITOR_SUCCESS";
export interface DeleteCompetitorSuccessAction {
  type: typeof DELETE_COMPETITOR_SUCCESS;
  payload: any;
}

export const DELETE_COMPETITOR_FAILURE = "competitor-actions/DELETE_COMPETITOR_FAILURE";
export interface DeleteCompetitorFailureAction {
  type: typeof DELETE_COMPETITOR_FAILURE;
  error: IErrorMessage;
}
