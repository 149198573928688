import { axiosForReverseAds } from "axiosConfig";
import KeywordsTable from "components/KeywordsTable";
import SavedListDropdown from "components/SavedListDropdown";
import Spinner from "components/Spinner";
import { handlingError, handlingKeywordServiceError } from "constants/utility/error";
import {
  convertKeywordV3ArrToObj,
  dropdownItemValues,
  IDataForSEOV3,
  KEYWORD_SEARCH_TYPE,
} from "constants/utility/keyword";
import { Formik } from "formik";
import ICompetitorInfoResponse from "models/competitor-info-response.interface";
import IKeywordSearchVolumeResponse from "models/keyword-search-volume-response.interface";
import React from "react";
import { connect, ConnectedProps, useDispatch } from "react-redux";
import { fetchSavedKeywordList } from "store/actions/keyword-list.actions";
import { RootState } from "store/store";
import { Step3Styled } from "./styled";
import api from "services/api";
import { toast } from "react-toastify";

interface IStep3Props {
  error: string;
  onSelectAllButtonClick: (newItems: Array<string>) => void;
  onDeselectAllItems: (deselectItems: Array<string>) => void;
  onKeywordSelect: (addedKeyword: IDataForSEOV3) => void;
  selectedKeys: Array<string>;
  fetchSuggestedKeywords: (keyword: string, locationName?: string) => Promise<void>;
  setTableData: (tableData: { [key: string]: IDataForSEOV3 }) => void;
  loading: boolean;
  tableData: { [key: string]: IDataForSEOV3 };
  suggestedKeywords: { [key: string]: IDataForSEOV3 };
}

const Step3: React.FunctionComponent<IStep3Props> = ({
  selectedKeys,
  onSelectAllButtonClick,
  onDeselectAllItems,
  onKeywordSelect,
  fetchSuggestedKeywords,
  setTableData,
  tableData,
  suggestedKeywords,
  loading,
  error,
}) => {
  const dispatch = useDispatch();
  // React.useEffect(() => {
  //   window.scrollTo(0, 0);
  //   dispatch(fetchSavedKeywordList(0));
  // }, []);
  const [fetching, setFetching] = React.useState<boolean>(false);

  const onSubmit = async (values: { mode: KEYWORD_SEARCH_TYPE; keyword: string }) => {
    setFetching(true);

    if (values.mode === dropdownItemValues.targetByCompetitor.value) {
      if (values.keyword) {
        try {
          const { data } = await axiosForReverseAds.post<ICompetitorInfoResponse>(
            "keywordServices/competitorInfo",
            {
              domain: values.keyword,
            }
          );

          let topCompetitors = {} as { [key: string]: IDataForSEOV3 };
          let topOrgnics = {} as { [key: string]: IDataForSEOV3 };

          if (
            !data.data[0].traffic.sources.search_ad.top_keywords.length &&
            !data.data[0].traffic.sources.search_organic.top_keywords.length
          ) {
            toast.error(`The competitor site doesn't have any keywords`);
          }

          if (data.data[0].traffic.sources.search_ad.top_keywords.length) {
            const { data: topKeywordsResponse } = await api.dataForSEO.keywordSeachVolume(
              data.data[0].traffic.sources.search_ad.top_keywords
                .map(({ keyword }: { keyword: string }) => keyword)
                .slice(0, 50)
            );
            topCompetitors = convertKeywordV3ArrToObj(topKeywordsResponse.data);
          }

          if (data.data[0].traffic.sources.search_organic.top_keywords.length) {
            const { data: organicKeywordsResponse } = await api.dataForSEO.keywordSeachVolume(
              data.data[0].traffic.sources.search_organic.top_keywords
                .map(({ keyword }: { keyword: string }) => keyword)
                .slice(0, 50)
            );
            topOrgnics = convertKeywordV3ArrToObj(organicKeywordsResponse.data);
          }

          setTableData({ ...topCompetitors, ...topOrgnics });
        } catch (error) {
          handlingError(error);
        } finally {
          setFetching(false);
        }
      }
    } else if (values.keyword) {
      fetchSuggestedKeywords(values.keyword);
    }

    setFetching(false);
  };

  const onSelectSearchList = async (savedList: { name: string; id: string }) => {
    try {
      setFetching(true);
      const { data } = await axiosForReverseAds.get(`/keywordList/${savedList.id}`);
      const keywords = data.data.keywords.map((keyword: { keyword: string }) => keyword.keyword);
      if (keywords.length) {
        const { data } = await api.dataForSEO.keywordSeachVolume(keywords.slice(0, 50));
        const tableDataFromSavedList = convertKeywordV3ArrToObj(data.data);
        const currentTableData = tableDataFromSavedList || suggestedKeywords;
        setTableData(currentTableData);
      } else {
        setTableData({});
      }
    } catch (error: any) {
      handlingKeywordServiceError(error);
    }

    setFetching(false);
  };

  return (
    <Step3Styled>
      <div className="field name-campaign">
        <div className="build-campaign-title">
          <span data-testid="th" className="input-title">
            Keywords
          </span>
        </div>
      </div>
      {loading || fetching ? (
        <Spinner />
      ) : (
        <>
          <div className="field keyword-tool">
            <Formik
              // validationSchema={step3Schema}
              onSubmit={onSubmit}
              initialValues={{
                mode: dropdownItemValues.suggestedKeywords.value,
                keyword: "",
              }}
            >
              {({ setFieldValue, handleBlur, errors, handleChange, values, submitForm }) => {
                return (
                  <>
                    <SavedListDropdown
                      error={error}
                      submitForm={submitForm}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      setFieldValue={setFieldValue}
                      values={values}
                      onSelectSearchList={onSelectSearchList}
                      text={
                        dropdownItemValues[values.mode]
                          ? dropdownItemValues[values.mode].text
                          : values.mode
                      }
                      loading={loading || fetching}
                    />
                    {(errors.mode || errors.keyword) && (
                      <span className="errorMessage">{errors.keyword || errors.mode}</span>
                    )}
                  </>
                );
              }}
            </Formik>
          </div>

          <div className="field table">
            {Object.keys(tableData).length ? (
              <KeywordsTable
                isShowSelectAll
                fullHeight
                isCustomActions
                onSortTableData={(sortedTableData) => setTableData(sortedTableData)}
                keywords={tableData}
                isLoading={loading || fetching}
                selectedKeys={selectedKeys}
                selectAll={(selectedTableData) => {
                  onSelectAllButtonClick(selectedTableData);
                }}
                unSelectAll={(unselectedTableData) => onDeselectAllItems(unselectedTableData)}
                onKeywordSelect={(keyword) => onKeywordSelect(tableData[keyword])}
              />
            ) : null}
          </div>
        </>
      )}
    </Step3Styled>
  );
};

export default Step3;
