import { ICompareList } from "store/states/compare.state";
import { IErrorMessage } from "../../services/types";
import * as actions from "../action-types/compare.types";

// save

export function saveCompare(payload: Array<string>): actions.SaveCompareAction {
  return {
    type: actions.SAVE_COMPARE,
    payload,
  };
}

export function saveCompareRequest(): actions.SaveCompareRequestAction {
  return {
    type: actions.SAVE_COMPARE_REQUEST,
  };
}

export function saveCompareSuccess(payload: ICompareList): actions.SaveCompareSuccessAction {
  return {
    type: actions.SAVE_COMPARE_SUCCESS,
    payload,
  };
}

export function saveCompareFailure(error: IErrorMessage): actions.SaveCompareFailureAction {
  return {
    type: actions.SAVE_COMPARE_FAILURE,
    error,
  };
}

// get

export function getCompare(): actions.GetCompareAction {
  return {
    type: actions.GET_COMPARE,
  };
}

export function getCompareRequest(): actions.GetCompareRequestAction {
  return {
    type: actions.GET_COMPARE_REQUEST,
  };
}

export function getCompareSuccess(payload: Array<ICompareList>): actions.GetCompareSuccessAction {
  return {
    type: actions.GET_COMPARE_SUCCESS,
    payload,
  };
}

export function getCompareFailure(error: IErrorMessage): actions.GetCompareFailureAction {
  return {
    type: actions.GET_COMPARE_FAILURE,
    error,
  };
}

// get

export function deleteCompare(payload: Array<string>): actions.DeleteCompareAction {
  return {
    type: actions.DELETE_COMPARE,
    payload,
  };
}

export function deleteCompareRequest(): actions.DeleteCompareRequestAction {
  return {
    type: actions.DELETE_COMPARE_REQUEST,
  };
}

export function deleteCompareSuccess(payload: Array<string>): actions.DeleteCompareSuccessAction {
  return {
    type: actions.DELETE_COMPARE_SUCCESS,
    payload,
  };
}

export function deleteCompareFailure(error: IErrorMessage): actions.DeleteCompareFailureAction {
  return {
    type: actions.DELETE_COMPARE_FAILURE,
    error,
  };
}
