import * as CAMPAIGN_ACTIONS from "../actions/campaigns.actions";
import campaignsState from "../states/campaigns.state";
import { AnyAction } from "redux";
import * as types from "../action-types/campaigns.types";
import * as campaignTypes from "../action-types/campaign.types";

const campaignReducer = (state = campaignsState, action: AnyAction) => {
  switch (action.type) {
    case CAMPAIGN_ACTIONS.ADD_NEW_CAMPAIGN:
      state = {
        ...state,
        data: [action.payload.campaign, ...state.data],
      };
      break;

    case CAMPAIGN_ACTIONS.DELETE_CAMPAIGNS:
      state = {
        ...state,
        loading: true,
      };

      break;

    case CAMPAIGN_ACTIONS.DELETE_CAMPAIGNS_SUCCESS:
      state = {
        ...state,
        loading: false,
        data: state.data.filter((campaign) => !action.payload.campaignIds.includes(campaign.id)),
      };

      break;

    case CAMPAIGN_ACTIONS.DELETE_CAMPAIGNS_FAILURE:
      state = { ...state, loading: false };
      break;

    case CAMPAIGN_ACTIONS.TOGGLE_CAMPAIGN_ACTIVE_STATUS_SUCCESS:
      state = {
        ...state,
        data: state.data.map((campaign) => {
          return campaign.id !== action.payload.campaignId
            ? campaign
            : {
                ...campaign,
                isActive: !campaign.isActive,
              };
        }),
      };

      break;

    case CAMPAIGN_ACTIONS.SET_CAMPAIGN_REPORTS:
      state = {
        ...state,
        data: state.data.map((campaign, index) => ({
          ...campaign,
          report: {
            data: action.payload.reports[index] || null,
            loading: false,
          },
        })),
      };

      break;

    case CAMPAIGN_ACTIONS.UPDATE_CAMPAIGN_ADFORM_ID_SUCCESS:
      state = {
        ...state,
        data: state.data.map((campaign) =>
          campaign.id !== action.payload.campaignId
            ? campaign
            : {
                ...campaign,
              }
        ),
      };

      break;

    case CAMPAIGN_ACTIONS.LOAD_CAMPAIGN_REPORTS:
      state = {
        ...state,
        data: state.data.map((campaign) => ({
          ...campaign,
          report: {
            loading: true,
            data: null,
          },
        })),
      };
      break;

    case CAMPAIGN_ACTIONS.CHANGE_CAMPAIGN_STATUS:
      state = {
        ...state,
        data: state.data.map((campaign) => ({
          ...campaign,
          status: action.payload.status,
        })),
      };

      break;
    case CAMPAIGN_ACTIONS.SET_CAMPAIGN_ID:
      state = {
        ...state,
        campaignId: action.payload.campaignId,
      };
      break;

    case types.GET_CAMPAIGNS_REQUEST:
      state = { ...state, loading: true, globalFilter: action.payload };
      break;

    case types.GET_CAMPAIGNS_SUCCESS:
      state = {
        ...state,
        data: action.payload,
        total: action.total,
        loading: false,
        loaded: true,
      };
      break;
    case types.GET_CAMPAIGNS_FAILURE:
      state = {
        ...state,
        loading: false,
      };
      break;

    // duplicate

    case campaignTypes.DUPLICATE_CAMPAIGN_REQUEST:
      state = {
        ...state,
        isDuplicating: true,
      };
      break;

    case campaignTypes.DUPLICATE_CAMPAIGN_FAILURE:
      state = {
        ...state,
        error: action.error,
        isDuplicating: false,
      };
      break;

    case campaignTypes.DUPLICATE_CAMPAIGN_SUCCESS:
      state = {
        ...state,
        data: action.payload ? [action.payload, ...state.data] : state.data,
        isDuplicating: false,
      };
      break;

    case campaignTypes.CREATE_CAMPAIGN_REQUEST:
      {
        state = {
          ...state,
          loading: true,
        };
      }
      break;

    case campaignTypes.UPDATE_CAMPAIGNS_LIST_REQUEST:
      {
        state = {
          ...state,
          loading: true,
        };
      }
      break;

    case campaignTypes.UPDATE_CAMPAIGNS_LIST_SUCCESS:
      {
        state = {
          ...state,
          loaded: false,
          loading: false,
        };
      }
      break;
    // created successfully.

    case campaignTypes.CREATE_CAMPAIGN_SUCCESS:
      state = {
        ...state,
        loading: false,
        data: action.payload ? [action.payload, ...state.data] : state.data,
        loaded: false,
      };
      break;

    // update campaign
    case campaignTypes.EDIT_CAMPAIGN_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;

    case campaignTypes.EDIT_CAMPAIGN_SUCCESS:
      state = {
        ...state,
        loading: false,
      };
      break;

    case campaignTypes.EDIT_CAMPAIGN_FAILURE:
      state = {
        ...state,
        loading: false,
      };
      break;

    default:
  }

  return state;
};

export default campaignReducer;
