import React from "react";
import Button from "components/Button";
import { CheckoutTable, DatePickerPopup, BudgetItem, CustomBudget } from "./styled";
import { Spinner, OverlayTrigger, Tooltip, FormControlProps } from "react-bootstrap";
import Input from "components/Input";
import { Formik, FormikProps } from "formik";
import { validateDiscountCode } from "store/actions/campaigns.actions";
import { useDispatch } from "react-redux";
import Calendar from "components/Calendar";
import useOnClickOutside from "hooks/useClickOutside";
import { OverlayInjectedProps } from "react-bootstrap/esm/Overlay";
import { Range } from "react-date-range";
import { displayDate } from "constants/utility/date";
import { IPaymentForm } from "../../modules/Client/pages/Campaign/Step5/types";
import { IPromo } from "../PaymentSummary";
import applyCodeSchema from "schemas/apply-code.schema";
import { IPostCampaignData } from "constants/utility/campaign";
import CalendarIcon from "components/Icons/CalendarIcon";

interface ICheckoutPanelProps {
  isPaymentLoading: boolean;
  promo: IPromo | null;
  applyPromo: (promo: IPromo | null) => void;
  formikProps: FormikProps<IPostCampaignData>;
}

export const budget = [
  { key: "50", text: "$50/Day", value: 50 },
  { key: "100", text: "$100/Day", value: 100 },
];

// const MINIMUM_DAYS_FROM_TODAY = 3;
// const MINIMUM_DAYS_FOR_CAMPAIGN = 30;
const initialRange = {
  startDate: undefined,
  endDate: undefined,
  key: "selection",
} as Range;

const CheckoutPanel: React.FunctionComponent<ICheckoutPanelProps> = ({
  isPaymentLoading,
  promo,
  applyPromo,
  formikProps,
}) => {
  const [currentBudget, setBudget] = React.useState(budget[0]);
  const target = React.useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const [currentRange, setCurrentRange] = React.useState<Range>(initialRange);
  const [isOpenCalendar, openCalendar] = React.useState(false);

  const { setFieldValue, values, handleChange, touched, errors } = formikProps;

  useOnClickOutside(target, () => {
    openCalendar(false);
  });

  const renderPopup = (props: OverlayInjectedProps) => {
    return (
      <Tooltip
        style={{
          opacity: 1,
        }}
        ref={target}
        id="date-selector-tooltip"
      >
        <DatePickerPopup
          {...props}
          style={{
            ...props.style,
          }}
        >
          <Calendar
            range={currentRange}
            setSelection={(range) => {
              setCurrentRange(range);
              if (range.startDate !== range.endDate) {
                setFieldValue("paymentForm.startDate", range.startDate);
                setFieldValue("paymentForm.endDate", range.endDate);
                setCurrentRange(initialRange);
                openCalendar(false);
              }
            }}
          />
        </DatePickerPopup>
      </Tooltip>
    );
  };
  return (
    <CheckoutTable>
      <div className="checkout-detail">
        <h6 className="section-header">
          <b>Checkout</b>
        </h6>

        <div className="campaign-budget">
          <h4 className="header-title">Campaign Budget</h4>
          <ul className="budget-list">
            {budget.map((price) => (
              <BudgetItem
                selected={currentBudget.value === price.value}
                key={price.key}
                onClick={() => {
                  setBudget(price);
                  setFieldValue("paymentForm.budgetPerDay", price.value);
                }}
              >
                <button type="button" className="budget-item">
                  {price.text}
                </button>
              </BudgetItem>
            ))}
            <CustomBudget
              selected={currentBudget.value === 25}
              onClick={() => {
                setBudget({
                  key: "custom",
                  text: "custom",
                  value: 25,
                });
                setFieldValue("paymentForm.budgetPerDay", 25);
              }}
            >
              <button type="button" className="budget-item">
                <div className="title">
                  <b>Custom</b>
                </div>
                <div className="info">
                  <i>Estimated $25 per day</i>
                </div>
              </button>
            </CustomBudget>
          </ul>
          {currentBudget.key === "custom" && (
            <input
              className="budget"
              type="number"
              min="25"
              max="1000"
              placeholder="Budget"
              name="paymentForm.budgetPerDay"
              value={values.paymentForm.budgetPerDay}
              onChange={(e) => {
                if (e.target.value.length <= 4) {
                  handleChange(e);
                }
              }}
              onKeyDown={(e: any) => {
                if (e.target.value.startsWith("0")) {
                  e.target.value = "";
                }
              }}
              onBlur={(e: any) => {
                if (Number(e.target.value) < 25) {
                  e.target.value = 25;
                  handleChange(e);
                }
              }}
            />
          )}
          {touched.paymentForm?.budgetPerDay && errors.paymentForm?.budgetPerDay && (
            <div className="errorMessage">{errors.paymentForm.budgetPerDay}</div>
          )}
        </div>
        <OverlayTrigger
          show={isOpenCalendar}
          trigger="click"
          placement="bottom"
          overlay={renderPopup}
        >
          <div
            data-testid="time-period"
            onClick={() => openCalendar((prev) => !prev)}
            className="time-period"
          >
            <h4 className="header-title">Starting Date</h4>
            <div className="campaign-period">
              <span>
                {`${displayDate(new Date(values.paymentForm.startDate))} - ${displayDate(
                  new Date(values.paymentForm.endDate)
                )}`}
              </span>
              <CalendarIcon className="calendar-icon" />
            </div>
            {touched.paymentForm?.startDate &&
              touched.paymentForm?.endDate &&
              errors.paymentForm?.startDate &&
              errors.paymentForm?.endDate && (
                <div className="errorMessage">*Campaign period required</div>
              )}
          </div>
        </OverlayTrigger>

        <h4 className="header-title">Discount Code</h4>
        {promo ? (
          <div className="applied-promo">
            <span className="promo">{`Get $${promo.amount}USD from ${promo.title}`}</span>
            <Button
              onClick={() => {
                setFieldValue("paymentForm.discountCode", undefined);
                applyPromo(null);
              }}
            >
              Remove
            </Button>
          </div>
        ) : (
          <Formik
            validationSchema={applyCodeSchema}
            initialValues={{
              discountCode: "",
            }}
            onSubmit={(values, { setSubmitting, setFieldError }) => {
              setTimeout(async () => {
                await dispatch(
                  validateDiscountCode(
                    values.discountCode,
                    applyPromo,
                    setFieldError,
                    setFieldValue
                  )
                );

                setSubmitting(false);
              }, 1500);
            }}
          >
            {({ values, handleChange, handleSubmit, isSubmitting, errors }) => {
              return (
                <form onSubmit={handleSubmit} className="promo-section">
                  <div className="input-wrapper">
                    <Input
                      height={35}
                      dark
                      noBorder
                      maxLength={10}
                      type="text"
                      name="discountCode"
                      value={values.discountCode}
                      onChange={handleChange}
                      error={errors.discountCode}
                      touched
                    />
                  </div>
                  <div className="button-wrapper">
                    <Button
                      loading={isSubmitting}
                      width={80}
                      height={35}
                      secondary
                      noPadding
                      disabled={isSubmitting || isPaymentLoading}
                      type="submit"
                    >
                      Apply
                    </Button>
                  </div>
                </form>
              );
            }}
          </Formik>
        )}
      </div>
    </CheckoutTable>
  );
};

export default CheckoutPanel;
