import styled, { css } from "styled-components/macro";

export const NewsTag = styled.span<{ tag: string }>`
  font-size: 12px;
  padding: 10px 15px;
  position: absolute;
  font-weight: bold;
  top: 0;
  left: 0;

  ${({ tag, theme }) =>
    tag === "Global Trends" &&
    css`
      background-color: ${theme.color.green};
      color: black;
    `}

  ${({ tag, theme }) =>
    tag === "Feature Updates" &&
    css`
      background-color: ${theme.color.primary};
      color: white;
    `}

  ${({ tag }) =>
    tag === "Vatic AI News" &&
    css`
      background-color: rgb(103, 0, 203);
      color: white;
    `}
`;

export const FeedSidebar = styled.div`
  margin-top: 20px;
  max-width: 400px;

  .recent-news {
    background-color: #000000;
    padding: 20px;

    .news-list-title {
      color: rgb(27, 255, 118);
      font-size: 18px;
      font-weight: 600;
      padding-bottom: 1rem;
      border-bottom: rgb(226, 226, 226) 1px solid;
    }

    .recent-item {
      padding-bottom: 15px;
    }

    .recent-item {
      .feed-title {
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
      .date-post {
        font-size: 12px;
      }
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      .date-post {
        color: ${({ theme }) => theme.color.blue};
      }
    }
  }
`;

export const NewsFeedPageStyled = styled.div<{ display?: boolean }>`
  margin-bottom: 50px;
  width: 100%;
  margin-top: 21px;

  .more-feed {
    text-align: center;

    .btn {
      width: 100%;
      margin-top: 15px;
    }
  }

  .content-section {
    display: flex;
    gap: 15px;
  }

  .input-header {
    margin-bottom: 0 !important;
  }

  .keyword-tool-title {
    font-size: 11px;
  }

  .page-title {
    font-size: 20px;
    font-weight: bold;
    min-height: 24px;
  }

  .selected-table {
    max-height: unset;
  }

  .news-feed-page {
    .feed-wrapper {
      display: flex;
      flex-direction: row;
      row-gap: 15px;
      width: 100%;
      /* overflow-y: scroll; */
      /* height: 90vh; */
      flex-wrap: wrap;
      justify-content: space-between;

      .feed-item {
        cursor: pointer;
        display: flex;
        width: 100%;
        background: rgb(26, 26, 26);

        .content-wrapper {
          padding: 15px;
          width: 50%;
        }

        .thumbnail-wrapper {
          position: relative;
          text-align: center;

          .feed-thumbnail {
            background-size: cover;
            background-repeat: no-repeat;
            width: 320px;
            height: auto;
            margin: 0 auto;
            background-position: center;
          }
        }

        .title {
          -webkit-line-clamp: 2;
          font-weight: bold;
          color: ${({ theme }) => theme.color.green};
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }

        .date {
          color: ${({ theme }) => theme.color.blue};
          font-size: 12px;
        }

        &:first-child {
          display: block;

          .content-wrapper {
            width: 100%;
          }

          .thumbnail-wrapper {
            width: 100%;
            text-align: center;

            .feed-thumbnail {
              width: 100%;
              height: auto;
            }
          }

          .feed-content {
            white-space: pre-line;
            -webkit-line-clamp: 2;
          }
        }

        .feed-content {
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          color: lightgray;
        }
      }
    }
  }

  @media (min-width: 1360px) {
    .feed-wrapper > div:nth-child(1) {
      width: 58% !important;
    }
    .feed-wrapper > div:nth-child(2) {
      width: 40% !important;
      flex-direction: column;

      .thumbnail-wrapper {
        width: 100% !important;
        img {
          width: 100% !important;
          height: auto !important;
        }
      }
    }
  }
`;
