export const BUSINESS_ID_REF = {
  1: "Affiliate",
  2: "Agency",
  3: "Automotive",
  4: "Beauty",
  5: "Education",
  6: "Entertainment & Media",
  7: "Fashion & Apparel",
  8: "Finance",
  9: "Government",
  10: "Nonprofits",
  11: "Health & Fitness",
  12: "Property & Real Estate",
  13: "Manufacturing",
  14: "Restaurants",
  15: "Retail",
  16: "Technology",
  17: "Telecommunications",
  18: "Transportation & Logistics",
  19: "Travel & Hospitality",
} as Record<string, string>;

export const BUSINESS_TYPE = [
  {
    value: 1,
    text: "Affiliate",
  },
  {
    value: 2,
    text: "Agency",
  },
  {
    value: 3,
    text: "Automotive",
  },
  {
    value: 4,
    text: "Beauty",
  },
  {
    value: 5,
    text: "Education",
  },
  {
    value: 6,
    text: "Entertainment & Media",
  },
  {
    value: 7,
    text: "Fashion & Apparel",
  },
  {
    value: 8,
    text: "Finance",
  },
  {
    value: 9,
    text: "Government",
  },
  {
    value: 10,
    text: "Nonprofits",
  },
  {
    value: 11,
    text: "Health & Fitness",
  },
  {
    value: 12,
    text: "Property & Real Estate",
  },
  {
    value: 13,
    text: "Manufacturing",
  },
  {
    value: 14,
    text: "Restaurants",
  },
  {
    value: 15,
    text: "Retail",
  },
  {
    value: 16,
    text: "Technology",
  },
  {
    value: 17,
    text: "Telecommunications",
  },
  {
    value: 18,
    text: "Transportation & Logistics",
  },
  {
    value: 19,
    text: "Travel & Hospitality",
  },
];
