import { IDataForSEO } from "constants/utility/keyword";
import { IErrorMessage } from "../../services/types";
import * as actions from "../action-types/home.types";

export function saveKeywordsHistory(payload: {
  [key: string]: IDataForSEO;
}): actions.SaveKeywordsHistoryAction {
  return {
    type: actions.SAVE_KEYWORDS_TO_HISTORY,
    payload,
  };
}

export function saveKeywordsHistoryRequest(): actions.SaveKeywordsHistoryRequestAction {
  return {
    type: actions.SAVE_KEYWORDS_TO_HISTORY_REQUEST,
  };
}

export function saveKeywordsHistorySuccess(payload: {
  [key: string]: IDataForSEO;
}): actions.SaveKeywordsHistorySuccessAction {
  return {
    type: actions.SAVE_KEYWORDS_TO_HISTORY_SUCCESS,
    payload,
  };
}

export function saveKeywordsHistoryFailure(
  error: IErrorMessage
): actions.SaveKeywordsHistoryFailureAction {
  return {
    type: actions.SAVE_KEYWORDS_TO_HISTORY_FAILURE,
    error,
  };
}
