export const numberToAbbreviatedString = (value: number) => {
  let newValue: string | number = value;

  if (value >= 1000) {
    const suffixes = ["", "K", "M", "B", "T"];
    const suffixNum = Math.floor(value.toString().length / 3);
    let shortValue: string | number = "";

    for (let precision = 2; precision >= 1; precision--) {
      shortValue = parseFloat(
        (suffixNum !== 0 ? value / Math.pow(1000, suffixNum) : value).toPrecision(precision)
      );
      const dotLessShortValue = shortValue.toString().replace(/[^a-zA-Z 0-9]+/g, "");

      if (dotLessShortValue.length <= 2) {
        break;
      }
    }

    if (Number(shortValue) % 1 !== 0) {
      shortValue = Number(shortValue).toFixed(1);
    }

    newValue = shortValue + suffixes[suffixNum];
  }

  return newValue;
};

export const numberWithCommas = (number: number | string, decimal?: boolean) => {
  if (decimal) {
    const options = {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    };
    const formatted = Number(number).toLocaleString("en", options);
    return formatted;
  } else {
    return Math.round(Number(number))
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
};

export const twoDecimal = (number: number) => {
  return Math.round((number + Number.EPSILON) * 100) / 100;
};
