import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import IconWrapper from "../Icons/IconWrapper";
import { StyledPagination } from "./styled";

interface IPaginationProps {
  pageOptions: number[];
  pageIndex: number;
  canPreviousPage: boolean;
  canNextPage: boolean;
  gotoPage: (page: number) => void;
  previousPage: () => void;
  nextPage: () => void;
  pageCount: number;
}

const Pagination: React.FunctionComponent<IPaginationProps> = ({
  pageOptions,
  pageIndex,
  canPreviousPage,
  canNextPage,
  gotoPage,
  previousPage,
  nextPage,
  pageCount,
}) => {
  return pageOptions.length ? (
    <StyledPagination className="pagination" data-testid="pagination">
      <span className="total-page">
        Page {pageIndex + 1} of {pageOptions.length}
      </span>
      <IconWrapper
        data-testid="pagination-first"
        onClick={() => gotoPage(0)}
        disabled={!canPreviousPage}
      >
        <FontAwesomeIcon icon="angle-double-left" />
      </IconWrapper>

      <IconWrapper
        data-testid="pagination-prev"
        onClick={() => previousPage()}
        disabled={!canPreviousPage}
      >
        <FontAwesomeIcon icon="angle-left" />
      </IconWrapper>

      <IconWrapper data-testid="pagination-next" onClick={() => nextPage()} disabled={!canNextPage}>
        <FontAwesomeIcon icon="angle-right" />
      </IconWrapper>

      <IconWrapper
        data-testid="pagination-last"
        onClick={() => gotoPage(pageCount - 1)}
        disabled={!canNextPage}
      >
        <FontAwesomeIcon icon="angle-double-right" />
      </IconWrapper>
    </StyledPagination>
  ) : null;
};

export default Pagination;
