import React from "react";
import styled, { css } from "styled-components/macro";
import Spinner from "components/Spinner";

const StyledInput = styled.div<{
  $isError?: boolean;
  $noBorder?: boolean;
  $left?: number;
  $disabled?: boolean;
  $transparent?: boolean;
  $height?: number;
  $dark?: boolean;
  $black?: boolean;
  $width?: number;
}>`
  position: relative;
  width: 100%;
  opacity: ${({ $disabled }) => ($disabled ? 0.5 : 1)};
  height: min-content;
  font-family: "Open Sans", sans-serif;
  border-radius: 8px !important;
  border: 1px solid rgba(0, 0, 0, 0) !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  height: 50px !important;
  line-height: 21px !important;

  .spinner-wrapper {
    position: absolute;
    right: 20px;
    top: 0;
    width: fit-content;

    .spinner-border {
      width: 1rem;
      height: 1rem;
      border-width: 0.15em;
    }
  }

  .input-text {
    transition: 0.3s;
    width: ${({ $width }) => ($width ? `${$width}px` : "100%")};
    height: ${({ $height }) => $height || 45}px;
    padding-left: ${({ $left }) => {
      return $left || "20";
    }}px;
    border: 1px solid
      ${({ $isError, $noBorder, theme }) =>
        $isError ? "rgb(220, 20, 60)" : $noBorder ? "transparent" : theme.inputBorder};
    color: ${({ $isError, theme }) => ($isError ? "rgb(204,0,0)" : theme.contrast)};
    background-color: ${({ $dark, $black, theme }) =>
      $dark ? theme.inputSecondary : $black ? theme.input : theme.inputDark};
    border-radius: 8px;

    ::placeholder,
    ::-webkit-input-placeholder {
      opacity: 0.5;
      color: ${({ theme }) => theme.contrast};
    }

    :-ms-input-placeholder {
      opacity: 0.5;
      color: ${({ theme }) => theme.contrast};
    }

    outline: none;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  }

  ${({ $transparent }) =>
    $transparent &&
    css`
      input {
        background: transparent !important;

        &:focus {
          outline: none !important;
        }
      }
    `}

  ${({ $black }) =>
    $black &&
    css`
      input {
        font-size: 14px;
        ::placeholder {
          color: ${({ theme }) => theme.light};
        }
        :-webkit-autofill {
          -webkit-box-shadow: ${({ theme }) => theme.autofill} !important;
        }
      }
    `}
`;

interface IInputProps {
  transparent: boolean;
  disabled: boolean;
  left?: number;
  noBorder: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  [key: string]: any;
  dark?: boolean;
  error?: string;
  black?: boolean;
  loading?: boolean;
}

const Input = React.forwardRef(
  (
    {
      height,
      transparent,
      disabled,
      left,
      children,
      noBorder,
      dark,
      error,
      touched,
      black,
      width,
      loading,
      ...otherProps
    }: IInputProps,
    forwardedRef: any
  ) => {
    return (
      <StyledInput
        $black={black}
        $transparent={transparent}
        $disabled={disabled}
        $left={left}
        $noBorder={noBorder}
        $isError={error && touched}
        $height={height}
        $dark={dark}
        $width={width}
      >
        {loading && <Spinner />}
        <input
          autoComplete="off"
          ref={forwardedRef}
          disabled={disabled || loading}
          className="input-text"
          {...otherProps}
        />
        {error && touched && <div className="input-error errorMessage">{error}</div>}
        {children}
      </StyledInput>
    );
  }
);

Input.displayName = "Input";

export default Input;
