import React from "react";

const Step6Icon = ({ className }: { className?: string }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 61.89 61.89" className={className}>
      <defs>
        <style>
          {
            "\n      .icon-svg{fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.89px;}\n    "
          }
        </style>
      </defs>
      <g id="Layer_2" data-name="Layer 2">
        <g id="details">
          <path className="icon-svg" d="M31,30.4a5.69,5.69,0,1,1,5.68-5.69" />
          <path className="icon-svg" d="M31,30.4a5.68,5.68,0,1,1-5.69,5.68" />
          <line className="icon-svg" x1={30.95} y1={19.03} x2={30.95} y2={13.35} />
          <line className="icon-svg" x1={30.95} y1={47.44} x2={30.95} y2={41.76} />
          <circle className="icon-svg" cx={30.95} cy={30.95} r={30} />
        </g>
      </g>
    </svg>
  );
};

export default Step6Icon;
