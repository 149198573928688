import Button from "components/Button";
import React from "react";
import { Link } from "react-router-dom";
import { RegisterSuccessStyled } from "./styled";
import * as AUTH_ROUTE from "modules/Auth/routes";

const RegisterSuccess = () => {
  return (
    <RegisterSuccessStyled>
      <p>Congratulations!</p>
      <p>Your account has been successfully created.</p>
      <p>Please check your email to verify your account.</p>
      <div className="forget-password-button">
        <span className="back-button">← Back to </span>
        <Link to={`../${AUTH_ROUTE.LOGIN}`}>{` Log in`}</Link>
      </div>
    </RegisterSuccessStyled>
  );
};

export default RegisterSuccess;
