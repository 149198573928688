import { IDataForSEOV3 } from "constants/utility/keyword";

export const sortObjectByKey = (
  currentObj: { [key: string]: IDataForSEOV3 },
  valSelector: (obj: IDataForSEOV3) => void
): [{ [key: string]: IDataForSEOV3 }, boolean] => {
  const objToArray = Object.entries(currentObj);
  const isAscending = !!(
    objToArray.length &&
    valSelector(objToArray[0][1]) < valSelector(objToArray[objToArray.length - 1][1])
  );

  const sortedArray = objToArray.sort((a, b) => {
    if (valSelector(a[1]) > valSelector(b[1])) {
      return isAscending ? -1 : 1;
    } else if (valSelector(a[1]) < valSelector(b[1])) {
      return isAscending ? 1 : -1;
    } else {
      return 0;
    }
  });

  const sortedObj = sortedArray.reduce((acc, cur) => {
    const [key, value] = cur;
    acc[key] = value;
    return acc;
  }, {} as { [key: string]: IDataForSEOV3 });

  return [sortedObj, isAscending];
};
