import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { TablePaginationStyled, PaginationButton } from "./styled";
const rowsList = [10, 20, 30, 40, 50];

interface ITablePaginationProps {
  amountOfKeywords: number;
  setStartingPage: (page: number) => void;
  setPageLimit: (page: number) => void;
  pageLimit: number;
  startingPage: number;
}

const TablePagination: React.FunctionComponent<ITablePaginationProps> = ({
  amountOfKeywords,
  setPageLimit,
  setStartingPage,
  pageLimit,
  startingPage,
}) => {
  return (
    <TablePaginationStyled>
      <div className="rows-tab">
        <span>Show rows: </span>

        <select
          onChange={(e) => {
            setStartingPage(0);
            setPageLimit(Number(e.target.value));
          }}
          value={pageLimit}
          className="rows-selector"
        >
          {rowsList.map((val) => (
            <option key={val} disabled={amountOfKeywords < val} value={val}>
              {val}
            </option>
          ))}
        </select>
      </div>
      <span>
        {`${startingPage + 1} -
                ${
                  startingPage + pageLimit > amountOfKeywords
                    ? amountOfKeywords
                    : startingPage + pageLimit
                }
                of ${amountOfKeywords}`}
      </span>
      <div className="pagination-actions">
        <PaginationButton onClick={() => setStartingPage(0)} disabled={startingPage <= 0}>
          <FontAwesomeIcon icon="step-backward" />
        </PaginationButton>
        <PaginationButton
          disabled={startingPage <= 0}
          onClick={() => setStartingPage(startingPage - pageLimit - (startingPage % 10))}
        >
          <FontAwesomeIcon icon="chevron-left" />
        </PaginationButton>
        <PaginationButton
          disabled={startingPage + pageLimit >= amountOfKeywords}
          onClick={() => setStartingPage(startingPage + pageLimit)}
        >
          <FontAwesomeIcon icon="chevron-right" />
        </PaginationButton>
        <PaginationButton
          disabled={startingPage + pageLimit >= amountOfKeywords}
          onClick={() => {
            setStartingPage(
              amountOfKeywords - (amountOfKeywords % pageLimit) < amountOfKeywords
                ? amountOfKeywords - (amountOfKeywords % pageLimit)
                : amountOfKeywords - pageLimit
            );
          }}
        >
          <FontAwesomeIcon icon="step-forward" />
        </PaginationButton>
      </div>
    </TablePaginationStyled>
  );
};

export default TablePagination;
