import styled from "styled-components/macro";

export const ManageUserPage = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  font-size: 12px;
  margin-top: 20px;

  .keyword-tool-title {
    font-size: 11px;
  }

  .page-title {
    font-size: 20px;
    font-weight: bold;
  }

  .table-wrapper {
    margin-top: 20px;

    .table-wrapper-inner {
      .table-responsive {
        display: flex;
        margin-bottom: 20px;
        overflow-y: hidden;
      }

      .pagination {
        display: flex;
        gap: 15px;
        margin-top: 20px;
        justify-content: flex-end;
        align-items: center;
      }
    }
  }

  .scrollable {
    /* overflow-x: scroll; */

    table {
      thead {
        tr {
          th {
            &:nth-child(1) {
              width: 150px;
              text-align: center;
            }
            &:nth-child(2) {
              width: 100px;
              text-align: center;
            }
            &:nth-child(3) {
              width: 150px;
              text-align: center;
            }
            &:nth-child(4) {
              text-align: center;
              width: 150px;
            }
            &:nth-child(5) {
              width: 100px;
              text-align: center;
            }
            &:nth-child(6) {
              width: 150px;
              text-align: center;
            }
            &:nth-child(7) {
              width: 165px;
              text-align: center;
            }
            &:nth-child(8) {
              width: 100px;
              text-align: center;
            }
          }
        }
      }

      tbody {
        tr {
          td {
            &:nth-child(1) {
              text-align: center;
            }

            &:nth-child(2) {
              text-align: center;
            }

            &:nth-child(3) {
              text-align: center;
            }

            &:nth-child(4) {
              text-align: center;
            }

            &:nth-child(5) {
              text-align: center;
            }

            &:nth-child(6) {
              text-align: center;
            }

            &:nth-child(7) {
              display: flex;
              justify-content: center;
              align-items: center;
            }

            &:nth-child(8) {
              text-align: center;
            }
          }
        }
      }
    }
  }

  .freeze {
    table {
      thead {
        tr {
          th {
            &:first-child {
              padding-left: 20px;
            }
            &:nth-child(1) {
              width: 200px;
            }
            &:nth-child(2) {
              width: 250px;
            }
            &:nth-child(3) {
              width: 50px;
              text-align: center;
            }
          }
        }
      }

      tbody {
        tr {
          td {
            &:first-child {
              padding-left: 20px;
            }

            &:nth-child(3) {
              text-align: center;
            }

            &:nth-child(5) {
              text-align: center;
            }

            &:nth-child(6) {
              text-align: center;
            }

            &:nth-child(7) {
              display: flex;
              justify-content: center;
              align-items: center;
            }

            &:nth-child(10) {
              text-align: center;
            }
          }
        }
      }
    }
  }
`;
