import { put, call, takeEvery, all, fork, takeLatest } from "redux-saga/effects";

import api from "../../services/api";
import {
  GET_USERS,
  UPDATE_USER,
  DELETE_USERS,
  DeleteUsersAction,
  UpdateUserAction,
  GetUsersAction,
} from "../action-types/users.types";
import * as actions from "../actions/users.actions";
import { closeSlideModal } from "../actions/sidebar.actions";
import { handlingError } from "../../constants/utility/error";

// get

function* onGetUsers(action: GetUsersAction) {
  try {
    yield put(actions.getUsersRequest());
    const { data, total } = yield call(api.admin.users.getUsers, action.payload);
    yield put(actions.getUsersSuccess(data, total));
  } catch (error: any) {
    handlingError(error);
    yield put(actions.getUsersFailure(error.response.data.message));
  }
}

function* watchOnGetUsers() {
  yield takeLatest(GET_USERS, onGetUsers);
}

// update

function* onUpdateUser(action: UpdateUserAction) {
  try {
    yield put(actions.updateUserRequest());
    const { data } = yield call(api.admin.users.updateUser, action.payload);
    yield put(actions.updateUserSuccess(data));
    yield put(closeSlideModal());
  } catch (error: any) {
    handlingError(error);
    yield put(actions.updateUserFailure(error.response.data.message));
  }
}

function* watchOnUpdateUser() {
  yield takeLatest(UPDATE_USER, onUpdateUser);
}

// delete

function* onDeleteUsers(action: DeleteUsersAction) {
  try {
    yield put(actions.deleteUsersRequest());
    yield call(api.admin.users.deleteUsers, action.payload.ids);
    yield put(actions.getUsers(action.payload.currentFilters));
  } catch (error: any) {
    handlingError(error);
    yield put(actions.deleteUsersFailure(error.response.data.message));
  }
}

function* watchOnDeleteUsers() {
  yield takeEvery(DELETE_USERS, onDeleteUsers);
}

export default function* usersSaga() {
  yield all([fork(watchOnGetUsers), fork(watchOnUpdateUser), fork(watchOnDeleteUsers)]);
}
