// @ts-nocheck
/* eslint-disable */

export const debounce = (fn, delay = 500) => {
  let timeout;
  return function (...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      timeout = null;
      fn.apply(this, args);
    }, delay);
  };
};
