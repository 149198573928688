import React from "react";
import ExportIcon from "components/Icons/ExportIcon";
import Checkbox from "components/Checkbox";
import { BottomBarStyled, TableActionButton } from "./styled";
import { connect, ConnectedProps, useDispatch } from "react-redux";
// import { addKeywords } from "store/actions/keyword-list-form.actions";
import { RootState } from "store/store";
import { showExportPopup, showKeywordListPopup } from "store/actions/popup.actions";
import SaveToListIcon from "components/Icons/SaveToListIcon";
import { TrashIcon } from "components/Icons";
import { ENUM_USER_ROLES } from "store/states/user.state";

interface IBottomBarProps extends PropsFromRedux {
  children?: React.ReactNode | JSX.Element;
  isAllChecked: boolean;
  selectAllCallback: () => void;
  selectedItems: Array<string>;
  onSelectionDeleteButtonClick?: () => void;
  onExport?: {
    type: "keywords" | "campaigns" | "performance";
    data?: Array<string> | { startDate: Date; endDate: Date; id?: string };
  };
  saveList?: boolean;
}

const mapStateToProps = ({ savedKeywordsList, user }: RootState) => ({
  savedKeywordsList,
  user,
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const BottomBar: React.FunctionComponent<IBottomBarProps> = ({
  children,
  isAllChecked,
  selectAllCallback,
  selectedItems,
  onSelectionDeleteButtonClick,
  onExport,
  saveList,
  user,
}) => {
  const dispatch = useDispatch();
  // const [isSaving, setSaveKeyword] = React.useState(false);

  // const onKeywordAddToList = async (
  //   keywords: Array<string>,
  //   list: { id: string; name: string }
  // ) => {
  //   setSaveKeyword(true);
  //   await dispatch(addKeywords(keywords, list.id));
  //   setSaveKeyword(false);
  // };

  const disabled = !selectedItems.length;

  return (
    <BottomBarStyled className="bottom-bar">
      <div className="button-group">
        <div className="select-all-checkbox">
          <Checkbox
            data-testid="select-all"
            selectAll
            centered
            checked={isAllChecked}
            onSelect={() => selectAllCallback()}
          />
          <span>Select All</span>
        </div>

        <div className="keyword-table-actions">
          {onExport && (
            // <DropdownStyled color="primary">
            //   <DropdownButton>
            //     <Dropdown.Toggle>
            //       <ActionButton icon={<ExportIcon color="rgb(27,255,118)" />} text="Export" />
            //     </Dropdown.Toggle>
            //     <Dropdown.Menu>
            //       <Dropdown.Item onClick={() => onExport("csv")}>CSV</Dropdown.Item>
            //       <Dropdown.Item onClick={() => onExport("xlsx")}>Excel</Dropdown.Item>
            //     </Dropdown.Menu>
            //   </DropdownButton>
            // </DropdownStyled>

            <TableActionButton
              color="primary"
              className="export-table-action"
              onClick={() =>
                dispatch(
                  showExportPopup({
                    type: onExport.type,
                    data: onExport.data,
                  })
                )
              }
            >
              <ExportIcon />
              <span>Export</span>
            </TableActionButton>
          )}

          {saveList && (
            <TableActionButton
              className="save-list-table-action"
              disabled={disabled}
              onClick={() => dispatch(showKeywordListPopup({ values: selectedItems }))}
            >
              <SaveToListIcon />
              <span>Save to list</span>
            </TableActionButton>
          )}

          {onSelectionDeleteButtonClick && user.role !== ENUM_USER_ROLES.ADMIN && (
            <TableActionButton
              outline
              className="delete-table-action"
              disabled={!selectedItems.length}
              onClick={() => {
                onSelectionDeleteButtonClick();
              }}
            >
              <TrashIcon />
              <span>Delete</span>
            </TableActionButton>
          )}
        </div>
      </div>
      {children || null}
    </BottomBarStyled>
  );
};

BottomBar.defaultProps = {
  saveList: true,
};

export default connector(BottomBar);
