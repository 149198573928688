import { IPaymentDetail, PaymentGateway, PaymentStatus } from "./payments.state";
import { CampaignStatus, ENUM_CAMPAIGN_STATUS } from "./campaign.state";
import { IContentCategory } from "constants/category";
import { AdFormCategoryID, ICampaignFilters } from "services/types";
import { TargetingItem } from "./targeting.state";
interface ICampaignsState {
  loading: boolean;
  error: null;
  data: Array<ICampaignListData>;
  loaded: boolean;
  campaignId: null | string;
  isDuplicating: boolean;
  total: number;
  globalFilter: ICampaignFilters;
}

const state: ICampaignsState = {
  loading: false,
  error: null,
  data: [],
  loaded: false, // Indicates whether the data is loaded at least once,
  campaignId: null,
  isDuplicating: false,
  total: 0,
  globalFilter: {},
};

export interface IPaymentStatus {
  state: PaymentStatus;
  amount: number;
  id: string;
  paymentGateway: PaymentGateway;
}

export type ICampaignListData = ICampaign & IDailyReport;

export interface ICampaign {
  id: string;
  adFormId: string | null;
  beeswaxId: string | null;
  name: string;
  goal: string;
  amount?: null | number;
  description: string;
  created_at: Date;
  budgetPerDay: number;
  startDate: string;
  endDate: string;
  isActive: boolean;
  payments?: Array<IPaymentDetail>;
  state: null | PaymentStatus;
  status: CampaignStatus;
  creativeType: number;
  theKeywords: string[];
  theGenders: string[];
  theAgeGroups: string[];
  theOs: number[];
  theDevices: number[];
  theBrowsers: number[];
  locations: ILocation[];
  banners: IAdsCreative[];
  creatives: IAdsCreative[];
  totalLifeTimeBudget: number;
  report: ICampaignReport;
  categories: Array<AdFormCategoryID & { behavior: "include" | "exclude" }>;
  overallReports: IDailyReport | null;
  userName?: string;
  portalUrl?: string;
}

export interface IAdsCreative {
  id: number;
  campaignId?: string;
  url: string;
  title?: string;
  created_at?: Date;
  updated_at?: Date;
  images: BannerImage[];
  landingPageUrl?: string;
  thankyouPageUrl?: string;
  hook?: string;
  offer?: string;
  callToAction?: string;
  briefing?: string;
  notes?: string;
  assets?: BannerImage[];
  creativeId?: string;
}

export interface BannerImage {
  name: string;
  url: string;
  width: number;
  height: number;
  original: {
    height: number;
    mine: string;
    size: string;
    width: number;
  };
}

export interface ILocation {
  address: string;
  placeId: string;
  countryId?: string;
}

export interface ICampaignReport {
  loading?: boolean;
  data: IDailyReport | null;
}
export const mockRepor = [
  {
    date: "2022-02-01",
    impression: 4098,
    click: 60,
    conversion: 0,
    engageVisitor: null,
    radCpc: 12.916666666666666,
    ctr: 0.014641288433382138,
    radCpm: 0.0001891166422645193,
    radCpa: 0,
    conversionRate: 0,
    cost: 775,
  },
  {
    date: "2022-02-02",
    impression: 1605,
    click: 13,
    conversion: 0,
    engageVisitor: null,
    radCpc: 59.61538461538461,
    ctr: 0.00809968847352025,
    radCpm: 0.0004828660436137072,
    radCpa: 0,
    conversionRate: 0,
    cost: 775,
  },
  {
    date: "2022-02-03",
    impression: 2321,
    click: 27,
    conversion: 0,
    engageVisitor: null,
    radCpc: 28.703703703703702,
    ctr: 0.011632916846186989,
    radCpm: 0.00033390779836277464,
    radCpa: 0,
    conversionRate: 0,
    cost: 775,
  },
  {
    date: "2022-02-04",
    impression: 2674,
    click: 9,
    conversion: 0,
    engageVisitor: null,
    radCpc: 86.11111111111111,
    ctr: 0.0033657442034405387,
    radCpm: 0.00028982797307404635,
    radCpa: 0,
    conversionRate: 0,
    cost: 775,
  },
  {
    date: "2022-02-05",
    impression: 3231,
    click: 24,
    conversion: 0,
    engageVisitor: null,
    radCpc: 32.291666666666664,
    ctr: 0.007428040854224698,
    radCpm: 0.0002398638192510059,
    radCpa: 0,
    conversionRate: 0,
    cost: 775,
  },
  {
    date: "2022-02-06",
    impression: 5795,
    click: 19,
    conversion: 0,
    engageVisitor: null,
    radCpc: 40.78947368421053,
    ctr: 0.003278688524590164,
    radCpm: 0.00013373597929249354,
    radCpa: 0,
    conversionRate: 0,
    cost: 775,
  },
  {
    date: "2022-02-07",
    impression: 4209,
    click: 22,
    conversion: 0,
    engageVisitor: null,
    radCpc: 35.22727272727273,
    ctr: 0.005226894749346638,
    radCpm: 0.00018412924685198384,
    radCpa: 0,
    conversionRate: 0,
    cost: 775,
  },
  {
    date: "2022-02-08",
    impression: 1194,
    click: 13,
    conversion: 0,
    engageVisitor: null,
    radCpc: 59.61538461538461,
    ctr: 0.010887772194304857,
    radCpm: 0.0006490787269681742,
    radCpa: 0,
    conversionRate: 0,
    cost: 775,
  },
  {
    date: "2022-02-09",
    impression: 0,
    click: 0,
    conversion: 0,
    engageVisitor: null,
    radCpc: 0,
    ctr: 0,
    radCpm: 0,
    radCpa: 0,
    conversionRate: 0,
    cost: 0,
  },
  {
    date: "2022-02-10",
    impression: 0,
    click: 0,
    conversion: 1,
    engageVisitor: null,
    radCpc: 0,
    ctr: 0,
    radCpm: 0,
    radCpa: 775,
    conversionRate: 0,
    cost: 0,
  },
  {
    date: "2022-02-11",
    impression: 0,
    click: 0,
    conversion: 0,
    engageVisitor: null,
    radCpc: 0,
    ctr: 0,
    radCpm: 0,
    radCpa: 0,
    conversionRate: 0,
    cost: 0,
  },
  {
    date: "2022-02-12",
    impression: 0,
    click: 0,
    conversion: 0,
    engageVisitor: null,
    radCpc: 0,
    ctr: 0,
    radCpm: 0,
    radCpa: 0,
    conversionRate: 0,
    cost: 0,
  },
  {
    date: "2022-02-13",
    impression: 0,
    click: 0,
    conversion: 1,
    engageVisitor: null,
    radCpc: 0,
    ctr: 0,
    radCpm: 0,
    radCpa: 775,
    conversionRate: 0,
    cost: 0,
  },
  {
    date: "2022-02-14",
    impression: 0,
    click: 0,
    conversion: 1,
    engageVisitor: 0,
    radCpc: 0,
    ctr: 0,
    radCpm: 0,
    radCpa: 775,
    conversionRate: 0,
    cost: 0,
  },
];
export interface ICampaignReportData {
  click: number;
  // engageVisitor: null | number;
  conversion: number;
  // conversionRate: number;
  impression: number;
  // radCpa: number;
  // radCpc: number;
  // radCpm: number;
  ctr: number;
  spend?: number;
  cost?: number;

  cvr?: number;

  date?: string;
  geo?: string;
  segment_name?: string;
  creative?: string;
  platform?: string;
}

export default state;

export interface IDailyReport {
  cvr: number;
  spend: number;
  geo?: string;

  id: number;
  cacheStatisticIds: string;
  source: string;
  refId: string;
  date: string;
  cov?: number;
  ctr: number;
  ctrUnique?: number;
  ctrView?: number;
  ecpa?: number;
  ecpc?: number;
  ecpi?: number;
  ecpm?: number;
  ecpmv?: number;
  ecpp?: number;
  engagementRate?: number;
  engagingImpressions?: number;
  events?: number;
  measurableImpressions?: number;
  mouseOverRate?: number;
  pageviews?: number;
  rtbBids?: number;
  rtbWinRate?: number;
  sales?: number;
  seAvgCPC?: number;
  seAvgPosition?: number;
  visitors?: number;
  visits?: number;
  uniqueImpressionsPercent?: number;
  uniqueClicksPercent?: number;
  viewImpressionsPercentIAB?: number;
  avgBrandExposureDuration?: number;
  avgEngagementTime?: number;
  avgExpansionTime?: number;
  avgFrequency?: number;
  avgMouseoverTime?: number;
  avgPageDuration?: number;
  avgOrderSales?: number;
  avgVideoPlayTime?: number;
  avgVisitDuration?: number;
  bounceRate?: number;
  //
  cost: number;
  totalBudget: number;
  totalCampaignDay: number;
  totalRunningDay: number;
  radCpa: number;
  radCpc: number;
  radCpm: number;
  impression: number;
  engageVisitor: number | null;
  click: number;
  conversion: number;
  conversionRate: number;
  budgetPerDay: number;
}
