import Accordion from "components/Accordion";
import Badge from "components/Badge";
import Button from "components/Button";
import CampaignItemList from "components/CampaignItemList";
import CompareIcon from "components/Icons/CompareIcon";
import CompetitorIcon from "components/Icons/CompetitorIcon";
import PlusIcon from "components/Icons/PlusIcon";
import SuggestedIcon from "components/Icons/SuggestedIcon";
import KeywordListItem from "components/KeywordListItem";
import KeywordSetItem from "components/KeywordSetItem";
import Spinner from "components/Spinner";
import { useNavigate } from "react-router-dom";
// import KeywordTools from "components/KeywordTools";
import React from "react";
import { connect, ConnectedProps, useDispatch } from "react-redux";
import {
  updateCampaignListRequest,
  updateCampaignListSuccess,
} from "store/actions/campaign.actions";
import { getCampaigns } from "store/actions/campaigns.actions";
import { deleteCompare, getCompare } from "store/actions/compare.actions";
import { deleteCompetitor, getCompetitor } from "store/actions/competitor.actions";
import {
  showAdvertiserForm,
  showBuildCampaign,
  showComparePopup,
  showCompetitorPopup,
  showConfirmPopup,
  showSuggestedPopup,
} from "store/actions/popup.actions";
import { deleteSuggested, getSuggested } from "store/actions/suggested.actions";
import { RootState } from "store/store";
import { HomeStyled, LinkText } from "./styled";

const mapStateToProps = ({
  keywordsList,
  compare,
  campaigns,
  suggested,
  competitor,
  user,
  popup,
}: RootState) => ({
  keywordsList,
  compare,
  campaigns,
  suggested,
  competitor,
  user,
  popup,
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface IHomeProps extends PropsFromRedux {}
const Home: React.FunctionComponent<IHomeProps> = ({
  keywordsList,
  suggested,
  competitor,
  compare,
  campaigns,
  user,
}) => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(updateCampaignListRequest());
    dispatch(updateCampaignListSuccess());
  }, []);

  React.useEffect(() => {
    if (!campaigns.loaded) {
      dispatch(
        getCampaigns({
          page: 1,
          asc: "created_date",
        })
      );
    }
  }, [campaigns.loaded]);

  React.useEffect(() => {
    dispatch(getCompare());
    dispatch(getSuggested());
    dispatch(getCompetitor());
  }, []);

  const openComparePopup = (keywords: Array<string>) => {
    dispatch(showComparePopup(keywords));
  };

  const onDeleteCompareList = (id: string) => {
    dispatch(
      showConfirmPopup({
        title: "Delete?",
        body: "Are you sure you want to delete this list?",
        confirmText: "Delete",
        action: () => dispatch(deleteCompare([id])),
      })
    );
  };

  const openSuggestedPopup = (keyword: string) => {
    dispatch(showSuggestedPopup(keyword));
  };

  const onDeleteSuggestedList = (id: number) => {
    dispatch(
      showConfirmPopup({
        title: "Delete?",
        body: "Are you sure you want to delete this list?",
        confirmText: "Delete",
        action: () => dispatch(deleteSuggested([id])),
      })
    );
  };

  const openCompetitorPopup = (url: string) => {
    dispatch(showCompetitorPopup(url));
  };

  const onDeleteCompetitorList = (id: number) => {
    dispatch(
      showConfirmPopup({
        title: "Delete?",
        body: "Are you sure you want to delete this list?",
        confirmText: "Delete",
        action: () => dispatch(deleteCompetitor([id])),
      })
    );
  };

  const handleCreateCampaignClick = () => {
    if (!user.website || !user.businessId) {
      dispatch(showAdvertiserForm());
    } else {
      dispatch(showBuildCampaign());
    }
  };

  const isEmpty = !(
    campaigns.data.length ||
    competitor.data.length ||
    compare.data.length ||
    suggested.data.length
  );

  const colors = ["78, 22, 193", "169, 21, 107", "194, 123, 43"];

  const isLoading =
    campaigns.loading || (competitor.loading && compare.loading && suggested.loading);

  const navigate = useNavigate();

  return (
    <HomeStyled isEmpty={isEmpty}>
      <h2 className="page-title">Home</h2>
      {!isLoading ? (
        <div className="home-wrapper">
          <div className="keyword-tools-wrapper">
            <h6 className="keyword-tools-title">Keywords Tools</h6>

            {isEmpty && (
              <ul className="keyword-tools">
                <li className="keyword-tool-item">
                  <span>Search for suggestion</span>
                  <SuggestedIcon />
                  <Button onClick={() => navigate("/app/keywords/suggested")} color="purple" full>
                    Suggested
                  </Button>
                </li>

                <li className="keyword-tool-item">
                  <span>Start to compete</span>
                  <CompetitorIcon />
                  <Button onClick={() => navigate("/app/keywords/competitor")} color="gold" full>
                    Competitor
                  </Button>
                </li>

                <li className="keyword-tool-item">
                  <span>Start to compare</span>
                  <CompareIcon />
                  <Button onClick={() => navigate("/app/keywords/signals")} full>
                    Signal
                  </Button>
                </li>
              </ul>
            )}

            <div className="keywords-history">
              <Accordion
                trigger={<span className="recently-title">Recently Suggested</span>}
                content={
                  suggested.loading ? (
                    <div className="loading-wrapper">
                      <Spinner />
                    </div>
                  ) : suggested.data.length ? (
                    <ul className="history-list">
                      {suggested.data.map((suggestedItem: { id: number; value: string }) => {
                        return (
                          <KeywordSetItem
                            id={suggestedItem.id.toString()}
                            onDeleteList={() => onDeleteSuggestedList(suggestedItem.id)}
                            openPopup={() => openSuggestedPopup(suggestedItem.value)}
                            key={suggestedItem.id}
                          >
                            <Badge
                              color={`rgb(${colors[1]},0.${Math.floor(Math.random() * 6) + 3} )`}
                              text={suggestedItem.value}
                            />
                          </KeywordSetItem>
                        );
                      })}
                    </ul>
                  ) : (
                    <div className="empty-text">
                      <span>You haven't searched for any suggested keywords yet.</span>
                      <LinkText onClick={() => dispatch(showSuggestedPopup())}>
                        <u>Ask for suggestions</u>
                      </LinkText>
                    </div>
                  )
                }
              />
            </div>

            <div className="keywords-history">
              <Accordion
                trigger={<span className="recently-title">Recently Compare</span>}
                content={
                  compare.loading ? (
                    <div className="loading-wrapper">
                      <Spinner />
                    </div>
                  ) : compare.data.length ? (
                    <ul className="history-list">
                      {compare.data.map((compareItem) => {
                        return (
                          <KeywordSetItem
                            id={compareItem.id}
                            onDeleteList={() => onDeleteCompareList(compareItem.id)}
                            openPopup={() =>
                              openComparePopup(
                                compareItem.keywords.map((keyword) => keyword.keyword)
                              )
                            }
                            key={compareItem.id}
                          >
                            {compareItem.keywords.map((keyword) => {
                              return (
                                <Badge
                                  key={keyword.id}
                                  color={`rgb(${colors[0]},0.${
                                    Math.floor(Math.random() * 6) + 3
                                  } )`}
                                  text={keyword.keyword}
                                />
                              );
                            })}
                          </KeywordSetItem>
                        );
                      })}
                    </ul>
                  ) : (
                    <div className="empty-text">
                      <span>You haven't compared any keywords yet.</span>
                      <LinkText onClick={() => dispatch(showComparePopup())}>
                        <u>Compare keywords</u>
                      </LinkText>
                    </div>
                  )
                }
              />
            </div>

            <div className="keywords-history">
              <Accordion
                trigger={<span className="recently-title">Recently Searched by Competitor</span>}
                content={
                  competitor.loading ? (
                    <div className="loading-wrapper">
                      <Spinner />
                    </div>
                  ) : competitor.data.length ? (
                    <ul className="history-list">
                      {competitor.data.map((competitorItem: { id: number; value: string }) => {
                        return (
                          <KeywordSetItem
                            id={competitorItem.id.toString()}
                            onDeleteList={() => onDeleteCompetitorList(competitorItem.id)}
                            openPopup={() => openCompetitorPopup(competitorItem.value)}
                            key={competitorItem.id}
                          >
                            <Badge
                              color={`rgb(${colors[2]},0.${Math.floor(Math.random() * 6) + 3} )`}
                              text={competitorItem.value}
                            />
                          </KeywordSetItem>
                        );
                      })}
                    </ul>
                  ) : (
                    <div className="empty-text">
                      <span>You haven't made any search based on competitors just yet.</span>
                      <LinkText onClick={() => dispatch(showCompetitorPopup())}>
                        <u>Search by competitor</u>
                      </LinkText>
                    </div>
                  )
                }
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="page-loading">
          <Spinner />
        </div>
      )}

      {!isEmpty && !isLoading && (
        <div className="bottom-actions">
          <div className="home-actions">
            <Button
              data-testid="suggested-button"
              onClick={() => dispatch(showSuggestedPopup())}
              icon={<SuggestedIcon />}
              color="purple"
            >
              <span className="action-text">Suggested</span>
            </Button>

            <Button
              data-testid="compare-button"
              onClick={() => dispatch(showComparePopup())}
              icon={<CompareIcon />}
            >
              <span className="action-text">Compare</span>
            </Button>

            <Button
              data-testid="competitor-button"
              onClick={() => dispatch(showCompetitorPopup())}
              icon={<CompetitorIcon />}
              color="gold"
            >
              <span className="action-text">Competitor</span>
            </Button>
          </div>

          <div className="campaign-actions">
            <Button
              data-testid="create-campaign-button"
              onClick={handleCreateCampaignClick}
              icon={<PlusIcon />}
              color="blue"
            >
              <span className="action-text">New Campaign</span>
            </Button>
          </div>
        </div>
      )}
    </HomeStyled>
  );
};

export default connector(Home);
