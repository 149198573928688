interface IChangePasswordState {
  loading: boolean;
  error: {
    newPassword: Array<string>;
  } | null;
}

const state = {
  loading: false,
  error: null,
} as IChangePasswordState;

export default state;
