import { IPostCampaignData } from "constants/utility/campaign";
import { IErrorMessage } from "../../services/types";
import * as types from "../action-types/campaign.types";
import { IRefIdData } from "../states/admin.state";
import { IPaymentDetail, IPaymentDetailData } from "../states/payments.state";
import { IDuplicateCampaignPayload } from "../states/campaign.state";
import { ICampaign } from "../states/campaigns.state";
import { CATEGORY_KEY_VALUES_OBJECT } from "constants/category";

export function updateCampaignListRequest() {
  return {
    type: types.UPDATE_CAMPAIGNS_LIST_REQUEST,
  };
}

export function updateCampaignListSuccess() {
  return {
    type: types.UPDATE_CAMPAIGNS_LIST_SUCCESS,
  };
}

// get campaign by id

export function getCampaignById(id: string): types.GetCampaignByIdAction {
  return {
    type: types.GET_CAMPAIGN_BY_ID,
    id,
  };
}

export function getCampaignByIdRequest(): types.GetCampaignByIdRequestAction {
  return {
    type: types.GET_CAMPAIGN_BY_ID_REQUEST,
  };
}

export function getCampaignByIdSuccess(campaign: ICampaign): types.GetCampaignByIdSuccessAction {
  return {
    type: types.GET_CAMPAIGN_BY_ID_SUCCESS,
    campaign,
  };
}

export function getCampaignByIdFailure(error: IErrorMessage): types.GetCampaignByIdFailureAction {
  return {
    type: types.GET_CAMPAIGN_BY_ID_FAILURE,
    error,
  };
}

// Update payment status

export function resetCurrentCampaign() {
  return {
    type: types.RESET_CURRENT_CAMPAIGN,
  };
}

export function updatePaymentStatus(payload: IPaymentDetailData) {
  return {
    type: types.UPDATE_PAYMENT_STATUS,
    payload,
  };
}

export function updatePaymentStatusRequest(): types.UpdatePaymentStatusRequestAction {
  return {
    type: types.UPDATE_PAYMENT_STATUS_REQUEST,
  };
}

export function updatePaymentStatusSuccess(
  payload: IPaymentDetail
): types.UpdatePaymentStatusSuccessAction {
  return {
    type: types.UPDATE_PAYMENT_STATUS_SUCCESS,
    payload,
  };
}

export function updatePaymentStatusFailure(
  error: IErrorMessage
): types.UpdatePaymentStatusFailureAction {
  return {
    type: types.UPDATE_PAYMENT_STATUS_FAILURE,
    error,
  };
}

// Update setting

export function setting(payload: IRefIdData) {
  return {
    type: types.UPDATE_REF_ID,
    payload,
  };
}

export function settingRequest(): types.settingRequestAction {
  return {
    type: types.UPDATE_REF_ID_REQUEST,
  };
}
export function settingSuccess(payload: {
  message: string;
  state: string;
  portalUrl?: string;
}): types.settingSuccessAction {
  return {
    type: types.UPDATE_REF_ID_SUCCESS,
    payload,
  };
}

export function settingFailure(error: IErrorMessage): types.settingFailureAction {
  return {
    type: types.UPDATE_REF_ID_FAILURE,
    error,
  };
}

export function removeMessage() {
  return {
    type: types.REMOVE_MESSAGE,
  };
}

// duplicate

export function duplicateCampaign(payload: IDuplicateCampaignPayload) {
  return {
    type: types.DUPLICATE_CAMPAIGN,
    payload,
  };
}

export function duplicateCampaignRequest(): types.duplicateCampaignRequestAction {
  return {
    type: types.DUPLICATE_CAMPAIGN_REQUEST,
  };
}

export function duplicateCampaignSuccess(
  payload?: ICampaign
): types.duplicateCampaignSuccessAction {
  return {
    type: types.DUPLICATE_CAMPAIGN_SUCCESS,
    payload,
  };
}

export function duplicateCampaignFailure(
  error: IErrorMessage
): types.duplicateCampaignFailureAction {
  return {
    type: types.DUPLICATE_CAMPAIGN_FAILURE,
    error,
  };
}

// edit

export function editCampaign(
  payload: IPostCampaignData & {
    includes: CATEGORY_KEY_VALUES_OBJECT;
    excludes: CATEGORY_KEY_VALUES_OBJECT;
  }
): types.editCampaignAction {
  return {
    type: types.EDIT_CAMPAIGN,
    payload,
  };
}

export function editCampaignRequest(): types.editCampaignRequestAction {
  return {
    type: types.EDIT_CAMPAIGN_REQUEST,
  };
}

export function editCampaignSuccess(): types.editCampaignSuccessAction {
  return {
    type: types.EDIT_CAMPAIGN_SUCCESS,
  };
}

export function editCampaignFailure(error: IErrorMessage): types.editCampaignFailureAction {
  return {
    type: types.EDIT_CAMPAIGN_FAILURE,
    error,
  };
}

// link beeswax

export function linkCampaign(payload: string): types.linkCampaignAction {
  return {
    type: types.LINK_CAMPAIGN,
    payload,
  };
}

export function linkCampaignRequest(): types.linkCampaignRequestAction {
  return {
    type: types.LINK_CAMPAIGN_REQUEST,
  };
}

export function linkCampaignSuccess(payload: { status: string }): types.linkCampaignSuccessAction {
  return {
    type: types.LINK_CAMPAIGN_SUCCESS,
    payload,
  };
}

export function linkCampaignFailure(error: IErrorMessage): types.linkCampaignFailureAction {
  return {
    type: types.LINK_CAMPAIGN_FAILURE,
    error,
  };
}

// create

export function createCampaign(
  payload: IPostCampaignData & {
    keywords: Array<string>;
    includes: CATEGORY_KEY_VALUES_OBJECT;
    excludes: CATEGORY_KEY_VALUES_OBJECT;
  }
): types.createCampaignAction {
  return {
    type: types.CREATE_CAMPAIGN,
    payload,
  };
}

export function createCampaignRequest(): types.createCampaignRequestAction {
  return {
    type: types.CREATE_CAMPAIGN_REQUEST,
  };
}

export function createCampaignSuccess(
  payload: IPostCampaignData
): types.createCampaignSuccessAction {
  return {
    type: types.CREATE_CAMPAIGN_SUCCESS,
    payload,
  };
}

export function createCampaignFailure(error: IErrorMessage): types.createCampaignFailureAction {
  return {
    type: types.CREATE_CAMPAIGN_FAILURE,
    error,
  };
}
