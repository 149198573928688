import styled, { css } from "styled-components/macro";

export const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 450px;
`;

export const KeywordsTableWrapper = styled.div``;

export const SortStatusArrow = styled.span<{ isAscending: boolean }>`
  color: ${({ theme }) => theme.dark};
  position: relative;
  margin: 0 13px;

  &:before {
    content: "⬆";
    opacity: ${({ isAscending }) => (isAscending ? 1 : 0.5)};
    position: absolute;
  }

  &:after {
    content: "⬇";
    opacity: ${({ isAscending }) => (isAscending ? 0.5 : 1)};
    position: absolute;
    right: -4px;
  }
`;

export const ResultTable = styled.div<{ fullHeight?: boolean; size?: "sm" | "md" }>`
  width: 100%;
  max-height: ${({ fullHeight }) => (fullHeight ? "100%" : "450px")};
  overflow-x: hidden;
  overflow-y: auto;

  .select-all {
    width: 100px;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    border: 0;
    font-size: 12px;
    align-items: center;
    height: 37px;
  }

  table {
    width: 100%;

    thead {
      border-color: ${({ theme }) => theme.keywordsTableBorder};
    }

    .col-monthly {
      min-width: 150px;
    }
    .col-network {
      min-width: 150px;
    }
    .col-score {
      min-width: 150px;
    }
    .col-google {
      /* min-width: 100px; */
    }
    .col-rad-cpc {
      padding-right: 20px;
      min-width: 100px;
    }

    th,
    td {
      font-size: 0.75em;
      font-weight: 400;
      letter-spacing: 0.5px;
      vertical-align: middle;
    }

    td {
      color: ${({ theme }) => theme.tableContent};
      height: 48px;
    }

    .keyword-name {
      word-break: break-word;
      font-size: 12px;
      width: 100%;
    }

    .network-matches {
      color: ${({ theme }) => theme.network};
    }

    .rad-cpc {
      color: ${({ theme }) => theme.cpc} !important;
      font-weight: ${({ theme }) => theme.weight.bold};
      padding-right: 20px;
    }

    .score {
      color: ${({ theme }) => theme.score} !important;
      font-weight: ${({ theme }) => theme.weight.bold};
    }

    th {
      padding: 5px;
      white-space: nowrap;
      width: 150px;
      position: sticky;
      height: 45px;
      top: 0;
      z-index: 8;
      cursor: pointer;
      color: ${({ theme }) => theme.contrast};

      &:first-child {
        min-width: 80px;
      }

      .brandText {
        vertical-align: middle;
        display: inline-block;
      }
    }

    .brand-icon {
      margin-right: 8px;
      height: 16px;
      width: 16px;

      path {
        fill: ${({ theme }) => theme.cpc};
      }
    }

    .googleIcon {
      margin-right: 8px;
      height: 16px;
      width: 16px;
    }

    tbody {
      tr:not(.placeholderRow) {
        cursor: pointer;
      }

      tr {
        border-bottom: 1px solid ${({ theme }) => theme.keywordsTableBorder};
        /* &:hover {
          td {
            background: rgb(54, 62, 52);
          }
        } */
      }

      /* tr:nth-of-type(even) {
        td {
          background: #252525;
        }
      } */
    }

    .difficultyHigh {
      color: #e32a2a;
    }

    .difficultyMedium {
      color: #eccf27;
    }

    .difficultyLow {
      color: #6ad14e;
    }
  }

  ${({ size }) =>
    size === "sm" &&
    css`
      table {
        width: 100%;

        thead {
          border-color: ${({ theme }) => theme.keywordsTableBorder};

          th {
            height: 30px;

            &:first-child {
              min-width: 25px;
            }
          }
        }

        tbody {
          td {
            height: 30px;
          }

          .rad-cpc {
            padding-right: 0;
          }
        }

        .keyword-name {
          font-size: 10px;
          max-width: 100px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .col-monthly {
          min-width: 105px;
        }
        .col-network {
          min-width: 105px;
        }
        .col-score {
          min-width: 45px;
        }
        .col-google {
          /* min-width: 100px; */

          > img {
            width: 12px;
            height: 12px;
          }
        }
        .col-rad-cpc {
          padding-right: 0px;
          min-width: 47px;

          > img {
            width: 12px !important;
            height: 12px !important;
          }
        }
      }
    `}
`;
