import React from "react";
import { Image } from "react-bootstrap";
import styled from "styled-components/macro";
import valid from "card-validator";
import { AVAILABLE_CARD_TYPE } from "./constants";
import Input from "components/Input";
import { FormikProps } from "formik";
import { ILocation } from "store/states/campaigns.state";
import { IValidFile } from "components/Dropzone";
import { IBUDGET_FORM } from "store/actions/payment.actions";
import { IPostCampaignData } from "constants/utility/campaign";

const jcb = require("assets/credit-cards/jcb.svg").default;
const master = require("assets/credit-cards/master-card.svg").default;
const discover = require("assets/credit-cards/discover.svg").default;
const visa = require("assets/credit-cards/visa.svg").default;
const amex = require("assets/credit-cards/american-express.svg").default;
const diners = require("assets/credit-cards/diners-club.svg").default;

const CreditCardFormStyled = styled.div`
  .credit-card-sets {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    flex-wrap: wrap;

    img {
      background: #f8f8f8;
      border-radius: 2px;
      width: 100%;
      max-width: 50px;
    }
  }

  .credit-card {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .card-type {
      position: absolute;
      right: 20px;
      top: 8px;
      z-index: 2;
      color: #ffffff;
      font-weight: ${({ theme }) => theme.weight.bold};

      > img {
        width: 30px;
        height: 30px;
      }
    }

    .input-group {
      display: flex;
      gap: 10px;
      flex-wrap: nowrap;

      .input-column {
        flex-basis: 0;
        flex-grow: 1;
      }
    }
  }
`;

export enum ENUM_CREDIT_CARDS {
  JCB = "jcb",
  MASTER = "mastercard",
  DISCOVER = "discover",
  VISA = "visa",
  AMEX = "american-express",
  DINER = "diners-club",
}

type CARD =
  | ENUM_CREDIT_CARDS.DINER
  | ENUM_CREDIT_CARDS.JCB
  | ENUM_CREDIT_CARDS.MASTER
  | ENUM_CREDIT_CARDS.DISCOVER
  | ENUM_CREDIT_CARDS.VISA
  | ENUM_CREDIT_CARDS.AMEX;

const CreditCardForm = React.forwardRef<
  HTMLDivElement,
  { formikProps: FormikProps<IPostCampaignData> }
>(({ formikProps }, forwardedRef) => {
  const { values, handleChange, touched, errors, handleBlur } = formikProps;
  const { card } = valid.number(values.card.cardNumber);
  const AMEXGap = `(.{0,4})(.{0,6})(.{0,5})`;
  const normalGap = `(.{4})`;
  const isAmex = card && card.type === AVAILABLE_CARD_TYPE.AMERICAN_EXPRESS;

  const reg = new RegExp(isAmex ? AMEXGap : normalGap, "g");

  const CARD_LIST: Record<CARD, string> = {
    jcb: jcb,
    mastercard: master,
    discover: discover,
    visa: visa,
    "american-express": amex,
    "diners-club": diners,
  };

  return (
    <CreditCardFormStyled ref={forwardedRef}>
      <form className="credit-card">
        <Input
          data-testid="card-holder"
          dark
          noBorder
          maxLength={26}
          onChange={handleChange}
          name="card.fullName"
          type="text"
          value={values.card.fullName}
          placeholder="Name on Card"
          onBlur={handleBlur}
          error={errors.card?.fullName}
          touched={touched.card?.fullName}
        />
        <Input
          data-testid="card-number"
          dark
          noBorder
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const { target } = e;
            let position = target.selectionEnd;
            const { length } = target.value;

            target.value = target.value
              .replace(/[^\dA-Z]/g, "")
              .replace(reg, `$1 ${isAmex ? "$2 $3 " : ""}`)
              .trim();

            if (position) {
              target.selectionEnd = position +=
                target.value.charAt(position - 1) === " " &&
                target.value.charAt(length - 1) === " " &&
                length !== target.value.length
                  ? 1
                  : 0;
            }
            // maintain input selection to current position

            handleChange(e);
          }}
          name="card.cardNumber"
          type="text"
          value={values.card.cardNumber}
          placeholder="Credit Card Number"
          maxLength={(card && card.lengths[0] + card.gaps.length) || 19}
          onBlur={handleBlur}
          error={errors.card?.cardNumber}
          touched={touched.card?.cardNumber}
        >
          {card && CARD_LIST[card.type as CARD] && (
            <div className="card-type">
              <img src={CARD_LIST[card.type as CARD]} alt="card" />
            </div>
          )}
        </Input>

        <div className="input-group">
          <div className="input-column">
            <Input
              data-testid="card-exp"
              noBorder
              onKeyDown={(e: any) => {
                if (e.key === "Backspace") {
                  if (e.target.value[e.target.value.length - 2] === "/") {
                    e.target.value = e.target.value.slice(0, 3);
                  }

                  if (e.target.selectionStart === 1 || e.target.selectionStart === 2) {
                    e.preventDefault();
                    e.target.value = "";
                  }
                } else if (/^\d+$/.test(e.key)) {
                  if (e.target.selectionStart === 0) {
                    if (e.key > 1) {
                      e.target.value = `0`;
                    }
                  }
                  if (e.target.value.length === 2) {
                    e.target.value = `${e.target.value}/`;
                  }
                } else {
                  e.preventDefault();
                }
              }}
              dark
              maxLength={5}
              onChange={handleChange}
              name="card.expirationDate"
              type="text"
              value={values.card.expirationDate}
              placeholder="Exp       00/00"
              onBlur={handleBlur}
              error={errors.card?.expirationDate}
              touched={touched.card?.expirationDate}
            />
          </div>

          <div className="input-column">
            <Input
              data-testid="card-security"
              dark
              noBorder
              type="text"
              pattern="\d*"
              maxLength={(card && card.code.size) || 3}
              onChange={handleChange}
              name="card.securityCode"
              value={values.card.securityCode.replace(/\D/g, "")}
              placeholder={(card && card.code.name) || "Security Code"}
              onBlur={handleBlur}
              error={errors.card?.securityCode}
              touched={touched.card?.securityCode}
            />
          </div>
        </div>
        {/* <div className="credit-card-sets">
            <Image src={cirrusIcon.default} />
            <Image src={jcbIcon.default} />
            <Image src={maestroIcon.default} />
            <Image src={masterIcon.default} />
            <Image src={visaIcon.default} />
          </div> */}
      </form>
    </CreditCardFormStyled>
  );
});

CreditCardForm.displayName = "CreditCard";

export default CreditCardForm;
