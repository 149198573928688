import { IErrorMessage } from "../../services/types";

// save

export const SAVE_SUGGESTED = "suggested-actions/SAVE_SUGGESTED";

export interface SaveSuggestedAction {
  type: typeof SAVE_SUGGESTED;
  payload: string;
}

export const SAVE_SUGGESTED_REQUEST = "suggested-actions/SAVE_SUGGESTED_REQUEST";
export interface SaveSuggestedRequestAction {
  type: typeof SAVE_SUGGESTED_REQUEST;
}

export const SAVE_SUGGESTED_SUCCESS = "suggested-actions/SAVE_SUGGESTED_SUCCESS";
export interface SaveSuggestedSuccessAction {
  type: typeof SAVE_SUGGESTED_SUCCESS;
  payload: { id: number; value: string };
}

export const SAVE_SUGGESTED_FAILURE = "suggested-actions/SAVE_SUGGESTED_FAILURE";
export interface SaveSuggestedFailureAction {
  type: typeof SAVE_SUGGESTED_FAILURE;
  error: IErrorMessage;
}

// get

export const GET_SUGGESTED = "suggested-actions/GET_SUGGESTED";

export interface GetSuggestedAction {
  type: typeof GET_SUGGESTED;
}

export const GET_SUGGESTED_REQUEST = "suggested-actions/GET_SUGGESTED_REQUEST";
export interface GetSuggestedRequestAction {
  type: typeof GET_SUGGESTED_REQUEST;
}

export const GET_SUGGESTED_SUCCESS = "suggested-actions/GET_SUGGESTED_SUCCESS";
export interface GetSuggestedSuccessAction {
  type: typeof GET_SUGGESTED_SUCCESS;
  payload: any;
}

export const GET_SUGGESTED_FAILURE = "suggested-actions/GET_SUGGESTED_FAILURE";
export interface GetSuggestedFailureAction {
  type: typeof GET_SUGGESTED_FAILURE;
  error: IErrorMessage;
}

// delete

export const DELETE_SUGGESTED = "suggested-actions/DELETE_SUGGESTED";

export interface DeleteSuggestedAction {
  type: typeof DELETE_SUGGESTED;
  payload: Array<number>;
}

export const DELETE_SUGGESTED_REQUEST = "suggested-actions/DELETE_SUGGESTED_REQUEST";
export interface DeleteSuggestedRequestAction {
  type: typeof DELETE_SUGGESTED_REQUEST;
}

export const DELETE_SUGGESTED_SUCCESS = "suggested-actions/DELETE_SUGGESTED_SUCCESS";
export interface DeleteSuggestedSuccessAction {
  type: typeof DELETE_SUGGESTED_SUCCESS;
  payload: any;
}

export const DELETE_SUGGESTED_FAILURE = "suggested-actions/DELETE_SUGGESTED_FAILURE";
export interface DeleteSuggestedFailureAction {
  type: typeof DELETE_SUGGESTED_FAILURE;
  error: IErrorMessage;
}
