// get

import { IErrorMessage, IUsersFilters } from "../../services/types";
import { IUserAuthentication } from "../states/user.state";

export const GET_USERS = "users-actions/GET_USERS";
export interface GetUsersAction {
  type: typeof GET_USERS;
  payload: IUsersFilters;
}

export const GET_USERS_REQUEST = "users-actions/GET_USERS_REQUEST";
export interface GetUsersRequestAction {
  type: typeof GET_USERS_REQUEST;
}

export const GET_USERS_SUCCESS = "users-actions/GET_USERS_SUCCESS";
export interface GetUsersSuccessAction {
  type: typeof GET_USERS_SUCCESS;
  users: Array<IUserAuthentication>;
  total: number;
}

export const GET_USERS_FAILURE = "users-actions/GET_USERS_FAILURE";
export interface GetUsersFailureAction {
  type: typeof GET_USERS_FAILURE;
  error: IErrorMessage;
}

// update

export const UPDATE_USER = "users-actions/UPDATE_USER";

export interface UpdateUserAction {
  type: typeof UPDATE_USER;
  payload: IUserAuthentication;
}

export const UPDATE_USER_REQUEST = "users-actions/UPDATE_USER_REQUEST";
export interface UpdateUserRequestAction {
  type: typeof UPDATE_USER_REQUEST;
}

export const UPDATE_USER_SUCCESS = "users-actions/UPDATE_USER_SUCCESS";
export interface UpdateUserSuccessAction {
  type: typeof UPDATE_USER_SUCCESS;
  user: IUserAuthentication;
}

export const UPDATE_USER_FAILURE = "users-actions/UPDATE_USER_FAILURE";
export interface UpdateUserFailureAction {
  type: typeof UPDATE_USER_FAILURE;
  error: IErrorMessage;
}

// delete

export const DELETE_USERS = "users-actions/DELETE_USERS";
export interface DeleteUsersAction {
  type: typeof DELETE_USERS;
  payload: {
    ids: Array<string>;
    currentFilters: IUsersFilters;
  };
}

export const DELETE_USERS_REQUEST = "users-actions/DELETE_USERS_REQUEST";
export interface DeleteUsersRequestAction {
  type: typeof DELETE_USERS_REQUEST;
}

export const DELETE_USERS_SUCCESS = "users-actions/DELETE_USERS_SUCCESS";
export interface DeleteUsersSuccessAction {
  type: typeof DELETE_USERS_SUCCESS;
  ids: Array<string>;
}

export const DELETE_USERS_FAILURE = "users-actions/DELETE_USERS_FAILURE";
export interface DeleteUsersFailureAction {
  type: typeof DELETE_USERS_FAILURE;
  error: IErrorMessage;
}
