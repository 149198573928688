const popupState = {
  showAdvertiserForm: {
    show: false,
  },
  showBetaAlert: {
    show: true,
  },
  showForgetPasswordPopup: {
    show: false,
  },
  showCampaignDetailPopup: {
    show: false,
    id: null,
  },
  showChangePasswordPopup: {
    show: false,
  },
  showConfirmPopup: {
    show: false,
    action: null,
    title: "",
    body: "",
    confirmText: "",
  },
  showExportPopup: {
    show: false,
    type: "keywords",
    data: {
      keywords: [],
    },
  },
  showComparePopup: {
    show: false,
    keywords: [],
  },
  showCompetitorPopup: {
    show: false,
    website: "",
  },
  showSuggestedPopup: {
    show: false,
    keyword: "",
  },
  screenLoading: false,
  showIntroPopup: false,
  showSubscriptionRequiredPopup: false,
  showCampaignPopup: false,
  showKeywordListPopup: false,
  showNewCampaignPopup: false,
  showBannerContent: null,
  showBuildCampaign: {
    show: false,
    id: null,
  },
  showCreativeForm: false,
  showEditCampaign: {
    show: false,
    step: null,
  },
  showImportKeywords: {
    show: false,
    keywords: [],
  },
  values: {
    keywords: null,
  },
  campaign: {
    id: "",
    name: "",
  },
} as PopupState;

interface PopupState {
  showBetaAlert: {
    show: boolean;
  };
  showAdvertiserForm: {
    show: boolean;
  };
  showForgetPasswordPopup: {
    show: boolean;
  };
  showCampaignDetailPopup: {
    show: boolean;
    id: string | null;
  };
  showChangePasswordPopup: {
    show: boolean;
  };
  showConfirmPopup: {
    show: boolean;
    action: (() => void) | null;
    title: string;
    body?: string;
    confirmText: string;
  };
  showExportPopup: {
    show: boolean;
    type: "campaigns" | "keywords" | "performance";
    data: {
      keywords: Array<string>;
      campaign?: {
        startDate: Date;
        endDate: Date;
        id?: string;
      };
    };
  };
  showBuildCampaign: {
    show: boolean;
    id: null | string;
  };
  showSuggestedPopup: {
    show: boolean;
    keyword: string;
  };
  showCompetitorPopup: {
    show: boolean;
    website: string;
  };
  showComparePopup: {
    show: boolean;
    keywords: Array<string>;
  };
  screenLoading: boolean;
  showEditCampaign: {
    show: boolean;
    step: null | number;
  };
  showIntroPopup: boolean;
  showSubscriptionRequiredPopup: boolean;
  showCampaignPopup: boolean;
  showKeywordListPopup: boolean;
  showImportKeywords: {
    show: boolean;
    keywords: Array<string>;
  };
  showBannerContent: {
    id: null | number;
  } | null;
  showCreativeForm: boolean;
  values: {
    keywords: Array<string> | null;
    pageIndex?: number;
  };
  showNewCampaignPopup: boolean;
  pageIndex?: number;
  campaign: {
    name: string;
    id: string;
  };
}

export default popupState;
