import { useEffect, useState } from "react";
export const useDarkMode = () => {
  const [theme, setTheme] = useState<"light" | "dark">("light");
  const [mountedComponent, setMountedComponent] = useState(false);

  const setMode = (mode: "light" | "dark") => {
    window.localStorage.setItem("theme", mode);
    setTheme(mode);
  };

  const themeToggler = () => {
    theme === "light" ? setMode("dark") : setMode("light");
  };

  useEffect(() => {
    const localTheme = window.localStorage.getItem("theme");
    localTheme ? setTheme(localTheme as "light" | "dark") : setMode("light");
    setMountedComponent(true);
  }, []);

  return [theme, themeToggler, mountedComponent] as ["light" | "dark", () => void, boolean];
};
