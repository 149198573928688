import { FILTER_INPUT } from "constants/types";

export enum FILTER_COLUMN {
  ID = "id",
  PAYMENT_GATEWAY = "paymentGateway",
  STATUS = "state",
  CREATED_AT = "created_at",
  UPDATED_AT = "updated_at",
  CAMPAIGN_ID = "campaignId",
  AMOUNT = "amount",
  CAMPAIGN_OWNER = "ownerName",
  CAMPAIGN_NAME = "campaignName",
}

export interface IDateRange {
  startDate: Date;
  endDate: Date;
}

export const FILTER_COLUMN_TEXT = {
  // [FILTER_COLUMN.USERNAME]: "Username",
  [FILTER_COLUMN.ID]: "Payment ID",
  [FILTER_COLUMN.PAYMENT_GATEWAY]: "Payment Type",
  [FILTER_COLUMN.CREATED_AT]: "Create Date",
  [FILTER_COLUMN.UPDATED_AT]: "Updated Date",
  [FILTER_COLUMN.STATUS]: "Payment Status",
  [FILTER_COLUMN.CAMPAIGN_NAME]: "Campaign Name",
  [FILTER_COLUMN.CAMPAIGN_OWNER]: "Campaign Owner",
  [FILTER_COLUMN.AMOUNT]: "Amount",
};

export const FILTER_COLUMN_ITEMS = [
  // { key: FILTER_COLUMN.USERNAME, text: FILTER_COLUMN_TEXT[FILTER_COLUMN.USERNAME], input: "text" },
  {
    key: FILTER_COLUMN.ID,
    text: FILTER_COLUMN_TEXT[FILTER_COLUMN.ID],
    input: FILTER_INPUT.TEXT,
  },
  {
    key: FILTER_COLUMN.AMOUNT,
    text: FILTER_COLUMN_TEXT[FILTER_COLUMN.AMOUNT],
    input: FILTER_INPUT.NUMBER_RANGE,
  },
  {
    key: FILTER_COLUMN.PAYMENT_GATEWAY,
    text: FILTER_COLUMN_TEXT[FILTER_COLUMN.PAYMENT_GATEWAY],
    input: FILTER_INPUT.SELECT,
  },
  {
    key: FILTER_COLUMN.CREATED_AT,
    text: FILTER_COLUMN_TEXT[FILTER_COLUMN.CREATED_AT],
    input: FILTER_INPUT.DATE_RANGE,
  },
  {
    key: FILTER_COLUMN.UPDATED_AT,
    text: FILTER_COLUMN_TEXT[FILTER_COLUMN.UPDATED_AT],
    input: FILTER_INPUT.DATE_RANGE,
  },
  {
    key: FILTER_COLUMN.CAMPAIGN_NAME,
    text: FILTER_COLUMN_TEXT[FILTER_COLUMN.CAMPAIGN_NAME],
    input: FILTER_INPUT.TEXT,
  },
  {
    key: FILTER_COLUMN.CAMPAIGN_OWNER,
    text: FILTER_COLUMN_TEXT[FILTER_COLUMN.CAMPAIGN_OWNER],
    input: FILTER_INPUT.TEXT,
  },
  {
    key: FILTER_COLUMN.STATUS,
    text: FILTER_COLUMN_TEXT[FILTER_COLUMN.STATUS],
    input: FILTER_INPUT.SELECT,
  },
];
