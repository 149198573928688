import React from "react";
import styled from "styled-components/macro";
import { Spinner as LoadingSpinner } from "react-bootstrap";

const StyledSpinner = styled.div`
  .spinner-border {
    color: ${({ theme }) => theme.secondary};
  }
`;
interface ISpinnerProps {
  size?: "sm";
}

const Spinner: React.FunctionComponent<ISpinnerProps> = ({ size }) => {
  return (
    <StyledSpinner data-testid="spinner" className="spinner-wrapper">
      <LoadingSpinner size={size} animation="border" />
    </StyledSpinner>
  );
};

export default Spinner;
