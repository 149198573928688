import { IErrorMessage } from "../../services/types";
export interface INewsFeed {
  id: number;
  title: string;
  imageFile: File | null;
  imageUrl: string | null;
  feedCategoryId: number;
  isPublished: boolean;
  editorSchema: string;
  bodyHtml: string;
  publishedDate: string;
  category: {
    id: number;
    name: string;
  };
}

interface INewsFeedState {
  loading: boolean;
  error: IErrorMessage | null;
  data: Array<INewsFeed>;
  current: INewsFeed | null;
  total: number;
}

const state = {
  loading: false,
  error: null,
  data: [],
  current: null,
  total: 0,
} as INewsFeedState;

export default state;
