import React from "react";
import {
  COMPARE_HEADERS,
  COMPARE_SELECT,
} from "modules/Client/pages/CampaignReport/Performance/constants";
import useOnClickOutside from "../../hooks/useClickOutside";
import { MetricSelectorStyled, CompareSelector, Comparator } from "./styled";

interface IMetricSelector {
  color: string;
  children: React.ReactNode[];
  active?: string;
  compare?: string;
  callback: (val: COMPARE_SELECT) => void;
}

const MetricSelector: React.FunctionComponent<IMetricSelector> = ({
  color,
  children,
  active,
  callback,
  compare,
}) => {
  const ref = React.useRef() as React.MutableRefObject<HTMLDivElement>;
  const [isOpen, setOpen] = React.useState(false);

  useOnClickOutside(ref, () => setOpen(false));

  return (
    <MetricSelectorStyled
      ref={ref}
      onClick={() => setOpen((prev) => !prev)}
      open={isOpen}
      color={color}
      data-testid="metric-selectors"
    >
      {children}
      {isOpen && (
        <CompareSelector color={color}>
          {COMPARE_HEADERS.map((head) => {
            const current = !!(head.value === active);
            const disabled = !!(head.value === compare);
            return (
              <Comparator
                data-testid={`metric-selector-${head.value}`}
                active={current}
                disabled={disabled}
                onClick={() => (!current || !disabled) && callback(head.value)}
                key={head.value}
              >
                {head.text}
              </Comparator>
            );
          })}
        </CompareSelector>
      )}
    </MetricSelectorStyled>
  );
};

export default MetricSelector;
