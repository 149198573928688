import React from "react";
import { TooltipProps } from "recharts";
import { NameType, ValueType } from "recharts/types/component/DefaultTooltipContent";
import { numberWithCommas } from "constants/utility/number";
import { ChartTooltipStyled, ChartValue } from "./styled";

const ChartTooltip = ({
  label,
  payload,
}: TooltipProps<ValueType, NameType> & { label?: string }) => {
  return (
    <ChartTooltipStyled>
      {label && <p className="label"> {label} </p>}
      <ul>
        {payload?.map((payload) => {
          return (
            <ChartValue key={payload.dataKey} color={payload.color}>
              <span className="key">{payload.name} :</span>
              <span className="value"> {numberWithCommas(payload.value as number)} </span>
            </ChartValue>
          );
        })}
      </ul>
    </ChartTooltipStyled>
  );
};

export default React.memo(ChartTooltip);
