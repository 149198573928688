import React from "react";
import Checkbox from "components/Checkbox";
import { FormikProps } from "formik";
import TooltipTitle from "components/Tooltip";
import { Step1Styled } from "./styled";
import TextArea from "components/TextArea";
import { goalsStepOne } from "constants/utility/messages";
import Input from "components/Input";
import { IPostCampaignData } from "constants/utility/campaign";

const goals = [
  "Brand awareness",
  "Reach",
  "App Installs",
  "Web Traffic",
  "Engagement",
  "Video views",
  "Lead Generation",
  "Subscriptions",
  "Online Sales",
];

const goalsTitle = [
  "Maximum Impressions",
  "Maximum Impressions",
  "",
  "Maximum CTR",
  "",
  "",
  "Maximum Sign-ups, Sales",
  "Maximum Sign-ups, Sales",
  "Maximum Sign-ups, Sales",
];

interface CampaignStep1Props {
  formikProps: FormikProps<IPostCampaignData>;
}

const Step1 = ({ formikProps }: CampaignStep1Props) => {
  const { errors, handleChange, touched, values, setFieldValue } = formikProps;

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const renderCheckboxWithTitle = (goal: string, index: number) => {
    return (
      <div
        className="goal-checkbox"
        data-testid={`goal-checkbox-${index}`}
        key={goal}
        onClick={() => setFieldValue("goal", goal)}
        // checked={values.goal === goal}
      >
        <Checkbox checked={values.goal === goal} />

        <span className="goal-label">
          <TooltipTitle
            color="rgb(51,51,51)"
            id="title-checkbox"
            background="rgb(27,255,118)"
            content={goalsStepOne[index]}
          >
            {goal}
          </TooltipTitle>
        </span>
      </div>
    );
  };

  const renderCheckbox = (goal: string, index: number) => {
    return (
      <div
        className="goal-checkbox"
        data-testid={`goal-checkbox-${index}`}
        key={goal}
        onClick={() => setFieldValue("goal", goal)}
        // checked={values.goal === goal}
      >
        <Checkbox checked={values.goal === goal} />

        <span className="goal-label">{goal}</span>
      </div>
    );
  };
  return (
    <Step1Styled>
      <div className="field name-campaign">
        <div className="build-campaign-title">
          <span className="input-title">Name Your Campaign</span>
        </div>

        <div className="input-wrapper">
          <Input
            data-testid="campaign-name"
            placeholder="e.g. Name of Campaign*"
            error={errors.name}
            touched={touched.name}
            onChange={handleChange}
            name="name"
            value={values.name}
          />
        </div>
      </div>

      <div className="field goal-selector">
        <div className="input-title">
          <span>Goal</span>
        </div>

        <div className="goal-group">
          {goals.map((goal, index) => {
            if (goalsTitle[index] !== "") {
              return renderCheckboxWithTitle(goal, index);
            } else {
              return renderCheckbox(goal, index);
            }
          })}
        </div>

        {errors.goal && touched.goal && (
          <div className="goal-error errorMessage">{errors.goal}</div>
        )}
      </div>

      <div className="field description">
        <div className="input-title">
          <span>Description</span>
        </div>
        <div className="input-wrapper">
          <TextArea
            data-testid="campaign-description"
            onChange={handleChange}
            name="description"
            value={values.description}
            height={100}
          />
          {errors.description && touched.description && (
            <div className="errorMessage">{errors.description}</div>
          )}
        </div>
      </div>
    </Step1Styled>
  );
};

export default Step1;
