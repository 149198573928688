import Button from "components/Button";
import React from "react";
import { connect, ConnectedProps, useDispatch } from "react-redux";
import { showChangePasswordPopup } from "store/actions/popup.actions";
import { MySettingsStyled } from "./styled";
import DropdownSelect from "components/DropdownSelect";
import Checkbox from "components/Checkbox";
import Switch from "components/Switch";
import { RootState } from "store/store";
import { changeDateFormat, changeLanguage } from "store/actions/settings.actions";
import { useDarkMode } from "hooks/useDarkMode";

interface IMySettingsProps extends PropsFromRedux {
  theme: "light" | "dark";
  themeToggler: () => void;
}

const mapStateToProps = ({ user }: RootState) => ({
  user,
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const MySettings: React.FunctionComponent<IMySettingsProps> = ({ user, theme, themeToggler }) => {
  const dispatch = useDispatch();

  return (
    <MySettingsStyled>
      <b>My Settings</b>

      <div className="settings-wrapper">
        <section className="profile-settings">
          <div className="setting-title">
            <span>Profile settings</span>
          </div>
          <div className="profile-wrapper">
            <div className="profile">
              <div className="thumbnail">
                <span>{user.userName.charAt(0).toUpperCase()}</span>
              </div>
            </div>
            <div className="user-info">
              <div className="field">
                <p className="info-field">Full name</p>
                <p className="info-value">{user.userName}</p>
              </div>

              <div className="field">
                <p className="info-field">Email</p>
                <p className="info-value">{user.email}</p>
              </div>

              <div className="button-wrapper">
                <Button width={250} outline onClick={() => dispatch(showChangePasswordPopup())}>
                  Change Password
                </Button>
              </div>
            </div>
          </div>
        </section>

        <section className="general-settings">
          <div className="setting-wrapper">
            <div className="setting-title">
              <span>General settings</span>
            </div>
          </div>

          <div className="setting-inputs">
            {/* <div className="field">
              <p className="info-field">Language</p>

              <DropdownSelect
                options={[
                  { name: "English", value: "en" },
                  { name: "Thai", value: "th" },
                ]}
                labelField="name"
                valueField="value"
                height={40}
                width={150}
                onChange={(val: Array<{ name: string; value: "en" | "th" }>) =>
                  dispatch(changeLanguage(val[0]))
                }
                values={[settings.language]}
                name="language"
              />
            </div> */}

            {/* <div className="field">
              <p className="info-field">Date format</p>

              <div className="date-format-selectors">
                <div
                  onClick={() => dispatch(changeDateFormat("mm/dd/yyyy"))}
                  className="checkbox-wrapper"
                >
                  <Checkbox checked={settings.dateFormat === "mm/dd/yyyy"} />
                  <span>mm/dd/yyyy</span>
                </div>

                <div
                  onClick={() => dispatch(changeDateFormat("dd/mm/yyyy"))}
                  className="checkbox-wrapper"
                >
                  <Checkbox checked={settings.dateFormat === "dd/mm/yyyy"} />
                  <span>dd/mm/yyyy</span>
                </div>

                <div
                  onClick={() => dispatch(changeDateFormat("yyyy/mm/dd"))}
                  className="checkbox-wrapper"
                >
                  <Checkbox checked={settings.dateFormat === "yyyy/mm/dd"} />
                  <span>yyyy/mm/dd</span>
                </div>
              </div>
            </div> */}

            <div className="field dark-mode">
              <p className="info-field">Dark Mode</p>
              <div className="switch-wrapper">
                <Switch index={1} onChange={() => themeToggler()} isActive={theme === "dark"} />
              </div>
            </div>
          </div>
        </section>
      </div>
    </MySettingsStyled>
  );
};

export default connector(MySettings);
