import React from "react";

const EyesCloseIcon = ({ className }: { className?: string }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39.72 28.84" className={className}>
      <defs>
        <style>{".cls-1{fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;}"}</style>
      </defs>
      <g id="Layer_2" data-name="Layer 2">
        <g id="login">
          <path className="cls-1" d="M26.85,4.66S8.71-.51.5,14.48c0,0,1.07,4.46,8.15,8.26" />
          <path
            className="cls-1"
            d="M30.89,6.21a18.23,18.23,0,0,1,8.33,8.27s-6.13,14.21-26.53,10"
          />
          <line className="cls-1" x1={5.85} y1={28.34} x2={33.81} y2={0.5} />
          <path className="cls-1" d="M13.11,18.34s-2.3-4.38.4-8.22c0,0,3.84-5.32,10-2.3" />
          <path className="cls-1" d="M16.32,21.25s4.4,2.25,8.22-.49c0,0,5.27-3.91,2.19-10" />
        </g>
      </g>
    </svg>
  );
};

export default EyesCloseIcon;
