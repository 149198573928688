import { FIXED_AD_SIZES } from "../../components/Dropzone/constants";

export const gcd = (a: number, b: number): number => {
  return b ? gcd(b, a % b) : a;
};

const aspectRatio = (width: number, height: number) => {
  const divisor = gcd(width, height);
  return `${width / divisor}:${height / divisor}`;
};

export default aspectRatio;

export const validateFile = (file: File) => {
  const validTypes = ["image/jpeg", "image/jpg", "image/png", "image/gif", "image/x-icon"];
  if (validTypes.indexOf(file.type) === -1 || validateFileSize(file.size)) {
    return false;
  }
  return true;
};

export const fileSize = (size: number) => {
  if (size === 0) {
    return "0 Bytes";
  }
  const k = 1024;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  const i = Math.floor(Math.log(size) / Math.log(k));
  return `${parseFloat((size / Math.pow(k, i)).toFixed(2))}${sizes[i]}`;
};

export const validateFileSize = (size: number) => {
  if (size >= Number(process.env.REACT_APP_MAX_FILE_SIZE)) {
    return true;
  }
  return false;
};

export const getAvailableSizes = (
  originWidth: number,
  originHeight: number
): undefined | Array<{ width: number; height: number }> => {
  const ratio = aspectRatio(originWidth, originHeight);
  // const flex = FLEX_AD_SIZES[ratio];
  const fixed = FIXED_AD_SIZES[ratio];

  // if (flex && flex.length === 2) {
  //   const [min, max] = flex;

  //   if (
  //     originHeight >= min.height &&
  //     originWidth >= min.width &&
  //     originHeight <= max.height &&
  //     originWidth <= max.width
  //   ) {
  //     return [{ width: originWidth, height: originHeight }];
  //   }
  // } else if (flex.length === 4) {
  //   const [min1, max1, min2, max2] = flex;

  //   if (
  //     originHeight >= min1.height &&
  //     originWidth >= min1.width &&
  //     originHeight <= max1.height &&
  //     originWidth <= max1.width
  //   ) {
  //     return [{ width: originWidth, height: originHeight }];
  //   }

  //   if (
  //     originHeight >= min2.height &&
  //     originWidth >= min2.width &&
  //     originHeight <= max2.height &&
  //     originWidth <= max2.width
  //   ) {
  //     return [{ width: originWidth, height: originHeight }];
  //   }
  // }
  if (fixed) {
    return fixed.filter((size) => originWidth === size.width && originHeight === size.height);
  }
};
