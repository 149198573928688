import { useState } from "react";
import { convertKeywordV3ArrToObj, IDataForSEOV3 } from "constants/utility/keyword";
import { axiosForReverseAds } from "axiosConfig";
import { handlingKeywordServiceError } from "constants/utility/error";
import IKeywordForKeywordsResponse from "models/keyword-for-keywords-response.interface";

const useKeywordsTableHook = () => {
  const [loading, setLoading] = useState(false);
  const [suggestedKeywords, setSuggestedKeywords] = useState<{ [key: string]: IDataForSEOV3 }>({});

  const resetState = () => {
    setSuggestedKeywords({});
  };

  const fetchSuggestedKeywords = async (
    keyword: string,
    locationName = "United States",
    language = "English"
  ) => {
    try {
      setLoading(true);
      const { data } = await axiosForReverseAds.post<IKeywordForKeywordsResponse>(
        "keywordServices/keywordForKeywordsV3",
        {
          keyword,
          locationName,
          language,
        }
      );
      const keywordObj = convertKeywordV3ArrToObj(data.data);
      setSuggestedKeywords(keywordObj);
    } catch (error: any) {
      handlingKeywordServiceError(error);
    }
    setLoading(false);
  };

  return { suggestedKeywords, loading, fetchSuggestedKeywords, resetState };
};

export default useKeywordsTableHook;
