import React, { useEffect, useState } from "react";
import StyledAudience from "./styled";
import { useLocation, useNavigate, useParams } from "react-router";
import { axiosForReverseAds } from "axiosConfig";
import { Button } from "react-bootstrap";

const Audience = () => {
  const { id } = useParams();
  const loc = useLocation();
  const navigate = useNavigate();
  const LIST_SIZE = 5;

  const [signalList, setSignalList] = useState<
    Array<{ name: string; id: number; location: string; language: string }>
  >([]);

  const [items, setItems] = useState<
    Array<{ name: string; id: number; location: string; language: string }>
  >([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [btnRange, setBtnRange] = useState({ min: 1, max: 3 });

  const paginate = (startIndex: number) => {
    setItems(signalList.slice(startIndex, startIndex + LIST_SIZE));
  };

  const showBtn = (direction: string) => {
    if (direction === "next") {
      if (btnRange.max === Math.ceil(signalList.length / LIST_SIZE)) {
        return;
      } else if (btnRange.max + 3 > Math.ceil(signalList.length / LIST_SIZE)) {
        setBtnRange({ min: btnRange.max + 1, max: Math.ceil(signalList.length / LIST_SIZE) });
      } else {
        setBtnRange({ min: btnRange.max + 1, max: btnRange.max + 3 });
      }
    } else {
      if (btnRange.min === 1) {
        return;
      } else if (btnRange.min - 3 < 1) {
        setBtnRange({ min: 1, max: 3 });
      } else {
        setBtnRange({ min: btnRange.min - 3, max: btnRange.min - 1 });
      }
    }
  };

  useEffect(() => {
    const fetchSignalName = async () => {
      try {
        const response = await axiosForReverseAds.get("get_audience_signals", {
          params: { aud_id: id },
        });
        setSignalList(response.data.data);
        setItems(response.data.data.slice(0, LIST_SIZE));
        if (response.data.data.length < 3) {
          setBtnRange({ min: 1, max: response.data.data.length });
        }
      } catch (e) {
        setItems([]);
      }
    };
    fetchSignalName();
  }, []);

  return (
    <StyledAudience>
      <div className="container">
        <div className="container-head">Audience</div>
        <div className="signal-h1">
          Audience Name: <span className="signal-name">{loc.state?.name}</span>
        </div>
        <div className="btn-container">
          <Button
            className="signals-btn"
            onClick={() => {
              navigate("/app/keywords/audience-update/" + id, {
                state: { audiance_name: loc.state?.name },
              });
            }}
          >
            Add Signal
          </Button>
        </div>
        <div className="container-body">
          <table className="table">
            <thead className="thead">
              <tr>
                <th className="th1">Signal</th>
              </tr>
            </thead>
            <tbody>
              {items.map((item, index) => (
                <tr key={index}>
                  <td
                    className="link"
                    onClick={() => {
                      navigate("/app/keywords/signal/" + item.id, {
                        state: {
                          location: item.location,
                          language: item.language,
                          name: item.name,
                        },
                      });
                    }}
                  >
                    {item.name}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="pagination-container">
          <div
            className={btnRange.min === 1 ? "pagination-option disable" : "pagination-option"}
            onClick={() => {
              showBtn("prev");
            }}
          >
            &laquo;
          </div>
          {(() => {
            const jsxElements = [];
            for (let i = 0, j = 1; i < signalList.length; i += LIST_SIZE, j++) {
              jsxElements.push(
                <div
                  key={i}
                  className={
                    j >= btnRange.min && j <= btnRange.max
                      ? pageNumber === j
                        ? "pagination-active"
                        : "pagination-option"
                      : "hide"
                  }
                  onClick={() => {
                    paginate(i);
                    setPageNumber(j);
                  }}
                >
                  {j}
                </div>
              );
            }
            return jsxElements;
          })()}
          <div
            className={
              btnRange.max * LIST_SIZE >= signalList.length
                ? "pagination-option disable"
                : "pagination-option"
            }
            onClick={() => {
              showBtn("next");
            }}
          >
            &raquo;
          </div>
        </div>
      </div>
    </StyledAudience>
  );
};

export default Audience;
