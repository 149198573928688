import * as Yup from "yup";

export default Yup.object().shape({
  email: Yup.string()
    .required("Required")
    .matches(
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
      "Invalid email address"
    ),
});
