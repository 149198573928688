import React from "react";
import Sidebar from "../Sidebar";
import Topbar from "../Topbar";
import { connect, ConnectedProps } from "react-redux";
import { StyledAuthWrapper, LayoutWrapper } from "./styled";
import Spinner from "components/Spinner";
import { useLocation } from "react-router-dom";
import { RootState } from "store/store";
import { IBottomLink, INavigation, INavigationGroup, ITopbarLink } from "modules/Client/routes";

interface IAuthWrapperProps extends PropsFromRedux {
  children: JSX.Element;
  sidebarLinks: Array<INavigationGroup>;
  topbarRightLinks?: Array<INavigation>;
}

const mapStateToProps = ({ user, popup, sidebar }: RootState) => ({
  popup,
  user,
  sidebar,
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const AuthWrapper: React.FunctionComponent<IAuthWrapperProps> = ({
  children,
  sidebarLinks,
  sidebar,
  // topbarLeftLinks,
  topbarRightLinks,
  user,
  popup,
}) => {
  const token = window.localStorage.getItem("accessToken");
  const location = useLocation();

  return (
    <StyledAuthWrapper isLoading={popup.screenLoading}>
      {popup.screenLoading && (
        <div className="screen-loading">
          <Spinner />
        </div>
      )}
      <LayoutWrapper className="layout-wrapper" isLoading={popup.screenLoading} />
      <Sidebar links={sidebarLinks} />
      <main className="content d-flex flex-column" id="content">
        <Topbar
          // leftLinks={topbarLeftLinks}
          rightLinks={topbarRightLinks}
        />
        {token && !user.accessToken ? (
          <div className="spinner-wrapper">
            <Spinner />
          </div>
        ) : location.pathname.includes("pricing") ? (
          children
        ) : (
          <div className="container-fluid">{children}</div>
        )}
      </main>
    </StyledAuthWrapper>
  );
};

export default connector(AuthWrapper);
