import React from "react";
import Checkbox from "components/Checkbox";
import { SelectedCategoryList } from "../styled";
import { RootState } from "store/store";
import { connect, ConnectedProps } from "react-redux";
import { CATEGORY_KEY_VALUES_ARRAY, CATEGORY_KEY_VALUES_OBJECT } from "constants/category";

interface ISelectedCategoryProps extends PropsFromRedux {
  includes: CATEGORY_KEY_VALUES_OBJECT;
  excludes: CATEGORY_KEY_VALUES_OBJECT;
}

const mapStateToProps = ({ categories }: RootState) => ({
  parentList: categories.parent,
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const SelectedCategory: React.FunctionComponent<ISelectedCategoryProps> = ({
  parentList,
  includes,
  excludes,
}) => {
  const renderSelectedItems = (items: CATEGORY_KEY_VALUES_OBJECT) => {
    const parentKey = Object.keys(items);

    return parentKey.map((parent) => {
      const subCategories = Object.values(items[parent]);
      return subCategories.length ? (
        <div className="divided-category" data-testid={`parent-${parent}`} key={parent}>
          <span>
            <b>{parentList[parent as keyof CATEGORY_KEY_VALUES_ARRAY]}</b>
          </span>
          {subCategories.map((selectedItem) => {
            return (
              <div
                data-testid="category-selected-item"
                className="selected-item"
                key={selectedItem.source_key}
              >
                <Checkbox checked={false} shape="exclude" />
                {/* {renderCategoryTag(type)} */}
                <span
                  data-testid={`selected-${selectedItem.source_key}-${selectedItem.behavior}`}
                >{`${selectedItem.name} `}</span>
                {/* <span
                  data-testid="delete-category-selected"
                  className="delete"
                  onClick={() => callback(Number(id))}
                /> */}
              </div>
            );
          })}
        </div>
      ) : null;
    });
  };

  return (
    <div className="selected-wrapper">
      <SelectedCategoryList>
        <div className="selected-group include">
          <Checkbox shape="circle" type="checkbox" checked />
          <span className="include-label">
            <b>INCLUDE</b>
          </span>
        </div>
        {renderSelectedItems(includes)}
        <div className="selected-group exclude">
          <Checkbox shape="exclude" type="checkbox" checked />
          <span className="exclude-label">
            <b>EXCLUDE</b>
          </span>
        </div>
        {renderSelectedItems(excludes)}
      </SelectedCategoryList>
    </div>
  );
};

export default connector(SelectedCategory);
