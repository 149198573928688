import styled from "styled-components/macro";
import { Popover } from "react-bootstrap";

export const StyledPopover = styled(Popover)<{
  background?: string;
  color?: string;
  placement?: string;
}>`
  font-size: 12px;
  h3 {
    font-size: 12px;
  }

  .popover-header {
    color: ${({ color }) => color || "#000000"};
  }

  .popover-body {
    padding: 16px 16px 16px 16px;
    color: ${({ color }) => color || "#000000"};
    white-space: pre-line;
  }

  .popover-bottom {
  }

  .bs-popover-bottom {
    font-size: 30px;
  }
  .bottomContent {
    padding: 0px 16px 7px 16px;
    font-style: italic;
    color: #00000075;
    white-space: pre-line;
  }
`;
