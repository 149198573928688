import { AnyAction } from "redux";
import * as actions from "../action-types/campaign-payment.types";
import campaignPaymentState from "../states/campaign-payment.state";

const campaignReducer = (state = campaignPaymentState, action: AnyAction) => {
  switch (action.type) {
    case actions.CAMPAIGN_PAYMENT_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;

    case actions.CAMPAIGN_PAYMENT_FAILURE:
      state = {
        ...state,
        error: action.error,
        loading: false,
      };
      break;

    case actions.CAMPAIGN_PAYMENT_SUCCESS:
      state = {
        ...state,
        loading: false,
      };
      break;

    default:
  }

  return state;
};

export default campaignReducer;
