import React from "react";
import { PageNotFoundStyled } from "./styled";

const PageNotFound = () => {
  return (
    <PageNotFoundStyled>
      <h1>404 Page Not Found</h1>
    </PageNotFoundStyled>
  );
};

export default PageNotFound;
