import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { axiosForReverseAds } from "../../axiosConfig";
import ICreateKeywordListResponse from "../../models/create-keyword-list-response.interface";
import IDeleteKeywordListResponse from "../../models/delete-keyword-list-response.interface";
import IDeleteKeywordsResponse from "../../models/delete-keywords-response.interface";
import IKeywordSearchVolumeResponse from "../../models/keyword-search-volume-response.interface";
import { handlingError, onSuccess } from "../../constants/utility/error";
import { IDataForSEO } from "../../constants/utility/keyword";
import { RootState } from "../store";
import { addKeywords } from "./keyword-list-form.actions";
import { hideImportKeywords, hideKeywordSavedListPopup } from "./popup.actions";
import { getSavedKeywordsList } from "./save-keywords-list.actions";

export const ADD_KEYWORDS = "ADD_KEYWORDS";
export const ADD_KEYWORDS_SUCCESS = "ADD_KEYWORDS_SUCCESS";
export const ADD_KEYWORDS_ERROR = "ADD_KEYWORDS_ERROR";

export const ADD_KEYWORD_LIST = "ADD_KEYWORD_LIST";
export const UPDATE_KEYWORD_LIST = "UPDATE_KEYWORD_LIST";

export const FETCH_KEYWORDS_LIST = "FETCH_KEYWORDS_LIST";
export const FETCH_KEYWORDS_LIST_SUCCESS = "FETCH_KEYWORDS_LIST_SUCCESS";
export const FETCH_KEYWORDS_LIST_ERROR = "FETCH_KEYWORDS_LIST_ERROR";

export const FETCH_KEYWORDS_LIST_STATS_SUCCESS = "FETCH_KEYWORDS_LIST_STATS_SUCCESS";
export const FETCH_KEYWORDS_LIST_STATS_FAILURE = "FETCH_KEYWORDS_LIST_STATS_FAILURE";

export const DELETE_KEYWORDS_LIST = "DELETE_KEYWORDS_LIST";
export const DELETE_KEYWORDS_LIST_SUCCESS = "DELETE_KEYWORDS_LIST_SUCCESS";
export const DELETE_KEYWORDS_LIST_ERROR = "DELETE_KEYWORDS_LIST_ERROR";

export const DELETE_KEYWORDS_REQUEST = "DELETE_KEYWORDS_REQUEST";
export const DELETE_KEYWORDS_SUCCESS = "DELETE_KEYWORDS_SUCCESS";
export const DELETE_KEYWORDS_ERROR = "DELETE_KEYWORDS_ERROR";

export const CREATE_KEYWORDS_LIST = "CREATE_KEYWORDS_LIST";
export const CREATE_KEYWORDS_LIST_SUCCESS = "CREATE_KEYWORDS_LIST_SUCCESS";
export const CREATE_KEYWORDS_LIST_ERROR = "CREATE_KEYWORDS_LIST_ERROR";

export const fetchSavedKeywordList = (
  page?: number
): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async (dispatch) => {
    dispatch({
      type: FETCH_KEYWORDS_LIST,
    });

    try {
      const { data } = await axiosForReverseAds.get(
        `keywordLists/filter?page=${page ? `${page + 1}` : 1}`
      );
      dispatch({
        type: FETCH_KEYWORDS_LIST_SUCCESS,
        payload: {
          total: data.total,
          list: data.data.map((item: any) => ({
            ...item,
            stats: {
              avgMonthlySearches: 0,
              averageDifficulty: "",
              averageGoogleCpc: 0,
            },
          })),
        },
      });

      // dispatch(loadKeywordsStats());
    } catch (error: any) {
      handlingError(error);
    }
  };
};

// export const loadKeywordsStats = (): ThunkAction<void, RootState, unknown, AnyAction> => {
//   return async (dispatch, getState) => {
//     // dispatch({
//     //   type: FETCH_KEYWORDS_LIST,
//     // });

//     const { keywordsList } = getState();

//     if (keywordsList.data.length) {
//       const promises = keywordsList.data.map((keywordList, listIndex: number) => {
//         const keywordGroup = keywordList.keywords
//           .map((keywordObject) => keywordObject.keyword)
//           .slice(0, 50);
//         return new Promise(async (resolve, reject) => {
//           if (keywordGroup.length) {
//             try {
//               const keywordDataResponse =
//                 await axiosForReverseAds.post<IKeywordSearchVolumeResponse>(
//                   "keywordServices/keywordSearchVolumeV3",
//                   {
//                     keywords: keywordGroup.slice(0, 50),
//                   }
//                 );
//               const results = keywordDataResponse.data.data as Array<IDataForSEO>;
//               let averageMonthlySearches = 0;
//               let averageCompetition = 0;
//               let averageGoogleCpc = 0;
//               let averageRADCpc = 0;

//               const difficulties = ["Easy", "Medium", "Difficult"];
//               const difficultyShare = 100 / difficulties.length;
//               results.forEach((keywordDetails) => {
//                 const keywordObject = keywordsList.data[listIndex].keywords.find(
//                   (element) => element.keyword?.toLowerCase() === keywordDetails.key?.toLowerCase()
//                 );
//                 if (keywordObject) {
//                   keywordObject.stats = keywordDetails;

//                   averageMonthlySearches += keywordDetails.sv;
//                   averageCompetition += keywordDetails.cmp * 100;
//                   averageGoogleCpc += keywordDetails.cpc;
//                   averageRADCpc += keywordDetails.radCpc;
//                 }
//               });

//               const averageCompetitionScore = averageCompetition / results.length;
//               const stats = {
//                 avgMonthlySearches: Math.round(Math.floor(averageMonthlySearches) / results.length),
//                 averageDifficulty: averageMonthlySearches
//                   ? difficulties[Math.floor(averageCompetitionScore / difficultyShare)] ||
//                     difficulties[difficulties.length - 1]
//                   : "",
//                 averageGoogleCpc: averageGoogleCpc
//                   ? (averageGoogleCpc / results.length).toFixed(2)
//                   : "",
//                 averageRADCpc: averageRADCpc ? (averageRADCpc / results.length).toFixed(2) : "",
//               };
//               resolve({ ...keywordList, stats });
//             } catch (error: any) {
//               resolve({
//                 ...keywordList,
//                 stats: {
//                   avgMonthlySearches: 0,
//                   averageDifficulty: "",
//                   averageGoogleCpc: 0,
//                   averageRADCpc: 0,
//                 },
//               });
//               handlingError(error);
//               dispatch({
//                 type: FETCH_KEYWORDS_LIST_STATS_FAILURE,
//               });
//             }
//           } else {
//             resolve({
//               ...keywordList,
//               stats: {
//                 avgMonthlySearches: 0,
//                 averageDifficulty: "",
//                 averageGoogleCpc: 0,
//                 averageRADCpc: 0,
//               },
//             });
//           }
//         });
//       });

//       await Promise.allSettled(promises).then((values) => {
//         const allValues = values.map((result: any) => result.value);
//         dispatch({
//           type: FETCH_KEYWORDS_LIST_STATS_SUCCESS,
//           payload: {
//             list: allValues,
//           },
//         });
//       });
//     }
//   };
// };

export const deleteKeywordsList = (
  listIds: Array<string>
): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async (dispatch, getState) => {
    dispatch({
      type: DELETE_KEYWORDS_LIST,
    });

    const state = getState();
    await axiosForReverseAds.post<IDeleteKeywordListResponse>("keywordList/delete", {
      ids: listIds,
    });
    const newList = state.keywordsList.data.filter(
      (list: { id: string; name: string }) => !listIds.includes(list.id)
    );

    dispatch({
      type: DELETE_KEYWORDS_LIST_SUCCESS,
      payload: {
        newList: newList,
      },
    });
    dispatch(getSavedKeywordsList());
    onSuccess("Keyword list have been deleted");
  };
};

export const deleteKeywords = (
  keywordIds: Array<string>,
  keywordListId: string
): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async (dispatch) => {
    dispatch({
      type: DELETE_KEYWORDS_REQUEST,
      payload: keywordListId,
    });

    try {
      await axiosForReverseAds.post<IDeleteKeywordsResponse>("keywords/remove", {
        keywordIds: keywordIds,
        listId: keywordListId,
      });

      dispatch({
        type: DELETE_KEYWORDS_SUCCESS,
        payload: {
          keywordIds,
          id: keywordListId,
        },
      });

      // await dispatch(loadKeywordsStats());
      onSuccess(`${keywordIds.length} selected keywords have been removed`);
    } catch (error: any) {
      dispatch({
        type: DELETE_KEYWORDS_ERROR,
        payload: keywordListId,
      });
      handlingError(error);
    }
  };
};

export const createKeywordsList = (
  listName: string,
  values: Array<string> | null,
  options: {
    redirect?: (id: string) => void;
    setErrors?: any;
    pageIndex?: number;
  }
): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async (dispatch) => {
    try {
      dispatch({
        type: CREATE_KEYWORDS_LIST,
      });

      const { data } = await axiosForReverseAds.post<ICreateKeywordListResponse>(
        "keywordList/create",
        {
          name: listName,
        }
      );

      dispatch({
        type: CREATE_KEYWORDS_LIST_SUCCESS,
        payload: {
          ...data.data,
          keywords: [],
          loading: false,
          loaded: false,
        },
      });

      if (values) {
        dispatch(addKeywords(values, data.data.id));
      } else {
        dispatch(fetchSavedKeywordList(options.pageIndex));
      }

      // dispatch(getSavedKeywordsList());
      onSuccess(`Your ${listName} list has been created`);

      dispatch(hideKeywordSavedListPopup());
      dispatch(hideImportKeywords());

      options.redirect && options.redirect(data.data.id);
    } catch (err: any) {
      options.setErrors && options.setErrors({ name: err.response.data.message });
    }
  };
};
