import { AnyAction } from "redux";
import * as types from "../action-types/banner.types";
import banner from "../states/banner.state";

const bannersReducer = (state = banner, action: AnyAction) => {
  switch (action.type) {
    // create
    case types.CREATE_BANNERS_REQUEST:
      state = { ...state, loading: true };
      break;
    case types.CREATE_BANNERS_SUCCESS:
      state = { ...state, data: [...state.data, action.payload], loading: false };
      break;
    case types.CREATE_BANNERS_FAILURE:
      state = { ...state, error: action.error, loading: false };
      break;

    // update
    case types.UPDATE_BANNERS_REQUEST:
      state = { ...state, loading: true };
      break;
    case types.UPDATE_BANNERS_SUCCESS:
      state = { ...state, data: action.payload, loading: false };
      break;
    case types.UPDATE_BANNERS_FAILURE:
      state = { ...state, error: action.error, loading: false };
      break;

    // get
    case types.GET_BANNERS_REQUEST:
      state = { ...state, loading: true };
      break;
    case types.GET_BANNERS_SUCCESS:
      state = { ...state, data: action.payload, loading: false };
      break;
    case types.GET_BANNERS_FAILURE:
      state = { ...state, error: action.error, loading: false };
      break;

    // delete
    case types.DELETE_BANNER_REQUEST:
      state = { ...state, loading: true };
      break;
    case types.DELETE_BANNER_SUCCESS:
      state = {
        ...state,
        data: state.data.filter((banner) => banner.id !== action.payload),
        loading: false,
      };
      break;
    case types.DELETE_BANNER_FAILURE:
      state = { ...state, error: action.error, loading: false };
      break;

    // update asset
    case types.EDIT_BANNER_ASSET_REQUEST:
      state = { ...state, isAssetUpdating: true };
      break;
    case types.EDIT_BANNER_ASSET_SUCCESS:
      state = {
        ...state,
        isAssetUpdating: false,
      };
      break;
    case types.EDIT_BANNER_ASSET_FAILURE:
      state = { ...state, error: action.error, isAssetUpdating: false };
      break;
    default:
  }

  return state;
};

export default bannersReducer;
