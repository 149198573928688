import * as actions from "store/actions/user.actions";
import * as types from "store/action-types/user.types";
import api from "services/api";
import { handlingError, onSuccess } from "constants/utility/error";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  hideAdvertiserForm,
  hideChangePasswordPopup,
  showBuildCampaign,
} from "store/actions/popup.actions";

// update advertiser

function* onUpdateAdvertiser(action: types.UpdateAdvertiserAction) {
  try {
    yield put(actions.updateAdvertiserRequest());
    yield call(api.user.setBusinessGroupAndWebsite, action.payload);
    yield put(actions.updateAdvertiserSuccess(action.payload));
    yield onSuccess("Update advertiser");
    yield put(showBuildCampaign());
    yield put(hideAdvertiserForm());
  } catch (error: any) {
    handlingError(error);
    yield put(actions.updateAdvertiserFailure(error.response.data.formErrors));
  }
}

function* watchOnUpdateAdvertiser() {
  yield takeEvery(types.UPDATE_ADVERTISER, onUpdateAdvertiser);
}

// update

function* onUpdatePassword(action: types.UpdatePasswordAction) {
  try {
    yield put(actions.updatePasswordRequest());
    yield call(api.user.updatePassword, action.payload);
    yield put(actions.updatePasswordSuccess());

    onSuccess(`Password has been changed`);
    yield put(hideChangePasswordPopup());
  } catch (error: any) {
    handlingError(error);
    yield put(actions.updatePasswordFailure(error.response.data.formErrors));
  }
}

function* watchOnUpdatePassword() {
  yield takeEvery(types.UPDATE_PASSWORD, onUpdatePassword);
}

// forget

function* onForgetPassword(action: types.ForgetPasswordAction) {
  try {
    yield put(actions.onForgetPasswordRequest());
    yield call(api.user.forgetPassword, action.payload);
    yield put(actions.onForgetPasswordSuccess());
  } catch (error: any) {
    handlingError(error);
    yield put(actions.onForgetPasswordFailure(error.response.data.formErrors));
  }
}

function* watchOnForgetPassword() {
  yield takeEvery(types.FORGET_PASSWORD, onForgetPassword);
}

export default function* usersSaga() {
  yield all([
    fork(watchOnUpdatePassword),
    fork(watchOnForgetPassword),
    fork(watchOnUpdateAdvertiser),
  ]);
}
