import styled from "styled-components/macro";

export const PaymentSelectorStyled = styled.section`
  flex-basis: 100%;
  flex: 1;
  padding: 25px;
  border-radius: 10px;
`;

export const FormCheckbox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  .form-check-input {
    cursor: pointer;
    width: 0;
    height: 0;
    margin: 0;
    border: 0;

    &:before {
      background: ${({ theme }) => theme.background};
      transform: translate(-12px, -2px);
      content: "";
      position: absolute;
      height: 15px;
      width: 15px;
      border: 2px solid #cacaca;
      border-radius: 50%;
      margin: 0;
    }

    &:checked {
      &:after {
        background: ${({ theme }) => theme.background};
        transform: translate(-8px, 2px);
        content: "";
        position: absolute;
        height: 8px;
        width: 8px;
        border: 4px solid #fe0099;
        border-radius: 50%;
        margin: 0;
      }
    }
  }
`;

export const PaymentMethodStyled = styled.div<{ disabled?: boolean; contentHeight?: number }>`
  opacity: ${({ disabled }) => (disabled ? 0.7 : 1)};
  /* background: ${({ disabled }) => (disabled ? "rgb(228,234,236)" : "rgb(60,60,60)")}; */
  display: flex;
  width: 100%;
  pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
  flex-direction: column;
  padding: 20px 0;
  height: 100%;

  &:last-child {
    border-top: 1px solid ${({ theme }) => theme.checkboxBorder};
  }

  /* &:hover {
    box-shadow: 3px 2px 9px -2px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 3px 2px 9px -2px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 3px 2px 9px -2px rgba(0, 0, 0, 0.25);
  } */

  .payment-content {
    overflow: hidden;
    height: ${({ contentHeight }) => contentHeight || 0}px;
    transition: 0.3s ease-out;

    .input-error {
      position: initial;
    }
  }

  .payment-selector {
    display: flex;
    cursor: pointer;
    position: relative;

    .method-detail {
      width: 80%;

      .title {
        color: ${({ theme }) => theme.cpc};
        font-size: 14px;
        margin: 0;
      }

      .message {
        margin-top: 1rem;
        color: ${({ theme }) => theme.contrast};
        font-size: 12px;
      }
    }

    .checkbox-input {
      width: 20%;
      display: flex;
      justify-content: center;
      align-items: start;
    }
  }
`;
