import styled, { css } from "styled-components/macro";

export const Tab = styled.div<{ active?: boolean }>`
  border-bottom: 1px solid #444;
  padding: 20px;
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
  color: ${({ theme }) => theme.color.primary};

  ${({ active }) =>
    active &&
    css`
      box-shadow: 5px 0px 0px 0px ${({ theme }) => theme.alertModalBackground};
    `}
`;

export const CampaignAdminWrapper = styled.div`
  border: 1px solid gray;
  height: 100%;
  display: flex;
  background-color: ${({ theme }) => theme.alertModalBackground};

  th,
  td {
    padding: 0 10px;
  }

  .table-body {
    font-size: 10px;
    color: ${({ theme }) => theme.contrast};

    tr > td {
      height: 40px;
    }

    .view-payment {
      background: rgb(98, 163, 184);
      border-radius: 50px;
      padding: 3px;
      cursor: pointer;
    }
  }

  .table-head {
    font-size: 12px;
    height: 40px;
  }

  .left-side {
    border-right: 1px solid #444;
    width: 30%;
  }

  .right-side {
    overflow-y: scroll;
    max-height: 500px;
    width: 70%;
    padding: 20px;
    color: ${({ theme }) => theme.contrast};

    .setting-panel > div {
      margin-bottom: 20px;
    }

    .setting-inputs {
      display: flex;
      gap: 20px;
    }

    .tool-inputs {
      display: flex;
      gap: 20px;
      > div {
        width: 75%;
      }
      .tool-inputs__cc {
        display: flex;
        gap: 10px;
      }
      .add-cc-button {
        margin: center;
      }
      .tool-inputs__cc-list {
        margin-top: 20px;
        display: flex;
        gap: 10px;
        flex-wrap: wrap;
      }
      .tool-inputs__error {
        color: white;
        font-size: 12px;
        font-weight: 600;
      }
    }

    .submit-section {
      margin-top: 20px;
    }
  }
`;
