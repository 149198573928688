import { AnyAction } from "redux";
import * as actions from "../action-types/home.types";
import homeState from "../states/home.state";

const homeReducer = (state = homeState, action: AnyAction) => {
  switch (action.type) {
    case actions.SAVE_KEYWORDS_TO_HISTORY_REQUEST:
      state = { ...state, loading: true };
      break;
    case actions.SAVE_KEYWORDS_TO_HISTORY_SUCCESS:
      state = { ...state, mock: action.payload, loading: false };
      break;

    case actions.SAVE_KEYWORDS_TO_HISTORY_FAILURE:
      state = { ...state, loading: false };
      break;

    default:
  }
  return state;
};

export default homeReducer;
