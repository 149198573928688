import React from "react";
import { Link } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { Formik } from "formik";
import FloatingLabel from "components/FloatingLabel";
import { axiosForReverseAds } from "axiosConfig";
import Button from "components/Button";
import { ForgetPasswordStyled } from "./styled";
import IForgetPasswordResponse from "models/forget-password-response.interface";
import forgetPasswordSchema from "schemas/forget-password.schema";
import Input from "components/Input";
import * as AUTH_ROUTE from "modules/Auth/routes";

const ForgetPasswordPage = () => {
  const [hasSentEmail, setSentEmail] = React.useState(false);

  return (
    <ForgetPasswordStyled>
      <div className="wrapper">
        <h4 className="title secondaryFont">Forget Password</h4>
        {hasSentEmail ? (
          <p>We have emailed your password reset link</p>
        ) : (
          <>
            <p>
              Please enter your email address and we'll send you instructions on how to reset your
              password
            </p>
            <Formik
              validationSchema={forgetPasswordSchema}
              initialValues={{
                email: "",
              }}
              onSubmit={async (values, { setSubmitting, setErrors }) => {
                try {
                  const response = await axiosForReverseAds.post<IForgetPasswordResponse>(
                    `/auth/v2/forgotPassword`,
                    {
                      email: values.email,
                    }
                  );
                  if (response.status === 200 && response.data) {
                    setSentEmail(true);
                  }
                } catch (error: any) {
                  if (error.response.data.message) {
                    setErrors({
                      email: "No matching email address found",
                    });
                  }
                }
                setSubmitting(false);
              }}
            >
              {({ isSubmitting, touched, errors, values, handleChange, handleSubmit }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <div className="input-wrapper">
                      <Input
                        type="text"
                        placeholder="Email Address"
                        name="email"
                        black
                        error={errors.email}
                        touched={touched.email}
                        value={values.email}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="input-wrapper">
                      <Button width={250} type="submit" block loading={isSubmitting}>
                        Submit
                      </Button>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </>
        )}
        <div className="forget-password-button">
          <span className="back-button">← Back to </span>
          <Link to={`../${AUTH_ROUTE.LOGIN}`}>{` Log in`}</Link>
        </div>
      </div>
    </ForgetPasswordStyled>
  );
};

export default ForgetPasswordPage;
