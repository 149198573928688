import React from "react";
import { CustomModal } from "./styled";
import { Modal } from "react-bootstrap";
import Button from "components/Button";
import { RootState } from "store/store";
import { connect, ConnectedProps, useDispatch } from "react-redux";
import { hideConfirmPopup, hideExportPopup } from "store/actions/popup.actions";
import Checkbox from "components/Checkbox";
import CloseButton from "components/CloseButton";
import api from "services/api";

interface IExportPopup extends PropsFromRedux {}

const mapStateToProps = ({ popup }: RootState) => ({
  popup,
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const ConfirmPopup: React.FunctionComponent<IExportPopup> = ({ popup }) => {
  const dispatch = useDispatch();

  const onHide = () => {
    dispatch(hideConfirmPopup());
  };

  return (
    <CustomModal keyboard show={popup.showConfirmPopup.show} onHide={onHide} centered>
      <Modal.Header>
        <b>{popup.showConfirmPopup.title}</b>
        <CloseButton onClick={onHide} top={10} right={34} dark />
      </Modal.Header>
      <Modal.Body>
        <span className="confirm-text">{popup.showConfirmPopup.body || ""}</span>
      </Modal.Body>
      <Modal.Footer>
        <Button color="gray" onClick={onHide}>
          Cancel
        </Button>
        <Button
          onClick={() => {
            popup.showConfirmPopup.action && popup.showConfirmPopup.action();
            onHide();
          }}
        >
          {popup.showConfirmPopup.confirmText}
        </Button>
      </Modal.Footer>
    </CustomModal>
  );
};

export default connector(ConfirmPopup);
