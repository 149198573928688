import styled, { css } from "styled-components/macro";

export const TableActionButton = styled.div<{
  disabled?: boolean;
  outline?: boolean;
  color?: "primary";
}>`
  display: flex;
  font-size: 10px;
  gap: 5px;
  align-items: center;
  padding: 5px 10px;
  border-radius: 5px;
  background: ${({ theme, color }) => (color ? theme.color[color] : theme.color.blue)};
  height: 25px;
  cursor: pointer;
  opacity: 1;
  transition: 0.3s;

  > svg {
    width: 12px;
    height: 12px;

    .icon-svg {
      stroke: ${({ theme }) => theme.contrast};
    }
  }

  ${({ outline }) =>
    outline &&
    css`
      border: 1px solid white;
      background: transparent;
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `}
`;

export const BottomBarStyled = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 5px;

  .button-group {
    display: flex;
    gap: 5px;

    .select-all-checkbox {
      gap: 10px;
      font-size: 10px;
      color: ${({ theme }) => theme.contrast};
      display: flex;
      align-items: center;
      margin-right: 10px;
    }
  }

  .keyword-table-actions {
    display: flex;
    gap: 15px;

    .export-table-action {
      > span {
        color: #ffffff;
      }
    }

    .save-list-table-action {
      .icon-svg {
        stroke: #ffffff;
      }
      > span {
        color: #ffffff;
      }
    }

    .delete-table-action {
      border: 1px solid ${({ theme }) => theme.contrast};

      > span {
        color: ${({ theme }) => theme.contrast};
      }
    }

    span {
      align-self: center;
    }

    .action {
      cursor: pointer;
      width: 100px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 0.3s;

      &:hover {
        opacity: 0.65;
      }
    }
  }
`;
