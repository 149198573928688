import React from "react";

const FilterIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      fill="#ffffff"
      enableBackground="new 0 0 512.013 512.013"
      height="16"
      viewBox="0 0 512.013 512.013"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path d="m463.085 0h-414.156c-19.31 0-30.661 21.763-19.597 37.599.213.302-6.556-8.916 158.032 215.117 3.692 5.342 5.643 11.593 5.643 18.089v215.917c0 20.996 24.069 32.577 40.39 20.227l70.154-52.884c9.68-7.188 15.456-18.67 15.456-30.73v-152.53c0-6.496 1.95-12.747 5.643-18.089 164.461-223.861 157.819-214.817 158.032-215.117 11.059-15.829-.279-37.599-19.597-37.599zm-162.728 235.111c-6.801 9.257-11.35 22.164-11.35 35.693v152.529c0 2.613-1.255 5.102-3.355 6.655-1.847 1.361 12.142-9.153-62.645 47.223v-206.406c0-12.726-3.853-24.968-11.142-35.402-.181-.259 4.898 6.659-103.142-140.402h294.567zm124.973-170.111h-338.646l-25.713-35h390.072z" />
      </g>
    </svg>
  );
};

export default FilterIcon;
