import Badge from "components/Badge";
import { HomeIcon, TrashIcon } from "components/Icons";
import EditIcon from "components/Icons/EditIcon";
import React, { Children } from "react";
import { KeywordSetItemStyled } from "./styled";

interface IKeywordSetItemProps {
  id: string;
  openPopup: () => void;
  onDeleteList: () => void;
  children: React.ReactNode | JSX.Element[];
}

const KeywordSetItem: React.FunctionComponent<IKeywordSetItemProps> = ({
  id,
  onDeleteList,
  openPopup,
  children,
}) => {
  return (
    <KeywordSetItemStyled>
      <div style={{ display: "flex" }}>
        <div onClick={() => openPopup()} className="popup-icon">
          <EditIcon />
        </div>
        <ul className="keyword-set">{children}</ul>
      </div>

      <div onClick={onDeleteList} className="delete-icon">
        <TrashIcon />
      </div>
    </KeywordSetItemStyled>
  );
};

export default KeywordSetItem;
