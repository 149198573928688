import { IErrorMessage } from "../../services/types";

interface ICompareState {
  loading: boolean;
  error: IErrorMessage | null;
  data: Array<ICompareList>;
  total: number;
}

const state = {
  loading: false,
  error: null,
  data: [],
  total: 0,
} as ICompareState;

export interface ICompareList {
  id: string;
  keywords: Array<{
    id: string;
    keyword: string;
  }>;
}

export default state;
