import { IErrorMessage } from "../../services/types";

interface ICompetitorState {
  loading: boolean;
  error: IErrorMessage | null;
  data: Array<any>;
  total: number;
}

const state = {
  loading: false,
  error: null,
  data: [],
  total: 0,
} as ICompetitorState;

export default state;
