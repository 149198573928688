import ManageUsers from "./pages/ManageUsers";
import AllCampaigns from "./pages/AllCampaigns";

// import EditCampaign from "../shared/pages/EditCampaign/EditCampaign";
// import AllTransactions from "./pages/AllTransactions";
// import CampaignForm from "../UserApp/pages/CampaignForm";

import manageCampaignIcon from "assets/images/menu-icons/manage-campaign-icon.svg";
import { INavigationGroup, IRoute } from "modules/Client/routes";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import ManagePayment from "./pages/ManagePayment";

export const APP_BASE_ROUTE = "/admin";
export const NEWS_FEED = `feed/`;
export const EDIT_NEWS_FEED = `feed/:id`;
export const CREATE_NEWS_FEED = `feed/create/`;
export const ALL_USERS = `users/`;
export const ALL_CAMPAIGNS = `campaigns/`;
// export const VIEW_CAMPAIGN = `campaign/view/`;
// export const EDIT_CAMPAIGN = `campaign/edit/`;
export const CAMPAIGN_REPORT = `campaign/:campaignId`;
export const MANAGE_PAYMENTS = `manage-payments/`;

export const adminAppRoutes: Array<IRoute> = [
  {
    path: ALL_USERS,
    component: ManageUsers,
  },
  {
    path: ALL_CAMPAIGNS,
    component: AllCampaigns,
  },

  // {
  //   path: NEWS_FEED,
  //   component: NewsFeed,
  // },

  {
    path: MANAGE_PAYMENTS,
    component: ManagePayment,
  },
];

export const sidebarLinks = [
  {
    text: "Admin Zone",
    collapsed: true,
    children: [
      {
        path: ALL_CAMPAIGNS,
        text: "Manage Campaigns",
        activeLinks: [ALL_CAMPAIGNS, CAMPAIGN_REPORT, "campaign"],
      },
      {
        path: ALL_USERS,
        text: "Manage Users",
        // icon: { type: "fontAwesome", name: ["fas", "users"] },
        activeLinks: [],
      },
      // {
      //   path: NEWS_FEED,
      //   text: "News Feed",
      //   // icon: { type: "fontAwesome", name: ["fas", "ad"] },
      //   activeLinks: [],
      // },
      {
        path: MANAGE_PAYMENTS,
        text: "Manage Payments",
        // icon: { type: "fontAwesome", name: ["fas", "file-invoice-dollar"] },
        // icon: { type: "fontAwesome", name: ["fas", "receipt"] },
        activeLinks: [],
      },
    ],
  },
] as Array<INavigationGroup>;
