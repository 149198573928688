export enum ENUM_USER_ROLES {
  USER = "user",
  SUPER_ADMIN = "super_admin",
  ADMIN = "admin",
}

export const TEXT_ROLE = {
  [ENUM_USER_ROLES.USER]: "User",
  [ENUM_USER_ROLES.ADMIN]: "Admin",
  [ENUM_USER_ROLES.SUPER_ADMIN]: "Super Admin",
};

export type USER_ROLE = ENUM_USER_ROLES.ADMIN | ENUM_USER_ROLES.SUPER_ADMIN | ENUM_USER_ROLES.USER;

export interface IUserAuthentication {
  id: string;
  fullName: string; // POST API
  userName: string; // GET API
  email: string;
  country: string;
  isAdmin: boolean;
  isSubscribed?: boolean;
  accessToken?: null | string;
  website: string;
  businessId: number;
  package: string;
  deleted_at?: Date;
  totalCampaign: number;
  created_at?: Date;
  updated_at?: Date;
  isAuthenticated: boolean;
  role: USER_ROLE | null;
}
export interface IUpdateUserData {
  fullName: string;
  email?: string;
  role: USER_ROLE | null;
}

const userState: IUserAuthentication & { loading: boolean } = {
  id: "",
  fullName: "",
  userName: "",
  email: "",
  totalCampaign: 0,
  country: "",
  isAdmin: false,
  isSubscribed: false,
  accessToken: null,
  website: "",
  businessId: 0,
  package: "Free",
  isAuthenticated: false,
  role: null,
  loading: false,
};

export default userState;
