import { ICampaignFilters, IErrorMessage } from "../../services/types";
import { IPaymentDetail } from "../states/payments.state";
import { IRefIdData } from "../states/admin.state";
import { IDuplicateCampaignPayload } from "../states/campaign.state";
import { ICampaign } from "../states/campaigns.state";
import { CATEGORY_KEY_VALUES_OBJECT } from "constants/category";
import { IPostCampaignData } from "constants/utility/campaign";

export const UPDATE_CAMPAIGNS_LIST_REQUEST = "campaigns-actions/UPDATE_CAMPAIGNS_LIST_REQUEST";

export const UPDATE_CAMPAIGNS_LIST_SUCCESS = "campaigns-actions/UPDATE_CAMPAIGNS_LIST_SUCCESS";

export const SET_CAMPAIGNS = "campaigns-actions/SET_CAMPAIGNS";

export const RESET_CURRENT_CAMPAIGN = "campaign-actions/RESET_CURRENT_CAMPAIGN";
// get campaign by id

export const GET_CAMPAIGN_BY_ID = "campaigns-actions/GET_CAMPAIGN_BY_ID";
export interface GetCampaignByIdAction {
  type: typeof GET_CAMPAIGN_BY_ID;
  id: string;
}

export const GET_CAMPAIGN_BY_ID_REQUEST = "campaigns-actions/GET_CAMPAIGN_BY_ID_REQUEST";
export interface GetCampaignByIdRequestAction {
  type: typeof GET_CAMPAIGN_BY_ID_REQUEST;
}

export const GET_CAMPAIGN_BY_ID_SUCCESS = "campaigns-actions/GET_CAMPAIGN_BY_ID_SUCCESS";
export interface GetCampaignByIdSuccessAction {
  type: typeof GET_CAMPAIGN_BY_ID_SUCCESS;
  campaign: ICampaign;
}

export const GET_CAMPAIGN_BY_ID_FAILURE = "campaigns-actions/GET_CAMPAIGN_BY_ID_FAILURE";
export interface GetCampaignByIdFailureAction {
  type: typeof GET_CAMPAIGN_BY_ID_FAILURE;
  error: IErrorMessage;
}

// Update payment status

export const UPDATE_PAYMENT_STATUS = "admin-actions/UPDATE_PAYMENT_STATUS";

export const UPDATE_PAYMENT_STATUS_REQUEST = "admin-actions/UPDATE_PAYMENT_STATUS_REQUEST";

export interface UpdatePaymentStatusAction {
  type: typeof UPDATE_PAYMENT_STATUS;
  payload: IPaymentDetail;
}

export interface UpdatePaymentStatusRequestAction {
  type: typeof UPDATE_PAYMENT_STATUS_REQUEST;
}

export const UPDATE_PAYMENT_STATUS_SUCCESS = "admin-actions/UPDATE_PAYMENT_STATUS_SUCCESS";
export interface UpdatePaymentStatusSuccessAction {
  type: typeof UPDATE_PAYMENT_STATUS_SUCCESS;
  payload: IPaymentDetail;
}

export const UPDATE_PAYMENT_STATUS_FAILURE = "admin-actions/UPDATE_PAYMENT_STATUS_FAILURE";
export interface UpdatePaymentStatusFailureAction {
  type: typeof UPDATE_PAYMENT_STATUS_FAILURE;
  error: IErrorMessage;
}

export type PaymentStatusAction =
  | UpdatePaymentStatusAction
  | UpdatePaymentStatusRequestAction
  | UpdatePaymentStatusSuccessAction
  | UpdatePaymentStatusFailureAction;

// Update ref id

export const UPDATE_REF_ID = "admin-actions/UPDATE_REF_ID";

export const UPDATE_REF_ID_REQUEST = "admin-actions/UPDATE_REF_ID_REQUEST";

export interface settingAction {
  type: typeof UPDATE_REF_ID;
  payload: IRefIdData;
}

export interface settingRequestAction {
  type: typeof UPDATE_REF_ID_REQUEST;
}

export const UPDATE_REF_ID_SUCCESS = "admin-actions/UPDATE_REF_ID_SUCCESS";
export interface settingSuccessAction {
  type: typeof UPDATE_REF_ID_SUCCESS;
  payload: {
    message: string;
    state: string;
  };
}

export const UPDATE_REF_ID_FAILURE = "admin-actions/UPDATE_REF_ID_FAILURE";
export interface settingFailureAction {
  type: typeof UPDATE_REF_ID_FAILURE;
  error: IErrorMessage;
}

export type RefIdAction =
  | settingAction
  | settingRequestAction
  | settingSuccessAction
  | settingFailureAction;

export const REMOVE_MESSAGE = "admin-actions/REMOVE_MESSAGE";

// duplicate campaign

export const DUPLICATE_CAMPAIGN = "admin-actions/DUPLICATE_CAMPAIGN";

export const DUPLICATE_CAMPAIGN_REQUEST = "admin-actions/DUPLICATE_CAMPAIGN_REQUEST";

export interface duplicateCampaignAction {
  type: typeof DUPLICATE_CAMPAIGN;
  payload: IDuplicateCampaignPayload;
}

export interface duplicateCampaignRequestAction {
  type: typeof DUPLICATE_CAMPAIGN_REQUEST;
}

export const DUPLICATE_CAMPAIGN_SUCCESS = "admin-actions/DUPLICATE_CAMPAIGN_SUCCESS";
export interface duplicateCampaignSuccessAction {
  type: typeof DUPLICATE_CAMPAIGN_SUCCESS;
  payload?: ICampaign;
}

export const DUPLICATE_CAMPAIGN_FAILURE = "admin-actions/DUPLICATE_CAMPAIGN_FAILURE";
export interface duplicateCampaignFailureAction {
  type: typeof DUPLICATE_CAMPAIGN_FAILURE;
  error: IErrorMessage;
}

export type DuplicateCampaignAction =
  | duplicateCampaignAction
  | duplicateCampaignRequestAction
  | duplicateCampaignSuccessAction
  | duplicateCampaignFailureAction;

// edit

export const EDIT_CAMPAIGN = "campaign-actions/EDIT_CAMPAIGN";
export interface editCampaignAction {
  type: typeof EDIT_CAMPAIGN;
  payload: IPostCampaignData & {
    includes: CATEGORY_KEY_VALUES_OBJECT;
    excludes: CATEGORY_KEY_VALUES_OBJECT;
  };
}

export const EDIT_CAMPAIGN_REQUEST = "campaign-actions/EDIT_CAMPAIGN_REQUEST";

export interface editCampaignRequestAction {
  type: typeof EDIT_CAMPAIGN_REQUEST;
}

export const EDIT_CAMPAIGN_SUCCESS = "campaign-actions/EDIT_CAMPAIGN_SUCCESS";
export interface editCampaignSuccessAction {
  type: typeof EDIT_CAMPAIGN_SUCCESS;
}

export const EDIT_CAMPAIGN_FAILURE = "campaign-actions/EDIT_CAMPAIGN_FAILURE";
export interface editCampaignFailureAction {
  type: typeof EDIT_CAMPAIGN_FAILURE;
  error: IErrorMessage;
}

// link beeswax

export const LINK_CAMPAIGN = "campaign-actions/LINK_CAMPAIGN";
export interface linkCampaignAction {
  type: typeof LINK_CAMPAIGN;
  payload: string;
}

export const LINK_CAMPAIGN_REQUEST = "campaign-actions/LINK_CAMPAIGN_REQUEST";

export interface linkCampaignRequestAction {
  type: typeof LINK_CAMPAIGN_REQUEST;
}

export const LINK_CAMPAIGN_SUCCESS = "campaign-actions/LINK_CAMPAIGN_SUCCESS";
export interface linkCampaignSuccessAction {
  type: typeof LINK_CAMPAIGN_SUCCESS;
  payload: { status: string };
}

export const LINK_CAMPAIGN_FAILURE = "campaign-actions/LINK_CAMPAIGN_FAILURE";
export interface linkCampaignFailureAction {
  type: typeof LINK_CAMPAIGN_FAILURE;
  error: IErrorMessage;
}

// create campaign

export const CREATE_CAMPAIGN = "campaign-actions/CREATE_CAMPAIGN";
export interface createCampaignAction {
  type: typeof CREATE_CAMPAIGN;
  payload: IPostCampaignData & {
    includes: CATEGORY_KEY_VALUES_OBJECT;
    excludes: CATEGORY_KEY_VALUES_OBJECT;
  };
}

export const CREATE_CAMPAIGN_REQUEST = "campaign-actions/CREATE_CAMPAIGN_REQUEST";

export interface createCampaignRequestAction {
  type: typeof CREATE_CAMPAIGN_REQUEST;
}

export const CREATE_CAMPAIGN_SUCCESS = "campaign-actions/CREATE_CAMPAIGN_SUCCESS";
export interface createCampaignSuccessAction {
  type: typeof CREATE_CAMPAIGN_SUCCESS;
  payload: IPostCampaignData;
}

export const CREATE_CAMPAIGN_FAILURE = "campaign-actions/CREATE_CAMPAIGN_FAILURE";
export interface createCampaignFailureAction {
  type: typeof CREATE_CAMPAIGN_FAILURE;
  error: IErrorMessage;
}
