import styled from "styled-components/macro";

export const StyledPagination = styled.div`
  display: flex;
  gap: 15px;
  justify-content: flex-end;
  align-items: center;

  color: ${({ theme }) => theme.contrast};

  .total-page {
    font-size: 12px;
  }
`;
