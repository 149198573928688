import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { connect, ConnectedProps, useDispatch } from "react-redux";
import * as ROUTES from "../../routes";
import { registerUser } from "store/actions/user.actions";
import { Formik } from "formik";
import { validation, register } from "../../../../schemas/user.schema";
import { RegisterPageStyled } from "./styled";
import Checkbox from "components/Checkbox";
import Button from "components/Button";
import { RootState } from "store/store";
import Input from "components/Input";
import PlusIcon from "components/Icons/PlusIcon";
import { HomeIcon } from "components/Icons";
import CompareIcon from "components/Icons/CompareIcon";
import EyesCloseIcon from "components/Icons/EyesCloseIcon";
import EyesOpenIcon from "components/Icons/EyesOpenIcon";

interface IRegisterProps {
  currentTheme: "light" | "dark";
}

const Register: React.FunctionComponent<IRegisterProps> = ({ currentTheme }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [displayPassword, setDisplayPassword] = React.useState(false);
  const [displayConfirm, setDisplayConfirm] = React.useState(false);

  return (
    <Formik
      validationSchema={validation.concat(register)}
      initialValues={{
        fullName: "",
        email: "",
        password: "",
        confirmPassword: "",
        subscribeSpecialOffer: true,
        termsAndCondition: false,
      }}
      onSubmit={(values, { setErrors, setSubmitting }) => {
        setTimeout(async () => {
          await dispatch(registerUser(values, setErrors, navigate));
          setSubmitting(false);
        }, 1000);
      }}
    >
      {({ handleSubmit, handleChange, errors, touched, values, isSubmitting, setFieldValue }) => {
        return (
          <RegisterPageStyled
            currentTheme={currentTheme}
            data-testid="register-form"
            onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <div>
              <h4 className="title secondaryFont">GET STARTED BETA</h4>

              <div className="form-field">
                <Input
                  autoComplete="new-password"
                  black
                  width={400}
                  data-testid="fullname"
                  type="text"
                  placeholder="Full Name"
                  name="fullName"
                  touched={touched.fullName}
                  error={errors.fullName}
                  value={values.fullName}
                  onChange={handleChange}
                />
              </div>

              <div className="form-field">
                <Input
                  autoComplete="new-password"
                  black
                  width={400}
                  data-testid="email"
                  type="email"
                  placeholder="Email"
                  name="email"
                  touched={touched.email}
                  error={errors.email}
                  value={values.email}
                  onChange={handleChange}
                />
              </div>

              <div className="form-field">
                <Input
                  autoComplete="new-password"
                  black
                  width={400}
                  data-testid="password"
                  type={displayPassword ? "text" : "password"}
                  placeholder="Password"
                  name="password"
                  touched={touched.password}
                  error={errors.password}
                  value={values.password}
                  onChange={handleChange}
                />
                <span
                  className={`show-password ${displayPassword ? "show" : ""}`}
                  onClick={() => setDisplayPassword((prev) => !prev)}
                >
                  {displayPassword ? <EyesOpenIcon /> : <EyesCloseIcon />}
                </span>
              </div>

              <div className="form-field">
                <Input
                  autoComplete="new-password"
                  black
                  width={400}
                  data-testid="confirm"
                  type={displayConfirm ? "text" : "password"}
                  placeholder="Confirm Password"
                  name="confirmPassword"
                  onChange={handleChange}
                  touched={touched.confirmPassword}
                  error={errors.confirmPassword}
                  value={values.confirmPassword}
                />
                <span
                  className={`show-password ${displayConfirm ? "show" : ""}`}
                  onClick={() => setDisplayConfirm((prev) => !prev)}
                >
                  {displayConfirm ? <EyesOpenIcon /> : <EyesCloseIcon />}
                </span>
              </div>

              <div className="section form-field checkbox">
                <div className="checkbox-wrapper">
                  <Checkbox
                    inline
                    size="sm"
                    type="checkbox"
                    name="subscribeSpecialOffer"
                    checked={values.subscribeSpecialOffer}
                    onSelect={() =>
                      setFieldValue("subscribeSpecialOffer", !values.subscribeSpecialOffer)
                    }
                  />
                </div>

                <span className="offer-label">
                  {`Please send me Vatic AI emails with special offers
and other information.`}
                </span>
              </div>
              <div className="section form-field checkbox">
                <div className="checkbox-wrapper">
                  <Checkbox
                    inline
                    size="sm"
                    type="checkbox"
                    name="termsAndCondition"
                    checked={values.termsAndCondition}
                    onSelect={() => setFieldValue("termsAndCondition", !values.termsAndCondition)}
                    error={!!(errors.termsAndCondition && touched.termsAndCondition)}
                  />
                </div>
                <span className="offer-label">
                  <span>I agree to Vatic AI's</span>
                  <a
                    href="https://vaticai.com/terms-and-conditions/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span>Terms & Conditions</span>
                  </a>
                  <br />
                  <span>and</span>
                  <a href="https://vaticai.com/privacy-policy/" target="_blank" rel="noreferrer">
                    Privacy Policy
                  </a>
                </span>
              </div>
              {errors.termsAndCondition && touched.termsAndCondition && (
                <span className="errorMessage">{errors.termsAndCondition}</span>
              )}
              <div data-testid="register-btn" className="section form-field">
                <Button width={200} type="submit" disabled={isSubmitting}>
                  {isSubmitting ? (
                    <React.Fragment>
                      Please wait...
                      <Spinner animation="border" size="sm" className="ml-2" />
                    </React.Fragment>
                  ) : (
                    <strong>Sign Up</strong>
                  )}
                </Button>
              </div>
              <p className="login">
                Already have an account? <Link to={`../${ROUTES.LOGIN}`}>Login</Link>
              </p>
            </div>
            <div className="graphics">
              <div className="home-sample">
                <div className="icon-wrapper">
                  <HomeIcon />
                </div>
              </div>
              <div className="compare-sample">
                <div className="icon-wrapper">
                  <CompareIcon />
                </div>
              </div>
              <div className="campaign-sample">
                <div className="icon-wrapper">
                  <PlusIcon />
                </div>
              </div>
            </div>
          </RegisterPageStyled>
        );
      }}
    </Formik>
  );
};

export default Register;
