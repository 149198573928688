import React from "react";
import Select from "react-dropdown-select";
import styled, { css, keyframes } from "styled-components/macro";

const icon = require("assets/home-screen/folder-down-icon.svg").default;

const spin = keyframes`
  0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
`;

const CustomSelect = styled(Select)<{
  error?: boolean;
  highlight?: boolean;
  width?: number;
  border?: boolean;
  isTransparent?: boolean;
  input?: boolean;
  multi?: boolean;
  backgroundColor?: "purple" | "primary";
  height?: number;
}>`
  &:focus-within,
  &:hover {
    border: 1px solid ${({ border }) => (border ? "rgb(80,80,80)" : "transparent")} !important;
  }
  min-width: 185px;
  background: ${({ theme, backgroundColor }) =>
    backgroundColor ? theme.color[backgroundColor] : theme.inputSecondary};
  height: ${({ multi, height }) => (multi ? "auto" : height ? `${height}px` : "45px")};
  border: 1px solid ${({ error, theme, border }) => (error ? theme.color.error : "transparent")} !important;
  width: ${({ width }) => (width ? `${width}px` : "auto")} !important;
  border-radius: 8px !important;

  &:focus,
  &:focus-within {
    box-shadow: none !important;
  }

  .react-dropdown-select-no-data {
    color: white;
    font-size: 12px;
  }

  .react-dropdown-select-dropdown-add-new {
    color: white;
    font-size: 12px;
    padding: 10px 20px;
  }

  .react-dropdown-select-loading {
    &:after {
      border-width: 2px;
      border-color: ${({ theme }) => theme.color.green} ${({ theme }) => theme.color.green}
        ${({ theme }) => theme.color.green} transparent;
      animation: ${spin} 0.7s linear infinite;
    }
  }

  .react-dropdown-select-dropdown {
    background: ${({ theme }) => theme.dropdown};
    border: 0px solid #ccc;
    box-shadow: 7px 5px 3px 0px #00000073;
    margin-top: 5px;
    border-radius: 8px;
    color: ${({ theme }) => theme.contrast};
    top: 100%;
    width: 100%;
    padding: 10px;
  }

  .react-dropdown-select:focus-within {
    box-shadow: none !important;
  }

  .react-dropdown-select-item {
    font-size: ${({ theme }) => theme.size.md} !important;
    border-bottom: 1px solid ${({ border, theme }) => (border ? theme.dark : "transparent")} !important;
    padding: 10px;

    ${({ highlight }) =>
      highlight &&
      css`
        &:first-child,
        &:nth-child(2) {
          position: relative;

          &:after {
            content: "☆";
            position: absolute;
            right: 0;
            top: 0;
            width: 25px;
            height: 25px;
            transform: translate(0px, 10px);
          }
        }
        &:nth-child(2) {
          border-bottom: 1px solid #555555;
        }
      `}
  }

  .react-dropdown-select-item-selected {
    color: ${({ theme }) => theme.authLogo} !important;
    background: transparent !important;
  }

  .noResultsFound {
    font-size: ${({ theme }) => theme.size.md};
    padding: 8px;
    text-align: center;
    color: #757575;
  }
  .react-dropdown-select-content {
    align-items: center;
    flex-wrap: nowrap;

    span {
      font-size: ${({ theme }) => theme.size.md};
      /* color: #757575; */
      margin-left: 5px;
      color: ${({ error, theme, backgroundColor }) =>
        error ? theme.color.error : backgroundColor ? "rgb(255,255,255)" : theme.contrast};
      margin-top: 2px;
      background-color: ${({ theme, multi }) => (multi ? theme.color.primary : "transparent")};
      /* max-width: calc(100% - 60px); */
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .react-dropdown-select-option span {
    color: #ffffff;
  }

  .react-dropdown-select-dropdown-handle {
    margin-top: 6px;
  }

  ${({ isTransparent }) =>
    isTransparent &&
    css`
      border-color: transparent !important;
      background-color: transparent;
      box-shadow: none !important;
    `}

  ${({ input }) =>
    input &&
    css`
      padding-left: 15px !important;
      font-size: 18px;
      border-color: ${({ theme }) => theme.dark} !important;
      background-color: ${({ theme }) => theme.dark};
      box-shadow: none !important;

      .react-dropdown-select-dropdown-handle {
        display: none;
      }

      .react-dropdown-select-content > span {
        font-size: 16px;
      }

      &:after {
        position: absolute;
        content: "";
        top: 7px;
        right: 0px;
        padding: 15px 25px;
        background-image: url(${icon});
        background-repeat: no-repeat;
        background-size: 20px 20px;
        background-position: center;
        border-left: 1px solid gray;
      }

      &:hover,
      &:focus,
      &:focus-within {
        border-color: transparent !important;
      }
    `}
`;

const Label = styled.label<{ error: boolean }>`
  color: ${({ error, theme }) => (error ? theme.color.error : "unset")};
  margin-bottom: 10px;
`;

export const CreateNewListLabel = styled.span`
  width: 110px;
  font-size: 12px;
  color: gray;
  position: absolute;
  top: 30px;
  right: 0;
  z-index: 1;
`;

interface IDropdownSelectProps {
  error?: boolean;
  highlight?: boolean;
  width?: number;
  border?: boolean;
  isTransparent?: boolean;
  input?: boolean;
  multi?: boolean;
  backgroundColor?: "purple" | "primary";
  height?: number;
}

const DropdownSelect = React.forwardRef<IDropdownSelectProps, any>((props, ref) => {
  return (
    <>
      {props.label && <Label error={props.error}>{props.label}</Label>}
      {props.displayCreateLabel && <CreateNewListLabel>Enter to create</CreateNewListLabel>}
      <CustomSelect
        createNewLabel="Enter to create {search}"
        searchFn={props.searchFn}
        highlight={props.highlight}
        multi={props.multi}
        backgroundColor={props.backgroundColor}
        ref={ref}
        height={props.height}
        {...props}
      />
      {props.error && <div className="errorMessage">{props.error}</div>}
    </>
  );
});

DropdownSelect.displayName = "DropdownSelect";

DropdownSelect.defaultProps = {
  border: true,
};

export default DropdownSelect;
