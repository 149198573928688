import React from "react";
import { Link, useLocation } from "react-router-dom";
import { connect, ConnectedProps } from "react-redux";
import { Image, Nav, Navbar } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { openSidebar, closeSidebar } from "store/actions/sidebar.actions";
import { CustomNavbar, SlideLogo } from "./styled";
import { RootState } from "store/store";
import { INavigation } from "modules/Client/routes";
import RADLogo from "components/Icons/RADLogo";
import logo from "assets/images/VaticAI Logo_Horizontal - Black.png";

const mapStateToProps = ({ sidebar }: RootState) => ({
  sidebar,
});

interface ITopbarProps extends PropsFromRedux {
  rightLinks?: INavigation[];
}

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const Topbar: React.FunctionComponent<ITopbarProps> = ({ sidebar, dispatch, rightLinks }) => {
  const location = useLocation();
  // const dummy = [];

  // const onMenuButtonClick = () => {
  //   if (sidebar.close) {
  //     dispatch(openSidebar());
  //   } else {
  //     dispatch(closeSidebar());
  //   }
  // };

  return (
    <CustomNavbar
      open={!sidebar.close}
      data-testid="topbar"
      sticky="top"
      expand="lg"
      className="sticky-nav position-fixed"
    >
      <SlideLogo open={sidebar.close}>
        {/* <RADLogo className="rad-logo" /> */}
        <Image src={logo} className="rad-logo" alt="logo" />
      </SlideLogo>
      {/* <div className="menuToggle" onClick={() => onMenuButtonClick()}>
        <FontAwesomeIcon icon={["fas", sidebar.close ? "align-right" : "align-left"]} size="sm" />
      </div> */}
      {/* <Navbar.Toggle aria-controls="basic-navbar-nav">
        <FontAwesomeIcon icon={["fas", "bars"]} size="sm" />
      </Navbar.Toggle> */}
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav>
          <Nav.Link
            className={`${location.pathname.includes("pricing") ? "link-active" : ""}`}
            as={Link}
            to="/app/pricing/"
          >
            Packages
          </Nav.Link>
          {/* {dummy
            ? dummy.map((route, i) =>
                route.children ? (
                  <NavDropdown key={"menuTopLeft_" + i} title={route.text}>
                    {route.children.map((subRoute, j) => (
                      <NavDropdown.Item
                        key={"menuTopLeftSub_" + i + "_" + j}
                        as={Link}
                        to={subRoute.path}
                      >
                        {subRoute.text}
                      </NavDropdown.Item>
                    ))}
                  </NavDropdown>
                ) : (
                  <Nav.Link
                    key={"menuTopLeft_" + i}
                    as={Link}
                    to={route.path}
                    title={route.text}
                    className={`mr-4 ${location.pathname.includes(route.path) ? "active" : ""}`}
                  >
                    {route.text}
                  </Nav.Link>
                )
              )
            : ""} */}
        </Nav>
        <div className="setting-button">
          <button type="button" className="btn btn-primary d-none">
            5 days left | Get Premium Access
          </button>
          {rightLinks
            ? rightLinks.map((route, index: number) => (
                <Nav.Link
                  key={`menuTopRight_${route.path}`}
                  as={Link}
                  to={route.path || ""}
                  title={route.text}
                  className={`nav-icon ${
                    route.path && location.pathname.includes(route.path) ? "active" : ""
                  }`}
                >
                  {/* {route.icon && route.icon.type === "fontAwesome" ? (
                    <FontAwesomeIcon fill="white" icon={route.icon.name} size="sm" />
                  ) : null} */}
                </Nav.Link>
              ))
            : null}
        </div>
      </Navbar.Collapse>
    </CustomNavbar>
  );
};

export default connector(Topbar);
