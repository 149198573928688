import { AnyAction } from "redux";
import * as types from "../action-types/targeting.types";
import defaultState from "../states/targeting.state";

const targetingReducer = (state = defaultState, action: AnyAction) => {
  switch (action.type) {
    case types.GET_TARGETING_REQUEST:
      state = { ...state, loading: true };
      break;

    case types.GET_TARGETING_SUCCESS:
      state = {
        ...state,
        loading: false,
        data: action.payload,
      };
      break;

    case types.GET_TARGETING_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    default:
  }

  return state;
};

export default targetingReducer;
