export const AUTH_BASE_ROUTE = "/auth";
export const LOGIN = `login/`;
export const REGISTER = `register/`;
export const RESET_PASSWORD = `reset-password/`;
export const FORGET_PASSWORD = `forget-password/`;

export const REGISTER_SUCCESSFULLY = `register-success`;
export const VERIFIED_SUCCESSFULLY = `verified/`;
export const VERIFIED_FAILED = "failed-verified/";
export const ALREADY_VERIFIED = "already-verified/";
