import styled, { css } from "styled-components/macro";

export const TextAreaStyled = styled.textarea<{ $height?: number; $readonly?: boolean }>`
  outline: none;
  border-color: ${({ theme }) => theme.inputDark};
  background: ${({ theme }) => theme.inputDark};
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  color: ${({ theme }) => theme.contrast};
  height: ${({ $height }) => ($height ? `${$height}px` : "100%")};
  font-size: 14px;

  ${({ $readonly }) =>
    $readonly &&
    css`
      color: transparent;
      text-shadow: 0 0 0 ${({ theme }) => theme.contrast};
      cursor: default;
    `}
`;
