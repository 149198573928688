import { SelectStyled } from "components/Select/styled";
import styled from "styled-components";

export const DatePickerPopup = styled.div`
  background-color: ${({ theme }) => theme.background};
  padding: 10px 15px;
  border-radius: 3px;
  flex-direction: column;
  display: flex;
  position: absolute;
  inset: 0px auto auto 0px;
  justify-content: space-around;
  font-size: 14px;
  color: rgb(33, 37, 41);
  margin-top: 10px;

  .button-wrapper {
    margin-top: 10px;
    display: flex;
    gap: 10px;
    justify-content: end;
  }
`;

export const EmptyReport = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  width: 100%;
  color: ${({ theme }) => theme.contrast};
`;

export const CampaignPerformanceStyled = styled.div`
  padding: 10px;

  .performance-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    .performance-filter {
      display: flex;
      background-color: ${({ theme }) => theme.alertModalBackground};
      padding: 15px 15px;
      border-radius: 10px;
      font-size: 12px;
      align-items: center;

      .date-filter {
        color: ${({ theme }) => theme.contrast};
        font-weight: bold;
        padding-right: 10px;
        border-right: 1px solid ${({ theme }) => theme.text};
      }

      .date-range {
        margin-right: 10px;
        width: 250px;
        padding-left: 10px;
        color: ${({ theme }) => theme.text};
        display: flex;
        justify-content: space-between;
      }

      .icon-wrapper {
        display: flex;
        cursor: pointer;
        align-items: center;

        svg {
          width: 12px;
          height: 12px;

          path,
          line {
            fill: ${({ theme }) => theme.contrast};
            stroke: ${({ theme }) => theme.contrast};
          }
        }
      }
    }

    .metric-compare {
      display: flex;
      gap: 10px;

      .metric-item {
        width: 200px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;

        &:first-child {
          background-color: ${({ theme }) => theme.color.primary};
        }

        &:last-child {
          background-color: ${({ theme }) => theme.color.purple};
        }
      }
    }
  }

  .form-select {
    width: 150px;
    font-size: 12px;
    border-radius: 3px;
  }

  .graph-section {
    /* overflow-y: auto; */
    /* min-height: 200px; */
    max-height: 400px;
    margin-top: 20px;
    color: ${({ theme }) => theme.contrast};

    .table-loader {
      height: 300px;
    }

    .select-wrapper {
      //
    }
  }

  .performance-tools {
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* color: ${({ theme }) => theme.contrast}; */
  }
`;
