import { AnyAction } from "redux";
import * as LOCATION_ACTIONS from "../action-types/countries.types";
import locationsState from "../states/keyword-locations.state";

const locationReducer = (state = locationsState, action: AnyAction) => {
  switch (action.type) {
    case LOCATION_ACTIONS.GET_COUNTRIES:
      state = { ...state, loading: true };
      break;
    case LOCATION_ACTIONS.GET_COUNTRIES_SUCCESS:
      state = {
        ...state,
        countries: action.countries,
        loading: false,
      };
      break;
    case LOCATION_ACTIONS.GET_COUNTRIES_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;
    default:
  }

  return state;
};

export default locationReducer;
