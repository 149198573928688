import styled from "styled-components/macro";

export const AddFilterButton = styled.span`
  border-radius: 50px;
  cursor: pointer;
  background: ${({ theme }) => theme.color.primary};
  color: white;
  padding: 2px 10px;
  transition: 0.3s;

  &:hover {
    opacity: 0.65;
  }
`;

export const ColumnOption = styled.li<{ disabled?: boolean }>`
  padding: 7px 7px 7px 15px;
  cursor: pointer;
  background: ${({ disabled }) => (disabled ? "gray" : "#000000")};
  pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};

  &:hover {
    background: ${({ theme }) => theme.color.primary};
  }
`;

export const FilteringItem = styled.div`
  font-size: 10px;
  padding: 10px 40px 10px 10px;
  background: ${({ theme }) => theme.nav};
  position: relative;

  .multi-item {
    margin: 5px 15px;
    padding: 0;
  }

  .current-column {
    color: ${({ theme }) => theme.color.primary};
    font-weight: bold;
  }

  .remove-filter {
    &:after {
      content: "✕";
      position: absolute;
      width: 10px;
      height: 10px;
      top: 3px;
      right: 3px;
      cursor: pointer;
    }
  }
`;

export const PaymentFilterStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
  transition: 0.3s;

  .filter-content {
    min-width: 500px;
  }

  .dropdown-select {
    min-width: 140px;
    min-height: 45px;
    background: #000000;
    border-color: #000000 !important;
  }

  .current-input {
    display: flex;
    font-size: 10px;
    align-items: stretch;
    position: relative;

    .input-text {
      border: none;
    }

    .current-filter-wrapper {
      position: relative;
      display: flex;

      .filter-form {
        display: flex;
      }
    }

    .selecting-col {
      background: ${({ theme }) => theme.nav};
      min-height: 45px;
      display: flex;
      align-items: center;
      padding: 5px 15px;
    }
  }

  .filtering-tab {
    margin-right: 10px;
    display: flex;
    align-items: center;
    gap: 20px;
    height: 50px;
  }

  .status-selector {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  .hide-filter {
    width: 15px;
    height: 15px;
    margin-left: 10px;
    position: absolute;
    top: 15px;
    right: -30px;

    &:after {
      content: "✕";
      width: 15px;
      height: 15px;
      cursor: pointer;
    }
  }
`;

export const FilterListSelector = styled.div`
  margin-top: 5px;
  ul {
    width: 200px;
    margin: 0;
    list-style: none;
    text-align: left;
    padding: 0px;
  }
`;

export const PaymentFilterPopover = styled.div`
  padding: 10px 10px;
  background: ${({ theme }) => theme.nav};
  margin-top: 10px;

  .date-range-actions {
    display: flex;
    gap: 10px;
    margin-top: 10px;
    justify-content: end;
  }

  .filters {
    font-weight: bold;
  }

  .filter-popover {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;

    .filter-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;

      .filter-selector {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .filter-column {
        display: flex;
        align-items: center;
        width: 120px;
      }
    }
  }

  .filter-action {
    display: flex;
    gap: 20px;
    justify-content: flex-end;
  }
`;
