import React from "react";
import { Routes, Navigate, Route } from "react-router-dom";
import { connect, ConnectedProps, useDispatch } from "react-redux";
import { adminAppRoutes } from "./routes";
import * as AUTH_ROUTES from "modules/Auth/routes";
import { getBankList } from "store/actions/admin.actions";
import { fetchContentCategories } from "store/actions/categories.actions";
import { RootState } from "store/store";
import { getTargeting } from "store/actions/targeting.actions";

interface IAdminAppProps extends PropsFromRedux {}

const mapStateToProps = ({ user, admin }: RootState) => ({
  user,
  admin,
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const AdminApp: React.FunctionComponent<IAdminAppProps> = ({ user, admin }) => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getBankList());
    dispatch(fetchContentCategories());
    dispatch(getTargeting());
  }, []);

  return (
    <Routes>
      {user.accessToken ? (
        <React.Fragment>
          {adminAppRoutes.map(({ path, component: Component }) => {
            return <Route key={path} path={path} element={<Component />} />;
          })}
        </React.Fragment>
      ) : (
        <Route
          path="*"
          element={<Navigate to={`${AUTH_ROUTES.AUTH_BASE_ROUTE}/${AUTH_ROUTES.LOGIN}`} />}
        />
      )}

      <Route path="*" element={<Navigate to="/404" />} />
    </Routes>
  );
};

export default connector(AdminApp);
