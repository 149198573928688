import { axiosForReverseAds } from "axiosConfig";
import { TableActionButton } from "components/BottomBar/styled";
import DateRange from "components/DateRange";
import { AdsIcon } from "components/Icons";
import CalendarIcon from "components/Icons/CalendarIcon";
import ExportIcon from "components/Icons/ExportIcon";
import MetricSelector from "components/MetricSelector";
import Pagination from "components/Pagination";
import Spinner from "components/Spinner";
import useOnClickOutside from "hooks/useClickOutside";
import { displayDate, formatDateTime, formatDateToServer } from "constants/utility/date";
import { handlingError } from "constants/utility/error";
import { numberWithCommas, twoDecimal } from "constants/utility/number";
import { isSameDay } from "date-fns/esm";
import IGetCampaignReportResponse from "models/get-campaign-report-response.interface";
import {
  COMPARE_HEADERS,
  COMPARE_SELECT,
  COMPARE_VALUE,
} from "modules/Client/pages/CampaignReport/Performance/constants";
import CreativeTable from "components/CreativeTable";
import DynamicChart from "components/DynamicChart";
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { OverlayInjectedProps } from "react-bootstrap/esm/Overlay";
import { Range } from "react-date-range";
import { connect, ConnectedProps, useDispatch } from "react-redux";
import { Column, TableInstance, usePagination, useTable } from "react-table";
import { showExportPopup } from "store/actions/popup.actions";
import { ICampaignReportData, IDailyReport } from "store/states/campaigns.state";
import { RootState } from "store/store";
import { CampaignPerformanceStyled, EmptyReport, DatePickerPopup } from "./styled";
import {
  columnWithLocation,
  columnWithDate,
  columnWithDevice,
  columnWithKeyword,
  columnWithAds,
} from "./columns";
import Select from "components/Select";
import { SingleValue } from "react-select";
// import sample from "./sample";
// import geoMock from "./geo.mock";
// import { keywordmock } from "./keyword.mock";

interface ICampaignPerformanceProps extends PropsFromRedux {}

const mapStateToProps = ({ currentCampaign }: RootState) => ({
  currentCampaign,
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const CampaignPerformance: React.FunctionComponent<ICampaignPerformanceProps> = ({
  currentCampaign,
}) => {
  const [chartDate, setChartData] = React.useState<Array<ICampaignReportData>>([]);
  const [dateListReport, setDateListReport] = React.useState<Array<ICampaignReportData>>([]);

  const [currentFilter, setCurrentFilter] = React.useState({ value: "date", label: "Date" });

  const dateTableInstance = useTable(
    {
      columns: columnWithDate,
      // columns: columnWithLocation,
      data: dateListReport,
      initialState: { pageSize: 10, pageIndex: 0 },
    },
    usePagination
  );

  const deviceTableInstance = useTable(
    {
      columns: columnWithDevice,
      data: dateListReport,
      initialState: { pageSize: 10, pageIndex: 0 },
    },
    usePagination
  );

  const adTableInstance = useTable(
    {
      columns: columnWithAds,
      data: dateListReport,
      initialState: { pageSize: 10, pageIndex: 0 },
    },
    usePagination
  );

  const keywordTableInstance = useTable(
    {
      columns: columnWithKeyword,
      data: dateListReport,
      initialState: { pageSize: 10, pageIndex: 0 },
    },
    usePagination
  );

  const locationTableInstance = useTable(
    {
      columns: columnWithLocation,
      data: dateListReport,
      initialState: { pageSize: 10, pageIndex: 0 },
    },
    usePagination
  );

  const [currentTableInstance, setTableInstance] =
    React.useState<TableInstance<ICampaignReportData>>(dateTableInstance);

  const [firstCompare, setFirstCompare] = React.useState<COMPARE_SELECT>(COMPARE_HEADERS[0].value);
  const [secondCompare, setSecondCompare] = React.useState<COMPARE_SELECT>(
    COMPARE_HEADERS[1].value
  );
  const [show, setShow] = React.useState(false);
  const target = React.useRef(null);
  const [isFetching, setFetching] = React.useState(false);

  const [selectionRange, setSelectionRange] = React.useState<Array<Range>>([
    {
      startDate: undefined,
      endDate: undefined,
      key: "selection",
    },
  ]);

  const [selectedRange, setSelectedRange] = React.useState<Range>({
    startDate: currentCampaign.data ? new Date(currentCampaign.data.startDate) : new Date(),
    endDate: currentCampaign.data ? new Date(currentCampaign.data.endDate) : new Date(),
    key: "selection",
  });

  useOnClickOutside(target, () => {
    setShow(false);
  });

  // React.useEffect(() => {
  //   if (isFetching) {
  //     window.setTimeout(() => {
  //       setFetching(false);
  //     }, 3000);
  //   }
  // }, [isFetching]);

  const renderPopup = (props: OverlayInjectedProps) => {
    return (
      <Tooltip
        style={{
          opacity: 1,
        }}
        ref={target}
        id="date-tooltip"
      >
        <DatePickerPopup
          {...props}
          style={{
            ...props.style,
          }}
        >
          {currentCampaign.data && (
            <DateRange
              ranges={selectionRange}
              minDate={new Date(currentCampaign.data.startDate)}
              maxDate={new Date()}
              onSelection={(range) => {
                setSelectionRange([range]);
                if (
                  range.startDate &&
                  range.endDate &&
                  !isSameDay(range.startDate, range.endDate)
                ) {
                  setShow(false);

                  if (range.startDate && range.endDate) {
                    fetchCampaignDateReport(new Date(range.startDate), new Date(range.endDate));
                  }

                  setSelectedRange(range);
                  setSelectionRange([
                    {
                      startDate: undefined,
                      endDate: undefined,
                      key: "selection",
                    },
                  ]);
                }
              }}
            />
          )}
        </DatePickerPopup>
      </Tooltip>
    );
  };

  const fetchDeviceReports = async () => {
    setFetching(true);
    if (currentCampaign.data) {
      try {
        const {
          data: { data },
        } = await axiosForReverseAds.get<IGetCampaignReportResponse>(
          `/campaign/platform-report/${currentCampaign.data.id}`
        );

        const sortedDeviceReport = Object.values(
          data
            .sort((a, b) => a.date.localeCompare(b.date))
            .reduce((acc, cur) => {
              // if (acc[cur.date] && cur.source !== "beeswax") {
              //   acc = {
              //     ...acc,
              //   };
              // } else {
              acc = {
                ...acc,
                [cur.date]: cur,
              };
              // }
              return acc;
            }, {} as { [key: string]: IDailyReport })
        ) as Array<IDailyReport>;

        setDateListReport(sortedDeviceReport);
        setChartData(sortedDeviceReport);
      } catch (error: any) {
        handlingError(error);
      }
    }
    setFetching(false);
  };

  const fetchKeywordReports = async () => {
    setFetching(true);
    if (currentCampaign.data) {
      try {
        const {
          data: { data },
        } = await axiosForReverseAds.get<IGetCampaignReportResponse>(
          `/campaign/segment-report/${currentCampaign.data.id}`
        );

        const sortedSegmentReport = Object.values(
          data
            .sort((a, b) => a.date.localeCompare(b.date))
            .reduce((acc, cur) => {
              // if (acc[cur.date] && cur.source !== "beeswax") {
              //   acc = {
              //     ...acc,
              //   };
              // } else {
              acc = {
                ...acc,
                [cur.date]: cur,
              };
              // }
              return acc;
            }, {} as { [key: string]: IDailyReport })
        ) as Array<IDailyReport>;

        setDateListReport(data);
        setChartData(sortedSegmentReport);
      } catch (error: any) {
        handlingError(error);
      }
    }
    setFetching(false);
  };

  const fetchCampaignDateReport = async (startDate: Date, endDate: Date) => {
    setFetching(true);
    const start = formatDateToServer(startDate);
    const end = formatDateToServer(endDate);

    if (currentCampaign.data) {
      try {
        const {
          data: { data },
        } = await axiosForReverseAds.get<IGetCampaignReportResponse>(
          `/campaign/performance/${currentCampaign.data.id}?from_date=${start}&to_date=${end}`
        );
        const sortedDateList = Object.values(
          data
            .sort((a, b) => a.date.localeCompare(b.date))
            .reduce((acc, cur) => {
              // if (acc[cur.date] && cur.source !== "beeswax") {
              //   acc = {
              //     ...acc,
              //   };
              // } else {
              acc = {
                ...acc,
                [cur.date]: cur,
              };
              // }
              return acc;
            }, {} as { [key: string]: IDailyReport })
        ) as Array<IDailyReport>;

        setDateListReport(data);
        setChartData(sortedDateList);
      } catch (error: any) {
        handlingError(error);
      }
    }
    setFetching(false);
  };

  const fetchCampaignGeoReport = async () => {
    setFetching(true);

    if (currentCampaign.data) {
      try {
        const {
          data: { data },
        } = await axiosForReverseAds.get<IGetCampaignReportResponse>(
          `/campaign/geo-report/${currentCampaign.data.id}`
        );

        const sortedGeoList = Object.values(
          data
            .sort((a, b) => a.date.localeCompare(b.date))
            .reduce((acc, cur) => {
              // if (acc[cur.date] && cur.source !== "beeswax") {
              //   acc = {
              //     ...acc,
              //   };
              // } else {
              acc = {
                ...acc,
                [cur.date]: cur,
              };
              // }
              return acc;
            }, {} as { [key: string]: IDailyReport })
        ) as Array<IDailyReport>;

        setDateListReport(data);
        setChartData(sortedGeoList);
      } catch (error: any) {
        handlingError(error);
      }
    }
    setFetching(false);
  };

  const fetchAdsCampaignReport = async () => {
    setFetching(true);

    if (currentCampaign.data) {
      try {
        const {
          data: { data },
        } = await axiosForReverseAds.get<IGetCampaignReportResponse>(
          `/campaign/ads-report/${currentCampaign.data.id}`
        );

        const sortedAdsReport = Object.values(
          data
            .sort((a, b) => a.date.localeCompare(b.date))
            .reduce((acc, cur) => {
              // if (acc[cur.date] && cur.source !== "beeswax") {
              //   acc = {
              //     ...acc,
              //   };
              // } else {
              acc = {
                ...acc,
                [cur.date]: cur,
              };
              // }
              return acc;
            }, {} as { [key: string]: IDailyReport })
        ) as Array<IDailyReport>;

        setDateListReport(data);
        setChartData(sortedAdsReport);
      } catch (error: any) {
        handlingError(error);
      }
    }
    setFetching(false);
  };

  const firstSummary = chartDate.reduce((acc, cur) => acc + (cur[firstCompare] || 0), 0);
  const secondSummary = chartDate.reduce((acc, cur) => acc + (cur[secondCompare] || 0), 0);

  const renderMetricSummary = (num: number, column: COMPARE_SELECT) => {
    return `${numberWithCommas(twoDecimal(num))}`;
    // ${column === COMPARE_VALUE.CTR || column === COMPARE_VALUE.CONVERSIONS ? "%" : ""}
    // `;
  };

  React.useEffect(() => {
    if (selectedRange.startDate && selectedRange.endDate) {
      fetchCampaignDateReport(new Date(selectedRange.startDate), new Date(selectedRange.endDate));
    }
  }, []);

  const onFilterChange = (option: SingleValue<{ value: string; label: string }>) => {
    option && setCurrentFilter(option);
    if (option?.value === "date") {
      if (selectedRange.startDate && selectedRange.endDate) {
        fetchCampaignDateReport(new Date(selectedRange.startDate), new Date(selectedRange.endDate));
      }
      setTableInstance(dateTableInstance);
    } else if (option?.value === "device") {
      fetchDeviceReports();
      setTableInstance(deviceTableInstance);
    } else if (option?.value === "ad") {
      fetchAdsCampaignReport();
      setTableInstance(adTableInstance);
    } else if (option?.value === "keyword") {
      fetchKeywordReports();
      setTableInstance(keywordTableInstance);
    } else if (option?.value === "geo") {
      fetchCampaignGeoReport();
      setTableInstance(locationTableInstance);
    }
  };

  const dispatch = useDispatch();

  const {
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state,
  } = currentTableInstance;

  return (
    <CampaignPerformanceStyled>
      <div className="performance-actions">
        {dateListReport.length ? (
          <OverlayTrigger show={show} trigger="click" placement="bottom" overlay={renderPopup}>
            <div className="performance-filter">
              <div className="date-filter">
                <span>Filter by Period</span>
              </div>
              <div className="date-range">
                {selectedRange.startDate && selectedRange.endDate && (
                  <span>
                    {`${formatDateTime(selectedRange.startDate)} - ${formatDateTime(
                      selectedRange.endDate
                    )}`}
                  </span>
                )}
                <div onClick={() => setShow(!show)} className="icon-wrapper">
                  <CalendarIcon />
                </div>
              </div>
            </div>
          </OverlayTrigger>
        ) : null}

        {currentCampaign.data?.portalUrl ? (
          <iframe
            id="embeded-dashboard"
            title="performance"
            src={currentCampaign.data.portalUrl}
            style={{
              width: "100%",
              height: "450px",
              display: "block",
              position: "relative",
            }}
          />
        ) : dateListReport.length ? (
          <div className="metric-compare">
            <MetricSelector
              callback={(val) => setFirstCompare(val)}
              compare={secondCompare}
              active={firstCompare}
              color="rgb(117,0,211)"
            >
              <span className="metric-key">{firstCompare}</span>
              <span className="metric-value">
                {renderMetricSummary(firstSummary, firstCompare)}
              </span>
            </MetricSelector>
            <MetricSelector
              callback={(val) => setSecondCompare(val)}
              compare={firstCompare}
              active={secondCompare}
              color="rgb(255,0,152)"
            >
              <span className="metric-key">{secondCompare}</span>
              <span className="metric-value">
                {renderMetricSummary(secondSummary, secondCompare)}
              </span>
            </MetricSelector>
          </div>
        ) : null}
      </div>
      <div className="graph-section">
        {isFetching ? (
          <div className="table-loader">
            <Spinner />
          </div>
        ) : dateListReport.length ? (
          <DynamicChart active={firstCompare} compare={secondCompare} dateList={chartDate} />
        ) : (
          <EmptyReport>
            <p>Your campaign doesn't have the report yet.</p>
          </EmptyReport>
        )}

        <div className="select-wrapper">
          <Select
            value={currentFilter}
            options={[
              { value: "date", label: "Date" },
              { value: "geo", label: "Locations" },
              { value: "ad", label: "Ads" },
              { value: "device", label: "Device" },
              { value: "keyword", label: "Keywords" },
            ]}
            onChange={(option) => {
              onFilterChange(option);
            }}
          />
        </div>

        {isFetching ? null : dateListReport.length ? (
          <CreativeTable table={currentTableInstance} />
        ) : null}
      </div>

      {dateListReport.length && !isFetching ? (
        <div className="performance-tools">
          <TableActionButton
            color="primary"
            onClick={() => {
              if (currentCampaign.data && selectedRange.startDate && selectedRange.endDate) {
                dispatch(
                  showExportPopup({
                    type: "performance",
                    data: {
                      startDate: selectedRange.startDate,
                      endDate: selectedRange.endDate,
                      id: currentCampaign.data.id,
                    },
                  })
                );
              }
            }}
          >
            <ExportIcon />
            <span>Export</span>
          </TableActionButton>
          <Pagination
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            pageOptions={pageOptions}
            pageCount={pageCount}
            gotoPage={gotoPage}
            nextPage={nextPage}
            previousPage={previousPage}
            pageIndex={state.pageIndex}
          />
        </div>
      ) : null}
    </CampaignPerformanceStyled>
  );
};

export default connector(CampaignPerformance);
