import { AnyAction } from "redux";
import * as actions from "../action-types/saved-keywords-list.types";
import savedListState from "../states/saved-list.state";

const saveKeywordsList = (state = savedListState, action: AnyAction) => {
  switch (action.type) {
    // get

    case actions.GET_SAVED_KEYWORDS_LIST_REQUEST:
      state = { ...state, loading: true };
      break;
    case actions.GET_SAVED_KEYWORDS_LIST_SUCCESS:
      state = {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
      break;
    case actions.GET_SAVED_KEYWORDS_LIST_FAILURE:
      state = {
        ...state,
        loading: false,
        data: [],
        error: action.error,
      };
      break;

    default:
  }

  return state;
};

export default saveKeywordsList;
