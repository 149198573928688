import React from "react";
import { useDispatch } from "react-redux";
import { getCampaigns } from "store/actions/campaigns.actions";
import { CampaignListTable } from "./styled";
import { ICampaign, ICampaignListData } from "store/states/campaigns.state";
import Checkbox from "components/Checkbox";
import ListTable from "components/ListTable";
import Spinner from "components/Spinner";
import { CampaignStatus, ENUM_CAMPAIGN_STATUS } from "store/states/campaign.state";
import { usePagination, useTable } from "react-table";
import { columns, FREEZE_COLUMNS } from "./column";
import { IFilterColumn } from "components/TableFilter";
import Pagination from "components/Pagination";
import { displayDate, formatDate } from "constants/utility/date";
import BottomBar from "components/BottomBar";
import {
  showCampaignDetailPopup,
  showNewCampaignPopup,
  showBuildCampaign,
} from "store/actions/popup.actions";
import Tooltip from "components/Tooltip";
import { popoverOfManageCampaign } from "constants/utility/messages";
import Popover from "components/Popover";
import EmptyState from "modules/Client/pages/UserCampaigns/EmptyState";

const duplicateImg = require("assets/images/duplicate.png");

type CampaignListProps = {
  onCampaignStatusChange?: (status: string, campaign: ICampaign) => void;
  onSelect: (id: string) => void;
  selectAll: (items: Array<string>) => void;
  unselectAll: (items: Array<string>) => void;
  selectedCampaigns: Array<string>;
  loading: boolean;
  campaigns: Array<ICampaignListData>;
  admin?: boolean;
  filterStatus?: CampaignStatus;
  currentFilters?: IFilterColumn;
  onSelectionDeleteButtonClick?: () => void;
  total: number;
  loaded: boolean;
};

type IColumnTooltip = {
  ctr: string;
  ctrUnique: string;
  ecpa: string;
  ecpc: string;
  ecpm: string;
};

const CampaignList = ({
  onSelect,
  selectAll,
  unselectAll,
  selectedCampaigns,
  loading,
  campaigns,
  admin,
  filterStatus,
  onSelectionDeleteButtonClick,
  currentFilters,
  total,
  loaded,
}: CampaignListProps) => {
  const dispatch = useDispatch();

  const hidden = columns
    .filter((col) => col.accessor === "userName")
    .map((col) => col.accessor) as any;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data: campaigns,
      manualPagination: true,
      pageCount: Math.ceil(total / 10),
      initialState: {
        pageSize: 10,
        pageIndex: 0,
        hiddenColumns: admin ? [] : hidden,
      },
    },
    usePagination
  );

  const currentItems = page.map((item) => item.original.id);
  const isAllChecked = currentItems.every((id) => selectedCampaigns.includes(id));

  const totalFreezeColumns = admin ? FREEZE_COLUMNS.length : FREEZE_COLUMNS.length - 1;

  // const [entering, setEntering] = React.useState<null | number>(null);

  React.useEffect(() => {
    if (pageIndex) {
      const filterObj = {
        username: currentFilters?.userName,
        name: currentFilters?.name,
        status: currentFilters?.status,
        owner_email: currentFilters?.owner_email,
        page: pageIndex + 1,
        start_from: currentFilters?.startDate && formatDate(currentFilters?.startDate.startDate),
        start_to: currentFilters?.startDate && formatDate(currentFilters?.startDate.endDate),
        end_from: currentFilters?.endDate && formatDate(currentFilters?.endDate.startDate),
        end_to: currentFilters?.endDate && formatDate(currentFilters?.endDate.endDate),
        created_from:
          currentFilters?.created_at && formatDate(currentFilters?.created_at.startDate),
        created_to: currentFilters?.created_at && formatDate(currentFilters?.created_at.endDate),
        source: currentFilters?.source,
      };

      dispatch(getCampaigns(filterObj));
    }
  }, [pageIndex]);

  React.useEffect(() => {
    if (currentFilters) {
      const filterObj = {
        username: currentFilters.userName,
        name: currentFilters.name,
        status: currentFilters.status,
        owner_email: currentFilters.owner_email,
        page: 1,
        start_from: currentFilters.startDate && formatDate(currentFilters.startDate.startDate),
        start_to: currentFilters.startDate && formatDate(currentFilters.startDate.endDate),
        end_from: currentFilters.endDate && formatDate(currentFilters.endDate.startDate),
        end_to: currentFilters.endDate && formatDate(currentFilters.endDate.endDate),
        created_from: currentFilters.created_at && formatDate(currentFilters.created_at.startDate),
        created_to: currentFilters.created_at && formatDate(currentFilters.created_at.endDate),
        source: currentFilters?.source,
      };
      gotoPage(0);
      dispatch(getCampaigns(filterObj));
    }
  }, [currentFilters]);

  return (
    <CampaignListTable admin={admin} className="tableWrap">
      {loading ? (
        <div className="campaigns-loading">
          <Spinner />
        </div>
      ) : campaigns.length ? (
        <div className="table-container">
          <div className="table-responsive">
            <div className="freeze">
              <ListTable {...getTableProps()}>
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      <th key={`${headerGroup.id}_checkbox-column`}>
                        {/* {page.length > 0 && (
                          <Checkbox
                            centered
                            checked={isAllChecked}
                            onSelect={() => {
                              isAllChecked
                                ? unselectAll(page.map((row) => row.original.id))
                                : selectAll(currentItems);
                            }}
                          />
                        )} */}
                      </th>
                      {headerGroup.headers.map(
                        (column, index) =>
                          index < totalFreezeColumns && (
                            <th {...column.getHeaderProps()}>{column.render("Header")}</th>
                          )
                      )}
                    </tr>
                  ))}
                </thead>

                <tbody {...getTableBodyProps()}>
                  {page.map((row, rowIndex) => {
                    prepareRow(row);
                    return (
                      <tr
                        {...row.getRowProps()}
                        // onMouseEnter={() => setEntering(rowIndex)}
                        // onMouseLeave={() => setEntering(null)}
                      >
                        <td
                          // style={{
                          //   filter: entering === rowIndex ? "sepia(100%)" : "none",
                          // }}
                          key={`${row.original.id}_checkbox-cell`}
                        >
                          <Checkbox
                            centered
                            checked={selectedCampaigns.includes(row.original.id)}
                            onSelect={() => {
                              onSelect(row.original.id);
                            }}
                          />
                        </td>
                        {row.cells.map((cell, index) => {
                          if (cell.column.id === "name") {
                            return (
                              <td
                                style={
                                  {
                                    // filter: entering === rowIndex ? "sepia(100%)" : "none",
                                    // pointerEvents: cell.row.original.state ? "auto" : "none",
                                  }
                                }
                                {...cell.getCellProps()}
                              >
                                <span
                                  className="link-to-overview"
                                  onClick={() => {
                                    if (
                                      cell.row.original.status !== ENUM_CAMPAIGN_STATUS.DRAFT ||
                                      admin
                                    ) {
                                      dispatch(showCampaignDetailPopup(cell.row.original.id));
                                    } else {
                                      dispatch(showBuildCampaign(cell.row.original.id));
                                    }
                                  }}
                                >
                                  {cell.value}
                                  {/* </Link> */}
                                </span>
                                {!admin && (
                                  <Tooltip
                                    color="rgb(51,51,51)"
                                    id="duplicate-campaign"
                                    background="rgb(27,255,118)"
                                    content="Duplicate Campaign"
                                  >
                                    <span
                                      onClick={() => {
                                        dispatch(
                                          showNewCampaignPopup({
                                            id: row.original.id,
                                            name: row.original.name,
                                          })
                                        );
                                      }}
                                      className="duplicate-button"
                                    >
                                      <img src={duplicateImg} alt="duplicate" />
                                    </span>
                                  </Tooltip>
                                )}
                              </td>
                            );
                          } else {
                            return (
                              index < totalFreezeColumns && (
                                <td
                                  // style={{
                                  //   filter: entering === rowIndex ? "sepia(100%)" : "none",
                                  // }}
                                  {...cell.getCellProps()}
                                >
                                  {cell.render("Cell")}
                                </td>
                              )
                            );
                          }
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </ListTable>
            </div>

            <div className="scrollable">
              <ListTable {...getTableProps()}>
                <thead>
                  {headerGroups.map((headerGroup) => {
                    return (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column, index) => {
                          if (index >= totalFreezeColumns) {
                            if (popoverOfManageCampaign[column.id as keyof IColumnTooltip]) {
                              return (
                                <th key={column.id}>
                                  <Popover
                                    id={popoverOfManageCampaign[column.id].id}
                                    placement={popoverOfManageCampaign[column.id].placement}
                                    color={popoverOfManageCampaign[column.id].color}
                                    title={popoverOfManageCampaign[column.id].title}
                                    content={popoverOfManageCampaign[column.id].content}
                                    bottomContent={popoverOfManageCampaign[column.id].bottomContent}
                                  >
                                    <>{column.render("Header")}</>
                                  </Popover>
                                </th>
                              );
                            } else {
                              return <th key={column.id}>{column.render("Header")}</th>;
                            }
                          }
                          return null;
                        })}
                      </tr>
                    );
                  })}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {page.map((row, rowIndex) => {
                    prepareRow(row);
                    return (
                      <tr
                        // onMouseEnter={() => setEntering(rowIndex)}
                        // onMouseLeave={() => setEntering(null)}
                        {...row.getRowProps()}
                      >
                        {row.cells.map((cell, colIndex) => {
                          if (cell.column.id === "created_at") {
                            return (
                              <td
                                // style={{
                                //   filter: entering === rowIndex ? "sepia(100%)" : "none",
                                // }}
                                {...cell.getCellProps()}
                              >
                                {displayDate(new Date(cell.value))}
                              </td>
                            );
                          } else {
                            return (
                              colIndex >= totalFreezeColumns && (
                                <td
                                  {...cell.getCellProps()}
                                  // style={{
                                  //   filter: entering === rowIndex ? "sepia(100%)" : "none",
                                  // }}
                                >
                                  {cell.render("Cell")}
                                </td>
                              )
                            );
                          }
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </ListTable>
            </div>
          </div>
          {page.length > 0 && (
            <BottomBar
              onExport={{
                type: "campaigns",
              }}
              isAllChecked={isAllChecked}
              selectedItems={selectedCampaigns}
              selectAllCallback={() => {
                isAllChecked
                  ? unselectAll(page.map((row) => row.original.id))
                  : selectAll(currentItems);
              }}
              onSelectionDeleteButtonClick={() =>
                onSelectionDeleteButtonClick && onSelectionDeleteButtonClick()
              }
              saveList={false}
            >
              <Pagination
                canPreviousPage={canPreviousPage}
                canNextPage={canNextPage}
                pageOptions={pageOptions}
                pageCount={pageCount}
                gotoPage={gotoPage}
                nextPage={nextPage}
                previousPage={previousPage}
                pageIndex={pageIndex}
              />
            </BottomBar>
          )}
        </div>
      ) : admin ? (
        <div className="empty-search">
          <p>Campaign not found.</p>
        </div>
      ) : (
        <EmptyState />
      )}
    </CampaignListTable>
  );
};

export default CampaignList;
