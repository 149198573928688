import styled from "styled-components/macro";

export const StyledInputRangeNumber = styled.div`
  display: flex;
  flex-direction: column;
  width: 220px;
  padding: 5px 10px;

  .range-wrapper {
    display: flex;
    margin-bottom: 5px;
  }

  .range-between {
    display: flex;
    align-items: center;
    padding: 10px;
  }

  .slider {
    position: relative;
    margin-bottom: 10px;
  }

  .slider-track,
  .slider-range {
    border-radius: 3px;
    height: 5px;
    position: absolute;
  }

  .slider-track {
    background-color: white;
    width: 100%;
    z-index: 1;
  }

  .slider-range {
    background-color: ${({ theme }) => theme.color.primary};
    z-index: 2;
  }

  .thumb,
  .thumb::-webkit-slider-thumb {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
  }

  .thumb {
    pointer-events: none;
    position: absolute;
    height: 0;
    width: 200px;
    outline: none;

    &:first-child {
      z-index: 3;
    }

    &:nth-child(2) {
      z-index: 4;
    }

    ::-webkit-slider-thumb {
      background-color: ${({ theme }) => theme.color.primary};
      border: none;
      border-radius: 50%;
      box-shadow: 0 0 1px 1px white;
      cursor: pointer;
      height: 10px;
      width: 10px;
      margin-top: 4px;
      pointer-events: all;
      position: relative;
    }

    /* For Firefox browsers */
    ::-moz-range-thumb {
      background-color: ${({ theme }) => theme.color.primary};
      border: none;
      border-radius: 50%;
      box-shadow: 0 0 1px 1px white;
      cursor: pointer;
      height: 10px;
      width: 10px;
      margin-top: 4px;
      pointer-events: all;
      position: relative;
    }
  }

  .input-number {
    padding-left: 10px;
    width: 100%;
    height: 30px;
    border: 0;

    color: #ffffff;
    background-color: #000000;
    border-radius: 2px;

    ::placeholder,
    ::-webkit-input-placeholder {
      opacity: 0.5;
    }
    :-ms-input-placeholder {
      opacity: 0.5;
    }

    &:focus {
      outline: 1px solid rgb(0, 0, 0);
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  }
`;
