import { ICompareList } from "store/states/compare.state";
import { IErrorMessage } from "../../services/types";

// save

export const SAVE_COMPARE = "compare-actions/SAVE_COMPARE";

export interface SaveCompareAction {
  type: typeof SAVE_COMPARE;
  payload: Array<string>;
}

export const SAVE_COMPARE_REQUEST = "compare-actions/SAVE_COMPARE_REQUEST";
export interface SaveCompareRequestAction {
  type: typeof SAVE_COMPARE_REQUEST;
}

export const SAVE_COMPARE_SUCCESS = "compare-actions/SAVE_COMPARE_SUCCESS";
export interface SaveCompareSuccessAction {
  type: typeof SAVE_COMPARE_SUCCESS;
  payload: ICompareList;
}

export const SAVE_COMPARE_FAILURE = "compare-actions/SAVE_COMPARE_FAILURE";
export interface SaveCompareFailureAction {
  type: typeof SAVE_COMPARE_FAILURE;
  error: IErrorMessage;
}

// get

export const GET_COMPARE = "compare-actions/GET_COMPARE";

export interface GetCompareAction {
  type: typeof GET_COMPARE;
}

export const GET_COMPARE_REQUEST = "compare-actions/GET_COMPARE_REQUEST";
export interface GetCompareRequestAction {
  type: typeof GET_COMPARE_REQUEST;
}

export const GET_COMPARE_SUCCESS = "compare-actions/GET_COMPARE_SUCCESS";
export interface GetCompareSuccessAction {
  type: typeof GET_COMPARE_SUCCESS;
  payload: Array<ICompareList>;
}

export const GET_COMPARE_FAILURE = "compare-actions/GET_COMPARE_FAILURE";
export interface GetCompareFailureAction {
  type: typeof GET_COMPARE_FAILURE;
  error: IErrorMessage;
}

// delete

export const DELETE_COMPARE = "compare-actions/DELETE_COMPARE";

export interface DeleteCompareAction {
  type: typeof DELETE_COMPARE;
  payload: Array<string>;
}

export const DELETE_COMPARE_REQUEST = "compare-actions/DELETE_COMPARE_REQUEST";
export interface DeleteCompareRequestAction {
  type: typeof DELETE_COMPARE_REQUEST;
}

export const DELETE_COMPARE_SUCCESS = "compare-actions/DELETE_COMPARE_SUCCESS";
export interface DeleteCompareSuccessAction {
  type: typeof DELETE_COMPARE_SUCCESS;
  payload: Array<string>;
}

export const DELETE_COMPARE_FAILURE = "compare-actions/DELETE_COMPARE_FAILURE";
export interface DeleteCompareFailureAction {
  type: typeof DELETE_COMPARE_FAILURE;
  error: IErrorMessage;
}
