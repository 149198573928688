import { INewsFeed } from "../states/admin-news-feed.state";

export const GET_ADMIN_NEWS_FEED = "admin-actions/GET_ADMIN_NEWS_FEED";

export interface GetAdminNewsFeedAction {
  type: typeof GET_ADMIN_NEWS_FEED;
  page: number;
  pageLimit: number;
}

export const GET_ADMIN_NEWS_FEED_REQUEST = "admin-actions/GET_ADMIN_NEWS_FEED_REQUEST";
export interface GetAdminNewsFeedRequestAction {
  type: typeof GET_ADMIN_NEWS_FEED_REQUEST;
}

export const GET_ADMIN_NEWS_FEED_SUCCESS = "admin-actions/GET_ADMIN_NEWS_FEED_SUCCESS";
export interface GetAdminNewsFeedSuccessAction {
  type: typeof GET_ADMIN_NEWS_FEED_SUCCESS;
  payload: INewsFeed[];
  total: number;
}

export const GET_ADMIN_NEWS_FEED_FAILURE = "admin-actions/GET_ADMIN_NEWS_FEED_FAILURE";
export interface GetAdminNewsFeedFailureAction {
  type: typeof GET_ADMIN_NEWS_FEED_FAILURE;
  error: string;
}
//

export const GET_NEWS_FEED_BY_ID = "admin-actions/GET_NEWS_FEED_BY_ID";
export const RESET_NEWS_FEED_BY_ID = "admin-actions/RESET_NEWS_FEED_BY_ID";
export interface GetNewsFeedByIdAction {
  type: typeof GET_NEWS_FEED_BY_ID;
  payload: number;
}

export const GET_NEWS_FEED_BY_ID_REQUEST = "admin-actions/GET_NEWS_FEED_BY_ID_REQUEST";
export interface GetNewsFeedByIdRequestAction {
  type: typeof GET_NEWS_FEED_BY_ID_REQUEST;
}

export const GET_NEWS_FEED_BY_ID_SUCCESS = "admin-actions/GET_NEWS_FEED_BY_ID_SUCCESS";
export interface GetNewsFeedByIdSuccessAction {
  type: typeof GET_NEWS_FEED_BY_ID_SUCCESS;
  payload: INewsFeed;
}

export const GET_NEWS_FEED_BY_ID_FAILURE = "admin-actions/GET_NEWS_FEED_BY_ID_FAILURE";
export interface GetNewsFeedByIdFailureAction {
  type: typeof GET_NEWS_FEED_BY_ID_FAILURE;
  error: string;
}

//

export const UPDATE_ADMIN_NEWS_FEED = "admin-actions/UPDATE_ADMIN_NEWS_FEED";
export interface UpdateAdminNewsFeedAction {
  type: typeof UPDATE_ADMIN_NEWS_FEED;
  payload: INewsFeed;
}

export const UPDATE_ADMIN_NEWS_FEED_REQUEST = "admin-actions/UPDATE_ADMIN_NEWS_FEED_REQUEST";
export interface UpdateAdminNewsFeedRequestAction {
  type: typeof UPDATE_ADMIN_NEWS_FEED_REQUEST;
}

export const UPDATE_ADMIN_NEWS_FEED_SUCCESS = "admin-actions/UPDATE_ADMIN_NEWS_FEED_SUCCESS";
export interface UpdateAdminNewsFeedSuccessAction {
  type: typeof UPDATE_ADMIN_NEWS_FEED_SUCCESS;
  payload: INewsFeed;
}

export const UPDATE_ADMIN_NEWS_FEED_FAILURE = "admin-actions/UPDATE_ADMIN_NEWS_FEED_FAILURE";
export interface UpdateAdminNewsFeedFailureAction {
  type: typeof UPDATE_ADMIN_NEWS_FEED_FAILURE;
  error: string;
}
//

export const CREATE_ADMIN_NEWS_FEED = "admin-actions/CREATE_ADMIN_NEWS_FEED";
export interface CreateAdminNewsFeedAction {
  type: typeof CREATE_ADMIN_NEWS_FEED;
  payload: INewsFeed;
  history: any;
}

export const CREATE_ADMIN_NEWS_FEED_REQUEST = "admin-actions/CREATE_ADMIN_NEWS_FEED_REQUEST";
export interface CreateAdminNewsFeedRequestAction {
  type: typeof CREATE_ADMIN_NEWS_FEED_REQUEST;
}

export const CREATE_ADMIN_NEWS_FEED_SUCCESS = "admin-actions/CREATE_ADMIN_NEWS_FEED_SUCCESS";
export interface CreateAdminNewsFeedSuccessAction {
  type: typeof CREATE_ADMIN_NEWS_FEED_SUCCESS;
  payload: INewsFeed;
}

export const CREATE_ADMIN_NEWS_FEED_FAILURE = "admin-actions/CREATE_ADMIN_NEWS_FEED_FAILURE";
export interface CreateAdminNewsFeedFailureAction {
  type: typeof CREATE_ADMIN_NEWS_FEED_FAILURE;
  error: string;
}
//

export const DELETE_ADMIN_NEWS_FEED = "admin-actions/DELETE_ADMIN_NEWS_FEED";
export interface DeleteAdminNewsFeedAction {
  type: typeof DELETE_ADMIN_NEWS_FEED;
  payload: number;
}

export const DELETE_ADMIN_NEWS_FEED_REQUEST = "admin-actions/DELETE_ADMIN_NEWS_FEED_REQUEST";
export interface DeleteAdminNewsFeedRequestAction {
  type: typeof DELETE_ADMIN_NEWS_FEED_REQUEST;
}

export const DELETE_ADMIN_NEWS_FEED_SUCCESS = "admin-actions/DELETE_ADMIN_NEWS_FEED_SUCCESS";
export interface DeleteAdminNewsFeedSuccessAction {
  type: typeof DELETE_ADMIN_NEWS_FEED_SUCCESS;
  payload: number;
}

export const DELETE_ADMIN_NEWS_FEED_FAILURE = "admin-actions/DELETE_ADMIN_NEWS_FEED_FAILURE";
export interface DeleteAdminNewsFeedFailureAction {
  type: typeof DELETE_ADMIN_NEWS_FEED_FAILURE;
  error: string;
}
