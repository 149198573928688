import React from "react";
import { useParams } from "react-router-dom";
import { getNewsFeedById } from "store/actions/admin-news-feed.actions";
import { INewsFeed } from "store/states/admin-news-feed.state";
import { NewsFeedDetailStyled } from "./styled";
import { useDispatch } from "react-redux";
import Spinner from "components/Spinner";
import { formatDateTime } from "constants/utility/date";
import { NewsTag } from "../NewsFeed/styled";
interface INewsFeedDetail {
  feed: INewsFeed | null;
  loading: boolean;
}

const NewsFeedDetail: React.FunctionComponent<INewsFeedDetail> = ({ feed, loading }) => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getNewsFeedById(Number(id)));
  }, [id]);

  return (
    <NewsFeedDetailStyled>
      {!feed || loading ? (
        <div className="spinner-wrapper">
          <Spinner />
        </div>
      ) : (
        <div className="content-wrapper">
          <img src={process.env.REACT_APP_IMAGES_STORAGE} alt="" />
          <NewsTag tag={feed.category.name}>{feed.category.name} </NewsTag>

          <div className="feed-detail">
            <div />
            <p className="publish-date">
              Publish Date: {formatDateTime(new Date(feed.publishedDate), true)}
            </p>
          </div>
        </div>
      )}
    </NewsFeedDetailStyled>
  );
};

export default NewsFeedDetail;
