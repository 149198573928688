import styled from "styled-components/macro";

export const LinkText = styled.span`
  color: ${({ theme }) => theme.homeLink};
  font-style: italic;
  font-style: underline;
  margin-left: 4px;
  margin-right: 4px;
  cursor: pointer;
`;

export const HomeStyled = styled.div<{ isEmpty: boolean }>`
  margin-top: 20px;
  max-height: calc(100vh - 85px);

  .page-loading {
    height: 100vh;
  }

  ::-webkit-scrollbar {
    width: 10px;
  }

  .keyword-tools-title {
    margin-bottom: 20px;
    font-weight: 600;
  }

  .keywords-history {
    .recently-title {
      font-weight: 600;
    }
    transition: 0.1s;
    border-bottom: 1px solid ${({ theme }) => theme.border} !important;

    &:last-child {
      border: none;
    }
  }

  .keyword-tool-item {
    height: 200px;
    width: 100%;
    background-color: ${({ theme }) => theme.inputBorder};
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    align-items: center;
    transition: 0.1s;
    margin-bottom: 20px;

    > button {
      font-weight: 600;
      font-size: 16px;
    }

    > svg {
      width: 40px;
      height: 40px;

      polyline,
      rect,
      line,
      polygon {
        stroke: ${({ theme }) => theme.homeIcon};
      }
    }
  }

  .empty-text {
    font-size: 12px;
    margin-top: 15px;
    padding-left: 20px;
  }

  .page-title {
    font-size: 20px;
    font-weight: bold;
  }

  .home-wrapper {
    display: flex;
    color: ${({ theme }) => theme.text};

    .keyword-tools-wrapper {
      margin-top: 20px;
      width: 70%;
      margin-right: 20px;
      padding-right: 20px;
      overflow-y: auto;
      max-height: calc(100vh - ${({ isEmpty }) => (isEmpty ? 0 : 210)}px);
      transition: 0.1s;

      .keyword-tools {
        list-style: none;
        display: flex;
        padding: 0;
        gap: 20px;
        margin: 0;
      }

      .keywords-history {
        margin-bottom: 20px;
        border-bottom: 1px solid ${({ theme }) => theme.border};

        .loading-wrapper {
          height: 50px;
          position: relative;
        }

        .history-list {
          padding: 20px 0 0 0;
          margin: 0px 0 0px 20px;
        }

        &:last-child {
          border: none;
        }

        .saved-keywords-list {
          margin: 20px 0 0 0;
          display: flex;
          gap: 10px;
          flex-direction: column;
          padding-left: 20px;
        }
      }
    }

    .campaigns-wrapper {
      transition: 0.1s;
      width: 30%;
      padding: 20px;
      border-left: 1px solid ${({ theme }) => theme.border};
      min-width: 300px;

      .your-campaigns {
        margin-top: 0px;
      }
    }
  }

  .bottom-actions {
    display: flex;
    position: fixed;
    bottom: 0;
    width: calc(100% - 15.5rem);
    padding-right: 5px;

    .home-actions {
      transition: 0.1s;
      width: 70%;
      padding: 20px 0;
      background-color: ${({ theme }) => theme.background};
      display: flex;
      justify-content: space-evenly;

      svg {
        width: 15px;
        height: 15px;
      }

      .action-text {
        margin-right: 30px;
      }
    }

    .campaign-actions {
      width: 30%;
      padding: 20px 0;
      background-color: ${({ theme }) => theme.background};
      text-align: center;
      transition: 0.1s;

      svg {
        width: 15px;
        height: 15px;
      }

      .action-text {
        margin-right: 20px;
      }
    }
  }

  .campaign-list {
    min-width: 260px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px 20px 20px 0;
    margin: 0px 0 0px 20px;
    overflow-y: auto;
    max-height: calc(100vh - 270px);
    transition: 0.1s;
  }
`;
