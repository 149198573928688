import * as actions from "../action-types/campaign-payment.types";

import { IErrorMessage } from "services/types";
import { IPostCampaignData } from "constants/utility/campaign";

export function campaignPayment(
  payload: IPostCampaignData & { campaignId: string }
): actions.campaignPaymentAction {
  return {
    type: actions.CAMPAIGN_PAYMENT,
    payload,
  };
}

export function campaignPaymentRequest(): actions.campaignPaymentRequestAction {
  return {
    type: actions.CAMPAIGN_PAYMENT_REQUEST,
  };
}

export function campaignPaymentSuccess(
  payload: IPostCampaignData
): actions.campaignPaymentSuccessAction {
  return {
    type: actions.CAMPAIGN_PAYMENT_SUCCESS,
    payload,
  };
}

export function campaignPaymentFailure(error: IErrorMessage): actions.campaignPaymentFailureAction {
  return {
    type: actions.CAMPAIGN_PAYMENT_FAILURE,
    error,
  };
}
