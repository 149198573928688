import { IErrorMessage } from "../../services/types";
import * as types from "../action-types/banner.types";
import { IBanner } from "../states/banner.state";

// get

export function getBanners(): types.GetBannersAction {
  return {
    type: types.GET_BANNERS,
  };
}

export function getBannersRequest(): types.GetBannersRequestAction {
  return {
    type: types.GET_BANNERS_REQUEST,
  };
}

export function getBannersSuccess(payload: any): types.GetBannersSuccessAction {
  return {
    type: types.GET_BANNERS_SUCCESS,
    payload,
  };
}

export function getBannersFailure(error: IErrorMessage): types.GetBannersFailureAction {
  return {
    type: types.GET_BANNERS_FAILURE,
    error,
  };
}

// create banners

export function createBanners(payload: any): types.CreateBannersAction {
  return {
    type: types.CREATE_BANNERS,
    payload,
  };
}

export function createBannersRequest(): types.CreateBannersRequestAction {
  return {
    type: types.CREATE_BANNERS_REQUEST,
  };
}

export function createBannersSuccess(payload: any): types.CreateBannersSuccessAction {
  return {
    type: types.CREATE_BANNERS_SUCCESS,
    payload,
  };
}

export function createBannersFailure(error: IErrorMessage): types.CreateBannersFailureAction {
  return {
    type: types.CREATE_BANNERS_FAILURE,
    error,
  };
}

// update

export function updateBanners(payload: {
  bannerAd: IBanner;
  campaignId: string;
  bannerId: number;
}): types.UpdateBannersAction {
  return {
    type: types.UPDATE_BANNERS,
    payload,
  };
}

export function updateBannersRequest(): types.UpdateBannersRequestAction {
  return {
    type: types.UPDATE_BANNERS_REQUEST,
  };
}

export function updateBannersSuccess(payload: any): types.UpdateBannersSuccessAction {
  return {
    type: types.UPDATE_BANNERS_SUCCESS,
    payload,
  };
}

export function updateBannersFailure(error: IErrorMessage): types.UpdateBannersFailureAction {
  return {
    type: types.UPDATE_BANNERS_FAILURE,
    error,
  };
}

// delete

export function deleteBanner(payload: number): types.DeleteBannerAction {
  return {
    type: types.DELETE_BANNER,
    payload,
  };
}

export function deleteBannerRequest(): types.DeleteBannerRequestAction {
  return {
    type: types.DELETE_BANNER_REQUEST,
  };
}

export function deleteBannerSuccess(payload: any): types.DeleteBannerSuccessAction {
  return {
    type: types.DELETE_BANNER_SUCCESS,
    payload,
  };
}

export function deleteBannerFailure(error: IErrorMessage): types.DeleteBannerFailureAction {
  return {
    type: types.DELETE_BANNER_FAILURE,
    error,
  };
}

// create assets

export function createBannerAssets(payload: any): types.CreateBannerAssetsAction {
  return {
    type: types.CREATE_BANNER_ASSETS,
    payload,
  };
}

export function createBannerAssetsRequest(): types.CreateBannerAssetsRequestAction {
  return {
    type: types.CREATE_BANNER_ASSETS_REQUEST,
  };
}

export function createBannerAssetsSuccess(): types.CreateBannerAssetsSuccessAction {
  return {
    type: types.CREATE_BANNER_ASSETS_SUCCESS,
  };
}

export function createBannerAssetsFailure(
  error: IErrorMessage
): types.CreateBannerAssetsFailureAction {
  return {
    type: types.CREATE_BANNER_ASSETS_FAILURE,
    error,
  };
}

// delete assets

export function deleteBannerAssets(payload: any): types.DeleteBannerAssetsAction {
  return {
    type: types.DELETE_BANNER_ASSETS,
    payload,
  };
}

export function deleteBannerAssetsRequest(): types.DeleteBannerAssetsRequestAction {
  return {
    type: types.DELETE_BANNER_ASSETS_REQUEST,
  };
}

export function deleteBannerAssetsSuccess(payload: any): types.DeleteBannerAssetsSuccessAction {
  return {
    type: types.DELETE_BANNER_ASSETS_SUCCESS,
    payload,
  };
}

export function deleteBannerAssetsFailure(
  error: IErrorMessage
): types.DeleteBannerAssetsFailureAction {
  return {
    type: types.DELETE_BANNER_ASSETS_FAILURE,
    error,
  };
}

// edit asset

export function editBannerAsset(payload: {
  width: number;
  height: number;
  imageUrl: string;
  bannerId: number;
  name: string;
}): types.EditBannerAssetAction {
  return {
    type: types.EDIT_BANNER_ASSET,
    payload,
  };
}

export function editBannerAssetRequest(): types.EditBannerAssetRequestAction {
  return {
    type: types.EDIT_BANNER_ASSET_REQUEST,
  };
}

export function editBannerAssetSuccess(): types.EditBannerAssetSuccessAction {
  return {
    type: types.EDIT_BANNER_ASSET_SUCCESS,
  };
}

export function editBannerAssetFailure(error: IErrorMessage): types.EditBannerAssetFailureAction {
  return {
    type: types.EDIT_BANNER_ASSET_FAILURE,
    error,
  };
}
