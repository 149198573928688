import { IRoute } from "modules/Client/routes";
import { ConnectedComponent } from "react-redux";
import { showConfirmPopup } from "store/actions/popup.actions";
import { logoutUser } from "store/actions/user.actions";
import Billing from "./pages/Billing";
import Exports from "./pages/Exports";
import MySettings from "./pages/MySettings";
import NotificationSetting from "./pages/NotificationSetting";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";

export const BASE_ROUTE = `/user`;

export const MY_SETTINGS = `settings/`;

export const NOTIFICATIONS = `notifications/`;

export const EXPORTS = `exports/`;

export const BILLING = `billing/`;

export const PRIVACY = `privacy/`;

export const TERMS = `terms/`;

export const userRoutes: Array<{
  path?: string;
  component?: ConnectedComponent<any, any> | React.FunctionComponent<any>;
  text: string;
  disabled?: boolean;
  action?: string;
}> = [
  { path: MY_SETTINGS, text: "My settings", component: MySettings },
  { path: NOTIFICATIONS, disabled: true, text: "Notifications", component: NotificationSetting },
  {
    path: EXPORTS,
    text: "Exports",
    component: Exports,
    disabled: true,
  },
  { path: BILLING, text: "Billing", component: Billing, disabled: true },
  { path: PRIVACY, text: "Privacy", component: Privacy },
  { path: TERMS, text: "Terms", component: Terms },
  {
    text: "Logout",
    action: "LOGOUT",
  },
];
