import React from "react";
import Form from "react-bootstrap/Form";

import styled from "styled-components/macro";

const CustomSwitch = styled(Form.Check)<{ checked: boolean }>`
  .custom-control-input:checked ~ .custom-control-label::before {
    border-color: ${({ theme }) => theme.color.primaryDark};
    background-color: ${({ theme }) => theme.color.primaryDark};
  }

  .custom-control-label::before {
    top: 0;
    right: -3.25rem;
    left: auto;
    height: 25px;
    width: 48px;
    border-radius: 50px;
    border: 1px solid ${({ theme }) => theme.dark};
    background-color: ${({ theme }) => theme.dark};
  }

  .custom-control-label::after {
    right: calc(-2rem + 4px);
    top: 2px;
    left: auto;
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }

  .custom-control-input:checked ~ .custom-control-label::after {
    transform: translateX(1.25rem);
  }

  .form-check-input {
    height: 20px;
    width: 40px;

    &:checked {
      background-color: ${({ theme }) => theme.color.primary};
      border-color: ${({ theme }) => theme.color.primary};
    }

    &:focus {
      /* background: transparent;
      border: 1px solid black; */
      border-color: ${({ checked, theme }) =>
        checked ? theme.color.primary : "rgb(191, 191, 191)"};
      box-shadow: none;
      filter: ${({ checked }) => (checked ? "grayscale(0)" : "grayscale(1)")};
    }
  }
`;

interface ISwitchProps {
  index: number;
  isActive: boolean;
  onChange: (checked: boolean) => void;
  disabled?: boolean;
}

const Switch: React.FunctionComponent<ISwitchProps> = ({ index, isActive, onChange, disabled }) => {
  return (
    <CustomSwitch
      disabled={disabled}
      type="switch"
      id={`switch${index}`}
      label=""
      checked={isActive}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChange(event.target.checked)}
    />
  );
};

export default Switch;
