const state = {
  total: 0,
  loading: false,
  error: null,
  data: null,
  assets: [],
} as ICreativeState;

interface ICreativeState {
  loading: boolean;
  error: string | null;
  data: ICreative | null;
  assets: Array<IAsset>;
  total: number;
}

export interface IAssetFromServer {
  name: string;
  url: string;
}

export interface ICreative {
  landingPageUrl: string;
  hook: string;
  offer: string;
  action: string;
  briefing: string;
  notes: string;
  creativeId?: number;
  images: Array<IAsset>;
  assetsUrl: Array<string>;
  assets: Array<IAssetFromServer>;
}

export interface IPostCreative {
  campaignId: string;
  creativeAd: ICreative;
}

export interface IAsset {
  id?: string;
  imageFile: File;
  name: string;
}

export default state;
