import { IDataForSEOV3 } from "constants/utility/keyword";

const state = {
  total: 0,
  loading: false,
  error: null,
  mock: {},
  data: [], // Extract out all of the countries from the raw data
} as IHomeState;

interface IHomeState {
  loading: boolean;
  error: string | null;
  data: Array<string>;
  mock: { [key: string]: IDataForSEOV3 };
  total: number;
}

export default state;
