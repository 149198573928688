import React from "react";
import { connect, ConnectedProps, useDispatch } from "react-redux";
import { hideNewCampaignPopup } from "store/actions/popup.actions";
import { DuplicateCampaignForm, StyledModal } from "./styled";
import Button from "../Button";
import { Modal } from "react-bootstrap";
import Input from "components/Input";
import { RootState } from "store/store";
import { Formik, FormikErrors } from "formik";
import { duplicateCampaign } from "store/actions/campaign.actions";
import newCampaignSchema from "schemas/new-campaign.schema";
import CloseButton from "components/CloseButton";

interface INewCampaignPopupProps extends PropsFromRedux {}

const mapStateToProps = ({ popup, campaigns }: RootState) => ({
  popup,
  loading: campaigns.isDuplicating,
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const NewCampaignPopup: React.FunctionComponent<INewCampaignPopupProps> = ({ popup, loading }) => {
  const dispatch = useDispatch();

  const onCancelButtonClick = () => {
    dispatch(hideNewCampaignPopup());
  };

  const onFormSubmit = async (
    data: { name: string },
    setErrors: (errors: FormikErrors<{ name: string }>) => void
  ) => {
    dispatch(
      duplicateCampaign({
        fromCampaignId: popup.campaign.id,
        toName: data.name,
      })
    );
  };

  React.useEffect(() => {
    return () => {
      onCancelButtonClick();
    };
  }, []);

  return (
    <StyledModal keyboard show={popup.showNewCampaignPopup} centered>
      <Modal.Header>
        <Modal.Title>
          <CloseButton top={20} right={20} onClick={onCancelButtonClick} />
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <DuplicateCampaignForm>
          <Formik
            validationSchema={newCampaignSchema}
            onSubmit={(values, { setErrors }) => onFormSubmit(values, setErrors)}
            initialValues={{ name: `${popup.campaign.name} - (Copy)` }}
          >
            {({ handleChange, handleSubmit, values, errors }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <div className="input-wrapper">
                    <label className="form-label">Campaign Name</label>
                    <Input
                      onChange={handleChange}
                      type="text"
                      placeholder="Name"
                      name="name"
                      value={values.name}
                    />
                    {errors.name && <div className="errorMessage">{errors.name}</div>}
                  </div>
                  <div className="modal-action-buttons">
                    <Button
                      width={150}
                      outline
                      type="button"
                      onClick={onCancelButtonClick}
                      className="close-button"
                    >
                      Cancel
                    </Button>
                    <Button width={150} type="submit" className="save-button" disabled={loading}>
                      {loading ? "Please wait..." : "Duplicate"}
                    </Button>
                  </div>
                </form>
              );
            }}
          </Formik>
        </DuplicateCampaignForm>
      </Modal.Body>
    </StyledModal>
  );
};

export default connector(NewCampaignPopup);
