export const initialState = {
  data: [] as Array<IPaymentDetail>,
  loading: false,
  total: 0,
  error: null,
};

export interface IPaymentDetailData {
  id: string;
  state: PaymentStatus;
  bankId: number;
  note: string;
}

export interface IPaymentDetail {
  transactionId: null | string;
  id: string;
  paymentGateway: PaymentGateway;
  token: null | string;
  payerId: null | string;
  amount: number;
  state: PaymentStatus;
  campaignId: string;
  created_at: Date;
  updated_at: Date;
  bankId: null | number;
  note: null | string;
  ownerName: string;
  campaignName: string;
}

export enum ENUM_PAYMENT_STATUS {
  APPROVED = "approved",
  FAILED = "failed",
  WAITING = "waiting for payment",
  REQUESTED = "requested",
}

export type PaymentStatus =
  | ENUM_PAYMENT_STATUS.WAITING
  | ENUM_PAYMENT_STATUS.REQUESTED
  | ENUM_PAYMENT_STATUS.FAILED
  | ENUM_PAYMENT_STATUS.APPROVED;

export const PAYMENT_STATUS_TEXT = {
  [ENUM_PAYMENT_STATUS.WAITING]: "Waiting",
  [ENUM_PAYMENT_STATUS.FAILED]: "Failed",
  [ENUM_PAYMENT_STATUS.APPROVED]: "Approved",
  [ENUM_PAYMENT_STATUS.REQUESTED]: "Requested",
};

export enum ENUM_PAYMENT_GATEWAY {
  STRIPE = "stripe",
  OMISE = "omise",
}

export const PAYMENT_GATEWAY_TEXT = {
  [ENUM_PAYMENT_GATEWAY.STRIPE]: "Stripe",
  [ENUM_PAYMENT_GATEWAY.OMISE]: "Omise",
};

export type PaymentGateway = ENUM_PAYMENT_GATEWAY.STRIPE | ENUM_PAYMENT_GATEWAY.OMISE;
