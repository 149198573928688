import styled from "styled-components/macro";

export const Step2Styled = styled.div`
  padding-right: 20px;
  color: ${({ theme }) => theme.targeting};

  .name-campaign {
    .build-campaign-title {
      color: ${({ theme }) => theme.contrast};
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
    }
  }

  .targeting-select {
    position: absolute;
    right: 0;
    top: 0px;
    display: flex;
    gap: 10px;
    align-items: center;
  }
`;

export const LocationWrapper = styled.div`
  margin-top: 20px;
`;

export const SelectorContainer = styled.div<{ wider?: boolean }>`
  display: flex;
  padding: 20px;
  gap: 20px;
  background-color: ${({ theme }) => theme.reviewBackground};
  border-radius: 10px;
  margin-top: 20px;

  .checkbox-wrapper {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
    max-width: ${({ wider }) => (wider ? 300 : 140)}px;
    width: 100%;

    .checkbox-label {
      font-size: 14px;
    }
  }
`;

export const ContentCategoryWrapper = styled.div`
  margin-top: 20px;
`;
