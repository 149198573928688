import React from "react";

const PlusIcon = ({ className }: { className?: string }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.62 17.69">
      <defs>
        <style>
          {".icon-svg{fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;}"}
        </style>
      </defs>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_3" data-name="Layer 3">
          <line className="icon-svg" x1={9.31} y1={17.19} x2={9.31} y2={0.5} />
          <line className="icon-svg" x1={18.12} y1={8.84} x2={0.5} y2={8.84} />
        </g>
      </g>
    </svg>
  );
};

export default PlusIcon;
