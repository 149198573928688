import SaveToListIcon from "components/Icons/SaveToListIcon";
import React from "react";
import { InvoiceListStyled } from "./styled";

const InvoiceList = () => {
  return (
    <InvoiceListStyled>
      <h6 className="invoice-title">Invoices</h6>
      <div className="invoice-table">
        <div className="table-header">
          <span>Invoice No.</span>
          <span>Payment Date</span>
          <span>Documents</span>
        </div>

        <div className="table-body">
          <div className="table-row">
            <span>RAD00111</span>
            <span>November 15, 2021</span>
            <span>
              <SaveToListIcon />
            </span>
          </div>

          <div className="table-row">
            <span>RAD00222</span>
            <span>December 15, 2021</span>
            <span>
              <SaveToListIcon />
            </span>
          </div>

          <div className="table-row">
            <span>RAD00333</span>
            <span>January 15, 2021</span>
            <span>
              <SaveToListIcon />
            </span>
          </div>
        </div>
      </div>
    </InvoiceListStyled>
  );
};

export default InvoiceList;
