import { AnyAction } from "redux";
import * as actions from "../action-types/campaign.types";
import * as bannerActions from "../action-types/banner.types";
import campaignState from "../states/campaign.state";

const campaignReducer = (state = campaignState, action: AnyAction) => {
  switch (action.type) {
    case actions.RESET_CURRENT_CAMPAIGN:
      state = { ...state, data: null };
      break;

    // get campaign by id

    case actions.GET_CAMPAIGN_BY_ID_REQUEST:
      state = { ...state, data: null, loading: true };
      break;
    case actions.GET_CAMPAIGN_BY_ID_SUCCESS:
      state = { ...state, data: action.campaign, loading: false };
      break;
    case actions.GET_CAMPAIGN_BY_ID_FAILURE:
      state = { ...state, error: action.error, loading: false };
      break;

    // update payment
    case actions.UPDATE_PAYMENT_STATUS_REQUEST:
      state = { ...state, updating: true };
      break;
    case actions.UPDATE_PAYMENT_STATUS_SUCCESS:
      if (state.data && state.data.payments) {
        const index = state.data.payments.findIndex((payment) => payment.id === action.payload.id);
        state = {
          ...state,
          updating: false,
          data: {
            ...state.data,
            payments: [
              ...state.data.payments.slice(0, index),
              { ...action.payload },
              ...state.data.payments.slice(index + 1),
            ],
          },
        };
      }

      break;
    case actions.UPDATE_PAYMENT_STATUS_FAILURE:
      state = { ...state, updating: false, error: action.payload };
      break;

    // update ref ids
    case actions.UPDATE_REF_ID_REQUEST:
      state = { ...state, updating: true };
      break;
    case actions.UPDATE_REF_ID_SUCCESS:
      state = {
        ...state,
        data: state.data
          ? {
              ...state.data,
              portalUrl: action.payload.portalUrl || "",
            }
          : null,
        updating: false,
        message: action.payload.message,
      };
      break;
    case actions.UPDATE_REF_ID_FAILURE:
      state = { ...state, updating: false };
      break;

    case actions.REMOVE_MESSAGE:
      state = {
        ...state,
        message: null,
      };
      break;

    // edit

    case actions.EDIT_CAMPAIGN_REQUEST:
      state = {
        ...state,
        updating: true,
      };
      break;

    case actions.EDIT_CAMPAIGN_FAILURE:
      state = {
        ...state,
        error: action.error,
        updating: false,
      };
      break;

    case bannerActions.UPDATE_BANNERS_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;

    case bannerActions.UPDATE_BANNERS_FAILURE:
      state = {
        ...state,
        loading: false,
      };
      break;

    case bannerActions.UPDATE_BANNERS_SUCCESS:
      state = {
        ...state,
        data: state.data
          ? {
              ...state.data,
              banners: action.payload,
            }
          : null,
        loading: false,
      };
      break;

    case actions.EDIT_CAMPAIGN_SUCCESS:
      state = {
        ...state,
        // data: state.data
        //   ? {
        //       ...state.data,
        //       ...action.payload,
        //     }
        //   : null,
        updating: false,
      };
      break;

    // link

    case actions.LINK_CAMPAIGN_REQUEST:
      state = {
        ...state,
        linking: true,
      };
      break;

    case actions.LINK_CAMPAIGN_FAILURE:
      state = {
        ...state,
        error: action.error,
        linking: false,
      };
      break;

    case actions.LINK_CAMPAIGN_SUCCESS:
      state = {
        ...state,
        linking: false,
      };
      break;

    default:
  }

  return state;
};

export default campaignReducer;
