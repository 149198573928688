const state = {
  data: null,
  loading: false,
  error: null,
} as ICurrentKeywordsListItem;

interface ICurrentKeywordsListItem {
  data: { name: string; id: string; keywords: Array<{ id: string; keyword: string }> } | null;
  loading: boolean;
  error: string | null;
}

export default state;
