import styled from "styled-components";

export const BillingStyled = styled.div`
  .billing-wrapper {
    display: flex;
    margin-top: 20px;

    .plan-invoice-section {
      width: 60%;
      overflow-y: auto;
      max-height: calc(100vh - 200px);
      padding: 0 20px 0 0;

      .plan-title {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;

        .upgrade-button {
          margin-right: 100px;
          border: 1px solid white;
          border-radius: 5px;
          font-size: 10px;
          padding: 4px 5px;
        }

        .title {
          color: ${({ theme }) => theme.color.green};
          font-size: 14px;
          font-weight: bold;
        }
      }

      .plan-section {
        display: flex;
        margin-bottom: 30px;
      }
    }

    .payment-management {
      width: 40%;
      margin-left: 50px;
      font-size: 14px;

      .title {
        margin-bottom: 30px;
      }

      .card-management {
        margin-bottom: 30px;
        display: flex;
        flex-direction: column;
        gap: 20px;

        .card-detail {
          display: flex;

          > span {
            &:first-child {
              color: ${({ theme }) => theme.text};
            }
            width: 50%;
          }
        }
      }
    }
  }

  .plan-grid {
    font-size: 12px;
    display: grid;
    grid-template-rows: repeat(17, 22px);

    &:nth-child(1) {
      width: 40%;
    }

    &:nth-child(2) {
      width: 60%;
    }

    .feature {
      color: ${({ theme }) => theme.color.gold};
    }

    .tool-plan {
      color: ${({ theme }) => theme.text};
    }

    .design-plan {
      font-style: italic;
      color: ${({ theme }) => theme.text};
    }
  }
`;
