import React from "react";

const ResearchToolsIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      fill="white"
      width="23px"
      height="23px"
      className={className}
      viewBox="0 0 128 128"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path d="m102.441 90.08-23.233-21.5a1.75 1.75 0 0 0 -2.426.047l-2.841 2.841-2.726-2.728a27.656 27.656 0 1 0 -2.475 2.475l2.726 2.726-2.841 2.841a1.749 1.749 0 0 0 -.047 2.426l21.5 23.233a8.675 8.675 0 0 0 6.251 2.8h.174a8.747 8.747 0 0 0 5.936-15.167zm-69.1-22.534a24.189 24.189 0 1 1 34.208 0 24.036 24.036 0 0 1 -34.211 0zm66.869 32.664a5.3 5.3 0 0 1 -3.812 1.536 5.207 5.207 0 0 1 -3.75-1.682l-20.358-22 5.776-5.776 22 20.358a5.248 5.248 0 0 1 .146 7.562z" />
        <path d="m50.441 30.351a20.091 20.091 0 1 0 20.092 20.09 20.112 20.112 0 0 0 -20.092-20.09zm0 36.682a16.591 16.591 0 1 1 16.592-16.592 16.61 16.61 0 0 1 -16.592 16.592z" />
        <path d="m50.441 37.413a13.044 13.044 0 0 0 -13.028 13.028 1.75 1.75 0 0 0 3.5 0 9.539 9.539 0 0 1 9.528-9.528 1.75 1.75 0 0 0 0-3.5z" />
      </g>
    </svg>
  );
};

export default ResearchToolsIcon;
