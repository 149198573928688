import { IErrorMessage } from "../../services/types";
import { IAsset, ICreative, IPostCreative } from "../states/creative.state";

// create

export const CREATE_CREATIVE = "creative-actions/CREATE_CREATIVE";
export interface CreateCreativeAction {
  type: typeof CREATE_CREATIVE;
  payload: IPostCreative;
}

export const CREATE_CREATIVE_REQUEST = "creative-actions/CREATE_CREATIVE_REQUEST";
export interface CreateCreativeRequestAction {
  type: typeof CREATE_CREATIVE_REQUEST;
}

export const CREATE_CREATIVE_SUCCESS = "creative-actions/CREATE_CREATIVE_SUCCESS";
export interface CreateCreativeSuccessAction {
  type: typeof CREATE_CREATIVE_SUCCESS;
  payload: ICreative;
}

export const CREATE_CREATIVE_FAILURE = "creative-actions/CREATE_CREATIVE_FAILURE";
export interface CreateCreativeFailureAction {
  type: typeof CREATE_CREATIVE_FAILURE;
  error: IErrorMessage;
}

// get

export const GET_CREATIVE = "creative-actions/GET_CREATIVE";
export interface GetCreativeAction {
  type: typeof GET_CREATIVE;
}

export const GET_CREATIVE_REQUEST = "creative-actions/GET_CREATIVE_REQUEST";
export interface GetCreativeRequestAction {
  type: typeof GET_CREATIVE_REQUEST;
}

export const GET_CREATIVE_SUCCESS = "creative-actions/GET_CREATIVE_SUCCESS";
export interface GetCreativeSuccessAction {
  type: typeof GET_CREATIVE_SUCCESS;
  payload: ICreative;
}

export const GET_CREATIVE_FAILURE = "creative-actions/GET_CREATIVE_FAILURE";
export interface GetCreativeFailureAction {
  type: typeof GET_CREATIVE_FAILURE;
  error: IErrorMessage;
}

// update

export const UPDATE_CREATIVE = "creative-actions/UPDATE_CREATIVE";
export interface UpdateCreativeAction {
  type: typeof UPDATE_CREATIVE;
  payload: IPostCreative;
}

export const UPDATE_CREATIVE_REQUEST = "creative-actions/UPDATE_CREATIVE_REQUEST";
export interface UpdateCreativeRequestAction {
  type: typeof UPDATE_CREATIVE_REQUEST;
}

export const UPDATE_CREATIVE_SUCCESS = "creative-actions/UPDATE_CREATIVE_SUCCESS";
export interface UpdateCreativeSuccessAction {
  type: typeof UPDATE_CREATIVE_SUCCESS;
  payload: ICreative;
}

export const UPDATE_CREATIVE_FAILURE = "creative-actions/UPDATE_CREATIVE_FAILURE";
export interface UpdateCreativeFailureAction {
  type: typeof UPDATE_CREATIVE_FAILURE;
  error: IErrorMessage;
}

// delete

export const DELETE_CREATIVE = "creative-actions/DELETE_CREATIVE";
export interface DeleteCreativeAction {
  type: typeof DELETE_CREATIVE;
  payload: { creativeId: number };
}

export const DELETE_CREATIVE_REQUEST = "creative-actions/DELETE_CREATIVE_REQUEST";
export interface DeleteCreativeRequestAction {
  type: typeof DELETE_CREATIVE_REQUEST;
}

export const DELETE_CREATIVE_SUCCESS = "creative-actions/DELETE_CREATIVE_SUCCESS";
export interface DeleteCreativeSuccessAction {
  type: typeof DELETE_CREATIVE_SUCCESS;
}

export const DELETE_CREATIVE_FAILURE = "creative-actions/DELETE_CREATIVE_FAILURE";
export interface DeleteCreativeFailureAction {
  type: typeof DELETE_CREATIVE_FAILURE;
  error: IErrorMessage;
}

// create creative assets

export const CREATE_CREATIVE_ASSETS = "creative-actions/CREATE_CREATIVE_ASSETS";
export interface CreateCreativeAssetsAction {
  type: typeof CREATE_CREATIVE_ASSETS;
  payload: Array<IAsset>;
}

export const CREATE_CREATIVE_ASSETS_REQUEST = "creative-actions/CREATE_CREATIVE_ASSETS_REQUEST";
export interface CreateCreativeAssetsRequestAction {
  type: typeof CREATE_CREATIVE_ASSETS_REQUEST;
}

export const CREATE_CREATIVE_ASSETS_SUCCESS = "creative-actions/CREATE_CREATIVE_ASSETS_SUCCESS";
export interface CreateCreativeAssetsSuccessAction {
  type: typeof CREATE_CREATIVE_ASSETS_SUCCESS;
  payload: Array<IAsset>;
}

export const CREATE_CREATIVE_ASSETS_FAILURE = "creative-actions/CREATE_CREATIVE_ASSETS_FAILURE";
export interface CreateCreativeAssetsFailureAction {
  type: typeof CREATE_CREATIVE_ASSETS_FAILURE;
  error: IErrorMessage;
}

// delete creative assets

export const DELETE_CREATIVE_ASSETS = "creative-actions/DELETE_CREATIVE_ASSETS";
export interface DeleteCreativeAssetsAction {
  type: typeof DELETE_CREATIVE_ASSETS;
  payload: Array<IAsset>;
}

export const DELETE_CREATIVE_ASSETS_REQUEST = "creative-actions/DELETE_CREATIVE_ASSETS_REQUEST";
export interface DeleteCreativeAssetsRequestAction {
  type: typeof DELETE_CREATIVE_ASSETS_REQUEST;
}

export const DELETE_CREATIVE_ASSETS_SUCCESS = "creative-actions/DELETE_CREATIVE_ASSETS_SUCCESS";
export interface DeleteCreativeAssetsSuccessAction {
  type: typeof DELETE_CREATIVE_ASSETS_SUCCESS;
  payload: Array<IAsset>;
}

export const DELETE_CREATIVE_ASSETS_FAILURE = "creative-actions/DELETE_CREATIVE_ASSETS_FAILURE";
export interface DeleteCreativeAssetsFailureAction {
  type: typeof DELETE_CREATIVE_ASSETS_FAILURE;
  error: IErrorMessage;
}
