import * as types from "store/action-types/targeting.types";
import { GeoTarget, TargetingItem } from "store/states/targeting.state";

export function getTargeting(): types.GetTargetingListAction {
  return {
    type: types.GET_TARGETING,
  };
}

export function getTargetingRequest(): types.GetTargetingListRequestAction {
  return {
    type: types.GET_TARGETING_REQUEST,
  };
}

export function getTargetingSuccess(payload: {
  devices: Array<TargetingItem>;
  browsers: Array<TargetingItem>;
  os: Array<TargetingItem>;
  countries?: Array<GeoTarget>;
}): types.GetTargetingListSuccessAction {
  return {
    type: types.GET_TARGETING_SUCCESS,
    payload,
  };
}

export function getTargetingFailure(payload: string): types.GetTargetingListFailureAction {
  return {
    type: types.GET_TARGETING_FAILURE,
    payload,
  };
}
