import styled from "styled-components/macro";

export const CampaignsEmptyStyled = styled.div`
  background: ${({ theme }) => theme.inputBorder};
  border-radius: 5px;
  text-align: center;
  display: flex;
  min-height: 100%;

  .empty-content {
    max-width: 600px;
    margin: auto;
    height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    svg {
      width: 15px;
      height: 15px;
      margin-left: 10px;
      margin-bottom: 1px;
    }
  }

  .empty-state-logo {
    max-width: 320px;
    margin-bottom: 30px;
  }

  .empty-state-title {
    font-size: 1.25em;
    font-weight: 600;
  }

  .empty-state-text {
    color: ${({ theme }) => theme.text};
    font-size: 14px;
  }

  @media screen and (max-width: 767px) {
    padding: 10px;

    .empty-content {
      padding: 35px 20px;
    }

    .empty-state-logo {
      max-width: 100px;
    }
  }
`;
