import styled from "styled-components/macro";

export const StyledSlideModal = styled.div`
  .align-right {
    position: absolute;
    right: 10px;
  }

  .overlay {
    background-color: rgba(0, 0, 0, 0);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
    z-index: 1041;
    transition: 0.3s;
    visibility: hidden;
  }

  .overlay.show {
    visibility: visible;
    background-color: rgba(0, 0, 0, 0.55);
  }

  .slide-modal {
    width: 450px;
    top: 0;
    position: fixed;
    right: -150vw;
    background-color: ${({ theme }) => theme.background};
    height: 100%;
    box-shadow: 0 0 4px 0px rgba(0, 0, 0, 0.15);
    left: auto;
    padding: 12px;
    transition: all 0.3s ease-out;
    z-index: 1042;
  }

  .slide-modal.show {
    right: 0;
  }

  .close {
    cursor: pointer;
    position: absolute;
    top: 16px;
    right: 10px;
    background-color: transparent;
    border: 0;
    font-size: 18px;
  }
`;
