import styled from "styled-components";

export const KeywordSetItemStyled = styled.div`
  display: flex;
  padding: 8px 15px 8px 15px;
  background: ${({ theme }) => theme.inputBorder};
  margin-bottom: 10px;
  transition: 0.1s;
  border-radius: 10px;
  justify-content: space-between;

  .popup-icon {
    cursor: pointer;

    svg {
      width: 12px;
      height: 12px;

      .icon-svg {
        stroke: ${({ theme }) => theme.contrast};
      }
    }
  }

  .delete-icon {
    display: flex;
    align-items: center;
    padding: 0 5px;
    cursor: pointer;

    svg {
      width: 12px;
      height: 12px;

      .icon-svg {
        stroke: ${({ theme }) => theme.contrast};
      }
    }
  }

  .keyword-set {
    display: flex;
    list-style: none;
    gap: 10px;
    padding-left: 15px;
  }
`;
