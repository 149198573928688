import BottomBar from "components/BottomBar";
import CloseButton from "components/CloseButton";
import { SearchIcon } from "components/Icons";
import Input from "components/Input";
import KeywordsTable from "components/KeywordsTable";
import LocationFilter from "components/LocationFilter";
import { messages } from "constants/utility/messages";
import useKeywordsTableHook from "hooks/useKeywordsTable";
import React from "react";
import { Modal } from "react-bootstrap";
import { connect, ConnectedProps, useDispatch } from "react-redux";
import { ICity, ICountry } from "store/action-types/countries.types";
import { hideSuggestedPopup } from "store/actions/popup.actions";
import { saveSuggested } from "store/actions/suggested.actions";
import { RootState } from "store/store";
import Spinner from "components/Spinner";
import { SuggestedPopupModal } from "./styled";

interface ISuggestedPopupProps extends PropsFromRedux {}

const mapStateToProps = ({ popup }: RootState) => ({
  popup,
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const SuggestedPopup: React.FunctionComponent<ISuggestedPopupProps> = ({ popup }) => {
  const dispatch = useDispatch();

  const [selectedCountry, setSelectedCountry] = React.useState<ICountry | null>(null);
  const [selectedCity, setSelectedCity] = React.useState<ICity | null>(null);
  const [searchedKeyword, setSearchedKeyword] = React.useState<string | null>(null);
  const [selectedKeywords, setSelectedKeywords] = React.useState<Array<string>>([]);
  const { suggestedKeywords, loading, fetchSuggestedKeywords, resetState } = useKeywordsTableHook();

  const onKeywordSearch = React.useCallback((keyword: string, location?: string) => {
    if (keyword) {
      setSearchedKeyword(keyword);
      fetchSuggestedKeywords(keyword, location);
    }
  }, []);

  const [searchTerms, setSearch] = React.useState("");

  const onKeywordSelect = (selectedKeyword: string) => {
    let newKeywordsList = [];
    if (selectedKeywords.includes(selectedKeyword)) {
      newKeywordsList = selectedKeywords.filter((element) => element !== selectedKeyword);
    } else {
      newKeywordsList = [...selectedKeywords, selectedKeyword];
    }

    setSelectedKeywords(newKeywordsList);
  };

  const onExit = () => {
    if (searchTerms) {
      dispatch(saveSuggested(searchTerms));
    }
    dispatch(hideSuggestedPopup());
    setSearch("");
    resetState();
    setSearchedKeyword(null);
    setSelectedKeywords([]);
  };

  React.useEffect(() => {
    if (popup.showSuggestedPopup.keyword) {
      onKeywordSearch(
        popup.showSuggestedPopup.keyword,
        selectedCity ? selectedCity.location_name : selectedCountry?.name
      );
    }
  }, [popup.showSuggestedPopup.keyword]);

  return (
    <SuggestedPopupModal
      keyboard
      $blur={
        popup.showExportPopup.show || popup.showImportKeywords.show || popup.showKeywordListPopup
      }
      size="xl"
      centered
      show={popup.showSuggestedPopup.show}
      onHide={onExit}
      onExited={onExit}
      data-testid="suggested-modal"
    >
      <Modal.Header>
        <Modal.Title>
          <div className="keyword-tool-title">
            <span className="tool-title">Suggested</span>
            <span className="tool-description">
              {`Suggested Keywords tool that discovers niche relevant results, difficulty, and monthly search volume. 
You can discover results sed on geo-location.`}
            </span>
          </div>

          <CloseButton
            top={15}
            right={15}
            onClick={() => {
              dispatch(hideSuggestedPopup());
            }}
          />
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="search-bar">
          <div className="keyword-input">
            <Input
              data-testid="keyword-search"
              height={50}
              placeholder="Search Keyword"
              noBorder
              black
              value={searchTerms}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)}
              onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                e.key === "Enter" &&
                  onKeywordSearch(
                    searchTerms,
                    selectedCity ? selectedCity.location_name : selectedCountry?.name
                  );
              }}
            />
            <SearchIcon />
          </div>
          <LocationFilter
            searchTerms={searchTerms}
            onSubmit={() => {
              onKeywordSearch(
                searchTerms,
                selectedCity ? selectedCity.location_name : selectedCountry?.name
              );
              setSearch("");
            }}
            onCountryChange={(values) => {
              setSelectedCountry(values[0]);
            }}
            onCityChange={(values) => {
              setSelectedCity(values[0]);
            }}
          />
        </div>

        {searchedKeyword && Object.keys(suggestedKeywords).length ? (
          <div className="suggested-keywords-wrapper">
            <div className="table-wrapper">
              <KeywordsTable
                isCustomActions
                fullHeight
                KeywordsSuggested={messages.KeywordsSuggested.content}
                keywords={suggestedKeywords}
                // searchedKeyword={searchedKeyword}
                selectedKeys={selectedKeywords}
                isLoading={loading}
                onKeywordSelect={onKeywordSelect}
                selectAll={(selectedTableData) =>
                  setSelectedKeywords((prev) =>
                    Array.from(new Set([...prev, ...selectedTableData]))
                  )
                }
                unSelectAll={(unselectedTableData) =>
                  setSelectedKeywords((prev) =>
                    prev.filter((item) => !unselectedTableData.includes(item))
                  )
                }
              />
            </div>

            {!loading && (
              <div className="suggested-bottom-bar">
                <BottomBar
                  isAllChecked={selectedKeywords.length === Object.keys(suggestedKeywords).length}
                  selectAllCallback={() => {
                    setSelectedKeywords((prev) =>
                      prev.length === Object.keys(suggestedKeywords).length
                        ? []
                        : Object.keys(suggestedKeywords)
                    );
                  }}
                  selectedItems={selectedKeywords}
                  // keywordsTableData?: { [key: string]: IDataForSEO };
                  onExport={{
                    type: "keywords",
                    data: Object.keys(suggestedKeywords),
                  }}
                  // saveList?: string;
                />
              </div>
            )}
          </div>
        ) : loading ? (
          <div className="loading-wrapper">
            <Spinner />
          </div>
        ) : (
          <div className="empty" />
        )}
      </Modal.Body>
    </SuggestedPopupModal>
  );
};

export default connector(SuggestedPopup);
