const state = {
  data: [],
  loading: false,
  error: null,
} as IKeywordsListItems;

export interface IKeywordsListItems {
  data: Array<{ name: string; id: string }>;
  loading: boolean;
  error: string | null;
}

export default state;
