import styled, { css } from "styled-components/macro";
export const CalendarWrapper = styled.div<{ isStartSelect: boolean }>`
  .rdrDateDisplayWrapper,
  .rdrMonthAndYearWrapper,
  .rdrCalendarWrapper {
    color: ${({ theme }) => theme.contrast};
    background: ${({ theme }) => theme.background};
    border-color: rgb(26, 26, 26);
    border-radius: 10px;
  }

  .rdrDateDisplayItemActive {
    input {
      color: ${({ theme }) => theme.contrast};
    }
  }

  .rdrMonthAndYearPickers {
    select {
      color: ${({ theme }) => theme.contrast};
    }
  }

  .rdrDateDisplayItem {
    background: transparent;
  }

  .rdrDayDisabled {
    background: transparent;
  }

  .rdrPprevButton {
    background: ${({ theme }) => theme.color.primary};
    i {
      border-color: transparent white transparent transparent;
    }
  }

  .rdrNextButton {
    background: ${({ theme }) => theme.color.primary};
    i {
      border-color: transparent transparent transparent white;
    }
  }

  .rdrDay > .rdrDayNumber > span {
    color: ${({ theme }) => theme.contrast} !important;

    &:after {
      background: ${({ theme }) => theme.color.primary} !important;
    }
  }

  .rdrDayPassive > .rdrDayNumber > span {
    /* color: ${({ theme }) => theme.checkboxBorder}; */
    color: ${({ theme }) => theme.homeIcon} !important;
  }

  .rdrDayDisabled > .rdrDayNumber > span {
    color: ${({ theme }) => theme.checkbox} !important;
  }

  ${({ isStartSelect }) =>
    isStartSelect &&
    css`
      .rdrInRange {
        color: transparent !important;
      }
    `}
`;
