import React from "react";
import { EditCampaignModal } from "./styled";
import { Modal } from "react-bootstrap";
import { connect, ConnectedProps, useDispatch } from "react-redux";
import { RootState } from "store/store";
import { hideEditCampaign } from "store/actions/popup.actions";
import CloseButton from "components/CloseButton";
import { FormikProps, useFormik } from "formik";
import {
  IPostCampaignData,
  buildCampaignDescription,
  BUILD_CAMPAIGN_INITIAL_FORM_VALUES,
} from "constants/utility/campaign";
import step1Schema from "schemas/step1.schema";
import { editCampaign } from "store/actions/campaign.actions";
import step3Schema from "schemas/step3.schema";
import step4Schema from "schemas/step4.schema";
import Step1 from "components/Step1";
import Step2 from "components/Step2";
import Step3 from "components/Step3";
import Step4 from "components/Step4";
import useKeywordsTableHook from "hooks/useKeywordsTable";
import aspectRatio, { getAvailableSizes } from "constants/utility/upload";
import { convertKeywordV3ArrToObj, IDataForSEOV3 } from "constants/utility/keyword";
import { SchemaOf } from "yup";
import { axiosForReverseAds } from "axiosConfig";
import { handlingKeywordServiceError } from "constants/utility/error";
import IKeywordSearchVolumeResponse from "models/keyword-search-volume-response.interface";
import TablePagination from "components/TablePagination";
import Step1Icon from "components/Icons/Step1Icon";
import Step2Icon from "components/Icons/Step2Icon";
import Step3Icon from "components/Icons/Step3Icon";
import Step4Icon from "components/Icons/Step4Icon";
import Button from "components/Button";
import { CATEGORY_KEY_VALUES_OBJECT } from "constants/category";
import api from "services/api";

interface ICampaignPopupProps extends PropsFromRedux {}

const mapStateToProps = ({ popup, currentCampaign }: RootState) => ({
  popup,
  currentCampaign,
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const EditCampaign: React.FunctionComponent<ICampaignPopupProps> = ({ popup, currentCampaign }) => {
  const dispatch = useDispatch();
  const [schema, setSchema] = React.useState<SchemaOf<any> | null>(null);
  const { suggestedKeywords, loading, fetchSuggestedKeywords, resetState } = useKeywordsTableHook();
  const [tableData, setTableData] = React.useState(suggestedKeywords);
  const [selectedKeywords, setSelectedKeywords] = React.useState<{ [key: string]: IDataForSEOV3 }>(
    {}
  );
  const scrollRef = React.useRef<HTMLDivElement>(null);
  const [isLoading, setLoading] = React.useState(false);

  const [error, setError] = React.useState("");

  const [includes, setIncludes] = React.useState<CATEGORY_KEY_VALUES_OBJECT>({});
  const [excludes, setExcludes] = React.useState<CATEGORY_KEY_VALUES_OBJECT>({});
  const [isInvalidFilesShow, setShowInvalidFiles] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = 0;
    }
  }, [popup.showEditCampaign.step]);

  const keywords = Object.values(selectedKeywords).map((keywordData) => keywordData.display);

  const fetchKeywordsList = async (keywords: Array<string>) => {
    if (keywords.length) {
      try {
        const { data } = await api.dataForSEO.keywordSeachVolume(keywords.slice(0, 50));

        const results = convertKeywordV3ArrToObj(data.data);

        return results;
      } catch (error: any) {
        handlingKeywordServiceError(error);
      }
    }
    return {};
  };

  const getInitialFormValues = () => {
    if (currentCampaign.data) {
      const {
        name,
        description,
        goal,
        theGenders,
        theAgeGroups,
        locations,
        theDevices,
        theOs,
        theBrowsers,
        banners,
      } = currentCampaign.data;

      return {
        ...BUILD_CAMPAIGN_INITIAL_FORM_VALUES,
        name,
        description: description || "",
        goal,
        locations,
        ageGroups: theAgeGroups,
        genders: theGenders,
        devices: theDevices,
        os: theOs,
        browsers: theBrowsers,
        images: banners.length
          ? banners[0].images.map((image) => ({
              ...image,
              ratio: aspectRatio(image.width, image.height),
              id: image.url,
              availableSizes: getAvailableSizes(image.original.width, image.original.height),
              originWidth: image.original.width,
              originHeight: image.original.height,
              type: image.original.mine,
              size: image.original.size.toString(),
              thumbnail: `${process.env.REACT_APP_IMAGES_STORAGE}${image.url}`,
            }))
          : [],
        landingPageUrl:
          banners.length && banners[0].landingPageUrl ? banners[0].landingPageUrl : "",
        thankyouPageUrl:
          banners.length && banners[0].thankyouPageUrl ? banners[0].thankyouPageUrl : "",
        title: banners.length && banners[0].title ? banners[0].title : "",
      };
    }
    return BUILD_CAMPAIGN_INITIAL_FORM_VALUES;
  };

  const buildCampaignFormik = useFormik({
    validationSchema: schema,
    enableReinitialize: true,
    initialValues: getInitialFormValues(),
    onSubmit: (values) => {
      if (currentCampaign.data) {
        dispatch(
          editCampaign({
            ...values,
            bannerId: currentCampaign.data.banners.length && currentCampaign.data.banners[0].id,
            id: currentCampaign.data.id,
            keywords,
            includes,
            excludes,
          })
        );
      }
    },
  });

  const fetchCampaignKeywordData = async () => {
    setLoading(true);
    if (currentCampaign.data) {
      const initialKeywords = await fetchKeywordsList(currentCampaign.data.theKeywords);
      setTableData(() => ({ ...initialKeywords }));
      setSelectedKeywords(() => ({ ...initialKeywords }));
    }
    setLoading(false);
  };

  React.useEffect(() => {
    fetchCampaignKeywordData();

    // if (currentCampaign.data?.categories) {
    //   const includes = currentCampaign.data.categories
    //     .filter((category) => category.behavior === "include")
    //     .reduce((acc, cur) => {
    //       return {
    //         ...acc,
    //         [cur.parentKeyBeeswax || "-1"]: {
    //           ...acc[cur.parentKeyBeeswax!],
    //           [cur.keyBeeswax]: cur,
    //         },
    //       };
    //     }, {} as CATEGORY_KEY_VALUES_OBJECT);

    //   const excludes = currentCampaign.data.categories
    //     .filter((category) => category.behavior === "exclude")
    //     .reduce((acc, cur) => {
    //       return {
    //         ...acc,
    //         [cur.parentKeyBeeswax || "-1"]: {
    //           ...acc[cur.parentKeyBeeswax!],
    //           [cur.keyBeeswax]: cur,
    //         },
    //       };
    //     }, {} as CATEGORY_KEY_VALUES_OBJECT);

    //   setExcludes(excludes);
    //   setIncludes(includes);
    // }

    switch (popup.showEditCampaign.step) {
      case 1:
        setSchema(step1Schema);
        break;
      case 2:
        //   setSchema(step1Schema);
        break;
      // case 3:
      //   setSchema(step3Schema);
      // break;
      case 4:
        setSchema(step4Schema);
        break;
      default:
    }
  }, [popup.showEditCampaign.step]);

  const [startingPage, setStartingPage] = React.useState(0);
  const [pageLimit, setPageLimit] = React.useState(10);
  const amountOfKeywords = Object.keys(tableData).length;
  const currentItems = Object.keys(tableData).slice(startingPage, startingPage + pageLimit);

  const slicedTableData = currentItems.reduce((acc, cur) => {
    acc[cur] = tableData[cur];
    return acc;
  }, {} as any);

  React.useEffect(() => {
    setTableData(suggestedKeywords);
  }, [suggestedKeywords]);

  const onSelectAllButtonClick = (newItems: Array<string>) => {
    const selectedData = newItems
      // .slice(startingPage, pageLimit + startingPage)
      .reduce((acc: { [key: string]: IDataForSEOV3 }, cur: string) => {
        acc = { ...acc, [cur]: { ...tableData[cur] } };
        return acc;
      }, {} as { [key: string]: IDataForSEOV3 });

    setSelectedKeywords((prev: { [key: string]: IDataForSEOV3 }) => ({
      ...prev,
      ...selectedData,
    }));
  };

  const onDeselectAllItems = (deselectItems: Array<string>) => {
    setSelectedKeywords((prev: { [key: string]: IDataForSEOV3 }) => {
      deselectItems.forEach((removeKeyword: string) => {
        delete prev[removeKeyword];
      });
      return { ...prev };
    });
  };

  const onKeywordSelect = (addedKeyword: IDataForSEOV3) => {
    setSelectedKeywords((prev: { [key: string]: IDataForSEOV3 }) => {
      if (prev[addedKeyword.keyword]) {
        delete prev[addedKeyword.keyword];
        return { ...prev };
      } else {
        return {
          ...prev,
          [addedKeyword.keyword.toLowerCase()]: {
            ...addedKeyword,
          },
        };
      }
    });
  };

  const renderBuildCampaignStep = () => {
    switch (popup.showEditCampaign.step) {
      case 1:
        return <Step1 formikProps={buildCampaignFormik} />;
      case 2:
        return (
          <Step2
            setError={() => setError("")}
            error=""
            includes={includes}
            excludes={excludes}
            setValues={(includes, excludes) => {
              setIncludes(includes);
              setExcludes(excludes);
            }}
            formikProps={buildCampaignFormik}
          />
        );
      case 3:
        return (
          <Step3
            error=""
            selectedKeys={keywords}
            onDeselectAllItems={onDeselectAllItems}
            onKeywordSelect={onKeywordSelect}
            onSelectAllButtonClick={onSelectAllButtonClick}
            fetchSuggestedKeywords={fetchSuggestedKeywords}
            setTableData={(tableData) => {
              setTableData(() => ({ ...tableData }));
            }}
            loading={loading}
            // tableData={tableData}
            tableData={slicedTableData}
            suggestedKeywords={suggestedKeywords}
          />
        );
      case 4:
        return (
          <Step4
            isInvalidFilesShow={isInvalidFilesShow}
            setShowInvalidFiles={(show) => setShowInvalidFiles(show)}
            formikProps={buildCampaignFormik}
          />
        );
      default:
        return null;
    }
  };

  const renderStepIcon = () => {
    switch (popup.showEditCampaign.step) {
      case 1:
        return (
          <div className="icon-wrapper">
            <Step1Icon className="step-icon" />
            <span className="step-title">1.Details</span>
          </div>
        );
      case 2:
        return (
          <div className="icon-wrapper">
            <Step2Icon className="step-icon" />
            <span className="step-title">2.Targeting</span>
          </div>
        );
      case 3:
        return (
          <div className="icon-wrapper">
            <Step3Icon className="step-icon" />
            <span className="step-title">3.Keywords</span>
          </div>
        );
      case 4:
        return (
          <div className="icon-wrapper">
            <Step4Icon className="step-icon" />
            <span className="step-title">4.Creative</span>
          </div>
        );
      default:
        return null;
    }
  };

  const onExit = () => {
    buildCampaignFormik.resetForm();
    resetState();
    setTableData({});
    setSelectedKeywords({});
  };

  return (
    <EditCampaignModal
      centered
      data-testid="build-campaign"
      show={popup.showEditCampaign.show}
      backdrop="static"
      size="xl"
      onExited={onExit}
    >
      <Modal.Header>
        <Modal.Title>
          <div className="build-campaign-title">
            <div className="heading">
              <b>Edit campaign</b>
            </div>
            {popup.showEditCampaign.step && (
              <div className="modal-description">
                <p className="content">
                  {buildCampaignDescription[popup.showEditCampaign.step - 1]}
                </p>
              </div>
            )}

            {renderStepIcon()}
          </div>
          <CloseButton top={20} right={20} onClick={() => dispatch(hideEditCampaign())} />
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="form-section">
          {/* <div className="progress-percent">
                <span>Progress {(currentStep - 1) * 20}% |</span>
                <span> Completed</span>
              </div> */}
          <div className="scrolly" ref={scrollRef}>
            <div className="steps-form">{renderBuildCampaignStep()}</div>
          </div>
        </div>
        {/* {popup.showEditCampaign.step === 3 && Object.keys(tableData).length ? (
          <TablePagination
            amountOfKeywords={amountOfKeywords}
            setStartingPage={(num) => setStartingPage(num)}
            setPageLimit={(num) => setPageLimit(num)}
            pageLimit={pageLimit}
            startingPage={startingPage}
          />
        ) : null} */}

        <div className="step-navigation">
          {error && <span className="errorMessage">{error}</span>}
          <span>
            <Button color="gray" onClick={() => dispatch(hideEditCampaign())} width={150}>
              {"Cancel"}
            </Button>
          </span>
          <span>
            <Button
              disabled={currentCampaign.loading || loading || isLoading}
              loading={currentCampaign.updating}
              onClick={() => {
                if (
                  popup.showEditCampaign.step === 2 &&
                  !(
                    buildCampaignFormik.values.genders.length ||
                    buildCampaignFormik.values.ageGroups.length ||
                    buildCampaignFormik.values.locations.length ||
                    buildCampaignFormik.values.devices.length ||
                    buildCampaignFormik.values.os.length ||
                    buildCampaignFormik.values.browsers.length ||
                    Object.keys(excludes).length ||
                    Object.keys(includes).length
                  )
                ) {
                  setError("Targeting required");
                } else if (
                  popup.showEditCampaign.step === 3 &&
                  !Object.keys(selectedKeywords).length
                ) {
                  setError("Keywords required");
                } else {
                  setError("");
                  buildCampaignFormik.submitForm();
                }
              }}
              width={150}
            >
              {"Save"}
            </Button>
          </span>
        </div>
      </Modal.Body>
    </EditCampaignModal>
  );
};

export default connector(EditCampaign);
