import CloseButton from "components/CloseButton";
import React from "react";
import { Modal } from "react-bootstrap";
import { connect, ConnectedProps, useDispatch } from "react-redux";
import { hideAdvertiserForm } from "store/actions/popup.actions";
import { RootState } from "store/store";
import { AdvertiserPopupModal } from "./styled";
import DropdownSelect from "components/DropdownSelect";
import { useFormik } from "formik";
import { BUSINESS_TYPE } from "constants/advertiser";
import Input from "components/Input";
import Button from "components/Button";
import advertiserSchema from "schemas/advertiser.schema";
import ErrorMessage from "components/ErrorMessage";
import { updateAdvertiser } from "store/actions/user.actions";

interface IAdvertiserPopupProps extends PropsFromRedux {}

const mapStateToProps = ({ popup, user }: RootState) => ({
  popup,
  user,
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const AdvertiserPopup: React.FunctionComponent<IAdvertiserPopupProps> = ({ popup, user }) => {
  const dispatch = useDispatch();

  const text = `Only one step left
to create your advertising campaigns`;

  const { resetForm, touched, errors, setFieldValue, values, handleChange, submitForm } = useFormik(
    {
      validationSchema: advertiserSchema,
      onSubmit: async (values: { businessId: number; website: string }) => {
        dispatch(updateAdvertiser(values));
      },
      initialValues: {
        businessId: 0,
        website: "",
      },
    }
  );

  const onExit = () => {
    dispatch(hideAdvertiserForm());
    resetForm();
  };

  return (
    <AdvertiserPopupModal
      keyboard
      show={popup.showAdvertiserForm.show}
      onExit={onExit}
      onHide={onExit}
      centered
    >
      <Modal.Header>
        <Modal.Title>
          <CloseButton top={20} right={20} onClick={() => dispatch(hideAdvertiserForm())} />
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="advertiser-form-wrapper">
          <div className="form-title">
            <p>{text}</p>
          </div>
          <div className="input-wrapper">
            <DropdownSelect
              height={40}
              error={touched.businessId && errors.businessId}
              placeholder="Business Category"
              name="businessId"
              onChange={(type: Array<{ value: string }>) => {
                setFieldValue("businessId", type[0].value);
              }}
              value={values.businessId}
              options={BUSINESS_TYPE}
              labelField="text"
              valueField="value"
            />
          </div>

          <div className="input-wrapper">
            <Input
              touched={touched.website}
              error={errors.website}
              noBorder={false}
              black
              height={40}
              onChange={handleChange}
              value={values.website}
              name="website"
              placeholder="Your business website"
            />
          </div>

          <div className="confirm">
            <Button
              height={40}
              width={170}
              color="gray"
              onClick={() => dispatch(hideAdvertiserForm())}
              type="button"
            >
              Cancel
            </Button>

            <Button
              loading={user.loading}
              height={40}
              width={170}
              onClick={() => submitForm()}
              type="button"
            >
              Create Campaign
            </Button>
          </div>
        </div>
      </Modal.Body>
    </AdvertiserPopupModal>
  );
};

export default connector(AdvertiserPopup);
