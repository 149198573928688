import React from "react";
import SelectedCategory from "./SelectedCategory";
import { CategorySelectorStyled } from "./styled";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "store/store";
import CategoryItemList from "./CategoryItemList";
import { CATEGORY_KEY_VALUES_OBJECT, ENUM_CATEGORY_BEHAVIOR } from "constants/category";
import { debounce } from "constants/utility/etc";
import { AdFormCategoryID } from "services/types";

interface ICategorySelectorProps extends PropsFromRedux {
  setValues: (includes: CATEGORY_KEY_VALUES_OBJECT, excludes: CATEGORY_KEY_VALUES_OBJECT) => void;
  includes: CATEGORY_KEY_VALUES_OBJECT;
  excludes: CATEGORY_KEY_VALUES_OBJECT;
}

const mapStateToProps = ({ categories }: RootState) => ({
  categories,
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const CategorySelector: React.FunctionComponent<ICategorySelectorProps> = ({
  categories,
  setValues,
  includes,
  excludes,
}) => {
  const searchRef = React.useRef<HTMLInputElement>(null);

  const handleSearch = debounce(() => {
    // const { current } = searchRef;
    // if (current) {
    //   setCategoryList(
    //     categories.data.filter((item) =>
    //       item.fullName.toLowerCase().includes(current.value.toLowerCase())
    //     )
    //   );
    // }
  });

  React.useEffect(() => {
    setValues(includes, excludes);
  }, [excludes, includes]);

  return (
    <CategorySelectorStyled>
      <div className="section" data-testid="category-wrapper">
        {/* <Input
          dark
          data-testid="search-category"
          noBorder
          ref={searchRef}
          type="text"
          onKeyDown={handleSearch}
          name="seach-category"
          placeholder="Search Content Category"
        /> */}
        <CategoryItemList
          onSelectAll={(newIncludes, newExcludes) => {
            setValues(newIncludes, newExcludes);
          }}
          parents={categories.parent}
          categoryList={categories.data}
          includes={includes}
          excludes={excludes}
          deselect={(item, type: "exclude" | "include") => {
            if (type === "exclude") {
              const getNewExcludes = () => {
                const {
                  [item.source_parent_key]: { [item.source_key]: values, ...excluded },
                  ...rest
                } = excludes;

                return {
                  ...rest,
                  [item.source_parent_key]: excluded,
                };
              };

              const newExcludes = getNewExcludes();
              setValues(includes, newExcludes);
            } else {
              const getNewIncludes = () => {
                const {
                  [item.source_parent_key]: { [item.source_key]: values, ...excluded },
                  ...rest
                } = includes;

                return {
                  ...rest,
                  [item.source_parent_key]: excluded,
                };
              };

              const newIncludes = getNewIncludes();
              setValues(newIncludes, excludes);
            }
          }}
          setIncludes={(item) => {
            if (item.iab_code.includes("-")) {
              const getNewIncludes = () => {
                return {
                  ...includes,
                  [item.source_parent_key]: {
                    ...includes[item.source_parent_key],
                    [item.source_key]: {
                      ...item,
                      behavior: ENUM_CATEGORY_BEHAVIOR.INCLUDE,
                    },
                  },
                };
              };

              const getNewExcludes = () => {
                if (excludes[item.source_parent_key]) {
                  delete excludes[item.source_parent_key][item.source_key];
                }

                return excludes;
              };

              const newIncludes = getNewIncludes();
              const newExcludes = getNewExcludes();
              setValues(newIncludes, newExcludes);
            } else {
              const allSubcategories = categories.data[item.source_parent_key].reduce(
                (acc, cur) => {
                  if (!cur.iab_code.includes("-")) {
                    acc[cur.source_parent_key] = cur;
                  }
                  return acc;
                },
                {} as { [key: string]: AdFormCategoryID & { behavior: "exclude" | "include" } }
              );

              const getNewIncludes = () => {
                return { ...includes, [item.source_parent_key]: allSubcategories };
              };

              const getNewExcludes = () => {
                delete excludes[item.source_parent_key];
                return excludes;
              };

              const newIncludes = getNewIncludes();
              const newExcludes = getNewExcludes();

              setValues(newIncludes, newExcludes);
            }
          }}
          setExcludes={(item) => {
            if (item.iab_code.includes("-")) {
              const getNewIncludes = () => {
                if (includes[item.source_parent_key]) {
                  delete includes[item.source_parent_key][item.source_key];
                }

                return includes;
              };

              const getNewExcludes = () => {
                return {
                  ...excludes,
                  [item.source_parent_key]: {
                    ...excludes[item.source_parent_key],
                    [item.source_key]: {
                      ...item,
                      behavior: ENUM_CATEGORY_BEHAVIOR.EXCLUDE,
                    },
                  },
                };
              };

              const newIncludes = getNewIncludes();
              const newExcludes = getNewExcludes();

              setValues(newIncludes, newExcludes);
            } else {
              const allSubcategories = categories.data[item.source_parent_key].reduce(
                (acc, cur) => {
                  if (!cur.iab_code.includes("-")) {
                    acc[cur.source_parent_key] = cur;
                  }
                  return acc;
                },
                {} as { [key: string]: AdFormCategoryID & { behavior: "exclude" | "include" } }
              );

              const getNewExcludes = () => ({
                ...excludes,
                [item.source_parent_key]: allSubcategories,
              });
              const getNewIncludes = () => {
                delete includes[item.source_parent_key];
                return includes;
              };

              const newIncludes = getNewIncludes();
              const newExcludes = getNewExcludes();

              setValues(newIncludes, newExcludes);
            }
          }}
        />
      </div>
      <div className="section">
        <span className="clear" onClick={() => {}}>
          Remove All
        </span>
        <SelectedCategory includes={includes} excludes={excludes} />
      </div>
    </CategorySelectorStyled>
  );
};

export default connector(CategorySelector);
