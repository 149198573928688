import { put, call, takeEvery, all, fork } from "redux-saga/effects";

import api from "../../services/api";
import * as types from "../action-types/banner.types";
import * as actions from "../actions/banner-actions";
import { handlingError, handlingFormErrors, onSuccess } from "../../constants/utility/error";
import { hideBannerContent, hideScreenLoading } from "../actions/popup.actions";
import { IValidFile } from "components/Dropzone";
import { getAvailableSizes } from "../../constants/utility/upload";
import { IBannerFromServer } from "../states/banner.state";

// create

function* onCreateBanners(action: types.CreateBannersAction) {
  try {
    yield put(actions.createBannersRequest());
    const { data } = yield call(api.banner.createBanners, action.payload);

    if (action.payload.bannerAd.images.length) {
      yield put(actions.createBannersRequest());
      yield call(api.banner.createBannerAssets, action.payload, data.bannerId);
      yield put(actions.createBannerAssetsSuccess());
    }
    yield put(
      actions.createBannersSuccess({
        ...action.payload.bannerAd,
        // images: action.payload.bannerAd.images,
        id: data.bannerId,
      })
    );
    // yield put(hideBannerContent());
  } catch (error: any) {
    yield put(hideScreenLoading());
    yield handlingFormErrors(error);
    yield put(actions.createBannersFailure(error.data));
  }
}

function* watchonCreateBanners() {
  yield takeEvery(types.CREATE_BANNERS, onCreateBanners);
}

// update

function* onUpdateBanners(action: types.UpdateBannersAction) {
  try {
    yield put(actions.updateBannersRequest());
    const { data } = yield call(api.banner.updateBanners, action.payload);
    const addedImages = action.payload.bannerAd.images.filter(
      (banner: IValidFile) => !!banner.imageFile
    );
    if (addedImages.length) {
      yield put(actions.createBannerAssetsRequest());
      yield call(
        api.banner.createBannerAssets,
        {
          ...action.payload,
          bannerAd: {
            ...action.payload.bannerAd,
            images: addedImages,
          },
        },
        data.bannerId
      );

      yield put(actions.createBannerAssetsSuccess());
    }

    if (action.payload.bannerAd.imagesUrl.length) {
      yield put(actions.deleteBannerAssetsRequest());
      const { data: result } = yield call(
        api.banner.deleteBannerAssets,
        action.payload.bannerAd.imagesUrl,
        data.bannerId
      );
      yield put(actions.deleteBannerAssetsSuccess(result));
    }

    const { data: updated, state } = yield call(
      api.campaign.getCampaignById,
      action.payload.campaignId
    );

    if (state === "success") {
      yield put(
        actions.updateBannersSuccess(
          updated.banners.map((banner: IBannerFromServer) => ({
            ...banner,
            thankyouPageUrl: banner.thankyouPageUrl || "",
            imagesUrl: [],
            images: banner.images.map((image) => ({
              ...image,
              id: image.url,
              availableSizes: getAvailableSizes(image.original.width, image.original.height),
              originWidth: image.original.width,
              originHeight: image.original.height,
              type: image.original.mine,
              size: image.original.size,
              thumbnail: `${process.env.REACT_APP_IMAGES_STORAGE}${image.url}`,
            })),
          }))
        )
      );
    }
  } catch (error: any) {
    yield handlingFormErrors(error);
    yield put(actions.updateBannersFailure(error.data));
  }
}

function* watchonUpdateBanners() {
  yield takeEvery(types.UPDATE_BANNERS, onUpdateBanners);
}

// delete

function* onDeleteBanner(action: types.DeleteBannerAction) {
  try {
    yield put(actions.deleteBannerRequest());
    const { data } = yield call(api.banner.deleteBanner, action.payload);
    yield put(actions.deleteBannerSuccess(action.payload));
    yield onSuccess("Delete banner");
  } catch (error: any) {
    yield handlingError(error);
    yield put(actions.deleteBannerFailure(error.data));
  }
}

function* watchonDeleteBanner() {
  yield takeEvery(types.DELETE_BANNER, onDeleteBanner);
}

// get

function* onLoadBanners(action: types.GetBannersAction) {
  try {
    yield put(actions.getBannersRequest());
    // const { data } = yield call(api.creative.getCreative);
    yield put(actions.getBannersSuccess([]));
  } catch (error: any) {
    yield handlingError(error);
    yield put(actions.getBannersFailure(error.data));
  }
}

function* watchonLoadBanners() {
  yield takeEvery(types.GET_BANNERS, onLoadBanners);
}

// edit banner asset

function* onEditBannerAsset(action: types.EditBannerAssetAction) {
  try {
    yield put(actions.editBannerAssetRequest());
    const { state } = yield call(api.banner.editBannerAsset, action.payload);
    if (state === "success") {
      yield put(actions.editBannerAssetSuccess());
    }
  } catch (error: any) {
    yield handlingError(error);
    yield put(actions.editBannerAssetFailure(error.data));
  }
}

function* watchonEditBannerAsset() {
  yield takeEvery(types.EDIT_BANNER_ASSET, onEditBannerAsset);
}

export default function* BannersSaga() {
  yield all([
    fork(watchonUpdateBanners),
    fork(watchonCreateBanners),
    fork(watchonDeleteBanner),
    fork(watchonLoadBanners),
    fork(watchonEditBannerAsset),
  ]);
}
