import React from "react";
import { CustomModal } from "./styled";
import { Modal } from "react-bootstrap";
import Button from "components/Button";
import { RootState } from "store/store";
import { connect, ConnectedProps, useDispatch } from "react-redux";
import { hideForgetPasswordPopup } from "store/actions/popup.actions";
import Checkbox from "components/Checkbox";
import CloseButton from "components/CloseButton";
import api from "services/api";
import Input from "components/Input";
import { AdsIcon } from "components/Icons";
import { useFormik } from "formik";
import EyesCloseIcon from "components/Icons/EyesCloseIcon";
import EyesOpenIcon from "components/Icons/EyesOpenIcon";
import { onForgetPassword } from "store/actions/user.actions";
import updatePasswordSchema from "schemas/update-password.schema";
import forgetPasswordSchema from "schemas/forget-password.schema";

interface IForgetPasswordPopup extends PropsFromRedux {}

const mapStateToProps = ({ popup, forgetPassword }: RootState) => ({
  popup,
  forgetPassword,
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const ForgetPasswordPopup: React.FunctionComponent<IForgetPasswordPopup> = ({
  popup,
  forgetPassword,
}) => {
  const dispatch = useDispatch();

  const onHide = () => {
    dispatch(hideForgetPasswordPopup());
  };

  const { values, errors, handleChange, submitForm, setFieldError, touched, resetForm } = useFormik(
    {
      initialValues: {
        email: "",
      },
      onSubmit: (values) => {
        dispatch(onForgetPassword(values.email));
      },
      validationSchema: forgetPasswordSchema,
    }
  );

  // React.useEffect(() => {
  //   if (ForgetPassword.error) {
  //     setFieldError("email", ForgetPassword.error.newPassword[0]);
  //   }
  // }, [ForgetPassword.error]);

  const onExit = () => {
    resetForm();
  };

  return (
    <CustomModal
      keyboard
      show={popup.showForgetPasswordPopup.show}
      onExit={onExit}
      onHide={onHide}
      centered
    >
      <Modal.Header>
        <b>Forgot Password?</b>
        <CloseButton onClick={onHide} top={10} right={34} dark />
      </Modal.Header>
      <Modal.Body>
        <div className="input-group">
          <div className="input-wrapper">
            <Input
              touched={touched.email}
              error={errors.email}
              name="email"
              value={values.email}
              onChange={handleChange}
              autoComplete="off"
              type="email"
              placeholder="Email Address"
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button width={200} outline onClick={onHide}>
          Cancel
        </Button>
        <Button
          width={200}
          loading={forgetPassword.loading}
          onClick={() => {
            submitForm();
          }}
        >
          Send
        </Button>
      </Modal.Footer>
    </CustomModal>
  );
};

export default connector(ForgetPasswordPopup);
