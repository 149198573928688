import { IErrorMessage } from "../../services/types";
import { CampaignStatus } from "./campaign.state";

export const paymentsListState = {
  loading: false,
  error: null,
  bankInfo: [],
  message: null,
} as {
  loading: boolean;
  error: null | IErrorMessage;
  bankInfo: Array<IBankInfo>;
  message: string | null;
};

export interface IRefIdData {
  campaignId: string;
  beeswaxId?: string;
  adFormId?: string; // retrieve data from API
  adformId?: string; // send data to API
  status?: CampaignStatus;
  portalUrl?: string;
}

export interface IBankInfo {
  bankName?: string;
  id: number;
  name: string;
  fullName: string;
  address: string;
  swiftCode: string;
  taxId: string;
  accountName: string;
  accountNumber: string;
  routingNumber: string;
  iban: string;
  bic: string;
  created_at: Date;
  updated_at: Date;
}
