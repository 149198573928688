import { Column } from "react-table";
import { PAYMENT_STATUS_TEXT } from "store/states/payments.state";
import { CAMPAIGN_STATUS_TEXT } from "store/states/campaign.state";
import { ICampaignListData, ICampaignReportData, IDailyReport } from "store/states/campaigns.state";
import { campaignStatus } from "constants/campaign-status";
import { numberWithCommas } from "constants/utility/number";
import { displayDate } from "constants/utility/date";

export const FREEZE_COLUMNS = ["name", "userName"];

export const renderCellValue = (
  report: IDailyReport | null,
  col: keyof IDailyReport,
  decimal = true
) => {
  if (report && report[col]) {
    return `${numberWithCommas(report[col] || 0, decimal)}`;
  } else {
    return "";
  }
};

export const renderMinimalCellValue = (col?: number, decimal = true) => {
  if (typeof col === "number") {
    if (col) {
      return `${numberWithCommas(col || 0, decimal)}`;
    } else {
      return 0;
    }
  } else {
    return "";
  }
};

export const columns: Array<Column<ICampaignListData>> = [
  {
    Header: "Campaign Name",
    accessor: "name",
    // filter: "includes",
  },
  {
    Header: "Campaign Owner",
    accessor: "userName",
  },

  {
    Header: "Status",
    accessor: "status",
    // filter 'includes',
    Cell: ({ value }) => (
      <div className="status">
        <div className={`status-indicator ${value}`} />
        <span className="current-status">{CAMPAIGN_STATUS_TEXT[value]}</span>
      </div>
    ),
  },

  {
    Header: "Payment",
    id: "state",
    accessor: ({ state }) => (state ? PAYMENT_STATUS_TEXT[state] : ""),
  },
  {
    Header: "Create Date",
    accessor: "created_at",
    filter: "dateRange",
  },
  {
    Header: "Start Date",
    id: "startDate",
    accessor: ({ startDate, state }) => (state ? displayDate(new Date(startDate)) : ""),
    filter: "dateRangeFormatted",
  },
  {
    Header: "End Date",
    id: "endDate",
    accessor: ({ endDate, state }) => (state ? displayDate(new Date(endDate)) : ""),
    filter: "dateRangeFormatted",
  },

  {
    Header: "Total Campaigns",
    id: "totalCampaignDay",
    accessor: ({ totalCampaignDay, state }) => {
      return state ? renderMinimalCellValue(totalCampaignDay, false) : "";
    },
  },
  {
    Header: "Total Running Days",
    id: "totalRunningDay",
    accessor: ({ totalRunningDay, state }) => {
      return state ? renderMinimalCellValue(totalRunningDay, false) : "";
    },
  },

  {
    Header: "Total Budget",
    id: "totalBudget",
    accessor: ({ totalBudget }) => {
      return totalBudget ? `$${numberWithCommas(totalBudget)}` : "";
    },
  },

  {
    Header: "Daily Budget",
    id: "budgetPerDay",
    accessor: ({ budgetPerDay }) => {
      return budgetPerDay ? `$${numberWithCommas(budgetPerDay)}` : "";
    },
  },

  {
    Header: "RAD CPC",
    id: "radCpc",
    accessor: ({ radCpc }) => renderMinimalCellValue(radCpc, false),
  },

  {
    Header: "Clicks",
    id: "click",
    accessor: ({ click }) => renderMinimalCellValue(click, false),
  },
  {
    Header: "Impressions",
    id: "impression",
    accessor: ({ impression }) => renderMinimalCellValue(impression, false),
  },

  {
    Header: "Conversions",
    id: "conversion",
    accessor: ({ conversion }) => renderMinimalCellValue(conversion, false),
  },

  {
    Header: "CTR",
    id: "ctr",
    accessor: ({ ctr }) => (ctr ? `${ctr.toFixed(2)}%` : ""),
  },

  {
    Header: "RAD CPM",
    id: "radCpm",
    accessor: ({ radCpm }) => renderMinimalCellValue(radCpm),
  },

  {
    Header: "RAD CPA",
    id: "radCpa",
    accessor: ({ radCpa }) => renderMinimalCellValue(radCpa),
  },

  {
    Header: "Conversion Rate",
    id: "conversionRate",
    accessor: ({ conversionRate }) => (conversionRate ? `${conversionRate.toFixed(2)}%` : ""),
  },
];

// const filterTypes = React.useMemo(
//   () => ({
//     text: (rows: Array<Row>, id: IdType<string>, filterValue: string) => {
//       return matchSorter(rows, filterValue, { keys: [(row: Row) => row.values[id]] });
//     },
//     dateRange: (rows: Array<Row>, id: IdType<string>, filterValue: IDateRange) => {
//       const filtered = rows.filter((row) => {
//         const end = new Date(filterValue.endDate);
//         return (
//           isAfter(addDays(end, 1), new Date(row.values[id])) &&
//           isBefore(new Date(filterValue.startDate), new Date(row.values[id]))
//         );
//       });
//       return filtered;
//     },
//     dateRangeFormatted: (rows: Array<Row>, id: IdType<string>, filterValue: IDateRange) => {
//       const filtered = rows.filter((row) => {
//         const end = addDays(new Date(filterValue.endDate), 1);
//         const start = addDays(new Date(filterValue.startDate), -1);
//         const compare = new Date(row.values[id]);
//         return isAfter(end, compare) && isBefore(start, compare);
//       });
//       return filtered;
//     },
//   }),
//   []
// );
