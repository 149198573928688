import { Modal } from "react-bootstrap";
import styled from "styled-components/macro";

export const NewKeywordItem = styled.li`
  padding: 10px;
  background: black;
  border-bottom: 1px solid gray;
  font-size: 12px;

  .delete-insert-keyword {
    margin-right: 10px;
    cursor: pointer;
    padding: 5px;
  }
`;

export const ImportKeywordsFormStyled = styled(Modal)`
  background-color: transparent;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  border-radius: 8px;

  .new-keyword-list {
    list-style: none;
    padding: 0;
    margin: 0;
    height: 200px;
    background: black;
    overflow-y: auto;
  }

  .keyword-insert {
    display: flex;
    gap: 10px;
    margin-bottom: 5px;

    form {
      width: 100%;
    }
  }

  .download-sample {
    margin: 10px 0;
    text-align: end;
    font-size: 12px;
    color: cornflowerblue;

    .download-button {
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .file-input {
    visibility: hidden;
    position: absolute;
  }

  .file-button {
    padding: 10px;
    height: auto;
    background: gray;
    width: 200px;
    text-align: center;
  }

  .modal-content {
    color: ${({ theme }) => theme.contrast};
    background-color: ${({ theme }) => theme.alertModalBackground};
    border-radius: 10px;
    border: 1px solid ${({ theme }) => theme.dark};
    padding-top: 20px;
  }

  .modal-header {
    padding-bottom: 0;
    border-bottom: none;
    justify-content: center;

    .modal-title {
      font-size: 16px;
      font-weight: bold;
      color: ${({ theme }) => theme.color.green};
    }
  }

  .modal-body {
    padding: 40px 45px;
  }

  .footer-bar {
    display: flex;
    justify-content: center;
    margin-top: 20px;

    .action-buttons {
      display: flex;
      gap: 10px;
      justify-content: end;
    }
  }
`;
