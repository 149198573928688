import React, { useRef } from "react";
import { StyledDropzone, DropContainer } from "./styled";
import cuid from "cuid";
import aspectRatio, { fileSize, getAvailableSizes, validateFile } from "constants/utility/upload";
import CloudIcon from "components/Icons/CloudIcon";
interface IBannerImage extends File {
  width?: number;
  height?: number;
}

export interface IValidFile {
  id: string;
  name: string;
  originWidth: number;
  originHeight: number;
  width?: number;
  height?: number;
  imageFile?: File;
  url?: string;
  ratio: string;
  size: string;
  type: string;
  availableSizes?: Array<{ width: number; height: number }>;
  thumbnail: string;
}

export interface IInvalidFile {
  id: string;
  name: string;
}

interface IDropzoneProps {
  isError?: boolean;
  setUnsupportedFiles: (invalidFiles: Array<IInvalidFile>) => void;
  setNewBannerImages: (validFiles: Array<IValidFile>) => void;
}

const Dropzone: React.FunctionComponent<IDropzoneProps> = ({
  isError,
  setUnsupportedFiles,
  setNewBannerImages,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const modalImageRef = useRef<HTMLDivElement>(null);
  const modalRef = useRef<HTMLDivElement>(null);
  const progressRef = useRef<HTMLDivElement>(null);
  const uploadRef = useRef<HTMLSpanElement>(null);
  const uploadModalRef = useRef<HTMLDivElement>(null);

  const preventDefault = (e: React.DragEvent<HTMLInputElement>) => {
    e.preventDefault();
  };

  const dragOver = (e: React.DragEvent<HTMLInputElement>) => {
    preventDefault(e);
  };

  const dragEnter = (e: React.DragEvent<HTMLInputElement>) => {
    preventDefault(e);
  };

  const dragLeave = (e: React.DragEvent<HTMLInputElement>) => {
    preventDefault(e);
  };

  const fileDrop = async (e: React.DragEvent<HTMLInputElement>) => {
    preventDefault(e);
    const { files } = e.dataTransfer;
    if (files.length) {
      const [validFiles, invalidFiles] = await handleFiles(files);
      setNewBannerImages(validFiles as IValidFile[]);
      setUnsupportedFiles(invalidFiles);
    }
  };

  const filesSelected = async () => {
    if (fileInputRef.current && fileInputRef.current.files && fileInputRef.current.files.length) {
      const [validFiles, invalidFiles] = await handleFiles(fileInputRef.current.files);
      setNewBannerImages(validFiles as IValidFile[]);
      setUnsupportedFiles(invalidFiles);

      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    }
  };

  const fileInputClicked = () => {
    fileInputRef.current && fileInputRef.current.click();
  };

  const handleFiles = async (files: FileList) => {
    const validFiles: Array<IValidFile> = [];
    const invalidFiles: Array<IInvalidFile> = [];
    const promiseArray = [];

    for (let i = 0; i < files.length; i++) {
      const currentFile: IBannerImage = files[i];
      promiseArray.push(
        new Promise((resolve) => {
          if (validateFile(currentFile)) {
            const img = new Image();

            const objectURL = URL.createObjectURL(currentFile);
            img.src = objectURL;

            img.onload = () => {
              currentFile.width = img.width;
              currentFile.height = img.height;

              const sizesList = getAvailableSizes(img.width, img.height);
              if (sizesList && sizesList.length) {
                validFiles.push({
                  id: cuid(),
                  name: `${currentFile.name
                    .replace(/[- ]+/g, "_")
                    .replace(/[^A-Za-z0-9_.-]+/g, "")
                    .replace(/\.[^/.]+/g, "")}`,
                  originWidth: img.width,
                  originHeight: img.height,
                  imageFile: currentFile,
                  ratio: aspectRatio(img.width, img.height),
                  availableSizes: sizesList,
                  size: fileSize(currentFile.size),
                  type: currentFile.type.substring(6),
                  thumbnail: objectURL,
                  width: sizesList && sizesList[sizesList.length - 1].width,
                  height: sizesList && sizesList[sizesList.length - 1].height,
                });
              } else {
                invalidFiles.push({
                  name: currentFile.name,
                  id: cuid(),
                });
              }
              resolve("");
            };
          } else {
            invalidFiles.push({
              name: currentFile.name,
              id: cuid(),
            });
            resolve("");
          }
        })
      );
    }
    await Promise.allSettled(promiseArray);
    return [validFiles, invalidFiles];
  };

  // const fileType = (fileName) => {
  //     return fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length) || fileName;
  // }

  const closeModal = () => {
    if (modalRef.current && modalImageRef.current) {
      modalRef.current.style.display = "none";
      modalImageRef.current.style.backgroundImage = "none";
    }
  };

  // const uploadFiles = async () => {
  //     uploadModalRef.current.style.display = 'block';
  //     uploadRef.current.innerHTML = 'File(s) Uploading...';
  //     for (let i = 0; i < validFiles.length; i++) {
  //         const formData = new FormData();
  //         formData.append('image', validFiles[i]);
  //         formData.append('key', '');

  //         axios.post('https://api.imgbb.com/1/upload', formData, {
  //             onUploadProgress: (progressEvent) => {
  //                 const uploadPercentage = Math.floor((progressEvent.loaded / progressEvent.total) * 100);
  //                 progressRef.current.innerHTML = `${uploadPercentage}%`;
  //                 progressRef.current.style.width = `${uploadPercentage}%`;

  //                 if (uploadPercentage === 100) {
  //                     uploadRef.current.innerHTML = 'File(s) Uploaded';
  //                     validFiles.length = 0;
  //                     setNewBannerImages([...validFiles]);
  //                     setSelectedFiles([...validFiles]);
  //                     setUnsupportedFiles([...validFiles]);
  //                 }
  //             },
  //         })
  //         .catch(() => {
  //             uploadRef.current.innerHTML = `<span class="error">Error Uploading File(s)</span>`;
  //             progressRef.current.style.backgroundColor = 'red';
  //         })
  //     }
  // }

  const closeUploadModal = () => {
    if (uploadModalRef.current) {
      uploadModalRef.current.style.display = "none";
    }
  };

  return (
    <StyledDropzone>
      {/* {unsupportedFiles.length === 0 && validFiles.length ? <button className="file-upload-btn" onClick={() => uploadFiles()}>Upload Files</button> : ''}  */}
      <DropContainer
        isError={isError}
        onDragOver={dragOver}
        onDragEnter={dragEnter}
        onDragLeave={dragLeave}
        onDrop={fileDrop}
        onClick={fileInputClicked}
      >
        {isError && <div className="errorMessage txt-center">{isError}</div>}
        <div className="drop-message">
          <div className="upload-icon">
            <CloudIcon />
          </div>
          <div className="upload-message">
            <span>Upload your banner ads </span>
          </div>
        </div>
        <input
          ref={fileInputRef}
          data-testid="dropzone"
          className="file-input"
          type="file"
          multiple
          onChange={filesSelected}
        />
      </DropContainer>
      <div className="modal" ref={modalRef}>
        <div className="overlay" />
        <span className="close" onClick={() => closeModal()}>
          X
        </span>
        <div className="modal-image" ref={modalImageRef} />
      </div>

      <div className="upload-modal" ref={uploadModalRef}>
        <div className="overlay" />
        <div className="close" onClick={() => closeUploadModal()}>
          X
        </div>
        <div className="progress-container">
          <span ref={uploadRef} />
          <div className="progress">
            <div className="progress-bar" ref={progressRef} />
          </div>
        </div>
      </div>
    </StyledDropzone>
  );
};

export default Dropzone;
