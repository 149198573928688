import { IPostCampaignData } from "constants/utility/campaign";
import { IErrorMessage } from "services/types";

export const CAMPAIGN_PAYMENT = "campaign-actions/CAMPAIGN_PAYMENT";
export interface campaignPaymentAction {
  type: typeof CAMPAIGN_PAYMENT;
  payload: IPostCampaignData & { campaignId: string };
}

export const CAMPAIGN_PAYMENT_REQUEST = "campaign-actions/CAMPAIGN_PAYMENT_REQUEST";

export interface campaignPaymentRequestAction {
  type: typeof CAMPAIGN_PAYMENT_REQUEST;
}

export const CAMPAIGN_PAYMENT_SUCCESS = "campaign-actions/CAMPAIGN_PAYMENT_SUCCESS";
export interface campaignPaymentSuccessAction {
  type: typeof CAMPAIGN_PAYMENT_SUCCESS;
  payload: IPostCampaignData;
}

export const CAMPAIGN_PAYMENT_FAILURE = "campaign-actions/CAMPAIGN_PAYMENT_FAILURE";
export interface campaignPaymentFailureAction {
  type: typeof CAMPAIGN_PAYMENT_FAILURE;
  error: IErrorMessage;
}
