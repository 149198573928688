import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Button from "../Button";
import {
  UserFilterStyled,
  UserFilterPopover,
  FilterListSelector,
  FilteringItem,
  AddFilterButton,
  ColumnOption,
  StyledTooltip,
} from "./styled";
import Input from "../Input";
import { Formik, useFormik } from "formik";
import { OverlayInjectedProps } from "react-bootstrap/esm/Overlay";
import { FilterIcon } from "../Icons";
import useOnClickOutside from "../../hooks/useClickOutside";
import DropdownSelect from "../DropdownSelect";
import { Range } from "react-date-range";
import DateRange from "../DateRange";
import { displayDate } from "constants/utility/date";
import { isSameDay } from "date-fns";
import { onSelectFilter } from "./helper";
import { FILTER_COLUMN, FILTER_COLUMN_ITEMS, FILTER_COLUMN_TEXT, IDateRange } from "./constants";
import { RootState } from "store/store";
import { connect, ConnectedProps } from "react-redux";
import { BUSINESS_TYPE } from "constants/advertiser";
import { ICountry } from "store/action-types/countries.types";
import { FILTER_INPUT } from "constants/types";
import Badge from "../Badge";
import { USER_ROLE } from "store/states/user.state";

const mapStateToProps = ({ keywordLocations }: RootState) => ({
  keywordLocations,
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export interface IFilterColumn {
  created_at?: IDateRange;
  email?: string;
  package?: "Free";
  // totalCampaign?: number
  role?: USER_ROLE;
  updated_at?: IDateRange;
  // userName?: string;
  deleted_at?: string;
}
interface IUserFilterProps extends PropsFromRedux {
  onFilter: (values: IFilterColumn) => void;
  onFilterStatusChange?: (status?: any) => void;
  currentFilters?: IFilterColumn;
}
export interface IFilterItem {
  filter: {
    key: string;
    format: string;
  };
  value: string | { startDate?: Date; endDate?: Date } | Array<string>;
}

const UserFilter: React.FunctionComponent<IUserFilterProps> = ({ onFilter, keywordLocations }) => {
  const filterTarget = React.useRef<HTMLDivElement>(null);
  const dateRangeTarget = React.useRef<HTMLDivElement>(null);

  const [isShowFilterPopup, setShowFilterPopup] = React.useState(false);
  const [isShowDateRange, setShowDateRange] = React.useState(false);
  const [selectedDateRange, setSelectedDateRange] = React.useState<Array<Range>>([
    {
      startDate: undefined,
      endDate: undefined,
      key: "selection",
    },
  ]);

  const [filtering, setFiltering] = React.useState<Array<IFilterItem>>([]);

  const [selecting, setSelecting] = React.useState<{
    text: string;
    key: string;
    input: string;
  } | null>(null);

  useOnClickOutside(filterTarget, () => setShowFilterPopup(false));
  useOnClickOutside(dateRangeTarget, () => {
    setSelecting(null);
    setShowDateRange(false);
  });

  const filterPopover = (props: OverlayInjectedProps) => {
    const cols = filtering.map((current) => current.filter.key);

    return (
      <Tooltip style={{ opacity: 1 }} ref={filterTarget} id="filter-tooltip">
        <FilterListSelector
          {...props}
          style={{
            ...props.style,
          }}
          id="filter-popover"
        >
          <ul>
            {FILTER_COLUMN_ITEMS.map((col) => {
              return (
                <ColumnOption
                  disabled={cols.includes(col.key)}
                  key={col.key}
                  onClick={() => {
                    setShowFilterPopup(false);
                    setSelecting(col);
                    if (col.input === FILTER_INPUT.DATE_RANGE) {
                      setShowDateRange(true);
                    }
                  }}
                >
                  {col.text}
                </ColumnOption>
              );
            })}
          </ul>
        </FilterListSelector>
      </Tooltip>
    );
  };

  const dateRangePopover = (props: OverlayInjectedProps) => {
    return (
      <Tooltip style={{ opacity: 1 }} ref={dateRangeTarget} id="date-range-tooltip">
        <UserFilterPopover
          {...props}
          style={{
            ...props.style,
          }}
          id="date-range-popover"
        >
          <DateRange
            ranges={selectedDateRange}
            onSelection={(range) => {
              setSelectedDateRange([range]);
              if (range.startDate && range.endDate && !isSameDay(range.startDate, range.endDate)) {
                setFiltering((prev) => {
                  setSelecting(null);
                  if (selecting) {
                    return onSelectFilter(selecting, prev, FILTER_INPUT.DATE_RANGE, {
                      startDate: range.startDate,
                      endDate: range.endDate,
                    });
                  } else {
                    return prev;
                  }
                });

                selecting &&
                  onFilter({
                    [selecting.key]: {
                      startDate: range.startDate,
                      endDate: range.endDate,
                    },
                  });

                setShowDateRange(false);

                setSelectedDateRange([
                  {
                    startDate: undefined,
                    endDate: undefined,
                    key: "selection",
                  },
                ]);
              }
            }}
          />
        </UserFilterPopover>
      </Tooltip>
    );
  };

  const submitFilter = (values: { text: string }) => {
    setSelecting(null);

    setFiltering((prev) => {
      if (selecting && values.text.trim()) {
        return onSelectFilter(selecting, prev, FILTER_INPUT.TEXT, values.text);
      } else {
        return prev;
      }
    });

    selecting &&
      values.text.trim() &&
      onFilter({
        [selecting.key]: values.text,
      });
  };

  const formik = useFormik({
    onSubmit: submitFilter,
    initialValues: {
      text: "",
    },
  });

  const multi = useFormik({
    onSubmit: (values, { resetForm }) => {
      if (values.selected.length) {
        setFiltering((prev) => {
          setSelecting(null);
          if (selecting) {
            return onSelectFilter(
              selecting,
              prev,
              FILTER_INPUT.MULTI_SELECT,
              values.selected.map((item) => {
                if (selecting.key === FILTER_COLUMN.COUNTRY) {
                  return item.name;
                } else if (selecting.key === FILTER_COLUMN.BUSINESS) {
                  return item.text;
                } else {
                  return "";
                }
              })
            );
          } else {
            return prev;
          }
        });

        selecting &&
          onFilter({
            [selecting.key]: values.selected.map((item) => {
              if (selecting.key === FILTER_COLUMN.COUNTRY) {
                return item.name;
              } else if (selecting.key === FILTER_COLUMN.BUSINESS) {
                return item.value;
              } else {
                return null;
              }
            }),
          });

        resetForm();
      }
    },
    initialValues: {
      selected: [] as Array<{ id: string; name: string; value: number; text: string }>,
    },
  });

  const renderOptions = (type: string) => {
    switch (type) {
      case FILTER_COLUMN.COUNTRY:
        return (
          <DropdownSelect
            multi
            border={false}
            searchable={false}
            className="dropdown-select"
            labelField="name"
            valueField="name"
            onChange={(status: Array<ICountry>) => {
              multi.setFieldValue("selected", status);
            }}
            values={multi.values.selected}
            options={keywordLocations.countries}
          />
        );
      case FILTER_COLUMN.BUSINESS:
        return (
          <DropdownSelect
            multi
            border={false}
            searchable={false}
            className="dropdown-select"
            labelField="text"
            valueField="value"
            onChange={(status: Array<{ value: string }>) => {
              multi.setFieldValue("selected", status);
            }}
            values={multi.values.selected}
            options={BUSINESS_TYPE}
          />
        );
      case FILTER_COLUMN.PACKAGE:
        return (
          <DropdownSelect
            border={false}
            searchable={false}
            className="dropdown-select"
            labelField="text"
            valueField="value"
            onChange={(status: Array<{ value: string }>) => {
              formik.setFieldValue("text", status[0].value.toString());
            }}
            values={[{ name: formik.values.text }]}
            options={[
              {
                text: "Free",
                value: "Free",
              },
            ]}
          />
        );
      // case FILTER_COLUMN.IS_ADMIN:
      //   return (
      //     <DropdownSelect
      //       border={false}
      //       searchable={false}
      //       className="dropdown-select"
      //       labelField="text"
      //       valueField="value"
      //       onChange={(status: Array<{ value: string }>) => {
      //         formik.setFieldValue("text", status[0].value.toString());
      //       }}
      //       values={[{ name: formik.values.text }]}
      //       options={[
      //         {
      //           text: "Yes",
      //           value: 1,
      //         },
      //         {
      //           text: "No",
      //           value: 0,
      //         },
      //       ]}
      //     />
      //   );

      case FILTER_COLUMN.DELETED:
        return (
          <DropdownSelect
            border={false}
            searchable={false}
            className="dropdown-select"
            labelField="text"
            valueField="value"
            onChange={(status: Array<{ value: string }>) => {
              formik.setFieldValue("text", status[0].value.toString());
            }}
            values={[{ name: formik.values.text }]}
            options={[
              {
                text: "Active",
                value: 1,
              },
              {
                text: "Deleted",
                value: 0,
              },
            ]}
          />
        );
      default:
        return [];
    }
  };

  const renderFilterInput = (selecting: { input: string; key: string; text: string }) => {
    switch (selecting.input) {
      case FILTER_INPUT.TEXT:
        return (
          <Formik
            onSubmit={submitFilter}
            initialValues={{
              text: "",
            }}
          >
            {({ touched, errors, values, handleChange, handleSubmit }) => {
              return (
                <form className="filter-form" onSubmit={handleSubmit}>
                  <Input
                    dark
                    autoComplete="off"
                    touched={touched.text}
                    error={errors.text}
                    name="text"
                    value={values.text}
                    onChange={handleChange}
                  />
                  <Button secondary type="submit">
                    Apply
                  </Button>
                </form>
              );
            }}
          </Formik>
        );

      case FILTER_INPUT.SELECT:
        return (
          <form className="filter-form" onSubmit={formik.handleSubmit}>
            {renderOptions(selecting.key)}
            <Button type="submit" secondary>
              Apply
            </Button>
          </form>
        );

      case FILTER_INPUT.MULTI_SELECT:
        return (
          <form className="filter-form" onSubmit={multi.handleSubmit}>
            {renderOptions(selecting.key)}
            <Button disabled={!multi.values.selected.length} type="submit" secondary>
              Apply
            </Button>
          </form>
        );

      default:
        return null;
    }
  };

  return (
    <UserFilterStyled>
      <div className="filtering-tab">
        <div className="filters">
          <FilterIcon />
          <span> Filters:</span>
        </div>
      </div>

      {filtering.map((filterList) => {
        return (
          <FilteringItem isMulti={filterList.filter.format === "multi"} key={filterList.filter.key}>
            <div className="current-column">
              {FILTER_COLUMN_TEXT[filterList.filter.key as keyof IFilterColumn]}
            </div>
            {filterList.filter.format === FILTER_INPUT.DATE_RANGE &&
            typeof filterList.value !== "string" &&
            !Array.isArray(filterList.value) &&
            filterList.value.startDate &&
            filterList.value.endDate ? (
              <div>
                <span>{`${displayDate(filterList.value.startDate)} - ${displayDate(
                  filterList.value.endDate
                )}`}</span>
              </div>
            ) : Array.isArray(filterList.value) ? (
              <ul className="multi-list">
                {filterList.value.slice(0, 3).map((multiItem) => (
                  <Badge size="xs" text={multiItem} color="#FE0099" key={multiItem} />
                ))}

                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <StyledTooltip id={`tooltip-compare`}>
                      <ul>
                        {filterList.value.slice(3).map((multiItem) => (
                          <Badge size="xs" text={multiItem} color="#FE0099" key={multiItem} />
                        ))}
                      </ul>
                    </StyledTooltip>
                  }
                >
                  <span>
                    {filterList.value.length > 3 && (
                      <Badge size="xs" text={`+${filterList.value.length - 3}`} color="#FE0099" />
                    )}
                  </span>
                </OverlayTrigger>
              </ul>
            ) : // ) : filterList.filter.key === FILTER_COLUMN.IS_ADMIN ? (
            //   <span>{Number(filterList.value) ? "Yes" : "No"}</span>
            filterList.filter.key === FILTER_COLUMN.DELETED ? (
              <span>{Number(filterList.value) ? "Active" : "Deleted"}</span>
            ) : (
              <span>{filterList.value.toString()}</span>
            )}
            <span
              className="remove-filter"
              onClick={() => {
                setFiltering((prev) =>
                  prev.filter((item) => item.filter.key !== filterList.filter.key)
                );
                onFilter({
                  [filterList.filter.key as keyof IFilterColumn]: "",
                });
              }}
            />
          </FilteringItem>
        );
      })}

      {filtering.length !== FILTER_COLUMN_ITEMS.length && (
        <OverlayTrigger
          show={isShowFilterPopup}
          placement="bottom-start"
          trigger="click"
          overlay={filterPopover}
        >
          <span>
            {!selecting && (
              <AddFilterButton onClick={() => setShowFilterPopup(!isShowFilterPopup)}>
                +
              </AddFilterButton>
            )}
          </span>
        </OverlayTrigger>
      )}

      <OverlayTrigger
        show={!!selecting && isShowDateRange}
        placement="bottom-start"
        trigger="click"
        overlay={dateRangePopover}
      >
        <div className="filter-content">
          {selecting && (
            <div className="current-input">
              {<span className="selecting-col">{selecting.text}</span>}
              <div className="current-filter-wrapper">
                {renderFilterInput(selecting)}

                {selecting.input !== FILTER_INPUT.DATE_RANGE && (
                  <span
                    className="hide-filter"
                    onClick={() => {
                      multi.resetForm();
                      setSelecting(null);
                    }}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </OverlayTrigger>
    </UserFilterStyled>
  );
};

export default connector(UserFilter);
