import { AnyAction } from "redux";
import * as USER_ACTIONS from "../action-types/users.types";
import { IUserAuthentication } from "../states/user.state";

const initialState = {
  loading: false,
  updateUserLoading: false,
  data: [],
  error: null,
  total: 0,
} as {
  total: number;
  updateUserLoading: boolean;
  loading: boolean;
  error: null | Error;
  data: Array<IUserAuthentication>;
};

const usersReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    // get
    case USER_ACTIONS.GET_USERS_REQUEST:
      state = { ...state, loading: true, data: [], error: null };
      break;
    case USER_ACTIONS.GET_USERS_SUCCESS:
      state = {
        ...state,
        loading: false,
        data: action.users,
        total: action.total,
        error: null,
      };
      break;
    case USER_ACTIONS.GET_USERS_FAILURE:
      state = {
        ...state,
        loading: false,
        data: [],
        error: action.error,
      };
      break;

    // update

    case USER_ACTIONS.UPDATE_USER_REQUEST:
      state = { ...state, error: null, updateUserLoading: true };
      break;
    case USER_ACTIONS.UPDATE_USER_SUCCESS:
      const index = state.data.findIndex((user) => user.id === action.user.id);
      state = {
        ...state,
        updateUserLoading: false,
        data: [...state.data.slice(0, index), { ...action.user }, ...state.data.slice(index + 1)],
      };

      break;
    case USER_ACTIONS.UPDATE_USER_FAILURE:
      state = {
        ...state,
        updateUserLoading: false,
        error: action.error,
      };
      break;

    // delete

    case USER_ACTIONS.DELETE_USERS_REQUEST:
      state = { ...state, error: null, loading: true };
      break;
    case USER_ACTIONS.DELETE_USERS_SUCCESS:
      state = {
        ...state,
        loading: false,
        data: state.data.filter((item) => action.ids.indexOf(item.id) === -1),
      };

      break;
    case USER_ACTIONS.DELETE_USERS_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.error,
      };
      break;

    default:
  }

  return state;
};

export default usersReducer;
