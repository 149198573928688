import * as Yup from "yup";
import { regex } from "constants/utility/campaign";

export const register = Yup.object().shape({
  fullName: Yup.string()
    .required("Please enter your full name")
    .min(4, "Full name must be 4 characters long")
    .matches(/^[a-zA-Z0-9_]+( [a-zA-Z0-9_]+)*$/, "Please enter a valid full name"),

  confirmPassword: Yup.string()
    .required("Please enter your confirm password")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),

  // website: Yup.string()
  //   .matches(regex, "*Enter correct url")
  //   .min(2, "Website URL must have at least 2 characters")
  //   .max(100, "Website URL can't be longer than 100 characters")
  //   .required("Website URL is required"),

  subscribeSpecialOffer: Yup.boolean(),
  termsAndCondition: Yup.boolean().isTrue("Please check read terms and condition agreement"),

  // businessId: Yup.number().required("Business type is required"),

  // country: Yup.string().required("Country is required"),
});

export const validation = Yup.object().shape({
  email: Yup.string()
    .required("Please enter your email")
    .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, "Please enter a valid email address"),

  password: Yup.string()
    .required("Please enter your password")
    .min(8, "Password must be 8 characters long")
    .matches(
      /^[a-zA-Z0-9.!@?#"$%&:';()*\+,\/;\-=[\\\]\^_{|}<>~`]+$/,
      "Please enter a valid password character"
    ),
});

export const updateUserSchema = Yup.object().shape({
  fullName: Yup.string()
    .required("Please enter your full name")
    .min(4, "Full name must be 4 characters long")
    .matches(/^[a-zA-Z0-9_]+( [a-zA-Z0-9_]+)*$/, "Please enter a valid full name"),

  email: Yup.string().required("Email is required"),
});
