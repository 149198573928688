import { AnyAction } from "redux";
import * as actions from "../actions/settings.actions";
import settingsState from "../states/settings.state";

const settingsReducer = (state = settingsState, action: AnyAction) => {
  switch (action.type) {
    case actions.CHANGE_LANGUAGE:
      state = { ...state, language: action.payload };
      break;

    case actions.CHANGE_DATE_FORMAT:
      state = { ...state, dateFormat: action.payload };
      break;

    default:
  }

  return state;
};

export default settingsReducer;
