import React from "react";
import { InputGroup, Dropdown, DropdownButton, FormControl } from "react-bootstrap";
import { dropdownItemValues } from "modules/Client/pages/Campaign/constants";
import { SavedListDropdownStyled } from "./styled";
import Spinner from "../Spinner";
import Tooltip from "../Tooltip";
import { messages } from "constants/utility/messages";
import Input from "components/Input";
import { RootState } from "store/store";
import { connect, ConnectedProps } from "react-redux";

const mapStateToProps = ({ keywordsList }: RootState) => ({ keywordsList });

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
interface SavedListDropdownProps extends PropsFromRedux {
  text: string;
  setFieldValue: (field: string, val: any) => void;
  onSelectSearchList: any;
  submitForm: () => void;
  handleBlur: any;
  handleChange: React.ChangeEventHandler<any>;
  values: any;
  loading?: boolean;
  error?: string;
}

const SavedListDropdown: React.FunctionComponent<SavedListDropdownProps> = ({
  submitForm,
  handleBlur,
  handleChange,
  values,
  onSelectSearchList,
  text,
  keywordsList,
  setFieldValue,
  loading,
  error,
}) => {
  return (
    <SavedListDropdownStyled disabled={loading}>
      <Tooltip
        placement={messages.KeywordSelection.placement}
        color={messages.KeywordSelection.color}
        id={messages.KeywordSelection.id}
        background={messages.KeywordSelection.background}
        content={messages.KeywordSelection.content}
      >
        <DropdownButton title={text} id="select-keyword-searchtype">
          <Dropdown.Item
            onClick={() => setFieldValue("mode", dropdownItemValues.suggestedKeywords.value)}
          >
            Suggested Keywords
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => setFieldValue("mode", dropdownItemValues.targetByCompetitor.value)}
          >
            Target by Competitor
          </Dropdown.Item>
          <Tooltip
            placement={messages.savedlist.placement}
            color={messages.savedlist.color}
            id={messages.savedlist.id}
            background={messages.savedlist.background}
            content={messages.savedlist.content}
          >
            <Dropdown.Header>Your Saved Lists</Dropdown.Header>
          </Tooltip>
          {!keywordsList.loading && keywordsList.data.length ? <Dropdown.Divider /> : ""}
          {keywordsList.loading ? (
            <Spinner />
          ) : keywordsList.data.length ? (
            keywordsList.data.map((list: any) => (
              <Dropdown.Item
                onClick={() => {
                  setFieldValue("keyword", "");
                  setFieldValue("mode", list.name);
                  onSelectSearchList(list);
                }}
                key={`campaignSearchTypeSavedList_${list.name}`}
              >
                {list.name}
              </Dropdown.Item>
            ))
          ) : (
            <div className="no-saved-msg">No keyword saved.</div>
          )}
        </DropdownButton>
      </Tooltip>
      <input
        data-testid="keyword-input"
        aria-describedby="basic-addon1"
        placeholder={
          values.mode === dropdownItemValues.targetByCompetitor.value
            ? "Enter competitor URL "
            : "Enter Search Keyword"
        }
        onBlur={handleBlur}
        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
          if (e.key === "Enter") {
            e.preventDefault();
            // setFieldValue("mode", dropdownItemValues.suggestedKeywords.value);
            submitForm();
          }
        }}
        onChange={handleChange}
        value={values.keyword || ""}
        disabled={loading}
        className="keyword-input"
        name="keyword"
        autoComplete="off"
      />
      {error && <span className="select-keyword-error">{error}</span>}
    </SavedListDropdownStyled>
  );
};

export default connector(SavedListDropdown);
