import { IErrorMessage } from "../../services/types";
import * as types from "../action-types/countries.types";

export function getCountries(): types.GetCountriesAction {
  return {
    type: types.GET_COUNTRIES,
  };
}

export function getCountriesRequest(): types.GetCountriesRequestAction {
  return {
    type: types.GET_COUNTRIES_REQUEST,
  };
}

export function getCountriesSuccess(countries: types.ICountry[]): types.GetCountriesSuccessAction {
  return {
    type: types.GET_COUNTRIES_SUCCESS,
    countries,
  };
}

export function getCountriesFailure(error: IErrorMessage): types.GetCountriesFailureAction {
  return {
    type: types.GET_COUNTRIES_FAILURE,
    error,
  };
}
