import styled from "styled-components/macro";

export const MySettingsStyled = styled.div`
  .settings-wrapper {
    display: flex;
    margin-top: 30px;

    .field {
      margin-bottom: 30px;
    }

    .setting-title {
      font-size: 12px;
      color: ${({ theme }) => theme.text};
      font-weight: bold;
      display: flex;
    }

    .profile-settings {
      width: 50%;
      margin-right: 50px;

      .profile-wrapper {
        display: flex;
        margin-top: 20px;

        .profile {
          .thumbnail {
            border: 1px solid ${({ theme }) => theme.disabled};
            border-radius: 50%;
            font-size: 26px;
            color: ${({ theme }) => theme.background};
            font-weight: bold;
            display: flex;
            justify-content: center;
            align-items: center;
            background: ${({ theme }) => theme.secondary};
            width: 60px;
            height: 60px;
          }
        }

        .user-info {
          width: 100%;
          margin-left: 30px;

          .button-wrapper {
            padding: 20px 0;
          }

          .info-value {
            font-weight: 600;
            padding-bottom: 10px;
            border-bottom: 1px solid ${({ theme }) => theme.homeIcon};
          }
        }
      }
    }

    .info-field {
      font-size: 14px;
      color: ${({ theme }) => theme.text};
      margin-bottom: 10px;
    }

    .general-settings {
      width: 50%;
      padding: 0 20px;
      border-left: 1px solid ${({ theme }) => theme.homeIcon};

      .setting-inputs {
        margin-top: 30px;

        .date-format-selectors {
          display: flex;
          gap: 20px;
          font-size: 12px;

          .checkbox-wrapper {
            display: flex;
            align-items: center;
            gap: 10px;
            cursor: pointer;
          }
        }

        .dark-mode {
          display: flex;
          align-items: center;
          gap: 10px;
        }

        .switch-wrapper {
          margin-bottom: 10px;
        }
      }

      .setting-wrapper {
        margin-top: 20px;
      }
    }
  }
`;
