import { AnyAction } from "redux";
import * as types from "../action-types/creative.types";
import creative from "../states/creative.state";

const creativeReducer = (state = creative, action: AnyAction) => {
  switch (action.type) {
    // create
    case types.CREATE_CREATIVE_REQUEST:
      state = { ...state, loading: true };
      break;
    case types.CREATE_CREATIVE_SUCCESS:
      state = { ...state, data: action.payload, loading: false };
      break;
    case types.CREATE_CREATIVE_FAILURE:
      state = { ...state, error: action.error, loading: false };
      break;

    // get
    case types.GET_CREATIVE_REQUEST:
      state = { ...state, loading: true };
      break;
    case types.GET_CREATIVE_SUCCESS:
      state = { ...state, data: action.payload, loading: false };
      break;
    case types.GET_CREATIVE_FAILURE:
      state = { ...state, error: action.error, loading: false };
      break;

    // update

    case types.UPDATE_CREATIVE_REQUEST:
      state = { ...state, loading: true };
      break;
    case types.UPDATE_CREATIVE_SUCCESS:
      state = { ...state, data: { ...state.data, ...action.payload }, loading: false };
      // state = { ...state, data: action.payload, loading: false };
      break;
    case types.UPDATE_CREATIVE_FAILURE:
      state = { ...state, error: action.error, loading: false };
      break;

    // delete

    case types.DELETE_CREATIVE_REQUEST:
      state = { ...state, loading: true };
      break;
    case types.DELETE_CREATIVE_SUCCESS:
      state = { ...state, data: null, loading: false };
      break;
    case types.DELETE_CREATIVE_FAILURE:
      state = { ...state, error: action.error, loading: false };
      break;

    // create creative assets

    case types.CREATE_CREATIVE_ASSETS_REQUEST:
      state = { ...state, loading: true };
      break;
    case types.CREATE_CREATIVE_ASSETS_SUCCESS:
      state = { ...state, assets: action.payload, loading: false };
      break;
    case types.CREATE_CREATIVE_ASSETS_FAILURE:
      state = { ...state, error: action.error, loading: false };
      break;

    // delete creative assets

    case types.DELETE_CREATIVE_ASSETS_REQUEST:
      state = { ...state, loading: true };
      break;
    case types.DELETE_CREATIVE_ASSETS_SUCCESS:
      state = { ...state, assets: action.payload, loading: false };
      break;
    case types.DELETE_CREATIVE_ASSETS_FAILURE:
      state = { ...state, error: action.error, loading: false };
      break;

    default:
  }

  return state;
};

export default creativeReducer;
