import { AnyAction } from "redux";
import * as actions from "../action-types/feed.types";
import feedsState from "../states/feeds.state";

const feedsReducer = (state = feedsState, action: AnyAction) => {
  switch (action.type) {
    case actions.GET_FEEDS_REQUEST:
      state = { ...state, loading: true };
      break;
    case actions.GET_FEEDS_SUCCESS:
      state = {
        ...state,
        total: action.total,
        data: [...state.data, ...action.payload],
        loading: false,
      };
      break;
    case actions.INITIALIZE_FEED:
      state = {
        ...state,
        total: action.total,
        data: action.payload,
        loading: false,
      };
      break;
    case actions.GET_FEEDS_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;
    default:
  }

  return state;
};

export default feedsReducer;
