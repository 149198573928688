import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ILocation } from "store/states/campaigns.state";
import { LocationSelectorStyled, LocationTags, SearchResult } from "./styled";
import api from "services/api";
import Input from "components/Input";
import { debounce } from "constants/utility/etc";
import DropdownSelect from "components/DropdownSelect";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "store/store";
import { GeoTarget } from "store/states/targeting.state";

const mapStateToProps = ({ targeting }: RootState) => ({
  targeting,
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface ILocationSelectorProps extends PropsFromRedux {
  locations: Array<ILocation>;
  handleUpdateLocation: (locations: Array<ILocation>) => void;
}

function simulateMouseClick(targetNode: any) {
  function triggerMouseEvent(targetNode: any, eventType: any) {
    const clickEvent = new CustomEvent(eventType);
    targetNode.dispatchEvent(clickEvent);
  }
  [
    // "mouseover",
    "mousedown",
    "mouseup",
    //  "click"
  ].forEach((eventType) => {
    targetNode && triggerMouseEvent(targetNode, eventType);
  });
}

const LocationSelector: React.FunctionComponent<ILocationSelectorProps> = ({
  targeting,
  locations,
  handleUpdateLocation,
}) => {
  const [locationSuggest, setLocationSuggest] = React.useState<string>("");
  const [addedLocations, setAddedLocations] = React.useState<Array<ILocation>>(locations);
  const [isShowCityList, setShowCityList] = React.useState(false);
  const countrySelectorRef = React.useRef(null) as any;

  const [citiesResults, setCities] = React.useState<
    Array<{
      type: string;
      name: string;
      fullName: string;
      cityId?: number;
      regionId?: number;
      countryId: number;
    }>
  >([]);
  const [loading, setLoading] = React.useState(false);

  const [searchTerms, setSearchTerms] = React.useState("");

  const handleSearch = React.useCallback(
    debounce(async (searchTerms: string) => {
      setLoading(true);

      try {
        const { data } = await api.targeting.getTargetingCities(searchTerms);
        setCities(
          data.filter((city: any) => {
            return city.cityId;
          })
        );
        setShowCityList(true);
      } catch (err) {
        setCities([]);
      }
      setLoading(false);
    }, 1500),
    []
  );

  const onLocationRemoveButtonClick = (location: ILocation) => {
    const locations = addedLocations.filter((element) => element !== location);
    setAddedLocations(locations);

    handleUpdateLocation(locations);
  };

  return (
    <LocationSelectorStyled>
      <div style={{ display: "flex", gap: "10px" }}>
        <DropdownSelect
          border={false}
          searchFn={({ props, state }: any) => {
            return props.options.filter((result: GeoTarget) =>
              result.name.toLowerCase().startsWith(state.search.toLowerCase())
            );
          }}
          placeholder="Country"
          options={targeting.data.countries}
          searchBy="name"
          labelField="name"
          valueField="id"
          ref={countrySelectorRef}
          onChange={(values: Array<GeoTarget>) => {
            if (!values.length) {
              return;
            }
            const existingLocation = addedLocations.find(
              (location) => Number(location.placeId) === values[0].id
            );

            if (!existingLocation && values[0].id) {
              const updatedLocations = [
                ...addedLocations,
                {
                  address: values[0].name,
                  placeId: values[0].id.toString(),
                  type: "country",
                },
              ];

              setAddedLocations(updatedLocations);

              handleUpdateLocation(updatedLocations);
            }

            countrySelectorRef.current.state.searchResults = targeting.data.countries;
          }}
        />

        <Input
          dark
          placeholder="Search City"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setSearchTerms(e.target.value);
            e.target.value && handleSearch(e.target.value);
          }}
          value={searchTerms}
          loading={loading}
        />
      </div>

      {isShowCityList && !loading && citiesResults.length ? (
        <SearchResult>
          {citiesResults.map((city) => {
            return (
              <li
                key={city.cityId}
                onClick={async () => {
                  setShowCityList(false);

                  const existingLocation = addedLocations.find(
                    (location) => Number(location.placeId) === city.cityId
                  );

                  if (!existingLocation && city.cityId) {
                    const updatedLocations = [
                      ...addedLocations,
                      {
                        address: city.fullName,
                        placeId: city.cityId.toString(),
                        type: "city",
                      },
                    ];

                    setAddedLocations(updatedLocations);

                    handleUpdateLocation(updatedLocations);

                    setCities([]);
                    setSearchTerms("");
                  }
                }}
              >
                {city.fullName}
              </li>
            );
          })}
        </SearchResult>
      ) : null}

      <div className="selected-locations">
        <LocationTags data-testid="location-tag">
          {addedLocations.map((location, index) => (
            <div className="added-location" key={location.placeId}>
              <span>{location.address}</span>
              <FontAwesomeIcon
                data-testid="remove-location"
                className="tag-remove"
                icon="times"
                onClick={() => onLocationRemoveButtonClick(location)}
              />
            </div>
          ))}
        </LocationTags>
      </div>
    </LocationSelectorStyled>
  );
};

export default connector(LocationSelector);
