import React from "react";

const CompareIcon = ({ className }: { className?: string }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.58 17.81">
      <defs>
        <style>
          {".icon-svg{fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;}"}
        </style>
      </defs>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_3" data-name="Layer 3">
          <polyline className="icon-svg" points="18.08 10.73 0.5 10.73 9.29 17.31" />
          <polyline className="icon-svg" points="0.5 7.33 18.08 7.33 9.29 0.5" />
        </g>
      </g>
    </svg>
  );
};

export default CompareIcon;
