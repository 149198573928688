import { FormikProps } from "formik";
import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { axiosForReverseAds } from "../../axiosConfig";
import ICheckCouponResponse from "../../models/check-coupon-response.interface";
import IDeleteCampaignsResponse from "../../models/delete-campaigns-response.interface";
import { IPromo } from "components/PaymentSummary";
import { ICampaign, ICampaignListData, ICampaignReportData } from "../states/campaigns.state";
import { RootState } from "../store";
import { handlingError, onSuccess } from "../../constants/utility/error";
import { ICampaignFilters, IErrorMessage } from "services/types";

import * as types from "store/action-types/campaigns.types";
import { IPaymentForm } from "constants/utility/campaign";

export const ADD_NEW_CAMPAIGN = "ADD_NEW_CAMPAIGN";

export const DELETE_CAMPAIGNS = "DELETE_CAMPAIGNS";
export const DELETE_CAMPAIGNS_SUCCESS = "DELETE_CAMPAIGNS_SUCCESS";
export const DELETE_CAMPAIGNS_FAILURE = "DELETE_CAMPAIGNS_FAILURE";

export const TOGGLE_CAMPAIGN_ACTIVE_STATUS = "TOGGLE_CAMPAIGN_ACTIVE_STATUS";
export const TOGGLE_CAMPAIGN_ACTIVE_STATUS_SUCCESS = "TOGGLE_CAMPAIGN_ACTIVE_STATUS_SUCCESS";
export const TOGGLE_CAMPAIGN_ACTIVE_STATUS_FAILURE = "TOGGLE_CAMPAIGN_ACTIVE_STATUS_FAILURE";

export const UPDATE_CAMPAIGN_ADFORM_ID = "UPDATE_CAMPAIGN_ADFORM_ID";
export const UPDATE_CAMPAIGN_ADFORM_ID_SUCCESS = "UPDATE_CAMPAIGN_ADFORM_ID_SUCCESS";
export const UPDATE_CAMPAIGN_ADFORM_ID_FAILURE = "UPDATE_CAMPAIGN_ADFORM_ID_FAILURE";

export const LOAD_CAMPAIGN_REPORTS = "LOAD_CAMPAIGN_REPORTS";
export const SET_CAMPAIGN_REPORTS = "SET_CAMPAIGN_REPORTS";
export const CHANGE_CAMPAIGN_STATUS = "CHANGE_CAMPAIGN_STATUS";

export const SET_CAMPAIGN_ID = "SET_CAMPAIGN_ID";

export const APPLY_COUPON = "APPLY_COUPON";
export const APPLY_COUPON_SUCCESS = "APPLY_COUPON_SUCCESS";
export const APPLY_COUPON_FAILURE = "APPLY_COUPON_FAILURE";

// get paid campaigns

export function getCampaigns(filters: ICampaignFilters): types.GetCampaignsAction {
  return {
    type: types.GET_CAMPAIGNS,
    filters,
  };
}

export function getCampaignsRequest(payload: ICampaignFilters): types.GetCampaignsRequestAction {
  return {
    type: types.GET_CAMPAIGNS_REQUEST,
    payload,
  };
}

export function getCampaignsSuccess(
  payload: ICampaignListData[],
  total: number
): types.GetCampaignsSuccessAction {
  return {
    type: types.GET_CAMPAIGNS_SUCCESS,
    payload,
    total,
  };
}

export function getCampaignsFailure(error: IErrorMessage): types.GetCampaignsFailureAction {
  return {
    type: types.GET_CAMPAIGNS_FAILURE,
    error,
  };
}

// set campaign id

export const setCampaignId = (campaignId: string | null) => {
  return {
    type: SET_CAMPAIGN_ID,
    payload: {
      campaignId,
    },
  };
};

export const addCampaign = (campaign: ICampaign) => {
  return {
    type: ADD_NEW_CAMPAIGN,
    payload: {
      campaign,
    },
  };
};

export const setCampaignReports = (reports: Array<ICampaignReportData>) => {
  return {
    type: SET_CAMPAIGN_REPORTS,
    payload: {
      reports: reports,
    },
  };
};

export const deleteHomeCampaign = (
  campaignIds: Array<string>
): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async (dispatch) => {
    dispatch({
      type: DELETE_CAMPAIGNS,
    });

    try {
      await axiosForReverseAds.post<IDeleteCampaignsResponse>("campaign/delete", { campaignIds });

      dispatch({
        type: DELETE_CAMPAIGNS_SUCCESS,
        payload: {
          campaignIds: campaignIds,
        },
      });

      dispatch(
        getCampaigns({
          page: 1,
        })
      );

      onSuccess("Delete draft campaign");
    } catch (error: any) {
      dispatch({
        type: DELETE_CAMPAIGNS_FAILURE,
      });
      handlingError(error);
    }
  };
};

export const deleteCurrentUserCampaigns = (
  campaignIds: Array<string>
): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async (dispatch, getState) => {
    dispatch({
      type: DELETE_CAMPAIGNS,
    });

    const { campaigns } = getState();

    try {
      await axiosForReverseAds.post<IDeleteCampaignsResponse>("campaign/delete", { campaignIds });

      dispatch({
        type: DELETE_CAMPAIGNS_SUCCESS,
        payload: {
          campaignIds: campaignIds,
        },
      });

      dispatch(
        getCampaigns({
          page: 1,
          status: campaigns.data[0].status,
        })
      );

      onSuccess(`Delete ${campaignIds.length} campaigns`);
    } catch (error: any) {
      dispatch({
        type: DELETE_CAMPAIGNS_FAILURE,
      });
      handlingError(error);
    }
  };
};

export const validateDiscountCode = (
  code: string,
  applyPromo: (data: IPromo | null) => void,
  setFieldError: FormikProps<IPaymentForm>["setFieldError"],
  setFieldValue: FormikProps<IPaymentForm>["setFieldValue"]
): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async (dispatch) => {
    dispatch({
      type: APPLY_COUPON,
    });

    try {
      const {
        data: { data, state },
      } = await axiosForReverseAds.post<ICheckCouponResponse>("campaign/discount/checkCoupon", {
        code,
        campaignId: null,
      });

      if (state === "success") {
        dispatch({
          type: APPLY_COUPON_SUCCESS,
        });

        setFieldValue("discountCode", data.code);
        applyPromo(data);
      }
    } catch (err: any) {
      dispatch({
        type: APPLY_COUPON_FAILURE,
      });

      setFieldError("discountCode", err.response.data.message);
    }
  };
};
