import { CATEGORY_KEY_VALUES_ARRAY } from "constants/category";
import { IErrorMessage } from "../../services/types";

interface CategoryState {
  loading: boolean;
  error: IErrorMessage | null;
  data: CATEGORY_KEY_VALUES_ARRAY;
  parent: { [key: string]: string };
}

const categoryState = {
  loading: false,
  error: null,
  data: {},
  parent: {},
} as CategoryState;

export default categoryState;
