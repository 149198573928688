import React from "react";
import { TermsStyled } from "./styled";

const Terms = () => {
  return (
    <TermsStyled>
      <b>TERMS / CONDITIONS</b>
      <div className="terms-wrapper">
        <div className="content">
          <p>
            When used herein, the term Vatic AI (Thailand) Co. Ltd. dba Vatic AI and the term
            “Quotations” means the total price quoted to a customer (“Customer”) by Vatic AI to
            utilize action-producing advertisements, or surveys, through the use of coupons,
            vouchers, tickets, promotions and campaigns, supplied by Vatic AI (Vatic AI Ads”), to
            generate leads and awareness and to build loyalty programs and relationship management
            to support the Customer’s inbound and outbound services (Vatic AI Services”).
          </p>
          <p className="heading-terms">
            Vatic AI Services are subject to the following Advertising Terms and Conditions as the
            same may from time to time be amended, modified or supplemented by Vatic AI:
          </p>

          <p>
            1. All quotations offered through the self-service portal are offered for immediate
            acceptance and are firm for one (1) business day. Vatic AI reserves the right to revise
            any quotation offered through the self-service portal after one (1) business day. All
            quotations offered by a sales representative of Vatic AI are offered for immediate
            acceptance and are firm for three (3) business days. Vatic AI reserves the right to
            revise a quotation offered by a sales representative of Vatic AI after three (3)
            business days. Quotations are based on specifications submitted by the Customer. If the
            Customer requests any changes to those specifications, the Quotation may be revised and
            the Customer will be deemed to have accepted such price revisions unless the Customer
            notifies Vatic AI within five (5) days of receiving such revised Quotation.
          </p>

          <p>
            2. All sums and/or calculations referenced in the Quotation are in USD. In the event
            that any sum needs to be converted into another currency, Vatic AI shall use the
            exchange rate published by a reputable third party (such as XE.com).
          </p>

          <p>
            3. One hundred percent (100%) of a Quotation must be paid to Vatic AI by the Customer
            within five (5) days of any cancellation of the Quotation by the Customer and/or its
            agent signing on behalf of the Customer.
          </p>

          <p>
            4. Materials provided by the Customer for inclusion in any Vatic AI Ads shall be
            delivered to Vatic AI on or before the due date specified in the quotation, failing
            which they will not be included in such Vatic AI Ads.
          </p>

          <p>
            5. Vatic AI Ads must be approved in writing by Vatic AI and Vatic AI reserves the right
            to alter or refuse any, or part thereof, Vatic AI Ad.
          </p>

          <p>
            6. Proofs submitted to the Customer should be reviewed and returned electronically to
            Vatic AI marked “O.K.” or “O.K. with corrections” and electronically signed with the
            initials of a person duly authorized to approve such proofs. If a revised proof is
            desired, such a request must be made when the proof is returned. Retouching of Vatic AI
            Ads is not included in quotations and is charged on an actual time basis. Vatic AI is
            not responsible for errors in any proof supplied and/or approved by the Customer.
          </p>
          <p>
            7. Vatic AI reserves the right to review the Customer’s ad content to ensure that the
            Vatic AI Ads are approved by both Vatic AI and by its partner networks. Such creative
            requirements and policies are important for any campaign. The ad content guidelines
            outlined below apply to the Customer’s ad and website images.
          </p>

          <p>
            i. Adult Content: Vatic AI may approve Vatic AI Ads that depict nudity, graphic
            language, or adult concepts, subject to the terms and conditions of its partner networks
            and advertising exchanges.
          </p>

          <p>
            ii. Alcohol: Vatic AI may approve Vatic AI Ads that depict alcohol or alcohol
            consumption, subject to the terms and conditions of its partner networks and advertising
            exchanges.
          </p>

          <p>
            iii. Cannabis: Vatic AI may approve Vatic AI Ads that depict cannabis or cannabis use or
            consumption, subject to the terms and conditions of its partner networks and advertising
            exchanges.
          </p>

          <p>
            iv. Body images: In line with Vatic AI policy on Adult Content, sexualized images will
            not be approved. Avoid nudity (implied or explicit), unnecessary skin exposure or
            cleavage, or other tactics designed to solicit a sexual response. Vatic AI Ads will be
            subject to the terms and conditions of its partner networks and advertising exchanges.
            For example, Facebook doesn’t permit the use of ad images that imply ideal or
            undesirable body image, including “before and after” photos, images that imply unlikely
            results, or images that draw unnecessary attention to body parts.
          </p>

          <p>
            v. Brand Notification: The Customer’s visitor should be able to have a clear
            understanding of where they'll be going when they click the Vatic AI Ad. Vatic AI
            requires that the brand shown in any Vatic AI Ads matches the brand of the destination
            landing page.
          </p>

          <p>
            vi. Shock Value: Vatic AI will not approve any Vatic AI Ads that use images that are
            visually disturbing, gory, or designed to shock or scare.
          </p>

          <p>
            vii. Spam Tactics: Tactics to mislead a visitor are strictly prohibited. Vatic AI will
            not approve Vatic AI Ads that: Imitate computer functions such as system messages,
            progress bars, or virus warnings; Mimic the function of other types of media, such as
            appearing to be an embedded video; or initiate prompts to download files or software
            after the click.
          </p>

          <p>
            viii. Strobing and Flashing: Strobing, flashing backgrounds, or otherwise distracting
            Vatic AI Ads are not permitted. Do not attempt to overtly distract the user from page
            content.
          </p>

          <p>
            ix. Toubon Law (France): France’s 1994 Toubon Law requires that ads in France must be
            only in French, or include a French translation. This applies to ads shown on any French
            IP address (not just on or from domains). To serve Vatic AI Ads in France, please make
            sure that the ad content is in French language.
          </p>

          <p>
            x. Personally Targeted Messaging: Due to the highly targeted nature of retargeting,
            Vatic AI is especially sensitive to user privacy. This is something to be particularly
            careful with if your website focuses on products or services for a specific audience.
            Even if your website meets Vatic AI content requirements, targeted messaging that
            implies knowledge of an individual’s personal circumstances will not be approved by
            Vatic AI.
          </p>

          <p>
            xi. Violence and Weapons: Vatic AI will not approve Vatic AI Ads that depict violence,
            including pictures of guns or other firearms.
          </p>

          <p>
            8. It is understood and agreed that, unless otherwise specified, any customer lists and
            any other data furnished to the Customer by Vatic AI (“Confidential Information”) are
            furnished for one time use only and shall remain the proprietary and confidential
            property of Vatic AI. Any systems or programs developed or supplied by Vatic AI in the
            provision of Vatic AI Services shall also remain the property of Vatic AI and be
            considered Confidential Information. Aside from the permitted uses under this Agreement,
            the Customer agrees to protect any Confidential Information, keep it confidential, and
            not disclose or use such Confidential Information except for the purposes contemplated
            hereby or to disclose to third parties the terms set forth in this Agreement.
          </p>

          <p>
            9. Sketches, dummies or copies submitted by Vatic AI remain its property and no use of
            the same shall be made or any idea obtained there from shall be used without the prior
            written consent of Vatic AI.
          </p>

          <p>
            10. THE WARRANTIES IN THIS AGREEMENT ARE EXCLUSIVE AND MADE EXPRESSLY IN LIEU OF ALL
            OTHER WARRANTIES, REPRESENTATIONS AND REMEDIES: (1) WRITTEN OR ORAL; (2) EXPRESS OR
            IMPLIED; (3) AT LAW, IN EQUITY OR UNDER CONTRACT, INCLUDING WITHOUT LIMITATION ANY
            IMPLIED WARRANTY OF MERCHANTABILITY, NON-INFRINGEMENT, FITNESS FOR A PARTICULAR PURPOSE,
            OR OTHERWISE; AND (4) NOTWITHSTANDING ANY COURSE OF DEALING BETWEEN THE PARTIES OR
            CUSTOM AND USAGE IN THE TRADE TO THE CONTRARY.
          </p>

          <p>
            11. The nature of the business of Vatic AI is such that it cannot guarantee or be
            financially responsible for the consequences of any delay or failure to provide any
            Vatic AI Services by a stipulated date. In addition, the liability of Vatic AI for any
            other type of loss, damage, or injury of any kind whatsoever incurred or suffered by the
            Customer or by any other person shall not exceed the direct fees paid by Customer to
            Vatic AI for direct services rendered (specifically, excluding any amounts of money paid
            to Vatic AI for services to be rendered by other third parties) for such Vatic AI
            Services. In no event (including but not limited to fundamental breach of contract,
            breach of a fundamental term of the contract and the negligence or gross negligence of
            Vatic AI, its employees or agents, and whether arising from miss delivery, failure to
            deliver or delay in delivery) shall Vatic AI be liable, whether through the suit of
            Customer or any third party, and whether in contract, tort or otherwise, for any
            indirect, incidental, consequential, punitive or special damages or for damages for loss
            of earning of profit
          </p>

          <p>
            12. All Quotations for Vatic AI Services are exclusive of any and all applicable taxes,
            including federal tax, value added tax or any other sales tax, which shall be added to
            the Quotation or otherwise included in any relevant calculation, and are to be borne by
            the Customer, and where any withholding tax or similar deduction is required to be made,
            the amount of the Invoice shall be paid net of that deduction.
          </p>

          <p>
            13. Vatic AI, or its sales representatives or designates, as the case may be, will
            invoice the Customer for the Quotation (the “Invoice”) in advance of each applicable
            month in which Vatic AI Services are to be rendered (the “Campaign”). Payment to Vatic
            AI of any Invoice must be made at least seven (7) business days prior to the effective
            date of the Customer’s Campaign (the “Campaign Launch”). In the event that payment of an
            Invoice is not made in advance of seven (7) business days prior to the respective
            Campaign Launch, Vatic AI reserves the right, but not the obligation, to delay, until
            the time that such payment is made, or otherwise terminate the Campaign Launch. In
            addition, the Customer also agrees to pay all costs of collection Vatic AI incurs,
            including legal fees and expenses, should a default in payment to Vatic AI occurs.
          </p>

          <p>
            14. If the creditworthiness of the Customer at any time becomes unsatisfactory to Vatic
            AI, Vatic AI reserves the right to require payment for any Vatic AI Services in advance,
            or provision of satisfactory security for payment.
          </p>

          <p>
            15. Subject to the credit approval, Vatic AI may require prepayment for any Vatic AI
            Services rendered to a new Customer.
          </p>
          <p>
            16. Vatic AI shall not be liable for any failure to provide Vatic AI Services as a
            result of strike, fire, accident, civil disturbance, terrorist acts, unusual market
            conditions, media delay or interruption, or any other cause beyond the control of Vatic
            AI.
          </p>

          <p>
            17. Customer shall indemnify and save harmless Vatic AI, its employees, agents and
            affiliates, from and against all claims, losses, costs and damages (including indirect,
            special and consequential damages and/or for damages for loss of earning of profits and
            including legal fees of a solicitor and his/her own Customer basis and interest)
            incurred or suffered by Vatic AI, its employees, agents and affiliates, in connection
            with or arising out of:
          </p>

          <p>
            i. any infringement of alleged infringement by the Customer of any copyright, trademark,
            trade name or other proprietary right;
          </p>

          <p>
            ii. any claim that the distribution of materials provided by the Customer constitutes an
            unlawful violation and/or violation of the Competition Act or any other law (including,
            without limitation, misleading advertising or promotion of a product by means of an
            unlawful or allegedly unlawful contest, lottery or game of chance or skill); and
          </p>

          <p>
            iii. any other claims made against the Customer or Vatic AI in respect of the Customer’s
            advertisement.
          </p>

          <p>
            Upon Vatic AI’ request, the Customer agrees, at its own cost and expense, to defend any
            such actions, claims or demands on behalf of Vatic AI using legal counsel reasonably
            acceptable to Vatic AI.
          </p>

          <p>
            18. These terms and conditions represent the entire agreement of the parties and no
            terms, representations or conditions in any way modifying or changing the provisions
            stated herein shall be binding upon Vatic AI unless made in writing and signed and
            approved by an authorized representative of Vatic AI.
          </p>

          <p>
            19. This Agreement is the complete and exclusive statement of the mutual promises and
            consideration of the parties, and supersedes and cancels any previous written and oral
            agreements and communications relating to the subject matter of this Agreement and/or
            any proposed terms and conditions stipulated in the Customer’s purchase order, request
            for quotation, or similar form.
          </p>

          <p>
            20. This Agreement shall be governed by the laws of the Province of Ontario and the laws
            of Canada applicable therein. Each party hereto irrevocably waives any objection on the
            grounds of venue, forum non-convenience or any similar grounds and irrevocably consents
            to service of process by mail or in any other manner permitted by applicable law and
            consents to the jurisdiction of the courts of the Province of Ontario.
          </p>

          <p>
            21. The Customer may not, without Vatic AI’ prior written consent, assign, pledge, grant
            a security interest in or otherwise transfer this Agreement or any of its right, or
            obligations under this Agreement, to any person.
          </p>

          <p>
            22. Except as expressly provided in this Agreement, no amendment or waiver of this
            Agreement shall be binding unless executed in writing by the party to be bound thereby.
          </p>

          <p>
            23. If any provision of this Agreement shall for any reason be held illegal or
            unenforceable, such provision shall be deemed separable from the remaining provisions of
            this Agreement and shall in no way affect or impair the validity or enforceability of
            the remaining provisions of this Agreement.
          </p>

          <p>
            24. Vatic AI may terminate this Agreement by notice in writing to the Customer if any of
            the following occur: (a) the Customer breaches any terms of this Agreement; (b) the
            Customer makes any attempt to assign or otherwise transfer any of its rights under this
            Agreement; or (c) the Customer is dissolved, becomes insolvent or is unable to pay its
            debts or fails or admits in writing its inability generally to pay its debts as they
            become due, make a general assignment for the benefit of its creditors, institutes or
            has instituted against it a proceeding seeking a judgment of insolvency or bankruptcy or
            has a secured party take possession of all or substantially all of its assets or has a
            distress, execution or other legal process levied, enforced or sued on or against all
            substantially all of its assets.
          </p>

          <p>
            25. Upon termination of this Agreement, the Customer’s obligations under sections 2, 7,
            8, 9, 10, 11, 12, 15, 16, 17, 18, 19, 20 and 21 shall survive the termination and the
            Customer’s rights this Agreement shall immediately cease. Subject to section 13, above,
            termination of this Agreement shall not limit either party from pursuing any other
            remedies available to it, including injunctive relief, nor shall such termination
            relieve the Customer from its obligations to pay fees accrued prior to the termination.
          </p>

          <p>Click Fraud Statement</p>

          <p>
            At Vatic AI, it is our promise to always do right by our clients. We take all possible
            measures to ensure that we prevent as much invalid or fraudulent traffic as possible,
            with over 99% of invalid traffic being filtered out by our proactive measures. We have
            systems in place to filter and scrub IP data that is deemed to be invalid, our methods
            are proactively blocking invalid clicks and preventing the traffic from reaching our
            clients websites. Our automated detection algorithm looks for patterns will filtering
            through IPs, click redundancies and timestamp comparisons in order to block click fraud.
            We have a dedicated team of Safety and Trust experts analyzing new threats and
            constantly improving our systems filtering capabilities. We take all reports of invalid
            clicks very seriously and take time to investigate each case to determine click
            validity. Should fraudulent active be found to have occurred we will credit the
            advertiser the clicks back.
          </p>

          <p>Refund Policy</p>
          <p>
            All services that Vatic AI offers are considered to be provided on a strictly prepaid
            basis. Refund Policy: All services rendered by the Vatic AI are provided on a
            non-refundable basis.
          </p>
        </div>
        <div className="graphic-wrapper">
          <div className="graphic" />
        </div>
      </div>
    </TermsStyled>
  );
};

export default Terms;
