import styled, { css } from "styled-components/macro";
import { Modal } from "react-bootstrap";

const empty = require("assets/images/empty-graphics/suggested-graphic.svg").default;

export const SuggestedPopupModal = styled(Modal)<{ $blur?: boolean }>`
  /* background-color: transparent;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  border-radius: 8px; */
  ${({ $blur }) =>
    $blur &&
    css`
      .modal-content {
        &:after {
          position: absolute;
          content: "";
          width: 100%;
          height: 100%;
          background: #000000;
          border-radius: 10px;
          top: 0;
          left: 0;
          opacity: 0.5;
          z-index: 1;
        }
      }
    `}

  .clear-all {
    padding: 0px 10px;
    background-color: #ffffff;
    border-radius: 15px;
    font-size: 10px;
    display: flex;
    align-items: center;
    font-weight: bold;
    cursor: pointer;
  }

  .visual-reports {
    .compare-info {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;

      .badge-group {
        display: flex;
        gap: 10px;
      }
    }
  }

  .loading-wrapper {
    display: flex;
    align-items: center;
    min-height: 500px;
  }

  .modal-content {
    /* border-radius: 15px; */
    background-color: ${({ theme }) => theme.modalBackground};
    border-radius: 10px;
    min-height: 500px;
    padding: 0 20px;

    .visual-result {
      margin-top: 20px;

      .bottom-bar-actions {
        margin-top: 30px;
        margin-left: 25px;
      }
    }
  }

  .modal-header {
    padding: 30px;
    padding-bottom: 0;
    border-bottom: none;

    /* .modal-title {
      background-color: rgba(65, 96, 234, 0.15);
      border-radius: 8px;
      font-size: 0.88em;
      padding: 8px 15px;
      color: ${({ theme }) => theme.color.primary};
      font-weight: 300 !important;
      text-transform: capitalize;
    } */

    .modal-title {
      color: ${({ theme }) => theme.contrast};
      font-size: 18px;

      .keyword-tool-title {
        display: flex;

        .tool-title {
          font-weight: bold;
          padding: 0px 20px 0 0px;
          border-right: 1px solid ${({ theme }) => theme.contrast};
          margin-right: 20px;
        }

        .tool-description {
          font-size: 12px;
          white-space: pre;
        }
      }
    }
  }

  .modal-body {
    padding: 20px 30px;

    .suggested-keywords-wrapper {
      .table-wrapper {
        margin: 20px 0px 10px 0;
        max-height: 430px;
        overflow-y: auto;
      }

      .suggested-bottom-bar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-left: 25px;
      }
    }

    .empty {
      background-repeat: no-repeat;
      background-image: url(${empty});
      background-size: 500px;
      width: auto;
      height: 500px;
      background-position: center;
    }

    .search-bar {
      display: flex;
      gap: 20px;

      .keyword-input {
        width: 100%;
        position: relative;

        > svg,
        path {
          position: absolute;
          fill: ${({ theme }) => theme.dark};
          top: 15px;
          right: 15px;
        }
      }
    }
  }

  /* .modalActionButtons {
    border-top: 0;
    padding: 20px 0 10px;

    .keywordListPopupClose {
      color: #525252;
      font-size: 1em;
      font-weight: 400;
      text-align: center;
      border-radius: 4px;
      background-color: #dcdcdc;
      border: none;
      margin-right: 10px;
      width: 140px;
      height: 45px;
      &:hover {
        opacity: 0.65;
      }
    }

    .keywordListPopupClose:active {
      background-color: #dcdcdc;
      color: #525252;
    }

    .save-button,
    .close-button {
      margin-left: 20px;
      min-width: 140px;
    }
  } */
`;
