import React from "react";
import {
  ResponsiveContainer,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";
import styled from "styled-components/macro";
import {
  COMPARE_SELECT,
  COMPARE_VALUE,
} from "../../modules/Client/pages/CampaignReport/Performance/constants";
import { ICampaignReportData, IDailyReport } from "store/states/campaigns.state";
import ChartTooltip from "../ChartTooltip";
import { graphFormatter } from "constants/utility/chart";
import { displayDate } from "constants/utility/date";

const purple = "rgb(117,0,211)";
const pink = "rgb(255,0,152)";
interface IDynamicChartProps {
  active: COMPARE_SELECT;
  compare: COMPARE_SELECT;
  dateList: Array<ICampaignReportData>;
}

const DynamicChartStyled = styled.div`
  background: ${({ theme }) => theme.modalBackground};
  padding: 20px 10px;

  .recharts-text {
    fill: ${({ theme }) => theme.contrast};
    font-size: 12px;
  }

  .recharts-text[orientation="left"] > tspan {
    stroke: white;
    stroke-width: 0;
    fill: rgb(117, 0, 211);
  }

  .recharts-text[orientation="right"] > tspan {
    stroke: white;
    stroke-width: 0;
    fill: rgb(255, 0, 152);
  }
`;

const DynamicChart: React.FunctionComponent<IDynamicChartProps> = ({
  active,
  compare,
  dateList,
}) => {
  return (
    <DynamicChartStyled>
      <ResponsiveContainer width="100%" height={200}>
        <AreaChart data={dateList} margin={{ top: 10, right: 30, left: 0, bottom: 10 }}>
          <defs>
            <linearGradient id="0" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor={purple} stopOpacity={0.5} />
              <stop offset="50%" stopColor={purple} stopOpacity={0.2} />
            </linearGradient>
          </defs>

          <defs>
            <linearGradient id="1" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor={pink} stopOpacity={0.5} />
              <stop offset="50%" stopColor={pink} stopOpacity={0.2} />
            </linearGradient>
          </defs>

          <XAxis
            style={{ transform: "translate(0, 20px)" }}
            axisLine={false}
            dataKey="date"
            tickFormatter={(tickItem) => tickItem && displayDate(new Date(tickItem))}
          />

          <YAxis
            tickFormatter={graphFormatter}
            // unit={active === COMPARE_VALUE.CTR || active === COMPARE_VALUE.CONVERSIONS ? "%" : ""}
            unit={compare === COMPARE_VALUE.CTR ? "%" : ""}
            yAxisId="left"
            axisLine={false}
          />
          <YAxis
            tickFormatter={graphFormatter}
            // unit={compare === COMPARE_VALUE.CTR || compare === COMPARE_VALUE.CONVERSIONS ? "%" : ""}
            unit={compare === COMPARE_VALUE.CTR ? "%" : ""}
            yAxisId="right"
            axisLine={false}
            orientation="right"
          />

          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip content={(tooltipProps) => <ChartTooltip {...tooltipProps} />} />

          <Area
            yAxisId="left"
            type="monotone"
            dataKey={active}
            stroke={purple}
            fillOpacity={1}
            fill={`url(#${0})`}
          />
          <Area
            type="monotone"
            dataKey={compare}
            stroke={pink}
            fillOpacity={1}
            fill={`url(#${1})`}
            yAxisId="right"
          />
        </AreaChart>
      </ResponsiveContainer>
    </DynamicChartStyled>
  );
};

export default DynamicChart;
