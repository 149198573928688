// Payments

import { IErrorMessage, IPaymentsFilters } from "../../services/types";
import { IPaymentDetail } from "../states/payments.state";

export const GET_PAYMENTS = "admin-actions/GET_PAYMENTS";
export interface GetPaymentsAction {
  type: typeof GET_PAYMENTS_REQUEST;
  payload: IPaymentsFilters;
}

export const GET_PAYMENTS_REQUEST = "admin-actions/GET_PAYMENTS_REQUEST";
export interface GetPaymentsRequestAction {
  type: typeof GET_PAYMENTS_REQUEST;
}
export const GET_PAYMENTS_SUCCESS = "admin-actions/GET_PAYMENTS_SUCCESS";
export interface GetPaymentsSuccessAction {
  type: typeof GET_PAYMENTS_SUCCESS;
  payments: IPaymentDetail[];
  total: number;
}

export const GET_PAYMENTS_FAILURE = "admin-actions/GET_PAYMENTS_FAILURE";
export interface GetPaymentsFailureAction {
  type: typeof GET_PAYMENTS_FAILURE;
  error: IErrorMessage;
}

export type PaymentsAction =
  | GetPaymentsRequestAction
  | GetPaymentsSuccessAction
  | GetPaymentsFailureAction;
