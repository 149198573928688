import React from "react";

const ArrowRightIcon = ({ className }: { className?: string }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7.58 12.75">
      <defs>
        <style>
          {".icon-svg{fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;}"}
        </style>
      </defs>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_3" data-name="Layer 3">
          <polyline className="icon-svg" points="0.5 12.25 7.08 6.37 0.5 0.5" />
        </g>
      </g>
    </svg>
  );
};

export default ArrowRightIcon;
