import styled, { css } from "styled-components/macro";

export const AccordionStyled = styled.div<{ noBorder?: boolean }>`
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid ${({ noBorder, theme }) => (noBorder ? "transparent" : theme.dark)};
`;

export const AccordionContent = styled.div<{
  contentHeight: number;
  active: boolean;
  autoHide?: boolean;
}>`
  @keyframes delay-overflow {
    from {
      overflow: hidden;
    }
    to {
      overflow: visible;
    }
  }

  ${({ contentHeight, active, autoHide }) => {
    if (active) {
      return css`
        animation: 3s delay-overflow;
        transition: ${autoHide ? "none" : `3s ease-in`};
        max-height: 9999px;
      `;
    } else {
      return css`
        overflow: hidden;
        transition: ${autoHide ? "none" : `${Math.ceil(1 / (1000 / contentHeight))}s ease-out`};
        max-height: ${contentHeight || 0}px;
      `;
    }
  }}
`;

export const AccordionToggle = styled.div<{
  active?: boolean;
}>`
  font-size: 14px;
  margin: 0;

  cursor: pointer;
  position: relative;

  .trigger-element {
    margin-left: 20px;
  }

  &:after {
    content: "";
    /* width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 5px 10px 5px 10px;
    border-color: transparent transparent transparent rgb(120, 120, 120);
    display: inline-block;
    vertical-align: middle;
    left: 0;
    top: 7px;
    position: absolute;
    bottom: 0;
    margin-bottom: 10px; */

    transition: 0.3s;
    left: 0;
    top: 7px;
    position: absolute;
    border: solid ${({ theme }) => theme.text};
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(-45deg);
    ${({ active }) =>
      active &&
      css`
        transform: rotate(45deg);
        left: 2px;
        top: 5px;
      `};
  }
`;
