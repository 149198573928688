import React from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { connect, ConnectedProps, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { closeSidebar, openSidebar } from "store/actions/sidebar.actions";
import { logoutUser } from "store/actions/user.actions";
import { setCampaignId } from "store/actions/campaigns.actions";
import { SidebarWrapper, CustomMenu, CurrentUser, SidebarHeader } from "./styled";
import { RootState } from "store/store";
import { CAMPAIGN_NEW, INavigationGroup } from "modules/Client/routes";
import { resetCurrentFeed } from "store/actions/admin-news-feed.actions";
import Spinner from "components/Spinner";
import DropdownButton from "components/DropdownButton";
import { Dropdown, Image } from "react-bootstrap";
import UserNavigation from "layout/UserNavigation";
import {
  showAdvertiserForm,
  showBuildCampaign,
  showConfirmPopup,
} from "store/actions/popup.actions";
import ArrowLeftIcon from "components/Icons/ArrowLeftIcon";
import ArrowRightIcon from "components/Icons/ArrowRightIcon";
import RADLogo from "components/Icons/RADLogo";
import logo from "assets/images/VaticAI Logo_Horizontal - Black.png";

const mapStateToProps = ({ user, sidebar }: RootState) => ({
  user,
  sidebar,
});

interface ISidebarProps extends PropsFromRedux {
  links: INavigationGroup[];
}

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const Sidebar: React.FunctionComponent<ISidebarProps> = ({ sidebar, links, user }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [width, setWidth] = React.useState(window.innerWidth);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  const onLogoutButtonClick = async () => {
    dispatch(
      showConfirmPopup({
        title: "Log out?",
        body: "Do you want to log out?",
        confirmText: "Log out",
        action: () => dispatch(logoutUser(navigate)),
      })
    );
  };

  const onOverlayClick = async () => {
    dispatch(closeSidebar());
  };

  const onLinkClick = () => {
    dispatch(setCampaignId(null));
    dispatch(resetCurrentFeed());
    if (sidebar.close && width < 991) {
      dispatch(closeSidebar());
    }
  };

  const onMenuButtonClick = () => {
    if (sidebar.close) {
      dispatch(openSidebar());
    } else {
      dispatch(closeSidebar());
    }
  };

  React.useEffect(() => {
    if (width < 991) {
      dispatch(closeSidebar());
    } else {
      dispatch(openSidebar());
    }
  }, [width]);

  React.useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const params = useParams();

  return (
    <SidebarWrapper isClose={sidebar.close} id="sidebarWrapper">
      <div className="sidebar" data-testid="sidebar">
        <SidebarHeader onClick={onMenuButtonClick}>
          {/* <RADLogo className="rad-logo" /> */}
          <Image src={logo} className="rad-logo" alt="logo" />
          <span className={`icon-wrapper ${sidebar.close ? "flip" : ""}`}>
            <ArrowLeftIcon className="arrow-sidebar" />
          </span>
        </SidebarHeader>

        <div className="sidebar-item-wrapper">
          {location.pathname.includes("/user/") ? (
            <UserNavigation />
          ) : (
            links.map((route) =>
              route.children ? (
                <div key={route.text}>
                  <div className="sidebar-item">{route.text}</div>
                  {route.children.map(({ path, activeLinks, icon: Icon, text, action }) => {
                    return action ? (
                      <span
                        key={path}
                        onClick={() => {
                          if (path === CAMPAIGN_NEW) {
                            if (!user.website || !user.businessId) {
                              dispatch(showAdvertiserForm());
                            } else {
                              dispatch(showBuildCampaign());
                            }
                          } else {
                            dispatch(action());
                          }
                        }}
                        className={`route-item`}
                      >
                        {text}
                        {Icon ? (
                          <div className="nav-icon">
                            <Icon />
                          </div>
                        ) : null}
                      </span>
                    ) : path ? (
                      <Link
                        key={path}
                        onClick={onLinkClick}
                        to={path}
                        className={`${
                          activeLinks?.some((link) => location.pathname.includes(link)) ||
                          location.pathname.includes(path)
                            ? "active"
                            : ""
                        } route-item`}
                      >
                        {text}
                        {Icon ? (
                          <div className="nav-icon">
                            <Icon />
                          </div>
                        ) : null}
                      </Link>
                    ) : null;
                  })}
                </div>
              ) : route.path ? (
                <Link
                  key={route.path}
                  onClick={onLinkClick}
                  to={route.path || ""}
                  className={`${location.pathname.includes(route.path) ? "active" : ""}`}
                >
                  {route.text}
                  {route.icon ? (
                    <div className="nav-icon">{route.icon}</div>
                  ) : (
                    <img src={route.imagePath} alt="failedImage" className="img-icon" />
                  )}
                </Link>
              ) : null
            )
          )}
        </div>

        {/* {!location.pathname.includes("user") && ( */}
        {params["*"] !== "settings/" && (
          <div className="bottom-group">
            <CurrentUser isClose={sidebar.close}>
              {user.userName ? (
                <div className="thumbnail-actions">
                  <DropdownButton transparent>
                    <Dropdown.Toggle>
                      <div className="thumbnail">{user.userName.charAt(0).toUpperCase()}</div>
                      {!sidebar.close && <ArrowRightIcon />}
                    </Dropdown.Toggle>
                    <CustomMenu>
                      {user.role === "user" && (
                        <div className="user-dropdown">
                          <div className="thumbnail">{user.userName.charAt(0).toUpperCase()}</div>
                          <span className="user-name">{user.userName}</span>
                          <span onClick={() => navigate("/user/settings/")} className="edit-user">
                            Edit
                          </span>
                        </div>
                      )}

                      <Dropdown.Item disabled onClick={() => navigate("/user/notifications")}>
                        Notifications
                      </Dropdown.Item>
                      <Dropdown.Item disabled>Exports</Dropdown.Item>
                      <Dropdown.Item disabled onClick={() => navigate("/user/billing")}>
                        Billing
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => navigate("/user/privacy")}>
                        Privacy
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => navigate("/user/terms")}>Terms</Dropdown.Item>
                      <Dropdown.Item onClick={onLogoutButtonClick}>Logout</Dropdown.Item>
                    </CustomMenu>
                  </DropdownButton>
                </div>
              ) : (
                <Spinner />
              )}
            </CurrentUser>
          </div>
        )}
      </div>
      <div className="overlay" onClick={onOverlayClick} />
    </SidebarWrapper>
  );
};

export default connector(Sidebar);
