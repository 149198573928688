import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { closeSlideModal } from "store/actions/sidebar.actions";
import { RootState } from "store/store";
import Button from "../Button";
import { StyledSlideModal } from "./styled";

interface ISlideModalProps extends PropsFromRedux {
  content: React.ReactNode;
}

const SlideModal: React.FunctionComponent<ISlideModalProps> = ({ content, dispatch, sidebar }) => {
  function onModalClose(event: KeyboardEvent) {
    if (event.key === "Escape") {
      dispatch(closeSlideModal());
    }
  }

  React.useEffect(() => {
    document.addEventListener("keydown", onModalClose, false);
    return () => {
      document.removeEventListener("keydown", onModalClose, false);
    };
  }, []);

  return (
    <StyledSlideModal>
      <div className={`slide-modal ${sidebar.isOpenSlideModal ? "show" : ""}`}>
        <Button className="align-right" circle outline onClick={() => dispatch(closeSlideModal())}>
          ✕
        </Button>
        {sidebar.isOpenSlideModal && content}
      </div>
      <div className={`overlay ${sidebar.isOpenSlideModal ? "show" : ""}`} />
    </StyledSlideModal>
  );
};

const mapStateToProps = ({ sidebar }: RootState) => ({
  sidebar,
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(SlideModal);
