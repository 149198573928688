import { axiosForReverseAds } from "axiosConfig";
import BannerPreviewImage from "components/BannerPreviewImage";
import FirstCharacterLogo from "components/Icons/FirstCharacterLogo/indx";
import GoogleIcon from "components/Icons/GoogleIcon";
import Spinner from "components/Spinner";
import { CATEGORY_PARENTS, IContentCategory } from "constants/category";
import { handlingKeywordServiceError } from "constants/utility/error";
import { convertKeywordV3ArrToObj, IDataForSEOV3 } from "constants/utility/keyword";
import { numberWithCommas } from "constants/utility/number";
import IKeywordSearchVolumeResponse from "models/keyword-search-volume-response.interface";
import React from "react";
import { connect, ConnectedProps, useDispatch } from "react-redux";
import { showEditCampaign } from "store/actions/popup.actions";
import { ENUM_USER_ROLES } from "store/states/user.state";
import { RootState } from "store/store";
import { CampaignOverviewStyled } from "./styled";
import api from "services/api";
import { AdFormCategoryID } from "services/types";

const editIcon = require("assets/images/edit.png");

interface ICampaignOverviewProps extends PropsFromRedux {}

const mapStateToProps = ({ currentCampaign, user, targeting }: RootState) => ({
  currentCampaign,
  user,
  targeting,
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const CampaignOverview: React.FunctionComponent<ICampaignOverviewProps> = ({
  currentCampaign,
  user,
  targeting,
}) => {
  const dispatch = useDispatch();

  const includes =
    currentCampaign.data?.categories.filter((category) => category.behavior === "include") || [];
  const excludes =
    currentCampaign.data?.categories.filter((category) => category.behavior === "exclude") || [];

  const [performKeywords, setPerformKeywords] = React.useState<{
    [key: string]: IDataForSEOV3;
  } | null>(null);
  const [isKeywordsLoading, setKeywordsLoading] = React.useState(false);

  const parentIncludes = includes.reduce((acc, cur) => {
    if (cur.source_parent_key) {
      if (acc[cur.source_parent_key]) {
        acc[cur.source_parent_key] = [...acc[cur.source_parent_key], cur];
      } else {
        acc[cur.source_parent_key] = [cur];
      }
    }
    return acc;
  }, {} as { [key: string]: Array<AdFormCategoryID> });

  const parentExcludes = excludes.reduce((acc, cur) => {
    if (cur.source_parent_key) {
      if (acc[cur.source_parent_key]) {
        acc[cur.source_parent_key] = [...acc[cur.source_parent_key], cur];
      } else {
        acc[cur.source_parent_key] = [cur];
      }
    }

    return acc;
  }, {} as { [key: string]: Array<AdFormCategoryID> });

  const fetchKeywordsList = async (keywords: Array<string>) => {
    if (keywords.length) {
      try {
        const { data } = await api.dataForSEO.keywordSeachVolume(keywords.slice(0, 50));

        const results = convertKeywordV3ArrToObj(data.data);

        return results;
      } catch (error: any) {
        handlingKeywordServiceError(error);
      }
    }
    return {};
  };

  React.useEffect(() => {
    const fetchCampaignKeywordData = async () => {
      if (currentCampaign.data) {
        setPerformKeywords(null);
        setKeywordsLoading(true);

        const initialKeywords = await fetchKeywordsList(currentCampaign.data?.theKeywords);
        setPerformKeywords(initialKeywords);
        setKeywordsLoading(false);
      }
    };

    fetchCampaignKeywordData();
  }, [currentCampaign.data?.theKeywords]);

  const unknownInclude = includes.find((include) => include.id === 1);
  const unknownExclude = excludes.find((exclude) => exclude.id === 1);

  const campaignKeywords = Object.values(performKeywords || {});

  const avgNetwork =
    campaignKeywords.reduce((acc, cur) => acc + Number(cur.radSv), 0) / campaignKeywords.length;

  const avgSearches =
    campaignKeywords.reduce((acc, cur) => acc + Number(cur.search_volume), 0) /
    campaignKeywords.length;

  const avgScores =
    campaignKeywords.reduce((acc, cur) => {
      return acc + cur.competition;
    }, 0) / campaignKeywords.length;
  const avgGoogleCPC =
    campaignKeywords.reduce((acc, cur) => {
      return acc + cur.cpc;
    }, 0) / campaignKeywords.length;
  const avgRadCPC =
    campaignKeywords.reduce((acc, cur) => {
      return acc + cur.radCpc;
    }, 0) / campaignKeywords.length;

  const hasTargeting =
    currentCampaign.data &&
    (currentCampaign.data.theGenders.length ||
      currentCampaign.data.theAgeGroups.length ||
      currentCampaign.data.locations.length ||
      currentCampaign.data.theDevices.length ||
      currentCampaign.data.theOs.length ||
      currentCampaign.data.theBrowsers.length ||
      includes.length ||
      excludes.length);

  const selectedDevices = targeting.data.devices.filter((device) =>
    currentCampaign.data?.theDevices.includes(device.alternative_id)
  );
  const selectedOs = targeting.data.os.filter((os) =>
    currentCampaign.data?.theOs.includes(os.alternative_id)
  );
  const selectedBrowsers = targeting.data.browsers.filter((browser) =>
    currentCampaign.data?.theBrowsers.includes(browser.alternative_id)
  );

  return (
    currentCampaign.data && (
      <CampaignOverviewStyled>
        <div className="campaign-detail">
          <div className="left-col">
            <div className="details">
              {user.role !== ENUM_USER_ROLES.ADMIN && (
                <div onClick={() => dispatch(showEditCampaign(1))} className="edit">
                  <img src={editIcon} alt="edit-icon" />
                </div>
              )}

              <p className="detail-field">Details</p>
              <div>
                <div className="field-title">Campaign Name</div>
                <div className="field-value">{currentCampaign.data.name}</div>

                <div className="field-title">Goal</div>
                <div className="field-value">{currentCampaign.data.goal}</div>

                <div className="field-title">Description</div>
                <div className="field-value">{currentCampaign.data.description}</div>
              </div>
            </div>

            <div className="targeting">
              {user.role !== ENUM_USER_ROLES.ADMIN && (
                <div onClick={() => dispatch(showEditCampaign(2))} className="edit">
                  <img src={editIcon} alt="edit-icon" />
                </div>
              )}

              <p className="detail-field">Targeting</p>

              <div className="targeting-body">
                {hasTargeting ? (
                  <>
                    {currentCampaign.data.theAgeGroups.length ? (
                      <>
                        <span className="field-title">Age</span>
                        <p className="field-value">
                          {currentCampaign.data.theAgeGroups.map((age, index) => (
                            <span key={age}>{`${index ? "|" : ""} ${age} `}</span>
                          ))}
                        </p>
                      </>
                    ) : null}

                    {currentCampaign.data.theGenders.length ? (
                      <>
                        <span className="field-title">Gender</span>
                        <p className="field-value">
                          {currentCampaign.data.theGenders.map((gender, index) => (
                            <span key={gender}>{`${index ? "|" : ""} ${gender} `}</span>
                          ))}
                        </p>
                      </>
                    ) : null}

                    {currentCampaign.data.theBrowsers.length ? (
                      <>
                        <span className="field-title">Browser</span>
                        <p className="field-value">
                          {selectedBrowsers.map((browser) => (
                            <span key={browser.alternative_id}>
                              {`- ${browser.name}`}
                              <br />
                            </span>
                          ))}
                        </p>
                      </>
                    ) : null}

                    {currentCampaign.data.theOs.length ? (
                      <>
                        <span className="field-title">OS</span>
                        <p className="field-value">
                          {selectedOs.map((os) => (
                            <span key={os.alternative_id}>
                              {`- ${os.name}`}
                              <br />
                            </span>
                          ))}
                        </p>
                      </>
                    ) : null}

                    {currentCampaign.data.theDevices.length ? (
                      <>
                        <span className="field-title">Devices</span>
                        <p className="field-value">
                          {selectedDevices.map((device) => (
                            <span key={device.alternative_id}>
                              {`- ${device.name}`}
                              <br />
                            </span>
                          ))}
                        </p>
                      </>
                    ) : null}

                    {currentCampaign.data.locations.length ? (
                      <>
                        <span className="field-title">Locations</span>
                        <p className="field-value">
                          {currentCampaign.data.locations.map((location) => (
                            <span key={location.placeId}>
                              {`- ${location.address}`}
                              <br />
                            </span>
                          ))}
                        </p>
                      </>
                    ) : null}

                    {includes.length || excludes.length ? (
                      <span className="field-title">Content Category</span>
                    ) : null}

                    <div className="field-value">
                      {includes.length ? (
                        <>
                          <span>Include</span>
                          <ul>
                            {unknownInclude && <li>{unknownInclude.fullName}</li>}
                            {Object.keys(parentIncludes).map((parent) => {
                              return (
                                <li key={parent}>
                                  <span>{CATEGORY_PARENTS[parent]}</span>
                                  <ul>
                                    {parentIncludes[parent].map((sub) => {
                                      return (
                                        <li key={sub.id}>
                                          <span>{sub.name}</span>
                                        </li>
                                      );
                                    })}
                                  </ul>
                                </li>
                              );
                            })}
                          </ul>
                        </>
                      ) : null}

                      {excludes.length ? (
                        <>
                          <span>Exclude</span>
                          <ul>
                            {unknownExclude && <li>{unknownExclude.fullName}</li>}
                            {Object.keys(parentExcludes).map((parent) => {
                              return (
                                <li key={parent}>
                                  <span>{CATEGORY_PARENTS[parent]}</span>
                                  <ul>
                                    {parentExcludes[parent].map((sub) => {
                                      return (
                                        <li key={sub.id}>
                                          <span>{sub.name}</span>
                                        </li>
                                      );
                                    })}
                                  </ul>
                                </li>
                              );
                            })}
                          </ul>
                        </>
                      ) : null}
                    </div>
                  </>
                ) : (
                  <p
                    style={{
                      fontSize: "12px",
                      textAlign: "center",
                    }}
                  >
                    This campaign has no targeting
                  </p>
                )}
              </div>
            </div>
          </div>

          <div className="right-col">
            <div className="keywords">
              <p className="detail-field">Campaign Keywords</p>
              {user.role !== ENUM_USER_ROLES.ADMIN && (
                <div onClick={() => dispatch(showEditCampaign(3))} className="edit">
                  <img src={editIcon} alt="edit-icon" />
                </div>
              )}
              <div className="keywords-body">
                {isKeywordsLoading ? (
                  <Spinner />
                ) : performKeywords && Object.keys(performKeywords).length ? (
                  <div className="preview-keywords">
                    <div className="table-header">
                      <div>
                        <span>Keywords</span>
                      </div>
                      <div>
                        <span>Monthly Searches</span>
                      </div>
                      <div>
                        <span>Network Matches</span>
                      </div>
                      <div>
                        <span>Scores</span>
                      </div>
                      <div>
                        <GoogleIcon />
                        <span>CPC</span>
                      </div>
                      <div>
                        <FirstCharacterLogo className="icon" />
                        <span>CPC</span>
                      </div>
                    </div>
                    {campaignKeywords.map((keyword) => {
                      return (
                        <div key={keyword.display} className="table-row">
                          <div className="keyword-display">{keyword.display}</div>
                          <div className="text-right">{keyword.svFormatted}</div>
                          <div className="rad-sv">
                            <div className="text-right network-matches">
                              + {keyword.radSvFormatted}
                            </div>
                          </div>
                          <div className="text-center score">{keyword.competition}</div>
                          <div className="text-right">
                            {keyword.googleCpcString !== "-"
                              ? `$${keyword.googleCpcString}`
                              : keyword.googleCpcString}
                          </div>
                          <div className="text-right rad-cpc">
                            {keyword.radCpcString !== "-"
                              ? `$${keyword.radCpcString}`
                              : keyword.radCpcString}
                          </div>
                        </div>
                      );
                    })}
                    <div className="average-keywords-data">
                      <div />

                      <div className="monthly">
                        <span>{numberWithCommas(avgSearches)}</span>
                      </div>

                      <div className="network">
                        <span>{`+ ${numberWithCommas(avgNetwork)}`}</span>
                      </div>

                      <div className="scores">
                        <span>{Math.round(avgScores)}</span>
                      </div>

                      <div className="g-cpc">
                        <span>{`$${avgGoogleCPC.toFixed(2)}`}</span>
                      </div>

                      <div className="rad-cpc">
                        <span>{`$${avgRadCPC.toFixed(2)}`}</span>
                      </div>
                    </div>
                  </div>
                ) : (
                  <p
                    style={{
                      fontSize: "12px",
                      textAlign: "center",
                    }}
                  >
                    This campaign has no keywords
                  </p>
                )}
              </div>
            </div>

            <div className="creatives">
              {user.role !== ENUM_USER_ROLES.ADMIN && (
                <div onClick={() => dispatch(showEditCampaign(4))} className="edit">
                  <img src={editIcon} alt="edit-icon" />
                </div>
              )}
              <p className="detail-field">Creative and Content</p>

              {currentCampaign.data.banners.length ? (
                <>
                  <div className="banner-source">
                    <div className="creative-name">
                      <span>{currentCampaign.data.banners[0].title || ""}</span>
                    </div>

                    <div className="banner-detail">
                      <span className="banner-key">Landing Page URL :</span>
                      <span className="url">
                        {currentCampaign.data.banners[0].landingPageUrl || ""}
                      </span>
                    </div>

                    <div className="banner-detail">
                      <span className="banner-key">Thankyou Page URL :</span>
                      <span className="url">
                        {currentCampaign.data.banners[0].thankyouPageUrl || ""}
                      </span>
                    </div>

                    <div className="banner-detail">
                      <span className="banner-key">Banner Ads :</span>
                    </div>
                  </div>

                  <div className="banner-preview">
                    <div className="image-list-wrapper">
                      <ul className="image-list">
                        {currentCampaign.data.banners[0].images.map((image) => {
                          return (
                            <li key={image.url} className="image-item">
                              <div className="banner-wrapper">
                                <BannerPreviewImage
                                  height={160}
                                  isHorizontal={(image.width || 0) >= (image.height || 0) * 1.6}
                                  src={`${process.env.REACT_APP_IMAGES_STORAGE}${image.url}`}
                                />
                                <div className="size-wrapper">
                                  <span className="size-label">
                                    {image.width} x {image.height}
                                  </span>
                                </div>
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </>
              ) : (
                <p className="empty-state-creative">This campaign has no banner content</p>
              )}
            </div>
          </div>
        </div>
      </CampaignOverviewStyled>
    )
  );
};

export default connector(CampaignOverview);
