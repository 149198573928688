import Button from "components/Button";
import InvoiceList from "components/InvoiceList";
import cuid from "cuid";
import React from "react";
import { BillingStyled } from "./styled";

const Billing = () => {
  const cardNumber = "123 5678 1234";
  const splitted = cardNumber.split(" ");

  return (
    <BillingStyled>
      <b>Billing</b>
      <div className="billing-wrapper">
        <div className="plan-invoice-section">
          <div className="plan-title">
            <span className="title">Actual Plan</span>
            <div className="upgrade-button">
              <span>Upgrade</span>
            </div>
          </div>
          <div className="plan-section">
            <div className="plan-grid">
              <span>Monthly Plan</span>
              <span>Annual Plan</span>
              <span className="feature">Features</span>

              <span className="tool-plan">Keyword Tool</span>
              <span>Compare</span>
              <span>Suggested</span>
              <span>Competitor</span>

              <span className="tool-plan">Advertising Tool</span>
              <span className="design-plan">Banners</span>

              <span>Static banners</span>
              <span>Gif banners</span>
              <span>Video banners</span>

              <span className="design-plan">Landing Page</span>
              <span>Custom Landing Page</span>
              <span>Blockchain Hosting</span>

              <span>Free advertising credit</span>
              <span>
                <i>Pay Per Considered Visitor</i>
              </span>
            </div>

            <div className="plan-grid">
              <span>
                <b>$59</b> per month
              </span>
              <span>
                <b>$44.25</b>
                per month ($531 per year)
              </span>
              <span />
              <span />

              <span>
                <b>500 searches</b> per month
              </span>
              <span>
                <b>500 searches</b> per month
              </span>
              <span>
                <b>100 searches</b> per month
              </span>
              <span />
              <span />

              <span>
                <b>2 sets</b> per month
              </span>
              <span>
                <b>2 sets</b> per month
              </span>
              <span>
                <b>Contact our sales</b>
              </span>
              <span />

              <span>
                Starting from <b>$239</b>
              </span>
              <span>
                <b>Contact our sales</b>
              </span>
              <span>
                <b>$200</b>
              </span>
              <span>
                <b>Contact our sales</b>
              </span>
            </div>
          </div>

          <InvoiceList />
        </div>

        <div className="payment-management">
          <div className="title">
            <span>Payment Method</span>
          </div>

          <div className="card-management">
            <div className="card-detail">
              <span>Credit card number</span>
              <span>
                {splitted.map((section, index) =>
                  index !== splitted.length - 1 ? (
                    <span key={cuid()}>{`${section.replaceAll(
                      /^\d+$/g,
                      "*".repeat(section.length)
                    )} `}</span>
                  ) : (
                    <span key={cuid()}>{section}</span>
                  )
                )}
              </span>
            </div>

            <div className="card-detail">
              <span>Credit card type</span> <span>Mastercard</span>
            </div>

            <div className="card-detail">
              <span>Expiration date</span> <span>05/26</span>
            </div>
          </div>

          <Button width={250} height={30} outline>
            Edit payment method
          </Button>
        </div>
      </div>
    </BillingStyled>
  );
};

export default Billing;
