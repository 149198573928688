import * as Yup from "yup";
const pattern =
  "^(https?:\\/\\/)?" + // protocol
  "((([a-zA-Z\\d]([a-zA-Z\\d-]{0,61}[a-zA-Z\\d])*\\.)+" + // sub-domain + domain name
  "[a-zA-Z]{2,13})" + // extension
  "|((\\d{1,3}\\.){3}\\d{1,3})" + // OR ip (v4) address
  "|localhost)" + // OR localhost
  "(\\:\\d{1,5})?" + // port
  "(\\/[a-zA-Z\\&\\d%_.~+-:@]*)*" + // path
  "(\\?[a-zA-Z\\&\\d%_.,~+-:@=;&]*)?" + // query string
  "(\\#[-a-zA-Z&\\d_]*)?$"; // fragment locator
export const regex = new RegExp(pattern);

export default Yup.object().shape({
  title: Yup.string()
    .min(2, "*name must have at least 2 characters")
    .max(200, "*name can't be longer than 200 characters")
    .required("*name is required"),
  landingPageUrl: Yup.string()
    .matches(regex, "*Enter correct url")
    .min(2, "*url must have at least 2 characters")
    .max(200, "*url can't be longer than 200 characters")
    .required("*url is required"),

  thankyouPageUrl: Yup.string()
    .matches(regex, "*Enter correct url")
    .max(200, "*url can't be longer than 200 characters"),

  images: Yup.array().min(1, "*Banner image is required"),
  // banners: Yup.array().when("bannerImages", {
  //   is: (bannerImages: Array<File>) => bannerImages.length,
  //   then: Yup.array().min(0),
  //   otherwise: Yup.array().min(1, "*Banner assets is required"),
  // }),
});
