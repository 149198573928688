import { ICampaign } from "./campaigns.state";

export enum ENUM_CAMPAIGN_SOURCE {
  BEESWAX = "beeswax",
  ADFORM = "adform",
}

export const CAMPAIGN_SOURCE_TEXT = {
  [ENUM_CAMPAIGN_SOURCE.BEESWAX]: "Beeswax",
  [ENUM_CAMPAIGN_SOURCE.ADFORM]: "AdForm",
};

export enum ENUM_CAMPAIGN_STATUS {
  ACTIVE = "Active",
  PAUSED = "Paused",
  PENDING = "Pending",
  EDITED = "Edited",
  DELETED = "Deleted",
  DRAFT = "Draft",
  INACTIVE = "Inactive",
}

export type CampaignStatus =
  | ENUM_CAMPAIGN_STATUS.ACTIVE
  | ENUM_CAMPAIGN_STATUS.PAUSED
  | ENUM_CAMPAIGN_STATUS.PENDING
  | ENUM_CAMPAIGN_STATUS.EDITED
  | ENUM_CAMPAIGN_STATUS.DRAFT
  | ENUM_CAMPAIGN_STATUS.DELETED
  | ENUM_CAMPAIGN_STATUS.INACTIVE;

export const CAMPAIGN_STATUS_TEXT = {
  [ENUM_CAMPAIGN_STATUS.ACTIVE]: "Active",
  [ENUM_CAMPAIGN_STATUS.PAUSED]: "Paused",
  [ENUM_CAMPAIGN_STATUS.PENDING]: "Pending",
  [ENUM_CAMPAIGN_STATUS.EDITED]: "Edited",
  [ENUM_CAMPAIGN_STATUS.DELETED]: "Deleted",
  [ENUM_CAMPAIGN_STATUS.DRAFT]: "Draft",
  [ENUM_CAMPAIGN_STATUS.INACTIVE]: "Inactive",
};

export interface IDuplicateCampaignPayload {
  fromCampaignId: string;
  toName: string;
}

interface ICampaignState {
  loading: boolean;
  error: null;
  data: ICampaign | null;
  message: null | string;
  updating: boolean;
  linking: boolean;
}

const state: ICampaignState = {
  loading: false,
  error: null,
  data: null,
  message: null,
  updating: false,
  linking: false,
};

export default state;
