import { Modal } from "react-bootstrap";
import styled, { css } from "styled-components/macro";

export const BuildCampaignModal = styled(Modal)<{ $blur?: boolean }>`
  color: ${({ theme }) => theme.contrast};

  ${({ $blur }) =>
    $blur &&
    css`
      .modal-content {
        &:after {
          position: absolute;
          content: "";
          width: 100%;
          height: 100%;
          background: #000000;
          border-radius: 10px;
          top: 0;
          left: 0;
          opacity: 0.5;
          z-index: 10;
        }
      }
    `}

  .modal-content {
    background-color: ${({ theme }) => theme.modalBackground};
    border-radius: 20px;
    min-height: 500px;
    border: 1px solid rgb(79, 79, 79);
  }

  .modal-header {
    padding: 50px;
    padding-bottom: 0;
    border-bottom: none;
    position: relative;
  }

  .modal-title {
    color: ${({ theme }) => theme.contrast};
    font-size: 18px;

    .build-campaign-title {
      color: ${({ theme }) => theme.contrast};
      display: flex;
      margin-right: 100px;

      .heading {
        padding-right: 20px;
        border-right: 2px solid gray;
        height: 35px;
        white-space: nowrap;
      }

      .modal-description {
        .content {
          white-space: pre-wrap;
          padding-left: 20px;
          font-size: 12px;
        }
      }
    }
  }

  .modal-body {
    padding: 10px 50px 0px 50px;

    .step-navigation {
      display: flex;
      justify-content: space-between;
      width: calc(100% - 25px);
      padding: 20px 0;
    }

    .form-section {
      border-top: 2px solid ${({ theme }) => theme.inputBorder};
      border-bottom: 2px solid ${({ theme }) => theme.inputBorder};
      padding: 20px 0;

      position: relative;

      .progress-percent {
        position: absolute;
        right: 40px;
        z-index: 9;
        background: ${({ theme }) => theme.modalBackground};
        padding: 10px;
        border-radius: 5px;
        transform: translate(10px, -10px);

        .complete {
          color: ${({ theme }) => theme.secondary};
        }
      }

      .scrolly {
        max-height: 400px;
        overflow-y: scroll;

        .steps-form {
          height: 500px;
        }

        ::-webkit-scrollbar {
          width: 8px;
        }

        ::-webkit-scrollbar-thumb {
          background-color: ${({ theme }) => theme.checkbox};
        }
        ::-webkit-scrollbar-track {
          -webkit-box-shadow: ${({ theme }) => theme.scrollTrack};
        }
      }
    }
  }

  .steps {
    height: 100px;
  }

  .close-btn {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    width: 30px;
    height: 30px;

    &:before {
      background: ${({ theme }) => theme.checkbox};
      border-radius: 50%;
      padding: 15px;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
    }

    &:after {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30px;
      height: 30px;
      content: "✕";
      position: absolute;
      top: 0;
      left: 0;
      color: #ffffff;
    }

    &:hover {
      &:before {
        background: rgb(170, 170, 170);
      }
    }
  }
`;

export const ProgressLine = styled.span`
  position: absolute;
  border-top: 1px solid ${({ theme }) => theme.inactive};
  width: 80%;
  height: 100%;
  top: 30px;
`;

export const Progress = styled.span<{ currentStep: number }>`
  position: absolute;
  border-top: 1px solid ${({ theme }) => theme.highlight};
  width: ${({ currentStep }) => `${(currentStep - 1) * 2}0`}%;
  height: 100%;
  z-index: 1;
  top: -1px;
`;

export const StepList = styled.ul`
  width: calc(100% - 25px);
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  justify-content: space-evenly;
  position: relative;
`;

export const StepItem = styled.li<{ active: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 12px;

  .step-icon {
    .icon-svg {
      stroke: ${({ active, theme }) => (active ? theme.highlight : theme.inactive)};
    }

    width: 60px;
    height: 60px;
    background: ${({ theme }) => theme.modalBackground};
    z-index: 2;
    border-radius: 50%;
    padding: 5px;
  }

  .step-title {
    color: ${({ active, theme }) => (active ? theme.highlight : theme.contrast)};
  }
`;
