import UserCampaigns from "./pages/UserCampaigns";
import PaymentResult from "./pages/PaymentResult";
import NewsFeed from "./pages/NewsFeed";
import Home from "./pages/Home";
import Competitor from "./pages/Competitor";
import Suggested from "./pages/Suggested";
import Signals from "./pages/Signals";

import PricingPlan from "./pages/PricingPlan";
import { ConnectedComponent } from "react-redux";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import NewsFeedDetail from "./pages/NewsFeedDetail";
import { ManageCampaignIcon, HomeIcon } from "components/Icons";
import {
  showBuildCampaign,
  showComparePopup,
  showCompetitorPopup,
  showSuggestedPopup,
} from "store/actions/popup.actions";

import CompareIcon from "components/Icons/CompareIcon";
import SuggestedIcon from "components/Icons/SuggestedIcon";
import CompetitorIcon from "components/Icons/CompetitorIcon";
import PlusIcon from "components/Icons/PlusIcon";
import StoreSignal from "./pages/StoreSignal";
import Signal from "./pages/Signal";
import Audience from "./pages/Audience";
import Campaigns from "./pages/Campaigns";
import CreateCampaign from "./pages/CreateCampaign";
import Campaign from "./pages/Campaign_";
import UpdateAudience from "./pages/UpdateAudience";

export const APP_BASE_ROUTE = "app";
export const APP_HOME = `home`;
export const KEYWORDS_RESEARCH = `keywords/research/`;
export const KEYWORD_COMPETITOR = `keywords/competitor`;
export const KEYWORD_SUGGESTED = `keywords/suggested`;
export const KEYWORD_SAVE = `keywords/save`;
export const KEYWORD_SIGNALS = `keywords/signals`;
export const KEYWORD_SIGNAL = `keywords/signal/:id`;
export const KEYWORD_AUDIENCE = `keywords/audience/:id`;
export const KEYWORD_CAMPAIGNS = `keywords/campaigns`;
export const KEYWORD_CREATE_CAMPAIGNS = `keywords/create-campaign`;
export const KEYWORD_CAMPAIGN = `keywords/campaign/:campaign_id`;
export const KEYWORD_AUDIENCE_UPDATE = `keywords/audience-update/:audience_id`;

export const USER_CAMPAIGNS = `campaigns/`;
export const ADD_CAMPAIGN = `campaign/add/`;
export const CAMPAIGN_NEW = `campaign/new/`;
export const EDIT_CAMPAIGN = `campaign/:campaignId/edit/:step`;
export const CAMPAIGN_REPORT = `campaign/:campaignId`;
export const KEYWORD_HISTORY = `keyword-history/`;
export const SAVED_KEYWORDS = `saved-keywords/`;
export const SAVED_KEYWORDS_ITEMS = `saved-keywords/:id`;
export const CHECKOUT = `checkout/`;
export const PAYMENT = `campaign/:campaignId/payment`;
export const PAYMENT_RESULT = `payment-result`;
export const PRICING = `pricing`;
export const NEWS_FEED = `feed/`;
export const NEWS_FEED_ITEM = `feed/:id`;

export interface INavIcon {
  type: string;
  name: IconProp;
}

export interface INavigation {
  path: string;
  text: string;
  icon: ConnectedComponent<any, any> | React.FunctionComponent<any>;
  activeLinks?: string[];
  action?: () => void;
}

export interface INavigationGroup {
  text: string;
  collapsed?: boolean;
  children?: INavigation[];
  path?: string;
  icon?: React.ReactNode;
  activeLinks?: string[];
  imagePath?: string;
}

export interface IRoute {
  path: string;
  component: ConnectedComponent<any, any> | React.FunctionComponent<any>;
}

export const userAppRoutes: Array<IRoute> = [
  {
    path: PRICING,
    component: PricingPlan,
  },
  {
    path: PAYMENT_RESULT,
    component: PaymentResult,
  },
  {
    path: USER_CAMPAIGNS,
    component: UserCampaigns,
  },
  {
    path: NEWS_FEED,
    component: NewsFeed,
  },
  {
    path: `${NEWS_FEED_ITEM}:id`,
    component: NewsFeedDetail,
  },
  { path: APP_HOME, component: Home },
  {
    path: KEYWORD_COMPETITOR,
    component: Competitor,
  },
  {
    path: KEYWORD_SUGGESTED,
    component: Suggested,
  },
  {
    path: KEYWORD_SAVE,
    component: StoreSignal,
  },
  {
    path: KEYWORD_SIGNALS,
    component: Signals,
  },
  {
    path: KEYWORD_SIGNAL,
    component: Signal,
  },
  {
    path: KEYWORD_AUDIENCE,
    component: Audience,
  },
  {
    path: KEYWORD_CAMPAIGNS,
    component: Campaigns,
  },
  {
    path: KEYWORD_CREATE_CAMPAIGNS,
    component: CreateCampaign,
  },
  {
    path: KEYWORD_CAMPAIGN,
    component: Campaign,
  },
  {
    path: KEYWORD_AUDIENCE_UPDATE,
    component: UpdateAudience,
  },
];

const LOCAL_DOMAINS = ["localhost", "127.0.0.1"];
const sidebarLinks_local = [] as Array<INavigationGroup>;

export const sidebarLinks: Array<INavigationGroup> = [
  {
    text: "",
    collapsed: true,
    children: [
      {
        path: APP_HOME,
        text: "Home",
        icon: HomeIcon,
        activeLinks: [APP_HOME],
      },
    ],
  },

  //
  {
    text: "Keyword Tools",
    collapsed: true,
    children: [
      {
        path: KEYWORD_SUGGESTED,
        text: "Suggested",
        icon: SuggestedIcon,
        activeLinks: [KEYWORD_SUGGESTED],
      },
      {
        path: KEYWORD_COMPETITOR,
        text: "Competitor",
        icon: CompetitorIcon,
        activeLinks: [KEYWORD_COMPETITOR],
      },
      {
        path: KEYWORD_SIGNALS,
        text: "Signal",
        icon: CompareIcon,
        activeLinks: [KEYWORD_SIGNALS],
      },
      // {
      //   path: KEYWORD_AUDIENCES,
      //   text: "Audience",
      //   icon: CompareIcon,
      //   activeLinks: [KEYWORD_AUDIENCES],
      // },
      {
        path: KEYWORD_CAMPAIGNS,
        text: "Campaign",
        icon: CompareIcon,
        activeLinks: [KEYWORD_CAMPAIGNS],
      },
    ],
  },
  ...sidebarLinks_local,
];

export interface IBottomLink {
  path: string;
  text: string;
  imagePath: string;
  activeLinks: string[];
  icon?: INavIcon;
}

export interface ITopbarLink {
  path?: string;
  text: string;
  activeLinks?: string[];
  children?: Array<{
    path: string;
    text: string;
    icon?: ConnectedComponent<any, any> | React.FunctionComponent<any>;
    activeLinks: string[];
  }>;
}

export const topbarLeftLinks: Array<ITopbarLink> = [
  {
    path: "#AdvertisingTips",
    text: "Advertising Tips",
    activeLinks: ["#AdvertisingTips"],
  },
  {
    path: "#Blog",
    text: "Blog",
    activeLinks: ["#Blog"],
  },
  {
    path: "#Packages",
    text: "Packages",
    activeLinks: ["#Packages"],
  },
  {
    text: "Help Center",
    children: [
      {
        path: "#Action",
        text: "Action",
        activeLinks: ["#Action"],
      },
      {
        path: "#Something",
        text: "Something",
        activeLinks: ["#Something"],
      },
    ],
  },
];

export const topbarRightLinks = [] as Array<INavigation>;
