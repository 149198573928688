import React, { useEffect } from "react";
import DropdownSelect from "components/DropdownSelect";
import { connect, ConnectedProps } from "react-redux";
import { LocationFilterStyled } from "./styled";
import { axiosForReverseAds } from "axiosConfig";
import { RootState } from "store/store";

import { ICountry, ICity } from "store/action-types/countries.types";
import { handlingError } from "constants/utility/error";
import ICitiesResponse from "models/cities-response.interface";
import Button from "components/Button";
import bing_locations from "constants/bing_locations.json";
import { SelectRenderer } from "react-dropdown-select";

const mapStateToProps = ({ keywordLocations }: RootState) => ({
  keywordLocations,
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface ILocationFilterProps extends PropsFromRedux {
  onCountryChange: (countries: Array<ICountry>) => void;
  onCityChange: (cities: Array<ICity>) => void;
  disabled?: boolean;
  onSubmit: () => void;
  searchTerms: string;
}

const LocationFilter: React.FunctionComponent<ILocationFilterProps> = ({
  keywordLocations,
  onCountryChange,
  onCityChange,
  disabled,
  onSubmit,
  searchTerms,
}) => {
  const [selectedCountry, setSelectedCountry] = React.useState<ICountry | null>(null);
  const [selectedCity, setSelectedCity] = React.useState<ICity | null>(null);
  const [filteredCities, setFilteredCities] = React.useState<Array<ICity>>([]);
  const [cityLoading, setCityLoading] = React.useState(false);
  const MAX_CITIES_TO_SHOW = 200;
  const cityRef = React.useRef<any>(null);
  useEffect(() => {
    //
    setSelectedCity(null);
    setFilteredCities([]);

    if (cityRef && cityRef.current) {
      cityRef.current?.clearAll();
    }

    const fetchCities = async () => {
      setCityLoading(true);
      try {
        if (selectedCountry) {
          const filteredCities = bing_locations.filter(
            (location) => location.location_code_parent === selectedCountry.id
          );

          setFilteredCities(filteredCities);
        }
      } catch (error: any) {
        handlingError(error);
      }
      setCityLoading(false);
    };

    if (selectedCountry) {
      fetchCities();
    } else {
      setFilteredCities([]);
      setSelectedCity(null);
    }
  }, [selectedCountry]);

  const handleCountryChange = React.useCallback((values: Array<ICountry>) => {
    setSelectedCountry(values[0]);

    if (onCountryChange) {
      onCountryChange(values);
    }
  }, []);

  const handleCityChange = React.useCallback((values: Array<ICity>) => {
    setSelectedCity(values[0]);

    if (onCityChange) {
      onCityChange(values);
    }
  }, []);

  const onCitySearch = React.useCallback(
    ({ state }: SelectRenderer<unknown>) => {
      const regex = new RegExp(`${state.search}`, "gi");
      const cities = filteredCities.filter((location) => {
        return location.location_name.search(regex) > -1;
      });

      return cities.slice(0, MAX_CITIES_TO_SHOW);
    },
    [filteredCities]
  );

  return (
    <LocationFilterStyled>
      <div className="location-filter">
        <DropdownSelect
          height={50}
          backgroundColor="primary"
          border
          highlight
          disabled={disabled || false}
          loading={keywordLocations.loading}
          clearable={selectedCountry}
          placeholder="Select Country"
          options={keywordLocations.countries}
          searchBy="name"
          labelField="name"
          valueField="name"
          onChange={handleCountryChange}
          name="country"
          noDataRenderer={() => (
            <div className="noResultsFound">
              {keywordLocations.loading ? "Loading..." : "No results found"}
            </div>
          )}
        />

        <DropdownSelect
          height={50}
          backgroundColor="purple"
          ref={cityRef}
          options={filteredCities.slice(0, MAX_CITIES_TO_SHOW)}
          disabled={disabled || false}
          loading={cityLoading}
          clearable={selectedCity}
          placeholder="Select City"
          labelField="location_name"
          valueField="location_code"
          onChange={handleCityChange}
          searchFn={onCitySearch}
          noDataRenderer={() => (
            <div className="noResultsFound">
              {keywordLocations.loading ? "Loading..." : "No results found"}
            </div>
          )}
        />
      </div>
      <Button
        data-testid="keyword-submit"
        color="search-button"
        width={150}
        onClick={onSubmit}
        disabled={!searchTerms}
        type="submit"
      >
        Submit
      </Button>
    </LocationFilterStyled>
  );
};

export default connector(LocationFilter);
