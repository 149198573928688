import { IErrorMessage } from "../../services/types";
import * as types from "../action-types/saved-keywords-list.types";

export function getSavedKeywordsList(): types.GetSavedKeywordsListAction {
  return {
    type: types.GET_SAVED_KEYWORDS_LIST,
  };
}

export function getSavedKeywordsListRequest(): types.GetSavedKeywordsListRequestAction {
  return {
    type: types.GET_SAVED_KEYWORDS_LIST_REQUEST,
  };
}

export function getSavedKeywordsListSuccess(
  payload: Array<{ id: string; name: string }>
): types.GetSavedKeywordsListSuccessAction {
  return {
    type: types.GET_SAVED_KEYWORDS_LIST_SUCCESS,
    payload,
  };
}

export function getSavedKeywordsListFailure(
  error: IErrorMessage
): types.GetSavedKeywordsListFailureAction {
  return {
    type: types.GET_SAVED_KEYWORDS_LIST_FAILURE,
    error,
  };
}
