import { Modal } from "react-bootstrap";
import styled from "styled-components/macro";

export const SizeList = styled.div`
  color: ${({ theme }) => theme.contrast};
  display: flex;
  padding: 20px;
  background: ${({ theme }) => theme.alertModalBackground};
  width: 210px;
  border-radius: 15px;
  border: 1px solid ${({ theme }) => theme.lightBorder};
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
  margin-left: 40px;
`;

export const CustomModal = styled(Modal)`
  .size-trigger {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  .modal-content {
    background-color: ${({ theme }) => theme.background};
    border-radius: 15px;
  }

  .modal-title {
    font-weight: bold;
  }

  .modal-body {
    .message {
      margin: 0;
      font-size: ${({ theme }) => theme.size.md};
      color: ${({ theme }) => theme.contrast};

      .invalid-files-list {
        margin: 10px 0;
        color: ${({ theme }) => theme.color.error};
      }
    }
  }

  .modal-footer {
    .btn {
      background: ${({ theme }) => theme.color.primary};
      font-size: ${({ theme }) => theme.size.lg};
      border-radius: 0px;
      outline: none;
      border: none;
    }
  }
`;
