import { AnyAction } from "redux";
import * as types from "../action-types/user.types";
import changePasswordState from "../states/change-password.state";

const changePasswordReducer = (state = changePasswordState, action: AnyAction) => {
  switch (action.type) {
    case types.UPDATE_PASSWORD_REQUEST:
      state = { ...state, loading: true };
      break;
    case types.UPDATE_PASSWORD_SUCCESS:
      state = { ...state, loading: false, error: null };
      break;
    case types.UPDATE_PASSWORD_FAILURE:
      state = { ...state, loading: false, error: action.error };
      break;
    default:
  }

  return state;
};

export default changePasswordReducer;
