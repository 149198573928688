import { put, call, takeEvery, all, fork } from "redux-saga/effects";

import api from "../../services/api";
import * as types from "../action-types/feed.types";
import * as actions from "../actions/feed.actions";
import { handlingError } from "../../constants/utility/error";

function* onLoadFeeds(action: types.GetFeedsAction) {
  try {
    yield put(actions.getFeedsRequest());
    const { data, total } = yield call(api.feed.getFeeds, action.page);
    if (action.page) {
      yield put(actions.getFeedsSuccess(data, total));
    } else {
      yield put(actions.initializeFeeds(data, total));
    }
  } catch (error: any) {
    yield handlingError(error);
    yield put(actions.getFeedsFailure(error.data));
  }
}

function* watchonLoadFeeds() {
  yield takeEvery(types.GET_FEEDS, onLoadFeeds);
}

export default function* FeedsSaga() {
  yield all([fork(watchonLoadFeeds)]);
}
