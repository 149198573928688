import styled from "styled-components/macro";
import { Tooltip } from "react-bootstrap";

export const StyledTooltip = styled(Tooltip)<{ background?: string; color?: string }>`
  .tooltip-inner {
    color: ${({ color }) => color || "#ffffff"};
    font-size: 12px;
    background: ${({ background }) => background || "rgb(26,26,26)"};
  }

  .tooltip-arrow {
    &:before {
      border-top-color: ${({ background }) => background || "rgb(26,26,26)"};
    }
  }

  .tooltip.top-left .tooltip-arrow {
    bottom: -5px;
    left: 5px;
    border-width: 5px 5px 0;
    border-top-color: black;
  }

  .tooltip-inner {
    max-width: 500px;
  }
`;
