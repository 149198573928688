export const FIXED_AD_SIZES: Record<string, Array<{ width: number; height: number }>> = {
  "1:1": [
    { width: 200, height: 200 },
    // { width: 250, height: 250 },
  ],
  "3:5": [{ width: 240, height: 400 }],
  "25:36": [{ width: 250, height: 360 }],
  "6:5": [
    { width: 300, height: 250 },
    { width: 336, height: 280 },
  ],
  // "29:20": [{ width: 580, height: 400 }],
  "1:5": [{ width: 120, height: 600 }],
  "4:15": [{ width: 160, height: 600 }],
  "1:2": [{ width: 300, height: 600 }],
  "2:7": [{ width: 300, height: 1050 }],
  "39:5": [{ width: 468, height: 60 }],
  "364:45": [{ width: 728, height: 90 }],
  // "31:6": [{ width: 930, height: 180 }],
  "97:9": [{ width: 970, height: 90 }],
  "97:25": [{ width: 970, height: 250 }],
  // "49:6": [{ width: 980, height: 120 }],
  "6:1": [{ width: 300, height: 50 }],
  "32:5": [{ width: 320, height: 50 }],
  "16:5": [{ width: 320, height: 100 }],
};

// export const FLEX_AD_SIZES: Record<string, Array<{ width: number; height: number }>> = {
//   "2:1": [
//     { width: 200, height: 100 }, // Financial (Flex)
//     // { width: 300, height: 150 }, // min N/A (Flex), max Financial (Flex)
//     { width: 450, height: 225 }, // max N/A (Flex)

//     { width: 900, height: 450 }, // min Half Page (Flex)
//     { width: 1800, height: 900 }, // max Half Page (Flex)
//   ],
//   "6:1": [
//     { width: 300, height: 50 }, // min Smartphone Banner (Flex)
//     { width: 450, height: 75 }, // max Smart Phone Banner (Flex)
//   ],
//   "4:1": [
//     { width: 900, height: 225 }, // min Billboard (Flex)
//     { width: 1800, height: 450 }, // max Billboard (Flex)
//   ],
//   "8:1": [
//     { width: 600, height: 75 }, // min Leaderboard (Flex)
//     { width: 1200, height: 150 }, // max Leaderboard (Flex)
//   ],
//   "10:1": [
//     { width: 900, height: 90 }, // min Super Leaderboard (Flex)
//     { width: 1800, height: 180 }, // max Super Leaderboard (Flex)
//   ],
//   "1:2": [
//     { width: 300, height: 600 }, // min 300x600 (Flex)
//     { width: 450, height: 900 }, // max 300x600 (Flex)
//   ],
//   "1:3": [
//     { width: 300, height: 900 }, // min Portrait (Flex)
//     { width: 450, height: 1350 }, // max Portrait (Flex)
//   ],

//   "1:4": [
//     { width: 160, height: 640 }, // min Skyscraper (Flex)
//     { width: 240, height: 960 }, // max Skyscraper (Flex)
//   ],

//   "1:1": [
//     { width: 300, height: 300 }, // min Medium Rectangle (Flex)
//     { width: 450, height: 450 }, // max Medium Rectangle (Flex)
//   ],

//   "10:16": [
//     {
//       width: 800,
//       height: 1280,
//     }, // min 10x16 (Flex)
//     {
//       width: 1200,
//       height: 1920,
//     }, // max 10x16 (Flex)
//   ],
//   "2:3": [
//     { width: 300, height: 450 },
//     { width: 450, height: 675 },
//   ],
//   "3:4": [
//     {
//       width: 600,
//       height: 800,
//     },
//     {
//       width: 900,
//       height: 1200,
//     },
//   ],
//   "16:9": [
//     {
//       width: 540,
//       height: 300,
//     },
//     {
//       width: 800,
//       height: 450,
//     },
//     {
//       width: 1067,
//       height: 600,
//     },
//     {
//       width: 1600,
//       height: 900,
//     },
//   ],
//   "16:10": [
//     {
//       width: 1280,
//       height: 800,
//     },
//     {
//       width: 1920,
//       height: 1200,
//     },
//   ],
//   "3:2": [
//     {
//       width: 450,
//       height: 300,
//     },
//     {
//       width: 675,
//       height: 450,
//     },
//   ],
//   "4:3": [
//     {
//       width: 800,
//       height: 600,
//     },
//     {
//       width: 1200,
//       height: 900,
//     },
//   ],
// };

// export const FIXED_AD_SIZES: Record<string, Array<{ width: number; height: number }>> = {
//   "97:25": [{ width: 970, height: 250 }], // Billboard (Fixed)
//   "6:1": [
//     { width: 120, height: 20 }, // Feature Phone Small Banner (Fixed)
//     { width: 168, height: 28 }, // Feature Phone Medium Banner (Fixed)
//     { width: 216, height: 36 }, // Feature Phone Large Banner (Fixed)
//     { width: 300, height: 50 }, // Smartphone Banner (Fixed)
//   ],
//   "32:5": [{ width: 320, height: 50 }], // Smartphone Banner (Fixed)
//   "364:45": [{ width: 728, height: 90 }], // Leaderboard (Fixed)
//   "2:7": [{ width: 300, height: 1050 }], // Portrait (Fixed)
//   "4:15": [{ width: 160, height: 600 }], // Skyscraper (Fixed)
//   "6:5": [
//     { width: 300, height: 250 }, // Medium Rectangle (Fixed)
//   ],
//   "2:1": [
//     { width: 120, height: 60 }, // 20x60 (Fixed)
//   ],
//   "40:71": [{ width: 640, height: 1136 }], // Mobile Phone Interstitial (Fixed)
//   "375:667": [{ width: 750, height: 1334 }], // Mobile Phone Interstitial (Fixed)
//   "9:16": [
//     {
//       width: 1080,
//       height: 1920,
//     }, // Mobile Phone Interstitial (Fixed)
//     {
//       width: 300,
//       height: 540,
//     }, // min N/A
//     {
//       width: 450,
//       height: 800,
//     }, // max N/A

//     {
//       width: 600,
//       height: 1067,
//     }, // min Full Page Portrait (Flex)

//     {
//       width: 900,
//       height: 1600,
//     }, // min Full Page Portrait (Flex)
//   ],
//   "97:9": [{ width: 970, height: 90 }], // Super Leaderboard (Fixed)
// };
