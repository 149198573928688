import styled from "styled-components/macro";

export const ChartTooltipStyled = styled.div`
  border: 1px solid ${({ theme }) => theme.contrast};
  background-color: ${({ theme }) => theme.background};
  font-size: 14px;
  padding: 10px;
  border-radius: 10px;

  .label {
    color: ${({ theme }) => theme.contrast};
    font-weight: bold;
    margin-bottom: 5px;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
`;

export const ChartValue = styled.li<{ color?: string }>`
  color: ${({ color, theme }) => color || theme.color.green};
`;
