import React from "react";

const EyesOpenIcon = ({ className }: { className?: string }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39.86 22.65" className={className}>
      <defs>
        <style>{".cls-1{fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;}"}</style>
      </defs>
      <g id="Layer_2" data-name="Layer 2">
        <g id="login">
          <path
            className="cls-1"
            d="M.5,11.29S6.05.27,20.8.5c0,0,12.93.08,18.56,11,0,0-4,10.31-19.75,10.7C19.61,22.15,5.36,22.29.5,11.29Z"
          />
          <circle className="cls-1" cx={20.15} cy={11.29} r={7.32} />
        </g>
      </g>
    </svg>
  );
};

export default EyesOpenIcon;
