import { AnyAction } from "redux";
import * as actions from "../actions/user.actions";
import * as types from "../action-types/user.types";

import userState from "../states/user.state";

const userReducer = (state = userState, action: AnyAction) => {
  switch (action.type) {
    case actions.SET_USER_DETAILS:
      state = { ...state, ...action.payload };
      break;
    case actions.RESET_USER_DETAILS:
      state = userState;
      break;
    case types.UPDATE_ADVERTISER_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case types.UPDATE_ADVERTISER_SUCCESS:
      state = {
        ...state,
        ...action.payload,
        loading: false,
      };
      break;
    case types.UPDATE_ADVERTISER_FAILURE:
      state = {
        ...state,
        loading: false,
      };
      break;
    default:
  }

  return state;
};

export default userReducer;
