import { AnyAction } from "redux";
import * as actions from "../action-types/admin-news-feed.types";
import newsFeesState from "../states/admin-news-feed.state";

const adminNewsFeed = (state = newsFeesState, action: AnyAction) => {
  switch (action.type) {
    // get all
    case actions.GET_ADMIN_NEWS_FEED_REQUEST:
      state = { ...state, loading: true };
      break;

    case actions.GET_ADMIN_NEWS_FEED_SUCCESS:
      state = {
        ...state,
        data: action.payload,
        total: action.total,
        loading: false,
        error: null,
      };
      break;

    case actions.GET_ADMIN_NEWS_FEED_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    // get by id
    case actions.RESET_NEWS_FEED_BY_ID:
      state = { ...state, current: null };
      break;
    case actions.GET_NEWS_FEED_BY_ID_REQUEST:
      state = { ...state, loading: true };
      break;

    case actions.GET_NEWS_FEED_BY_ID_SUCCESS:
      state = {
        ...state,
        current: action.payload,
        loading: false,
        error: null,
      };
      break;

    case actions.GET_NEWS_FEED_BY_ID_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    // create
    case actions.CREATE_ADMIN_NEWS_FEED_REQUEST:
      state = { ...state, loading: true };
      break;

    case actions.CREATE_ADMIN_NEWS_FEED_SUCCESS:
      state = {
        ...state,
        data: [action.payload, ...state.data],
        loading: false,
        error: null,
      };
      break;

    case actions.CREATE_ADMIN_NEWS_FEED_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    // update
    case actions.UPDATE_ADMIN_NEWS_FEED_REQUEST:
      state = { ...state, loading: true };
      break;

    case actions.UPDATE_ADMIN_NEWS_FEED_SUCCESS:
      const index = state.data.findIndex((feed) => feed.id === action.payload.id);
      state = {
        ...state,
        current: action.payload,
        data: [
          ...state.data.slice(0, index),
          { ...state.data[index], ...action.payload },
          ...state.data.slice(index + 1),
        ],
        loading: false,
        error: null,
      };
      break;

    case actions.UPDATE_ADMIN_NEWS_FEED_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    // delete
    case actions.DELETE_ADMIN_NEWS_FEED_REQUEST:
      state = { ...state, loading: true };
      break;

    case actions.DELETE_ADMIN_NEWS_FEED_SUCCESS:
      state = {
        ...state,
        data: state.data.filter((feed) => feed.id !== action.payload),
        loading: false,
        error: null,
      };
      break;

    case actions.DELETE_ADMIN_NEWS_FEED_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;
    default:
  }

  return state;
};

export default adminNewsFeed;
