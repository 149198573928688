import React from "react";
import { ListTableStyled } from "./styled";

interface IListTableProps extends React.HTMLAttributes<HTMLTableElement> {
  children: React.ReactNode[];
  fullWidth?: boolean;
  isSavedList?: boolean;
}

const ListTable: React.FunctionComponent<IListTableProps> = ({
  children,
  isSavedList,
  fullWidth,
  ...props
}) => {
  return (
    <ListTableStyled
      data-testid="list-table"
      isSavedList={isSavedList}
      fullWidth={fullWidth}
      {...props}
    >
      {children}
    </ListTableStyled>
  );
};

export default ListTable;
