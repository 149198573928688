import { IErrorMessage } from "../../services/types";
import * as actions from "../action-types/feed.types";
import { INewsFeed } from "../states/admin-news-feed.state";

export function getFeeds(page?: number) {
  return {
    type: actions.GET_FEEDS,
    page,
  };
}

export function getFeedsRequest(): actions.GetFeedsRequestAction {
  return {
    type: actions.GET_FEEDS_REQUEST,
  };
}

export function initializeFeeds(payload: INewsFeed[], total: number): actions.InitializeFeedAction {
  return { type: actions.INITIALIZE_FEED, payload, total };
}

export function getFeedsSuccess(
  payload: INewsFeed[],
  total: number
): actions.GetFeedsSuccessAction {
  return {
    type: actions.GET_FEEDS_SUCCESS,
    payload,
    total,
  };
}

export function getFeedsFailure(error: IErrorMessage): actions.GetFeedsFailureAction {
  return {
    type: actions.GET_FEEDS_FAILURE,
    error,
  };
}
