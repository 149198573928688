import styled from "styled-components";
const empty = require("assets/images/empty-graphics/suggested-graphic.svg").default;

export const multiselectCss = {
  multiselectContainer: {
    border: "none",
    borderRadius: "20px !important",
  },
  searchBox: {
    border: "none",
    borderRadius: "20px !important",
    paddingLeft: "20px",
    paddingRight: "20px",
  },
};

export const customStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    border: state.isFocused || state.menuIsOpen ? "0px solid white" : "0px solid white",
    borderColor: state.isFocused ? "white" : "white",
    outline: state.isFocused ? "1px solid white" : "none",
    boxShadow: state.isFocused ? `0 0 0 0px white` : "none",
    transition: "border-color 0.1s ease",
    height: "50px",
    paddingLeft: "20px",
    paddingTop: "1px",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "21px",
    fontFamily: "Open Sans, sans-serif",
    borderRadius: "8px",
  }),
};

export const buttonCss = {
  width: "30%",
  fontFamily: "Open Sans, sans-serif",
  borderRadius: "8px",
  fontSize: "16px",
  padding: `1px 20px`,
};

const StyledSuggested = styled.div`
  width: 100%;
  min-height: 100%;
  .full {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-family: sans-serif !important;
    padding-bottom: 100px;
    height: auto;
  }

  .header {
    padding: 50px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 20px;
  }

  .tool-title {
    font-weight: bold;
    padding: 0px 20px 0px 0px;
    border-right: 1px solid;
    font-size: 18px;
    font-family: "Open Sans", sans-serif;
    line-height: 27px;
  }

  .tool-description {
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    font-family: "Open Sans", sans-serif;
    white-space: pre;
  }

  .container {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    gap: 30px;
  }

  .child-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 50px;
  }

  .child-container-1 {
    padding: 0px 20%;
  }

  .child-container-location {
    background: white;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0);
    box-sizing: border-box;
    color: rgb(51, 51, 51);
    font-family: "Open Sans", sans-serif !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    min-height: 50px;
    line-height: 21px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .child-container-language {
    border-radius: 8px;
  }

  .language {
    font-family: "Open Sans", sans-serif !important;
    border-radius: 8px !important;
  }

  .child-container-select {
    background: white;
    width: 100%;
    border-radius: 8px !important;
    border: 1px solid rgba(0, 0, 0, 0) !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    height: 50px !important;
    line-height: 21px !important;
    padding: 1px 20px !important;
  }

  .child-container-submit {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .search-bar {
    display: flex;
    gap: 20px;
    border-radius: 8px !important;
    border: 1px solid rgba(0, 0, 0, 0) !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    height: 50px !important;
    line-height: 21px !important;
  }

  .keyword-input {
    width: 100%;
    position: relative;
  }

  .search-icon {
    position: absolute;
    top: 15px;
    right: 15px;
  }

  .err-msg {
    color: red;
  }

  .child-container-table {
    width: 100%;
  }

  .child-container-table-data {
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
  }

  .table-fill {
    background: white;
    border-radius: 3px;
    border-collapse: collapse;
    margin: auto;
    padding: 5px;
    width: 100%;
    animation: float 5s infinite;
  }

  th {
    color: #d5dde5;
    background: #303633;
    border-bottom: 4px solid #9ea7af;
    font-size: 20px;
    font-weight: 200;
    padding: 15px;
    text-align: left;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    vertical-align: middle;
  }

  th:first-child {
    border-top-left-radius: 5px;
  }

  th:last-child {
    border-top-right-radius: 5px;
    border-right: none;
  }

  tr {
    border-top: 1px solid #c1c3d1;
    border-bottom: 1px solid #c1c3d1;
    color: #666b85;
    font-size: 14px;
    font-weight: normal;
    text-shadow: 0 1px 1px rgba(256, 256, 256, 0.1);
  }

  tr:hover td {
    background: #4e5066;
    color: #ffffff;
    border-top: 1px solid #22262e;
  }

  tr:first-child {
    border-top: none;
  }

  tr:last-child {
    border-bottom: none;
  }

  tr:nth-child(odd) td {
    background: #ebebeb;
  }

  tr:nth-child(odd):hover td {
    background: #4e5066;
  }

  tr:last-child td:first-child {
    border-bottom-left-radius: 3px;
  }

  tr:last-child td:last-child {
    border-bottom-right-radius: 3px;
  }

  td {
    background: #ffffff;
    padding: 15px;
    text-align: left;
    vertical-align: middle;
    font-weight: 300;
    font-size: 16px;
    text-shadow: -1px -1px 1px rgba(0, 0, 0, 0.1);
  }

  td:last-child {
    border-right: 0px;
  }

  th.text-left {
    text-align: left;
  }

  th.text-center {
    text-align: center;
  }

  th.text-right {
    text-align: right;
  }

  td.text-left {
    text-align: left;
  }

  td.text-center {
    text-align: center;
  }

  td.text-right {
    text-align: right;
  }

  .ring-loader {
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .empty-suggested-img {
    background-repeat: no-repeat;
    background-image: url(${empty});
    background-size: 300px;
    width: auto;
    height: 500px;
    background-position: center;
  }

  .edit-input {
    width: 100%;
    border: 1px solid rgb(230, 230, 230);
    padding: 2px;
    height: 40px;
    color: rgb(51, 51, 51);
    background-color: rgb(255, 255, 255);
    border-radius: 8px;
    outline: none;
    padding-left: 10px !important;
  }

  .edit-input:focus {
    border: 1px solid rgb(230, 230, 230);
    outline: none;
  }

  .popup-head {
    font-size: 14px;
    font-family: "Open Sans", sans-serif;
    line-height: 27px;
  }

  .popup-body {
    font-size: 12px;
    font-family: "Open Sans", sans-serif;
    line-height: 27px;
  }

  .padding-20 {
    padding: 20px;
  }

  .next-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 20px;
    gap: 30px;
  }

  .add-keyword-btn {
    padding: 10px 30px;
    font-size: 16px;
    font-family: "Open Sans", sans-serif;
  }

  .next-link {
    padding: 10px 30px;
    font-size: 16px;
    font-family: "Open Sans", sans-serif;
    text-decoration: none;
    font-weight: inherit;
    color: white;
  }

  .next-btn {
    padding: 0px !important;
  }

  .td-edit {
    padding: 0px 5px;
  }

  .copy-icon {
    width: 30px;
    height: 30px;
    cursor: pointer;
    margin-left: 30px;
  }
`;

export default StyledSuggested;
