import { put, call, takeEvery, all, fork } from "redux-saga/effects";

import api from "../../services/api";
import * as types from "../action-types/creative.types";
import * as actions from "../actions/creative-actions";
import { handlingError, handlingFormErrors, onSuccess } from "../../constants/utility/error";
import { hideCreativeForm } from "../actions/popup.actions";

// get

function* onLoadCreative(action: types.GetCreativeAction) {
  try {
    yield put(actions.getCreativeRequest());
    // const { data } = yield call(api.creative.getCreative);
    yield put(actions.getCreativeSuccess(null));
  } catch (error: any) {
    yield handlingError(error);
    yield put(actions.getCreativeFailure(error.data));
  }
}

function* watchonLoadCreative() {
  yield takeEvery(types.GET_CREATIVE, onLoadCreative);
}

// create

function* onCreateCreative(action: types.CreateCreativeAction) {
  try {
    yield put(actions.createCreativeRequest());
    const { state, data } = yield call(api.creative.createCreative, action.payload);

    if (action.payload.creativeAd.images.length) {
      yield put(actions.createCreativeAssetsRequest());
      const { data: assets } = yield call(
        api.creative.createCreativeAssets,
        action.payload,
        data.creativeId
      );
      yield put(actions.createCreativeAssetsSuccess(assets));
    }

    if (state === "success") {
      yield put(
        actions.createCreativeSuccess({ ...action.payload.creativeAd, creativeId: data.creativeId })
      );
    }
    yield put(hideCreativeForm());
    yield onSuccess("Create creative");
  } catch (error: any) {
    yield handlingFormErrors(error);
    yield put(actions.createCreativeFailure(error.data));
  }
}

function* watchonCreateCreative() {
  yield takeEvery(types.CREATE_CREATIVE, onCreateCreative);
}

// update

function* onUpdateCreative(action: types.UpdateCreativeAction) {
  try {
    yield put(actions.updateCreativeRequest());
    const { data } = yield call(api.creative.updateCreative, action.payload);

    if (action.payload.creativeAd.images?.length) {
      yield put(actions.createCreativeAssetsRequest());
      const { data: assets } = yield call(
        api.creative.createCreativeAssets,
        action.payload,
        data.creativeId
      );

      yield put(actions.createCreativeAssetsSuccess(assets));
    }

    if (action.payload.creativeAd.assetsUrl.length) {
      yield put(actions.deleteCreativeAssetsRequest());
      const { data: result } = yield call(
        api.creative.deleteCreativeAssets,
        action.payload.creativeAd.assetsUrl,
        data.creativeId
      );
      yield put(actions.deleteCreativeAssetsSuccess(result));
    }

    const { data: updated, state } = yield call(
      api.campaign.getCampaignById,
      action.payload.campaignId
    );
    if (state === "success") {
      yield put(
        actions.updateCreativeSuccess({ ...updated.creatives[0], images: [], assetsUrl: [] })
      );
    }
    yield put(hideCreativeForm());
    yield onSuccess("Update creative");
  } catch (error: any) {
    yield handlingFormErrors(error);
    yield put(actions.updateCreativeFailure(error.data));
  }
}

function* watchonUpdateCreative() {
  yield takeEvery(types.UPDATE_CREATIVE, onUpdateCreative);
}

// delete

function* onDeleteCreative(action: types.DeleteCreativeAction) {
  try {
    yield put(actions.deleteCreativeRequest());
    yield call(api.creative.deleteCreative, action.payload);
    yield put(actions.deleteCreativeSuccess());
  } catch (error: any) {
    yield handlingError(error);
    yield put(actions.deleteCreativeFailure(error.data));
  }
}

function* watchonDeleteCreative() {
  yield takeEvery(types.DELETE_CREATIVE, onDeleteCreative);
}

export default function* CreativeSaga() {
  yield all([
    fork(watchonLoadCreative),
    fork(watchonCreateCreative),
    fork(watchonUpdateCreative),
    fork(watchonDeleteCreative),
  ]);
}
