import BannerPreviewImage from "components/BannerPreviewImage";
import { IValidFile } from "components/Dropzone";
import FirstCharacterLogo from "components/Icons/FirstCharacterLogo/indx";
import GoogleIcon from "components/Icons/GoogleIcon";
import { CATEGORY_KEY_VALUES_OBJECT, CATEGORY_PARENTS } from "constants/category";
import { IDataForSEOV3 } from "constants/utility/keyword";
import { numberWithCommas } from "constants/utility/number";
import { FormikProps } from "formik";
import { IPostCampaignData } from "constants/utility/campaign";
import React from "react";
import { Step5Styled } from "./styled";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "store/store";
const editIcon = require("assets/images/edit.png");

interface IStep5Props extends PropsFromRedux {
  formikProps: FormikProps<IPostCampaignData>;
  setCurrentStep: (step: number) => void;
  keywordsData: { [key: string]: IDataForSEOV3 };
  includes: CATEGORY_KEY_VALUES_OBJECT;
  excludes: CATEGORY_KEY_VALUES_OBJECT;
}

const mapStateToProps = ({ targeting }: RootState) => ({
  targeting,
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const Step5: React.FunctionComponent<IStep5Props> = ({
  formikProps,
  setCurrentStep,
  keywordsData,
  includes,
  excludes,
  targeting,
}) => {
  const data = Object.values(keywordsData);

  const avgSearches = data.reduce((acc, cur) => acc + Number(cur.radSv), 0) / data.length;
  const avgGoogle = data.reduce((acc, cur) => acc + cur.cpc, 0) / data.length;
  const avgRAD = data.reduce((acc, cur) => acc + cur.radCpc, 0) / data.length;

  const { values } = formikProps;

  const includesParent = Object.keys(includes);
  const excludesParent = Object.keys(excludes);

  const selectedDevices = targeting.data.devices.filter((device) =>
    values.devices.includes(device.alternative_id)
  );
  const selectedOs = targeting.data.os.filter((os) => values.os.includes(os.alternative_id));
  const selectedBrowsers = targeting.data.browsers.filter((browser) =>
    values.browsers.includes(browser.alternative_id)
  );

  return (
    <Step5Styled>
      <div className="campaign-preview-wrapper">
        <div className="detail-section step1">
          <span className="edit" onClick={() => setCurrentStep(1)}>
            <img alt="edit-icon" src={editIcon} />
          </span>

          <div className="title">
            <span>Details</span>
          </div>

          <span className="basic-info">Campaign Name</span>
          <p data-testid="campaign-name">{values.name}</p>

          <span className="basic-info">Goal</span>
          <p data-testid="campaign-goal">{values.goal}</p>

          <span className="basic-info">Description</span>
          <p data-testid="campaign-description">{values.description}</p>
        </div>

        <div className="detail-section step3">
          <span className="edit" onClick={() => setCurrentStep(3)}>
            <img alt="edit-icon" src={editIcon} />
          </span>
          <span className="title">Campaign Keywords</span>

          {data.length ? (
            <div className="preview-keywords">
              <div className="table-row">
                <div>
                  <span>Keywords</span>
                </div>
                <div className="searches">
                  <span>Searches</span>
                </div>
                <div className="g-cpc">
                  <span>CPC</span>
                  <GoogleIcon className="g-logo" />
                </div>
                <div className="rad-cpc">
                  <span>CPC</span>
                  <FirstCharacterLogo className="rad-logo" />
                </div>
              </div>
              {data.map((keyword) => {
                return (
                  <div key={keyword.display} className="table-row">
                    <span>{keyword.display}</span> <span>{keyword.radSvFormatted}</span>
                    <span>${keyword.googleCpcString}</span> <span>${keyword.radCpcString}</span>
                  </div>
                );
              })}

              <div className="table-row">
                <span />
                <span>{numberWithCommas(avgSearches)}</span>
                {/* <span>Average CPC</span> */}
                <span>${avgGoogle.toFixed(2)}</span>
                <span>${avgRAD.toFixed(2)}</span>
              </div>
            </div>
          ) : null}
        </div>

        <div className="detail-section ste2">
          <span className="edit" onClick={() => setCurrentStep(2)}>
            <img alt="edit-icon" src={editIcon} />
          </span>

          <div className="title">
            <span>Targeting</span>
          </div>

          {values.ageGroups.length ? (
            <>
              <span className="basic-info">Age</span>
              <p>
                {values.ageGroups.map((age, index) => (
                  <span data-testid="targeting-age" key={age}>{`${index ? "|" : ""} ${age} `}</span>
                ))}
              </p>
            </>
          ) : null}

          {values.genders.length ? (
            <>
              <span className="basic-info">Gender</span>
              <p>
                {values.genders.map((gender, index) => (
                  <span data-testid="targeting-gender" key={gender}>{`${
                    index ? "|" : ""
                  } ${gender} `}</span>
                ))}
              </p>
            </>
          ) : null}

          {values.browsers.length ? (
            <>
              <span className="basic-info">Browser</span>
              <p>
                {selectedBrowsers.map((browser) => (
                  <span data-testid="targeting-browser" key={browser.alternative_id}>
                    {`- ${browser.name}`}
                    <br />
                  </span>
                ))}
              </p>
            </>
          ) : null}

          {values.os.length ? (
            <>
              <span className="basic-info">OS</span>
              <p>
                {selectedOs.map((os) => (
                  <span data-testid="targeting-os" key={os.alternative_id}>
                    {`- ${os.name}`}
                    <br />
                  </span>
                ))}
              </p>
            </>
          ) : null}

          {values.devices.length ? (
            <>
              <span className="basic-info">Devices</span>
              <p>
                {selectedDevices.map((device) => (
                  <span data-testid="targeting-device" key={device.alternative_id}>
                    {`- ${device.name}`}
                    <br />
                  </span>
                ))}
              </p>
            </>
          ) : null}

          {values.locations.length ? (
            <>
              <span className="basic-info">Locations</span>
              <p>
                {values.locations.map((location) => (
                  <span data-testid="targeting-location" key={location.placeId}>
                    {`- ${location.address}`}
                    <br />
                  </span>
                ))}
              </p>
            </>
          ) : null}

          {includesParent.length || excludesParent.length ? (
            <span className="basic-info">Content Category</span>
          ) : null}
          <div>
            {includesParent.length ? (
              <>
                <span>
                  <b>Include</b>
                </span>
                <ul>
                  {includesParent.map((include) => {
                    return (
                      <li key={CATEGORY_PARENTS[include]}>
                        <span>{CATEGORY_PARENTS[include]}</span>
                        <ul>
                          {Object.values(includes[include]).map((sub) => {
                            return (
                              <li key={sub.id}>
                                <span data-testid={`category-${sub.name}`}>{sub.name}</span>
                              </li>
                            );
                          })}
                        </ul>
                      </li>
                    );
                  })}
                </ul>
              </>
            ) : null}

            {excludesParent.length ? (
              <>
                <span>
                  <b>Exclude</b>
                </span>
                <ul>
                  {excludesParent.map((exclude) => {
                    return (
                      <li key={CATEGORY_PARENTS[exclude]}>
                        <span>{CATEGORY_PARENTS[exclude]}</span>
                        <ul>
                          {Object.values(excludes[exclude]).map((sub) => {
                            return (
                              <li key={sub.id}>
                                <span>{sub.name}</span>
                              </li>
                            );
                          })}
                        </ul>
                      </li>
                    );
                  })}
                </ul>
              </>
            ) : null}
          </div>
        </div>

        <div className="detail-section step4">
          <span className="edit" onClick={() => setCurrentStep(4)}>
            <img alt="edit-icon" src={editIcon} />
          </span>
          <div className="banner-info">
            <div className="title">
              <span>Creative and Content</span>
            </div>
            <p className="basic-info">{values.title}</p>

            <span className="">
              <b>Landing Page URL :</b>
            </span>
            <p className="url">{values.landingPageUrl}</p>

            <span className="">
              <b>Thankyou Page URL :</b>
            </span>
            <p className="url">{values.thankyouPageUrl}</p>
          </div>

          <div className="banner-preview">
            <span>
              <b>Banner Ads :</b>
            </span>
            <div className="image-list-wrapper">
              <ul className="image-list">
                {values.images.map((image) => {
                  return (
                    image && (
                      <li key={image.id} className="image-item">
                        <div className="banner-wrapper">
                          <BannerPreviewImage
                            isHorizontal={(image.width || 0) >= (image.height || 0) * 1.6}
                            src={image.thumbnail}
                          />
                          <div className="size-wrapper">
                            <span className="size-label">
                              {image.width} x {image.height}
                            </span>
                          </div>
                        </div>
                      </li>
                    )
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Step5Styled>
  );
};

export default connector(Step5);
