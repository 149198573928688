import styled from "styled-components/macro";

export const NewsFeedDetailStyled = styled.div`
  margin-top: 20px;
  margin-bottom: 50px;
  width: 100%;

  .keyword-tool-title {
    font-size: 11px;
  }

  .feed-detail {
    height: 100%;
    padding: 10px;
    background: rgb(26, 26, 26);

    .publish-date {
      color: ${({ theme }) => theme.color.green};
      font-weight: bold;
      font-size: 14px;
    }
  }

  .content-wrapper {
    position: relative;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: auto;
    }
  }

  .feed-image {
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 500px;
    background-position: center;
  }
`;
