import styled from "styled-components/macro";

export const CategoryTag = styled.div`
  border-radius: 15px;
  border: 1px solid rgb(150, 150, 150);
  width: fit-content;
  padding: 5px;
  font-size: 10px;
  display: flex;
  height: min-content;
  align-items: center;
  pointer-events: none;

  .tag-label {
    text-transform: uppercase;
    margin: 0 5px;
  }
`;

export const CategoryListWrapper = styled.div`
  margin-top: 10px;

  .category-wrapper {
    margin-top: 10px;
    margin-left: 40px;

    .category-name > .parent {
      color: ${({ theme }) => theme.secondaryHighlight};
    }
  }
`;

export const SelectedCategoryList = styled.ul`
  min-width: 300px;
  list-style: none;
  /* max-height: 200px; */
  /* min-height: 260px; */
  margin: 0;
  overflow-y: auto;
  padding: 0;

  .selected-group {
    display: flex;
    margin-top: 20px;

    .include-label {
      margin-left: 10px;
      color: ${({ theme }) => theme.secondary900};
    }

    .exclude-label {
      margin-left: 10px;
      color: ${({ theme }) => theme.color.primary};
    }
  }

  .exclude {
    padding-top: 20px;

    border-top: 1px solid ${({ theme }) => theme.checkbox};
  }

  .divided-category {
    margin-top: 10px;
    margin-left: 20px;
  }

  .selected-item {
    display: flex;
    gap: 15px;
    margin: 10px 0;
    padding-right: 10px;
    align-items: center;

    .delete {
      border-radius: 5px;
      position: relative;
      margin-left: auto;
      padding: 10px;
      cursor: pointer;

      &:before {
        position: absolute;
        top: -10px;
        bottom: 0;
        left: 0;
        right: 0;
        content: "×";
        font-size: 26px;
        color: rgb(150, 150, 150);
        text-align: center;
      }
    }
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0px 10px 10px rgb(0 0 0 / 30%);
    border-radius: 10px;
  }

  ::-webkit-scrollbar {
    height: 12px;
    width: 10px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
    background-color: #555;
  }
`;

export const CategorySelectorStyled = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  background: ${({ theme }) => theme.reviewBackground};
  border-radius: 10px;

  .clear {
    cursor: pointer;
    color: #999999;
    position: absolute;
    right: 0;
    transform: translate(-10px, -45px);
  }

  .section {
    margin: 15px 15px;
    padding: 0 5px;
    padding-right: 20px;
    width: 100%;
    position: relative;

    &:first-child {
      border-right: 1px solid ${({ theme }) => theme.checkbox};
    }

    .selected-wrapper {
      padding: 0;
    }
  }
`;

export const StyledItem = styled.div`
  .category-id {
    margin-left: 10px;
    color: gray;
  }

  .category-item {
    min-height: 40px;
    display: flex;
    align-items: center;
    margin-bottom: 1px solid black;
    gap: 15px;
  }
`;

export const CategoryList = styled.ul`
  overflow-y: auto;
  padding: 0;
  margin: 10px;
  /* max-height: 200px;
  min-height: 195px; */

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0px 10px 10px rgb(0 0 0 / 30%);
    border-radius: 10px;
  }

  ::-webkit-scrollbar {
    height: 12px;
    width: 10px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
    background-color: #555;
  }
`;
