import Button from "components/Button";
import React from "react";
import { Link } from "react-router-dom";
import { VerifiedFailureStyled } from "./styled";
import * as AUTH_ROUTE from "modules/Auth/routes";
import Input from "components/Input";
import { useFormik } from "formik";
import * as Yup from "yup";
import api from "services/api";
import { Alert } from "react-bootstrap";

const VerifiedFailure = () => {
  const [isLoading, setLoading] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState<null | { variant: string; text: string }>(
    null
  );

  const onResendVerification = async (values: { email: string }) => {
    setLoading(true);
    try {
      const { data: response } = await api.user.resendVerification(values.email);
      return response.data;
    } catch (error: any) {
      if (error.code === "auth/verifiy-token-failed") {
        setAlertMessage({
          variant: "danger",
          text: "Verify account failed.",
        });
      } else {
        setAlertMessage({
          variant: "danger",
          text: error.response.data.message,
        });
      }
      setLoading(false);
    }
  };

  const { submitForm, handleChange, values, errors, touched } = useFormik({
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .required("Required")
        .matches(
          /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
          "Invalid email address"
        ),
    }),
    initialValues: {
      email: "",
    },
    onSubmit: (values) => onResendVerification(values),
  });

  return (
    <VerifiedFailureStyled>
      <p>Email verified failed!</p>
      <p>Your account verification has been failure.</p>
      <p>Click the link to resend verification email.</p>

      {alertMessage && (
        <Alert className="mb-5" variant={alertMessage.variant}>
          {alertMessage.text}
        </Alert>
      )}
      <div className="button-wrapper">
        <Input
          type="text"
          placeholder="Email Address"
          dark
          width={200}
          error={errors.email}
          touched={touched.email}
          name="email"
          onChange={handleChange}
          value={values.email}
        />

        <Button loading={isLoading} onClick={() => submitForm()} height={40} noPadding>
          Resend
        </Button>
      </div>
      <div className="forget-password-button">
        <span className="back-button">← Back to </span>
        <Link to={`../${AUTH_ROUTE.LOGIN}`}>{` Log in`}</Link>
      </div>
    </VerifiedFailureStyled>
  );
};

export default VerifiedFailure;
