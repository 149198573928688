import styled from "styled-components/macro";

export const DropContainer = styled.div<{ isError?: boolean }>`
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  min-height: 50px;
  height: 100%;
  color: white;
  border-radius: 5px;

  .upload-icon {
    width: 70px;
    margin: 0 auto;
  }

  .upload-message {
    padding: 6px 20px;
    margin: auto;
    background-color: ${({ theme, isError }) => (isError ? "red" : theme.cpc)};
    border-radius: 8px;
    color: ${({ isError, theme }) => (isError ? "white" : theme.alertModalBackground)};
    transition: 0.3s;
    font-weight: 600;

    &:hover {
      opacity: 0.75;
    }
  }

  .drop-message {
    font-size: 14px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .banner-upload-info {
      font-size: ${({ theme }) => theme.size.sm};
      margin-top: 40px;
      letter-spacing: 0.3px;
    }
  }
  /* 
  @media (max-width: 400px) {
    .drop-message {
      flex-direction: row;
    }
  } */
`;

export const StyledDropzone = styled.div`
  width: 100%;

  .file-input {
    display: none;
  }

  .file-upload-btn {
    color: white;
    text-transform: uppercase;
    outline: none;
    background-color: #4aa1f3;
    font-weight: bold;
    padding: 8px 15px;
    margin-bottom: 5px;
  }

  .file-progressBar {
    width: 100%;
    border-radius: 5px;
    overflow: hidden;
    display: inline-block;
    margin: 0px 10px 5px 5px;
    vertical-align: top;
    flex: 6 !important;
    margin-left: 50px;
    box-sizing: content-box;
    width: calc(100% - 50px);
  }

  .file-progressBar div {
    height: auto;
    color: #fff;
    text-align: right;
    line-height: 15px;
    width: 0;
    background-color: #4caf50;
    border-radius: 3px;
    font-size: 13px;
  }

  .file-type {
    display: inline-block !important;
    position: absolute;
    font-size: 12px;
    font-weight: 700;
    line-height: 13px;
    margin-top: 25px;
    padding: 0 4px;
    border-radius: 2px;
    box-shadow: 1px 1px 2px #abc;
    color: #fff;
    background: #0080c8;
    text-transform: uppercase;
  }

  .file-error {
    display: inline-block;
    vertical-align: top;
    color: #9aa9bb;
  }

  .file-error-message {
    color: red;
  }

  .file-type-logo {
    width: 50px;
    height: 50px;
    background: url(../images/generic.png) no-repeat center center;
    background-size: 100%;
    position: absolute;
  }

  .file-size {
    display: inline-block;
    vertical-align: top;
    color: #30693d;
    margin-left: 10px;
    margin-right: 5px;
    margin-left: 10px;
    color: #444242;
    font-weight: 700;
    font-size: 14px;
  }

  .modal {
    z-index: 999;
    display: none;
    overflow: hidden;
  }

  .modal .overlay {
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.66);
    position: absolute;
    top: 0;
    left: 0;
  }

  .modal .modal-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
    object-fit: cover;
    width: 100%;
    height: 300px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  .modal-image-text {
    position: absolute;
    color: red;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 16px;
  }

  .close {
    position: absolute;
    top: 15px;
    right: 35px;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
  }

  .upload-modal {
    z-index: 999;
    display: none;
    overflow: hidden;
  }

  .upload-modal .overlay {
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.66);
    position: absolute;
    top: 0;
    left: 0;
  }

  .progress-container {
    background: white;
    width: 500px;
    height: 300px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
  }

  .progress-container span {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    font-size: 20px;
  }

  .progress {
    width: 90%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #efefef;
    height: 20px;
    border-radius: 5px;
  }

  .progress-bar {
    position: absolute;
    background-color: #4aa1f3;
    height: 20px;
    border-radius: 5px;
    text-align: center;
    color: white;
    font-weight: bold;
  }

  .error {
    color: red;
  }
`;
