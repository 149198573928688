import { axiosForReverseAds } from "axiosConfig";
import CloseButton from "components/CloseButton";
import { AdsIcon, SearchIcon } from "components/Icons";
import Input from "components/Input";
import KeywordsTable from "components/KeywordsTable";
import Spinner from "components/Spinner";
import { EXPORT_FILE_TYPE } from "constants/types";
import { handlingError } from "constants/utility/error";
import { convertKeywordV3ArrToObj, IDataForSEOV3 } from "constants/utility/keyword";
import ICompetitorInfoResponse from "models/competitor-info-response.interface";
import StatisticSection from "components/StatisticSection";
import React from "react";
import { Modal } from "react-bootstrap";
import { connect, ConnectedProps, useDispatch } from "react-redux";
import api from "services/api";
import { saveCompetitor } from "store/actions/competitor.actions";
import { hideCompetitorPopup } from "store/actions/popup.actions";
import { RootState } from "store/store";
import { CompetitorPopupModal } from "./styled";
import { IDomainInfo } from "constants/competitor";

interface ICompetitionPopupProps extends PropsFromRedux {}

const mapStateToProps = ({ popup }: RootState) => ({
  popup,
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const CompetitorPopup: React.FunctionComponent<ICompetitionPopupProps> = ({ popup }) => {
  const dispatch = useDispatch();

  const [searchTerms, setSearch] = React.useState("");
  const [domainLoading, setDomainLoading] = React.useState(false);
  const [domainInfo, setDomainInfo] = React.useState<null | IDomainInfo>(null);
  const [trafficeSource, setTrafficeSource] = React.useState<null | Array<{
    label: string;
    value: number;
  }>>(null);
  const [topPaidKeywords, setTopPaidKeywords] = React.useState<{ [key: string]: IDataForSEOV3 }>(
    {}
  );
  const [topOrganicKeywords, setTopOrganicKeywords] = React.useState<{
    [key: string]: IDataForSEOV3;
  }>({});
  const [keywordsChartData, setKeywordsChartData] = React.useState<null | Array<{
    name: string;
    value: number;
  }>>(null);
  const [domainChartData, setDomainChartData] = React.useState<null | Array<{
    label: string;
    value: number;
  }>>(null);
  const [selectedTopPaidKeywords, setSelectedTopPaidKeywords] = React.useState<Array<string>>([]);
  const [selectedTopOrganicKeywords, setSelectedTopOrganicKeywords] = React.useState<Array<string>>(
    []
  );
  const MONTHS = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const onDomainSearch = (domain: string) => {
    // const formattedDomain = domain.replace(/^https?:\/\/|\/$/gi, "");
    // setSearchedDomain(formattedDomain);
    if (domain) {
      fetchDomainData(domain);
    }
  };

  const fetchDomainData = async (searchedDomain: string) => {
    setDomainLoading(true);

    try {
      const { data } = await axiosForReverseAds.post<ICompetitorInfoResponse>(
        "keywordServices/competitorInfo",
        {
          domain: searchedDomain,
        }
      );

      const domainData = data.data[0];

      if (Array.isArray(domainData) || !Object.keys(domainData).length) {
        return;
      }
      setDomainInfo(domainData);

      setTrafficeSource([
        { label: "Direct", value: domainData.traffic.sources.direct.percent },
        {
          label: "Referrals",
          value: domainData.traffic.sources.referral.percent,
        },
        { label: "Social", value: domainData.traffic.sources.social.percent },
        { label: "Mail", value: domainData.traffic.sources.mail.percent },
        {
          label: "Search Ad",
          value: domainData.traffic.sources.search_ad.percent,
        },
      ]);

      setKeywordsChartData([
        { name: "Paid", value: domainData.traffic.sources.search_ad.percent },
        {
          name: "Organic",
          value: domainData.traffic.sources.search_organic.percent,
        },
      ]);

      // const chartDataForDomain = domainData.traffic.estimated.reduce((acc, dateString) => {
      //   const date = new Date(dateString);
      //   const item = {
      //     label: MONTHS[date.getMonth()],
      //     value: domainData.traffic.estimated[dateString],
      //   };
      //   return [...acc, item];
      // }, [] as Array<{ label: string; value: number }>);

      const chartDataForDomain = Object.keys(domainData.traffic.estimated).reduce(
        (acc, dateString) => {
          const date = new Date(dateString);
          const item = {
            label: MONTHS[date.getMonth()],
            value: domainData.traffic.estimated[dateString],
          };
          return [...acc, item];
        },
        [] as Array<{ label: string; value: number }>
      );

      setDomainChartData(chartDataForDomain);

      // Fetch top keywords data
      if (domainData.traffic.sources.search_ad.top_keywords.length) {
        const { data: topKeywordsResponse } = await api.dataForSEO.keywordSeachVolume(
          domainData.traffic.sources.search_ad.top_keywords
            .map(({ keyword }: { keyword: string }) => keyword)
            .slice(0, 50)
        );
        const topPaidObj = convertKeywordV3ArrToObj(topKeywordsResponse.data);
        setTopPaidKeywords(topPaidObj);
      }

      // Fetch organic keywords data
      if (domainData.traffic.sources.search_organic.top_keywords.length) {
        const { data: organicKeywordsResponse } = await api.dataForSEO.keywordSeachVolume(
          domainData.traffic.sources.search_organic.top_keywords
            .map(({ keyword }: { keyword: string }) => keyword)
            .slice(0, 50)
        );

        const topOrganicObj = convertKeywordV3ArrToObj(organicKeywordsResponse.data);
        setTopOrganicKeywords(topOrganicObj);
      }
    } catch (error: any) {
      setDomainInfo(null);
      handlingError(error);
    } finally {
      setDomainLoading(false);
    }
  };

  const onPaidKeywordSelect = (newKey: string) => {
    setSelectedTopPaidKeywords((prev) => {
      if (prev.includes(newKey)) {
        return prev.filter((currentKey) => currentKey !== newKey);
      } else {
        return [...prev, newKey];
      }
    });
  };

  const onOrganicKeywordSelect = (newKey: string) => {
    setSelectedTopOrganicKeywords((prev) => {
      if (prev.includes(newKey)) {
        return prev.filter((currentKey) => currentKey !== newKey);
      } else {
        return [...prev, newKey];
      }
    });
  };

  const onExportKeywords = (
    type: EXPORT_FILE_TYPE,
    keywordObj: { [key: string]: IDataForSEOV3 }
  ) => {
    const keywords = Object.values(keywordObj).map((keywordData) => keywordData.display);
    api.keywords.export.keywordSearchVolume(type, keywords);
  };

  const onExit = () => {
    if (searchTerms) {
      dispatch(saveCompetitor(searchTerms));
    }
    dispatch(hideCompetitorPopup());
    setSearch("");
    setDomainInfo(null);
    setTopPaidKeywords({});
    setTopOrganicKeywords({});
  };

  React.useEffect(() => {
    if (popup.showCompetitorPopup.website) {
      fetchDomainData(popup.showCompetitorPopup.website);
    }
  }, [popup.showCompetitorPopup.website]);

  return (
    <CompetitorPopupModal
      keyboard
      $blur={
        popup.showExportPopup.show || popup.showImportKeywords.show || popup.showKeywordListPopup
      }
      size="xl"
      centered
      show={popup.showCompetitorPopup.show}
      onHide={onExit}
      onExited={onExit}
      data-testid="competitor-modal"
    >
      <Modal.Header>
        <Modal.Title>
          <div className="keyword-tool-title">
            <span className="tool-title">Competitor</span>
            <span className="tool-description">
              {`Input direct competitor domain name to discover an accurate estimation of their
traffic, sources, keyword, and bounce rate.`}
            </span>
          </div>

          <CloseButton
            top={15}
            right={15}
            onClick={() => {
              dispatch(hideCompetitorPopup());
            }}
          />
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="search-bar">
          <div className="keyword-input">
            <Input
              data-testid="competitor-input"
              height={50}
              placeholder="Search Domain"
              noBorder
              black
              value={searchTerms}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)}
              onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                e.key === "Enter" && onDomainSearch(searchTerms);
              }}
            />
            <SearchIcon />
          </div>
          <span style={{ color: "darkgray", fontSize: "10px" }}>
            *Data is not available for sites with less than 8,000/month traffic
          </span>
        </div>

        {!domainInfo && domainLoading ? (
          <Spinner />
        ) : domainInfo ? (
          <div className="grid">
            <div className="table-group">
              <div className="top-paid-table">
                <div className="paid-keywords-wrapper">
                  <h3 className="table-title">
                    <b>Top paid keywords</b>
                    {/* {!domainLoading && domainInfo && (
                      <span className="total-visits">
                        <span className="visits">
                          {numberWithCommas(domainInfo.traffic.sources.search_ad.value)}
                        </span>
                        <span className="visits-label">Visits</span>
                      </span>
                    )} */}
                  </h3>
                </div>

                {domainLoading ? (
                  <Spinner />
                ) : Object.keys(topPaidKeywords).length ? (
                  <KeywordsTable
                    size="sm"
                    selectAll={(selectedTableData) =>
                      setSelectedTopPaidKeywords((prev) =>
                        Array.from(new Set([...prev, ...selectedTableData]))
                      )
                    }
                    unSelectAll={(unselectedTableData) =>
                      setSelectedTopPaidKeywords((prev) =>
                        prev.filter((item) => !unselectedTableData.includes(item))
                      )
                    }
                    keywords={topPaidKeywords}
                    onKeywordSelect={(keyword) => {
                      onPaidKeywordSelect(keyword);
                    }}
                    selectedKeys={selectedTopPaidKeywords}
                  />
                ) : (
                  <div className="default-state-text">
                    <span>No Results</span>
                  </div>
                )}
              </div>

              <div className="top-organic-table">
                <div className="top-organic-wrapper">
                  <h3 className="table-title">
                    <b>Top organic keywords</b>
                    {/* {!domainLoading && domainInfo && (
                      <span className="total-visits">
                        <span className="visits">
                          {numberWithCommas(domainInfo.traffic.sources.search_organic.value)}
                        </span>
                        <span className="visits-label">Visits</span>
                      </span>
                    )} */}
                  </h3>
                </div>

                {domainLoading ? (
                  <Spinner />
                ) : Object.keys(topOrganicKeywords).length ? (
                  <KeywordsTable
                    size="sm"
                    selectAll={(selectedTableData) =>
                      setSelectedTopOrganicKeywords((prev) =>
                        Array.from(new Set([...prev, ...selectedTableData]))
                      )
                    }
                    unSelectAll={(unselectedTableData) =>
                      setSelectedTopOrganicKeywords((prev) =>
                        prev.filter((item) => !unselectedTableData.includes(item))
                      )
                    }
                    keywords={topOrganicKeywords}
                    onKeywordSelect={(keyword) => {
                      onOrganicKeywordSelect(keyword);
                    }}
                    selectedKeys={selectedTopOrganicKeywords}
                  />
                ) : (
                  <div className="default-state-text">
                    <span>No Results</span>
                  </div>
                )}
              </div>
            </div>

            <StatisticSection
              domainLoading={domainLoading}
              domainInfo={domainInfo}
              domainChartData={domainChartData}
              keywordsChartData={keywordsChartData}
              trafficeSource={trafficeSource}
            />
          </div>
        ) : (
          <div className="empty" />
        )}
      </Modal.Body>
    </CompetitorPopupModal>
  );
};

export default connector(CompetitorPopup);
