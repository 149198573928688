import React from "react";
import googleIcon from "assets/images/google-icon.svg";
import reverseAdsIcon from "assets/images/logoNoText.png";
import { ResultTable, SortStatusArrow, SpinnerWrapper } from "./styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IDataForSEOV3 } from "constants/utility/keyword";
import { sortObjectByKey } from "./utils";
import { Form, Image } from "react-bootstrap";
import Checkbox from "components/Checkbox";
import Spinner from "components/Spinner";
import BottomBar from "components/BottomBar";
import { EXPORT_FILE_TYPE } from "constants/types";
import Tooltip from "components/Tooltip";
import Popover from "components/Popover";
import { messages } from "constants/utility/messages";
import FirstCharacterLogo from "components/Icons/FirstCharacterLogo/indx";

export enum TABLE_HEADER {
  KEYWORDS = "keyword",
  MONTHLY_SEARCHES = "svFormatted",
  NETWORK_MATCHES = "radSvFormatted",
  DIFFICULTY = "competition",
  GOOGLE_CPC = "cpc",
  RAD_CPC = "radCpc",
}
interface IKeywordsTableProps {
  keywords: { [key: string]: IDataForSEOV3 };
  selectAll?: (items: Array<string>) => void;
  unSelectAll?: (items: Array<string>) => void;
  selectedKeys: Array<string>;
  customCheck?: boolean;
  onSortTableData?: (keywords: { [key: string]: IDataForSEOV3 }) => void;
  isLoading?: boolean;
  onKeywordSelect?: (keyword: string) => void;
  setIsCurrentItemsAllChecked?: (current: boolean) => void;
  isCustomActions?: boolean;
  onRemoveKeywords?: () => void;
  includeCapitalize?: boolean;
  KeywordsSuggested?: string;
  fullHeight?: boolean;
  size?: "sm" | "md";
  isShowSelectAll?: boolean;
}

const KeywordsTable: React.FunctionComponent<IKeywordsTableProps> = ({
  isLoading,
  selectedKeys,
  onKeywordSelect,
  keywords,
  selectAll,
  unSelectAll,
  customCheck,
  onSortTableData,
  setIsCurrentItemsAllChecked,
  isCustomActions,
  onRemoveKeywords,
  includeCapitalize,
  KeywordsSuggested,
  fullHeight,
  size,
  isShowSelectAll,
}) => {
  // const [startingPage, setStartingPage] = React.useState(0);
  // const [pageLimit, setPageLimit] = React.useState(10);
  const [uniqueKeys, setUniqueKeys] = React.useState<Array<string>>([]);

  React.useEffect(() => {
    setUniqueKeys(selectedKeys.map((v) => (includeCapitalize ? v : v.toLowerCase())));
  }, [selectedKeys.length]);

  // const allSearchedKeywords = Object.keys(keywords);
  // const amountOfKeywords = allSearchedKeywords.length;
  // const currentItems = allSearchedKeywords.slice(startingPage, startingPage + pageLimit);
  const currentItems = Object.keys(keywords);

  const handleKeywordSelect = (
    event: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
    keyword: string
  ) => {
    event.persist();
    onKeywordSelect && onKeywordSelect(keyword);
  };

  const [currentColumn, setSorting] = React.useState<{
    column: string;
    isAscending: boolean;
  } | null>(null);

  const sortDataTableByColumn = (column: string) => {
    if (currentItems.length && onSortTableData) {
      const [sortedData, isAscending] = sortObjectByKey(
        keywords,
        (obj) => obj[column as keyof IDataForSEOV3]
      );

      setSorting({
        column,
        isAscending,
      });
      onSortTableData(sortedData);
    }
  };

  const isAllChecked = currentItems.every((keyword) =>
    uniqueKeys.includes(includeCapitalize ? keyword : keyword.toLowerCase())
  );

  React.useEffect(() => {
    if (setIsCurrentItemsAllChecked) {
      setIsCurrentItemsAllChecked(isAllChecked);
    }
  }, [isAllChecked]);

  // React.useEffect(() => {
  //   setSorting(null);
  // }, [isLoading, startingPage, pageLimit]);

  React.useEffect(() => {
    setSorting(null);
  }, [isLoading]);

  if (isLoading) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );
  } else {
    return (
      <>
        <ResultTable size={size} data-testid="keywords-table" fullHeight={fullHeight}>
          <table className="result-table">
            <thead>
              <tr>
                {currentItems.length && selectAll && unSelectAll ? (
                  <th>
                    {isShowSelectAll && (
                      <Checkbox
                        data-testid="keyword-checkbox"
                        size={size || "md"}
                        centered
                        checked={isAllChecked}
                        onSelect={() => {
                          isAllChecked ? unSelectAll(currentItems) : selectAll(currentItems);
                        }}
                      />
                    )}
                  </th>
                ) : null}

                <th
                  onClick={() => sortDataTableByColumn(TABLE_HEADER.KEYWORDS)}
                  className="text-left keyword-name"
                >
                  <Tooltip
                    placement={messages.keywordsComparison.placement}
                    color={messages.keywordsComparison.color}
                    id={messages.keywordsComparison.id}
                    background={messages.keywordsComparison.background}
                    content={KeywordsSuggested || messages.keywordsComparison.content}
                  >
                    <span> Keywords</span>
                  </Tooltip>

                  {currentColumn && currentColumn.column === TABLE_HEADER.KEYWORDS && (
                    <SortStatusArrow isAscending={currentColumn.isAscending} />
                  )}
                </th>
                <th
                  className="text-right col-monthly"
                  onClick={() => sortDataTableByColumn(TABLE_HEADER.MONTHLY_SEARCHES)}
                >
                  <Tooltip
                    placement={messages.monthlySearches.placement}
                    color="rgb(51,51,51)"
                    id="keywords-tooltip"
                    background="rgb(27,255,118)"
                    content={messages.monthlySearches.content}
                  >
                    <span>Monthly searches</span>
                  </Tooltip>

                  {currentColumn && currentColumn.column === TABLE_HEADER.MONTHLY_SEARCHES && (
                    <SortStatusArrow isAscending={currentColumn.isAscending} />
                  )}
                </th>
                <th
                  className="text-right col-network"
                  onClick={() => sortDataTableByColumn(TABLE_HEADER.NETWORK_MATCHES)}
                >
                  <Tooltip
                    color={messages.networkMatches.color}
                    id={messages.networkMatches.id}
                    background={messages.networkMatches.background}
                    content={messages.networkMatches.content}
                  >
                    <span>Network Matches</span>
                  </Tooltip>

                  {currentColumn && currentColumn.column === TABLE_HEADER.NETWORK_MATCHES && (
                    <SortStatusArrow isAscending={currentColumn.isAscending} />
                  )}
                </th>
                <th
                  className="text-center col-score"
                  onClick={() => sortDataTableByColumn(TABLE_HEADER.DIFFICULTY)}
                >
                  <Tooltip
                    color={messages.score.color}
                    id={messages.score.id}
                    background={messages.score.background}
                    content={messages.score.content}
                  >
                    <span>Score</span>
                  </Tooltip>

                  {currentColumn && currentColumn.column === TABLE_HEADER.DIFFICULTY && (
                    <SortStatusArrow isAscending={currentColumn.isAscending} />
                  )}
                </th>
                <th
                  className="text-right col-google"
                  onClick={() => sortDataTableByColumn(TABLE_HEADER.GOOGLE_CPC)}
                >
                  <Image className="googleIcon" src={googleIcon} />
                  <Popover
                    id={messages.cpc.id}
                    placement={messages.cpc.placement}
                    color={messages.cpc.color}
                    title={messages.cpc.title}
                    content={messages.cpc.content}
                    bottomContent={messages.cpc.bottomContent}
                  >
                    <span className="brandText">CPC</span>
                  </Popover>
                  {currentColumn && currentColumn.column === TABLE_HEADER.GOOGLE_CPC && (
                    <SortStatusArrow isAscending={currentColumn.isAscending} />
                  )}
                </th>
                <th
                  className="text-right col-rad-cpc"
                  onClick={() => sortDataTableByColumn(TABLE_HEADER.RAD_CPC)}
                >
                  {/* <Image className="brandIcon" src={reverseAdsIcon} /> */}
                  <FirstCharacterLogo className="brand-icon" />
                  <Popover
                    id={messages.cpc.id}
                    placement={messages.cpc.placement}
                    color={messages.cpc.color}
                    title={messages.cpc.title}
                    content={messages.cpc.content}
                  >
                    <span className="brandText">CPC</span>
                  </Popover>
                  {currentColumn && currentColumn.column === TABLE_HEADER.RAD_CPC && (
                    <SortStatusArrow isAscending={currentColumn.isAscending} />
                  )}
                </th>
              </tr>
            </thead>
            <tbody>
              {currentItems.length ? (
                currentItems.map((key) => {
                  const defaultChecked = customCheck ? key : keywords[key].keyword;
                  return (
                    <tr
                      data-testid={`keyword-row`}
                      key={keywords[key].keyword}
                      onClick={(event) => handleKeywordSelect(event, key)}
                    >
                      {onKeywordSelect && (
                        <td>
                          <Checkbox
                            size={size || "md"}
                            centered
                            checked={uniqueKeys.includes(
                              includeCapitalize ? defaultChecked : defaultChecked.toLowerCase()
                            )}
                          />
                        </td>
                      )}

                      <td data-testid="keyword-name" className="text-left keyword-name">
                        {keywords[key].display}
                      </td>
                      <td className="text-right">{keywords[key].svFormatted}</td>
                      <td>
                        <div className="text-right network-matches">
                          + {keywords[key].radSvFormatted}
                        </div>
                      </td>
                      <td className="text-center score">{keywords[key].competition}</td>
                      <td className="text-right">
                        {keywords[key].googleCpcString !== "-"
                          ? `$${keywords[key].googleCpcString}`
                          : keywords[key].googleCpcString}
                      </td>
                      <td className="text-right rad-cpc">
                        {keywords[key].radCpcString !== "-"
                          ? `$${keywords[key].radCpcString}`
                          : keywords[key].radCpcString}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr data-testid="empty-row" className="placeholderRow">
                  <td colSpan={8} className="placeholderCell">
                    {isLoading ? <Spinner /> : null}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </ResultTable>

        {!isCustomActions && currentItems.length > 0 && (
          <BottomBar
            onExport={{
              type: "keywords",
              data: currentItems,
            }}
            onSelectionDeleteButtonClick={onRemoveKeywords}
            isAllChecked={!!isAllChecked}
            selectedItems={selectedKeys}
            selectAllCallback={() => {
              if (isAllChecked) {
                unSelectAll && unSelectAll(currentItems);
              } else {
                selectAll && selectAll(currentItems);
              }
            }}
          />
        )}

        {/* {amountOfKeywords > 10 && (
          <TablePagination>
            <div className="rows-tab">
              <span>Show rows: </span>

              <Form.Control
                onChange={(e) => {
                  setStartingPage(0);
                  setPageLimit(Number(e.target.value));
                }}
                value={pageLimit}
                className="rows-selector"
                as="select"
              >
                {rowsList.map((val) => (
                  <option key={val} disabled={amountOfKeywords < val} value={val}>
                    {val}
                  </option>
                ))}
              </Form.Control>
            </div>
            <span>
              {`${startingPage + 1} -
              ${
                startingPage + pageLimit > amountOfKeywords
                  ? amountOfKeywords
                  : startingPage + pageLimit
              }
              of ${amountOfKeywords}`}
            </span>
            <div className="pagination-actions">
              <PaginationButton onClick={() => setStartingPage(0)} disabled={startingPage <= 0}>
                <FontAwesomeIcon icon="step-backward" />
              </PaginationButton>
              <PaginationButton
                disabled={startingPage <= 0}
                onClick={() => setStartingPage((prev) => prev - pageLimit - (prev % 10))}
              >
                <FontAwesomeIcon icon="chevron-left" />
              </PaginationButton>
              <PaginationButton
                disabled={startingPage + pageLimit >= amountOfKeywords}
                onClick={() => setStartingPage((prev) => prev + pageLimit)}
              >
                <FontAwesomeIcon icon="chevron-right" />
              </PaginationButton>
              <PaginationButton
                disabled={startingPage + pageLimit >= amountOfKeywords}
                onClick={() => {
                  setStartingPage(
                    amountOfKeywords - (amountOfKeywords % pageLimit) < amountOfKeywords
                      ? amountOfKeywords - (amountOfKeywords % pageLimit)
                      : amountOfKeywords - pageLimit
                  );
                }}
              >
                <FontAwesomeIcon icon="step-forward" />
              </PaginationButton>
            </div>
          </TablePagination>
        )} */}
      </>
    );
  }
};

KeywordsTable.propTypes = {};
KeywordsTable.defaultProps = {
  unSelectAll: () => {},
  customCheck: false,
  isLoading: false,
};
export default KeywordsTable;
