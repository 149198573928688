import {
  legacy_createStore as createStore,
  applyMiddleware,
  AnyAction,
  CombinedState,
} from "redux";
import reduxThunk from "redux-thunk";
import createSagaMiddleware from "redux-saga";
import appReducers from "./reducers/app.reducers";
import rootSaga from "./sagas";
import { composeWithDevTools } from "redux-devtools-extension";
import { USER_LOGOUT } from "./actions/user.actions";

const sagaMiddleware = createSagaMiddleware();

export const middlewares = [reduxThunk, sagaMiddleware];

if (process.env.NODE_ENV === "development") {
  // middlewares.push(logger);
}

const rootReducer = (state: CombinedState<any>, action: AnyAction) => {
  if (action.type === USER_LOGOUT) state = undefined;

  return appReducers(state, action);
};

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(...middlewares)));

sagaMiddleware.run(rootSaga);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

// @ts-ignore
if (window.Cypress) {
  // @ts-ignore
  window.store = store;
}

export default store;
