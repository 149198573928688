import { Modal } from "react-bootstrap";
import styled from "styled-components";

export const AdvertiserPopupModal = styled(Modal)`
  .modal-content {
    padding: 0 20px 30px 20px;
    background-color: ${({ theme }) => theme.alertModalBackground};
    border-radius: 10px;
    min-height: fit-content;
    color: ${({ theme }) => theme.contrast};
    border: 2px solid ${({ theme }) => theme.dark};
  }

  .react-dropdown-select {
    background: ${({ theme }) => theme.input};
    border: 1px solid ${({ theme }) => theme.selectAllBorder} !important;

    .react-dropdown-select-input {
      font-size: 12px;

      ::placeholder {
        opacity: 1;
        color: ${({ theme }) => theme.color.placeholder};
      }
    }

    content > span {
      font-size: 12px;
    }
  }

  .input-text {
    padding-left: 10px;
    border: 1px solid ${({ theme }) => theme.selectAllBorder};
    font-size: 12px;
  }

  .input-wrapper {
    margin-bottom: 15px;
  }

  .confirm {
    display: flex;
    justify-content: center;
    gap: 20px;
    align-items: start;
    padding-top: 10px;
  }

  .modal-header {
    padding: 30px;
    padding-bottom: 0;
    border-bottom: none;

    .modal-title {
      font-size: 18px;
    }
  }

  .modal-body {
    padding: 0;

    .advertiser-form-wrapper {
      max-width: 400px;
      margin: 0 auto;

      .form-title {
        white-space: pre;
        text-align: center;
        color: ${({ theme }) => theme.secondary};
        font-weight: 600;
        font-size: 14px;
      }
    }
  }
`;
