import Accordion from "components/Accordion";
import CategorySelector from "components/CategorySelector";
import Checkbox from "components/Checkbox";
import LocationSelector from "components/LocationSelector";
import { CATEGORY_KEY_VALUES_OBJECT } from "constants/category";
import { ENUM_TARGETING, TARGETING_KEY } from "constants/utility/targeting";
import { FormikProps } from "formik";
import { IPostCampaignData } from "constants/utility/campaign";
import React from "react";
import { ILocation } from "store/states/campaigns.state";
import { Step2Styled, SelectorContainer, LocationWrapper, ContentCategoryWrapper } from "./styled";
import { RootState } from "store/store";
import { connect, ConnectedProps } from "react-redux";

const mapStateToProps = ({ targeting }: RootState) => ({
  targeting,
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface IStep2Props extends PropsFromRedux {
  formikProps: FormikProps<IPostCampaignData>;
  error: string;
  setError: (error: string) => void;
  includes: CATEGORY_KEY_VALUES_OBJECT;
  excludes: CATEGORY_KEY_VALUES_OBJECT;
  setValues: (includes: CATEGORY_KEY_VALUES_OBJECT, excludes: CATEGORY_KEY_VALUES_OBJECT) => void;
}

const Step2: React.FunctionComponent<IStep2Props> = ({
  formikProps: { setFieldValue, values },
  error,
  setError,
  includes,
  excludes,
  setValues,
  targeting,
}) => {
  const locationElementRef = React.useRef<HTMLDivElement>(null);

  const handleCheckboxChange = (newItem: number, field: TARGETING_KEY) => {
    if (values[field].includes(newItem)) {
      const filtered = values[field].filter((oldItem: number) => oldItem !== newItem);
      setFieldValue(field, filtered);
    } else {
      setFieldValue(field, [...values[field], newItem]);
    }
  };

  const handleUpdateLocation = (newLocations: Array<ILocation>) => {
    setFieldValue("locations", newLocations);
  };

  React.useEffect(() => {
    setError("");
  }, [values]);

  return (
    <Step2Styled>
      <div>
        <Accordion
          trigger={<span data-testid="content-category">Content Category</span>}
          content={
            <ContentCategoryWrapper>
              <CategorySelector
                includes={includes}
                excludes={excludes}
                data-testid="category-selector"
                setValues={(includes, excludes) => {
                  setValues(includes, excludes);
                  // await setValues({ ...values, includes, excludes });
                }}
              />
            </ContentCategoryWrapper>
          }
        />

        <Accordion
          autoOpen={!!values.devices.length}
          autoHide={!values.devices.length}
          onClick={() => {
            if (values.devices.length === targeting.data.devices.length) {
              setFieldValue("devices", []);
            } else {
              setFieldValue(
                "devices",
                targeting.data.devices.map((device) => device.alternative_id)
              );
            }
          }}
          trigger={
            <>
              <span>Devices</span>
              <span data-testid="device-group" className="targeting-select">
                <Checkbox
                  size="sm"
                  checked={values.devices.length === targeting.data.devices.length}
                />
                <span>Select all</span>
              </span>
            </>
          }
          content={
            <SelectorContainer wider={targeting.data.devices.length < 4}>
              {targeting.data.devices.map((device, index) => (
                <div
                  data-testid={`device-${index}`}
                  key={device.alternative_id}
                  onClick={() => handleCheckboxChange(device.alternative_id, ENUM_TARGETING.DEVICE)}
                  className="checkbox-wrapper"
                >
                  <Checkbox checked={values.devices.includes(device.alternative_id)} />
                  <span className="checkbox-label">{device.name}</span>
                </div>
              ))}
            </SelectorContainer>
          }
        />

        <Accordion
          autoOpen={!!values.browsers.length}
          autoHide={!values.browsers.length}
          onClick={() => {
            if (values.browsers.length === targeting.data.browsers.length) {
              setFieldValue("browsers", []);
            } else {
              setFieldValue(
                "browsers",
                targeting.data.browsers.map((browser) => browser.alternative_id)
              );
            }
          }}
          trigger={
            <>
              <span>Browser</span>
              <span data-testid="browser-group" className="targeting-select">
                <Checkbox
                  size="sm"
                  checked={values.browsers.length === targeting.data.browsers.length}
                />
                <span>Select all</span>
              </span>
            </>
          }
          content={
            <SelectorContainer wider={targeting.data.browsers.length < 4}>
              {targeting.data.browsers.map((browser, index) => (
                <div
                  data-testid={`browser-${index}`}
                  key={browser.alternative_id}
                  onClick={() =>
                    handleCheckboxChange(browser.alternative_id, ENUM_TARGETING.BROWSER)
                  }
                  className="checkbox-wrapper"
                >
                  <Checkbox checked={values.browsers.includes(browser.alternative_id)} />
                  <span className="checkbox-label">{browser.name}</span>
                </div>
              ))}
            </SelectorContainer>
          }
        />

        <Accordion
          autoOpen={!!values.os.length}
          autoHide={!values.os.length}
          onClick={() => {
            if (values.os.length === targeting.data.os.length) {
              setFieldValue("os", []);
            } else {
              setFieldValue(
                "os",
                targeting.data.os.map((browser) => browser.alternative_id)
              );
            }
          }}
          trigger={
            <>
              <span>OS</span>
              <span data-testid="os-group" className="targeting-select">
                <Checkbox size="sm" checked={values.os.length === targeting.data.os.length} />
                <span>Select all</span>
              </span>
            </>
          }
          content={
            <SelectorContainer wider={targeting.data.os.length < 4}>
              {targeting.data.os.map((os, index) => (
                <div
                  data-testid={`os-${index}`}
                  key={os.alternative_id}
                  onClick={() => handleCheckboxChange(os.alternative_id, ENUM_TARGETING.OS)}
                  className="checkbox-wrapper"
                >
                  <Checkbox checked={values.os.includes(os.alternative_id)} />
                  <span className="checkbox-label">{os.name}</span>
                </div>
              ))}
            </SelectorContainer>
          }
        />

        <Accordion
          trigger={<span data-testid="location-targeting">Location</span>}
          content={
            <LocationWrapper>
              <div data-testid="map-block" ref={locationElementRef} className="form-map-block">
                <LocationSelector
                  handleUpdateLocation={handleUpdateLocation}
                  locations={values.locations}
                />
              </div>
            </LocationWrapper>
          }
        />
      </div>
    </Step2Styled>
  );
};

export default connector(Step2);
