import { INewsFeed } from "./admin-news-feed.state";

const state = {
  total: 0,
  loading: false,
  error: null,
  data: [], // Extract out all of the countries from the raw data
} as IFeedsState;

interface IFeedsState {
  loading: boolean;
  error: string | null;
  data: Array<INewsFeed>;
  total: number;
}

export default state;
