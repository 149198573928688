import React from "react";

const Step5Icon = ({ className }: { className?: string }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 62 62" className={className}>
      <defs>
        <style>
          {
            "\n      .icon-svg,.icon-svg{fill:none;stroke:#fff;stroke-width:2px;}.icon-svg{stroke-linecap:round;stroke-linejoin:round;}.icon-svg{stroke-miterlimit:10;}\n    "
          }
        </style>
      </defs>
      <g id="Layer_2" data-name="Layer 2">
        <g id="details">
          <circle className="icon-svg" cx={31} cy={31} r={30} />
          <circle className="icon-svg" cx={28.52} cy={27.23} r={12.25} />
          <line className="icon-svg" x1={36.18} y1={37.69} x2={43.63} y2={44.71} />
          <polygon
            className="icon-svg"
            points="26.34 32.46 22.15 28.27 23.66 26.76 26.34 29.45 33.12 22.67 34.63 24.18 26.34 32.46"
          />
        </g>
      </g>
    </svg>
  );
};

export default Step5Icon;
