import styled from "styled-components";

export const CampaignOverviewStyled = styled.div`
  padding: 10px;
  margin-bottom: 20px;

  .empty-state-creative {
    font-size: 12px;
    text-align: center;
    color: ${({ theme }) => theme.contrast};
  }

  .edit {
    position: absolute;
    top: 12px;
    right: 12px;
    cursor: pointer;
    width: 12px;
    height: 12px;
    display: flex;

    img {
      width: 12px;
      height: 12px;
      filter: ${({ theme }) => theme.invertIcon};
    }
  }

  .campaign-detail {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
    overflow-y: auto;
    max-height: 400px;
    padding-right: 15px;

    .field-title {
      font-size: 12px;
      color: ${({ theme }) => theme.secondary};
      font-weight: 600;
    }

    .field-value {
      font-size: 12px;
      color: ${({ theme }) => theme.targeting};
    }

    > div {
      .detail-field {
        margin-bottom: 5px;
        font-size: 12px;
        color: ${({ theme }) => theme.text};
      }
    }

    .left-col {
      grid-column: 1/2;
      display: flex;
      flex-direction: column;
      gap: 10px;

      > div {
        position: relative;
        border-radius: 10px;
        padding: 10px 15px;
        background: ${({ theme }) => theme.inputDark};
      }
    }

    .right-col {
      grid-column: 2/4;
      display: flex;
      flex-direction: column;
      gap: 10px;

      > div {
        position: relative;
        border-radius: 10px;
        padding: 10px 15px;
        background: ${({ theme }) => theme.inputDark};
      }
    }

    .details {
      //
    }

    .keywords {
      //

      .keywords-body {
        font-size: 12px;
        min-height: 100px;

        .preview-keywords {
          margin-top: 10px;
          color: ${({ theme }) => theme.contrast};

          .table-header {
            display: grid;
            grid-template-columns: 100px 1fr 1fr 100px 55px 55px;
            padding-bottom: 8px;

            svg {
              width: 15px;
              height: 15px;
              margin-right: 2px;
            }

            .icon {
              width: 15px;
              height: 15px;
              margin-right: 2px;

              path {
                transition: 0.1s;
                fill: ${({ theme }) => theme.cpc};
              }
            }

            > div {
              &:nth-child(1) {
                text-align: left;
              }

              &:nth-child(2) {
                text-align: right;
              }

              &:nth-child(3) {
                text-align: right;
              }

              &:nth-child(4) {
                text-align: center;
              }

              &:nth-child(5) {
                text-align: center;
                display: flex;
                align-items: center;
              }

              &:nth-child(6) {
                text-align: center;
                display: flex;
                align-items: center;
              }
            }
          }

          .average-keywords-data {
            display: grid;
            grid-template-columns: 100px 1fr 1fr 100px 55px 55px;
            color: ${({ theme }) => theme.color.gold};
            margin-top: 5px;

            .monthly {
              text-align: right;
            }

            .network {
              text-align: right;
            }

            .scores {
              text-align: center;
            }

            .g-cpc {
              text-align: center;
            }

            .rad-cpc {
              text-align: center;
            }
          }

          .table-row {
            display: grid;
            grid-template-columns: 100px 1fr 1fr 100px 55px 55px;
            padding: 5px 0;

            > div {
              &:nth-child(1) {
                text-align: left;
                max-width: 100px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                color: ${({ theme }) => theme.tableContent};
              }

              &:nth-child(2) {
                text-align: right;
                color: ${({ theme }) => theme.tableContent};
              }

              &:nth-child(3) {
                text-align: right;
                color: rgb(150, 154, 171);
              }

              &:nth-child(4) {
                text-align: center;
                color: ${({ theme }) => theme.color.gold};
              }

              &:nth-child(5) {
                text-align: center;
                color: ${({ theme }) => theme.tableContent};
              }

              &:nth-child(6) {
                text-align: center;
                color: ${({ theme }) => theme.cpc};
              }
            }

            &:last-child {
              border-bottom: none;
            }

            &:not(&:last-child, &:first-child) {
              > span {
                &:nth-child(4) {
                  color: ${({ theme }) => theme.cpc};
                }
              }
            }

            &:first-child {
              color: #ffffff;
            }

            &:last-child {
              color: ${({ theme }) => theme.color.gold};
            }

            > span {
              padding: 0 5px;

              &:nth-child(1) {
                text-align: left;
              }
              &:nth-child(2) {
                text-align: right;
              }
              &:nth-child(3) {
                text-align: center;
              }
              &:nth-child(4) {
                text-align: center;
              }
            }
          }
        }
      }
    }

    .targeting {
      //
    }

    .creatives {
      .banner-source {
        display: flex;
        font-size: 12px;
        flex-direction: column;
        gap: 5px;

        .creative-name {
          font-size: 14px;
          font-weight: 600;
          color: ${({ theme }) => theme.color.gold};
        }

        .banner-key {
          font-weight: 600;
          color: ${({ theme }) => theme.targeting};
          margin-right: 5px;
        }

        .url {
          color: ${({ theme }) => theme.contrast};
        }
      }

      .banner-preview {
        .image-list-wrapper {
          margin-top: 20px;

          .image-list {
            padding: 0;
            margin: 0;
            display: flex;
            flex-wrap: wrap;

            .image-item {
              padding: 10px;
              display: flex;
              min-width: 200px;

              .banner-wrapper {
                margin-left: 20px;
                width: 100%;
                height: 100%;

                .size-wrapper {
                  margin-top: 20px;

                  .size-label {
                    background-color: ${({ theme }) => theme.color.gold};
                    padding: 3px 14px;
                    color: #000000;
                    font-size: 10px;
                    font-weight: bold;
                  }
                }
              }

              .select-all {
                font-size: 14px;
                margin-left: 10px;
              }
            }
          }
        }
      }
    }
  }
`;
