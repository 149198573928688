import styled from "styled-components/macro";

export const Step1Styled = styled.div`
  padding-right: 20px;

  .field {
    margin-bottom: 30px;
    position: relative;
  }

  .input-title {
    margin-bottom: 10px;
  }

  .goal-error {
    position: absolute;
    top: 0;
    right: 0;
  }

  .name-campaign {
    .build-campaign-title {
      color: ${({ theme }) => theme.contrast};
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
    }
  }

  .goal-selector {
    .goal-group {
      background-color: ${({ theme }) => theme.inputDark};
      border-radius: 8px;
      display: grid;
      padding: 30px 50px;
      gap: 20px 130px;
      grid-template-columns: repeat(auto-fill, 170px);
      justify-content: center;

      .goal-checkbox {
        cursor: pointer;
        align-items: center;
        display: flex;

        .goal-label {
          margin-left: 15px;
        }
      }
    }
  }
`;
