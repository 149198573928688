import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Spinner, Alert, Image } from "react-bootstrap";
import { useDispatch } from "react-redux";
import * as ROUTES from "../../routes";
import FloatingLabel from "components/FloatingLabel";
import Button from "components/Button";
import { loginUser } from "store/actions/user.actions";
import { LoginPageStyled } from "./styled";
import Input from "components/Input";
import RADLogo from "components/Icons/RADLogo";
import logo from "assets/images/VaticAI Logo_Horizontal - Black.png";

// export enum ENUM_AUTH_ERROR {
//   'auth/verifiy-token-failed'
// }

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [alertMessage, setAlertMessage] = useState<null | { variant: string; text: string }>(null);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [isSubmitting, setSubmitting] = React.useState(false);

  const onFormSubmit = async (data: { email: string; password: string }) => {
    setSubmitting(true);
    try {
      await dispatch(loginUser(data, navigate));
    } catch (error: any) {
      if (
        error.response.data.code === "auth/user-not-found" ||
        error.response.data.code === "auth/wrong-password"
      ) {
        setAlertMessage({
          variant: "danger",
          text: "Email or password is incorrect",
        });
      } else if (
        error.response.data.code === "auth/too-many-requests" ||
        error.response.data.code === "auth/verify-token-failed" ||
        error.response.data.code === "auth/login-failed"
      ) {
        setAlertMessage({
          variant: "danger",
          text: error.response.data.message,
        });
      } else {
        setAlertMessage({
          variant: "danger",
          // text: error.response.data.data.message,
          text: `Email or password is incorrect`,
        });
      }
      setSubmitting(false);
    }
  };

  return (
    <LoginPageStyled>
      <form
        className="login-form"
        data-testid="form-wrapper"
        onSubmit={(e) => {
          e.preventDefault();
          onFormSubmit({
            email,
            password,
          });
        }}
        autoComplete="false"
      >
        <Image src={logo} className="logo" alt="logo" />
        {/* <RADLogo className="logo" /> */}

        {alertMessage && (
          <Alert data-testid="login-alert" className="mb-5" variant={alertMessage.variant}>
            {alertMessage.text}
          </Alert>
        )}

        <div className="input-wrapper">
          <Input
            height={47}
            black
            data-testid="email"
            type="email"
            placeholder="Email"
            name="email"
            // error={formik.error.email}
            // touched={formik.touched.email}
            value={email}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setEmail(event.target.value)}
          />
        </div>

        <div className="input-wrapper">
          <Input
            height={47}
            black
            data-testid="password"
            type="password"
            placeholder="Password"
            name="password"
            value={password}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setPassword(event.target.value)
            }
          />
        </div>

        <div className="forget-password-button">
          <Link to={`../${ROUTES.FORGET_PASSWORD}`}>Forgot password?</Link>
        </div>

        <div data-testid="login-btn" className="input-wrapper login-btn">
          <Button
            data-testid="login-button"
            type="submit"
            height={40}
            width={200}
            loading={isSubmitting}
          >
            Login
          </Button>
          <p className="signup">
            Need an account? <Link to={`../${ROUTES.REGISTER}`}>Sign up</Link>
          </p>
        </div>
      </form>
    </LoginPageStyled>
  );
};

export default Login;
