import { put, call, takeEvery, all, fork } from "redux-saga/effects";
import * as actions from "../action-types/admin.types";
import {
  getBankListFailure,
  getBankListSuccess,
  getBankListRequest,
} from "../actions/admin.actions";
import api from "../../services/api";
import { handlingError } from "../../constants/utility/error";

// banks

function* onLoadBankList() {
  try {
    yield put(getBankListRequest());
    const { data } = yield call(api.admin.payments.getBankList);
    yield put(getBankListSuccess(data));
  } catch (error: any) {
    handlingError(error);
    yield put(getBankListFailure(error.response.data.message));
  }
}

function* watchonLoadBankList() {
  yield takeEvery(actions.GET_BANK_LIST, onLoadBankList);
}

export default function* adminSaga() {
  yield all([fork(watchonLoadBankList)]);
}
