import styled from "styled-components";

export const modalStyle = {
  background: "rgb(240, 240, 240)",
};

const StyledCampaign = styled.div`
  width: 100%;
  min-height: 100%;
  .container-full {
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3%;
  }

  .container-head {
    font-weight: bold;
    padding: 0px 20px 0px 0px;
    font-size: 18px;
    font-family: "Open Sans", sans-serif;
    line-height: 27px;
  }

  .campaign-h1 {
    font-weight: 600;
    padding: 0px 20px 0px 0px;
    font-size: 16px;
    font-family: "Open Sans", sans-serif;
    line-height: 27px;
    width: 90%;
  }

  .campaign-h2 {
    font-weight: normal;
    font-size: 14;
  }

  .btn-container {
    width: 90%;
    margin-top: 20px;
  }

  .container-body {
    width: 90%;
    margin: 3%;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
  }

  .table {
    width: 100%;
    background: white;
    border-radius: 3px;
    border-collapse: collapse;
    margin: auto;
    padding: 5px;
    width: 100%;
    animation: float 5s infinite;
  }

  .thead {
    color: #d5dde5;
    background: #acc7bc;
    border-bottom: 4px solid #9ea7af;
    font-size: 20px;
    font-weight: 200;
    padding: 15px;
    text-align: left;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    vertical-align: middle;
  }

  .link {
    cursor: pointer;
    color: #007bff;
    text-decoration: none;
  }

  .link:hover {
    text-decoration: underline;
  }

  .signal-link {
    cursor: pointer;
    color: #007bff;
    text-decoration: none;
  }

  .signal-link:hover {
    text-decoration: underline;
  }

  .th1 {
    /* color: #d5dde5; */
    color: black;
    background: white;
    border-bottom: 4px solid #9ea7af;
    font-size: 20px;
    font-weight: 200;
    padding: 15px;
    text-align: left;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    vertical-align: middle;
  }

  .th1:first-child {
    border-top-left-radius: 5px;
  }

  .th1:last-child {
    border-top-right-radius: 5px;
    border-right: none;
  }

  .tr {
    /* border-top: 1px solid #c1c3d1; */
    border-bottom: 1px solid #c1c3d1;
    /* color: #666b85; */
    color: black;
    font-size: 14px;
    font-weight: normal;
    text-shadow: 0 1px 1px rgba(256, 256, 256, 0.1);
  }

  .tr:hover td {
    /* background: #4e5066; */
    /* background: rgb(190, 184, 184); */
    background: inherit;
    /* color: #ffffff; */
    color: black;
    border-top: 0px solid #22262e;
  }

  tr:first-child {
    border-top: none;
  }

  tr:last-child {
    border-bottom: none;
  }

  .tr:nth-child(odd) td {
    /* background: #ebebeb; */
    background: inherit;
  }

  .tr:nth-child(odd):hover td {
    /* background: #4e5066; */
    /* background: rgb(190, 184, 184); */
    background: inherit;
  }

  tr:last-child td:first-child {
    border-bottom-left-radius: 3px;
  }

  tr:last-child td:last-child {
    border-bottom-right-radius: 3px;
  }

  td {
    background: #ffffff;
    padding: 15px;
    text-align: left;
    vertical-align: middle;
    font-weight: 300;
    font-size: 16px;
    text-shadow: -1px -1px 1px rgba(0, 0, 0, 0.1);
    /* border-right: 1px solid #c1c3d1; */
  }

  td:last-child {
    border-right: 0px;
  }

  th.text-left {
    text-align: left;
  }

  th.text-center {
    text-align: center;
  }

  th.text-right {
    text-align: right;
  }

  td.text-left {
    text-align: left;
  }

  td.text-center {
    text-align: center;
  }

  td.text-right {
    text-align: right;
  }

  .btn {
    height: 50px;
    font-family: Open Sans, sans-serif;
    border-radius: 8px;
    font-size: 16px;
    padding: 1px 20px;
  }

  .pagination-container {
    display: flex;
    align-items: center;
    font-size: 14px;
  }

  .pagination-option {
    color: black;
    float: left;
    padding: 8px 16px;
    margin: 0px 8px;
  }

  .pagination-option:hover {
    cursor: pointer;
    background: #ffffff;
  }

  .disable {
    opacity: 0.4;
    cursor: default !important;
    background: none !important;
  }

  .pagination-active {
    color: blue;
    float: left;
    padding: 8px 16px;
    margin: 0px 8px;
  }

  .hide {
    display: none;
  }

  .donwload-img {
    height: 30px;
    width: 20px;
    text-align: center;
    cursor: pointer;
  }
`;

export default StyledCampaign;
