import React from "react";
import { Image } from "react-bootstrap";
import logo from "assets/images/campaigns-empty.svg";
import { CampaignsEmptyStyled } from "./styled";
import Button from "components/Button";
import { connect, ConnectedProps, useDispatch } from "react-redux";
import { showAdvertiserForm, showBuildCampaign } from "store/actions/popup.actions";
import PlusIcon from "components/Icons/PlusIcon";
import { RootState } from "store/store";

const mapStateToProps = ({ user }: RootState) => ({
  user,
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const EmptyState: React.FunctionComponent<PropsFromRedux> = ({ user }) => {
  const dispatch = useDispatch();

  const handleCreateCampaignClick = () => {
    if (!user.website || !user.businessId) {
      dispatch(showAdvertiserForm());
    } else {
      dispatch(showBuildCampaign());
    }
  };

  return (
    <CampaignsEmptyStyled>
      <div className="empty-content">
        <Image className="empty-state-logo" src={logo} alt="Vatic AI" />
        <p className="empty-state-text">You haven't created any campaign yet.</p>
        <Button noPadding height={40} width={190} color="blue" onClick={handleCreateCampaignClick}>
          <span>Start New Campaign</span>
          <PlusIcon />
        </Button>
      </div>
    </CampaignsEmptyStyled>
  );
};

export default connector(EmptyState);
