import { AnyAction } from "redux";
import * as SIDEBAR_ACTIONS from "../actions/sidebar.actions";
import sidebarState from "../states/sidebar.state";

const sidebarReducer = (state = sidebarState, action: AnyAction) => {
  switch (action.type) {
    case SIDEBAR_ACTIONS.CLOSE_SIDEBAR:
      state = { ...state, close: true };
      break;
    case SIDEBAR_ACTIONS.OPEN_SIDEBAR:
      state = { ...state, close: false };
      break;

    case SIDEBAR_ACTIONS.OPEN_SLIDE_MODAL:
      state = { ...state, isOpenSlideModal: true, id: action.id };
      break;
    case SIDEBAR_ACTIONS.CLOSE_SLIDE_MODAL:
      state = { ...state, isOpenSlideModal: false };
      break;

    default:
  }

  return state;
};

export default sidebarReducer;
