import styled from "styled-components";

const graphic = require("assets/images/empty-graphics/terms-graphic.svg").default;

export const TermsStyled = styled.div`
  .terms-wrapper {
    display: flex;
    margin-top: 20px;
    font-size: 12px;
    overflow-y: auto;
    max-height: 800px;

    .content {
      width: 40%;
      color: ${({ theme }) => theme.text};
    }

    .heading-terms {
      color: ${({ theme }) => theme.contrast};
    }

    .graphic-wrapper {
      width: 60%;
      height: fit-content;
      position: sticky;
      top: 0;
    }

    .graphic {
      background-repeat: no-repeat;
      background-image: url(${graphic});
      background-size: 300px;
      width: auto;
      height: 700px;
      background-position: center;
    }
  }
`;
