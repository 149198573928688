import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
// Axios config for Sheet.API

const axiosForSheetBest = axios.create({
  baseURL: process.env.REACT_APP_SHEET_BEST_BASE_URL,
});

// Axios config for AdForm

const axiosForAdForm: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_ADFORM_BASE_URL,
});

const axiosForReverseAds: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_REVERSE_ADS_API_BASE_URL,
});

axiosForReverseAds.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const accessToken = window.localStorage.getItem("accessToken");

    if (accessToken) {
      config.headers.common.Authorization = `Bearer ${accessToken}`;
    }

    return config;
  },
  (error): Promise<AxiosError> => {
    return Promise.reject(error);
  }
);

axiosForReverseAds.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  (error): Promise<AxiosError> => {
    if (error.response.status === 401) {
      localStorage.removeItem("accessToken");
      window.location.href = "/auth/login";
    }
    if (error.response.status === 403) {
      window.location.href = "/";
    }
    return Promise.reject(error);
  }
);

export { axiosForSheetBest, axiosForAdForm, axiosForReverseAds };
