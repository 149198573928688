import styled from "styled-components/macro";

export const ManageUserPage = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  font-size: 12px;
  margin-top: 20px;

  .keyword-tool-title {
    font-size: 11px;
  }

  .view-payment {
    background: rgb(98, 163, 184);
    border-radius: 50px;
    padding: 3px;
    cursor: pointer;
  }

  .page-title {
    font-size: 20px;
    font-weight: bold;
  }

  .table-wrapper {
    margin-top: 20px;

    .table-wrapper-inner {
      .pagination {
        display: flex;
        gap: 15px;
        margin-top: 20px;
        justify-content: flex-end;
        align-items: center;
      }
    }
  }

  table {
    thead {
      tr {
        th {
          &:first-child {
            padding-left: 20px;
          }
          &:nth-child(1) {
            width: 300px;
          }
          &:nth-child(2) {
            width: 50px;
          }
          &:nth-child(3) {
            width: 100px;
            text-align: center;
          }
          &:nth-child(4) {
            width: 100px;
            text-align: center;
          }
          &:nth-child(5) {
            text-align: center;
            width: 30px;
          }
          &:nth-child(6) {
            width: 80px;
          }
          &:nth-child(7) {
            width: 80px;
            /* text-align: center; */
          }
          &:nth-child(8) {
            /* text-align: center; */
            width: 100px;
          }
          &:nth-child(9) {
            width: 100px;
          }
          &:nth-child(10) {
            width: 50px;
          }
          &:nth-child(11) {
            width: 50px;
          }
        }
      }
    }

    tbody {
      tr {
        .actions-button {
          cursor: pointer;
        }
        td {
          &:first-child {
            padding-left: 20px;
          }
          &:nth-child(2) {
            text-align: center;
          }
          &:nth-child(3) {
            text-align: center;
          }
          &:nth-child(4) {
            text-align: center;
          }
          &:nth-child(6) {
            /* text-align: center; */
          }
          &:nth-child(7) {
            //
          }
        }
      }
    }
  }
`;
