import { Modal } from "react-bootstrap";
import styled from "styled-components/macro";

export const CustomModal = styled(Modal)`
  .modal-content {
    background-color: ${({ theme }) => theme.alertModalBackground};
    border: 1px solid ${({ theme }) => theme.dark};
    border-radius: 10px;
    padding: 15px;
  }

  .modal-header {
    justify-content: center;
    text-align: center;
    color: ${({ theme }) => theme.secondary};
    border: none;
    padding: 20px 20px 0px 20px;
  }

  .modal-body {
    text-align: center;

    .confirm-text {
      color: ${({ theme }) => theme.contrast};
      font-size: 12px;
    }

    .input-group {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .forget-password-btn {
        text-align: right;
        color: ${({ theme }) => theme.secondary};
        cursor: pointer;
        font-size: 14px;
      }

      .input-wrapper {
        position: relative;

        .icon-wrapper {
          padding: 8px 12px;
          cursor: pointer;
          position: absolute;
          top: 0;
          right: 0;
          opacity: 1;

          svg {
            fill: ${({ theme }) => theme.contrast};
            width: 20px;
            height: 20px;
          }
        }

        .show {
          opacity: 0.4;

          svg {
            fill: ${({ theme }) => theme.text};
          }
        }
      }
    }
  }

  .modal-footer {
    justify-content: center;
    border: none;

    .btn {
      background: ${({ theme }) => theme.color.primary};
      font-size: ${({ theme }) => theme.size.lg};
      border-radius: 0px;
      outline: none;
      border: none;
    }
  }
`;
