import { AnyAction } from "redux";
import * as actions from "../action-types/payments.types";
import { initialState } from "../states/payments.state";

const paymentList = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    // payment

    case actions.GET_PAYMENTS_REQUEST:
      state = { ...state, loading: true };
      break;
    case actions.GET_PAYMENTS_SUCCESS:
      state = {
        ...state,
        data: action.payments,
        total: action.total,
        loading: false,
        error: null,
      };
      break;
    case actions.GET_PAYMENTS_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    default:
  }

  return state;
};

export default paymentList;
