import React from "react";
import { OverlayTrigger } from "react-bootstrap";
import { StyledTooltip } from "./styled";

interface ITooltipProps {
  id: string;
  content: string;
  children: React.ReactNode;
  placement?: "top" | "bottom" | "left" | "right";
  background?: string;
  color?: string;
}

const Tooltip: React.FunctionComponent<ITooltipProps> = ({
  id,
  content,
  children,
  placement,
  background,
  color,
}) => {
  return (
    <OverlayTrigger
      placement={placement || "top"}
      overlay={
        <StyledTooltip data-testid="tooltip" background={background} color={color} id={id}>
          {content}
        </StyledTooltip>
      }
    >
      <span>{children}</span>
    </OverlayTrigger>
  );
};

export default Tooltip;
