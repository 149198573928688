import { Formik, useFormik } from "formik";
import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { setting, linkCampaign } from "store/actions/campaign.actions";
import { openSlideModal } from "store/actions/sidebar.actions";
import { PAYMENT_GATEWAY_TEXT, PAYMENT_STATUS_TEXT } from "store/states/payments.state";
import api from "services/api";
import { campaignStatusDropdown } from "constants/campaign-status";
import Badge from "components/Badge";
import { RootState } from "store/store";
import DropdownSelect from "components/DropdownSelect";
import { getGraphColors } from "constants/utility/chart";
import { formatDateTime } from "constants/utility/date";
import Button from "components/Button";
import { ResearchToolsIcon } from "components/Icons";
import Input from "components/Input";
import ListTable from "components/ListTable";
import SlideModal from "components/SlideModal";
import PaymentDetail from "./PaymentDetail";
import { CampaignAdminWrapper, Tab } from "./styled";
import * as types from "./types";
import { handlingError } from "constants/utility/error";
import { ENUM_CAMPAIGN_STATUS } from "store/states/campaign.state";
import ccEmailSchema from "schemas/cc-email.schema";
import emailAddressSchema from "schemas/email-address.schema";
import { toast } from "react-toastify";
import { ENUM_USER_ROLES } from "store/states/user.state";
import TextArea from "components/TextArea";

const CampaignAdmin: React.FunctionComponent<types.ICampaignAdminProps & PropsFromRedux> = ({
  dispatch,
  currentCampaign,
  user,
}) => {
  const [tab, setTab] = React.useState("payment");
  const [cc, setCc] = React.useState<Array<string>>([]);
  const [errorCcTextbox, setErrorCcTextbox] = React.useState("");

  const initialToolValues: types.IinitialToolValues = {
    email: "",
    txtCc: "",
  };

  const onRemoveCc = (value: any) => {
    const tmpCc = [...cc];
    const index = cc.indexOf(value);
    if (index !== -1) {
      tmpCc.splice(index, 1);
      setCc(tmpCc);
    }
  };

  const { submitForm, values, handleChange, errors, touched, setFieldValue, handleSubmit } =
    useFormik({
      onSubmit: (values) => {
        currentCampaign.data &&
          dispatch(
            setting({
              campaignId: currentCampaign.data.id,
              adformId: values.adform || undefined,
              beeswaxId: values.beeswax || undefined,
              status: values.status || undefined,
              portalUrl: values.portalUrl || undefined,
            })
          );
      },
      initialValues: {
        beeswax: currentCampaign.data?.beeswaxId || "",
        adform: currentCampaign.data?.adFormId || "",
        status: currentCampaign.data?.status || ENUM_CAMPAIGN_STATUS.ACTIVE,
        portalUrl: currentCampaign.data?.portalUrl || "",
      },
    });

  return currentCampaign.data ? (
    <CampaignAdminWrapper>
      <div className="left-side">
        <Tab active={tab === "payment"} onClick={() => setTab("payment")}>
          Payment
        </Tab>
        <Tab active={tab === "setting"} onClick={() => setTab("setting")}>
          Setting
        </Tab>
        <Tab active={tab === "tools"} onClick={() => setTab("tools")}>
          Tools
        </Tab>
      </div>

      <div className="right-side">
        {tab === "setting" && (
          <section className="setting-panel">
            <div>
              <form onSubmit={handleSubmit}>
                <div className="setting-inputs">
                  <div>
                    <h5>
                      <b>ADFORM</b>
                    </h5>
                    <br />
                    <Input
                      touched={touched.adform}
                      error={errors.adform}
                      name="adform"
                      value={values.adform}
                      onChange={handleChange}
                    />
                  </div>
                  <div>
                    <h5>
                      <b>BEESWAX</b>
                    </h5>
                    <br />
                    <Input
                      touched={touched.beeswax}
                      error={errors.beeswax}
                      name="beeswax"
                      value={values.beeswax}
                      onChange={handleChange}
                    />
                  </div>
                  <div>
                    <h5>
                      <b>STATUS</b>
                    </h5>
                    <br />
                    <DropdownSelect
                      labelField="text"
                      valueField="text"
                      values={[{ text: values.status }]}
                      onChange={(status: Array<{ text: string }>) => {
                        setFieldValue("status", status[0].text);
                      }}
                      options={campaignStatusDropdown}
                    />
                  </div>
                </div>
              </form>
            </div>

            <div>
              <h5>
                <b>PORTAL</b>
              </h5>
              <br />
              <TextArea
                rows={8}
                name="portalUrl"
                value={values.portalUrl}
                onChange={handleChange}
              />
            </div>
            <div className="submit-section">
              <Button onClick={submitForm} loading={currentCampaign.updating} type="submit">
                Submit
              </Button>{" "}
              <span className="update-message"> {currentCampaign.message} </span>
            </div>
            {!values.beeswax && (
              <div>
                <h5>
                  <b>BEESWAX LINK</b>
                </h5>
                <Button
                  loading={currentCampaign.linking}
                  onClick={() =>
                    currentCampaign.data && dispatch(linkCampaign(currentCampaign.data.id))
                  }
                >
                  Link
                </Button>
              </div>
            )}
          </section>
        )}
        {tab === "payment" && (
          <ListTable fullWidth>
            <thead className="table-head">
              <tr>
                <th>Payment ID</th>
                <th>Amount</th>
                <th>Payment Type</th>
                <th>Payment Status</th>
                <th>Updated At</th>
                <th />
              </tr>
            </thead>
            <tbody className="table-body">
              {currentCampaign.data.payments &&
                currentCampaign.data.payments.map((payment) => (
                  <tr key={payment.id}>
                    <td>{payment.id}</td>
                    <td>{payment.amount}</td>
                    <td>{PAYMENT_GATEWAY_TEXT[payment.paymentGateway]}</td>
                    <td>{PAYMENT_STATUS_TEXT[payment.state]}</td>
                    <td>
                      {formatDateTime(new Date(payment.updated_at))}{" "}
                      {new Date(payment.updated_at).toLocaleTimeString()}
                    </td>
                    <td>
                      {user.role === ENUM_USER_ROLES.SUPER_ADMIN && (
                        <span onClick={() => dispatch(openSlideModal(payment.id))}>
                          <ResearchToolsIcon className="view-payment" />
                        </span>
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </ListTable>
        )}
        {tab === "tools" && (
          <div>
            <Formik
              initialValues={initialToolValues}
              validationSchema={emailAddressSchema}
              onSubmit={(values, actions) => {
                setErrorCcTextbox("");
                if (currentCampaign.data) {
                  api.admin.campaign
                    .forwardCampaign({
                      campaignId: currentCampaign.data.id,
                      to: values.email,
                      cc,
                    })
                    .then((result) => {
                      actions.setSubmitting(false);
                      toast.success("Campaign information has been sent!");
                    })
                    .catch((e) => {
                      if (e.response && e.response.data) {
                        setErrorCcTextbox(e.response.data.message);
                      }
                      handlingError(e);
                      actions.setSubmitting(false);
                    });
                }
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className="tool-inputs">
                    <div>
                      <h5>
                        <b>FORWARD CAMPAIGN</b>
                      </h5>
                      <br />
                      <Input
                        touched={touched.email}
                        error={errors.email}
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        placeholder="Email"
                      />
                      <br />
                      <div className="tool-inputs__cc">
                        <Input
                          touched={touched.txtCc}
                          error={errors.txtCc}
                          name="txtCc"
                          value={values.txtCc}
                          onChange={handleChange}
                          placeholder="CC"
                        />
                        <Button
                          type="button"
                          onClick={() => {
                            ccEmailSchema.isValid(values).then((valid) => {
                              if (cc.includes(values.txtCc.trim())) {
                                setErrorCcTextbox("Duplicated email address");
                                return;
                              } else if (values.txtCc.trim().length === 0 || !valid) {
                                setErrorCcTextbox("Invalid email address");
                                return;
                              }
                              setCc([...cc, values.txtCc.trim()]);
                              setFieldValue("txtCc", "");
                              setErrorCcTextbox("");
                            });
                          }}
                          className="add-cc-button"
                        >
                          Add
                        </Button>
                      </div>
                      {errorCcTextbox && (
                        <span className="tool-inputs__error">{errorCcTextbox}</span>
                      )}
                      <div className="tool-inputs__cc-list">
                        {cc.map((c, index) => (
                          <Badge
                            color={getGraphColors[index]}
                            onRemove={() => onRemoveCc(c)}
                            key={c}
                            text={c}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="submit-section">
                    <Button type="submit" disabled={isSubmitting} loading={isSubmitting}>
                      Submit
                    </Button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        )}
        <SlideModal content={<PaymentDetail />} />
      </div>
    </CampaignAdminWrapper>
  ) : null;
};

const mapStateToProps = ({ currentCampaign, user, users }: RootState) => ({
  users,
  currentCampaign,
  user,
});

const connector = connect(mapStateToProps);

export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CampaignAdmin);
