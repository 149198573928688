import { IRegister } from "store/actions/user.actions";
import { ICampaign, ICampaignReportData, ILocation } from "store/states/campaigns.state";
import { IAdFormData } from "modules/Client/pages/UserCampaigns/types";
import { IMetric } from "../adform-metrices";
import { numberWithCommas } from "./number";
import { IValidFile } from "components/Dropzone";
import { BUDGET_LIST } from "constants/payment";
import { PAYMENT_METHOD_LIST } from "components/PaymentMethod";
import { IBUDGET_FORM } from "store/actions/payment.actions";
import { addDays } from "date-fns";

export type IPostCampaignData = {
  id?: string;
  bannerId?: number;
  name: string;
  description: string;
  goal: string;
  draft: boolean;
  // contentCategories: ICategoryItem;

  // includes: CATEGORY_KEY_VALUES_OBJECT;
  // excludes: CATEGORY_KEY_VALUES_OBJECT;

  ageGroups: Array<string>;
  genders: Array<string>;
  locations: Array<ILocation>;
  devices: Array<number>;
  os: Array<number>;
  browsers: Array<number>;

  title: string;
  landingPageUrl: string;
  thankyouPageUrl: string;
  images: Array<IValidFile>;
  imagesUrl: Array<string>;

  keywords: Array<string>;
  // payment

  card: {
    fullName: string;
    cardNumber: string;
    expirationDate: string;
    securityCode: string;
  };
  paymentMethod: string;
  paymentForm: IBUDGET_FORM;
};

export interface IPaymentForm {
  fullName: string;
  cardNumber: string;
  expirationDate: string;
  securityCode: string;
  budgetPerDay: number;
  startDate: Date;
  endDate: Date;
}

export const initialFormValues: IPostCampaignData = {
  name: "",
  description: "",
  goal: "",
  ageGroups: [],
  genders: [],
  locations: [],
  draft: true,
  // contentCategories: {},
  // includes: {},
  // excludes: {},
  devices: [],
  os: [],
  browsers: [],
  title: "",
  landingPageUrl: "",
  thankyouPageUrl: "",
  images: [] as Array<IValidFile>,
  imagesUrl: [] as Array<string>,

  keywords: [],

  card: {
    fullName: "",
    cardNumber: "",
    expirationDate: "",
    securityCode: "",
  },

  paymentMethod: PAYMENT_METHOD_LIST[0].key,
  paymentForm: {
    startDate: new Date(),
    endDate: addDays(new Date(), 30),
    budgetPerDay: BUDGET_LIST[0].value,
  },
  // budget calculation form values
};

export const BUILD_CAMPAIGN_INITIAL_FORM_VALUES: IPostCampaignData = {
  name: "",
  description: "",
  goal: "",
  ageGroups: [],
  genders: [],
  locations: [],
  draft: true,
  // contentCategories: {},
  // includes: {},
  // excludes: {},
  devices: [],
  os: [],
  browsers: [],
  title: "",
  landingPageUrl: "",
  thankyouPageUrl: "",
  images: [] as Array<IValidFile>,
  imagesUrl: [] as Array<string>,
  keywords: [],

  card: {
    fullName: "",
    cardNumber: "",
    expirationDate: "",
    securityCode: "",
  },

  paymentMethod: "credit card",
  paymentForm: {
    startDate: new Date(),
    endDate: addDays(new Date(), 30),
    budgetPerDay: BUDGET_LIST[0].value,
  },
  // budget calculation form values
};

const pattern =
  "^(https?:\\/\\/)?" + // protocol
  "((([a-zA-Z\\d]([a-zA-Z\\d-]{0,61}[a-zA-Z\\d])*\\.)+" + // sub-domain + domain name
  "[a-zA-Z]{2,13})" + // extension
  "|((\\d{1,3}\\.){3}\\d{1,3})" + // OR ip (v4) address
  "|localhost)" + // OR localhost
  "(\\:\\d{1,5})?" + // port
  "(\\/[a-zA-Z\\&\\d%_.~+-:@]*)*" + // path
  "(\\?[a-zA-Z\\&\\d%_.,~+-:@=;&]*)?" + // query string
  "(\\#[-a-zA-Z&\\d_]*)?$"; // fragment locator
export const regex = new RegExp(pattern);

export const buildCampaignDescription = [
  `Take the first step in building scalable and sustainable advertising campaigns 
without expensive and inconvenient processes.`,
  //   `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
  // has been the industry's standard dummy text ever since the 1500s, when an unknown`,
  `Build an accurate roadmap of your buyer's entire purchase path on a keyword-per-keyword basis with 
our patended "Keyword Roadmapping Algorithm", perfector for considered purchases.`,
  //   `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
  // has been the industry's standard dummy text ever since the 1500s, when an unknown`,
  //   `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
  // has been the industry's standard dummy text ever since the 1500s, when an unknown`,
  //   `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
  // has been the industry's standard dummy text ever since the 1500s, when an unknown`,
];
