import React from "react";
import { PaymentResultStyled, SpinnerWrapper } from "./styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle as check,
  faTimesCircle as failed,
  faHourglass as waiting,
  faFrown as error,
} from "@fortawesome/free-regular-svg-icons";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import { useDispatch } from "react-redux";
import { axiosForReverseAds } from "axiosConfig";
import { setCampaignId } from "store/actions/campaigns.actions";
import Spinner from "components/Spinner";
import Button from "components/Button";
import {
  ENUM_PAYMENT_STATUS,
  IPaymentDetail,
  PAYMENT_GATEWAY_TEXT,
} from "store/states/payments.state";

import { IBankInfo } from "store/states/admin.state";
import { handlingError } from "constants/utility/error";
import IBankInfoResponse from "models/bank-info-response.interface";
import IPaymentResultResponse from "models/payment-result-response.interface";

const PaymentResult = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const parsed = queryString.parse(useLocation().search);
  const [paymentResult, setPaymentResult] = React.useState<IPaymentResultResponse | null>(null);
  const [bankInfo, setBankInfo] = React.useState<IBankInfoResponse["data"] | null>(null);

  const handleRedirect = () => {
    //
  };

  React.useEffect(() => {
    const getPaymentResult = async () => {
      try {
        const result = await axiosForReverseAds.get<IPaymentResultResponse>(
          `/campaign/payment/${parsed.paymentId}`
        );

        if (result.data.campaignId) {
          setPaymentResult(result.data);
          dispatch(setCampaignId(result.data.campaignId));
        } else {
          navigate("/app/campaigns");
        }
      } catch (error: any) {
        navigate("/app/campaigns");
        handlingError(error);
      }
    };
    if (!paymentResult) {
      getPaymentResult();
    }
  }, [history, paymentResult, dispatch, parsed.paymentId]);

  const OrderComplete = () => {
    return (
      paymentResult && (
        <>
          <h3 className="title bold"> Order Completed Successfully!</h3>
          <p>Your order was successfully processed using</p>
          <h5 className="bold">{PAYMENT_GATEWAY_TEXT[paymentResult.paymentGateway]}</h5>
          {paymentResult.transactionId && (
            <p>
              <span>Transaction Number: </span>
              <span className="order-detail-id">{paymentResult.transactionId}</span>
            </p>
          )}

          <h5 className="bold">
            <span>Order Number: </span> <span className="order-detail-id">{paymentResult.id}</span>
          </h5>
          <h5 className="bold">
            <span>Campaign ID: </span>
            <span className="order-detail-id">{paymentResult.campaignId}</span>
          </h5>

          <p>Check your email for payment receipt.</p>

          <Button onClick={() => navigate(`/app/campaigns`)} className="btn btn-primary">
            Campaign Overview
          </Button>
        </>
      )
    );
  };

  const OrderFailed = () => {
    return (
      paymentResult && (
        <>
          <h3 className="error-message bold">Payment Failed</h3>
          <p>Error processing payment</p>

          <h5 className="bold">
            <span> Order Number: </span>
            <span className="order-detail-id">{paymentResult.id}</span>
          </h5>
          <h5 className="bold">
            <span>Campaign ID: </span>
            <span className="order-detail-id">{paymentResult.campaignId}</span>
          </h5>
          <Button onClick={() => navigate(`/app/campaign/${paymentResult.campaignId}/payment`)}>
            Try Again
          </Button>
        </>
      )
    );
  };

  const OrderPending = () => {
    return (
      paymentResult &&
      bankInfo && (
        <>
          <h3 className="title bold">Thank you for your order</h3>
          <p>
            Thank you for your order. It's on-hold until we confirm that payment has been received.
            In the meantime, here's a reminder of what your ordered:
          </p>

          <h5>
            <span>Order Number: </span>
            <span className="order-detail-id">{paymentResult.id}</span>
          </h5>
          <h5>
            <span>Campaign ID:</span>
            <span className="order-detail-id">{paymentResult.campaignId}</span>
          </h5>

          <p>Please make a bank transfer to the account below.</p>

          {bankInfo && (
            <>
              <h4 className="title bold">Our bank details</h4>
              <h4 className="bold">Vatic AI Co.,Ltd.</h4>
              <h5>Bank:</h5>
              <p>{bankInfo.bankName}</p>
              <h5>Account Number:</h5>
              <p>{bankInfo.accountNumber}</p>
              {bankInfo.routingNumber && (
                <>
                  <h5>Routing Number:</h5>
                  <p>{bankInfo.routingNumber}</p>
                </>
              )}
              {bankInfo.iban && (
                <>
                  <h5>IBAN:</h5>
                  <p>{bankInfo.iban}</p>
                </>
              )}
              {bankInfo.bic && (
                <>
                  <h5>BIC:</h5>
                  <p>{bankInfo.bic}</p>
                </>
              )}
            </>
          )}

          <Button onClick={() => navigate(`/app/campaigns`)} className="btn btn-primary">
            Campaign Overview
          </Button>
        </>
      )
    );
  };

  const renderPaymentStatus = () => {
    if (paymentResult) {
      switch (paymentResult.state) {
        case ENUM_PAYMENT_STATUS.APPROVED:
          return <OrderComplete />;
        case ENUM_PAYMENT_STATUS.FAILED:
          return <OrderFailed />;
        case ENUM_PAYMENT_STATUS.WAITING:
          return <OrderPending />;
        // case PAYMENT_STATUS_CONSTANTS.REQUESTED:
        //   return <h1>Requested</h1>;
        default:
          return null;
      }
    }
    return null;
  };

  const renderPaymentIcon = () => {
    if (paymentResult) {
      switch (paymentResult.state) {
        case ENUM_PAYMENT_STATUS.APPROVED:
          return check;
        case ENUM_PAYMENT_STATUS.FAILED:
          return failed;
        case ENUM_PAYMENT_STATUS.WAITING:
          return waiting;
        default:
          return error;
      }
    }
    return error;
  };

  const renderIconColor = () => {
    if (paymentResult) {
      switch (paymentResult.state) {
        case ENUM_PAYMENT_STATUS.APPROVED:
          return "rgb(118,180,70)";
        case ENUM_PAYMENT_STATUS.FAILED:
          return "crimson";
        case ENUM_PAYMENT_STATUS.WAITING:
          return "#ffffff";
        default:
          return "unset";
      }
    }
    return "unset";
  };

  if (paymentResult) {
    return (
      <PaymentResultStyled>
        <div className="payment-result-icon">
          <FontAwesomeIcon size="8x" color={renderIconColor()} icon={renderPaymentIcon()} />
        </div>
        <div className="payment-result-message">{renderPaymentStatus()}</div>
      </PaymentResultStyled>
    );
  } else {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );
  }
};

export default PaymentResult;
