import { AnyAction } from "redux";
import * as actions from "../action-types/admin.types";
import { paymentsListState } from "../states/admin.state";

const paymentList = (state = paymentsListState, action: AnyAction) => {
  switch (action.type) {
    // bank list

    case actions.GET_BANK_LIST_REQUEST:
      state = { ...state, loading: true };
      break;
    case actions.GET_BANK_LIST_SUCCESS:
      state = {
        ...state,
        bankInfo: action.banks,
        loading: false,
        error: null,
      };
      break;
    case actions.GET_BANK_LIST_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    default:
  }

  return state;
};

export default paymentList;
