import styled from "styled-components/macro";

export const Step3Styled = styled.div`
  padding-right: 20px;

  .field {
    margin-bottom: 30px;
  }

  .name-campaign {
    .build-campaign-title {
      color: ${({ theme }) => theme.contrast};
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
    }
  }
`;
