import { connect } from "react-redux";
import { RootState } from "store/store";
import { ConnectedProps } from "react-redux";

import React, { useEffect, useState } from "react";
import { Multiselect } from "multiselect-react-dropdown";
import Select from "react-select";
import { RingLoader } from "react-spinners";
import DeleteIcon from "@mui/icons-material/Delete";
import CreateIcon from "@mui/icons-material/Create";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button as Bt } from "reactstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useLocation } from "react-router-dom";

import Button from "components/Button";
import Input from "components/Input";
import { SearchIcon } from "components/Icons";
import StyledCompetitor, { buttonCss, customStyles, multiselectCss } from "./styled";
import { axiosForReverseAds } from "axiosConfig";

interface ICompareProps extends PropsFromRedux {}

const mapStateToProps = ({ popup }: RootState) => ({
  popup,
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const Competitor: React.FunctionComponent<ICompareProps> = () => {
  const loc = useLocation();

  const [location, setLocation] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState<
    Array<{ id: number; name: string; canonicalName: string }>
  >([]);
  const [loadingLocation, setLoadingLocation] = useState(true);

  const [language, setLanguage] = useState([{ value: 1, label: "" }]);
  const [selectedLanguage, setSelectedLanguage] = useState({ value: 1, label: "" });
  const [loadingLanguage, setLoadingLanguage] = useState(true);

  const [option, setOption] = useState(1);
  const [items, setItems] = useState<Array<{ keyword_idea: string; search_count: any }>>([]);

  const [errorMsg, setErrorMsg] = useState("");

  const [searchTerms, setSearch] = React.useState("");
  const [keywordTitle, setKeywordTitle] = useState("");

  const [openDeletePopup, setDeletePopup] = useState(false);
  const [indexDelete, setIndexDelete] = useState(-1);

  const [editableIndex, setEditableIndex] = useState(-1);

  const storeInfo = () => {
    localStorage.setItem("selectedLocation", JSON.stringify(selectedLocation));
    localStorage.setItem("selectedLanguage", JSON.stringify(selectedLanguage));
    localStorage.setItem("items", JSON.stringify(items));
    localStorage.setItem("keywordTitle", JSON.stringify(keywordTitle));
  };

  useEffect(() => {
    const loadStoredData = loc.state?.load;

    const fetchDataFromLocalStorage = () => {
      const storedItems = localStorage.getItem("items");
      const storedKeywordTitle = localStorage.getItem("keywordTitle");
      if (storedItems && storedKeywordTitle) {
        setItems(JSON.parse(storedItems));
        setKeywordTitle(JSON.parse(storedKeywordTitle));
        setOption(3);
      }
      const storedSelectedLoc = localStorage.getItem("selectedLocation");
      const storedSelectedLan = localStorage.getItem("selectedLanguage");
      if (storedSelectedLoc) {
        setSelectedLocation(JSON.parse(storedSelectedLoc));
      }
      if (storedSelectedLan) {
        setSelectedLanguage(JSON.parse(storedSelectedLan));
      }
    };

    if (loadStoredData == 2) {
      try {
        fetchDataFromLocalStorage();
      } catch {
        console.log("Previous data doesn't exist");
      }
    }
  }, []);

  const handleInputChangeLocation = (text: string) => {
    if (text == "") {
      return;
    }
    setLoadingLocation(true);
    const getlocationData = async () => {
      const response = await axiosForReverseAds.get("location/" + text);
      const resData = response.data;
      if (resData.data.length == 0) {
        setLocation([]);
      } else {
        setLocation(resData.data);
      }
      setLoadingLocation(false);
    };
    getlocationData();
  };

  const handleInputChangeLanguage = (text: string) => {
    if (text == "") {
      return;
    }
    setLoadingLanguage(true);
    const getlanguageData = async () => {
      const response = await axiosForReverseAds.get("language/" + text);
      const resData = response.data;
      if (!resData.data || resData.data.length == 0) {
        setLanguage([{ value: 1, label: "" }]);
      } else {
        setLanguage(resData.data);
      }
    };
    getlanguageData();
    setLoadingLanguage(false);
  };

  const setCurrentLanguage = (event: any) => {
    if (event) {
      setSelectedLanguage({ value: event["value"], label: event["label"] });
    }
  };

  const validateInput = () => {
    if (selectedLocation.length == 0) {
      setErrorMsg("Location Must be selected");
      return false;
    }

    if (selectedLanguage.label == "") {
      setErrorMsg("Language must be selected");
      return false;
    }

    if (!searchTerms) {
      setErrorMsg("You must enter search domain");
      return false;
    }

    setErrorMsg("");
    return true;
  };

  const getKeywordIdea = async () => {
    if (!validateInput()) {
      setOption(1);
      return;
    }
    setOption(2);
    const location_ids = selectedLocation.map((val: any) => val.id);
    const language_id = selectedLanguage.value;
    const keyword_phrase = searchTerms;
    const requestData = {
      location_ids: location_ids,
      language_id: language_id,
      page_url: keyword_phrase,
    };
    const response = await axiosForReverseAds.post("generate_key_word_ideas", requestData);
    const resData = response.data;
    setItems(resData.data);
    setOption(3);
    setKeywordTitle(searchTerms);
  };

  const destroy = () => {
    setOption(1);
    setLocation([]);
    setSelectedLocation([]);
    setLoadingLocation(true);
    setLanguage([{ value: 1, label: "" }]);
    setSelectedLanguage({ value: 1, label: "" });
    setLoadingLanguage(true);
    setItems([]);
    setErrorMsg("");
    setKeywordTitle("");
  };

  const editKeyword = (index: number) => {
    setEditableIndex(index);
  };

  const deleteKeyword = (index: any) => {
    setIndexDelete(index);
    setDeletePopup(true);
  };

  const cancelDelete = () => {
    setIndexDelete(-1);
    setDeletePopup(false);
  };

  const confirmDelete = () => {
    const newItems = items.filter((_, i) => i !== indexDelete);
    setItems(newItems);
    setIndexDelete(-1);
    setDeletePopup(false);
    toast.success("Keywrod deleted successfully!", { autoClose: 1000 });
  };

  const getKeywords = () => {
    const newItem = items.map((i) => i.keyword_idea);
    return newItem;
  };

  const getLocation = () => {
    const newLoc: string[] = selectedLocation.map((i) => {
      const splitArray = i["canonicalName"].split(",");
      return splitArray[splitArray.length - 1];
    });
    const uniqueLoc = newLoc.filter((value, index, array) => array.indexOf(value) === index);
    return uniqueLoc.join(", ");
  };

  const modifyVal = (index: number, e: any) => {
    const newItems = [...items];
    newItems[index].keyword_idea = e.target.value;
    setItems(newItems);
  };

  const addKeyword = () => {
    const l = items.length;
    const newItems = [...items];
    newItems.push({ keyword_idea: "", search_count: "" });
    setItems(newItems);
    setEditableIndex(l);
  };

  const commitChanges = (index: number) => {
    let newItems = [...items];
    if (items[index].keyword_idea === "") newItems = newItems.filter((_, i) => i != index);
    setItems(newItems);
    setEditableIndex(-1);
  };

  return (
    <StyledCompetitor>
      <div className="full">
        <div className="header">
          <span className="tool-title">Competitor</span>
          <span className="tool-description">
            {`Input direct competitor domain name to discover an accurate estimation of their
traffic, sources, keyword, and bounce rate.`}
          </span>
        </div>

        <div className="container">
          <div className="child-container child-container-1">
            <div className="child-container-location" data-testid="location">
              <Multiselect
                loading={loadingLocation}
                placeholder={"Search Location"}
                isObject={true}
                options={location}
                displayValue={"canonicalName"}
                onRemove={(event) => {
                  setSelectedLocation(event);
                  setLocation([]);
                  setLoadingLocation(true);
                }}
                onSelect={(event) => {
                  setSelectedLocation(event);
                  setLocation([]);
                  setLoadingLocation(true);
                }}
                onSearch={(event) => {
                  handleInputChangeLocation(event);
                }}
                selectedValueDecorator={(v, o) => {
                  return o["canonicalName"];
                }}
                style={multiselectCss}
              />
            </div>

            <div className="child-container-language" data-testid="language">
              <Select
                options={language}
                onChange={(event) => setCurrentLanguage(event)}
                onInputChange={(event) => {
                  handleInputChangeLanguage(event);
                }}
                styles={customStyles}
                placeholder={"Type Language"}
                className="language"
              />
            </div>

            <div className="search-bar" data-testid="search-keyword">
              <div className="keyword-input">
                <Input
                  height={50}
                  left={25}
                  placeholder="Search Domain"
                  noBorder
                  black
                  value={searchTerms}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)}
                />
                <div className="search-icon">
                  <SearchIcon />
                </div>
              </div>
            </div>

            <div className="child-container-submit" data-testid="submit-btn">
              <Button
                color="search-button"
                height={50}
                onClick={() => {
                  setOption(2);
                  getKeywordIdea();
                }}
                // disabled={!searchTerms}
                type="submit"
                style={buttonCss}
              >
                Submit
              </Button>
            </div>

            <div className="err-msg">{errorMsg}</div>
          </div>

          <div className="child-container-table">
            {option == 3 ? (
              <div>
                <div className="tool-title" style={{ padding: "20px", borderRight: "0px" }}>
                  <span>Suggestions For: "</span>
                  {keywordTitle}
                  <span>"</span>
                </div>

                <div className="padding-20">
                  <div className="child-container-table-data">
                    <table className="table-fill">
                      <thead>
                        <tr>
                          <th>Keyword</th>
                          <th>Volume</th>
                          <th>Edit</th>
                          <th>Delete</th>
                        </tr>
                      </thead>
                      <tbody className="table-body">
                        {items.map((item, index) => (
                          <tr key={index}>
                            {index == editableIndex ? (
                              <td className="td-edit">
                                <input
                                  type="text"
                                  value={item["keyword_idea"]}
                                  onChange={(event) => modifyVal(index, event)}
                                  onBlur={() => {
                                    commitChanges(index);
                                  }}
                                  onKeyDown={(event) => {
                                    event.key == "Enter" ? commitChanges(index) : console.log();
                                  }}
                                  className="edit-input"
                                  autoFocus
                                />
                              </td>
                            ) : (
                              <td
                                onClick={() => {
                                  setEditableIndex(index);
                                }}
                              >
                                {item["keyword_idea"]}
                              </td>
                            )}
                            <td>{item["search_count"]}</td>
                            <td>
                              <CreateIcon
                                style={{ cursor: "pointer" }}
                                fontSize="small"
                                onClick={() => editKeyword(index)}
                              />
                            </td>
                            <td>
                              <DeleteIcon
                                style={{ cursor: "pointer" }}
                                fontSize="small"
                                onClick={() => deleteKeyword(index)}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>

                    <div>
                      <Modal isOpen={openDeletePopup} toggle={cancelDelete} centered={true}>
                        <ModalHeader toggle={cancelDelete} className="popup-head">
                          Delete
                        </ModalHeader>
                        <ModalBody className="popup-body">
                          Are you sure you want to delete "
                          {indexDelete > -1 ? items[indexDelete].keyword_idea : ""}" keyword?
                        </ModalBody>
                        <ModalFooter>
                          <Bt color="primary" onClick={confirmDelete}>
                            Confirm
                          </Bt>{" "}
                          <Bt color="secondary" onClick={cancelDelete}>
                            Cancel
                          </Bt>
                        </ModalFooter>
                      </Modal>
                    </div>
                  </div>
                </div>

                <div className="next-container">
                  <Bt className="add-keyword-btn" onClick={addKeyword} color="secondary">
                    Add Keyword
                  </Bt>
                  {/* <Bt className="next-btn" onClick={nextClick}>Next</Bt> */}
                  <Bt className="next-btn" onClick={storeInfo}>
                    <Link
                      to="/app/keywords/save"
                      state={{
                        keywords: getKeywords(),
                        location: getLocation(),
                        language: selectedLanguage.label,
                        from: "competitor",
                      }}
                      className="next-link"
                    >
                      Next
                    </Link>
                  </Bt>
                </div>
              </div>
            ) : option == 2 ? (
              <div className="ring-loader">
                <RingLoader color="#36D7B7" loading={true} />
                <p>Loading...</p>
              </div>
            ) : (
              <div>
                <div className="empty-competitor-img" />
              </div>
            )}
          </div>
        </div>

        <div className="footer"></div>
      </div>
    </StyledCompetitor>
  );
};

export default connector(Competitor);
