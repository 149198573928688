import { IDataForSEO } from "constants/utility/keyword";
import { IErrorMessage } from "../../services/types";
import { ISavedKeyword, ISavedKeywordList } from "store/states/keywords-list.state";

// get

export const GET_CURRENT_SAVED_KEYWORDS_LIST =
  "savedKeywords-actions/GET_CURRENT_SAVED_KEYWORDS_LIST";
export interface GetCurrentSavedKeywordsListAction {
  type: typeof GET_CURRENT_SAVED_KEYWORDS_LIST;
  payload: string;
}

export const GET_CURRENT_SAVED_KEYWORDS_LIST_REQUEST =
  "savedKeywords-actions/GET_CURRENT_SAVED_KEYWORDS_LIST_REQUEST";
export interface GetCurrentSavedKeywordsListRequestAction {
  type: typeof GET_CURRENT_SAVED_KEYWORDS_LIST_REQUEST;
  payload: string;
}

export const GET_CURRENT_SAVED_KEYWORDS_LIST_SUCCESS =
  "savedKeywords-actions/GET_CURRENT_SAVED_KEYWORDS_LIST_SUCCESS";
export interface GetCurrentSavedKeywordsListSuccessAction {
  type: typeof GET_CURRENT_SAVED_KEYWORDS_LIST_SUCCESS;
  payload: ISavedKeywordList;
}

export const GET_CURRENT_SAVED_KEYWORDS_LIST_FAILURE =
  "savedKeywords-actions/GET_CURRENT_SAVED_KEYWORDS_LIST_FAILURE";
export interface GetCurrentSavedKeywordsListFailureAction {
  type: typeof GET_CURRENT_SAVED_KEYWORDS_LIST_FAILURE;
  error: IErrorMessage;
}

// edit

export const EDIT_CURRENT_SAVED_KEYWORDS_LIST =
  "savedKeywords-actions/EDIT_CURRENT_SAVED_KEYWORDS_LIST";
export interface EditCurrentSavedKeywordsListAction {
  type: typeof EDIT_CURRENT_SAVED_KEYWORDS_LIST;
  payload: {
    id: string;
    name: string;
  };
}

export const EDIT_CURRENT_SAVED_KEYWORDS_LIST_REQUEST =
  "savedKeywords-actions/EDIT_CURRENT_SAVED_KEYWORDS_LIST_REQUEST";
export interface EditCurrentSavedKeywordsListRequestAction {
  type: typeof EDIT_CURRENT_SAVED_KEYWORDS_LIST_REQUEST;
}

export const EDIT_CURRENT_SAVED_KEYWORDS_LIST_SUCCESS =
  "savedKeywords-actions/EDIT_CURRENT_SAVED_KEYWORDS_LIST_SUCCESS";
export interface EditCurrentSavedKeywordsListSuccessAction {
  type: typeof EDIT_CURRENT_SAVED_KEYWORDS_LIST_SUCCESS;
  payload: { id: string; name: string; keywords: Array<{ id: string; keyword: string }> };
}

export const EDIT_CURRENT_SAVED_KEYWORDS_LIST_FAILURE =
  "savedKeywords-actions/EDIT_CURRENT_SAVED_KEYWORDS_LIST_FAILURE";
export interface EditCurrentSavedKeywordsListFailureAction {
  type: typeof EDIT_CURRENT_SAVED_KEYWORDS_LIST_FAILURE;
  error: IErrorMessage;
}
