import styled, { css } from "styled-components/macro";

export const UploadContainer = styled.div<{ hasUploaded: boolean }>`
  width: 100%;

  .images-preview {
    width: 100%;
    margin-top: 20px;
  }

  ${({ hasUploaded }) =>
    hasUploaded
      ? css`
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
        `
      : css`
          display: flex;
          justify-content: center;
          align-items: center;
          height: 300px;
          flex-direction: column;
        `}

  .info-title {
    font-weight: 600;
  }

  .images-preview {
    .image-list-wrapper {
      margin-top: 20px;

      .image-list {
        padding: 0;
        margin: 0;

        .image-item {
          padding: 10px;
          display: flex;

          .banner-wrapper {
            margin-left: 20px;
            width: 100%;
            height: 100%;

            .size-wrapper {
              margin-top: 20px;

              .size-label {
                background-color: ${({ theme }) => theme.color.gold};
                padding: 3px 14px;
                color: #000000;
                font-size: 10px;
                font-weight: bold;
              }
            }
          }

          .select-all {
            font-size: 14px;
            margin-left: 10px;
          }
        }
      }
    }
  }

  .recommended-sizes {
    margin-top: 20px;
    padding-left: 30px;
    border-left: 1px solid ${({ theme }) => theme.dark};

    .size-list {
      display: flex;
      flex-direction: column;
      gap: 5px;
      color: ${({ theme }) => theme.contrast};
      font-size: 14px;
    }
  }

  .creative-requirements-full {
    margin-top: 20px;
    padding-left: 30px;
    border-left: 1px solid ${({ theme }) => theme.dark};

    .file-info {
      color: ${({ theme }) => theme.contrast};
      font-size: 14px;

      ul {
        padding-left: 15px;
      }

      .file-type {
        font-weight: 600;
        color: ${({ theme }) => theme.secondaryHighlight};
      }
    }
  }
`;

export const Step4Styled = styled.div`
  padding-right: 20px;
  color: ${({ theme }) => theme.contrast};

  .creative-uploader {
    display: grid;
    grid-template-rows: 20px 260px;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px 15px;
    margin-top: 20px;

    .detail-title {
      grid-row: 1/2;
      font-size: 14px;
    }

    .creative-card {
      grid-row: 2/3;
      background-color: ${({ theme }) => theme.dark};
      border-radius: 10px;
      padding: 15px;
    }

    .creative-card.add {
      .dropzone-wrapper {
        margin-top: 20px;
      }
    }

    .creative-card.landing {
      display: flex;
      flex-direction: column;
      gap: 20px;
      justify-content: center;
    }

    .landing {
      grid-column: 1/2;
    }

    .add {
      grid-column: 2/3;

      .requirement {
        font-size: 10px;
        margin-top: 20px;

        .requirement-title {
          font-size: 12px;
          text-align: center;
        }

        .requirement-text {
          padding-left: 20px;
          margin-top: 10px;
        }
      }
    }

    .request {
      grid-column: 3/4;

      .request-body {
        display: flex;
        flex-direction: column;
        max-width: 200px;
        gap: 10px;
        justify-content: center;
        margin: 0 auto;

        padding-top: 5px;

        .request-action {
          display: flex;
          flex-direction: column;
          text-align: center;
          gap: 10px;
          margin-bottom: 15px;

          .creative-req-btn {
            cursor: pointer;
            width: 200px;
            padding: 6px 20px;
            margin: auto;
            background-color: ${({ theme }) => theme.cpc};
            border-radius: 8px;
            color: ${({ theme }) => theme.alertModalBackground};
            transition: 0.3s;
            font-size: 14px;
            font-weight: 600;

            &:hover {
              opacity: 0.75;
            }
          }

          .info-btn {
            cursor: pointer;
            width: 200px;
            padding: 6px 20px;
            margin: auto;
            background-color: ${({ theme }) => theme.modalBackground};
            border-radius: 8px;
            color: ${({ theme }) => theme.contrast};
            border: 1px solid ${({ theme }) => theme.checkboxBorder};
            transition: 0.3s;
            font-size: 14px;
            font-weight: 600;

            &:hover {
              opacity: 0.75;
            }
          }

          .light-bulb {
            display: flex;
            height: 70px;
            justify-content: center;
            align-items: center;

            .request-icon {
              width: 60px;
              height: 60px;
            }
          }
        }
      }

      .request-info {
        font-size: 10px;

        .request-text {
          text-align: center;
          white-space: pre;
        }
      }
    }
  }

  .creative-section {
    .creative-input-group {
      display: flex;
      gap: 10px;
    }
  }

  .name-campaign {
    .build-campaign-title {
      color: ${({ theme }) => theme.contrast};
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
    }
  }

  .creative-requirements-short {
    display: grid;
    font-size: 14px;
    grid-template-columns: 200px 250px 250px 1fr;
    background-color: ${({ theme }) => theme.creativeReq};
    border-radius: 10px;
    padding: 20px 0;
    font-size: 14px;
    align-items: center;
    width: 100%;
    margin-top: 40px;
    color: ${({ theme }) => theme.contrast};

    .title {
      text-align: center;
    }

    .file-requirement {
      padding: 10px 20px;
      border-left: 1px solid gray;
    }
  }
`;
