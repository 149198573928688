import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router";

import { axiosForReverseAds } from "axiosConfig";
import { Button, Modal } from "react-bootstrap";
import StyledCampaign, { modalStyle } from "./styled";
import Input from "components/Input";
import { toast } from "react-toastify";
import downloadImg from "assets/images/document-download-icon.svg";

const Campaign = () => {
  const navigate = useNavigate();
  const loc = useLocation();
  const campaign_name = loc.state?.name;
  const { campaign_id } = useParams();
  const LIST_SIZE = 5;

  const [audienceList, setAudiencelList] = useState<
    Array<{
      audience_id: number;
      audience_name: string;
      signal_ids: string;
      signals_name: Array<{ id: number; name: string; location: string; language: string }>;
    }>
  >([]);

  const [showPopup, setShowPopup] = useState(false);
  const [audienceName, setAudienceName] = useState("");

  useEffect(() => {
    const fetchSignals = async () => {
      const storedUser_id = localStorage.getItem("user_id");
      if (storedUser_id) {
        const user_id = parseInt(storedUser_id);
        const response = await axiosForReverseAds.get("get_audiences", {
          params: { campaign_id: campaign_id },
        });
        setAudiencelList(response.data.data);

        setItems(response.data.data.slice(0, LIST_SIZE));
        if (response.data.data.length < 3) {
          setBtnRange({ min: 1, max: response.data.data.length });
        }
      } else {
        setAudiencelList([]);
      }
    };
    fetchSignals();
  }, [showPopup]);

  const createAudience = () => {
    const storeAudName = async () => {
      if (audienceName === "") {
        toast.error("You must enter audience name", { autoClose: 1000 });
        return;
      }
      const storedUser_id = localStorage.getItem("user_id");
      if (storedUser_id) {
        const user_id = parseInt(storedUser_id);
        const response = await axiosForReverseAds.post("add_audience", {
          user_id: user_id,
          campaign_id: campaign_id,
          audience_name: audienceName,
        });
        setAudienceName("");
        if (response.data.status === "success") {
          toast.success("Audience created successfully!", { autoClose: 1000 });
        } else {
          toast.error("Unable to create audience", { autoClose: 1000 });
        }
      } else {
        toast.error("Unable to create audience", { autoClose: 1000 });
      }
      setShowPopup(false);
    };
    storeAudName();
  };

  const [items, setItems] = useState<
    Array<{
      audience_id: number;
      audience_name: string;
      signal_ids: string;
      signals_name: Array<{ id: number; name: string; location: string; language: string }>;
    }>
  >([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [btnRange, setBtnRange] = useState({ min: 1, max: 3 });

  const paginate = (startIndex: number) => {
    setItems(audienceList.slice(startIndex, startIndex + LIST_SIZE));
  };

  const showBtn = (direction: string) => {
    if (direction === "next") {
      if (btnRange.max === Math.ceil(audienceList.length / LIST_SIZE)) {
        return;
      } else if (btnRange.max + 3 > Math.ceil(audienceList.length / LIST_SIZE)) {
        setBtnRange({ min: btnRange.max + 1, max: Math.ceil(audienceList.length / LIST_SIZE) });
      } else {
        setBtnRange({ min: btnRange.max + 1, max: btnRange.max + 3 });
      }
    } else {
      if (btnRange.min === 1) {
        return;
      } else if (btnRange.min - 3 < 1) {
        setBtnRange({ min: 1, max: 3 });
      } else {
        setBtnRange({ min: btnRange.min - 3, max: btnRange.min - 1 });
      }
    }
  };

  const handleDownload = async (audience_name: string) => {
    const response = await axiosForReverseAds.get("download_audience");
    if (response.data.status === "success") {
      const content = response.data.data;
      const blob = new Blob([content.join("\n")], { type: "text/plain" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = audience_name + ".txt";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <StyledCampaign>
      <div className="container-full">
        <div className="container-head">Campaign</div>
        <div className="campaign-h1">
          <span>Campaign Name</span>&nbsp;&nbsp;<span className="campaign-h2">{campaign_name}</span>
        </div>

        <div className="btn-container">
          <Button className="signals-btn" onClick={() => setShowPopup(true)}>
            Create Audience
          </Button>
        </div>

        <div>
          <Modal
            show={showPopup}
            onHide={() => {
              setShowPopup(false);
            }}
            centered={true}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <div>Create Audience</div>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body style={modalStyle}>
              <div>
                <div>Audience Name</div>
                <Input
                  onChange={(event: any) => {
                    setAudienceName(event.target.value);
                  }}
                  height={50}
                  left={25}
                  placeholder="Campaign Name"
                  black
                ></Input>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => {
                  setShowPopup(false);
                }}
              >
                Cancel
              </Button>
              <Button variant="primary" onClick={createAudience}>
                Create
              </Button>
            </Modal.Footer>
          </Modal>
        </div>

        <div className="container-body">
          <table className="table">
            <thead className="thead">
              <tr>
                <th className="th1">Audience Name</th>
                <th className="th1">Signals</th>
                <th className="th1">Download Audience</th>
              </tr>
            </thead>

            <tbody>
              {items?.map((item, index) => (
                <tr key={index} className="tr">
                  <td
                    onClick={() => {
                      navigate("/app/keywords/audience/" + item.audience_id, {
                        state: { name: item.audience_name },
                      });
                    }}
                  >
                    <span className="link">{item.audience_name}</span>
                  </td>
                  <td>
                    {item.signals_name.map((i, ind) => (
                      <span key={ind}>
                        <span
                          onClick={() => {
                            navigate("/app/keywords/signal/" + i.id, {
                              state: { name: i.name, language: i.language, location: i.location },
                            });
                          }}
                          className="signal-link"
                        >
                          {i.name}
                        </span>
                        <span>{ind == item.signals_name.length - 1 ? " " : ", "}</span>
                      </span>
                    ))}
                  </td>
                  <td className="td-img">
                    <img
                      src={downloadImg}
                      alt="Download"
                      className="donwload-img"
                      onClick={() => handleDownload(item.audience_name)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="pagination-container">
          <div
            className={btnRange.min === 1 ? "pagination-option disable" : "pagination-option"}
            onClick={() => {
              showBtn("prev");
            }}
          >
            &laquo;
          </div>
          {(() => {
            const jsxElements = [];
            for (let i = 0, j = 1; i < audienceList.length; i += LIST_SIZE, j++) {
              jsxElements.push(
                <div
                  key={i}
                  className={
                    j >= btnRange.min && j <= btnRange.max
                      ? pageNumber === j
                        ? "pagination-active"
                        : "pagination-option"
                      : "hide"
                  }
                  onClick={() => {
                    paginate(i);
                    setPageNumber(j);
                  }}
                >
                  {j}
                </div>
              );
            }
            return jsxElements;
          })()}
          <div
            className={
              btnRange.max * LIST_SIZE >= audienceList.length
                ? "pagination-option disable"
                : "pagination-option"
            }
            onClick={() => {
              showBtn("next");
            }}
          >
            &raquo;
          </div>
        </div>
      </div>
    </StyledCampaign>
  );
};

export default Campaign;
