import { IErrorMessage } from "../../services/types";
import * as actions from "../action-types/suggested.types";

// save

export function saveSuggested(payload: string): actions.SaveSuggestedAction {
  return {
    type: actions.SAVE_SUGGESTED,
    payload,
  };
}

export function saveSuggestedRequest(): actions.SaveSuggestedRequestAction {
  return {
    type: actions.SAVE_SUGGESTED_REQUEST,
  };
}

export function saveSuggestedSuccess(payload: {
  id: number;
  value: string;
}): actions.SaveSuggestedSuccessAction {
  return {
    type: actions.SAVE_SUGGESTED_SUCCESS,
    payload,
  };
}

export function saveSuggestedFailure(error: IErrorMessage): actions.SaveSuggestedFailureAction {
  return {
    type: actions.SAVE_SUGGESTED_FAILURE,
    error,
  };
}

// get

export function getSuggested(): actions.GetSuggestedAction {
  return {
    type: actions.GET_SUGGESTED,
  };
}

export function getSuggestedRequest(): actions.GetSuggestedRequestAction {
  return {
    type: actions.GET_SUGGESTED_REQUEST,
  };
}

export function getSuggestedSuccess(payload: any): actions.GetSuggestedSuccessAction {
  return {
    type: actions.GET_SUGGESTED_SUCCESS,
    payload,
  };
}

export function getSuggestedFailure(error: IErrorMessage): actions.GetSuggestedFailureAction {
  return {
    type: actions.GET_SUGGESTED_FAILURE,
    error,
  };
}

// get

export function deleteSuggested(payload: Array<number>): actions.DeleteSuggestedAction {
  return {
    type: actions.DELETE_SUGGESTED,
    payload,
  };
}

export function deleteSuggestedRequest(): actions.DeleteSuggestedRequestAction {
  return {
    type: actions.DELETE_SUGGESTED_REQUEST,
  };
}

export function deleteSuggestedSuccess(payload: any): actions.DeleteSuggestedSuccessAction {
  return {
    type: actions.DELETE_SUGGESTED_SUCCESS,
    payload,
  };
}

export function deleteSuggestedFailure(error: IErrorMessage): actions.DeleteSuggestedFailureAction {
  return {
    type: actions.DELETE_SUGGESTED_FAILURE,
    error,
  };
}
