import styled from "styled-components";

export const NotificationSettingsStyled = styled.div`
  .notification-settings-wrapper {
    display: flex;
    margin-top: 20px;
    font-size: 12px;
    color: ${({ theme }) => theme.text};
  }

  .notification-table {
    width: 50%;

    .table-header {
      font-size: 14px;
      padding: 30px 0;
      height: 50px;
      height: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid ${({ theme }) => theme.dark};

      > div {
        &:nth-child(1) {
          width: 50%;
        }
        &:nth-child(2) {
          width: 50%;
          text-align: center;
        }
      }
    }

    .table-body {
      font-size: 12px;

      display: flex;
      flex-direction: column;
      gap: 10px;

      .noti-list {
        display: flex;
        justify-content: space-between;
        padding: 10px 0;
        align-items: center;
        border-bottom: 1px solid ${({ theme }) => theme.dark};

        > div {
          &:nth-child(1) {
            width: 50%;
          }
          &:nth-child(2) {
            width: 50%;
            display: flex;
            justify-content: center;
          }
        }
      }
    }
  }
`;
