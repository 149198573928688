import styled from "styled-components/macro";

export const Step5Styled = styled.div`
  padding-right: 20px;
  font-size: 12px;

  .name-campaign {
    .build-campaign-title {
      color: ${({ theme }) => theme.contrast};
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
    }
  }

  .campaign-preview-wrapper {
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 200px min-content 1fr;
    margin-top: 50px;
    color: ${({ theme }) => theme.previewTextColor};

    .title {
      color: ${({ theme }) => theme.cpc};
      font-weight: bold;
      margin-bottom: 10px;
    }

    .basic-info {
      font-size: 16px;
      font-weight: bold;
    }

    .step1 {
      grid-column: 1/2;
      grid-row: 1/2;

      p {
        margin-bottom: 5px;
      }
    }

    .step3 {
      grid-column: 2/3;
      grid-row: 1/3;

      .preview-keywords {
        margin-top: 20px;
        min-height: 200px;
        color: rgb(180, 180, 180);

        .table-row {
          display: grid;
          grid-template-columns: 70px 1fr 45px 45px;
          padding: 5px 0;
          /* color: ${({ theme }) => theme.contrast}; */

          border-bottom: 1px solid ${({ theme }) => theme.lightBorder};

          .searches {
            text-align: right;
          }

          .g-cpc {
            text-align: center;
          }

          .rad-cpc {
            text-align: center;
          }

          &:last-child {
            border-bottom: none;
          }

          > span {
            &:nth-child(4) {
              color: ${({ theme }) => theme.cpc};
            }
          }

          &:first-child {
            color: ${({ theme }) => theme.contrast};

            .g-logo {
              width: 12px;
              height: 12px;
              margin-left: 5px;
            }

            .rad-logo {
              width: 12px;
              height: 12px;
              margin-left: 5px;

              .icon-svg {
                fill: ${({ theme }) => theme.cpc};
              }
            }

            > span {
              color: ${({ theme }) => theme.contrast};
            }
          }

          &:last-child {
            color: ${({ theme }) => theme.secondary100};

            > span {
              color: ${({ theme }) => theme.secondary100};
            }
          }

          > span {
            padding: 0 5px;

            &:nth-child(1) {
              text-align: left;
            }
            &:nth-child(2) {
              text-align: right;
            }
            &:nth-child(3) {
              text-align: center;
            }
            &:nth-child(4) {
              text-align: center;
            }
          }
        }
      }
    }

    .step2 {
      grid-column: 1/2;
      grid-row: 2/3;
    }

    .step4 {
      grid-column: 3/4;
      grid-row: 1/4;

      .banner-info {
        .creative-name {
          font-weight: bold;
          color: ${({ theme }) => theme.color.gold};
        }

        .url {
          color: ${({ theme }) => theme.contrast};
        }

        > p {
          margin-bottom: 5px;
        }
      }

      .image-list-wrapper {
        margin-top: 20px;

        .image-list {
          padding: 0;
          margin: 0;

          .image-item {
            padding: 10px;
            display: flex;

            .banner-wrapper {
              margin-left: 20px;
              width: 100%;
              height: 100%;

              .size-wrapper {
                margin-top: 20px;

                .size-label {
                  background-color: ${({ theme }) => theme.color.gold};
                  padding: 3px 14px;
                  color: #000000;
                  font-size: 10px;
                  font-weight: bold;
                }
              }
            }

            .select-all {
              font-size: 14px;
              margin-left: 10px;
            }
          }
        }
      }
    }

    .detail-section {
      background-color: ${({ theme }) => theme.reviewBackground};
      border-radius: 10px;
      padding: 15px 20px;
      position: relative;

      .edit {
        position: absolute;
        top: 15px;
        right: 15px;
        cursor: pointer;

        > img {
          width: 15px;
          height: 15px;
          filter: ${({ theme }) => theme.invertIcon};
        }
      }
    }
  }
`;
