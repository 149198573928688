export interface ISettingsState {
  language: "en" | "th";
  dateFormat: "dd/mm/yyyy" | "mm/dd/yyyy" | "yyyy/mm/dd";
}

const settingsState = {
  language: { name: "English", value: "en" },
  dateFormat: "dd/mm/yyyy",
};

export default settingsState;
