import styled from "styled-components/macro";

export const ForgetPasswordStyled = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  .wrapper {
    margin: 0 auto;

    width: 450px;
  }
  .input-wrapper {
    margin-bottom: 20px;
  }
`;
