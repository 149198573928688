export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";
export const CHANGE_DATE_FORMAT = "CHANGE_DATE_FORMAT";
export const CHANGE_DARK_MODE = "CHANGE_DARK_MODE";
export const CLOSE_SLIDE_MODAL = "CLOSE_SLIDE_MODAL";

export const changeLanguage = (payload: { name: string; value: "en" | "th" }) => {
  return {
    type: CHANGE_LANGUAGE,
    payload,
  };
};

export const changeDateFormat = (payload: "mm/dd/yyyy" | "dd/mm/yyyy" | "yyyy/mm/dd") => {
  return {
    type: CHANGE_DATE_FORMAT,
    payload,
  };
};
