import Button from "components/Button";
import React from "react";
import { Link } from "react-router-dom";
import { VerifiedSuccessStyled } from "./styled";
import * as AUTH_ROUTE from "modules/Auth/routes";

const VerifiedSuccess = () => {
  return (
    <VerifiedSuccessStyled>
      <p>Email verified successfuly!</p>
      <p>Your account has been successfully verified.</p>
      <div className="forget-password-button">
        <span className="back-button">← Back to </span>
        <Link to={`../${AUTH_ROUTE.LOGIN}`}>{` Log in`}</Link>
      </div>
    </VerifiedSuccessStyled>
  );
};

export default VerifiedSuccess;
