import { AnyAction } from "redux";
import * as POPUP_ACTIONS from "../actions/popup.actions";
import popupState from "../states/popup.state";

const popupReducer = (state = popupState, action: AnyAction) => {
  switch (action.type) {
    case POPUP_ACTIONS.SHOW_ADVERTISER_FORM_POPUP:
      state = {
        ...state,
        showAdvertiserForm: {
          show: true,
        },
      };
      break;
    case POPUP_ACTIONS.HIDE_ADVERTISER_FORM_POPUP:
      state = {
        ...state,
        showAdvertiserForm: {
          show: false,
        },
      };
      break;

    case POPUP_ACTIONS.SHOW_BETA_ALERT:
      state = {
        ...state,
        showBetaAlert: {
          show: true,
        },
      };
      break;
    case POPUP_ACTIONS.HIDE_BETA_ALERT:
      state = {
        ...state,
        showBetaAlert: {
          show: false,
        },
      };
      break;

    case POPUP_ACTIONS.SHOW_CAMPAIGN_DETAIL_POPUP:
      state = {
        ...state,
        showCampaignDetailPopup: {
          show: true,
          id: action.payload,
        },
      };
      break;

    case POPUP_ACTIONS.HIDE_CAMPAIGN_DETAIL_POPUP:
      state = {
        ...state,
        showCampaignDetailPopup: {
          show: false,
          id: null,
        },
      };
      break;

    case POPUP_ACTIONS.SHOW_FORGET_PASSWORD_POPUP:
      state = {
        ...state,
        showForgetPasswordPopup: {
          show: true,
        },
      };
      break;

    case POPUP_ACTIONS.HIDE_FORGET_PASSWORD_POPUP:
      state = {
        ...state,
        showForgetPasswordPopup: {
          show: false,
        },
      };
      break;

    case POPUP_ACTIONS.SHOW_CHANGE_PASSWORD_POPUP:
      state = {
        ...state,
        showChangePasswordPopup: {
          show: true,
        },
      };
      break;

    case POPUP_ACTIONS.HIDE_CHANGE_PASSWORD_POPUP:
      state = {
        ...state,
        showChangePasswordPopup: {
          show: false,
        },
      };
      break;

    case POPUP_ACTIONS.SHOW_CONFIRM_POPUP:
      state = {
        ...state,
        showConfirmPopup: {
          show: true,
          ...action.payload,
        },
      };
      break;

    case POPUP_ACTIONS.HIDE_CONFIRM_POPUP:
      state = {
        ...state,
        showConfirmPopup: {
          show: false,
          action: null,
          title: "",
          confirmText: "",
        },
      };
      break;

    case POPUP_ACTIONS.SHOW_EXPORT_POPUP:
      state = {
        ...state,
        showExportPopup: {
          show: true,
          type: action.payload.type,
          data:
            action.payload.type === "keywords"
              ? {
                  keywords: action.payload.data || [],
                }
              : {
                  campaign: action.payload.data,
                  keywords: [],
                },
        },
      };
      break;

    case POPUP_ACTIONS.HIDE_EXPORT_POPUP:
      state = {
        ...state,
        showExportPopup: {
          show: false,
          type: "keywords",
          data: {
            keywords: [],
          },
        },
      };
      break;

    case POPUP_ACTIONS.HIDE_COMPARE_POPUP:
      state = {
        ...state,
        showComparePopup: {
          ...state.showComparePopup,
          show: false,
          // keywords: [],
        },
      };

      break;

    case POPUP_ACTIONS.SHOW_COMPARE_POPUP:
      state = {
        ...state,
        showComparePopup: {
          show: true,
          keywords: action.payload || [],
        },
      };
      break;

    case POPUP_ACTIONS.HIDE_BUILD_CAMPAIGN:
      state = { ...state, showBuildCampaign: { show: false, id: null } };
      break;
    case POPUP_ACTIONS.SHOW_BUILD_CAMPAIGN:
      state = {
        ...state,
        showBuildCampaign: {
          show: true,
          id: action.payload,
        },
      };
      break;

    case POPUP_ACTIONS.HIDE_SUGGESTED_POPUP:
      state = {
        ...state,
        showSuggestedPopup: {
          show: false,
          keyword: "",
        },
      };
      break;

    case POPUP_ACTIONS.SHOW_SUGGESTED_POPUP:
      state = {
        ...state,
        showSuggestedPopup: {
          show: true,
          keyword: action.payload,
        },
      };
      break;

    case POPUP_ACTIONS.HIDE_COMPETITOR_POPUP:
      state = {
        ...state,
        showCompetitorPopup: {
          show: false,
          website: "",
        },
      };
      break;

    case POPUP_ACTIONS.SHOW_COMPETITOR_POPUP:
      state = {
        ...state,
        showCompetitorPopup: {
          show: true,
          website: action.payload,
        },
      };
      break;

    case POPUP_ACTIONS.SHOW_BANNER_CONTENT_POPUP:
      state = {
        ...state,
        showBannerContent: {
          id: action.payload,
        },
      };
      break;

    case POPUP_ACTIONS.HIDE_BANNER_CONTENT_POPUP:
      state = { ...state, showBannerContent: null };
      break;

    case POPUP_ACTIONS.SHOW_CREATIVE_FORM_POPUP:
      state = { ...state, showCreativeForm: true };
      break;

    case POPUP_ACTIONS.SHOW_IMPORT_KEYWORDS_FORM_POPUP:
      state = {
        ...state,
        showImportKeywords: {
          show: true,
          keywords: action.payload,
        },
      };
      break;

    case POPUP_ACTIONS.HIDE_IMPORT_KEYWORDS_FORM_POPUP:
      state = {
        ...state,
        showImportKeywords: {
          show: false,
          keywords: [],
        },
      };
      break;

    case POPUP_ACTIONS.HIDE_CREATIVE_FORM_POPUP:
      state = { ...state, showCreativeForm: false };
      break;

    case POPUP_ACTIONS.SHOW_EDIT_CAMPAIGN_POPUP:
      state = {
        ...state,
        showEditCampaign: {
          show: true,
          step: action.payload,
        },
      };
      break;

    case POPUP_ACTIONS.HIDE_EDIT_CAMPAIGN_POPUP:
      state = {
        ...state,
        showEditCampaign: {
          show: false,
          step: null,
        },
      };
      break;

    case POPUP_ACTIONS.SHOW_INTRO_POPUP:
      state = { ...state, showIntroPopup: true };
      break;

    case POPUP_ACTIONS.HIDE_INTRO_POPUP:
      state = { ...state, showIntroPopup: false };
      break;

    case POPUP_ACTIONS.SHOW_SUBSCRIPTION_REQUIRED_POPUP:
      state = { ...state, showSubscriptionRequiredPopup: true };
      break;

    case POPUP_ACTIONS.HIDE_SUBSCRIPTION_REQUIRED_POPUP:
      state = { ...state, showSubscriptionRequiredPopup: false };
      break;

    case POPUP_ACTIONS.SHOW_CAMPAIGN_POPUP:
      state = { ...state, showCampaignPopup: true };
      break;

    case POPUP_ACTIONS.HIDE_CAMPAIGN_POPUP:
      state = { ...state, showCampaignPopup: false };
      break;
    case POPUP_ACTIONS.SHOW_KEYWORD_LIST_POPUP:
      state = {
        ...state,
        showKeywordListPopup: true,
        values: action.payload,
      };
      break;

    case POPUP_ACTIONS.HIDE_KEYWORD_LIST_POPUP:
      state = { ...state, showKeywordListPopup: false };
      break;

    case POPUP_ACTIONS.SHOW_NEW_CAMPAIGN_POPUP:
      state = { ...state, showNewCampaignPopup: true, campaign: action.payload };
      break;

    case POPUP_ACTIONS.HIDE_NEW_CAMPAIGN_POPUP:
      state = { ...state, showNewCampaignPopup: false };
      break;

    case POPUP_ACTIONS.SHOW_SCREEN_LOADING:
      state = { ...state, screenLoading: true };
      break;

    case POPUP_ACTIONS.HIDE_SCREEN_LOADING:
      state = { ...state, screenLoading: false };
      break;

    default:
  }

  return state;
};

export default popupReducer;
