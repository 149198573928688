import { put, call, takeEvery, all, fork } from "redux-saga/effects";

import api from "../../services/api";
import * as types from "../action-types/home.types";
import * as actions from "../actions/home.actions";
import { handlingError } from "../../constants/utility/error";

function* onSaveKeywordsHistory(action: types.SaveKeywordsHistoryAction) {
  try {
    yield put(actions.saveKeywordsHistoryRequest());
    yield put(actions.saveKeywordsHistorySuccess(action.payload));
  } catch (error: any) {
    yield handlingError(error);
    yield put(actions.saveKeywordsHistoryFailure(error.data));
  }
}

function* watchonSaveKeywordsHistory() {
  yield takeEvery(types.SAVE_KEYWORDS_TO_HISTORY, onSaveKeywordsHistory);
}

export default function* homeSaga() {
  yield all([fork(watchonSaveKeywordsHistory)]);
}
