import styled, { css } from "styled-components/macro";
import { Tooltip, Modal } from "react-bootstrap";

export const PlanSwitch = styled.div<{ isMonthlyActive: boolean }>`
  border-radius: 15px;
  background-color: rgb(66, 66, 66);
  width: fit-content;
  display: flex;
  font-size: 10px;
  gap: 10px;
  position: relative;
  border: 1px solid rgb(51, 51, 51);
  cursor: pointer;
  margin-left: auto;

  .monthly {
    transition: 0.3s;
    padding: 2px 5px;
    z-index: 1;
    color: ${({ isMonthlyActive }) => (isMonthlyActive ? "rgb(66, 66, 66)" : "rgb(240, 240, 240)")};
  }

  .yearly {
    transition: 0.3s;
    padding: 2px 10px;
    z-index: 1;
    color: ${({ isMonthlyActive }) => (isMonthlyActive ? "rgb(240, 240, 240)" : "rgb(66, 66, 66)")};
  }

  &:after {
    transition: 0.3s;
    width: 50%;
    content: "";
    background: rgb(240, 240, 240);
    left: ${({ isMonthlyActive }) => (isMonthlyActive ? 0 : "50%")};
    position: absolute;
    top: 0;
    height: 100%;
    border-radius: 15px;
  }
`;

export const ModalHubSpot = styled(Modal)`
  .modal-content {
    background-color: ${({ theme }) => theme.background};
    min-height: 591px;
  }
  .modal-title.h4 {
    color: ${({ theme }) => theme.contrast};
    font-size: 20px;
    font-weight: bold;
  }

  .hbspt-form {
    color: pink;
  }
`;

export const StyledTooltip = styled(Tooltip)`
  margin-left: 40px;

  .tooltip-inner {
    background: rgb(109, 42, 150) !important;
  }

  .tooltip-arrow {
    &:before {
      border-right-color: rgb(109, 42, 150) !important;
    }
  }
`;

export const Plan = styled.div<{ isRecommend?: boolean }>`
  position: relative;
  z-index: 1;
  display: grid;
  grid-template-rows: 60px 40px 30px 60px 60px 30px 30px 30px repeat(15, 30px);
  align-items: stretch;
  font-size: 14px;
  border-right: 1px solid ${({ theme }) => theme.lightBorder};
  overflow: hidden;

  .free-label {
    grid-row: 2/4;
  }

  .plan {
    font-size: 16px;
    font-weight: 600;
  }

  .feature-item {
    font-size: 12px;
  }

  .minor {
    font-size: 10px;
    line-height: 14px;
    margin-top: 5px;
  }

  .button-pricing {
    border: none;
    border-radius: 5px;
    width: 130px;

    color: #ffffff;
    background-color: rgb(167, 169, 172);
    font-weight: bold;
    font-size: 10px;
    padding: 5px 0px;
  }

  .contact {
    font-size: 12px;
  }

  .light-bulb {
    grid-row: 1/4;

    .lightbulb-icon {
      width: 120px;
      height: 120px;

      path,
      line {
        stroke: ${({ theme }) => theme.contrast};
      }
    }
  }

  &:last-child {
    border: none;

    &:hover {
      .button-pricing {
        background-color: ${({ theme }) => theme.color.primary};
      }

      .highlight-text {
        color: ${({ theme }) => theme.color.primary};
      }
    }
  }

  &:first-child {
    padding-left: 20px;

    > div {
      justify-content: start;
    }
  }

  &:nth-child(3) {
    &:hover {
      .button-pricing {
        background-color: ${({ theme }) => theme.color.gold};
      }

      .highlight-text {
        color: ${({ theme }) => theme.color.gold};
      }
    }
  }

  &:nth-child(4) {
    &:hover {
      .button-pricing {
        background-color: ${({ theme }) => theme.color.green};
        color: #000000;
      }

      .highlight-text {
        color: ${({ theme }) => theme.color.green};
      }
    }
  }

  &:not(&:first-child) {
    &:hover {
      background-color: ${({ theme }) => theme.planHovering};
    }
  }

  .bold-text {
    font-weight: bold;
  }

  .plan-title {
    font-size: 20px;
    font-weight: bold;
    align-items: center;

    > div {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .plan-label {
      width: 100%;
      font-size: 40px;
    }
  }
  .free {
    //
  }

  .starter {
    color: ${({ theme }) => theme.color.gold};
  }

  .growth {
    color: ${({ theme }) => theme.secondary900};
  }

  .enterprise {
    color: ${({ theme }) => theme.color.primary};
  }

  .plan-icon {
    background-repeat: no-repeat;
    background-size: contain;
    height: auto;
    background-position: center;
    margin: 0 auto;
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .signup {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .highlight {
    margin: 0px 5px;
    font-size: 14px;
    font-weight: ${({ theme }) => theme.weight.bold};
  }

  .small-text {
    font-style: italic;
    font-size: 12px;
    font-weight: 600;
    color: rgb(206, 206, 206);
  }

  .highlight-text {
    font-size: 16px;
    font-weight: ${({ theme }) => theme.weight.bold};
    margin: 0 5px;
  }

  ${({ isRecommend }) =>
    isRecommend &&
    css`
      &:before {
        position: absolute;
        content: "BEST VALUE";
        padding: 5px 25px;
        background: linear-gradient(
          90deg,
          rgba(210, 0, 214, 1) 32%,
          rgba(132, 63, 170, 1) 46%,
          rgba(85, 60, 174, 1) 61%,
          rgba(58, 80, 210, 1) 75%,
          rgba(28, 155, 246, 1) 94%
        );
        position: absolute;
        font-size: 10px;
        font-weight: 600;
        top: 15px;
        color: rgb(230, 230, 230);
        right: -25px;

        transform: rotate(45deg);
      }
    `}
`;

export const PricingPlanLayout = styled.div`
  padding: 0px;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.background};

  @media (max-width: 1367px) {
    overflow-x: scroll;
    overflow-y: hidden;
  }

  .modal-content {
    background-color: ${({ theme }) => theme.background};
    /* padding: 20px; */
  }

  .wrapper {
    margin: 0px 23px;
    width: max-content;
    height: 100%;
    margin-bottom: 85px;

    .page-title {
      color: ${({ theme }) => theme.contrast};
      padding: 50px 0px 20px 0px;
    }

    .pricing-plan-list {
      padding-left: 100px;
      margin-top: 50px;
      display: grid;
      grid-template-columns: repeat(5, 200px);
      grid-template-rows: 1fr;
      text-align: center;
      color: ${({ theme }) => theme.contrast};

      ${Plan} {
        &:nth-child(1) {
          .background {
            height: 100%;
            transform: translateY(0);
          }
        }

        &:nth-child(2) {
          .background {
            transform: translateY(-15px);
          }
        }

        &:nth-child(3),
        &:nth-child(5) {
          z-index: 2;

          .background {
            transform: translateY(-25px);
          }
        }

        &:nth-child(4) {
          z-index: 3;
        }
      }
    }
  }
`;
