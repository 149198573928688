import { axiosForReverseAds } from "../axiosConfig";
import { IForwardCampaign } from "../modules/Client/pages/CampaignReport/CampaignAdmin/types";
import { ICountry } from "../store/action-types/countries.types";
import { IPaymentDetailData } from "../store/states/payments.state";
import { IRefIdData } from "../store/states/admin.state";
import { IDuplicateCampaignPayload, ENUM_CAMPAIGN_STATUS } from "../store/states/campaign.state";
import { IUserAuthentication } from "../store/states/user.state";
import fileDownload from "js-file-download";
import { formatDate } from "../constants/utility/date";
import { EXPORT_FILE_TYPE } from "../constants/types";
import { toast } from "react-toastify";
import { buildFormData } from "../constants/utility/keyword";
import { INewsFeed } from "../store/states/admin-news-feed.state";
import { ICampaignFilters, IPaymentsFilters, IUsersFilters } from "./types";
import queryString from "query-string";
import { IBanner } from "../store/states/banner.state";
import { ICreative, IPostCreative } from "../store/states/creative.state";
import ICountriesResponse from "../models/countries-response.interface";
import ICreateCreativeResponse from "../models/create-creative-response.interface";
import ICreateBannerResponse from "../models/create-banner-response.interface";
import IUpdateCreativeResponse from "../models/update-creative-response.interface";
import IUpdateBannerResponse from "../models/update-banner-response.interface";
import ICreateAssetsResponse from "../models/create-assets-response.interface";
import IDeleteAssetsResponse from "../models/delete-assets-response.interface";
import IDuplicateCampaignResponse from "../models/duplicate-campaign-response.interface";
import IEditCampaignResponse from "../models/edit-campaign-response.interface";
import IUpdateUserResponse from "../models/update-user-response.interface";
import IUpdatePaymentStatusResponse from "../models/update-payment-status-response.interface";
import ICampaignSettingResponse from "../models/campaign-setting-response.interface";
import IForwardCampaignResponse from "../models/forward-campaign-response.interface";
import ICreateNewsFeedResponse from "../models/create-news-feed-response.interface";
import IUpdateNewsFeedResponse from "../models/update-news-feed-response.interface";
import IGetCampaignsResponse from "../models/get-campaigns-response.interface";
import IGetCampaignByIdResponse from "../models/get-campaign-by-id-response.interface";
import IGetKeywordListResponse from "../models/get-keyword-list-response.interface";
import IEditSavedKeywordsResponse from "../models/edit-saved-keywords-response.interface";
import IKeywordSearchVolumeResponse from "models/keyword-search-volume-response.interface";

const api = {
  targeting: {
    getCountries: async () => {
      const response = await axiosForReverseAds.get(`feed/countries`);
      return response.data;
    },
    getTargeting: async (type: "browser" | "os" | "device") => {
      const response = await axiosForReverseAds.get(`adform/campaign-configs?type=${type}`);
      return response.data;
    },

    getTargetingCities: async (searchTerms: string) => {
      const params = queryString.stringify({
        search: searchTerms.toLowerCase(),
      });
      const { data } = await axiosForReverseAds.get<any>(`feed/locations?${params}`);

      return data;
    },
  },
  user: {
    setBusinessGroupAndWebsite: async (data: { businessId: number; website: string }) => {
      const response = await axiosForReverseAds.post("auth/v2/setBusinessGroupAndWebsite", {
        ...data,
      });
      return response.data;
    },
    updatePassword: async (data: { password: string; newPassword: string }) => {
      const response = await axiosForReverseAds.post("auth/v2/newPassword", {
        ...data,
      });
      return response.data;
    },
    forgetPassword: async (data: string) => {
      const response = await axiosForReverseAds.post(`/auth/v2/forgotPassword`, {
        email: data,
      });
      return response.data;
    },
    resendVerification: async (data: string) => {
      const response = await axiosForReverseAds.post(`/auth/v2/resendVerificationMail`, {
        email: data,
      });
      return response.data;
    },
  },
  compare: {
    getCompareHistory: async () => {
      const response = await axiosForReverseAds.get<any>("history/keywordGroups");
      return response.data;
    },
    saveCompare: async (data: Array<string>) => {
      const response = await axiosForReverseAds.post<any>("history/keywordGroups", {
        keywords: data,
      });
      return response.data;
    },
    deleteCompareHistory: async (data: Array<string>) => {
      const response = await axiosForReverseAds.delete<any>("history/keywordGroups", {
        data: {
          ids: data,
        },
      });
      return response.data;
    },
  },
  suggested: {
    getSuggestedHistory: async () => {
      const response = await axiosForReverseAds.get<any>("history/suggested");
      return response.data;
    },
    saveSuggested: async (data: string) => {
      const response = await axiosForReverseAds.post<any>("history/suggested", {
        keywords: [data],
      });
      return response.data;
    },
    deleteSuggestedHistory: async (data: Array<number>) => {
      const response = await axiosForReverseAds.delete<any>("history/suggested", {
        data: {
          ids: { ...data },
        },
      });
      return response.data;
    },
  },
  competitor: {
    getCompetitorHistory: async () => {
      const response = await axiosForReverseAds.get<any>("history/competitors");
      return response.data;
    },
    saveCompetitor: async (data: string) => {
      const response = await axiosForReverseAds.post<any>("history/competitors", {
        competitors: [data],
      });
      return response.data;
    },
    deleteCompetitorHistory: async (data: Array<number>) => {
      const response = await axiosForReverseAds.delete<any>("history/competitors", {
        data: {
          ids: { ...data },
        },
      });
      return response.data;
    },
  },
  dataForSEO: {
    keywordSeachVolume: async (
      keywords: Array<string>,
      locationName = "United States",
      language = "English"
    ) => {
      const response = await axiosForReverseAds.post<IKeywordSearchVolumeResponse>(
        "keywordServices/keywordSearchVolumeV3",
        {
          keywords,
          locationName,
          language,
        }
      );
      return response;
    },
  },
  creative: {
    createCreative: async (data: IPostCreative) => {
      const response = await axiosForReverseAds.post<ICreateCreativeResponse>("campaign/creative", {
        ...data,
      });
      return response.data;
    },
    updateCreative: async (data: IPostCreative) => {
      const response = await axiosForReverseAds.put<IUpdateCreativeResponse>("campaign/creative", {
        ...data,
      });
      return response.data;
    },
    deleteCreative: async (data: { creativeId: number }) => {
      const response = await axiosForReverseAds.delete("campaign/creative", {
        data,
      });
      return response.data;
    },
    // assets

    createCreativeAssets: async (
      data: {
        creativeAd: ICreative;
      },
      creativeId: number
    ) => {
      const formData = new FormData();
      buildFormData(formData, {
        creativeAd: {
          images: data.creativeAd.images,
        },
        creativeId,
      });
      // formData.append("_method", "PUT");
      const response = await axiosForReverseAds.post<ICreateAssetsResponse>(
        "campaign/creativeAssets",
        formData
      );
      return response.data;
    },
    deleteCreativeAssets: async (data: Array<string>, creativeId: number) => {
      const response = await axiosForReverseAds.delete("campaign/creativeAssets", {
        data: { assetsUrl: data, creativeId },
      });
      return response.data;
    },
  },
  banner: {
    createBanners: async (data: { bannerAd: IBanner; campaignId: string }) => {
      const response = await axiosForReverseAds.post<ICreateBannerResponse>("campaign/banner", {
        ...data,
      });
      return response.data;
    },
    updateBanners: async (data: { bannerAd: IBanner; campaignId: string; bannerId: number }) => {
      const response = await axiosForReverseAds.put<IUpdateBannerResponse>("campaign/banner", {
        ...data,
      });
      return response.data;
    },
    deleteBanner: async (data: number) => {
      const response = await axiosForReverseAds.delete("campaign/banner", {
        data: {
          bannerIds: [data],
        },
      });
      return response.data;
    },

    createBannerAssets: async (
      data: {
        bannerAd: IBanner;
      },
      bannerId: number
    ) => {
      const formData = new FormData();
      buildFormData(formData, {
        bannersAds: {
          images: data.bannerAd.images.map((image: any) => ({
            name: image.name,
            imageFile: image.imageFile,
            height: image.height,
            width: image.width,
          })),
        },
        bannerId,
      });
      // formData.append("_method", "PUT");
      const response = await axiosForReverseAds.post<IDeleteAssetsResponse>(
        "campaign/bannerAssets",
        formData
      );
      return response.data;
    },
    editBannerAsset: async (data: {
      width: number;
      height: number;
      imageUrl: string;
      bannerId: number;
      name: string;
    }) => {
      const response = await axiosForReverseAds.put("campaign/bannerAssets", { ...data });
      return response.data;
    },
    deleteBannerAssets: async (data: Array<string>, bannerId: number) => {
      const response = await axiosForReverseAds.delete("campaign/bannerAssets", {
        data: { imagesUrl: data, bannerId },
      });
      return response.data;
    },
  },
  campaigns: {
    getCampaigns: async (filters: ICampaignFilters) => {
      const activeWithPaused = filters.status;
      // filters.status === ENUM_CAMPAIGN_STATUS.ACTIVE
      //   ? [filters.status, ENUM_CAMPAIGN_STATUS.PAUSED]
      //   : filters.status;
      const params = queryString.stringify({ ...filters, status: activeWithPaused });
      const { data } = await axiosForReverseAds.get<IGetCampaignsResponse>(`campaigns?${params}`);
      return data;
    },

    export: async (type: EXPORT_FILE_TYPE, dateRange?: { startDate: Date; endDate: Date }) => {
      try {
        const file = await axiosForReverseAds.get<Blob>(
          `/campaigns/export?extension=${type}${
            dateRange
              ? `&from_date=${formatDate(dateRange.startDate)}&to_date=${formatDate(
                  dateRange.endDate
                )}`
              : ""
          }`,
          {
            responseType: "blob",
          }
        );
        fileDownload(
          file.data,
          `Campaigns${
            dateRange
              ? ` (${formatDate(dateRange.startDate)} - ${formatDate(dateRange.endDate)}) `
              : ""
          }.${type}`
        );
      } catch (error: any) {
        if (error.response.status === 400) {
          toast.error("Export campaign performance failure");
        }
      }
    },
  },

  campaign: {
    export: async (
      id: string,
      type: EXPORT_FILE_TYPE,
      dateRange?: { startDate: Date; endDate: Date }
    ) => {
      try {
        const file = await axiosForReverseAds.get<Blob>(
          `/campaign/export/${id}?extension=${type}${
            dateRange
              ? `&from_date=${formatDate(dateRange.startDate)}&to_date=${formatDate(
                  dateRange.endDate
                )}`
              : ""
          }`,
          {
            responseType: "blob",
          }
        );
        fileDownload(
          file.data,
          `Campaign Performance${
            dateRange
              ? ` (${formatDate(dateRange.startDate)} - ${formatDate(dateRange.endDate)}) `
              : ""
          }.${type}`
        );
      } catch (error: any) {
        if (error.response.status === 400) {
          toast.error("Export campaign performance failure");
        }
      }
    },
    create: async (data: any) => {
      const response = await axiosForReverseAds.post<IEditCampaignResponse>(`/campaign`, {
        ...data,
        ...data.paymentForm,
        ...data.card,
      });
      return response.data;
    },
    getCampaignById: async (id: string) => {
      const { data } = await axiosForReverseAds.get<IGetCampaignByIdResponse>(`/campaign/${id}`);
      return data;
    },
    duplicateCampaign: async (payload: IDuplicateCampaignPayload) => {
      const { data } = await axiosForReverseAds.post<IDuplicateCampaignResponse>(
        "/campaign/duplicate",
        {
          ...payload,
        }
      );
      return data;
    },
    edit: async (data: any) => {
      const formData = new FormData();
      buildFormData(formData, data);
      formData.append("_method", "PUT");

      const response = await axiosForReverseAds.post<IEditCampaignResponse>(`/campaign`, formData);

      return response.data;
    },
    linkBeeswax: async (id: string) => {
      const response = await axiosForReverseAds.post<any>(`campaign/linkBeeswax/`, {
        campaignId: id,
      });
      return response.data;
    },
    payment: async (data: any) => {
      const response = await axiosForReverseAds.post<any>("/campaign/payment", {
        ...data,
        ...data.card,
      });
      return response;
    },
  },
  admin: {
    payments: {
      getPayments: async (filters: IPaymentsFilters) => {
        const params = queryString.stringify(filters, {
          arrayFormat: "bracket",
          skipNull: true,
          skipEmptyString: true,
        });
        const response = await axiosForReverseAds.get(`admin/campaign/payments?${params}`);
        return response.data;
      },
      getBankList: async () => {
        const response = await axiosForReverseAds.get("admin/banks");
        return response.data;
      },
    },
    users: {
      getUsers: async (filters: IUsersFilters) => {
        const params = queryString.stringify(filters, {
          arrayFormat: "bracket",
          skipNull: true,
          skipEmptyString: true,
        });
        const response = await axiosForReverseAds.get(`admin/users?${params}`);
        return response.data;
      },
      updateUser: async (data: IUserAuthentication) => {
        const response = await axiosForReverseAds.post<IUpdateUserResponse>("admin/user/update", {
          ...data,
        });
        return response.data;
      },
      deleteUsers: async (data: Array<string>) => {
        const response = await axiosForReverseAds.post("admin/users/delete", { ids: data });
        return response.data;
      },
    },
    campaign: {
      updatePaymentStatus: async (data: IPaymentDetailData) => {
        const response = await axiosForReverseAds.post<IUpdatePaymentStatusResponse>(
          "admin/campaign/payment",
          {
            ...data,
          }
        );
        return response.data;
      },
      setting: async (data: IRefIdData) => {
        const response = await axiosForReverseAds.post<ICampaignSettingResponse>(
          "admin/campaign/setting",
          {
            ...data,
          }
        );
        return response.data;
      },
      forwardCampaign: async (data: IForwardCampaign) => {
        const response = await axiosForReverseAds.post<IForwardCampaignResponse>(
          "admin/campaign/forwardCampaign",
          {
            ...data,
          }
        );
        return response.data;
      },
    },
    feed: {
      getFeeds: async (page?: number, pageLimit?: number) => {
        const response = await axiosForReverseAds.get(`feed/all?page=${page ? `${page + 1}` : 1}`);
        return response.data;
      },
      getFeedById: async (data: number) => {
        const response = await axiosForReverseAds.get(`feed/${data}`);
        return response.data;
      },
      createFeed: async (data: INewsFeed) => {
        const { editorSchema, ...rest } = data;
        const formData = new FormData();
        buildFormData(formData, rest);
        formData.append("isPublished", data.isPublished.toString());
        formData.append("editorSchema", JSON.stringify(editorSchema));
        const response = await axiosForReverseAds.post<ICreateNewsFeedResponse>(
          "feed/create",
          formData
        );
        return response.data;
      },
      updateFeed: async (data: INewsFeed) => {
        const { editorSchema, ...rest } = data;
        const formData = new FormData();
        buildFormData(formData, rest);
        formData.append("isPublished", data.isPublished.toString());
        formData.append("editorSchema", JSON.stringify(editorSchema));

        const response = await axiosForReverseAds.post<IUpdateNewsFeedResponse>(
          "feed/update",
          formData
        );
        return response.data;
      },
      deleteFeed: async (data: number) => {
        const response = await axiosForReverseAds.post("feed/delete", {
          id: data,
        });
        return response.data;
      },
    },
  },
  keywords: {
    export: {
      keywordSearchVolume: async (type: EXPORT_FILE_TYPE, keywords: Array<string>) => {
        try {
          const file = await axiosForReverseAds.post<Blob>(
            `/keywordServices/keywordSearchVolumeReport?extension=${type}`,
            {
              keywords: keywords.slice(0, 50),
            },
            { responseType: "blob" }
          );
          fileDownload(file.data, `Keywords.${type}`);
        } catch (error: any) {
          if (error.response.status === 400) {
            toast.error("Export keywords failure");
          }
        }
      },
      keywordForKeywords: async (type: EXPORT_FILE_TYPE, keyword: string, locationId?: number) => {
        try {
          const file = await axiosForReverseAds.post<Blob>(
            `/keywordServices/keywordForKeywordsV3Report?extension=${type}`,
            {
              keyword,
              locationId,
            },
            { responseType: "blob" }
          );
          fileDownload(file.data, `Keywords.${type}`);
        } catch (error: any) {
          if (error.response.status === 400) {
            toast.error("Export keywords failure");
          }
        }
      },
    },
  },
  savedKeywordsList: {
    getSavedKeywordsList: async () => {
      const { data } = await axiosForReverseAds.get("keywordLists");
      return data;
    },
    getCurrentSavedList: async (id: string) => {
      const { data } = await axiosForReverseAds.get<IGetKeywordListResponse>(`/keywordList/${id}`);
      return data;
    },
    editSavedKeywordsList: async (payload: { id: string; name: string }) => {
      const { data } = await axiosForReverseAds.post<IEditSavedKeywordsResponse>(
        "keywordList/edit",
        {
          name: payload.name,
          id: payload.id,
        }
      );
      return data;
    },
  },
  feed: {
    getFeeds: async (page?: number) => {
      const { data } = await axiosForReverseAds.get(`feeds?page=${page ? `${page + 1}` : 1}`);
      return data;
    },
    getRecent: async () => {
      const { data } = await axiosForReverseAds.get("feed/recent");
      return data;
    },
  },
};

export default api;
