import { IErrorMessage } from "../../services/types";
import * as types from "../action-types/creative.types";
import { IAsset, IPostCreative } from "../states/creative.state";

// create

export function createCreative(payload: IPostCreative): types.CreateCreativeAction {
  return {
    type: types.CREATE_CREATIVE,
    payload,
  };
}

export function createCreativeRequest(): types.CreateCreativeRequestAction {
  return {
    type: types.CREATE_CREATIVE_REQUEST,
  };
}

export function createCreativeSuccess(payload: any): types.CreateCreativeSuccessAction {
  return {
    type: types.CREATE_CREATIVE_SUCCESS,
    payload,
  };
}

export function createCreativeFailure(error: IErrorMessage): types.CreateCreativeFailureAction {
  return {
    type: types.CREATE_CREATIVE_FAILURE,
    error,
  };
}

// get

export function getCreative(): types.GetCreativeAction {
  return {
    type: types.GET_CREATIVE,
  };
}

export function getCreativeRequest(): types.GetCreativeRequestAction {
  return {
    type: types.GET_CREATIVE_REQUEST,
  };
}

export function getCreativeSuccess(payload: any): types.GetCreativeSuccessAction {
  return {
    type: types.GET_CREATIVE_SUCCESS,
    payload,
  };
}

export function getCreativeFailure(error: IErrorMessage): types.GetCreativeFailureAction {
  return {
    type: types.GET_CREATIVE_FAILURE,
    error,
  };
}

// update

export function updateCreative(payload: any): types.UpdateCreativeAction {
  return {
    type: types.UPDATE_CREATIVE,
    payload,
  };
}

export function updateCreativeRequest(): types.UpdateCreativeRequestAction {
  return {
    type: types.UPDATE_CREATIVE_REQUEST,
  };
}

export function updateCreativeSuccess(payload: any): types.UpdateCreativeSuccessAction {
  return {
    type: types.UPDATE_CREATIVE_SUCCESS,
    payload,
  };
}

export function updateCreativeFailure(error: IErrorMessage): types.UpdateCreativeFailureAction {
  return {
    type: types.UPDATE_CREATIVE_FAILURE,
    error,
  };
}

// delete

export function deleteCreative(payload: { creativeId: number }): types.DeleteCreativeAction {
  return {
    type: types.DELETE_CREATIVE,
    payload,
  };
}

export function deleteCreativeRequest(): types.DeleteCreativeRequestAction {
  return {
    type: types.DELETE_CREATIVE_REQUEST,
  };
}

export function deleteCreativeSuccess(): types.DeleteCreativeSuccessAction {
  return {
    type: types.DELETE_CREATIVE_SUCCESS,
  };
}

export function deleteCreativeFailure(error: IErrorMessage): types.DeleteCreativeFailureAction {
  return {
    type: types.DELETE_CREATIVE_FAILURE,
    error,
  };
}

// create assets

export function createCreativeAssets(payload: Array<IAsset>): types.CreateCreativeAssetsAction {
  return {
    type: types.CREATE_CREATIVE_ASSETS,
    payload,
  };
}

export function createCreativeAssetsRequest(): types.CreateCreativeAssetsRequestAction {
  return {
    type: types.CREATE_CREATIVE_ASSETS_REQUEST,
  };
}

export function createCreativeAssetsSuccess(
  payload: Array<IAsset>
): types.CreateCreativeAssetsSuccessAction {
  return {
    type: types.CREATE_CREATIVE_ASSETS_SUCCESS,
    payload,
  };
}

export function createCreativeAssetsFailure(
  error: IErrorMessage
): types.CreateCreativeAssetsFailureAction {
  return {
    type: types.CREATE_CREATIVE_ASSETS_FAILURE,
    error,
  };
}

// delete assets

export function deleteCreativeAssets(payload: any): types.DeleteCreativeAssetsAction {
  return {
    type: types.DELETE_CREATIVE_ASSETS,
    payload,
  };
}

export function deleteCreativeAssetsRequest(): types.DeleteCreativeAssetsRequestAction {
  return {
    type: types.DELETE_CREATIVE_ASSETS_REQUEST,
  };
}

export function deleteCreativeAssetsSuccess(payload: any): types.DeleteCreativeAssetsSuccessAction {
  return {
    type: types.DELETE_CREATIVE_ASSETS_SUCCESS,
    payload,
  };
}

export function deleteCreativeAssetsFailure(
  error: IErrorMessage
): types.DeleteCreativeAssetsFailureAction {
  return {
    type: types.DELETE_CREATIVE_ASSETS_FAILURE,
    error,
  };
}
