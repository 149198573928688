import { toast } from "react-toastify";

export const handlingError = (error: any) => {
  if (error.response?.data?.message) {
    toast.error(error.response.data.message);
  } else {
    toast.error("System Error");
  }
};

export const handlingFormErrors = (error: any) => {
  if (error.response?.data?.formErrors) {
    const fields = Object.keys(error.response.data.formErrors);
    if (fields.length) {
      const message = fields.reduce((acc, cur) => {
        acc += `${cur}: ${error.response.data.formErrors[cur]} 
    `;
        return acc;
      }, ``);
      toast.error(message);
    } else {
      toast.error(error.response.data.message);
    }
  } else {
    toast.error("System Error");
  }
};

export const handlingKeywordServiceError = (error: any) => {
  if (error.response?.data?.error) {
    toast.error(error.response.data.error.message);
  } else {
    handlingError(error);
  }
};

export const onSuccess = (message: string) => {
  toast.success(`${message} successfully!`);
};
