import { AnyAction } from "redux";
import * as actions from "../action-types/current-saved-keywords.types";
import savedListState from "../states/current-saved-list.state";

const currentSaveKeywordsList = (state = savedListState, action: AnyAction) => {
  switch (action.type) {
    // get

    // case actions.GET_CURRENT_SAVED_KEYWORDS_LIST_REQUEST:
    //   state = { ...state, loading: true, data: null };
    //   break;

    // case actions.GET_CURRENT_SAVED_KEYWORDS_LIST_SUCCESS:
    //   state = {
    //     ...state,
    //     loading: false,
    //     data: action.payload,
    //     error: null,
    //   };
    //   break;

    // case actions.GET_CURRENT_SAVED_KEYWORDS_LIST_FAILURE:
    //   state = {
    //     ...state,
    //     loading: false,
    //     data: null,
    //     error: action.error,
    //   };
    //   break;

    // edit

    case actions.EDIT_CURRENT_SAVED_KEYWORDS_LIST_REQUEST:
      state = { ...state };
      break;

    case actions.EDIT_CURRENT_SAVED_KEYWORDS_LIST_SUCCESS:
      state = {
        ...state,
        loading: false,
        data: { ...state.data, ...action.payload },
        error: null,
      };
      break;

    case actions.EDIT_CURRENT_SAVED_KEYWORDS_LIST_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.error,
      };
      break;

    default:
  }

  return state;
};

export default currentSaveKeywordsList;
