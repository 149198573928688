import React from "react";

const SaveToListIcon = ({ className }: { className?: string }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.91 18.6">
      <defs>
        <style>
          {".icon-svg{fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;}"}
        </style>
      </defs>
      <g id="Layer_2" data-name="Layer 2">
        <g id="icons">
          <path
            className="icon-svg"
            d="M6.6,6.18H1.81S.47,6.13.5,7.28l.05,9.79a1.33,1.33,0,0,0,1.26,1H18.52a1.17,1.17,0,0,0,.89-1.18l-.09-9.85A1.19,1.19,0,0,0,18,6.18H13.31"
          />
          <line className="icon-svg" x1={9.92} y1={0.5} x2={9.92} y2={11.62} />
          <polyline className="icon-svg" points="6.99 9.44 9.91 12.36 12.84 9.44" />
        </g>
      </g>
    </svg>
  );
};

export default SaveToListIcon;
