import { put, call, takeEvery, all, fork } from "redux-saga/effects";
import * as actions from "../actions/current-saved-keywords-list.actions";
import * as types from "../action-types/current-saved-keywords.types";
import api from "../../services/api";
import { handlingError } from "../../constants/utility/error";
import { getSavedKeywordsList } from "../actions/save-keywords-list.actions";
import { ISavedKeywordList } from "store/states/save-keywords-list.state";
import { convertKeywordV3ArrToObj, IDataForSEO } from "constants/utility/keyword";
import { ISavedKeyword } from "store/states/keywords-list.state";

// get

function* onGetCurrentSavedKeywordsList(action: types.GetCurrentSavedKeywordsListAction) {
  try {
    yield put(actions.getCurrentSavedKeywordsListRequest(action.payload));
    const { data }: { data: ISavedKeywordList } = yield call(
      api.savedKeywordsList.getCurrentSavedList,
      action.payload
    );

    const { data: response } = yield call(
      api.dataForSEO.keywordSeachVolume,
      data.keywords.slice(0, 50).map(({ keyword }) => keyword)
    );
    const results = convertKeywordV3ArrToObj(response.data, true);
    const keywordsWithData: Array<ISavedKeyword> = data.keywords.map((keyword) => ({
      ...keyword,
      stats: { ...results[keyword.keyword] },
    }));
    yield put(
      actions.getCurrentSavedKeywordsListSuccess({
        ...data,
        loading: false,
        keywords: keywordsWithData,
        loaded: true,
      })
    );
  } catch (error: any) {
    handlingError(error);
    yield put(actions.getCurrentSavedKeywordsListFailure(error.response.data.message));
  }
}

function* watchOnGetCurrentSavedKeywordsList() {
  yield takeEvery(types.GET_CURRENT_SAVED_KEYWORDS_LIST, onGetCurrentSavedKeywordsList);
}

// edit

function* onEditCurrentSavedKeywordsList(action: types.EditCurrentSavedKeywordsListAction) {
  try {
    yield put(actions.editCurrentSavedKeywordsListRequest());
    const { data } = yield call(api.savedKeywordsList.editSavedKeywordsList, action.payload);
    yield put(actions.editCurrentSavedKeywordsListSuccess(data));
    yield put(getSavedKeywordsList());
  } catch (error: any) {
    handlingError(error);
    yield put(actions.editCurrentSavedKeywordsListFailure(error.response.data.message));
  }
}

function* watchOnEditCurrentSavedKeywordsList() {
  yield takeEvery(types.EDIT_CURRENT_SAVED_KEYWORDS_LIST, onEditCurrentSavedKeywordsList);
}

export default function* saveKeywordsListSaga() {
  yield all([fork(watchOnGetCurrentSavedKeywordsList), fork(watchOnEditCurrentSavedKeywordsList)]);
}
