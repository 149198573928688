import ChangePasswordPopup from "components/ChangePasswordPopup";
import ForgetPasswordPopup from "components/ForgetPasswordPopup";
import ProtectedRoute from "components/ProtectedRoute";
import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { Route, Navigate, Routes } from "react-router-dom";
import { RootState } from "store/store";
import { userRoutes } from "./routes";
import * as AUTH_ROUTES from "modules/Auth/routes";

import { UserSettingStyled } from "./styled";

interface IUserSettingProps extends PropsFromRedux {
  theme: "light" | "dark";
  themeToggler: () => void;
}

const mapStateToProps = ({ user }: RootState) => ({
  user,
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const UserSetting: React.FunctionComponent<IUserSettingProps> = ({ user, themeToggler, theme }) => {
  return (
    <UserSettingStyled>
      <Routes>
        {user.accessToken ? (
          <React.Fragment>
            {userRoutes.map(({ path, component: Component }) => {
              return (
                Component && (
                  <Route
                    key={path}
                    path={path}
                    element={<Component themeToggler={themeToggler} theme={theme} />}
                  />
                )
              );
            })}
          </React.Fragment>
        ) : (
          <Route
            path="*"
            element={<Navigate to={`${AUTH_ROUTES.AUTH_BASE_ROUTE}/${AUTH_ROUTES.LOGIN}`} />}
          />
        )}

        <Route path="*" element={<Navigate to="/404" />} />
      </Routes>

      <ForgetPasswordPopup />
      <ChangePasswordPopup />
    </UserSettingStyled>
  );
};

export default connector(UserSetting);
