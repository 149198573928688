import { AnyAction } from "redux";
import * as types from "../action-types/compare.types";
import defaultState from "../states/compare.state";

const compareState = (state = defaultState, action: AnyAction) => {
  switch (action.type) {
    // save

    case types.SAVE_COMPARE_REQUEST:
      state = { ...state, loading: true };
      break;

    case types.SAVE_COMPARE_SUCCESS:
      state = {
        ...state,
        data: [action.payload, ...state.data.slice(0, 4)],
        loading: false,
      };
      break;

    case types.SAVE_COMPARE_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    // get

    case types.GET_COMPARE_REQUEST:
      state = { ...state, loading: true };
      break;

    case types.GET_COMPARE_SUCCESS:
      state = {
        ...state,
        data: action.payload,
        loading: false,
      };
      break;

    case types.GET_COMPARE_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    // delete

    case types.DELETE_COMPARE_REQUEST:
      state = { ...state, loading: true };
      break;

    case types.DELETE_COMPARE_SUCCESS:
      state = {
        ...state,
        data: action.payload.length
          ? state.data.filter((prev) => prev.id !== action.payload[0])
          : state.data,
        loading: false,
      };
      break;

    case types.DELETE_COMPARE_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    default:
  }

  return state;
};

export default compareState;
