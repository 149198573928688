export enum COMPARE_VALUE {
  CLICKS = "click",
  IMPRESSIONS = "impression",
  CTR = "ctr",
  // CONVERSIONS = "conversionRate",
}

export type COMPARE_SELECT = COMPARE_VALUE.CLICKS | COMPARE_VALUE.IMPRESSIONS | COMPARE_VALUE.CTR;
// | COMPARE_VALUE.CONVERSIONS;

export const COMPARE_HEADERS = [
  {
    text: "Clicks",
    value: COMPARE_VALUE.CLICKS,
  },
  {
    text: "Impressions",
    value: COMPARE_VALUE.IMPRESSIONS,
  },
  {
    text: "CTR",
    value: COMPARE_VALUE.CTR,
  },
  // {
  // text: "Conversions",
  // value: COMPARE_VALUE.CONVERSIONS,
  // },
];
