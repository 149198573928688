import React from "react";
import { OverlayTrigger } from "react-bootstrap";
import { StyledPopover } from "./styled";

interface IPopoverProps {
  id: string;
  title?: string;
  content: string;
  bottomContent?: string;
  children: React.ReactNode;
  placement?: "top" | "bottom" | "left" | "right";
  color?: string;
  show?: boolean;
}

const Popover: React.FunctionComponent<IPopoverProps> = ({
  id,
  title,
  content,
  bottomContent,
  children,
  placement,
  color,
  show,
}) => {
  return (
    <OverlayTrigger
      placement={placement || "right"}
      overlay={
        <StyledPopover data-testid="popover" id={`popover-positioned-${placement}`} color={color}>
          <StyledPopover.Header as="h3">{`${title}`}</StyledPopover.Header>
          <StyledPopover.Body>{content}</StyledPopover.Body>
          {bottomContent === undefined ? null : (
            <div className="bottomContent">{bottomContent}</div>
          )}
        </StyledPopover>
      }
      show={show}
    >
      <span>{children}</span>
    </OverlayTrigger>
  );
};

export default Popover;
