import { combineReducers } from "redux";
import userReducer from "./user.reducer";
import keywordLocationReducer from "./keyword-location.reducer";
import categoriesReducer from "./categories.reducer";
import campaignsReducer from "./campaigns.reducer";
import sidebarReducer from "./sidebar.reducer";
import keywordsListReducer from "./keywords-list.reducer";
import popupReducer from "./popup.reducer";
import keywordListFormReducer from "./keyword-list-form.reducer";
import adminReducer from "./admin.reducer";
import usersReducer from "./users.reducer";
import campaignReducer from "./campaign.reducer";
import savedKeywordsListReducer from "./save-keywords-list.reducer";
import adminNewsFeedReducer from "./admin-news-feed.reducer";
import feedsReducer from "./feeds.reducer";
import creativeReducer from "./creative.reducer";
import bannersReducer from "./banners.reducer";
import currentSavedListReducer from "./current-saved-keywords-list.reducer";
import paymentsReducer from "./payments.reducer";
import homeReducer from "./home.reducer";
import campaignPaymentReducer from "./campaign-payment.reducer";
import compareReducer from "./compare.reducer";
import suggestedReducer from "./suggested.reducer";
import competitorReducer from "./competitor.reducer";
import settingsReducer from "./settings.reducer";
import changePasswordReducer from "./change-password.reducer";
import forgetPasswordReducer from "./forget-password.reducer";
import targetingReducer from "./targeting.reducer";

const appReducers = combineReducers({
  adminNewsFeed: adminNewsFeedReducer,
  user: userReducer,
  keywordLocations: keywordLocationReducer,
  categories: categoriesReducer,
  campaigns: campaignsReducer,
  currentCampaign: campaignReducer,
  sidebar: sidebarReducer,
  keywordsList: keywordsListReducer,
  popup: popupReducer,
  keywordListForm: keywordListFormReducer,
  admin: adminReducer,
  users: usersReducer,
  savedKeywordsList: savedKeywordsListReducer,
  feed: feedsReducer,
  creative: creativeReducer,
  banners: bannersReducer,
  currentSavedList: currentSavedListReducer,
  payments: paymentsReducer,
  home: homeReducer,
  campaignPayment: campaignPaymentReducer,
  compare: compareReducer,
  suggested: suggestedReducer,
  competitor: competitorReducer,
  settings: settingsReducer,
  changePassword: changePasswordReducer,
  forgetPassword: forgetPasswordReducer,
  targeting: targetingReducer,
});

export default appReducers;
