import { AdFormCategoryID } from "services/types";

export interface CATEGORY_KEY_VALUES_ARRAY {
  [key: string]: Array<AdFormCategoryID & { behavior: "exclude" | "include" }>;
}

export type CATEGORY_KEY_VALUES_OBJECT = {
  [key: string]: { [key: string]: AdFormCategoryID & { behavior: "exclude" | "include" } };
};

// export enum ENUM_PARENT_CATEGORY  '-1' |
// '  IAB1' |
//  ' IAB10' |
//  ' IAB11' |
//  ' IAB12' |
//  ' IAB13' |
//  ' IAB14' |
//  ' IAB15' |
//  ' IAB16' |
//  ' IAB17' |
//  ' IAB18' |
//  ' IAB19' |
// '  IAB2' |
//  ' IAB20' |
//  ' IAB21' |
//  ' IAB22' |
//  ' IAB23' |
//  ' IAB24' |
//  ' IAB25' |
//  ' IAB26' |
// '  IAB3' |
// '  IAB4' |
// '  IAB5' |
// '  IAB6' |
// '  IAB7' |
// '  IAB8' |
// '  IAB9' |

export type PARENT_CATEGORY =
  | "-1"
  | "IAB1"
  | "IAB10"
  | "IAB11"
  | "IAB12"
  | "IAB13"
  | "IAB14"
  | "IAB15"
  | "IAB16"
  | "IAB17"
  | "IAB18"
  | "IAB19"
  | "IAB2"
  | "IAB20"
  | "IAB21"
  | "IAB22"
  | "IAB23"
  // | "IAB24"
  | "IAB25"
  | "IAB26"
  | "IAB3"
  | "IAB4"
  | "IAB5"
  | "IAB6"
  | "IAB7"
  | "IAB8"
  | "IAB9";

export enum ENUM_CATEGORY_BEHAVIOR {
  EXCLUDE = "exclude",
  INCLUDE = "include",
}

export type CATEGORY_BEHAVIOR_TYPE =
  | ENUM_CATEGORY_BEHAVIOR.EXCLUDE
  | ENUM_CATEGORY_BEHAVIOR.INCLUDE;

export type IContentCategory = {
  id: number;
  name: string;
  fullName: string;
  keyBeeswax: keyof CATEGORY_KEY_VALUES_ARRAY;
  parentKeyBeeswax: null | keyof CATEGORY_KEY_VALUES_ARRAY;
  behavior?: CATEGORY_BEHAVIOR_TYPE;
};

export interface ICategoryItem {
  [key: number]: {
    behavior: CATEGORY_BEHAVIOR_TYPE;
    key: string;
    fullName: string;
    parentKeyBeeswax: string;
    name: string;
  };
}

export const CATEGORY_PARENTS: Record<string, string> = {
  "-1": "Unknown",
  IAB1: "Arts & Entertainment",
  IAB10: "Home & Garden",
  IAB11: "Law, Govt & Politics",
  IAB12: "News",
  IAB13: "Personal Finance",
  IAB14: "Society",
  IAB15: "Science",
  IAB16: "Pets",
  IAB17: "Sports",
  IAB18: "Style & Fashion",
  IAB19: "Technology & Computing",
  IAB2: "Automotive",
  IAB20: "Travel",
  IAB21: "Real Estate",
  IAB22: "Shopping",
  IAB23: "Religion & Spirituality",
  // IAB24: "Uncategorized",
  IAB25: "Non-Standard Content",
  IAB26: "Illegal Content",
  IAB3: "Business",
  IAB4: "Careers",
  IAB5: "Education",
  IAB6: "Family & Parenting",
  IAB7: "Health & Fitness",
  IAB8: "Food & Drink",
  IAB9: "Hobbies & Interests",
};
