import { put, call, takeEvery, all, fork, delay, takeLatest, select } from "redux-saga/effects";
import * as types from "../action-types/campaign.types";
import * as actions from "../actions/campaign.actions";
import * as bannerActions from "../actions/banner-actions";
import api from "../../services/api";
import { handlingError, handlingFormErrors, onSuccess } from "../../constants/utility/error";
import {
  hideBuildCampaign,
  hideEditCampaign,
  hideNewCampaignPopup,
  hideScreenLoading,
  showScreenLoading,
} from "../actions/popup.actions";
import { toast } from "react-toastify";
import { setCampaignId, getCampaigns } from "store/actions/campaigns.actions";
import { createBanners, getBannersSuccess } from "store/actions/banner-actions";
import { campaignPayment } from "store/actions/campaign-payment.actions";
import { getAvailableSizes } from "constants/utility/upload";
import { getCreativeSuccess } from "store/actions/creative-actions";
import { IErrorMessage } from "services/types";
import { ICampaign } from "store/states/campaigns.state";
import { closeSlideModal } from "store/actions/sidebar.actions";
import { IPostCampaign } from "modules/Client/pages/Campaign/constants";
import { ENUM_CAMPAIGN_STATUS } from "store/states/campaign.state";
import { formatDate } from "constants/utility/date";

// create

function* onCreateCampaign(action: types.createCampaignAction) {
  try {
    const includes = Object.values(action.payload.includes)
      .map((item) => Object.values(item))
      .map((child) =>
        Object.values(child).map((include) => ({ id: include.id, behavior: "include" }))
      )
      .flat();

    const excludes = Object.values(action.payload.excludes)
      .map((item) => Object.values(item))
      .map((child) =>
        Object.values(child).map((exclude) => ({ id: exclude.id, behavior: "exclude" }))
      )
      .flat();

    const value = {
      ...action.payload,
      contentCategories: [...includes, ...excludes],
    } as IPostCampaign;

    yield put(actions.createCampaignRequest());

    const { data: response } = yield call(api.campaign.create, value);
    const { images, landingPageUrl, thankyouPageUrl, imagesUrl, title } = action.payload;

    if (images.length && landingPageUrl && title) {
      const { data } = yield call(api.banner.createBanners, {
        campaignId: response.campaignId,
        bannerAd: {
          images,
          landingPageUrl,
          thankyouPageUrl,
          imagesUrl,
          title,
        },
      });

      yield call(
        api.banner.createBannerAssets,
        {
          bannerAd: {
            images,
            landingPageUrl,
            thankyouPageUrl,
            imagesUrl,
            title,
          },
        },
        data.bannerId
      );
    }

    if (action.payload.card.fullName) {
      const { paymentForm, paymentMethod, card } = action.payload;
      const [expirationMonth, expirationYear] = card.expirationDate.split("/");
      const formatStartDate = paymentForm.startDate && formatDate(paymentForm.startDate);
      const formatEndDate = paymentForm.endDate && formatDate(paymentForm.endDate);

      const campaignPaymentData = {
        paymentType: paymentMethod,
        discountCode: paymentForm.discountCode,
        budgetPerDay: paymentForm.budgetPerDay,
        startDate: formatStartDate,
        endDate: formatEndDate,
        campaignId: response.campaignId,
        card: {
          fullName: card.fullName,
          number: card.cardNumber,
          expMonth: Number(expirationMonth),
          expYear: Number(`20${expirationYear}`),
          cvc: Number(card.securityCode),
        },
      };

      const { data } = yield call(api.campaign.payment, campaignPaymentData);

      if (data.nextUrl) {
        // yield put(actions.campaignPaymentSuccess(state));
        window.location.href = data.nextUrl;
      }
      yield put(hideBuildCampaign());
    } else {
      const { campaigns } = yield select();
      if (campaigns.globalFilter.status && campaigns.globalFilter.status === "Draft") {
        const { data } = yield call(api.campaign.getCampaignById, response.campaignId);
        yield put(actions.createCampaignSuccess(data));
      }
      yield put(actions.updateCampaignListSuccess());
      onSuccess("Draft created");
    }
  } catch (error: any) {
    yield put(hideScreenLoading());
    handlingFormErrors(error);
    yield put(actions.createCampaignFailure(error.response.data?.message));
  }
}

function* watchOnCreateCampaign() {
  yield takeLatest(types.CREATE_CAMPAIGN, onCreateCampaign);
}

// edit

function* onEditCampaign(action: types.editCampaignAction) {
  try {
    yield put(actions.editCampaignRequest());

    const includes = Object.values(action.payload.includes)
      .map((item) => Object.values(item))
      .map((eieis) => Object.values(eieis).map((testt) => ({ id: testt.id, behavior: "include" })))
      .flat();
    const excludes = Object.values(action.payload.excludes)
      .map((item) => Object.values(item))
      .map((eieis) => Object.values(eieis).map((testt) => ({ id: testt.id, behavior: "exclude" })))
      .flat();

    const { data: response } = yield call(api.campaign.edit, {
      ...action.payload,
      contentCategories: [...includes, ...excludes],
    });

    if (
      action.payload.bannerId &&
      action.payload.id &&
      action.payload.landingPageUrl &&
      action.payload.title
    ) {
      const updatedBanner = {
        bannerAd: {
          landingPageUrl: action.payload.landingPageUrl,
          thankyouPageUrl: action.payload.thankyouPageUrl,
          images: action.payload.images,
          imagesUrl: action.payload.imagesUrl,
          title: action.payload.title,
        },
        campaignId: action.payload.id,
        bannerId: action.payload.bannerId,
      };
      yield put(bannerActions.updateBanners(updatedBanner));
      // console.log(updatedBanner);
    } else if (
      action.payload.images.length &&
      action.payload.landingPageUrl &&
      action.payload.title
    ) {
      yield put(
        createBanners({
          campaignId: response.campaignId,
          bannerAd: {
            images: action.payload.images,
            landingPageUrl: action.payload.landingPageUrl,
            thankyouPageUrl: action.payload.thankyouPageUrl,
            imagesUrl: action.payload.imagesUrl,
            title: action.payload.title,
          },
        })
      );
    }

    const { currentCampaign } = yield select();

    // yield put(actions.resetCurrentCampaign());

    if (currentCampaign.data && currentCampaign.data.status !== "Draft") {
      yield put(actions.getCampaignById(currentCampaign.data.id));
      yield put(hideEditCampaign());

      yield put(actions.editCampaignSuccess());
      onSuccess("Campaign updated");
    } else {
      yield put(actions.editCampaignSuccess());
      // yield put(
      //   getCampaigns({
      //     page: 1,
      //     status: ENUM_CAMPAIGN_STATUS.DRAFT,
      //   })
      // );
      yield put(actions.updateCampaignListSuccess());
      onSuccess("Draft updated");
    }
  } catch (error: any) {
    handlingFormErrors(error);
    yield put(actions.editCampaignFailure(error.response.data.message));
  }
}

function* watchOnEditCampaign() {
  yield takeEvery(types.EDIT_CAMPAIGN, onEditCampaign);
}

// link

function* onLinkCampaign(action: types.linkCampaignAction) {
  try {
    yield put(actions.linkCampaignRequest());
    yield put(showScreenLoading());
    const { state } = yield call(api.campaign.linkBeeswax, action.payload);

    if (state === "success") {
      yield put(actions.linkCampaignSuccess(state));
      onSuccess("Link campaign");
    } else {
      toast.error("Link campaign to beeswax failure.");
    }
  } catch (error: any) {
    handlingError(error);
    yield put(actions.linkCampaignFailure(error.response.data.message));
  }
  yield put(hideScreenLoading());
}

function* watchOnLinkCampaign() {
  yield takeEvery(types.LINK_CAMPAIGN, onLinkCampaign);
}

function* onLoadCampaignById(action: types.GetCampaignByIdAction) {
  try {
    yield put(actions.getCampaignByIdRequest());
    yield put(setCampaignId(action.id));
    const { data } = yield call(api.campaign.getCampaignById, action.id);
    yield put(
      actions.getCampaignByIdSuccess({
        ...data,
        theOs: data.theOs.map((id: string) => Number(id)),
        theBrowsers: data.theBrowsers.map((id: string) => Number(id)),
        theDevices: data.theDevices.map((id: string) => Number(id)),
      })
    );

    if (data.creatives.length) {
      yield put(
        getCreativeSuccess({
          ...data.creatives[0],
          action: data.creatives[0].callToAction,
          creativeId: data.creatives[0].id,
          assetsUrl: [],
        })
      );
    }

    if (data.banners.length) {
      yield put(
        getBannersSuccess(
          data.banners.map((banner: any) => ({
            ...banner,
            thankyouPageUrl: banner.thankyouPageUrl || "",
            imagesUrl: [],
            images: banner.images.map((image: any) => ({
              ...image,
              id: image.url,
              availableSizes: getAvailableSizes(image.original.width, image.original.height),
              originWidth: image.original.width,
              originHeight: image.original.height,
              type: image.original.mine,
              size: image.original.size,
              thumbnail: `${process.env.REACT_APP_IMAGES_STORAGE}${image.url}`,
            })),
          }))
        )
      );
    }
  } catch (error: any) {
    handlingError(error);
    yield put(actions.getCampaignByIdFailure(error.response.data.message));
  }
}

function* watchGetCampaignById() {
  yield takeLatest(types.GET_CAMPAIGN_BY_ID, onLoadCampaignById);
}

// update payment status

function* onUpdatePaymentStatus(action: types.UpdatePaymentStatusAction) {
  try {
    yield put(actions.updatePaymentStatusRequest());
    const { data } = yield call(api.admin.campaign.updatePaymentStatus as any, action.payload);
    yield put(actions.updatePaymentStatusSuccess(data));
  } catch (error: any) {
    handlingError(error);
    yield put(actions.updatePaymentStatusFailure(error.response.data.message));
  }
  yield put(closeSlideModal());
}

function* watchonUpdatePaymentStatus() {
  yield takeLatest(types.UPDATE_PAYMENT_STATUS, onUpdatePaymentStatus);
}

// update ref id

function* onSetting(action: types.settingAction) {
  try {
    yield put(actions.settingRequest());
    let url = action.payload.portalUrl;
    if (action.payload.portalUrl && url && url.startsWith("<iframe") && url.endsWith("script>")) {
      const start = action.payload.portalUrl.indexOf("src");
      const end = action.payload.portalUrl.indexOf("</script>");
      url = url.substring(start + 7, end - 2);
    }
    const { message, state } = yield call(api.admin.campaign.setting, {
      ...action.payload,
      portalUrl: url,
    });
    yield put(actions.settingSuccess({ state, message, portalUrl: url }));
    onSuccess("Campaign setting");
  } catch (error: any) {
    handlingError(error);
    yield put(actions.settingFailure(error.response.data.message));
  }
  yield delay(2000);
  yield put(actions.removeMessage());
}

function* watchonSetting() {
  yield takeLatest(types.UPDATE_REF_ID, onSetting);
}

// duplicate campaign

function* onDuplicate(action: types.duplicateCampaignAction) {
  try {
    yield put(actions.duplicateCampaignRequest());

    const { data: result }: { data: { campaignId: string; success: boolean } } = yield call(
      api.campaign.duplicateCampaign,
      action.payload
    );

    const { campaigns } = yield select();

    if (campaigns.data[0].status === "Draft") {
      const { data } = yield call(api.campaign.getCampaignById, result.campaignId);
      yield put(actions.duplicateCampaignSuccess(data));
    } else {
      yield put(actions.duplicateCampaignSuccess());
    }

    onSuccess("Duplicate campaign");
    yield put(hideNewCampaignPopup());
  } catch (error: any) {
    handlingError(error);
    yield put(actions.duplicateCampaignFailure(error.response.data.message));
  }
}

function* watchonDuplicate() {
  yield takeLatest(types.DUPLICATE_CAMPAIGN, onDuplicate);
}

export default function* campaignSaga() {
  yield all([
    fork(watchGetCampaignById),
    fork(watchonUpdatePaymentStatus),
    fork(watchonSetting),
    fork(watchonDuplicate),
    fork(watchOnEditCampaign),
    fork(watchOnLinkCampaign),
    fork(watchOnCreateCampaign),
  ]);
}
