import { Modal } from "react-bootstrap";
import styled from "styled-components/macro";

export const CampaignPopupModal = styled(Modal)`
  color: #ffffff;

  .modal-content {
    background-color: ${({ theme }) => theme.modalBackground};
    border-radius: 20px;
    min-height: 500px;
    border: 1px solid rgb(79, 79, 79);
  }

  .campaign-loading {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .modal-header {
    padding-bottom: 0;
    border-bottom: none;
    position: relative;
  }

  .modal-title {
    color: ${({ theme }) => theme.contrast};
    font-size: 18px;
  }

  .modal-body {
    padding: 10px 0px 0px 50px;

    .campaign-heading {
      display: flex;
      justify-content: center;

      > div {
        border-right: 1px solid ${({ theme }) => theme.text};

        &:last-child {
          border: none;
        }
        width: 250px;
      }
      margin-top: 30px;
      margin-bottom: 10px;

      .field-item {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        font-size: 12px;
        color: ${({ theme }) => theme.text};

        .field-key {
          font-weight: 600;
          margin-bottom: 0px;
        }

        .field-value {
        }
      }
    }

    .current-campaign-wrapper {
      .campaign-body {
        padding-right: 40px;
      }

      .section-selector {
        border-radius: 10px;
        font-size: 16px;
        font-weight: bold;
        overflow: hidden;
        width: min-content;
        display: flex;

        .selector {
          cursor: pointer;
          width: 150px;
          text-align: center;
          padding: 5px 10px;
          background-color: ${({ theme }) => theme.dark};
        }

        .selected {
          background-color: ${({ theme }) => theme.tabSelected};
        }
      }
    }
  }
`;
