import React from "react";

const HomeIcon: React.FunctionComponent<{ className?: string }> = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.52 18.09">
      <defs>
        <style>
          {
            ".icon-svg,.icon-svg{fill:none;stroke:#fff;stroke-linejoin:round;}.icon-svg{stroke-linecap:round;}"
          }
        </style>
      </defs>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_3" data-name="Layer 3">
          <path
            className="icon-svg"
            d="M17.52,6.51v8.41a2.68,2.68,0,0,1-2.67,2.67H5.67A2.68,2.68,0,0,1,3,14.92V6.51"
          />
          <path className="icon-svg" d="M.5,9.08,8.18,1.4a3.22,3.22,0,0,1,4.55.08L20,8.77" />
        </g>
      </g>
    </svg>
  );
};

export default HomeIcon;
