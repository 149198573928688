import React from "react";

const Step1Icon = ({ className }: { className?: string }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 62 62" className={className}>
      <defs>
        <style>
          {
            "\n      .icon-svg{fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}\n    "
          }
        </style>
      </defs>
      <g id="Layer_2" data-name="Layer 2">
        <g id="details">
          <rect className="icon-svg" x={14.95} y={15.8} width={32.09} height={30.4} rx={3} />
          <line className="icon-svg" x1={21.37} y1={22.89} x2={40.63} y2={22.89} />
          <line className="icon-svg" x1={21.37} y1={26.95} x2={40.63} y2={26.95} />
          <line className="icon-svg" x1={21.37} y1={31} x2={40.63} y2={31} />
          <line className="icon-svg" x1={21.37} y1={35.05} x2={40.63} y2={35.05} />
          <line className="icon-svg" x1={32.07} y1={39.11} x2={40.63} y2={39.11} />
          <circle className="icon-svg" cx={31} cy={31} r={30} />
        </g>
      </g>
    </svg>
  );
};

export default Step1Icon;
