import React from "react";

const CompetitorIcon = ({ className }: { className?: string }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.58 17.65">
      <defs>
        <style>
          {".icon-svg{fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;}"}
        </style>
      </defs>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_3" data-name="Layer 3">
          <line className="icon-svg" x1={0.5} y1={17.15} x2={0.5} y2={0.5} />
          <polygon className="icon-svg" points="4.01 0.5 4.01 10.49 18.08 5.49 4.01 0.5" />
        </g>
      </g>
    </svg>
  );
};

export default CompetitorIcon;
