import React from "react";

const ArrowLeftIcon = ({ className }: { className?: string }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.67 25.67">
      <defs>
        <style>
          {
            ".icon-svg,.icon-svg{fill:none;stroke:#fff;stroke-width:0.86px;}.icon-svg{stroke-linecap:round;stroke-linejoin:round;}.icon-svg{stroke-miterlimit:10;}"
          }
        </style>
      </defs>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_3" data-name="Layer 3">
          <polyline className="icon-svg" points="15.17 6.78 8.59 12.66 15.17 18.53" />
          <circle className="icon-svg" cx={12.84} cy={12.84} r={12.4} />
        </g>
      </g>
    </svg>
  );
};

export default ArrowLeftIcon;
