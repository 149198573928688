import React from "react";
import { connect, ConnectedProps, useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";
import { hideComparePopup } from "store/actions/popup.actions";
import { ComparePopupModal } from "./styled";
import { RootState } from "store/store";
import KeywordsTable from "components/KeywordsTable";
import LocationFilter from "components/LocationFilter";
import { ICity, ICountry } from "store/action-types/countries.types";
import { convertKeywordV3ArrToObj, IDataForSEOV3 } from "constants/utility/keyword";
import { axiosForReverseAds } from "axiosConfig";
import IKeywordSearchVolumeResponse from "models/keyword-search-volume-response.interface";
import { handlingKeywordServiceError } from "constants/utility/error";
import Spinner from "components/Spinner";
import Input from "components/Input";
import { SearchIcon, TrashIcon } from "components/Icons";
import CompareGraph from "components/CompareGraph";
import BottomBar from "components/BottomBar";
import { EXPORT_FILE_TYPE } from "constants/types";
import api from "services/api";
import CloseButton from "components/CloseButton";
import { saveCompare } from "store/actions/compare.actions";

interface IComparePopupProps extends PropsFromRedux {}

const mapStateToProps = ({ popup }: RootState) => ({
  popup,
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const ComparePopup: React.FunctionComponent<IComparePopupProps> = ({ popup }) => {
  const dispatch = useDispatch();

  const [selectedCountry, setSelectedCountry] = React.useState<ICountry | null>(null);
  const [selectedCity, setSelectedCity] = React.useState<ICity | null>(null);
  const [compareKeywords, setCompareKeywords] = React.useState<{ [key: string]: IDataForSEOV3 }>(
    {}
  );
  const [keywordsLoading, setKeywordsLoading] = React.useState(false);
  const MAX_KEYWORDS_LIMIT = 5;
  const [selecting, setSelecting] = React.useState<Array<string>>([]);
  const [selectedData, setSelectedData] = React.useState<{ [key: string]: IDataForSEOV3 }>({});
  const [searchTerms, setSearch] = React.useState("");

  const onKeywordSelect = (keyword: string) => {
    let newKeywordsList: Array<string> = [];

    if (selecting.includes(keyword)) {
      newKeywordsList = selecting.filter((element) => element !== keyword);
      setSelecting(newKeywordsList);
      setSelectedData((prev) => {
        delete prev[keyword];
        return prev;
      });
    } else {
      newKeywordsList = [...selecting, keyword];
      setSelecting(newKeywordsList);
      setSelectedData((prev) => ({ ...prev, [keyword]: compareKeywords[keyword] }));
    }
  };

  const fetchKeywordsData = async (keywords: Array<string>) => {
    if (!keywords.length) {
      return;
    }

    setKeywordsLoading(true);

    const locationName = selectedCity ? selectedCity.location_name : selectedCountry?.name;

    try {
      const { data } = await api.dataForSEO.keywordSeachVolume(keywords, locationName);
      const obj = convertKeywordV3ArrToObj(data.data);
      setCompareKeywords(obj);
    } catch (error: any) {
      handlingKeywordServiceError(error);
    } finally {
      setKeywordsLoading(false);
    }
  };

  const onAddKeywordFormSubmit = (keyword: string) => {
    if (Object.keys(compareKeywords).length >= MAX_KEYWORDS_LIMIT || !keyword) {
      return;
    }

    const isAlreadyAdded = selecting.find(
      (keywordData) => keywordData.toLowerCase() === keyword.toLowerCase()
    );

    if (!isAlreadyAdded) {
      const keywordsList = [...Object.values(compareKeywords).map((val) => val.display), keyword];

      fetchKeywordsData(keywordsList);
    }
    setSearch("");
  };

  const onRemoveKeyword = (keyword: string) => {
    setSelecting((prev) => {
      return prev.filter((item) => item !== keyword);
    });

    setCompareKeywords((prev) => {
      delete prev[keyword];
      return { ...prev };
    });
  };

  const searchedKeywordList = Object.values(compareKeywords).map(
    (keywordData) => keywordData.display
  );

  const onExit = () => {
    const list = Object.keys(compareKeywords);
    if (list.length) {
      const isDefault = list.every((item) => popup.showComparePopup.keywords.includes(item));
      if (!isDefault || popup.showComparePopup.keywords.length !== list.length) {
        dispatch(saveCompare(list));
      }
    }
    dispatch(hideComparePopup());
    setCompareKeywords({});
    setSelecting([]);
  };

  const onSelectAll = () => {
    const allKeywords = Object.keys(compareKeywords);

    setSelecting((prev) => (prev.length === allKeywords.length ? [] : allKeywords));
    setSelectedData((prev) => (Object.keys(prev).length ? {} : { ...compareKeywords }));
  };

  const onExportKeywords = (type: EXPORT_FILE_TYPE) => {
    const keywords = Object.values(compareKeywords).map((keywordData) => keywordData.display);
    api.keywords.export.keywordSearchVolume(type, keywords);
  };

  const onDeleteResultKeywords = () => {
    setSelecting([]);

    setCompareKeywords((prev) => {
      selecting.forEach((keyword) => {
        delete prev[keyword];
      });
      return { ...prev };
    });
  };

  React.useEffect(() => {
    if (popup.showComparePopup.keywords.length) {
      fetchKeywordsData(popup.showComparePopup.keywords);
    }
  }, [popup.showComparePopup.keywords]);

  const keywordSet = Object.keys(compareKeywords);

  return (
    <ComparePopupModal
      keyboard
      $blur={
        popup.showExportPopup.show || popup.showImportKeywords.show || popup.showKeywordListPopup
      }
      size="xl"
      centered
      show={popup.showComparePopup.show}
      onHide={onExit}
      onExited={onExit}
      data-testid="compare-modal"
    >
      <Modal.Header>
        <Modal.Title>
          <div className="keyword-tool-title">
            <span className="tool-title">Compare</span>
            <span className="tool-description">
              {`Compare Keywords, enter relevant keywords to find the comparison results. 
You can search for any keyword, based on geo-location or worldwide to find the monthly search volume.`}
            </span>
          </div>

          <CloseButton
            top={15}
            right={15}
            onClick={() => {
              setSearch("");
              dispatch(hideComparePopup());
            }}
          />
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="search-bar">
          <div className="keyword-input">
            {searchedKeywordList.length > 4 && (
              <span className="limit-keywords">We support maximum 5 keywords comparison</span>
            )}
            <Input
              data-testid="keyword-search"
              height={50}
              placeholder="Search Keyword"
              noBorder
              black
              value={searchTerms}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)}
              onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                e.key === "Enter" && onAddKeywordFormSubmit(searchTerms);
              }}
            />
            <SearchIcon />
          </div>

          <LocationFilter
            searchTerms={searchTerms}
            onSubmit={() => {
              const searchList = Array.from(new Set([...searchedKeywordList, searchTerms]));
              fetchKeywordsData(searchList);
              setSearch("");
            }}
            onCountryChange={(values) => setSelectedCountry(values[0])}
            onCityChange={(values) => setSelectedCity(values[0])}
          />
        </div>

        {keywordsLoading ? (
          <div className="loading">
            <Spinner />
          </div>
        ) : keywordSet.length ? (
          <div className="visual-result">
            <CompareGraph
              keywordSet={keywordSet}
              selectedKeywords={
                selecting.length && selecting.length !== keywordSet.length
                  ? selectedData
                  : compareKeywords
              }
              onRemoveKeyword={onRemoveKeyword}
              onClearAll={() => setCompareKeywords({})}
              keywordsLoading={false}
            />
            <KeywordsTable
              onKeywordSelect={onKeywordSelect}
              selectAll={onSelectAll}
              unSelectAll={onSelectAll}
              keywords={compareKeywords}
              selectedKeys={selecting}
              isCustomActions
            />
            <div className="bottom-bar-actions">
              <BottomBar
                onExport={{
                  type: "keywords",
                  data: keywordSet,
                }}
                isAllChecked={selecting.length === keywordSet.length}
                selectAllCallback={onSelectAll}
                selectedItems={selecting}
                onSelectionDeleteButtonClick={onDeleteResultKeywords}
              />
            </div>
          </div>
        ) : (
          <div className="empty" />
        )}
      </Modal.Body>
    </ComparePopupModal>
  );
};

export default connector(ComparePopup);
