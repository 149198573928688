import { AnyAction } from "redux";
import * as actions from "../actions/keyword-list.actions";
import * as currentListTypes from "../action-types/current-saved-keywords.types";
import savedKeywordsState from "../states/keywords-list.state";
import { ISavedKeyword } from "store/states/save-keywords-list.state";

const keywordsListReducer = (state = savedKeywordsState, action: AnyAction) => {
  switch (action.type) {
    case actions.ADD_KEYWORD_LIST:
      state = {
        ...state,
        data: [...state.data, action.payload.newList],
      };

      break;

    case actions.ADD_KEYWORDS_SUCCESS:
      const index = state.data.findIndex((list) => list.id === action.payload.id);
      state = {
        ...state,
        data:
          index !== -1
            ? [
                ...state.data.slice(0, index),
                {
                  ...state.data[index],
                  loaded: false,
                  keywords: [...action.payload.keywords, ...state.data[index].keywords],
                },
                ...state.data.slice(index + 1),
              ]
            : state.data,
      };
      break;

    case actions.UPDATE_KEYWORD_LIST:
      state = {
        ...state,
        data: state.data.map((list) =>
          list.id !== action.payload.updatedItem.id ? list : action.payload.updatedItem
        ),
      };

      break;

    case actions.FETCH_KEYWORDS_LIST:
      state = { ...state, loading: true, loaded: false };
      break;
    case actions.FETCH_KEYWORDS_LIST_STATS_SUCCESS:
      state = {
        ...state,
        data: action.payload.list,
        loading: false,
        loaded: true,
      };
      break;

    case actions.FETCH_KEYWORDS_LIST_STATS_FAILURE:
      state = {
        ...state,
        loading: false,
      };
      break;
    case actions.FETCH_KEYWORDS_LIST_SUCCESS:
      state = {
        ...state,
        total: action.payload.total,
        data: action.payload.list,
        loading: false,
        loaded: true,
      };
      break;

    case actions.FETCH_KEYWORDS_LIST_ERROR:
      state = { ...state, loading: false, loaded: true };
      break;

    case actions.DELETE_KEYWORDS_LIST:
      state = { ...state, loading: true };
      break;

    case actions.DELETE_KEYWORDS_LIST_SUCCESS:
      state = {
        ...state,
        data: action.payload.newList,
        loading: false,
      };
      break;

    case actions.DELETE_KEYWORDS_LIST_ERROR:
      state = { ...state, loading: false };
      break;

    case actions.DELETE_KEYWORDS_REQUEST:
      const deleteItemIndex = state.data.findIndex((list) => list.id === action.payload);
      state = {
        ...state,
        savedListItemLoading: true,
        data: [
          ...state.data.slice(0, deleteItemIndex),
          {
            ...state.data[deleteItemIndex],
            loading: true,
          },
          ...state.data.slice(deleteItemIndex + 1),
        ],
      };
      break;

    case actions.DELETE_KEYWORDS_SUCCESS:
      const idx = state.data.findIndex((item) => item.id === action.payload.id);
      state = {
        ...state,
        savedListItemLoading: false,
        data: [
          ...state.data.slice(0, idx),
          {
            ...state.data[idx],
            keywords: state.data[idx].keywords.filter(
              (keyword) => !action.payload.keywordIds.includes(keyword.id)
            ),
            loading: false,
          },
          ...state.data.slice(idx + 1),
        ],
      };
      break;

    case actions.DELETE_KEYWORDS_ERROR:
      const deleteErrorIdx = state.data.findIndex((list) => list.id === action.payload);
      state = {
        ...state,
        savedListItemLoading: false,
        data: [
          ...state.data.slice(0, deleteErrorIdx),
          {
            ...state.data[deleteErrorIdx],
            loading: false,
          },
          ...state.data.slice(deleteErrorIdx + 1),
        ],
      };

      break;

    // continue
    case actions.CREATE_KEYWORDS_LIST:
      state = { ...state };
      break;

    case actions.CREATE_KEYWORDS_LIST_SUCCESS:
      state = {
        ...state,
        data: [action.payload, ...state.data],
      };
      break;

    case currentListTypes.GET_CURRENT_SAVED_KEYWORDS_LIST_REQUEST:
      const loadingItemIndex = state.data.findIndex((list) => list.id === action.payload);
      state = {
        ...state,
        data: [
          ...state.data.slice(0, loadingItemIndex),
          {
            ...state.data[loadingItemIndex],
            loading: true,
          },
          ...state.data.slice(loadingItemIndex + 1),
        ],
      };
      break;

    case currentListTypes.GET_CURRENT_SAVED_KEYWORDS_LIST_SUCCESS:
      const successItemIndex = state.data.findIndex((list) => list.id === action.payload.id);
      state = {
        ...state,
        data: [
          ...state.data.slice(0, successItemIndex),
          {
            ...state.data[successItemIndex],
            ...action.payload,
          },
          ...state.data.slice(successItemIndex + 1),
        ],
      };
      break;

    case currentListTypes.GET_CURRENT_SAVED_KEYWORDS_LIST_FAILURE:
      const failedItemIndex = state.data.findIndex((list) => list.id === action.payload.id);
      state = {
        ...state,
        data: [
          ...state.data.slice(0, failedItemIndex),
          {
            ...state.data[failedItemIndex],
            loading: false,
          },
          ...state.data.slice(failedItemIndex + 1),
        ],
      };
      break;

    default:
  }

  return state;
};

export default keywordsListReducer;
