import React from "react";
import styled, { css } from "styled-components/macro";
import { Dropdown, DropdownProps } from "react-bootstrap";

const StyledDropdown = styled(Dropdown)<{ $transparent?: boolean }>`
  width: min-content;

  .dropdown-toggle {
    :not(:disabled):not(.disabled):active {
      background-color: ${({ theme }) => theme.color.primary};
      border-color: ${({ theme }) => theme.color.primary};
    }

    transition: 0.3s;

    &:hover,
    &:disabled,
    &:active,
    &:focus {
      background-color: ${({ theme }) => theme.color.primary};
      border-color: ${({ theme }) => theme.color.primary};
      box-shadow: none;
      color: ${({ theme }) => theme.contrast};
    }

    ${({ $transparent }) =>
      $transparent &&
      css`
        background-color: transparent !important;
        border-color: transparent !important;

        &:after {
          content: none;
        }
      `}
  }

  .dropdown-item {
    &:active {
      background-color: ${({ theme }) => theme.color.primary};
    }
  }
`;

interface DropdownButtonProps extends DropdownProps {
  transparent?: boolean;
}

const DropdownButton: React.FunctionComponent<DropdownButtonProps> = ({
  transparent,
  ...props
}) => {
  return <StyledDropdown {...props} $transparent={transparent} />;
};

export default DropdownButton;
