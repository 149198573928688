import { useNavigate } from "react-router-dom";
import StyledCampaigns from "./styled";
import Button from "components/Button";
import { useState, useEffect } from "react";
import { axiosForReverseAds } from "axiosConfig";
import internal from "stream";
import { max, min } from "cypress/types/lodash";

const Campaigns = () => {
  const navigate = useNavigate();
  const LIST_SIZE = 5;
  const [campaignList, setCampaignList] = useState<
    Array<{ id: number; name: string; countries: Array<string> }>
  >([]);
  const [items, setItems] = useState<Array<{ id: number; name: string; countries: Array<string> }>>(
    []
  );
  const [pageNumber, setPageNumber] = useState(1);
  const [btnRange, setBtnRange] = useState({ min: 1, max: 3 });

  useEffect(() => {
    const fetchSignals = async () => {
      const storedUser_id = localStorage.getItem("user_id");
      if (storedUser_id) {
        const response = await axiosForReverseAds.get("get_campaigns", {
          params: { user_id: storedUser_id },
        });
        setCampaignList(response.data.data);
        setItems(response.data.data.slice(0, LIST_SIZE));
        if (response.data.data.length < 3) {
          setBtnRange({ min: 1, max: response.data.data.length });
        }
      }
    };
    fetchSignals();
  }, []);

  const paginate = (startIndex: number) => {
    setItems(campaignList.slice(startIndex, startIndex + LIST_SIZE));
  };

  const showBtn = (direction: string) => {
    if (direction === "next") {
      if (btnRange.max === Math.ceil(campaignList.length / LIST_SIZE)) {
        return;
      } else if (btnRange.max + 3 > Math.ceil(campaignList.length / LIST_SIZE)) {
        setBtnRange({ min: btnRange.max + 1, max: Math.ceil(campaignList.length / LIST_SIZE) });
      } else {
        setBtnRange({ min: btnRange.max + 1, max: btnRange.max + 3 });
      }
    } else {
      if (btnRange.min === 1) {
        return;
      } else if (btnRange.min - 3 < 1) {
        setBtnRange({ min: 1, max: 3 });
      } else {
        setBtnRange({ min: btnRange.min - 3, max: btnRange.min - 1 });
      }
    }
  };

  return (
    <StyledCampaigns>
      <div className="container">
        <div className="campaign-header">Campaigns</div>
        <div className="btn-container">
          <Button className="btn" onClick={() => navigate("/app/keywords/create-campaign")}>
            Create Campaign
          </Button>
        </div>
        <div className="container-body">
          <table className="table">
            <thead className="thead">
              <tr>
                <th className="th1">Campaign Name</th>
                <th className="th1">Countries</th>
              </tr>
            </thead>

            <tbody>
              {items?.map((item, index) => (
                <tr key={index} className="tr">
                  <td
                    onClick={() => {
                      navigate("/app/keywords/campaign/" + item.id, {
                        state: { name: item.name },
                      });
                    }}
                  >
                    <span className="link">{item.name}</span>
                  </td>
                  <td>
                    {item.countries.map((i, ind) => (
                      <span key={ind}>
                        <span>{i}</span>
                        <span>{ind == item.countries.length - 1 ? " " : ", "}</span>
                      </span>
                    ))}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="pagination-container">
          <div
            className={btnRange.min === 1 ? "pagination-option disable" : "pagination-option"}
            onClick={() => {
              showBtn("prev");
            }}
          >
            &laquo;
          </div>
          {(() => {
            const jsxElements = [];
            for (let i = 0, j = 1; i < campaignList.length; i += LIST_SIZE, j++) {
              jsxElements.push(
                <div
                  key={i}
                  className={
                    j >= btnRange.min && j <= btnRange.max
                      ? pageNumber === j
                        ? "pagination-active"
                        : "pagination-option"
                      : "hide"
                  }
                  onClick={() => {
                    paginate(i);
                    setPageNumber(j);
                  }}
                >
                  {j}
                </div>
              );
            }
            return jsxElements;
          })()}
          <div
            className={
              btnRange.max * LIST_SIZE >= campaignList.length
                ? "pagination-option disable"
                : "pagination-option"
            }
            onClick={() => {
              showBtn("next");
            }}
          >
            &raquo;
          </div>
        </div>
      </div>
    </StyledCampaigns>
  );
};

export default Campaigns;
