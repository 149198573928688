import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { axiosForReverseAds } from "../../axiosConfig";
import { RootState } from "../store";
import { ADD_KEYWORDS_SUCCESS, fetchSavedKeywordList } from "./keyword-list.actions";
import { handlingError } from "../../constants/utility/error";
import IAddKeywordsResponse from "../../models/add-keywords-response.interface";
import { toast } from "react-toastify";
import { getCurrentSavedKeywordsList } from "./current-saved-keywords-list.actions";
import { hideComparePopup, hideCompetitorPopup, hideSuggestedPopup } from "./popup.actions";

export const CREATE_KEYWORDS_LIST = "CREATE_KEYWORDS_LIST";
export const CREATE_KEYWORDS_LIST_SUCCESS = "CREATE_KEYWORDS_LIST_SUCCESS";
export const CREATE_KEYWORDS_LIST_ERROR = "CREATE_KEYWORDS_LIST_ERROR";

export const UPDATE_KEYWORD_LIST_FORM_DATA = "UPDATE_KEYWORD_LIST_FORM_DATA";

export const DUPLICATE_KEYWORDS_LIST = "DUPLICATE_KEYWORDS_LIST";
export const DUPLICATE_KEYWORDS_LIST_SUCCESS = "DUPLICATE_KEYWORDS_LIST_SUCCESS";
export const DUPLICATE_KEYWORDS_LIST_ERROR = "DUPLICATE_KEYWORDS_LIST_ERROR";

export const EDIT_KEYWORDS_LIST = "EDIT_KEYWORDS_LIST";
export const EDIT_KEYWORDS_LIST_SUCCESS = "EDIT_KEYWORDS_LIST_SUCCESS";
export const EDIT_KEYWORDS_LIST_ERROR = "EDIT_KEYWORDS_LIST_ERROR";

export const addKeywords = (
  keywords: Array<string>,
  keywordListId: string
): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async (dispatch, getState) => {
    try {
      const params = {
        keywords: keywords,
        keywordGroupId: keywordListId,
      };
      const { data } = await axiosForReverseAds.post<IAddKeywordsResponse>("keywords/add", params);

      if (data.state === "success") {
        toast.success(data.message);

        // dispatch(getCurrentSavedKeywordsList(keywordListId));

        dispatch({
          type: ADD_KEYWORDS_SUCCESS,
          payload: {
            id: keywordListId,
            keywords: data.data,
          },
        });

        dispatch(getCurrentSavedKeywordsList(keywordListId));
      }
    } catch (error: any) {
      handlingError(error);
    }

    dispatch(hideComparePopup());
    dispatch(hideSuggestedPopup());
    dispatch(hideCompetitorPopup());
  };
};
