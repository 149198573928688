import React from "react";
import { connect, ConnectedProps, useDispatch } from "react-redux";
import { deleteCurrentUserCampaigns, getCampaigns } from "store/actions/campaigns.actions";
import CampaignList from "components/CampaignList";
import { RootState } from "store/store";
import { UserCampaignStyled } from "./styled";
import EmptyState from "./EmptyState";
import TableFilter, { IFilterColumn } from "components/TableFilter";
import { CampaignStatus, ENUM_CAMPAIGN_STATUS } from "store/states/campaign.state";
import { formatDate } from "constants/utility/date";
import { showConfirmPopup } from "store/actions/popup.actions";
import { useLocation, useNavigate } from "react-router-dom";
import Spinner from "components/Spinner";

const mapStateToProps = ({ user, campaigns, popup }: RootState) => ({
  campaigns,
  user,
  popup,
});
const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface IUserCampaignProps extends PropsFromRedux {}

const UserCampaigns: React.FunctionComponent<IUserCampaignProps> = ({ campaigns, popup }) => {
  const dispatch = useDispatch();

  const [currentFilters, setCurrentFilters] = React.useState<IFilterColumn | undefined>({
    status: ENUM_CAMPAIGN_STATUS.ACTIVE,
  });

  const [selectedCampaigns, setSelectedCampaigns] = React.useState<Array<string>>([]);

  React.useEffect(() => {
    dispatch(
      getCampaigns({
        page: 1,
        status: ENUM_CAMPAIGN_STATUS.ACTIVE,
      })
    );
  }, []);

  // React.useEffect(() => {
  //   if (currentFilters) {
  //     const filterObj = {
  //       username: currentFilters.userName,
  //       name: currentFilters.name,
  //       status: currentFilters.status,
  //       owner_email: currentFilters.owner_email,
  //       page: 1,
  //       start_from: currentFilters.startDate && formatDate(currentFilters.startDate.startDate),
  //       start_to: currentFilters.startDate && formatDate(currentFilters.startDate.endDate),
  //       end_from: currentFilters.endDate && formatDate(currentFilters.endDate.startDate),
  //       end_to: currentFilters.endDate && formatDate(currentFilters.endDate.endDate),
  //       created_from: currentFilters.created_at && formatDate(currentFilters.created_at.startDate),
  //       created_to: currentFilters.created_at && formatDate(currentFilters.created_at.endDate),
  //     };
  //     dispatch(getCampaigns(filterObj));
  //   }
  // }, [currentFilters, !popup.showBuildCampaign.show]);

  const onSelectionDeleteButtonClick = async () => {
    dispatch(
      showConfirmPopup({
        title: "Delete Campaigns",
        body: "Are you sure you want to delete these campaigns?",
        confirmText: "Delete",
        action: () => {
          dispatch(deleteCurrentUserCampaigns(selectedCampaigns));
        },
      })
    );
  };

  const onCampaignSelectionChange = (campaignId: string) => {
    if (!selectedCampaigns.includes(campaignId)) {
      setSelectedCampaigns([...selectedCampaigns, campaignId]);
    } else {
      const filteredCampaigns = selectedCampaigns.filter((el) => el !== campaignId);
      setSelectedCampaigns(filteredCampaigns);
    }
  };

  const applyFilters = (filters: IFilterColumn) => {
    setCurrentFilters((prev) => ({ ...prev, ...filters }));
  };

  const onFilterStatusChange = (status?: CampaignStatus) => {
    setCurrentFilters((prev) => ({ ...prev, status }));
    //
  };
  return (
    <UserCampaignStyled>
      <h2 className="page-title">Manage your campaign</h2>

      <div className="manage-campaign-wrapper">
        {/* {campaigns.data.length ? (
          <TableFilter
            currentFilters={currentFilters}
            onFilterStatusChange={onFilterStatusChange}
            onFilter={applyFilters}
          />
        ) : null} */}

        {!campaigns.loading && !campaigns.data.length && !currentFilters ? (
          <EmptyState />
        ) : !campaigns.loading || !campaigns.isDuplicating ? (
          <div className="campaign-list-wrapper">
            <div className="tab-selector">
              <div
                onClick={() => onFilterStatusChange(ENUM_CAMPAIGN_STATUS.ACTIVE)}
                className={`tab ${
                  currentFilters?.status === ENUM_CAMPAIGN_STATUS.ACTIVE ? "selected" : ""
                }`}
              >
                <span>Active</span>
              </div>
              <div
                onClick={() => onFilterStatusChange(ENUM_CAMPAIGN_STATUS.PENDING)}
                className={`tab ${
                  currentFilters?.status === ENUM_CAMPAIGN_STATUS.PENDING ? "selected" : ""
                }`}
              >
                <span>Pending</span>
              </div>
              <div
                onClick={() => onFilterStatusChange(ENUM_CAMPAIGN_STATUS.DRAFT)}
                className={`tab ${
                  currentFilters?.status === ENUM_CAMPAIGN_STATUS.DRAFT ? "selected" : ""
                }`}
              >
                <span>Draft</span>
              </div>
            </div>

            <div className="current-campaigns">
              <CampaignList
                onSelectionDeleteButtonClick={onSelectionDeleteButtonClick}
                currentFilters={currentFilters}
                selectedCampaigns={selectedCampaigns}
                onSelect={onCampaignSelectionChange}
                selectAll={(selected) =>
                  setSelectedCampaigns((prev) => Array.from(new Set([...prev, ...selected])))
                }
                unselectAll={(unselected) =>
                  setSelectedCampaigns((prev) => prev.filter((item) => !unselected.includes(item)))
                }
                total={campaigns.total}
                loading={campaigns.loading}
                campaigns={campaigns.data}
                loaded={campaigns.loaded}
              />
            </div>
          </div>
        ) : (
          <Spinner />
        )}
      </div>
    </UserCampaignStyled>
  );
};

UserCampaigns.defaultProps = {};

UserCampaigns.propTypes = {};

export default connector(UserCampaigns);
