import * as Yup from "yup";

export default Yup.object().shape({
  paymentForm: Yup.object().shape({
    startDate: Yup.date().default(null).nullable().required("*Start date required"),
    endDate: Yup.date().default(null).nullable().required("*End date required"),
    budgetPerDay: Yup.number()
      .required("*Budget required")
      .min(25, "*Please enter a minimum budget per day ($25)"),
  }),
});
