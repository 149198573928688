import styled, { css } from "styled-components/macro";

export const CampaignListStyled = styled.div``;

export const CampaignListTable = styled.div<{ admin?: boolean }>`
  margin-top: 21px;
  height: 100%;

  .empty-campaigns,
  .empty-search {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
  }

  .campaigns-loading {
    height: 300px;
  }

  .duplicate-button {
    transform: translate(5px, 0px);
    cursor: pointer;
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }

    img {
      filter: ${({ theme }) => theme.invertIcon};
      width: 20px;
      height: 20px;
      transition: 0.1s;
    }
  }

  .table-container {
    /* width: calc(100% - 37em); */
    height: 100%;

    .pagination {
      display: flex;
      gap: 15px;
      margin-top: 20px;
      justify-content: flex-end;
      align-items: center;
    }

    thead {
      tr {
        //border: 1px solid ${({ theme }) => theme.light};
        th {
          /* background-color: rgb(26, 26, 26); */
          color: ${({ theme }) => theme.contrast};
          font-size: ${({ theme }) => theme.size.md};
          transition: 0.1s;
          font-weight: 400;
          /* text-align: center; */
          vertical-align: middle;
          border: none;
          height: 45px;
          min-width: 120px;
        }
      }
    }

    tbody {
      .link-to-overview {
        color: ${({ theme }) => theme.contrast};
        text-decoration: none;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        cursor: pointer;
        transition: 0.1s;

        &:hover {
          text-decoration: underline;
        }
      }
      .status {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;

        .current-status {
          text-align: center;
        }

        .status-indicator {
          width: 10px;
          height: 10px;
          border-radius: 50%;
        }

        .Pending,
        .Paused {
          background-color: ${({ theme }) => theme.secondary300};
        }

        .Draft {
          background-color: ${({ theme }) => theme.color.primary};
        }

        .Active {
          background-color: ${({ theme }) => theme.secondary900};
        }

        .Inactive,
        .Deleted {
          background-color: ${({ theme }) => theme.color.gray};
        }
      }

      tr {
        td {
          word-break: break-all;
          height: 45px;
          font-size: ${({ theme }) => theme.size.md};
          z-index: 1;

          &:first-child {
            white-space: nowrap;
            text-align: left;
            /* height: 67px; */
          }
        }
      }
    }

    .table-responsive {
      overflow-y: auto;
      display: flex;
      margin-bottom: 20px;

      .freeze,
      .scrollable {
        min-height: 400px;
      }

      .scrollable {
        overflow-x: scroll;

        ::-webkit-scrollbar-track {
          border-radius: 10px;
        }

        ::-webkit-scrollbar {
          height: 10px;
          width: 5px;
        }

        ::-webkit-scrollbar-thumb {
          border-radius: 10px;
        }

        table {
          thead {
            tr {
              border-bottom: 1px solid ${({ theme }) => theme.tableBorder};
              transition: 0.1s;

              th {
                font-size: 14px;
                font-weight: 600;
                text-align: center;

                ${({ admin }) =>
                  admin
                    ? css`
                        &:nth-child(3) {
                          width: 100px;
                        }

                        &:nth-child(4) {
                          text-align: center;
                        }

                        &:nth-child(5) {
                          width: 100px;
                          text-align: center;
                        }

                        &:nth-child(6) {
                          text-align: center;
                          width: 100px;
                        }

                        &:nth-child(7) {
                          text-align: center;
                        }

                        &:nth-child(8) {
                          text-align: center;
                        }

                        &:nth-child(9) {
                          text-align: center;
                        }

                        &:nth-child(10) {
                          /* background: rgb(89, 89, 89); */
                          text-align: center;
                        }

                        &:nth-child(11) {
                          /* background: rgb(89, 89, 89); */
                          text-align: center;
                        }

                        &:nth-child(12) {
                          /* background: rgb(89, 89, 89); */

                          text-align: center;
                        }

                        &:nth-child(, 13) {
                          /* background: rgb(89, 89, 89); */
                        }
                      `
                    : css`
                        &:nth-child(3) {
                          text-align: center;
                        }

                        &:nth-child(4) {
                          text-align: center;
                          width: 100px;
                        }

                        &:nth-child(5) {
                          text-align: center;
                        }

                        &:nth-child(6) {
                          text-align: center;
                        }

                        &:nth-child(7) {
                          /* background: rgb(89, 89, 89); */
                          text-align: center;
                        }

                        &:nth-child(8) {
                          /* background: rgb(89, 89, 89); */
                          text-align: center;
                        }

                        &:nth-child(9) {
                          /* background: rgb(89, 89, 89); */
                          text-align: center;
                        }

                        &:nth-child(10) {
                          /* background: rgb(89, 89, 89); */
                          text-align: center;
                        }

                        &:nth-child(11) {
                          text-align: center;
                        }
                      `}
              }
            }
          }
          tbody {
            tr {
              border-bottom: 1px solid ${({ theme }) => theme.tableBorder};
              transition: 0.1s;

              &:last-child {
                border: none;
              }

              td {
                height: 46px;
                text-align: center;
              }
            }
          }
        }
      }

      .freeze {
        margin-bottom: 15px;

        table {
          thead {
            tr {
              border-bottom: 1px solid ${({ theme }) => theme.tableBorder};
              transition: 0.1s;

              th {
                font-size: 14px;
                font-weight: 600;

                &:nth-child(1) {
                  min-width: 30px;
                }

                ${({ admin }) =>
                  admin
                    ? css`
                        &:nth-child(2) {
                          width: 330px;
                          padding-left: 10px;
                        }
                      `
                    : css`
                        &:nth-child(2) {
                          width: 330px;
                          padding-left: 10px;
                        }
                      `}
              }
            }
          }

          tbody {
            tr {
              border-bottom: 1px solid ${({ theme }) => theme.tableBorder};
              transition: 0.1s;

              &:last-child {
                border: none;
              }

              td {
                ${({ admin }) =>
                  admin
                    ? css`
                        &:nth-child(2) {
                          padding-left: 10px;
                        }

                        &:nth-child(4) {
                          text-align: center;
                        }

                        &:nth-child(5) {
                          text-align: center;
                        }

                        &:nth-child(6) {
                          text-align: center;
                        }

                        &:nth-child(7) {
                          text-align: center;
                        }

                        &:nth-child(8) {
                          text-align: center;
                        }

                        &:nth-child(9) {
                          text-align: center;
                        }

                        &:nth-child(10) {
                          text-align: center;
                        }

                        &:nth-child(11) {
                          text-align: center;
                        }

                        &:nth-child(12) {
                          text-align: center;
                        }
                      `
                    : css`
                        &:nth-child(2) {
                          display: flex;
                          align-items: center;
                          justify-content: space-between;
                          padding-left: 10px;
                        }

                        &:nth-child(3) {
                          text-align: center;
                        }

                        &:nth-child(4) {
                          text-align: center;
                        }

                        &:nth-child(5) {
                          text-align: center;
                        }

                        &:nth-child(6) {
                          text-align: center;
                        }

                        &:nth-child(7) {
                          text-align: center;
                        }

                        &:nth-child(8) {
                          text-align: center;
                        }

                        &:nth-child(9) {
                          text-align: center;
                        }

                        &:nth-child(10) {
                          text-align: center;
                        }

                        &:nth-child(11) {
                          text-align: center;
                        }
                      `}
              }
            }
          }
        }
      }
    }
  }

  ${({ admin }) =>
    admin &&
    css`
      .tableWrapInner {
        /* width: calc(100% - 54em); */

        .table-responsive {
          /* margin-left: 54em; */
        }
      }
    `}
`;
