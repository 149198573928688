import React from "react";
import { ImportKeywordsFormStyled, NewKeywordItem } from "./styled";
import { Modal } from "react-bootstrap";
import { useFormik } from "formik";
import Button from "components/Button";
import { connect, ConnectedProps, useDispatch } from "react-redux";
import { RootState } from "store/store";
import Input from "components/Input";
import { hideImportKeywords } from "store/actions/popup.actions";
import DropdownSelect from "components/DropdownSelect";
import { createKeywordsList } from "store/actions/keyword-list.actions";
import { addKeywords } from "store/actions/keyword-list-form.actions";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import fileDownload from "js-file-download";
import axios from "axios";
import importKeywordSchema from "schemas/import-keyword.schema";
import CloseButton from "components/CloseButton";

interface IImportKeywordsPopupProps extends PropsFromRedux {}

const mapStateToProps = ({ popup, savedKeywordsList }: RootState) => ({
  popup,
  savedKeywordsList,
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const ImportKeywordsForm: React.FunctionComponent<IImportKeywordsPopupProps> = ({
  popup,
  savedKeywordsList,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const fileRef = React.useRef<HTMLInputElement>(null);
  const dropdownRef = React.useRef<any>(null);
  const [isCreateNew, displayCreateNew] = React.useState(false);

  const redirect = (id: string) => {
    // navigate(id);
  };

  const onFormSubmit = async (values: { name: string; keywords: Array<string> }) => {
    const existing = savedKeywordsList.data.find(
      (item) => item.name.toLowerCase() === values.name.toLowerCase()
    );
    if (existing) {
      // dispatch(addKeywords(values.keywords, existing.id));
      dispatch(addKeywords(popup.showImportKeywords.keywords, existing.id));
      dispatch(hideImportKeywords());
    } else {
      dispatch(
        // createKeywordsList(values.name, values.keywords, {
        //   redirect,
        // })
        createKeywordsList(values.name, popup.showImportKeywords.keywords, {})
      );
    }
  };

  // const current = savedKeywordsList.data.find(
  //   (savedList) => savedList.id === popup.showImportKeywords.id
  // );

  const [newKeyword, setNewKeyword] = React.useState("");

  const onCancelButtonClick = () => {
    dispatch(hideImportKeywords());
  };

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: importKeywordSchema,
    onSubmit: (values) => onFormSubmit(values),
    // initialValues: current
    //   ? { name: current.name, keywords: [] }
    //   : ({ name: "", keywords: [] } as { name: string; keywords: Array<string> }),
    initialValues: { name: "", keywords: [] },
  });

  const onExit = () => {
    formik.resetForm();
  };

  const { values, errors, isSubmitting, setFieldValue, touched } = formik;
  return (
    <ImportKeywordsFormStyled
      centered
      show={popup.showImportKeywords.show}
      backdrop="static"
      onExited={onExit}
    >
      <Modal.Header>
        <Modal.Title>
          <span>Manage Keyword List</span>
          <CloseButton top={10} right={34} dark onClick={() => dispatch(hideImportKeywords())} />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="manage-list-wrapper">
          <h6>List name</h6>
          <DropdownSelect
            onDropdownClose={() => displayCreateNew(false)}
            // disabled={!!current}
            // displayCreateLabel={isCreateNew}
            ref={dropdownRef}
            input
            create
            onCreateNew={() => {
              if (dropdownRef.current) {
                dropdownRef.current.state.searchResults = savedKeywordsList.data;
              }
            }}
            disable
            error={touched.name && errors.name}
            placeholder="Eg. Keyword List 1"
            options={savedKeywordsList.data}
            searchBy="name"
            labelField="name"
            valueField="name"
            searchFn={(dropdownProps: any) => {
              if (!dropdownProps.state.search) {
                displayCreateNew(false);
                return;
              }
              if (
                savedKeywordsList.data.find(
                  (item) => item.name.toLowerCase() === dropdownProps.state.search.toLowerCase()
                )
              ) {
                displayCreateNew(false);
              } else {
                displayCreateNew(true);
              }
              return savedKeywordsList.data.filter((item) =>
                item.name.toLowerCase().includes(dropdownProps.state.search.toLowerCase())
              );
            }}
            onChange={(savedList: Array<{ name: string }>) => {
              if (savedList.length) {
                setFieldValue("name", savedList[0].name);
              } else {
                setFieldValue("name", "");
                if (dropdownRef.current) {
                  dropdownRef.current.state.searchResults = savedKeywordsList.data;
                }
              }
            }}
            // values={current ? [{ name: current.name }] : values.name ? [{ name: values.name }] : []}
            values={values.name ? [{ name: values.name }] : []}
            name="name"
            noDataRenderer={() => null}
          />
        </div>
        {/* <input
          onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
            if (e.target.files) {
              if (e.target.files[0].type !== "text/plain") {
                toast.error("Unsupport file format");
              } else {
                const reader = new FileReader();
                reader.onload = async (e) => {
                  const content = reader.result;
                  if (content && typeof content === "string") {
                    const importedKeywords = content
                      .trim()
                      .replace(/(?:\\[rn]|[\r\n]+)+/g, "\n")
                      .split("\n");

                    setFieldValue(
                      "keywords",
                      Array.from(new Set([...importedKeywords, ...values.keywords]))
                    );
                  }
                };
                reader.readAsText(e.target.files[0]);
              }
            }
            e.target.value = "";
          }}
          ref={fileRef}
          className="file-input"
          type="file"
        /> */}
        {/* <div className="keyword-insert">
          <form
            onSubmit={(e) => {
              e.preventDefault();

              if (newKeyword.trim().length) {
                setFieldValue("keywords", Array.from(new Set([...values.keywords, newKeyword])));
              }
              setNewKeyword("");
            }}
          >
            <Input
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNewKeyword(e.target.value)}
              type="text"
              placeholder="Add keyword"
              value={newKeyword}
            />
          </form>
          <div className="file-button" onClick={() => fileRef.current && fileRef.current.click()}>
            Load from file
          </div>
        </div> */}
        {/* <div className="download-sample">
          <span
            className="download-button"
            onClick={async () => {
              const file = await axios.get<Blob>(sampleFile, {
                responseType: "blob",
              });

              fileDownload(file.data, "SampleKeywords.txt");
            }}
          >
            Download Sample File
          </span>
        </div> */}
        {/* <div>
          <ul className="new-keyword-list">
            {values.keywords.map((newKeyword) => (
              <NewKeywordItem key={newKeyword}>
                <span
                  onClick={() =>
                    setFieldValue(
                      "keywords",
                      values.keywords.filter((keyword) => keyword !== newKeyword)
                    )
                  }
                  className="delete-insert-keyword"
                >
                  ✕
                </span>
                {newKeyword}
              </NewKeywordItem>
            ))}
          </ul>
        </div> */}

        <div className="footer-bar">
          {/* {errors.keywords ? (
            <span className="errorMessage">Please add some keyword</span>
          ) : (
            <span>Total {values.keywords.length} Keywords</span>
          )} */}
          <div className="action-buttons">
            <Button
              width={125}
              color="gray"
              type="button"
              onClick={onCancelButtonClick}
              className="close-button"
            >
              Cancel
            </Button>
            <Button
              width={125}
              onClick={() => formik.submitForm()}
              className="save-button"
              disabled={isSubmitting}
            >
              {isSubmitting ? "Please wait..." : "Save"}
            </Button>
          </div>
        </div>
      </Modal.Body>
    </ImportKeywordsFormStyled>
  );
};

export default connector(ImportKeywordsForm);
