import React from "react";
import { CustomModal } from "./styled";
import { Modal } from "react-bootstrap";
import Button from "components/Button";
import { RootState } from "store/store";
import { connect, ConnectedProps, useDispatch } from "react-redux";
import { hideExportPopup } from "store/actions/popup.actions";
import Checkbox from "components/Checkbox";
import CloseButton from "components/CloseButton";
import api from "services/api";

interface IExportPopup extends PropsFromRedux {}

const mapStateToProps = ({ popup }: RootState) => ({
  popup,
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const ExportPopup: React.FunctionComponent<IExportPopup> = ({ popup }) => {
  const dispatch = useDispatch();

  const onHide = () => {
    dispatch(hideExportPopup());
  };

  const [csvChecked, setCSV] = React.useState(false);
  const [excelChecked, setExcel] = React.useState(false);

  const onExport = () => {
    switch (popup.showExportPopup.type) {
      case "keywords":
        csvChecked &&
          api.keywords.export.keywordSearchVolume("csv", popup.showExportPopup.data.keywords);
        excelChecked &&
          api.keywords.export.keywordSearchVolume("xlsx", popup.showExportPopup.data.keywords);
        break;

      case "campaigns":
        csvChecked && api.campaigns.export("csv");
        excelChecked && api.campaigns.export("xlsx");
        break;

      case "performance":
        csvChecked &&
          popup.showExportPopup.data.campaign &&
          api.campaign.export(popup.showExportPopup.data.campaign.id || "", "csv", {
            startDate: popup.showExportPopup.data.campaign.startDate,
            endDate: popup.showExportPopup.data.campaign.endDate,
          });
        excelChecked &&
          popup.showExportPopup.data.campaign &&
          api.campaign.export(popup.showExportPopup.data.campaign.id || "", "xlsx", {
            startDate: popup.showExportPopup.data.campaign.startDate,
            endDate: popup.showExportPopup.data.campaign.endDate,
          });
        break;

      default:
    }
  };

  return (
    <CustomModal keyboard show={popup.showExportPopup.show} onHide={onHide} centered>
      <Modal.Header>
        <span>Export</span>
        <CloseButton onClick={onHide} top={10} right={34} dark />
      </Modal.Header>
      <Modal.Body>
        <div className="export-content">
          <div onClick={() => setCSV((prev) => !prev)} className="checkbox-wrapper">
            <Checkbox checked={csvChecked} size="sm" />
            <span>CSV</span>
          </div>

          <div onClick={() => setExcel((prev) => !prev)} className="checkbox-wrapper">
            <Checkbox checked={excelChecked} size="sm" />
            <span>Excel</span>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button color="gray" onClick={onHide}>
          Cancel
        </Button>
        <Button disabled={!csvChecked && !excelChecked} onClick={onExport}>
          Download
        </Button>
      </Modal.Footer>
    </CustomModal>
  );
};

export default connector(ExportPopup);
