import { IDataForSEOV3 } from "../../constants/utility/keyword";

const keywordsListState = {
  loading: false,
  data: [],
  error: null,
  savedListItemLoading: false,
  loaded: false,
  total: 0,
} as IKeywordsListState;

interface IKeywordsListState {
  loading: boolean;
  data: Array<ISavedKeywordList>;
  error: null;
  loaded: boolean;
  savedListItemLoading: boolean;
  total: number;
}

export interface IAvgSavedKeywordListStats {
  avgMonthlySearches: number;
  averageDifficulty: string;
  averageGoogleCpc: number;
  averageRADCpc: number;
}

export interface ISavedKeyword {
  id: string;
  keywordGroupId: string;
  keyword: string;
  stats: IDataForSEOV3;
}

export interface ISavedKeywordList {
  id: string;
  name: string;
  keywords: ISavedKeyword[];
  loading: boolean;
  loaded: boolean;
  stats?: IAvgSavedKeywordListStats;
}

export default keywordsListState;
