import * as types from "../action-types/admin-news-feed.types";
import { INewsFeed } from "../states/admin-news-feed.state";

export function getAdminNewsFeed(page?: number, pageLimit?: number) {
  return {
    type: types.GET_ADMIN_NEWS_FEED,
    page,
    pageLimit,
  };
}

export function getAdminNewsFeedRequest(): types.GetAdminNewsFeedRequestAction {
  return {
    type: types.GET_ADMIN_NEWS_FEED_REQUEST,
  };
}

export function getAdminNewsFeedSuccess(
  payload: INewsFeed[],
  total: number
): types.GetAdminNewsFeedSuccessAction {
  return {
    type: types.GET_ADMIN_NEWS_FEED_SUCCESS,
    payload,
    total,
  };
}

export function getAdminNewsFeedFailure(error: string): types.GetAdminNewsFeedFailureAction {
  return {
    type: types.GET_ADMIN_NEWS_FEED_FAILURE,
    error,
  };
}

//

export function getNewsFeedById(payload: number) {
  return {
    type: types.GET_NEWS_FEED_BY_ID,
    payload,
  };
}

export function resetCurrentFeed() {
  return {
    type: types.RESET_NEWS_FEED_BY_ID,
  };
}

export function getNewsFeedByIdRequest(): types.GetNewsFeedByIdRequestAction {
  return {
    type: types.GET_NEWS_FEED_BY_ID_REQUEST,
  };
}

export function getNewsFeedByIdSuccess(payload: INewsFeed): types.GetNewsFeedByIdSuccessAction {
  return {
    type: types.GET_NEWS_FEED_BY_ID_SUCCESS,
    payload,
  };
}

export function getNewsFeedByIdFailure(error: string): types.GetNewsFeedByIdFailureAction {
  return {
    type: types.GET_NEWS_FEED_BY_ID_FAILURE,
    error,
  };
}

//

export function updateAdminNewsFeed(payload: INewsFeed, history: any) {
  return {
    type: types.UPDATE_ADMIN_NEWS_FEED,
    payload,
    history,
  };
}

export function updateAdminNewsFeedRequest(): types.UpdateAdminNewsFeedRequestAction {
  return {
    type: types.UPDATE_ADMIN_NEWS_FEED_REQUEST,
  };
}

export function updateAdminNewsFeedSuccess(
  payload: INewsFeed
): types.UpdateAdminNewsFeedSuccessAction {
  return {
    type: types.UPDATE_ADMIN_NEWS_FEED_SUCCESS,
    payload,
  };
}

export function updateAdminNewsFeedFailure(error: string): types.UpdateAdminNewsFeedFailureAction {
  return {
    type: types.UPDATE_ADMIN_NEWS_FEED_FAILURE,
    error,
  };
}

//

export function createAdminNewsFeed(payload: INewsFeed, history: any) {
  return {
    type: types.CREATE_ADMIN_NEWS_FEED,
    payload,
    history,
  };
}

export function createAdminNewsFeedRequest(): types.CreateAdminNewsFeedRequestAction {
  return {
    type: types.CREATE_ADMIN_NEWS_FEED_REQUEST,
  };
}

export function createAdminNewsFeedSuccess(
  payload: INewsFeed
): types.CreateAdminNewsFeedSuccessAction {
  return {
    type: types.CREATE_ADMIN_NEWS_FEED_SUCCESS,
    payload,
  };
}

export function createAdminNewsFeedFailure(error: string): types.CreateAdminNewsFeedFailureAction {
  return {
    type: types.CREATE_ADMIN_NEWS_FEED_FAILURE,
    error,
  };
}

//

export function deleteAdminNewsFeed(payload: number): types.DeleteAdminNewsFeedAction {
  return {
    type: types.DELETE_ADMIN_NEWS_FEED,
    payload,
  };
}

export function deleteAdminNewsFeedRequest(): types.DeleteAdminNewsFeedRequestAction {
  return {
    type: types.DELETE_ADMIN_NEWS_FEED_REQUEST,
  };
}

export function deleteAdminNewsFeedSuccess(
  payload: number
): types.DeleteAdminNewsFeedSuccessAction {
  return {
    type: types.DELETE_ADMIN_NEWS_FEED_SUCCESS,
    payload,
  };
}

export function deleteAdminNewsFeedFailure(error: string): types.DeleteAdminNewsFeedFailureAction {
  return {
    type: types.DELETE_ADMIN_NEWS_FEED_FAILURE,
    error,
  };
}
