import { axiosForReverseAds } from "../../axiosConfig";
import { formatDate } from "../../constants/utility/date";
import { AnyAction } from "redux";
import { RootState } from "../store";
import { ThunkAction } from "redux-thunk";
import { handlingError } from "../../constants/utility/error";
import { hideScreenLoading } from "./popup.actions";

export const CREATE_PAYPAL_ORDER = "CREATE_PAYPAL_ORDER";
export const CREATE_PAYPAL_ORDER_SUCCESS = "CREATE_PAYPAL_ORDER_SUCCESS";
export const CREATE_PAYPAL_ORDER_FAILURE = "CREATE_PAYPAL_ORDER_FAILURE";

export const VALIDATE_CREDIT_CARD = "VALIDATE_CREDIT_CARD";
export const VALIDATE_CREDIT_CARD_SUCCESS = "VALIDATE_CREDIT_CARD_SUCCESS";
export const VALIDATE_CREDIT_CARD_FAILURE = "VALIDATE_CREDIT_CARD_FAILURE";

export const SUBMIT_PAYMENT_PENDING = "SUBMIT_PAYMENT_PENDING";
export const SUBMIT_PAYMENT_SUCCESS = "SUBMIT_PAYMENT_SUCCESS";
export const SUBMIT_PAYMENT_FAILURE = "SUBMIT_PAYMENT_FAILURE";
export interface ICARD_FORM {
  fullName: string;
  cardNumber: string;
  expirationMonth: string;
  expirationYear: string;
  securityCode: string;
}

export interface IBUDGET_FORM {
  budgetPerDay: number;
  startDate: Date;
  endDate: Date;
  discountCode?: string;
}

export const createCampaignPaymentOrder = (
  id: string,
  card: ICARD_FORM,
  paymentMethod: string,
  paymentForm: IBUDGET_FORM,
  setPaymentLoading: (loading: boolean) => void
): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: VALIDATE_CREDIT_CARD,
      });

      const formatStartDate = paymentForm.startDate && formatDate(paymentForm.startDate);
      const formatEndDate = paymentForm.endDate && formatDate(paymentForm.endDate);

      const result = await axiosForReverseAds.post<{
        nextUrl?: string;
        paymentId?: string;
        message?: string;
        successful?: string;
      }>("/campaign/payment", {
        paymentType: paymentMethod,
        discountCode: paymentForm.discountCode,
        budgetPerDay: paymentForm.budgetPerDay,
        startDate: formatStartDate,
        endDate: formatEndDate,
        campaignId: id,
        card: {
          fullName: card.fullName,
          number: card.cardNumber,
          expMonth: Number(card.expirationMonth),
          expYear: Number(`20${card.expirationYear}`),
          cvc: Number(card.securityCode),
        },
      });

      if (result && result.status === 200 && result.data.nextUrl) {
        dispatch({
          type: SUBMIT_PAYMENT_SUCCESS,
        });
        setPaymentLoading(false);
        window.location.href = result.data.nextUrl;
      }
    } catch (error: any) {
      handlingError(error);
      dispatch(hideScreenLoading());
      setPaymentLoading(false);
    }
  };
};
