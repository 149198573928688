import * as Yup from "yup";

export default Yup.object().shape({
  password: Yup.string()
    .required("Please enter your password")
    .min(8, "Password must be 8 characters long")
    .matches(
      /^[a-zA-Z0-9.!@?#"$%&:';()*\+,\/;\-=[\\\]\^_{|}<>~`]+$/,
      "Please enter a valid password character"
    ),
  confirmPassword: Yup.string()
    .required("Please enter your confirm password")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
});
