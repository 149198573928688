interface IKeywordForm {
  keywordList: {
    id: string;
    name: string;
  };
  mode: "create" | "edit" | "delete" | "duplicate";
  isSubmitting: boolean;
}

const state = {
  keywordList: {
    id: "",
    name: "",
  },
  mode: "create",
  isSubmitting: false,
} as IKeywordForm;

export default state;
