import CampaignAdmin from "modules/Client/pages/CampaignReport/CampaignAdmin";
import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "store/store";
import { CampaignAdminSettingsStyled } from "./styled";

interface ICampaignAdminSettingsProps {}

const CampaignAdminSettings: React.FunctionComponent<ICampaignAdminSettingsProps> = () => {
  return (
    <CampaignAdminSettingsStyled>
      <CampaignAdmin />
    </CampaignAdminSettingsStyled>
  );
};

export default CampaignAdminSettings;
