import styled, { css } from "styled-components";
import { Modal } from "react-bootstrap";
import betaIcon from "assets/beta/beta.png";

// const betaIconDark = ("/assets/beta/beta.png");
// const betaIconLight = ("/assets/beta/beta.png");

export const BetaAlertModal = styled(Modal)<{ $currentTheme: "light" | "dark" }>`
  .modal-content {
    padding: 0 20px;
    background-color: ${({ theme }) => theme.alertModalBackground};
    border-radius: 10px;
    min-height: 520px;
    color: ${({ theme }) => theme.contrast};
    border: 2px solid ${({ theme }) => theme.dark};
  }

  .modal-header {
    padding: 30px;
    padding-bottom: 0;
    border-bottom: none;

    .modal-title {
      font-size: 18px;

      .close-alert {
        position: absolute;
        top: 10px;
        right: 15px;
        font-size: 14px;
        font-weight: 600;
        cursor: pointer;

        &:hover {
          font-weight: bold;
        }
      }
    }
  }

  .modal-body {
    padding: 0;
  }

  .beta-content {
    margin: 0px auto;
    text-align: center;

    .beta-message {
      white-space: pre;
      font-size: 14px;
    }
  }

  .beta-icon {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: auto;
    height: 250px;
    margin: 10px auto;
  }

  ${({ $currentTheme }) =>
    $currentTheme === "light"
      ? css`
          .beta-icon {
            background-image: url(${betaIcon});
          }
        `
      : css`
          .beta-icon {
            background-image: url(${betaIcon});
          }
        `}
`;
