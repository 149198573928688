import styled from "styled-components/macro";

export const ResetPasswordStyled = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;

  .wrapper {
    width: 500px;
    margin: 0 auto;
  }

  .redirect {
    margin-top: 30px;
  }

  .input-wrapper {
    margin-bottom: 15px;
  }
`;
