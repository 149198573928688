import React from "react";
import { Form } from "react-bootstrap";
import { FormCheckbox, PaymentMethodStyled, PaymentSelectorStyled } from "./styled";
import CreditCard from "./CreditCard";
import { FormikProps } from "formik";

import Checkbox from "components/Checkbox";
import RadioButton from "components/RadioButton";
import { IPostCampaignData } from "constants/utility/campaign";

export const PAYMENT_METHOD_LIST: Array<IPaymentMethod> = [
  {
    title: "Credit Card",
    key: "credit card",
    message: "Instant authorization and additional security through 3-D Secure.",
    component: (
      formikProps?: FormikProps<IPostCampaignData>,
      ref?: React.RefObject<HTMLDivElement>
    ) => (formikProps ? <CreditCard formikProps={formikProps} ref={ref} /> : null),
  },
];

export interface IPaymentMethod {
  title: string;
  key: string;
  message: string;
  component: (
    props?: FormikProps<IPostCampaignData>,
    ref?: React.RefObject<HTMLDivElement>
  ) => JSX.Element | null;
}

interface IPaymentMethosSelectorProps {
  formikProps: FormikProps<IPostCampaignData>;
  paymentMethod: string;
  selectPaymentMethod: (method: string) => void;
}

const PaymentMethodSelector: React.FunctionComponent<IPaymentMethosSelectorProps> = ({
  formikProps,
  paymentMethod,
  selectPaymentMethod,
}) => {
  const [accordianContentHeight, setAccordianContentHeight] = React.useState(225);

  const itemsRef = React.useRef<Array<HTMLDivElement>>([]);
  const accordianChildRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    itemsRef.current = itemsRef.current.slice(0, PAYMENT_METHOD_LIST.length);
  }, [PAYMENT_METHOD_LIST.length]);

  React.useEffect(() => {
    if (accordianChildRef.current && accordianChildRef.current.scrollHeight) {
      setAccordianContentHeight(accordianChildRef.current.scrollHeight);
    }
  }, [formikProps.touched, formikProps.errors, paymentMethod]);

  return (
    <PaymentSelectorStyled>
      <FormCheckbox>
        <h6 className="section-header">
          <b>Payment Method</b>
        </h6>

        {PAYMENT_METHOD_LIST.map((payment, idx: number) => (
          <PaymentMethodStyled
            contentHeight={paymentMethod === payment.key ? accordianContentHeight : 0}
            onClick={() => {
              if (paymentMethod !== payment.key) {
                setAccordianContentHeight(0);
                selectPaymentMethod(payment.key);
              }
            }}
            key={payment.key}
          >
            <div className="payment-selector">
              <div className="checkbox-input">
                <RadioButton checked={paymentMethod === payment.key} />
              </div>
              <div className="method-detail">
                <h6 className="title">
                  <b>{payment.title}</b>
                </h6>
                <p className="message">{payment.message}</p>
              </div>
            </div>

            <div className="payment-content">
              {paymentMethod === payment.key && payment.component(formikProps, accordianChildRef)}
            </div>
          </PaymentMethodStyled>
        ))}
      </FormCheckbox>
    </PaymentSelectorStyled>
  );
};

export default PaymentMethodSelector;
