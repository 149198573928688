import React from "react";
import { DateRangeWrapper } from "./styled";
import { createStaticRanges, DateRangePicker, defaultStaticRanges, Range } from "react-date-range";
import { addDays, endOfDay, startOfDay, isSameDay } from "date-fns";

interface IDateRangeProps {
  minDate?: Date;
  maxDate?: Date;
  ranges: Range[];
  setStartDate?: (start?: Date) => void;
  setEndDate?: (end?: Date) => void;
  onSelection?: (range: Range) => void;
  showRange?: boolean;
}

const customRanges = createStaticRanges([
  {
    isSelected: (range) => {
      return !!(
        range.startDate &&
        range.endDate &&
        isSameDay(range.startDate, startOfDay(addDays(new Date(), -7))) &&
        isSameDay(range.endDate, endOfDay(addDays(new Date(), -1)))
      );
    },
    label: "Last 7  Days",
    range: () => ({
      startDate: startOfDay(addDays(new Date(), -7)),
      endDate: endOfDay(addDays(new Date(), -1)),
    }),
  },

  {
    isSelected: (range) => {
      return !!(
        range.startDate &&
        range.endDate &&
        isSameDay(range.startDate, startOfDay(addDays(new Date(), -30))) &&
        isSameDay(range.endDate, endOfDay(addDays(new Date(), -1)))
      );
    },
    label: "Last 30  Days",
    range: () => ({
      startDate: startOfDay(addDays(new Date(), -30)),
      endDate: endOfDay(addDays(new Date(), -1)),
    }),
  },
]);

const staticRanges = [
  defaultStaticRanges[0], // today
  defaultStaticRanges[1], // yesterday
  defaultStaticRanges[2], // this week
  customRanges[0], // last 7 days
  defaultStaticRanges[3], // last week
  customRanges[1], // last 30 days
  defaultStaticRanges[5], // last month
];

const DateRange: React.FunctionComponent<IDateRangeProps> = ({
  ranges,
  minDate,
  maxDate,
  setStartDate,
  setEndDate,
  onSelection,
  showRange,
  ...props
}) => {
  return (
    <DateRangeWrapper showRange={showRange}>
      <DateRangePicker
        showMonthAndYearPickers={false}
        // showDateDisplay={false}
        inputRanges={[]}
        endDatePlaceholder="To"
        startDatePlaceholder="From"
        staticRanges={staticRanges}
        rangeColors={["#FE0099"]}
        minDate={minDate}
        maxDate={maxDate}
        ranges={ranges}
        onChange={({ selection }) => {
          if (selection) {
            onSelection && onSelection(selection);
            setStartDate && setStartDate(selection.startDate);
            setEndDate && setEndDate(selection.endDate);
          }
        }}
        {...props}
      />
    </DateRangeWrapper>
  );
};

export default DateRange;
