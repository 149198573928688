import styled, { css } from "styled-components/macro";

export const RadioButtonStyled = styled.div<{ checked: boolean }>`
  height: 20px;
  width: 20px;
  border-radius: 50%;
  transform: translate(0px, 0px);
  position: relative;
  border-width: 0 2px 2px 0;
  cursor: pointer;

  &:before {
    content: "";
    position: absolute;
    border: 1px solid rgb(203, 203, 203);
    z-index: 1;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.radio};
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  ${({ checked }) =>
    checked
      ? css`
          &:after {
            content: "";
            position: absolute;
            border-width: 0 2px 2px 0;
            border-color: rgb(203, 203, 203);
            border-style: solid;
            transform: rotate(45deg);
            z-index: 2;
            top: 4px;
            left: 8px;
            width: 5px;
            height: 10px;
            border-color: #ffffff;
          }

          &:before {
            background-color: ${({ theme }) => theme.color.primary};
          }
        `
      : css`
          &:after {
            border-color: solid ${({ theme }) => theme.color.primary};
          }
        `}
`;
