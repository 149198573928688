import styled, { css } from "styled-components";

export const TablePaginationStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  font-size: 12px;
  height: 45px;
  align-items: center;
  padding: 0 10px;
  gap: 15px;
  color: ${({ theme }) => theme.contrast};

  .rows-tab {
    display: flex;
    align-items: center;
    gap: 10px;

    .rows-selector {
      padding: 3px;
      border-radius: 5px;
      width: auto;
      min-height: 18px;
      font-size: 12px;
      outline: none;
    }
  }

  .pagination-actions {
    display: flex;
    gap: 20px;
  }
`;

export const PaginationButton = styled.span<{ disabled: boolean }>`
  cursor: pointer;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
`;
