import React from "react";
import { RadioButtonStyled } from "./styled";

interface IRadioButtonProps {
  checked: boolean;
}

const RadioButton: React.FunctionComponent<IRadioButtonProps> = ({ checked }) => {
  return <RadioButtonStyled checked={checked} />;
};

export default RadioButton;
