import { IErrorMessage } from "../../services/types";
import { IBankInfo } from "../states/admin.state";

// Bank Info

export const GET_BANK_LIST = "admin-actions/GET_BANK_LIST";

export const GET_BANK_LIST_REQUEST = "admin-actions/GET_BANK_LIST_REQUEST";
export interface GetBankListRequestAction {
  type: typeof GET_BANK_LIST_REQUEST;
}

export const GET_BANK_LIST_SUCCESS = "admin-actions/GET_BANK_LIST_SUCCESS";
export interface GetBankListSuccessAction {
  type: typeof GET_BANK_LIST_SUCCESS;
  banks: IBankInfo[];
}

export const GET_BANK_LIST_FAILURE = "admin-actions/GET_BANK_LIST_FAILURE";
export interface GetBankListFailureAction {
  type: typeof GET_BANK_LIST_FAILURE;
  error: IErrorMessage;
}

export type BankListAction =
  | GetBankListRequestAction
  | GetBankListSuccessAction
  | GetBankListFailureAction;
