import styled from "styled-components/macro";

export const SelectedKeywordsBar = styled.aside`
  background-color: #000000;
  min-width: 360px;
  padding: 1rem;

  .avg-tab {
    background: ${({ theme }) => theme.background};
    margin: 10px 0;
    color: white;
    font-weight: 600;
    padding: 5px 10px;
    display: grid;
    grid-template-columns: 40% 15% 15% 15% 15%;
    font-size: 12px;

    .avg-cpc-title {
      grid-column: 1/4;
      text-align: right;
      margin-right: 20px;
    }

    .avg-google {
      text-align: center;
      grid-column: 4/5;
    }

    .avg-rad {
      text-align: center;
      grid-column: 5/6;
    }
  }

  .selected-table {
    max-height: 500px;
    overflow-y: scroll;
    display: grid;
    grid-template-rows: 25px 25px;
    grid-template-columns: 40% 15% 15% 15% 15%;
    font-size: 14px;
    padding: 0 5px;
    color: #ffffff;

    .keyword-column {
      grid-row: 1/2;
      grid-column: 1/2;
    }

    .search-column {
      grid-row: 1/2;
      grid-column: 2/3;
      text-align: center;
    }

    .score-column {
      grid-row: 1/2;
      grid-column: 3/4;
      text-align: center;
    }

    .cpc-column {
      grid-row: 1/2;
      grid-column: 4/6;
      text-align: center;
    }

    .google-icon-column {
      grid-row: 2/3;
      grid-column: 4/5;
      text-align: center;
    }

    .rad-icon-column {
      grid-row: 2/3;
      grid-column: 5/6;
      text-align: center;
    }
  }

  .keyword-table-list {
    overflow-y: hidden;
    padding: 0 10px;
    margin: 0;
  }

  .keywords-body {
    font-size: 12px;
    display: grid;
    grid-template-columns: 40% 15% 15% 15% 15%;
    border-bottom: 1px solid gray;
    height: 40px;
    align-items: end;
    padding: 3px 0;

    .keyword-column {
      grid-column: 1/2;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .search-column {
      grid-row: 1/2;
      grid-column: 2/3;
      text-align: center;
    }

    .score-column {
      text-align: center;
    }

    .google-cpc-column {
      grid-column: 4/5;
      text-align: center;
    }

    .rad-cpc-column {
      grid-column: 5/6;
      text-align: center;
    }
  }

  .txt-center {
    text-align: center;
  }

  .empty-state {
    color: white;
  }
  .header.row div {
    color: white;
  }

  .difficultyHigh {
    color: #e32a2a;
  }

  .difficultyMedium {
    color: #eccf27;
  }

  .difficultyLow {
    color: #6ad14e;
  }

  .comp-text {
    color: rgb(151, 194, 182);
  }

  .rad-text {
    color: rgb(63, 78, 85);
  }

  .empty-state {
    font-size: ${({ theme }) => theme.size.lg};
  }

  .selected-brandIcon {
    height: 12px;
    width: 12px;
  }

  .keywordbar-title {
    color: ${({ theme }) => theme.color.green};
    font-size: 18px;
    font-weight: 600;
    padding-bottom: 1rem;
    margin-bottom: 1.5rem;
    border-bottom: rgb(226, 226, 226) 1px solid;
  }

  .container {
    /* max-height: 400px; */
    /* overflow-y: scroll; */

    .item {
      color: rgb(190, 190, 190);
      font-size: 12px;
      padding: 17px 0px 3px 0px;
      border-bottom: 1px solid rgb(233, 233, 233);
    }

    .header {
      color: rgb(99, 118, 120);
      font-size: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .header > .col,
    .item > .col {
      padding: 0;
      text-align: center;
    }

    .header > .col-md-4,
    .item > .col-md-4,
    .search {
      padding: 0;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
`;
