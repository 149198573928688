import styled, { css } from "styled-components/macro";

export const DateRangeWrapper = styled.div<{ showRange?: boolean }>`
  .rdrStaticRange,
  .rdrDefinedRangesWrapper,
  .rdrCalendarWrapper,
  .rdrDateDisplayWrapper {
    color: ${({ theme }) => theme.contrast};
    background: ${({ theme }) => theme.background};
    border-color: ${({ theme }) => theme.lightBorder};
    border-radius: 10px;
  }

  .rdrDateDisplayItem {
    color: ${({ theme }) => theme.contrast};
    background: ${({ theme }) => theme.background};
    border-color: ${({ theme }) => theme.color.primary};
  }

  .rdrStaticRange {
    &:hover {
      .rdrStaticRangeLabel {
        background-color: ${({ theme }) => theme.background};
      }
    }
  }

  .rdrDay > .rdrDayNumber > span {
    color: ${({ theme }) => theme.contrast} !important;

    &:after {
      background: ${({ theme }) => theme.color.primary} !important;
    }
  }

  .rdrDayDisabled > .rdrDayNumber > span {
    color: ${({ theme }) => theme.checkbox} !important;
  }

  .rdrDayPassive > .rdrDayNumber > span {
    color: ${({ theme }) => theme.homeIcon} !important;
  }

  .rdrDayDisabled {
    background: transparent;
  }

  .rdrPprevButton {
    background: ${({ theme }) => theme.color.primary};
    i {
      border-color: transparent white transparent transparent;
    }
  }

  .rdrNextButton {
    background: ${({ theme }) => theme.color.primary};
    i {
      border-color: transparent transparent transparent white;
    }
  }

  ${({ showRange }) =>
    !showRange &&
    css`
      .rdrDay > .rdrInRange {
        color: transparent !important;
      }
    `}
`;
