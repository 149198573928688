import styled from "styled-components/macro";

export const AllCampaignsPage = styled.div`
  margin-top: 20px;

  .keyword-tool-title {
    font-size: 11px;
  }

  .freeze {
    margin-top: 7px;

    table > tbody > tr {
      height: 46px;
    }
  }

  .page-title {
    font-size: 20px;
    font-weight: bold;
  }

  .table-wrapper {
    margin-top: 20px;
  }
`;
