import { IErrorMessage } from "../../services/types";
import { IBanner } from "../states/banner.state";
import { IAsset } from "../states/creative.state";

// create

export const CREATE_BANNERS = "banner-actions/CREATE_BANNERS";
export interface CreateBannersAction {
  type: typeof CREATE_BANNERS;
  payload: {
    bannerAd: IBanner;
    campaignId: string;
  };
}

export const CREATE_BANNERS_REQUEST = "banner-actions/CREATE_BANNERS_REQUEST";
export interface CreateBannersRequestAction {
  type: typeof CREATE_BANNERS_REQUEST;
}

export const CREATE_BANNERS_SUCCESS = "banner-actions/CREATE_BANNERS_SUCCESS";
export interface CreateBannersSuccessAction {
  type: typeof CREATE_BANNERS_SUCCESS;
  payload: IBanner & { id: number };
}

export const CREATE_BANNERS_FAILURE = "banner-actions/CREATE_BANNERS_FAILURE";
export interface CreateBannersFailureAction {
  type: typeof CREATE_BANNERS_FAILURE;
  error: IErrorMessage;
}

// update

export const UPDATE_BANNERS = "banner-actions/UPDATE_BANNERS";
export interface UpdateBannersAction {
  type: typeof UPDATE_BANNERS;
  payload: {
    bannerAd: IBanner;
    campaignId: string;
    bannerId: number;
  };
}

export const UPDATE_BANNERS_REQUEST = "banner-actions/UPDATE_BANNERS_REQUEST";
export interface UpdateBannersRequestAction {
  type: typeof UPDATE_BANNERS_REQUEST;
}

export const UPDATE_BANNERS_SUCCESS = "banner-actions/UPDATE_BANNERS_SUCCESS";
export interface UpdateBannersSuccessAction {
  type: typeof UPDATE_BANNERS_SUCCESS;
  payload: Array<IBanner>;
}

export const UPDATE_BANNERS_FAILURE = "banner-actions/UPDATE_BANNERS_FAILURE";
export interface UpdateBannersFailureAction {
  type: typeof UPDATE_BANNERS_FAILURE;
  error: IErrorMessage;
}

// create creative assets

export const CREATE_BANNER_ASSETS = "creative-actions/CREATE_BANNER_ASSETS";
export interface CreateBannerAssetsAction {
  type: typeof CREATE_BANNER_ASSETS;
  payload: Array<IAsset>;
}

export const CREATE_BANNER_ASSETS_REQUEST = "creative-actions/CREATE_BANNER_ASSETS_REQUEST";
export interface CreateBannerAssetsRequestAction {
  type: typeof CREATE_BANNER_ASSETS_REQUEST;
}

export const CREATE_BANNER_ASSETS_SUCCESS = "creative-actions/CREATE_BANNER_ASSETS_SUCCESS";
export interface CreateBannerAssetsSuccessAction {
  type: typeof CREATE_BANNER_ASSETS_SUCCESS;
}

export const CREATE_BANNER_ASSETS_FAILURE = "creative-actions/CREATE_BANNER_ASSETS_FAILURE";
export interface CreateBannerAssetsFailureAction {
  type: typeof CREATE_BANNER_ASSETS_FAILURE;
  error: IErrorMessage;
}

// delete creative assets

export const DELETE_BANNER_ASSETS = "creative-actions/DELETE_BANNER_ASSETS";
export interface DeleteBannerAssetsAction {
  type: typeof DELETE_BANNER_ASSETS;
  payload: Array<IAsset>;
}

export const DELETE_BANNER_ASSETS_REQUEST = "creative-actions/DELETE_BANNER_ASSETS_REQUEST";
export interface DeleteBannerAssetsRequestAction {
  type: typeof DELETE_BANNER_ASSETS_REQUEST;
}

export const DELETE_BANNER_ASSETS_SUCCESS = "creative-actions/DELETE_BANNER_ASSETS_SUCCESS";
export interface DeleteBannerAssetsSuccessAction {
  type: typeof DELETE_BANNER_ASSETS_SUCCESS;
  payload: Array<IAsset>;
}

export const DELETE_BANNER_ASSETS_FAILURE = "creative-actions/DELETE_BANNER_ASSETS_FAILURE";
export interface DeleteBannerAssetsFailureAction {
  type: typeof DELETE_BANNER_ASSETS_FAILURE;
  error: IErrorMessage;
}

// get

export const GET_BANNERS = "Banner-actions/GET_BANNERS";
export interface GetBannersAction {
  type: typeof GET_BANNERS;
}

export const GET_BANNERS_REQUEST = "Banner-actions/GET_BANNERS_REQUEST";
export interface GetBannersRequestAction {
  type: typeof GET_BANNERS_REQUEST;
}

export const GET_BANNERS_SUCCESS = "Banner-actions/GET_BANNERS_SUCCESS";
export interface GetBannersSuccessAction {
  type: typeof GET_BANNERS_SUCCESS;
  payload: Array<IBanner>;
}

export const GET_BANNERS_FAILURE = "Banner-actions/GET_BANNERS_FAILURE";
export interface GetBannersFailureAction {
  type: typeof GET_BANNERS_FAILURE;
  error: IErrorMessage;
}

// delete

export const DELETE_BANNER = "Banner-actions/DELETE_BANNER";
export interface DeleteBannerAction {
  type: typeof DELETE_BANNER;
  payload: number;
}

export const DELETE_BANNER_REQUEST = "Banner-actions/DELETE_BANNER_REQUEST";
export interface DeleteBannerRequestAction {
  type: typeof DELETE_BANNER_REQUEST;
}

export const DELETE_BANNER_SUCCESS = "Banner-actions/DELETE_BANNER_SUCCESS";
export interface DeleteBannerSuccessAction {
  type: typeof DELETE_BANNER_SUCCESS;
  payload: Array<IBanner>;
}

export const DELETE_BANNER_FAILURE = "Banner-actions/DELETE_BANNER_FAILURE";
export interface DeleteBannerFailureAction {
  type: typeof DELETE_BANNER_FAILURE;
  error: IErrorMessage;
}

// edit banner asset

export const EDIT_BANNER_ASSET = "Banner-actions/EDIT_BANNER_ASSET";
export interface EditBannerAssetAction {
  type: typeof EDIT_BANNER_ASSET;
  payload: {
    width: number;
    height: number;
    imageUrl: string;
    bannerId: number;
    name: string;
  };
}

export const EDIT_BANNER_ASSET_REQUEST = "Banner-actions/EDIT_BANNER_ASSET_REQUEST";
export interface EditBannerAssetRequestAction {
  type: typeof EDIT_BANNER_ASSET_REQUEST;
}

export const EDIT_BANNER_ASSET_SUCCESS = "Banner-actions/EDIT_BANNER_ASSET_SUCCESS";
export interface EditBannerAssetSuccessAction {
  type: typeof EDIT_BANNER_ASSET_SUCCESS;
}

export const EDIT_BANNER_ASSET_FAILURE = "Banner-actions/EDIT_BANNER_ASSET_FAILURE";
export interface EditBannerAssetFailureAction {
  type: typeof EDIT_BANNER_ASSET_FAILURE;
  error: IErrorMessage;
}
