interface IForgetPasswordState {
  loading: boolean;
  error: string;
}

const state = {
  loading: false,
  error: "",
} as IForgetPasswordState;

export default state;
