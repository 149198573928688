import { IErrorMessage } from "../../services/types";
import * as actions from "../action-types/competitor.types";

// save

export function saveCompetitor(payload: string): actions.SaveCompetitorAction {
  return {
    type: actions.SAVE_COMPETITOR,
    payload,
  };
}

export function saveCompetitorRequest(): actions.SaveCompetitorRequestAction {
  return {
    type: actions.SAVE_COMPETITOR_REQUEST,
  };
}

export function saveCompetitorSuccess(payload: {
  id: number;
  value: string;
}): actions.SaveCompetitorSuccessAction {
  return {
    type: actions.SAVE_COMPETITOR_SUCCESS,
    payload,
  };
}

export function saveCompetitorFailure(error: IErrorMessage): actions.SaveCompetitorFailureAction {
  return {
    type: actions.SAVE_COMPETITOR_FAILURE,
    error,
  };
}

// get

export function getCompetitor(): actions.GetCompetitorAction {
  return {
    type: actions.GET_COMPETITOR,
  };
}

export function getCompetitorRequest(): actions.GetCompetitorRequestAction {
  return {
    type: actions.GET_COMPETITOR_REQUEST,
  };
}

export function getCompetitorSuccess(payload: any): actions.GetCompetitorSuccessAction {
  return {
    type: actions.GET_COMPETITOR_SUCCESS,
    payload,
  };
}

export function getCompetitorFailure(error: IErrorMessage): actions.GetCompetitorFailureAction {
  return {
    type: actions.GET_COMPETITOR_FAILURE,
    error,
  };
}

// get

export function deleteCompetitor(payload: Array<number>): actions.DeleteCompetitorAction {
  return {
    type: actions.DELETE_COMPETITOR,
    payload,
  };
}

export function deleteCompetitorRequest(): actions.DeleteCompetitorRequestAction {
  return {
    type: actions.DELETE_COMPETITOR_REQUEST,
  };
}

export function deleteCompetitorSuccess(payload: any): actions.DeleteCompetitorSuccessAction {
  return {
    type: actions.DELETE_COMPETITOR_SUCCESS,
    payload,
  };
}

export function deleteCompetitorFailure(
  error: IErrorMessage
): actions.DeleteCompetitorFailureAction {
  return {
    type: actions.DELETE_COMPETITOR_FAILURE,
    error,
  };
}
