// get campaigns

import { ICampaignFilters, IErrorMessage } from "services/types";
import { ICampaign } from "store/states/campaigns.state";

// get paid campaigns

export const GET_CAMPAIGNS = "campaigns-actions/GET_CAMPAIGNS";
export interface GetCampaignsAction {
  type: typeof GET_CAMPAIGNS;
  filters: ICampaignFilters;
}

export const GET_CAMPAIGNS_REQUEST = "campaigns-actions/GET_CAMPAIGNS_REQUEST";
export interface GetCampaignsRequestAction {
  type: typeof GET_CAMPAIGNS_REQUEST;
  payload: ICampaignFilters;
}

export const GET_CAMPAIGNS_SUCCESS = "campaigns-actions/GET_CAMPAIGNS_SUCCESS";
export interface GetCampaignsSuccessAction {
  type: typeof GET_CAMPAIGNS_SUCCESS;
  payload: ICampaign[];
  total: number;
}

export const GET_CAMPAIGNS_FAILURE = "campaigns-actions/GET_CAMPAIGNS_FAILURE";
export interface GetCampaignsFailureAction {
  type: typeof GET_CAMPAIGNS_FAILURE;
  error: IErrorMessage;
}

export type CampaignsAction =
  | GetCampaignsAction
  | GetCampaignsRequestAction
  | GetCampaignsSuccessAction
  | GetCampaignsFailureAction;
