import { IErrorMessage } from "../../services/types";
import * as actions from "../action-types/admin.types";
import { IBankInfo } from "../states/admin.state";

// Banks

export function getBankList() {
  return {
    type: actions.GET_BANK_LIST,
  };
}

export function getBankListRequest(): actions.GetBankListRequestAction {
  return {
    type: actions.GET_BANK_LIST_REQUEST,
  };
}

export function getBankListSuccess(banks: IBankInfo[]): actions.GetBankListSuccessAction {
  return {
    type: actions.GET_BANK_LIST_SUCCESS,
    banks,
  };
}

export function getBankListFailure(error: IErrorMessage): actions.GetBankListFailureAction {
  return {
    type: actions.GET_BANK_LIST_FAILURE,
    error,
  };
}
