import Button from "components/Button";
import React from "react";
import { Link } from "react-router-dom";
import { AlreadyVerifiedStyled } from "./styled";
import * as AUTH_ROUTE from "modules/Auth/routes";

const AlreadyVerified = () => {
  return (
    <AlreadyVerifiedStyled>
      <p>It looks like you've already verified your email with us. Thanks!</p>
      <div className="forget-password-button">
        <span className="back-button">← Back to </span>
        <Link to={`../${AUTH_ROUTE.LOGIN}`}>{` Log in`}</Link>
      </div>
    </AlreadyVerifiedStyled>
  );
};

export default AlreadyVerified;
