import { put, call, takeEvery, all, fork, delay } from "redux-saga/effects";
import * as types from "../action-types/admin-news-feed.types";
import * as actions from "../actions/admin-news-feed.actions";
import api from "../../services/api";
import { handlingError, onSuccess } from "../../constants/utility/error";

function* onLoadAdminNewsFeed(action: types.GetAdminNewsFeedAction) {
  try {
    yield put(actions.getAdminNewsFeedRequest());
    const { data, total } = yield call(api.admin.feed.getFeeds, action.page, action.pageLimit);
    yield put(actions.getAdminNewsFeedSuccess(data, total));
  } catch (error: any) {
    handlingError(error);
    yield put(actions.getAdminNewsFeedFailure(error.response.data.message));
  }
}

function* watchonLoadNewsFeed() {
  yield takeEvery(types.GET_ADMIN_NEWS_FEED, onLoadAdminNewsFeed);
}

//

function* onLoadNewsFeedById(action: types.GetNewsFeedByIdAction) {
  try {
    yield put(actions.getNewsFeedByIdRequest());
    const { data } = yield call(api.admin.feed.getFeedById, action.payload);
    yield put(actions.getNewsFeedByIdSuccess(data));
  } catch (error: any) {
    handlingError(error);
    yield put(actions.getNewsFeedByIdFailure(error.response.data.message));
  }
}

function* watchonLoadNewsFeedById() {
  yield takeEvery(types.GET_NEWS_FEED_BY_ID, onLoadNewsFeedById);
}

// banks

function* onCreateNewsFeed(action: types.CreateAdminNewsFeedAction) {
  try {
    yield put(actions.createAdminNewsFeedRequest());
    const { data } = yield call(api.admin.feed.createFeed, action.payload);
    yield delay(2000);
    onSuccess("Create news feed");
    yield put(actions.createAdminNewsFeedSuccess(data));
    // action.navigate("/admin/feed/");
  } catch (error: any) {
    handlingError(error);
    yield put(actions.createAdminNewsFeedFailure(error.response.data.message));
  }
}

function* watchonCreateNewsFeed() {
  yield takeEvery(types.CREATE_ADMIN_NEWS_FEED, onCreateNewsFeed);
}

// banks

function* onUpdateNewsFeed(action: types.UpdateAdminNewsFeedAction) {
  try {
    yield put(actions.updateAdminNewsFeedRequest());
    const { data } = yield call(api.admin.feed.updateFeed, action.payload);
    yield put(actions.updateAdminNewsFeedSuccess(data));
    onSuccess("Updated news feed");
  } catch (error: any) {
    handlingError(error);
    yield put(actions.updateAdminNewsFeedFailure(error.response.data.message));
  }
}

function* watchonUpdateNewsFeed() {
  yield takeEvery(types.UPDATE_ADMIN_NEWS_FEED, onUpdateNewsFeed);
}

// banks

function* onDeleteNewsFeed(action: types.DeleteAdminNewsFeedAction) {
  try {
    yield put(actions.deleteAdminNewsFeedRequest());
    const { data } = yield call(api.admin.feed.deleteFeed, action.payload);
    yield put(actions.deleteAdminNewsFeedSuccess(action.payload));
  } catch (error: any) {
    handlingError(error);
    yield put(actions.getAdminNewsFeedFailure(error.response.data.message));
  }
}

function* watchonDeleteNewsFeed() {
  yield takeEvery(types.DELETE_ADMIN_NEWS_FEED, onDeleteNewsFeed);
}

export default function* adminNewsFeedSaga() {
  yield all([
    fork(watchonLoadNewsFeed),
    fork(watchonCreateNewsFeed),
    fork(watchonUpdateNewsFeed),
    fork(watchonDeleteNewsFeed),
    fork(watchonLoadNewsFeedById),
  ]);
}
