import styled from "styled-components/macro";

export const UserCampaignStyled = styled.div`
  margin-top: 20px;
  padding-left: 10px;

  .page-title {
    font-size: 20px;
    font-weight: bold;
    margin-top: 20px;
  }

  .manage-campaign-wrapper {
    margin-right: 20px;

    .campaign-list-wrapper {
      margin-top: 40px;

      .current-campaigns {
        transition: 0.1s;
        background: ${({ theme }) => theme.inputBorder};
        padding: 1px 30px 30px 30px;
        border-radius: 0px 10px 10px 10px;
        min-height: 200px;

        .bottom-bar {
          position: relative;

          .button-group {
            position: absolute;
            top: -40px;
          }
          .pagination {
            position: absolute;
            right: 0;
            top: -20px;
          }
        }
      }

      .tab-selector {
        display: flex;
        gap: 5px;
        font-size: 14px;

        .tab {
          cursor: pointer;
          background-color: transparent;
          width: 140px;
          text-align: center;
          border-radius: 10px 10px 0px 0px;
          padding: 10px 10px 5px 10px;
          border: 1px solid ${({ theme }) => theme.inputBorder};
          border-bottom: none;
          transition: 0.1s;
        }
        .selected {
          background-color: ${({ theme }) => theme.inputBorder};
        }
      }
    }
  }
`;
