import React from "react";
import { SelectStyled } from "./styled";
import ReactSelect, { SingleValue, components } from "react-select";

interface SelectProps {
  options: Array<{ value: string; label: string }>;
  onChange: (newvalue: SingleValue<{ value: string; label: string }>) => void;
  value: SingleValue<{ value: string; label: string }>;
}

const Select: React.FunctionComponent<SelectProps> = ({ options, onChange, value }) => {
  return (
    <SelectStyled data-testid="select">
      <ReactSelect
        // styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        classNamePrefix="select"
        defaultValue={value}
        options={options}
        onChange={onChange}
      />
    </SelectStyled>
  );
};

export default Select;
