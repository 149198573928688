import styled, { css } from "styled-components/macro";

export const ListTableStyled = styled.table<{ fullWidth?: boolean; isSavedList?: boolean }>`
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "max-content")};
  height: max-content;
  margin-bottom: 20px;

  th {
    /* background-color: rgb(26, 26, 26); */
    color: ${({ theme }) => theme.contrast};
    padding: 5px;
    height: 47px;

    &:first-child {
      min-width: 80px;
    }
  }

  td {
    height: 48px;
  }
`;
