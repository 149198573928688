import dateFnsFormat from "date-fns/format";
const FORMAT = "d MMM yyyy";

export const differenceInDays = (startDate: Date, endDate: Date, includeStartDate = false) => {
  const differenceInTime = endDate.getTime() - startDate.getTime();
  const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));

  if (includeStartDate) {
    return differenceInDays + 1;
  }

  return differenceInDays;
};

export const countDays = (start: Date, end: Date) => {
  const second = 1000;
  const minute = second * 60;
  const hour = minute * 60;
  const day = hour * 24;

  if (start && end) {
    const startDate = new Date(start);
    const endDate = new Date(end);

    const diff = endDate.getTime() - startDate.getTime();
    return Math.floor(diff / day);
  }
  return 0;
};

export const getFormattedDate = (dateObject: Date) => {
  let month: number | string = dateObject.getMonth() + 1;
  let date: number | string = dateObject.getDate();
  const year = dateObject.getFullYear();

  if (month < 10) {
    month = `0${month}`;
  }

  if (date < 10) {
    date = `0${date}`;
  }

  return `${year}-${month}-${date}`;
};

export const formatDate = (date: Date) => {
  const dateFormat = new Intl.DateTimeFormat("en-GB").format(date);

  const year = dateFormat.substring(6, 10);
  const month = dateFormat.substring(3, 5);
  const day = dateFormat.substring(0, 2);

  return `${year}-${month}-${day}`;
};

export const displayDate = (date: Date) => {
  const formatted = dateFnsFormat(date, FORMAT);
  return formatted;
};

export function formatDateTime(date: Date, full?: boolean): string {
  const year = new Intl.DateTimeFormat("en", { year: "numeric" }).format(date);
  const month = new Intl.DateTimeFormat("en", { month: full ? "long" : "short" }).format(date);
  const day = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(date);
  return `${day} ${month} ${year}`;
}

export function formatDateToServer(date: Date): string {
  const year = new Intl.DateTimeFormat("en", { year: "numeric" }).format(date);
  const month = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(date);
  const day = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(date);
  return `${year}-${month}-${day}`;
}
