import React from "react";
import { StyledInputRangeNumber } from "./styled";

interface IInputRangeNumberProps {
  min: number;
  max: number;
  onChange: (values: { min: number; max: number }) => void;
}

const InputRangeNumber = ({ min, max, onChange }: IInputRangeNumberProps) => {
  const [minVal, setMinVal] = React.useState(min);
  const [maxVal, setMaxVal] = React.useState(max);

  const minValRef = React.useRef<HTMLInputElement>(null);
  const maxValRef = React.useRef<HTMLInputElement>(null);
  const range = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    onChange({ min: minVal, max: maxVal });
  }, [minVal, maxVal]);

  const getPercent = React.useCallback(
    (value: number) => {
      return Math.round(((value - min) / (max - min)) * 100);
    },
    [min, max]
  );

  React.useEffect(() => {
    if (maxValRef.current) {
      const minPercent = getPercent(minVal);
      const maxPercent = getPercent(+maxValRef.current.value);

      if (range.current) {
        range.current.style.left = `${minPercent}%`;
        range.current.style.width = `${maxPercent - minPercent}%`;
      }
    }
  }, [minVal, getPercent]);

  React.useEffect(() => {
    if (minValRef.current) {
      const maxPercent = getPercent(maxVal);
      const minPercent = getPercent(+minValRef.current.value);

      if (range.current) {
        range.current.style.width = `${maxPercent - minPercent}%`;
      }
    }
  }, [maxVal, getPercent]);

  const onMinValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = Math.min(+event.target.value, maxVal - 1);
    setMinVal(value);
    event.target.value = value.toString();
  };

  const onMaxValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = Math.min(max, Math.max(+event.target.value, minVal + 1));
    setMaxVal(value);
    event.target.value = value.toString();
  };

  return (
    <StyledInputRangeNumber data-testid="input-range-number">
      <div className="range-wrapper">
        <input
          data-testid="input-number-min"
          min={min}
          max={max}
          className="input-number"
          onChange={onMinValueChange}
          type="number"
          value={minVal}
        />
        <div className="range-between">to</div>
        <input
          data-testid="input-number-max"
          min={min}
          max={max}
          className="input-number"
          onChange={onMaxValueChange}
          type="number"
          value={maxVal}
        />
      </div>

      <div>
        <input
          data-testid="input-range-min"
          value={minVal}
          ref={minValRef}
          className="thumb"
          type="range"
          // step="100"
          min={min}
          max={max}
          onChange={onMinValueChange}
        />
        <input
          data-testid="input-range-max"
          value={maxVal}
          ref={maxValRef}
          className="thumb"
          min={min}
          max={max}
          // step="100"
          type="range"
          onChange={onMaxValueChange}
        />
        <div className="slider">
          <div className="slider-track" />
          <div ref={range} className="slider-range" />
        </div>
      </div>
    </StyledInputRangeNumber>
  );
};

export default InputRangeNumber;
