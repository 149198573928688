export const getRandomPastelColor = () => {
  const hue = Math.floor(Math.random() * 360);
  const randomColor = `hsl(${hue}, 70%, 60%)`;
  return randomColor;
};

export const graphFormatter = (number: number) => {
  if (number > 1000000000) {
    return `${number / 1000000000}B`;
  } else if (number > 1000000) {
    return `${number / 1000000}M`;
  } else if (number > 1000) {
    return `${number / 1000}K`;
  } else {
    return number.toString();
  }
};

export const MONTH_LIST = {
  1: "Jan",
  "01": "Jan",
  2: "Feb",
  "02": "Feb",
  3: "Mar",
  "03": "Mar",
  4: "Apr",
  "04": "Apr",
  5: "May",
  "05": "May",
  6: "Jun",
  "06": "Jun",
  7: "Jul",
  "07": "Jul",
  8: "Aug",
  "08": "Aug",
  9: "Sep",
  "09": "Sep",
  10: "Oct",
  11: "Nov",
  12: "Dec",
} as Record<string, string>;

export const getGraphColors = [
  "#9900ff",
  "#ff0099",
  "#ff9900",
  "#33cc33",
  "#0099ff",
  "#e01b1b",
  "#9900ff",
  "#ff0099",
  "#ff9900",
  "#33cc33",
  "#0099ff",
  "#e01b1b",
  "#9900ff",
  "#ff0099",
  "#ff9900",
  "#33cc33",
  "#0099ff",
  "#e01b1b",
  "#9900ff",
  "#ff0099",
  "#ff9900",
  "#33cc33",
  "#0099ff",
  "#e01b1b",
  "#9900ff",
  "#ff0099",
  "#ff9900",
  "#33cc33",
  "#0099ff",
  "#e01b1b",
  "#9900ff",
  "#ff0099",
  "#ff9900",
  "#33cc33",
  "#0099ff",
  "#e01b1b",
  "#9900ff",
  "#ff0099",
  "#ff9900",
  "#33cc33",
  "#0099ff",
  "#e01b1b",
  "#9900ff",
  "#ff0099",
  "#ff9900",
  "#33cc33",
  "#0099ff",
  "#e01b1b",
  "#9900ff",
  "#ff0099",
  "#ff9900",
  "#33cc33",
  "#0099ff",
  "#e01b1b",
];
