import { IErrorMessage } from "../../services/types";
import { INewsFeed } from "../states/admin-news-feed.state";

export const GET_FEEDS = "feeds-actions/GET_FEEDS";
export interface GetFeedsAction {
  type: typeof GET_FEEDS;
  page?: number;
}

export const GET_FEEDS_REQUEST = "feeds-actions/GET_FEEDS_REQUEST";
export interface GetFeedsRequestAction {
  type: typeof GET_FEEDS_REQUEST;
}

export const GET_FEEDS_SUCCESS = "feeds-actions/GET_FEEDS_SUCCESS";
export interface GetFeedsSuccessAction {
  type: typeof GET_FEEDS_SUCCESS;
  payload: INewsFeed[];
  total: number;
}

export const INITIALIZE_FEED = "feeds-actions/INITIALIZE_FEED";
export interface InitializeFeedAction {
  type: typeof INITIALIZE_FEED;
  payload: INewsFeed[];
  total: number;
}

export const GET_FEEDS_FAILURE = "feeds-actions/GET_FEEDS_FAILURE";
export interface GetFeedsFailureAction {
  type: typeof GET_FEEDS_FAILURE;
  error: IErrorMessage;
}
