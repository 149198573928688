import { axiosForReverseAds } from "axiosConfig";
import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";

import DeleteIcon from "@mui/icons-material/Delete";
import CreateIcon from "@mui/icons-material/Create";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button as Bt } from "reactstrap";
import { toast } from "react-toastify";
import StyledSignal from "./styled";

const Signal = () => {
  const { id } = useParams();
  const loc = useLocation();
  const name = loc.state?.name || "";
  const location = loc.state?.location || "";
  const language = loc.state?.language || "";
  const [items, setItems] = useState<Array<string>>([]);
  const [editableIndex, setEditableIndex] = useState(-1);

  const [openDeletePopup, setDeletePopup] = useState(false);
  const [indexDelete, setIndexDelete] = useState(-1);

  const modifyVal = (index: number, e: any) => {
    const newItems = [...items];
    newItems[index] = e.target.value;
    setItems(newItems);
  };

  const commitChanges = (index: number) => {
    let newItems = [...items];
    if (items[index] === "") newItems = newItems.filter((_, i) => i != index);
    setItems(newItems);
    setEditableIndex(-1);
  };

  const deleteKeyword = (index: any) => {
    setIndexDelete(index);
    setDeletePopup(true);
  };

  const cancelDelete = () => {
    setIndexDelete(-1);
    setDeletePopup(false);
  };

  const confirmDelete = () => {
    const newItems = items.filter((_, i) => i !== indexDelete);
    setItems(newItems);
    setIndexDelete(-1);
    setDeletePopup(false);
  };

  const addKeyword = () => {
    const l = items.length;
    const newItems = [...items];
    newItems.push("");
    setItems(newItems);
    setEditableIndex(l);
  };

  const storeInfo = () => {
    const updateSignal = async () => {
      const response = await axiosForReverseAds.post("update_signal", { id: id, keywords: items });
      if (response.data.status === "success") {
        toast.success("Signal updated successfully!", { autoClose: 1000 });
        window.history.go(-1);
      }
    };
    updateSignal();
  };

  useEffect(() => {
    const fetchSignal = async () => {
      const response = await axiosForReverseAds.get("get_signal/" + id);
      if (response.data.status === "success") {
        setItems(response.data.data);
      } else {
        setItems([]);
      }
    };
    fetchSignal();
  }, []);

  return (
    <StyledSignal>
      <div className="signal-full">
        <div>
          <div className="signal-h">Keyword</div>
          <div className="signal-h1">
            Signal Name &nbsp; <span className="signal-name">{name}</span>
          </div>
          <div className="signal-h1">
            Location &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            <span className="signal-name">{location}</span>
          </div>
          <div className="signal-h1">
            Language &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            <span className="signal-name">{language}</span>
          </div>
        </div>
        <div className="signal-table-container">
          <table className="signal-table">
            <thead>
              <tr>
                <th>Keyword</th>
                <th>Edit</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {items.map((item, index) => (
                <tr key={index}>
                  {index == editableIndex ? (
                    <td className="td-edit">
                      <input
                        type="text"
                        value={item}
                        className="edit-input"
                        onChange={(event) => modifyVal(index, event)}
                        onBlur={() => {
                          commitChanges(index);
                        }}
                        onKeyDown={(event) => {
                          event.key == "Enter" ? commitChanges(index) : console.log();
                        }}
                        autoFocus
                      />
                    </td>
                  ) : (
                    <td
                      onClick={() => {
                        setEditableIndex(index);
                      }}
                    >
                      {item}
                    </td>
                  )}
                  <td>
                    <CreateIcon
                      style={{ cursor: "pointer" }}
                      fontSize="small"
                      onClick={() => {
                        setEditableIndex(index);
                      }}
                    />
                  </td>
                  <td>
                    <DeleteIcon
                      style={{ cursor: "pointer" }}
                      fontSize="small"
                      onClick={() => deleteKeyword(index)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="signal-footer">
          <Bt className="keyword-btn" onClick={addKeyword} color="secondary">
            Add Keyword
          </Bt>
          <Bt onClick={storeInfo} className="save-btn">
            Save
          </Bt>
        </div>

        <div>
          <Modal isOpen={openDeletePopup} toggle={cancelDelete} centered={true}>
            <ModalHeader toggle={cancelDelete} className="popup-head">
              Delete
            </ModalHeader>
            <ModalBody className="popup-body">
              Are you sure you want to delete "{indexDelete > -1 ? items[indexDelete] : ""}"
              keyword?
            </ModalBody>
            <ModalFooter>
              <Bt color="primary" onClick={confirmDelete}>
                Confirm
              </Bt>{" "}
              <Bt color="secondary" onClick={cancelDelete}>
                Cancel
              </Bt>
            </ModalFooter>
          </Modal>
        </div>
      </div>
    </StyledSignal>
  );
};

export default Signal;
