import styled from "styled-components";

export const VerifiedFailureStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 500px;
  margin: 0 auto;
  height: 100vh;

  .button-wrapper {
    margin-bottom: 20px;
    display: flex;
    gap: 10px;
    align-items: start;
  }
`;
