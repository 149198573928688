import React from "react";
import Spinner from "components/Spinner";
import StyledButton from "./styled";
interface IButtonProps extends React.ComponentPropsWithoutRef<"button"> {
  noPadding?: boolean;
  outline?: boolean;
  circle?: boolean;
  children: React.ReactNode | JSX.Element[];
  loading?: boolean;
  danger?: boolean;
  transparent?: boolean;
  secondary?: boolean;
  block?: boolean;
  disabled?: boolean;
  type?: "submit" | "button";
  width?: number;
  height?: number;
  full?: boolean;
  color?: "gold" | "purple" | "blue" | "gray" | "search-button" | "lightgray";
  icon?: JSX.Element | React.ReactNode;
  squared?: boolean;
}
const Button = ({
  children,
  circle,
  height,
  full,
  transparent,
  noPadding,
  outline,
  loading,
  danger,
  secondary,
  block,
  disabled,
  width,
  type,
  color,
  icon,
  squared,
  ...props
}: IButtonProps) => {
  return (
    <StyledButton
      {...props}
      type={type || "button"}
      $block={block}
      $secondary={secondary}
      $disabled={loading || disabled}
      $circle={circle}
      $outline={outline}
      $noPadding={noPadding}
      $danger={danger}
      $transparent={transparent}
      $width={width}
      $height={height}
      $full={full}
      $color={color}
      $icon={!!icon}
      $squared={squared}
    >
      {loading ? (
        <Spinner />
      ) : icon ? (
        <div className="button-icon-wrapper">
          <span>{children}</span>
          {icon}
        </div>
      ) : (
        children
      )}
    </StyledButton>
  );
};

export default Button;
