import { IErrorMessage, IPaymentsFilters } from "../../services/types";
import * as actions from "../action-types/payments.types";
import { IPaymentDetail } from "../states/payments.state";

// Payments

export function getPayments(payload: IPaymentsFilters) {
  return {
    type: actions.GET_PAYMENTS,
    payload,
  };
}

export function getPaymentsRequest(): actions.GetPaymentsRequestAction {
  return {
    type: actions.GET_PAYMENTS_REQUEST,
  };
}

export function getPaymentsSuccess(
  payments: IPaymentDetail[],
  total: number
): actions.GetPaymentsSuccessAction {
  return {
    type: actions.GET_PAYMENTS_SUCCESS,
    payments,
    total,
  };
}

export function getPaymentsFailure(error: IErrorMessage): actions.GetPaymentsFailureAction {
  return {
    type: actions.GET_PAYMENTS_FAILURE,
    error,
  };
}
