import React from "react";
import Header from "components/Header";
import { NewsFeedPageStyled, FeedSidebar, NewsTag } from "./styled";
import KeywordSidebar from "components/KeywordSidebar";
import Spinner from "components/Spinner";
import { useNavigate, Route, Routes, useParams } from "react-router-dom";
import NewsFeedDetail from "../NewsFeedDetail";
import { RootState } from "store/store";
import { connect, ConnectedProps, useDispatch } from "react-redux";
import { INewsFeed } from "store/states/admin-news-feed.state";
import axios from "axios";
import { getFeeds } from "store/actions/feed.actions";
import { formatDateTime } from "constants/utility/date";
import Button from "components/Button";

interface INewsFeedRouteProps extends PropsFromRedux {}

interface INewsFeedProps {
  feed: INewsFeed[];
  total: number;
  loading: boolean;
}

const mapStateToProps = ({ feed, adminNewsFeed }: RootState) => ({
  adminNewsFeed,
  feed,
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const NewsFeed: React.FunctionComponent<INewsFeedProps> = ({ feed, total, loading }) => {
  const [currentPage, setPage] = React.useState(0);
  const dispatch = useDispatch();

  const wrapperRef = React.useRef<HTMLDivElement>(null);

  const infiniteScroll = () => {
    if (wrapperRef.current) {
      if (
        wrapperRef.current.scrollTop + wrapperRef.current.clientHeight ===
        wrapperRef.current.scrollHeight
      ) {
        setPage((prev) => prev + 1);
      }
    }
  };

  React.useEffect(() => {
    if (wrapperRef.current) {
      wrapperRef.current.addEventListener("scroll", infiniteScroll, false);
      return function cleanup() {
        wrapperRef.current?.removeEventListener("scroll", infiniteScroll, false);
      };
    }
  }, []);

  React.useEffect(() => {
    if (Math.ceil(total / 10) >= currentPage + 1) {
      dispatch(getFeeds(currentPage));
    }
  }, [currentPage]);

  const navigate = useNavigate();
  return (
    <NewsFeedPageStyled>
      <div className="news-feed-page">
        <div ref={wrapperRef} className="feed-wrapper">
          {feed.length ? (
            feed.map((item) => {
              return (
                <div
                  onClick={() => navigate(`${item.id}`)}
                  className="feed-item"
                  key={item.id + Math.random()}
                >
                  <div className="thumbnail-wrapper">
                    <img
                      alt="thumbnail"
                      src={process.env.REACT_APP_IMAGES_STORAGE + (item.imageUrl || "")}
                      className="feed-thumbnail"
                    />

                    <NewsTag tag={item.category.name}>{item.category.name} </NewsTag>
                  </div>

                  <div className="content-wrapper">
                    <h6 className="title" onClick={() => navigate(item.id.toString())}>
                      {item.title}
                    </h6>
                    <span className="date">
                      {formatDateTime(new Date(item.publishedDate), true)}
                    </span>
                    <div
                      className="feed-content"
                      // dangerouslySetInnerHTML={{
                      //   __html: item.bodyHtml.replace(/(<([^>]+)>)/gi, ""),
                      // }}
                    />
                  </div>
                </div>
              );
            })
          ) : loading ? (
            <Spinner />
          ) : (
            <h3>We'll be publish our news feed soon. Stay tuned!</h3>
          )}
        </div>
      </div>

      <div className="more-feed">
        {feed.length === total ? null : (
          <Button onClick={infiniteScroll} loading={loading}>
            Read more
          </Button>
        )}
      </div>
    </NewsFeedPageStyled>
  );
};

const NewsFeedRoutes: React.FunctionComponent<INewsFeedRouteProps> = ({
  feed,
  adminNewsFeed,
  dispatch,
}) => {
  const { path } = useParams();
  const navigate = useNavigate();
  const [cpcList, setCpcList] = React.useState([]);

  React.useEffect(() => {
    const fetchCpcList = async () => {
      if (process.env.REACT_APP_CPC) {
        const { data } = await axios.get(process.env.REACT_APP_CPC);
        const newList = data.datas.reduce((acc: any, cur: any) => {
          cur = {
            ...cur,
            key: cur.keyword,
            display: cur.keyword,
            googleCpcString: Number(cur.cpcGoogle).toFixed(2),
            radCpcString: Number(cur.cpcRAD).toFixed(2),
          };
          return [...acc, cur];
        }, []);
        setCpcList(newList);
      }
    };
    fetchCpcList();
  }, []);

  React.useEffect(() => {
    dispatch(getFeeds());
  }, []);

  const [seachResult, setSearchResult] = React.useState(feed.data);

  const onSearchFeed = (title: string) => {
    const result = feed.data.filter((item) =>
      item.title.toLowerCase().includes(title.toLowerCase())
    );
    setSearchResult(result);
  };

  React.useEffect(() => {
    setSearchResult(feed.data);
  }, [feed.data.length]);

  return (
    <NewsFeedPageStyled>
      {/* {isExact ? (
        <>
          <h2 className="page-title">News Feed</h2>
          <Header searchField="input" onSearchFormSubmit={onSearchFeed} />
        </>
      ) : (
        <>
          <h6 className="keyword-tool-title">News Feed</h6>
          <h2 className="page-title">{adminNewsFeed.current?.title}</h2>
        </>
      )} */}
      <div className="content-section">
        {/* <Routes>
          <Route path={path || ""}>
            <NewsFeed total={feed.total} loading={feed.loading} feed={seachResult} />
          </Route>
          <Route path={`${path}:id`}>
            <NewsFeedDetail loading={adminNewsFeed.loading} feed={adminNewsFeed.current} />
          </Route>
        </Routes> */}
        <FeedSidebar>
          {/* {!isExact && (
            <div className="recent-news">
              <h6 className="news-list-title">Recent News Feed</h6>
              <ul>
                {feed.data.length > 0 &&
                  feed.data.slice(0, 3).map((feed) => {
                    return (
                      <li
                        className="recent-item"
                        onClick={() => {
                          navigate(`/app/feed/${feed.id}`);
                        }}
                        key={feed.id}
                      >
                        <div className="feed-title">{feed.title}</div>
                        <span className="date-post">
                          {formatDateTime(new Date(feed.publishedDate), true)}
                        </span>
                      </li>
                    );
                  })}
              </ul>
            </div>
          )} */}
          <KeywordSidebar score={false} search={false} globalSelectedKeywords={cpcList} />
        </FeedSidebar>
      </div>
    </NewsFeedPageStyled>
  );
};

export default connector(NewsFeedRoutes);
