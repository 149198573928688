import { BUDGET_LIST } from "constants/payment";
import { FormikProps, useFormik } from "formik";
import CheckoutPanel from "components/CheckoutPanel";
import PaymentMethodSelector, { IPaymentMethod } from "components/PaymentMethod";
import CreditCardForm from "components/PaymentMethod/CreditCard";
import PaymentSummary, { IPromo } from "components/PaymentSummary";
import React from "react";
import { Step6Styled } from "./styled";
import { IPostCampaignData } from "constants/utility/campaign";

export const PAYMENT_METHOD_LIST: Array<IPaymentMethod> = [
  {
    title: "Credit Card",
    key: "credit card",
    message: "Instant authorization and additional security through 3-D Secure.",
    component: (
      formikProps?: FormikProps<IPostCampaignData>,
      ref?: React.RefObject<HTMLDivElement>
    ) => (formikProps ? <CreditCardForm formikProps={formikProps} ref={ref} /> : null),
  },
];

interface IStep6Props {
  formikProps: FormikProps<IPostCampaignData>;
  paymentMethod: string;
  selectPaymentMethod: (method: string) => void;
}

const Step6: React.FunctionComponent<IStep6Props> = ({
  formikProps,
  selectPaymentMethod,
  paymentMethod,
}) => {
  const [accordianContentHeight, setAccordianContentHeight] = React.useState(225);
  const itemsRef = React.useRef<Array<HTMLDivElement>>([]);
  const accordianChildRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    itemsRef.current = itemsRef.current.slice(0, PAYMENT_METHOD_LIST.length);
  }, [PAYMENT_METHOD_LIST.length]);

  // const dispatch = useDispatch();
  // const navigate =useNavigate();
  // const [isPaymentLoading, setPaymentLoading] = React.useState(false);
  const [promo, applyPromo] = React.useState<IPromo | null>(null);
  // React.useEffect(() => {
  //   if (accordianChildRef.current && accordianChildRef.current.scrollHeight) {
  //     setAccordianContentHeight(accordianChildRef.current.scrollHeight);
  //   }
  // }, [formikProps.touched, formikProps.errors, paymentMethod]);

  return (
    <Step6Styled>
      <h6>Make a payment</h6>
      <div className="payment-form">
        <CheckoutPanel
          promo={promo}
          applyPromo={(val: IPromo | null) => applyPromo(val)}
          isPaymentLoading={false}
          formikProps={formikProps}
        />
        <PaymentSummary promo={promo} formikProps={formikProps} />
        <PaymentMethodSelector
          selectPaymentMethod={selectPaymentMethod}
          paymentMethod={paymentMethod}
          formikProps={formikProps}
        />
      </div>
    </Step6Styled>
  );
};

export default Step6;
