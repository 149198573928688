import React from "react";

const Step3Icon = ({ className }: { className?: string }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 62 62" className={className}>
      <defs>
        <style>
          {
            ".icon-svg,.icon-svg{fill:none;stroke:#fff;stroke-width:2px;}.icon-svg{stroke-miterlimit:10;}.icon-svg{stroke-linecap:round;stroke-linejoin:round;}"
          }
        </style>
      </defs>
      <g id="Layer_2" data-name="Layer 2">
        <g id="details">
          <line className="icon-svg" x1={40.86} y1={42.42} x2={33.51} y2={34.27} />
          <circle className="icon-svg" cx={31} cy={31} r={15.56} />
          <circle className="icon-svg" cx={31} cy={31} r={4.25} />
          <circle className="icon-svg" cx={31} cy={31} r={30} />
        </g>
      </g>
    </svg>
  );
};

export default Step3Icon;
