import { put, call, takeEvery, all, fork, select } from "redux-saga/effects";
import * as types from "../action-types/campaign-payment.types";
import * as actions from "../actions/campaign-payment.actions";
import api from "../../services/api";
import { handlingError, handlingFormErrors, onSuccess } from "../../constants/utility/error";
import { hideBuildCampaign, hideScreenLoading, showScreenLoading } from "../actions/popup.actions";
import { formatDate } from "constants/utility/date";

function* onCreateCampaignPayment(action: types.campaignPaymentAction) {
  try {
    yield put(actions.campaignPaymentRequest());

    const { paymentForm, paymentMethod, card, campaignId } = action.payload;
    const [expirationMonth, expirationYear] = card.expirationDate.split("/");
    const formatStartDate = paymentForm.startDate && formatDate(paymentForm.startDate);
    const formatEndDate = paymentForm.endDate && formatDate(paymentForm.endDate);

    const campaignPaymentData = {
      paymentType: paymentMethod,
      discountCode: paymentForm.discountCode,
      budgetPerDay: paymentForm.budgetPerDay,
      startDate: formatStartDate,
      endDate: formatEndDate,
      campaignId,
      card: {
        fullName: card.fullName,
        number: card.cardNumber,
        expMonth: Number(expirationMonth),
        expYear: Number(`20${expirationYear}`),
        cvc: Number(card.securityCode),
      },
    };

    const { data } = yield call(api.campaign.payment, campaignPaymentData);

    if (data.nextUrl) {
      // yield put(actions.campaignPaymentSuccess(state));
      window.location.href = data.nextUrl;
    }
    yield put(hideBuildCampaign());
  } catch (error: any) {
    handlingError(error);
    yield put(actions.campaignPaymentFailure(error.response.data.message));
  }
  yield put(hideScreenLoading());
}

function* watchonCreateCampaignPayment() {
  yield takeEvery(types.CAMPAIGN_PAYMENT, onCreateCampaignPayment);
}

export default function* campaignPaymentSaga() {
  yield all([fork(watchonCreateCampaignPayment)]);
}
