import styled, { css } from "styled-components/macro";

export const LayoutWrapper = styled.div<{ isLoading: boolean }>`
  opacity: 0;
  display: none;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1050;
  background: black;

  ${({ isLoading }) =>
    isLoading &&
    css`
      display: block;
      opacity: 0.5;
    `}
`;

export const StyledAuthWrapper = styled.div<{ isLoading: boolean }>`
  display: flex;
  width: 100%;
  overflow: hidden;

  main {
    margin-top: 50px;
  }

  .content {
    padding: ${({ theme }) => theme.specific.topOffset} 30px;
    flex: 1;
    position: relative;
    padding-left: 310px;
    width: 100%;
    padding: unset;
    width: 10px;
    transition: 0.1s;
    background-color: ${({ theme }) => theme.background};
    color: ${({ theme }) => theme.contrast};

    .nav-tabs {
      background: repeating-linear-gradient(
        135deg,
        #1a5b33,
        #1a5b33 10px,
        #1c2d21 10px,
        #1c2d21 20px
      );
    }
  }

  @media screen and (max-width: 991px) {
    /* .content
    {
        padding-left: 15px;
        padding-right: 15px;
        margin-left: 0;
        padding-top: 20px;
    } */

    /* .content.contentNoPadding
    {
        padding-left: 0;
        padding-top: 20px;
    } */

    .content.contentNoPadding .menuButton {
      margin-left: 15px;
    }
  }

  @media screen and (max-width: 768px) {
    .filterFields select {
      width: 100%;
    }
  }

  .screen-loading {
    .spinner-wrapper {
      position: absolute;
      z-index: 1060;
      visibility: none;
      background: rgb(0, 0, 0, 0.4);
    }
  }

  ${({ isLoading }) =>
    isLoading &&
    css`
      .screen-loading {
        visibility: visible;
      }
    `}
`;
