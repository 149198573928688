import React from "react";
import { Column, TableInstance, usePagination, useTable } from "react-table";
import { ICampaignReportData, IDailyReport } from "store/states/campaigns.state";
import { displayDate } from "constants/utility/date";
import { CreativeTableStyled } from "./styled";

import { numberWithCommas } from "constants/utility/number";

interface ICreativeProps {
  // dateList: Array<ICampaignReportData>;
  table: TableInstance<ICampaignReportData>;
}

const CreativeTable: React.FunctionComponent<ICreativeProps> = ({ table }) => {
  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } = table;

  // const test = useTable(
  //   {
  //     columns,
  //     data: dateList,
  //     initialState: { pageSize: 10, pageIndex: 0 },
  //   },
  //   usePagination
  // );
  return (
    <CreativeTableStyled>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </CreativeTableStyled>
  );
};

export default CreativeTable;
