import { CampaignStatus } from "store/states/campaign.state";

export const SHOW_BETA_ALERT = "SHOW_BETA_ALERT";
export const HIDE_BETA_ALERT = "HIDE_BETA_ALERT";

export const SHOW_INTRO_POPUP = "SHOW_INTRO_POPUP";
export const HIDE_INTRO_POPUP = "HIDE_INTRO_POPUP";
export const SHOW_SUBSCRIPTION_REQUIRED_POPUP = "SHOW_SUBSCRIPTION_REQUIRED_POPUP";
export const HIDE_SUBSCRIPTION_REQUIRED_POPUP = "HIDE_SUBSCRIPTION_REQUIRED_POPUP";
export const SHOW_CAMPAIGN_POPUP = "SHOW_CAMPAIGN_POPUP";
export const HIDE_CAMPAIGN_POPUP = "HIDE_CAMPAIGN_POPUP";
export const SHOW_KEYWORD_LIST_POPUP = "SHOW_KEYWORD_LIST_POPUP";
export const HIDE_KEYWORD_LIST_POPUP = "HIDE_KEYWORD_LIST_POPUP";
export const SHOW_NEW_CAMPAIGN_POPUP = "SHOW_NEW_CAMPAIGN_POPUP";
export const HIDE_NEW_CAMPAIGN_POPUP = "HIDE_NEW_CAMPAIGN_POPUP";

export const SHOW_EDIT_CAMPAIGN_POPUP = "SHOW_EDIT_CAMPAIGN_POPUP";
export const HIDE_EDIT_CAMPAIGN_POPUP = "HIDE_EDIT_CAMPAIGN_POPUP";

export const SHOW_BANNER_CONTENT_POPUP = "SHOW_BANNER_CONTENT_POPUP";
export const HIDE_BANNER_CONTENT_POPUP = "HIDE_BANNER_CONTENT_POPUP";

export const SHOW_CREATIVE_FORM_POPUP = "SHOW_CREATIVE_FORM_POPUP";
export const HIDE_CREATIVE_FORM_POPUP = "HIDE_CREATIVE_FORM_POPUP";

export const SHOW_IMPORT_KEYWORDS_FORM_POPUP = "SHOW_IMPORT_KEYWORDS_FORM_POPUP";
export const HIDE_IMPORT_KEYWORDS_FORM_POPUP = "HIDE_IMPORT_KEYWORDS_FORM_POPUP";

export const SHOW_SCREEN_LOADING = "SHOW_SCREEN_LOADING";
export const HIDE_SCREEN_LOADING = "HIDE_SCREEN_LOADING";

export const SHOW_COMPARE_POPUP = "SHOW_COMPARE_POPUP";
export const HIDE_COMPARE_POPUP = "HIDE_COMPARE_POPUP";

export const SHOW_SUGGESTED_POPUP = "SHOW_SUGGESTED_POPUP";
export const HIDE_SUGGESTED_POPUP = "HIDE_SUGGESTED_POPUP";

export const SHOW_COMPETITOR_POPUP = "SHOW_COMPETITOR_POPUP";
export const HIDE_COMPETITOR_POPUP = "HIDE_COMPETITOR_POPUP";

export const SHOW_BUILD_CAMPAIGN = "SHOW_BUILD_CAMPAIGN";
export const HIDE_BUILD_CAMPAIGN = "HIDE_BUILD_CAMPAIGN";

export const SHOW_EXPORT_POPUP = "SHOW_EXPORT_POPUP";
export const HIDE_EXPORT_POPUP = "HIDE_EXPORT_POPUP";

export const SHOW_CONFIRM_POPUP = "SHOW_CONFIRM_POPUP";
export const HIDE_CONFIRM_POPUP = "HIDE_CONFIRM_POPUP";

export const SHOW_CHANGE_PASSWORD_POPUP = "SHOW_CHANGE_PASSWORD_POPUP";
export const HIDE_CHANGE_PASSWORD_POPUP = "HIDE_CHANGE_PASSWORD_POPUP";

export const SHOW_FORGET_PASSWORD_POPUP = "SHOW_FORGET_PASSWORD_POPUP";
export const HIDE_FORGET_PASSWORD_POPUP = "HIDE_FORGET_PASSWORD_POPUP";

export const SHOW_CAMPAIGN_DETAIL_POPUP = "SHOW_CAMPAIGN_DETAIL_POPUP";
export const HIDE_CAMPAIGN_DETAIL_POPUP = "HIDE_CAMPAIGN_DETAIL_POPUP";

export const SHOW_ADVERTISER_FORM_POPUP = "SHOW_ADVERTISER_FORM_POPUP";
export const HIDE_ADVERTISER_FORM_POPUP = "HIDE_ADVERTISER_FORM_POPUP";

export const showAdvertiserForm = () => {
  return {
    type: SHOW_ADVERTISER_FORM_POPUP,
  };
};

export const hideAdvertiserForm = () => {
  return {
    type: HIDE_ADVERTISER_FORM_POPUP,
  };
};

export const showBetaAlert = () => {
  return {
    type: SHOW_BETA_ALERT,
  };
};

export const hideBetaAlert = () => {
  return {
    type: HIDE_BETA_ALERT,
  };
};

export const showCampaignDetailPopup = (payload?: string) => {
  return {
    type: SHOW_CAMPAIGN_DETAIL_POPUP,
    payload,
  };
};

export const hideCampaignDetailPopup = () => {
  return {
    type: HIDE_CAMPAIGN_DETAIL_POPUP,
  };
};

export const showForgetPasswordPopup = (payload?: any) => {
  return {
    type: SHOW_FORGET_PASSWORD_POPUP,
    payload,
  };
};

export const hideForgetPasswordPopup = () => {
  return {
    type: HIDE_FORGET_PASSWORD_POPUP,
  };
};

export const showChangePasswordPopup = (payload?: any) => {
  return {
    type: SHOW_CHANGE_PASSWORD_POPUP,
    payload,
  };
};

export const hideChangePasswordPopup = () => {
  return {
    type: HIDE_CHANGE_PASSWORD_POPUP,
  };
};

export const showConfirmPopup = (payload: {
  title: string;
  body?: string;
  confirmText: string;
  action: () => void;
}) => {
  return {
    type: SHOW_CONFIRM_POPUP,
    payload,
  };
};

export const hideConfirmPopup = () => {
  return {
    type: HIDE_CONFIRM_POPUP,
  };
};

export const showExportPopup = (payload: {
  type: "keywords" | "campaigns" | "performance";
  data?: Array<string> | { id?: string; startDate: Date; endDate: Date };
}) => {
  return {
    type: SHOW_EXPORT_POPUP,
    payload,
  };
};

export const hideExportPopup = () => {
  return {
    type: HIDE_EXPORT_POPUP,
  };
};

export const showBuildCampaign = (payload?: string) => {
  return {
    type: SHOW_BUILD_CAMPAIGN,
    payload,
  };
};

export const hideBuildCampaign = () => {
  return {
    type: HIDE_BUILD_CAMPAIGN,
  };
};

export const showComparePopup = (payload?: Array<string>) => {
  return {
    type: SHOW_COMPARE_POPUP,
    payload,
  };
};

export const hideComparePopup = () => {
  return {
    type: HIDE_COMPARE_POPUP,
  };
};

export const showSuggestedPopup = (payload?: string) => {
  return {
    type: SHOW_SUGGESTED_POPUP,
    payload,
  };
};

export const hideSuggestedPopup = () => {
  return {
    type: HIDE_SUGGESTED_POPUP,
  };
};

export const showCompetitorPopup = (payload?: string) => {
  return {
    type: SHOW_COMPETITOR_POPUP,
    payload,
  };
};

export const hideCompetitorPopup = () => {
  return {
    type: HIDE_COMPETITOR_POPUP,
  };
};

//

export const showImportKeywords = (payload: Array<string>) => {
  return {
    type: SHOW_IMPORT_KEYWORDS_FORM_POPUP,
    payload,
  };
};

export const hideImportKeywords = () => {
  return {
    type: HIDE_IMPORT_KEYWORDS_FORM_POPUP,
  };
};

export const showBannerContent = (payload?: number) => {
  return {
    type: SHOW_BANNER_CONTENT_POPUP,
    payload,
  };
};

export const hideBannerContent = () => {
  return {
    type: HIDE_BANNER_CONTENT_POPUP,
  };
};

export const showCreativeForm = () => {
  return {
    type: SHOW_CREATIVE_FORM_POPUP,
  };
};

export const hideCreativeForm = () => {
  return {
    type: HIDE_CREATIVE_FORM_POPUP,
  };
};

export const showEditCampaign = (payload: number) => {
  return {
    type: SHOW_EDIT_CAMPAIGN_POPUP,
    payload,
  };
};

export const hideEditCampaign = () => {
  return {
    type: HIDE_EDIT_CAMPAIGN_POPUP,
  };
};

export const showIntroPopup = () => {
  return {
    type: SHOW_INTRO_POPUP,
  };
};

export const hideIntroPopup = () => {
  return {
    type: HIDE_INTRO_POPUP,
  };
};

export const showSubscriptionRequiredPopup = () => {
  return {
    type: SHOW_SUBSCRIPTION_REQUIRED_POPUP,
  };
};

export const showCampaignPopup = () => {
  return {
    type: SHOW_CAMPAIGN_POPUP,
  };
};

export const hideCampaignPopup = () => {
  return {
    type: HIDE_CAMPAIGN_POPUP,
  };
};

export const showKeywordListPopup = ({
  values,
  pageIndex,
}: {
  values?: Array<string>;
  pageIndex?: number;
}) => {
  return {
    type: SHOW_KEYWORD_LIST_POPUP,
    payload: { keywords: values, pageIndex },
  };
};

export const hideKeywordSavedListPopup = () => {
  return {
    type: HIDE_KEYWORD_LIST_POPUP,
  };
};

export const showNewCampaignPopup = (campaign: { id: string; name: string }) => {
  return {
    type: SHOW_NEW_CAMPAIGN_POPUP,
    payload: campaign,
  };
};

export const hideNewCampaignPopup = () => {
  return {
    type: HIDE_NEW_CAMPAIGN_POPUP,
  };
};

export const showScreenLoading = () => {
  return {
    type: SHOW_SCREEN_LOADING,
  };
};

export const hideScreenLoading = () => {
  return {
    type: HIDE_SCREEN_LOADING,
  };
};
