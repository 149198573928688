import styled from "styled-components/macro";

export const PaymentDetailStyled = styled.div`
  p {
    font-size: 12px;
  }
  .input-wrapper {
    padding: 10px 0;
  }
`;
