import * as Yup from "yup";
import valid from "card-validator";
import { AVAILABLE_CARD_TYPE } from "components/PaymentMethod/constants";

export default Yup.object().shape({
  card: Yup.object().shape({
    fullName: Yup.string()
      .matches(/^[A-Za-z  .]+$/, "Enter a valid full name card owner")
      .required("Card owner name is required"),

    cardNumber: Yup.string()
      .required("Credit Card number is required")
      // .test("test-jcb-not-supported", "Brand not supported (JCB)", (value) => {
      //   const { card } = valid.number(value);
      //   if (card) {
      //     return !(card.type === AVAILABLE_CARD_TYPE.JCB);
      //   }
      //   return true;
      // })
      // .test("test-amex-not-supported", "Brand not supported (American Express)", (value) => {
      //   const { card } = valid.number(value);
      //   if (card) {
      //     return !(card.type === AVAILABLE_CARD_TYPE.AMERICAN_EXPRESS);
      //   }
      //   return true;
      // })
      .test("test-number", "Credit Card number is invalid", (value) => valid.number(value).isValid),
    securityCode: Yup.string()
      .required("Required")
      .when("cardNumber", {
        is: (cardNumber: string) => {
          const { card } = valid.number(cardNumber);
          if (card) {
            return card.type === AVAILABLE_CARD_TYPE.AMERICAN_EXPRESS;
          }
          return false;
        },
        // then: Yup.string().min(4, "Enter a valid security code"),
        then: Yup.string().test("len", "Enter a valid security code", (val) => val?.length === 4),
        otherwise: Yup.string().test(
          "len",
          "Enter a valid security code",
          (val) => val?.length === 3
        ),
      }),
    // is: (imageUrl: string) => imageUrl && imageUrl.length,
    // then: Yup.mixed().nullable(),
    // otherwise: Yup.mixed().required("*Image is required"),

    // .min(3, "Enter a valid security code")
    // .required("Security code is required"),

    expirationDate: Yup.string()
      .required("Expiration date is required")
      .matches(/^(0[1-9]|1[0-2])\/([1-9][0-9])/, "Not a valid expiration date. Example: MM/YY")
      .test(
        "test-credit-card-expiration-date",
        "Invalid Expiration Date has past",
        (expirationDate) => {
          if (!expirationDate) {
            return false;
          }

          const today = new Date();
          const monthToday = today.getMonth() + 1;
          const yearToday = today.getFullYear().toString().substr(-2);

          const [expMonth, expYear] = expirationDate.split("/");

          if (Number(expYear) < Number(yearToday)) {
            return false;
          } else if (Number(expMonth) < monthToday && Number(expYear) <= Number(yearToday)) {
            return false;
          }

          return true;
        }
      )
      .test("test-credit-card-expiration-date", "Invalid Expiration Date", (expirationDate) => {
        if (!expirationDate) {
          return false;
        }

        const expYear = expirationDate.split("/")[1];

        if (!expYear) {
          return false;
        }

        const today = new Date();
        const yearToday = today.getFullYear().toString().substr(-2);

        if (Number(expYear) > Number(yearToday) + 10) {
          return false;
        }

        return true;
      })
      .test("test-credit-card-expiration-date", "Invalid Expiration Month", (expirationDate) => {
        if (!expirationDate) {
          return false;
        }

        const [expMonth] = expirationDate.split("/");

        if (Number(expMonth) > 12) {
          return false;
        }

        return true;
      }),
  }),
});
