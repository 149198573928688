import React from "react";
import { ExportsStyled } from "./styled";

const Exports = () => {
  return (
    <ExportsStyled>
      <b>Exports</b>
      <div className="exports-wrapper">test</div>
    </ExportsStyled>
  );
};

export default Exports;
