import { IFilterItem } from ".";

export const onSelectFilter = (
  selecting: {
    text: string;
    key: string;
    input: string;
  },
  prev: Array<IFilterItem>,
  format: string,
  value: string | { startDate?: Date; endDate?: Date } | Array<string>
) => {
  const index = prev.findIndex((currentFilter) => {
    return currentFilter.filter.key === selecting.key;
  });
  if (index !== -1) {
    return [
      ...prev.slice(0, index),
      {
        filter: {
          key: selecting.key,
          format,
        },
        value,
      },
      ...prev.slice(index + 1),
    ];
  } else {
    return [
      ...prev,
      {
        filter: {
          key: selecting.key,
          format,
        },
        value,
      },
    ];
  }
};
