interface ICampaignPaymentState {
  loading: boolean;
  error: null;
}

const state: ICampaignPaymentState = {
  loading: false,
  error: null,
};

export default state;
