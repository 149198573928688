import { AnyAction } from "redux";
import * as KEYWORD_LIST_FORM_ACTIONS from "../actions/keyword-list-form.actions";
import keywordListFormState from "../states/keyword-list-form.state";

const keywordListFormReducer = (state = keywordListFormState, action: AnyAction) => {
  switch (action.type) {
    case KEYWORD_LIST_FORM_ACTIONS.CREATE_KEYWORDS_LIST:
      state = { ...state, isSubmitting: true };
      break;

    case KEYWORD_LIST_FORM_ACTIONS.CREATE_KEYWORDS_LIST_SUCCESS:
      state = { ...state, isSubmitting: false, keywordList: action.payload.group };
      break;

    case KEYWORD_LIST_FORM_ACTIONS.CREATE_KEYWORDS_LIST_ERROR:
      state = { ...state, isSubmitting: false };
      break;

    case KEYWORD_LIST_FORM_ACTIONS.DUPLICATE_KEYWORDS_LIST:
      state = { ...state, isSubmitting: true };
      break;

    case KEYWORD_LIST_FORM_ACTIONS.DUPLICATE_KEYWORDS_LIST_SUCCESS:
      state = { ...state, isSubmitting: false };
      break;

    case KEYWORD_LIST_FORM_ACTIONS.DUPLICATE_KEYWORDS_LIST_ERROR:
      state = { ...state, isSubmitting: false };
      break;

    case KEYWORD_LIST_FORM_ACTIONS.EDIT_KEYWORDS_LIST:
      state = { ...state, isSubmitting: true };
      break;

    case KEYWORD_LIST_FORM_ACTIONS.EDIT_KEYWORDS_LIST_SUCCESS:
      state = { ...state, isSubmitting: false };
      break;

    case KEYWORD_LIST_FORM_ACTIONS.EDIT_KEYWORDS_LIST_ERROR:
      state = { ...state, isSubmitting: false };
      break;

    case KEYWORD_LIST_FORM_ACTIONS.UPDATE_KEYWORD_LIST_FORM_DATA:
      state = {
        ...state,
        ...action.payload,
      };

      break;

    default:
  }

  return state;
};

export default keywordListFormReducer;
