import styled from "styled-components/macro";

const background = require("assets/images/login-background-graphic.svg").default;

export const AuthStyled = styled.div`
  transition: 0.1s;
  display: flex;
  min-height: 100%;
  align-items: start;
  justify-content: start;
  flex-direction: column;
  font-size: ${({ theme }) => theme.size.md};
  background-image: url(${background});
  background-size: cover;
  color: ${({ theme }) => theme.contrast};
  background-color: ${({ theme }) => theme.auth};

  a {
    color: ${({ theme }) => theme.link};
  }

  padding: 50px 0;
  background-size: 100%;
  width: 100%;

  .logo-wrapper {
    margin-bottom: 25px;
  }
  .logo {
    max-width: 400px;
  }

  .title {
    font-size: 1.575em;
    font-weight: ${({ theme }) => theme.weight.bold};
    line-height: 29px;
    margin-bottom: 30px;
  }

  .btn {
    font-weight: ${({ theme }) => theme.weight.bold};
  }
`;
