import React from "react";
import { CalendarWrapper } from "./styled";
import { DateRange as OriginalCalendar, Range } from "react-date-range";
import { addDays } from "date-fns";

interface ICalendarProps {
  range: Range;
  setSelection: (range: Range) => void;
}

const Calendar: React.FunctionComponent<ICalendarProps> = ({ range, setSelection }) => {
  React.useEffect(() => {
    if (range.startDate) {
      document.querySelector<HTMLDivElement>(".rdrNextButton")?.click();
    }
  }, [range.startDate]);

  return (
    <CalendarWrapper data-testid="calendar" isStartSelect={!range.startDate}>
      <OriginalCalendar
        showDateDisplay={false}
        rangeColors={["#FE0099"]}
        startDatePlaceholder="From"
        endDatePlaceholder="To"
        months={1}
        scroll={{ enabled: true }}
        minDate={range.startDate ? addDays(range.startDate, 30) : new Date()}
        ranges={[range]}
        onChange={(item) => setSelection(item.selection)}
      />
    </CalendarWrapper>
  );
};

export default Calendar;
