import { IErrorMessage } from "../../services/types";

export interface ICountry {
  id: number;
  name: string;
  countryIsoCode: string;
  type: string;
}

export interface ICity {
  location_code: number;
  location_name: string;
  location_code_parent: null | number;
  country_iso_code: string;
  location_type: string;
}

export const SET_COUNTRIES = "campaigns-actions/SET_COUNTRIES";

export const GET_COUNTRIES = "campaigns-actions/GET_COUNTRIES";
export interface GetCountriesAction {
  type: typeof GET_COUNTRIES;
}

export const GET_COUNTRIES_REQUEST = "campaigns-actions/GET_COUNTRIES_REQUEST";
export interface GetCountriesRequestAction {
  type: typeof GET_COUNTRIES_REQUEST;
}

export const GET_COUNTRIES_SUCCESS = "campaigns-actions/GET_COUNTRIES_SUCCESS";
export interface GetCountriesSuccessAction {
  type: typeof GET_COUNTRIES_SUCCESS;
  countries: ICountry[];
}

export const GET_COUNTRIES_FAILURE = "campaigns-actions/GET_COUNTRIES_FAILURE";
export interface GetCountriesFailureAction {
  type: typeof GET_COUNTRIES_FAILURE;
  error: IErrorMessage;
}
