import styled from "styled-components/macro";
import { Navbar } from "react-bootstrap";

const logoFull = require("assets/theme-rad/rad-logo-full-white.png");

export const SlideLogo = styled.div<{ open: boolean }>`
  width: 100px;
  height: 25px;
  left: ${({ open }) => (open ? "20px" : "-300px")};
  position: absolute;
  -webkit-transition: all 350ms cubic-bezier(0.6, 0.05, 0.28, 0.91);
  transition: all 350ms cubic-bezier(0.6, 0.05, 0.28, 0.91);

  .rad-logo {
    width: 100%;
    path {
      fill: ${({ theme }) => theme.contrast};
    }
  }
`;

export const CustomNavbar = styled(Navbar)<{ open: boolean }>`
  height: 50px;
  background: ${({ theme }) => theme.nav}; // layout color
  width: ${({ open }) => (open ? "calc(100% - 15rem)" : "calc(100% - 3.5rem)")};
  left: ${({ open }) => (open ? 15 : 3.5)}rem;
  padding-left: 1.5rem;
  padding-right: 0.5rem;
  padding-top: 0;
  padding-bottom: 0;
  -webkit-transition: all 350ms cubic-bezier(0.6, 0.05, 0.28, 0.91);
  transition: all 350ms cubic-bezier(0.6, 0.05, 0.28, 0.91);
  border-left: 1px solid ${({ theme }) => theme.background};

  .btn-primary {
    font-size: 12px;
    background-color: #0dacd1;
    border-radius: 0%;
    margin-right: 1rem;
  }

  i {
    font-size: 1.25rem;
  }
  .nav-item {
    margin-right: 1rem;
  }

  @media (max-width: 990px) {
    width: calc(100% - 57px);
  }

  .setting-button {
    margin-right: 0;
    margin-left: auto;
  }

  .nav-icon {
    color: #ffffff;
  }

  .link-active {
    background: ${({ theme }) => theme.background};
    margin: -8px 0;
    display: flex;
    color: ${({ theme }) => theme.contrast};
    align-items: center;
  }

  .navbar-nav {
    font-size: 12px;
    width: 100%;
    justify-content: end;

    .nav-link {
      color: ${({ theme }) => theme.contrast} !important;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .navbar-toggler {
    color: #ffffff;
    cursor: pointer;
  }

  .menuToggle {
    color: #ffffff;
    cursor: pointer;
    padding-right: 1rem;
  }

  .topbar-brand {
    background-image: url(${logoFull.default});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    margin-top: -0.5rem;
    width: 15rem;
    position: relative;

    /* &:after {
      content: "BETA";
      position: absolute;
      bottom: -10px;
      right: 5px;
      background-color: white;
      font-size: 0.5rem;
      padding: 0px 10px;
      border-radius: 25px;
      text-align: center;
    } */
  }
`;
