import React from "react";
import Badge from "components/Badge";
import { graphFormatter, MONTH_LIST } from "constants/utility/chart";
import { IDataForSEOV3 } from "constants/utility/keyword";
import {
  ResponsiveContainer,
  AreaChart,
  Area,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts";
import ChartTooltip from "components/ChartTooltip";
import { CompareGraphStyled } from "./styled";

const MAX_KEYWORDS_LIMIT = 5;

const colors = ["#FF8D27", "#5BC9AD", "#F29CC4", "#44A3DE", "#ED636C"];
interface ICompareGraph {
  selectedKeywords: {
    [key: string]: IDataForSEOV3;
  };
  onRemoveKeyword: (keyword: string) => void;
  keywordsLoading: boolean;
  onClearAll: () => void;
  keywordSet: Array<string>;
}

const CompareGraph: React.FunctionComponent<ICompareGraph> = ({
  selectedKeywords,
  onRemoveKeyword,
  keywordsLoading,
  onClearAll,
  keywordSet,
}) => {
  const [chartData, setChartData] = React.useState<Array<IDataForSEOV3>>([]);

  const selectedValues = Object.values(selectedKeywords);

  React.useEffect(() => {
    if (selectedValues.length) {
      if (selectedValues[0].monthly_searches.length) {
        const tgData = [] as any;
        // Loop Date Object
        selectedValues[0].monthly_searches.forEach((date, index) => {
          const dv = {} as any;
          dv.name = `${date.year}-${MONTH_LIST[date.month]}`;
          selectedValues.forEach((data) => {
            dv[data.display] = data.monthly_searches[index].search_volume;
          });
          tgData.push(dv);
        });
        setChartData(tgData.reverse());
      }
    }
  }, [selectedValues.length]);

  return (
    <CompareGraphStyled>
      <div className="compare-info">
        <span className="badge-group">
          <span onClick={onClearAll} data-testid="clear-all" className="clear-all">
            Clear All
          </span>
          {keywordSet.map((keyword, index) => (
            <Badge
              onRemove={() => onRemoveKeyword(keyword.toLowerCase())}
              key={keyword}
              text={keyword}
              color={colors[index]}
            />
          ))}
        </span>
      </div>
      <div
        data-testid="compare-graph"
        className={`keywords-chart ${!chartData.length || keywordsLoading ? "not-displaying" : ""}`}
      >
        {chartData.length && !keywordsLoading ? (
          <ResponsiveContainer height={220}>
            <AreaChart data={chartData} margin={{ top: 0, right: 30, left: 0, bottom: 0 }}>
              {selectedValues.map((keyword, index) => (
                <defs key={keyword.display}>
                  <linearGradient id={index.toString()} x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor={colors[index]} stopOpacity={0.3} />
                    <stop offset="50%" stopColor={colors[index]} stopOpacity={0} />
                  </linearGradient>
                </defs>
              ))}

              <XAxis
                tick={{
                  fill: "white",
                  fontSize: 12,
                }}
                axisLine={false}
                dataKey="name"
              />

              <YAxis
                tickFormatter={graphFormatter}
                tick={{
                  fill: "white",
                  fontSize: 12,
                }}
                axisLine={false}
              />
              <CartesianGrid strokeDasharray="3 3" />
              <Tooltip content={(tooltipProps) => <ChartTooltip {...tooltipProps} />} />

              {selectedValues.map((keyword, index) => (
                <Area
                  key={colors[index]}
                  type="monotone"
                  dataKey={keyword.display}
                  stroke={colors[index]}
                  fillOpacity={1}
                  fill={`url(#${index})`}
                />
              ))}
            </AreaChart>
          </ResponsiveContainer>
        ) : null}
      </div>
    </CompareGraphStyled>
  );
};

export default React.memo(CompareGraph);
