import { FormikProps } from "formik";
import React from "react";
import { countDays } from "constants/utility/date";
import { TotalAmount } from "../CheckoutPanel/styled";
import { PaymentSummaryWrapper } from "./styled";
import { IPostCampaignData } from "constants/utility/campaign";

interface IPaymentSummaryProps {
  formikProps: FormikProps<IPostCampaignData>;
  promo: IPromo | null;
}

export interface IPromo {
  amount: number;
  title: string;
}

const PaymentSummary: React.FunctionComponent<IPaymentSummaryProps> = ({ formikProps, promo }) => {
  const {
    values: { paymentForm },
  } = formikProps;
  const days = countDays(paymentForm.startDate, paymentForm.endDate);

  return (
    <PaymentSummaryWrapper>
      <div className="summary-detail">
        <h6 className="section-header">
          <b>Payment Summary</b>
        </h6>

        <div className="budget-summary">
          <p>Your ads will run for {days} days+ </p>
          <p>
            <span className="bold">{days} Days Budget </span>
          </p>
        </div>

        <div className="amount-summary">
          <p className="budget-info">
            <span>
              ${paymentForm.budgetPerDay.toLocaleString()} per day x {days} days
            </span>
            <TotalAmount>
              ${`${(paymentForm.budgetPerDay * days).toLocaleString()}.00`} USD
            </TotalAmount>
          </p>

          {promo && (
            <p className="promo">
              <span> {promo.title} </span>
              <TotalAmount color="#00fe67">${promo.amount.toLocaleString()} USD</TotalAmount>
            </p>
          )}

          <hr className="line" />
          <p className="total-amount">
            <span className="bold">Total Amount </span>
            <TotalAmount>
              $
              {`${Math.max(
                0,
                paymentForm.budgetPerDay * days - (promo ? promo.amount : 0)
              ).toLocaleString()}.00`}
              <span> USD</span>
            </TotalAmount>
          </p>
        </div>
      </div>
    </PaymentSummaryWrapper>
  );
};

export default PaymentSummary;
