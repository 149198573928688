import * as Yup from "yup";

export default Yup.object().shape({
  // step 1
  name: Yup.string()
    .min(2, "*Name must have at least 2 characters")
    .max(100, "*Name can't be longer than 100 characters")
    .required("*Name is required"),

  description: Yup.string().max(100, "*Description can't be longer than 100 characters"),
  goal: Yup.string().required("*Goal required"),
});
