export const OPEN_SIDEBAR = "OPEN_SIDEBAR";
export const CLOSE_SIDEBAR = "CLOSE_SIDEBAR";

export const OPEN_SLIDE_MODAL = "OPEN_SLIDE_MODAL";
export const CLOSE_SLIDE_MODAL = "CLOSE_SLIDE_MODAL";

export const openSidebar = () => {
  return {
    type: OPEN_SIDEBAR,
    payload: {},
  };
};

export const closeSidebar = () => {
  return {
    type: CLOSE_SIDEBAR,
    payload: {},
  };
};

export const openSlideModal = (id: string) => {
  return {
    type: OPEN_SLIDE_MODAL,
    id,
  };
};

export const closeSlideModal = () => {
  return {
    type: CLOSE_SLIDE_MODAL,
  };
};
