import React from "react";
import styled from "styled-components";

const StyledSignal = styled.div`
width: 100%;
min-height: 100%;
.signal-full {
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    padding: 3% 5%;
}

.signal-head {
    width: 100%;
}

.signal-h {
    font-weight: bold;
    padding: 0px 20px 0px 0px;
    font-size: 18px;
    font-family: "Open Sans", sans-serif;
    line-height: 27px;
    width: 100%;
    text-align: center;
}

.signal-h1 {
    font-weight: bold;
    padding: 0px 20px 0px 0px;
    font-size: 16px;
    font-family: "Open Sans", sans-serif;
    line-height: 27px;
    width: 100%;
    margin: 5px;
}

.signal-name {
    font-weight: normal;
    font-size: 14;
}

.signal-table-container {
    width: 100%;
    padding-top: 2%;
}

.signal-table {
    width: 100%;
}

.td-edit {
    padding: 0px 5px;
}

.edit-input {
    width: 100%;
    border: 1px solid rgb(230,230,230);
    padding: 2px;
    height: 40px;
    color: rgb(51,51,51);
    background-color: rgb(255,255,255);
    border-radius: 8px;
    outline: none;
    padding-left: 10px !important;
  }

.signal-footer {
    display: flex;
    margin: 5%;
    justify-content: center;
    gap: 20px;
}

.save-btn {
    background: rgb(46, 170, 192);
    width: 140px;
    padding: 5px;
    height: 40px;
    font-size: 16px;
    font-weight: 600;
}

.save-btn:hover {
    background-color: rgb(46, 200, 195);
}

.keyword-btn {
    width: 140px;
    padding: 5px;
    height: 40px;
    font-size: 16px;
    font-weight: 600 !important;
}

th {
    color: #d5dde5;
    background: #303633;
    border-bottom: 4px solid #9ea7af;
    font-size: 20px;
    font-weight: 200;
    padding: 15px;
    text-align: left;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    vertical-align: middle;
  }

  th:first-child {
    border-top-left-radius: 5px;
  }

  th:last-child {
    border-top-right-radius: 5px;
    border-right: none;
  }

  tr {
    border-top: 1px solid #c1c3d1;
    border-bottom: 1px solid #c1c3d1;
    color: #666b85;
    font-size: 14px;
    font-weight: normal;
    text-shadow: 0 1px 1px rgba(256, 256, 256, 0.1);
  }

  tr:hover td {
    background: #4e5066;
    color: #ffffff;
    border-top: 1px solid #22262e;
  }

  tr:first-child {
    border-top: none;
  }

  tr:last-child {
    border-bottom: none;
  }

  tr:nth-child(odd) td {
    background: #ebebeb;
  }

  tr:nth-child(odd):hover td {
    background: #4e5066;
  }

  tr:last-child td:first-child {
    border-bottom-left-radius: 3px;
  }

  tr:last-child td:last-child {
    border-bottom-right-radius: 3px;
  }

  td {
    background: #ffffff;
    padding: 15px;
    text-align: left;
    vertical-align: middle;
    font-weight: 300;
    font-size: 16px;
    text-shadow: -1px -1px 1px rgba(0, 0, 0, 0.1);
    /* border-right: 1px solid #c1c3d1; */
  }

  td:last-child {
    border-right: 0px;
  }

  th.text-left {
    text-align: left;
  }

  th.text-center {
    text-align: center;
  }

  th.text-right {
    text-align: right;
  }

  td.text-left {
    text-align: left;
  }

  td.text-center {
    text-align: center;
  }

  td.text-right {
    text-align: right;
  }
`;

export default StyledSignal;