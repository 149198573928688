import styled from "styled-components";

const StyledUpdateAudience = styled.div`
  width: 100%;
  min-height: 100%;
  margin: 0px !important;
  padding: 0px !important;
  h1 {
    color: red;
  }

  .container {
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4% 0;
  }

  .signal-h1 {
    font-weight: bold;
    padding: 0px 20px 0px 0px;
    font-size: 16px;
    font-family: "Open Sans", sans-serif;
    line-height: 27px;
    width: 90%;
    margin: 5px;
  }

  .signal-name {
    font-weight: normal;
    font-size: 14;
  }

  .title {
    font-weight: bold;
    padding: 0px 20px 0px 0px;
    font-size: 18px;
    font-family: "Open Sans", sans-serif;
    line-height: 27px;
  }

  .container-body {
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .container-input {
    width: 100%;
  }

  .container-name {
    width: 500px;
  }

  .name {
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    font-family: "Open Sans", sans-serif;
    margin-bottom: 5px;
  }

  .audience-table {
    width: 100%;
    border-radius: 10px;
    border: 2px solid rgb(240, 240, 240);
    border-radius: 5px;
    background: white;
    padding: 10px 10px 0px 10px;
  }

  .table {
    width: 100%;

    background: white;
    border-radius: 5px;
    border-collapse: collapse;
    margin: auto;
    padding: 5px;
    width: 100%;
    animation: float 5s infinite;
  }

  .btn-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: center;
    margin-top: 50px;
  }

  th {
    font-size: 20px;
    font-weight: 200;
    text-align: left;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    vertical-align: middle;
  }

  tr {
    color: #666b85;
    font-size: 14px;
    font-weight: normal;
    text-shadow: 0 1px 1px rgba(256, 256, 256, 0.1);
  }

  td:first-child {
    padding-left: 20px;
  }

  th:first-child {
    padding-left: 20px;
  }
`;

export default StyledUpdateAudience;
