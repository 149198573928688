import styled from "styled-components/macro";

function getStatusColor(status: string) {
  switch (status) {
    case "Active":
      return `rgb(5, 235, 87)`;
    case "In Progress":
      return `rgb(200, 170, 8)`;
    case "Waiting":
      return `#ffffff`;
    default:
      return "";
  }
}

export const Status = styled.span<{ status: string }>`
  color: ${({ status }) => getStatusColor(status)};

  &:before {
    position: absolute;
    content: "";
    top: 0;
    right: -4px;
    width: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    height: 0;
    transform: rotate(45deg);
    border-bottom: 5px solid ${({ status }) => getStatusColor(status)};
  }
`;

export const CreativeTableStyled = styled.div`
  width: 100%;
  padding: 0 20px;
  max-height: 150px;
  overflow-y: auto;
  /* background-color: ${({ theme }) => theme.background}; */

  .pagination {
    display: flex;
    gap: 15px;
    margin-top: 20px;
    justify-content: flex-end;
    align-items: center;
  }

  .select-all-button {
    button {
      padding: 4px !important;
    }
  }

  .budget {
    color: #ffffff;
  }

  .spent {
    color: rgb(200, 170, 8);
  }

  .remaining {
    color: rgb(5, 235, 87);
  }

  td,
  th {
    padding: 0 15px;
    &:not(:first-child) {
      text-align: center;
    }
  }

  table {
    width: 100%;
    thead {
      height: 45px;
      font-size: 14px;

      tr {
        border-bottom: 1px solid ${({ theme }) => theme.inputBorder};

        th {
          font-size: 12px;
          width: 120px;

          &:first-child {
            width: 200px;

            span {
              display: flex;
              justify-content: start;
              align-items: center;
              button {
                padding: 4px;
              }
            }
          }
        }
      }
    }

    tbody {
      font-size: 14px;
      tr {
        td {
          font-size: 12px;
          position: relative;
          height: 45px;
          border-bottom: 1px solid ${({ theme }) => theme.inputBorder};

          &:first-child {
            display: flex;
            justify-content: start;
            align-items: center;
          }
        }
      }
    }
  }
`;
